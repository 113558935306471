import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import { AuthWrapper, Login } from '../../components/Authentication';
import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const AiquireLogin: FC = () => {
  const SuspenseComponent = () => {
    return (
      <>
        <AuthWrapper>
          <Login />
        </AuthWrapper>
      </>
    );
  }

  return (
    <div className="aiquire-auth-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default AiquireLogin;

