export enum ActiveObjectType {
  Rect = 'rect',
  Image = 'image',
  Text = 'textbox',
  Group = 'group',
}

export type FabricGroupExtended = fabric.Group & {
  svgUid: number;
  id: string;
  layerType: string;
  isSvg?: boolean;
  exportedAsset: string;
  cropX?: number;
  cropY?: number;
  cropWidth?: number;
  cropHeight?: number;
  isBackground?: boolean;
};

export type ExtendedFabricCircle = fabric.Circle & {
  id: string;
  layerType: string;
};

export type ExtendedFabricRect = fabric.Rect & {
  id: string;
  layerType: string;
  isBackground: boolean;
};

export type ExtendedFabricImage = fabric.Image & {
  naturalWidth: number;
  naturalHeight: number;
  id: string;
  layerType: string;
  src: string;
  filters: any[];
  elements?: HTMLImageElement[];
  isBackground?: boolean;
  objectReplaced: fabric.Object | undefined;
  svgXML?: SVGSVGElement;
  isSvg: boolean;
  oldSrc?: string;
  formerWidth?: number;
  formerHeight?: number;
  formerCropX: number;
  formerCropY: number;
  pxWidth?: number;
  pxHeight?: number;
  blob: Blob;
  colorsMap?: {
    [k: string]: {
      hash: string;
      color: string;
      attribute: string;
      className?: string | boolean;
      index: number;
      stroke?: boolean;
      replaceSpace?: boolean;
    }[];
  };
};

export type ExtendedFabricTextbox = fabric.Textbox & {
  id: string;
  layerType: string;
  isCustomFont?: Boolean;
};

export type ExtendedFabricPath = fabric.Path & {
  id: string;
  layerType: string;
  isSvg?: boolean;
  exportedAsset: string;
  cropX?: number;
  cropY?: number;
  cropWidth?: number;
  cropHeight?: number;
};

export type ExtendedFabricObject = fabric.Object & {
  id?: string;
  layerType?: string;
};
