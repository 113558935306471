import axios from 'axios';
import { put, takeLatest, all,call } from 'redux-saga/effects';
import { APPROVAL_URI } from '../../utilities/paths';
import { FetchWorkFlows, FETCH_WORKFLOWS, DELETE_WORKFLOW, DeleteWorkFlow, CREATE_WORKFLOW, CreateWorkFlow, FETCH_WORKFLOW_DATA, FetchWorkFlowData, CreateRound, CREATE_ROUND, UpdateRound, UPDATE_ROUND, DELETE_ROUND, DeleteRound, UpdateWorkFlowData, UPDATE_WORKFLOW_DATA, DuplicateWorkFlowRound, DUPLICATE_WORKFLOW_ROUND, DuplicateWorkflow, DUPLICATE_WORKFLOW, AddUserToRound, ADD_USER_TO_ROUND, RemoveUserFromRound, REMOVE_USER_FROM_ROUND } from "../types";
import { setWorkFlowLoader, setWorkFlows, addWorkFlow, removeWorkFlow, setWorkFlowData, createRound, addRound, changeRound, removeRound, changeWorkFlowData, setWorkflowPageData, getUsersData } from '../actions';
import HttpService from '../../HttpService/HttpService';
import { approvalWorkflowAPI } from '../../MappedAPI/MappedAPI';
import { store } from '../store';
import { openToast } from '../../Toasts';

function* fetchWorkFlows(action: FetchWorkFlows){
    let { payload: {page,page_size},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    // try{

        let query = `${APPROVAL_URI}/api/workflow?page=${page}&page_size=${page_size}&product_id=${product_id}`;
        
        let response = yield axios.get(query,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        yield put(setWorkFlowLoader(false));
        if(!response.data.error) {
            let {data} = response.data;
            let {results} = data;
            if(!callback){
                yield put(setWorkflowPageData({
                    count: data.count,
                    page_size: data.page_size,
                    current_page: data.current_page,
                    pages: data.pages
                }))
            }
            if(page == 1){
                if(callback){
                    callback(data,false)
                }else{
                    yield put(setWorkFlows(results));
                }
            }else{
                if(callback){
                    callback(data,false)
                }else{
                    yield put(addWorkFlow(results))
                }
            }
        }
    // }catch(error){
    //     openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    // }
}


function* deleteWorkFlow(action: DeleteWorkFlow){
    let { payload,callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${APPROVAL_URI}/api/workflow/${payload}`;
        let response = yield axios.delete(query,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
        yield put(setWorkFlowLoader(false));
        if(!response.data.error) {
            yield put(removeWorkFlow(payload))
            callback && callback(response?.data,false);
            openToast('success',response?.data?.data?.message || "WorkFlow Deleted!")
        }
    }catch(error){
        callback && callback(error?.response?.data,true);
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    }
}


function* createWorkflow(action: CreateWorkFlow){
    let { payload: {name,description} } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    try{

        let query = `${APPROVAL_URI}/api/workflow`;
        
        let response = yield axios.post(query,
            {
                name,description,product_id
            },
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        yield put(setWorkFlowLoader(false));
        if(!response.data.error) {
            let {data} = response.data;
            yield put(setWorkFlowData(data));
            // yield put(createRound(data.id,{
            //     name: "Round 1",
            //     settings: {
            //         everyonemustapprove: false
            //     }
            // }));
            data.rounds = [{
                id:-1,
                name: `Round 1`,
                settings: {
                    everyonemustapprove: false
                },
                order: -1,
                approvers: []
            }]
            yield put(addWorkFlow([data]));
            openToast('success',response?.data?.data?.message || "WorkFlow Created!")
        }
    }catch(error){
        yield put(setWorkFlowLoader(false));
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    }
}


function* fetchWorkFlowData(action: FetchWorkFlowData){
    let { payload,callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    let query = `${APPROVAL_URI}/api/workflow/${payload}`;
    

    try{
        let params = `/api/workflow/${payload}`
        let response = yield call(HttpService.get,APPROVAL_URI,`${params}`,TOKEN)
        
        if (!response.data.error) {
          callback && callback(response.data,false);
        } else {
          callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* createWorkFlowRound(action: CreateRound){
    let { payload: {workFlowId,round},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${APPROVAL_URI}/api/workflow/${workFlowId}/round`;
        
        let response = yield axios.post(query,round,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        yield put(setWorkFlowLoader(false));
        if(!response.data.error) {
            let {data} = response.data;
            callback && callback(response.data, false);
            // yield put(addRound(data));
            openToast('success',response?.data?.data?.message || "Round Added!")
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    
        callback && callback(error.response, true);
    }
}


function* updateRound(action: UpdateRound){
    let { payload: {workFlowId,round},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${APPROVAL_URI}/api/workflow/${workFlowId}/round/${round.id}`;
        
        let response = yield axios.put(query,round,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        yield put(setWorkFlowLoader(false));
        if(!response.data.error){
            if(callback){
                callback(response.data.data,false);
            }else{
                yield put(changeRound(response.data.data));
            }
            openToast('success',response?.data?.data?.message || "Round Updated!")
        }
    }catch(error){
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    }
}

function* deleteRound(action: DeleteRound){
    let { payload: {workFlowId,roundId} } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${APPROVAL_URI}/api/workflow/${workFlowId}/round/${roundId}`;
        let response = yield axios.delete(query,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
        yield put(setWorkFlowLoader(false));
        if(!response.data.error) {
            yield put(removeRound(roundId))
            openToast('success',response?.data?.data?.message || "Round Deleted!")
        }
    }catch(error){
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    }
}

function* updateWorkFlowData(action: UpdateWorkFlowData){
    let { payload: {workFlowId,name},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${APPROVAL_URI}/api/workflow/${workFlowId}`;
        
        let response = yield axios.put(query,{name},
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        yield put(setWorkFlowLoader(false));
        if(!response.data.error){
            if(callback){
                callback(response.data,false);
            }else{
                yield put(changeWorkFlowData(response.data.data));
            }
            openToast('success',response?.data?.data?.message || "Workflow Updated!")
        }
    }catch(error){
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
    }
}

function* duplicateWorkflowRound(action: DuplicateWorkFlowRound) {
    let { payload: {workflow_id,round_id},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
      let params = `/${workflow_id}/round/${round_id}/duplicate`;
      let response = yield call(HttpService.post,APPROVAL_URI,`${approvalWorkflowAPI}${params}`,TOKEN,{})
      
      if (!response.data.error) {
        openToast('success',response?.data?.data?.message || "Round Duplicated!")
        callback && callback(response.data,false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
      callback && callback(error.response, true);
    }
}


function* duplicateWorkflow(action: DuplicateWorkflow) {
    let { payload,callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
      let params = `/${payload}/duplicate`;
      let response = yield call(HttpService.post,APPROVAL_URI,`${approvalWorkflowAPI}${params}`,TOKEN,{})
      
      if (!response.data.error) {
        openToast('success',response?.data?.data?.message || "Workflow Duplicated!")

        callback && callback(response.data,false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
      callback && callback(error.response, true);
    }
}

function* addUserToRound(action: AddUserToRound) {
    let { payload: {workflowId,roundId,userId},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
      let params = `/${workflowId}/round/${roundId}/users`;
      let response = yield call(HttpService.post,APPROVAL_URI,`${approvalWorkflowAPI}${params}`,TOKEN,{user_id:userId})
      
      if (!response.data.error) {
        openToast('success',response?.data?.data?.message || "User Added to Round!")
        callback && callback(response.data,false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
      callback && callback(error.response, true);
    }
}

function* removeUserFromRound(action: RemoveUserFromRound) {
    let { payload: {workflowId,roundId,userId},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
      let params = `/${workflowId}/round/${roundId}/users`;
      let response = yield call(HttpService.delete,APPROVAL_URI,`${approvalWorkflowAPI}${params}`,TOKEN,{user_id:userId})
      
      if (!response.data.error) {
        openToast('success',response?.data?.data?.message || "User Removed from Round!")
        callback && callback(response.data,false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
        openToast('error',error?.response?.data?.message || "Something Went Wrong!")
      callback && callback(error.response, true);
    }
}



export function* workflowWatcher() {
    yield takeLatest(FETCH_WORKFLOWS, fetchWorkFlows)
    yield takeLatest(DELETE_WORKFLOW, deleteWorkFlow)
    yield takeLatest(CREATE_WORKFLOW, createWorkflow)
    yield takeLatest(FETCH_WORKFLOW_DATA, fetchWorkFlowData)
    yield takeLatest(CREATE_ROUND, createWorkFlowRound)
    yield takeLatest(UPDATE_ROUND, updateRound)
    yield takeLatest(DELETE_ROUND, deleteRound)
    yield takeLatest(UPDATE_WORKFLOW_DATA, updateWorkFlowData)
    yield takeLatest(DUPLICATE_WORKFLOW_ROUND, duplicateWorkflowRound)
    yield takeLatest(DUPLICATE_WORKFLOW, duplicateWorkflow)
    yield takeLatest(ADD_USER_TO_ROUND, addUserToRound)
    yield takeLatest(REMOVE_USER_FROM_ROUND, removeUserFromRound)
}