export enum CLASSIFICATION_TYPES {
  BACKGROUND = 'background',
  /* BACKGROUND_PART_HORIZONTAL = 'backgroundPartHorizontal',
  BACKGROUND_PART_VERTICAL = 'backgroundPartVertical', */
  BACKGROUND_PART_BOTTOM = 'backgroundPartBottom',
  BACKGROUND_PART_TOP = 'backgroundPartTop',
  BACKGROUND_PART_LEFT = 'backgroundPartLeft',
  BACKGROUND_PART_RIGHT = 'backgroundPartRight',
  DEFAULT = 'default',
  OUTSIDE_CANVAS = 'outsideCanvas',
  BACKGROUND_PART_CENTER = 'backgroundPartCenter'
}