import React from 'react'

export const FullScreenSpinner = () => {

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'grid',
        zIndex: 2147483649, // to overlay intercom overlay for now
        backgroundColor: 'rgba(23, 0, 92, 0.3)',
        backdropFilter: 'blur(0.5rem)',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <img width='150' src='/images/spinnerimg-white.gif' alt='Loading...' />
    </div>
  )
}