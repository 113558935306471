

import { UPDATE_HYPER_DETAILS, GET_HYPER_PROJECT_SUCCESS, GET_LAYER_ASSETS, GET_LAYER_ASSETS_SUCCESS, LOADING_HYPER, LOADING_HYPER_OFF, GET_ASSET_COMBINATION_DATA_SUCCESS,UPDATE_ALL_ASSETS_READY_STATUS } from '../types';

const initialState = {
  loading: false,
  projectData: null,
  projectDataSingle: null,
  layerAssetsData: null,
  allAssetsReadyStatus: true
}

const updateState = (oldState:any, updatedValues:any) => ({
  ...oldState,
  ...updatedValues,
  loading: false,
});

export const hyperPersonalizeReducer = (state = initialState, action:any) => {
  switch(action.type) {
    case GET_HYPER_PROJECT_SUCCESS:
      return updateState(state, { projectData: action.payload });
    case UPDATE_HYPER_DETAILS:
      return updateState(state, { projectData: action.payload });
    case GET_LAYER_ASSETS_SUCCESS:
      return updateState(state, { layerAssetsData: action.payload });
    case GET_ASSET_COMBINATION_DATA_SUCCESS:
      return updateState(state, { projectDataSingle: action.payload });
    case UPDATE_ALL_ASSETS_READY_STATUS:
      return updateState(state, { allAssetsReadyStatus: action.payload });
    case LOADING_HYPER_OFF:
      return updateState(state, { loading: false });
    case LOADING_HYPER:
      return { ...state, loading: true };
    default:
      return { ...state };
  }
}