import React, { FC } from 'react';
import { Card, Button } from 'pyxis-ui-kit';

const CardContainer: FC<{items: any,children:any,routePage:any}> = ({items,children,routePage}) => {
    require("./index.scss");
    return (
        <div className="card-container">
            <div className="item-container">
                {items.map((item:any, index:any)=>(
                <Card className="card-item" key={index}>
                    <div className="icon">
                        <img src={item?.icon} />
                    </div>
                    <div className="content">
                        <h1 className="title">{item.title}</h1>
                        <p className="description">{item.description}</p>
                    </div>
                </Card>
                ))}
            </div>
            <div className="btn-container">
                <Button btnsize="md" type="primary" className="continue" onClick={routePage}>{children}</Button>
            </div>
        </div>
    );
}

export default CardContainer;