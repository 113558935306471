import React, { lazy, Suspense, FC, useState, useEffect, useRef } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import './index.scss';
import { closeIntercom,loadIntercom } from '../../utilities/services/intercom';
import { useSelector } from 'react-redux';
import { AuthState } from '../../store/types';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}


const FeedBack: FC<any> = () => {
  const userData = useSelector((state: {authentication:AuthState})=>state.authentication.userData)
  useEffect(()=>{
    closeIntercom();
    return ()=>{
      loadIntercom({ name: userData?.firstName+" "+userData?.lastName,email: userData?.emailId })
    }
  },[])

  const SuspenseComponent = () => {
    return (
      <>
        <div className="aiquire-feedback">
          <iframe className="h-100" src="https://aiquire-feedback.web.app"></iframe>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default FeedBack;

