import { convertFromRaw, convertToRaw, ContentState, EditorState, convertFromHTML } from 'draft-js';

const getIndicesOf = (searchStr:string, str:string, caseSensitive?:boolean) => {
  let tempStr:string = str;
  let tempSearchStr:string = searchStr;
  const searchStrLen = tempSearchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index;
  const indices = [];
  if (!caseSensitive) {
    tempStr = tempStr.toLowerCase();
    tempSearchStr = tempSearchStr.toLowerCase();
  }

  while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

const getEntityRanges = (text:string, mentionName:string, mentionKey:number) => {
  const indices = getIndicesOf(mentionName, text);
  
  if (indices.length > 0) {
    return indices.map((offset) => ({
      key: mentionKey,
      length: mentionName.length,
      offset
    }));
  }

  return null;
};

const createMentionEntities = (text:string, tags:any[]) => {
  const blockWithoutHtml = convertFromHTML(text);
  const contentState = ContentState.createFromBlockArray(
    blockWithoutHtml.contentBlocks,
    blockWithoutHtml.entityMap,
  );
  const rawContent:any = convertToRaw(contentState);
  let rawState = tags.map((tag:any,index:number) => ({
    type: 'mention',
    mutability: 'IMMUTABLE',
    data: {mention: tag}
  }));

  rawContent.entityMap = [...rawState];

  rawContent.blocks = rawContent.blocks.map((block:any) => {
    const ranges:any[] = [];
    tags.forEach((tag, index) => {
      const entityRanges = getEntityRanges(block.text, '@'+tag.name, index);
      if (entityRanges) {
        ranges.push(...entityRanges);
      }
    });

    return { ...block, entityRanges: ranges };
  });

  return EditorState.createWithContent(convertFromRaw(rawContent));
};

export default createMentionEntities;