import axios, { AxiosRequestConfig } from 'axios';


const httpService = {
  get: (baseUrl: string, api: string, token: string | undefined, canceltoken?:any) =>
    axios.get(baseUrl + api, { headers: { token },cancelToken:canceltoken }),
  post: (baseUrl: string, api: string, token: string | undefined, data: any, config?: AxiosRequestConfig, canceltoken?:any) =>
    axios.post(baseUrl + api, data, { headers: token ? { token } : {}, ...config, cancelToken:canceltoken }),
  // post: (baseUrl: string, api: string, token: string | undefined, data: any, config?: AxiosRequestConfig) =>
  //   axios.post(baseUrl + api, data, { headers: token ? { token } : {}, ...config }),
  delete: (baseUrl: string, api: string, token: string | undefined, data?: any) =>
    axios.delete(baseUrl + api, { headers: { token }, data }),
  put: (baseUrl: string, api: string, token: string | undefined, data: any, canceltoken?:any) =>
    axios.put(baseUrl + api, data, { headers: { token }, cancelToken:canceltoken}),
  patch: (baseUrl: string, api: string, token: string | undefined, data: any) =>
    axios.patch(baseUrl + api, data, { headers: { token } }),
};



export default httpService;