import { WorkFLow, SetWorkFlows, SET_WORKFLOWS, CHANGE_WORKFLOW, REMOVE_WORKFLOW, FetchWorkFlows, FETCH_WORKFLOWS, SetWorkFlowLoader, SET_WORKFLOW_LOADER, DeleteWorkFlow, DELETE_WORKFLOW, CreateWorkFlow, CREATE_WORKFLOW, AddWorkFlow, ADD_WORKFLOW, FetchWorkFlowData, FETCH_WORKFLOW_DATA, WorkFlowData, SetWorkFlowData, SET_WORKFLOW_DATA, RoundCreateQuery, CreateRound, CREATE_ROUND, Round, AddRound, ADD_ROUND, UpdateRound, UPDATE_ROUND, ChangeRound, CHANGE_ROUND, DeleteRound, DELETE_ROUND, RemoveRound, REMOVE_ROUND, UpdateWorkFlowData, UPDATE_WORKFLOW_DATA, ChangeWorkFlowData, CHANGE_WORKFLOW_DATA, SetApprovalPopUpPropsAction, SetApprovalPopUpProps, SET_APPROVAL_POPUP_PROPS, PageData, SetWorkflowPageData, SET_WORKFLOW_PAGE_DATA, DUPLICATE_WORKFLOW_ROUND, DUPLICATE_WORKFLOW, AddUserToRound, ADD_USER_TO_ROUND, RemoveUserFromRound, REMOVE_USER_FROM_ROUND, SetWorkFlowTopBarTab, SET_WORKFLOW_TOPBAR_TAB } from "../types";


export const setWorkFlows = (payload: WorkFLow[]):SetWorkFlows => ({
    type: SET_WORKFLOWS,
    payload: payload
})

export const changeWorkFlows = (updatedWorkFlow: WorkFLow,workFlowIndex: number) => ({
    type: CHANGE_WORKFLOW,
    payload: {
        updatedWorkFlow,
        workFlowIndex
    }
})

export const removeWorkFlow = (payload: number) => ({
    type: REMOVE_WORKFLOW,
    payload: payload
})

export const fetchWorkFlows = (payload:{page:number,page_size?:number,product_id?:number},callback?:Function):FetchWorkFlows =>({
    type: FETCH_WORKFLOWS,
    payload: {
        page: payload.page,
        page_size: payload.page_size? payload.page_size: 10,
        product_id: payload.product_id? payload.product_id: 1
    },
    callback
})

export const setWorkFlowLoader = (payload: boolean):SetWorkFlowLoader => ({
    type: SET_WORKFLOW_LOADER,
    payload: payload
})

export const deleteWorkFlow = (payload: number,callback?:Function):DeleteWorkFlow => ({
    type: DELETE_WORKFLOW,
    payload,
    callback
})

export const createWorkFlow = (name:string,description:string,product_id:number = 1):CreateWorkFlow => ({
    type: CREATE_WORKFLOW,
    payload: {
        name,
        description,
        product_id
    }
})

export const addWorkFlow = (payload: WorkFLow[]):AddWorkFlow => ({
    type: ADD_WORKFLOW,
    payload: payload
})

export const fetchWorkFlowData = (payload: number,callback?:Function):FetchWorkFlowData => ({
    type: FETCH_WORKFLOW_DATA,
    payload,
    callback
})

export const setWorkFlowData = (payload: WorkFlowData|null): SetWorkFlowData => ({
    type: SET_WORKFLOW_DATA,
    payload: payload
})

export const createRound = (payload:{workFlowId:number, round: RoundCreateQuery},callback?:Function): CreateRound => ({
    type: CREATE_ROUND,
    payload,
    callback
})

export const addRound = (payload: Round): AddRound => ({
    type: ADD_ROUND,
    payload: payload
})

export const updateRound = (workFlowId:number,round: Round,callback?:Function): UpdateRound => ({
    type: UPDATE_ROUND,
    payload:{
        workFlowId,
        round
    },
    callback
})

export const changeRound = (updatedRound:Round,roundIndex?: number): ChangeRound => ({
    type: CHANGE_ROUND,
    payload: {
        roundIndex,
        updatedRound
    }
})

export const deleteRound = (workFlowId:number, roundId: number, callback?: Function): DeleteRound => ({
    type: DELETE_ROUND,
    payload: {
        workFlowId,
        roundId
    },
    callback
})

export const removeRound = (payload: number): RemoveRound => ({
    type: REMOVE_ROUND,
    payload: payload
})

export const updateWorkFlowData = (workFlowId: number,name: string,callback?:Function): UpdateWorkFlowData => ({
    type: UPDATE_WORKFLOW_DATA,
    payload: {
        workFlowId,
        name
    },
    callback
})

export const changeWorkFlowData = (payload: WorkFlowData): ChangeWorkFlowData => ({
    type: CHANGE_WORKFLOW_DATA, 
    payload: payload
})

export const setApprovalPopUpProps = (payload: SetApprovalPopUpProps): SetApprovalPopUpPropsAction => ({
    type: SET_APPROVAL_POPUP_PROPS,
    payload: payload
})

export const setWorkflowPageData = (payload: PageData): SetWorkflowPageData => ({
    type: SET_WORKFLOW_PAGE_DATA,
    payload
})

export const duplicateWorkflow = (payload:number,callback?:Function) => ({
    type: DUPLICATE_WORKFLOW,
    payload,
    callback
})

export const duplicateWorkflowRound = (payload:{workflow_id:number,round_id:number},callback?:Function) => ({
    type: DUPLICATE_WORKFLOW_ROUND,
    payload,
    callback
})

export const addUserToRound = (payload: { workflowId:number,roundId:number, userId:string },callback?:Function):AddUserToRound => ({
    type: ADD_USER_TO_ROUND,
    payload,
    callback
})

export const removeUserFromRound = (payload:{ workflowId:number,roundId:number, userId:string },callback?:Function):RemoveUserFromRound => ({
    type: REMOVE_USER_FROM_ROUND,
    payload,
    callback
})

export const setWorkflowTopbarTab = (payload:number):SetWorkFlowTopBarTab => ({
    type: SET_WORKFLOW_TOPBAR_TAB,
    payload
})