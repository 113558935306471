import React, { useState, useEffect } from 'react';
import ChooseToUpload from './ChooseToUpload';
import CongratulationScreen from './CongratulationScreen';
import UploadFiles from './UploadFiles';
import WelcomeScreen from './WelcomeScreen';
import { useHistory } from "react-router-dom";

const LibraryOnBoarding: React.FunctionComponent = () => {
    const [pageCount, setPageCount] = useState(0);
    const [selectedType, setSelectedType] = useState("custom files")
    // const [selectedType,setSelectedType]=useState("brand color")
    // const [selectedType,setSelectedType]=useState("typography")
    const routePage = (selectedType: any) => {
        setPageCount(pageCount + 1)
        setSelectedType(selectedType)
    }
    const [page,setPage]=useState(<WelcomeScreen routePage={routePage}/>)
    // const [page,setPage]=useState(<ChooseToUpload routePage={routePage}/>)
    // const [page, setPage] = useState(<UploadFiles routePage={routePage} selectedType={selectedType} />)
    // const [page,setPage]=useState(<CongratulationScreen routePage={routePage} selectedType={selectedType}/>)
    const history = useHistory()
    useEffect(() => {
        if (pageCount === 1) { setPage(<ChooseToUpload routePage={routePage} />) }
        else if (pageCount === 2) { setPage(<UploadFiles routePage={routePage} selectedType={selectedType} />) }
        else if (pageCount === 3) { setPage(<CongratulationScreen routePage={routePage} selectedType={selectedType} />) }
        else if (pageCount === 4) {
            if (selectedType == "custom files" || selectedType == "brand logo") {
                history.push('/cocreate/library/images')
            } else if (selectedType == "brand color") {
                history.push('/cocreate/library/color-palette')
            } else if (selectedType == "typography") {
                history.push('/cocreate/library/typography')
            }
        }
    }, [pageCount]);

    return <>{page}</>
}

export default LibraryOnBoarding;
