import {
  AuthState,
  Login,
  SetIsLoggedIn,
  SetToken,
  SET_IS_LOGGED_IN,
  SET_TOKEN,
  SET_USER_DATA,
  SetUserData,
  LOGOUT,
  Logout,
  SetAccessToken,
  SET_ACCESS_TOKEN
} from "../types";
import { closeIntercom } from '../../utilities/services/intercom';

const initialState: AuthState = {
  isLoggedIn: false,
  token: null,
  userData: null,
  accessToken: null
};

export const authReducer = (
  state: AuthState = initialState,
  action: SetIsLoggedIn | SetToken | SetUserData | Logout | SetAccessToken
) => {
  switch (action.type) {
    case SET_IS_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case SET_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: action.payload
      }
    }
    case LOGOUT: {
      closeIntercom();
      return initialState;
    }
    default: {
      return state;
    }
  }
};
