import React, { useState } from 'react';
import './index.scss';
import { openToast } from '../../../Toasts';
import CommonDrawerComponent from "../../shared/CommonDrawer";
import IconArrow from "../../../assets/svg/iconArrow.svg";
import { InputPassword } from 'pyxis-ui-kit';

interface CreatePasswordPopUpProps {
    onCancel?: Function;
    onSubmit?: Function;
}

const CreatePasswordPopUp:React.FC<CreatePasswordPopUpProps> = ({onCancel, onSubmit}) => {
    const [loading, setLoader] = useState(false);
    
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [oldPassword, setOldPassword] = useState('');

    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = () => {
        if(oldPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0) {
            if(newPassword !== confirmPassword) {
                openToast('error', 'Passwords do not match');
            } else {
                onSubmit && onSubmit({newPassword, oldPassword});
            }
        }
    }

    return (
    <CommonDrawerComponent
        title="Create new password"
        subText=""
        cancelButtonText="Cancel and return"
        submitButtonText="Continue"
        submitButtonIcon={<img src={IconArrow} alt="arrow" />}
        cancelButtonFunction={() => onCancel && onCancel()}
        submitButtonFunction={handleSubmit}
        isFormValid={
            oldPassword.length > 0 && 
            newPassword.length > 0 && 
            confirmPassword.length > 0 && 
            newPassword === confirmPassword &&
            !loading}
        loading={loading}>
            <div className="form">
                <div className="form-field">
                    <div>
                        <label className="currentPasswordLabel">
                            Current password
                        </label>
                    </div>
                    <div>
                        <InputPassword
                            className={oldPassword.length > 0 ? "oldPassword input-filled": "oldPassword"}
                            type={oldPasswordVisible ? "text" : "password"}
                            value={oldPassword}
                            placeholder="Enter the current password"
                            onChange={(e:any) => setOldPassword(e.target.value)}
                            onPressEnter={handleSubmit} />
                    </div>
                </div>
                <div className="form-field">
                    <div>
                        <label className="currentPasswordLabel">
                            New password
                        </label>
                    </div>
                    <div>
                        <InputPassword
                            className={newPassword.length > 0 ? "newPassword input-filled": "newPassword"}
                            type={newPasswordVisible ? "text" : "password"}
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e:any) => setNewPassword(e.target.value)} 
                            onPressEnter={handleSubmit} />
                    </div>
                </div>
                <div className="form-field">
                    <div>
                        <label className="currentPasswordLabel">
                            Confirm password
                        </label>
                    </div>
                    <div>
                        <InputPassword
                            className={confirmPassword.length > 0 && newPassword === confirmPassword
                                ? "confirmPassword input-filled"
                                : confirmPassword.length > 0 ?  "confirmPassword errorInput" : "confirmPassword"
                            }
                            type={confirmPasswordVisible ? "text" : "password"}
                            placeholder="Enter confirm password"
                            value={confirmPassword}
                            onChange={(e:any) => setConfirmPassword(e.target.value)}
                            onPressEnter={handleSubmit} />
                        {confirmPassword.length > 0 && newPassword !== confirmPassword && <div className="errorMessage">Passwords do not match</div>}
                    </div>
                </div>
            </div>    
        </CommonDrawerComponent>
    );
}

export default CreatePasswordPopUp;