import React from 'react';
import { Button } from 'pyxis-ui-kit';
import { CopyOutlined } from '@ant-design/icons';

export function Pagination({ activeRepromptIndex, setActiveRepromptIndex, setOutput, reprompts, setReprompt, setPureOutput }) {

    const goRight = () => {
        if (activeRepromptIndex < reprompts.length - 1) {
            setActiveRepromptIndex(activeRepromptIndex + 1);
            setReprompt(reprompts[activeRepromptIndex + 1].user_input == 'Regenerate text' ? '' : reprompts[activeRepromptIndex + 1].user_input)
            setOutput(parseTextToHTML(reprompts[activeRepromptIndex + 1].output))
            setPureOutput(reprompts[activeRepromptIndex + 1].output)
            return true;
        }

        return;
    }

    const goLeft = () => {
        if (activeRepromptIndex > 0) {
            setActiveRepromptIndex(activeRepromptIndex - 1);
            setReprompt(reprompts[activeRepromptIndex - 1].user_input == 'Regenerate text' ? '' : reprompts[activeRepromptIndex - 1].user_input)
            setOutput(parseTextToHTML(reprompts[activeRepromptIndex - 1].output))
            setPureOutput(reprompts[activeRepromptIndex - 1].output)
            return;
        }

        return;
    }

    return (
        <div className='pagination-btn-container' >
            <span
                style={{ color: activeRepromptIndex === 0 ? '#E0E0E0' : '#838383' }}
                className='left arrow-btn' onClick={() => goLeft()}>{"<  "}</span>
            <span style={{ width: '5px' }}>{" "}</span>
            <span>{activeRepromptIndex + 1}/{reprompts.length}</span>
            <span style={{ width: '5px' }}>{" "}</span>
            <span className='right arrow-btn'
                style={{ color: activeRepromptIndex === reprompts.length - 1 ? '#E0E0E0' : '#838383' }}
                onClick={() => goRight()}>{"  >"}</span>
            &nbsp;
        </div>
    )
}


export const ActionButtons = ({ setRepromptActive, copyToClipBoard }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        }}>
            <Button
                className="primary submit"
                onClick={() => setRepromptActive(true)}
            >
                Re Prompt
            </Button>
            <Button btnsize="md"
                type="primary"
                className="primary submit"
                icon={<CopyOutlined style={{ fontSize: '14px' }} />}
                style={{ position: "relative", margin: "2rem 2rem", padding: "0 8rem", display: "flex", flexDirection: 'row', gap: '5px' }}
                onClick={copyToClipBoard}

            >
                Copy to Clipboard
            </Button>
        </div>
    )
}


export const RepromptActionBar = ({inputRef,handleRepromptSubmit,reprompts,activeRepromptIndex,setOutput,isRepromptEmpty,setActiveRepromptIndex, setReprompt, rePrompt, loading, setRepromptActive, reprompt, setPureOutput}) => {
    return (
        <form className="chat-input-container" onSubmit={handleRepromptSubmit}>
            <div className='pagination-container'>
                {reprompts.length > 0 && <Pagination
                    setReprompt={setReprompt}
                    setPureOutput={setPureOutput}
                    setOutput={setOutput}
                    reprompts={reprompts}
                    activeRepromptIndex={activeRepromptIndex}
                    setActiveRepromptIndex={setActiveRepromptIndex}
                />}
            </div>
            <input ref={inputRef}
                placeholder={isRepromptEmpty ? 'Your output has been regenerated' : ''}
                onChange={(e) => {
                    setReprompt(e.target.value)
                }} value={reprompt} className='chat-input' type="text" height={"43px"} />
            <Button btnsize="md"
                type="primary"
                className="primary submit"
                onClick={rePrompt}
                disabled={loading}
                style={{ marginRight: "0px" }}>
                Re Prompt
            </Button>
            <Button
                className="primary submit"
                onClick={() => setRepromptActive(false)}
                style={{ marginLeft: "1rem" }}
            >
                Cancel
            </Button>
        </form>
    )
}



function extractLastLine(userInput) {
    const lines = userInput.split('\n');
    const lastLine = lines[lines.length - 1].trim();
    return lastLine;
}

function parseTextToHTML(text) {
    const encodedText = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    const finalHTML = `<div style="white-space: pre-line;">${encodedText}</div>`;
    return finalHTML;
}