import React, { useContext, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ConversionContext } from '../../ConversionContext/ConversionContext';
import { CreateContextAiquire } from '../../CreateContextAiquire/CreateContextAiquire';
import { loading, createProject } from '../../store/actions';

import './AiquireCreateFlow.scss';
import { openToast } from '../../Toasts';

const TopMenu = () => {
  const {
  } = useContext(ConversionContext);
  const {
    sizesArray,
    setSizesArray,
    selectedTemplate,
    currentStep,
    setCurrentStep,
    createMode,
    setCreateMode,
    setLoading,
    selectedSizes,
    setSelectedSizes,
  } = useContext(CreateContextAiquire);

  const history = useHistory();
  const dispatch = useDispatch();
  const props = useSelector((state: any) => ({
    templateSizes: state.create.templateSizes,
    popularSizes: state.create.popularTemplateSizes,
    productId: state.roles.selectedProductId,
    folder: state.folders.activeFolderIdCreate,
  }));
  
  const nextHandler = () => {
    if (currentStep === 'selectSizes' && !selectedSizes.length) return openToast('info', 'Please select any size to continue.');
    if (currentStep === 'selectTemplate' && selectedTemplate === -1) return openToast('info', 'Please select a template');
    if (createMode === 'set' && currentStep === 'selectSizes') return setCurrentStep('selectTemplate');

    const sizes = selectedSizes.map((sizeObj:any) => {
      const sizeId = sizeObj.split('-').reverse()[0];
      if (sizeObj.includes('custom')) {
        const [ w, h ]  = sizeId.replace(/\(|\)/g, '').split(',');
        return {
          type:'custom',
          height: parseInt(h, 10),
          width: parseInt(w, 10),
          name: sizeObj.split('-')[0],
        }
      } else {
        const { height, width, value } = sizesArray.find((obj:any) => obj.key.split('-').reverse()[0] === sizeId);
        return {
          type: 'preset',
          height,
          width,
          id: parseInt(sizeId, 10),
          name: value,
        }
      }
    });

    dispatch(loading());
    const postData:any = {
      title: createMode === 'single' ? 'My Project' : 'My Set',
      type: createMode === 'single' ? 'image' : 'image_set',
      product_id: props.productId,
      sizes,
      template: selectedTemplate && createMode !== 'single' ? selectedTemplate : null,
      folder: props.folder || null,
    }
    dispatch(createProject(postData, (res:any, isError:any) => {
      if (isError) openToast('error', 'project creation failed');
      if (!isError) {
        return history.push({pathname: `/cocreate/${createMode === 'single' ? 'editor' : 'set'}/${res.data.id}`,state:{ showUploadPSD: createMode === 'single' }});
      }
    }))
  }

  const prevHandler = () => {
    if (currentStep === 'selectSizes') {
      setCreateMode('');
      setSelectedSizes([]);
    }
    setCurrentStep(
      currentStep === 'selectTemplate' ? 'selectSizes' : 'createNew'
    );
  };

  const textHeading = currentStep === 'selectSizes' ? (createMode === 'single' ? 'Select single size' : 'Select multiple sizes') : 'Select a template';
  const textSubHeading = currentStep === 'selectSizes'
    ? 'Choose sizes for display advertising, social media or print.'
    : 'Browse hundreds of professional templates from any category.';
  return (
    <>
      <div className='sec-headingWrapper'>
        <h1 className='sec-heading'>{textHeading}</h1>
        <p>{textSubHeading}</p>
      </div>
      <div className='backnext-btn-sec'>
        <div className='back-next-btnWrapper'>
          <button onClick={prevHandler} className="back-btn-secondary">
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 7V9H3.99997L9.49997 14.5L8.07997 15.92L0.159973 8L8.07997 0.0800018L9.49997 1.5L3.99997 7H16Z" fill="#20007F"/>
            </svg><span className='ml-2'>Back</span>
          </button>
          <div className="exclamation-wrap">
            <button onClick={nextHandler} className="select-btn-primary">
              <span className='mr-2'>Select</span>
            </button>
          </div>
        </div>
        {selectedSizes.length ? (
          <div className='selectedtext'>
            <span className='mr-2 text-primary'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#20007F"/>
              </svg>
            </span>
            {`${selectedSizes.length} Size${
              selectedSizes.length !== 1 ? 's' : ''
            } Selected`}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TopMenu;
