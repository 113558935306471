
import React, { useState } from 'react'

export default function EditName(props:any) {
    const [name, setName] = useState(props.name);
    const changeHandler = (e:any) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            props.rename(name);
        }
    }
    return (
        <div className="popup-overlay" style={{ position: 'fixed' }} onClick={(e) => e.stopPropagation()}>
        <div className="addfolder-popup">
            <div className="title">
                <p>Rename Layer</p>
            </div>
            <div className="content">
            <p>Name</p>
            <input type="text" value={name} placeholder="Type name here" onKeyUp={changeHandler} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="footer">
            <button className="secondary" type="button" onClick={(e) => {
                e.stopPropagation();
                props.cancel();
            }}>Cancel</button>
            <button className="primary" type="button" onClick={(e) => {
                e.stopPropagation();
                props.rename(name);
            }}>Rename</button>
            </div>
        </div>
        </div>
    );
}