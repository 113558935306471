import React, { useState } from "react";
import { useLocation, Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendResetLink } from "../../../store/actions";
import { AuthState } from "../../../store/types";

import pyxisLogo from "../../../assets/svg/pyxisLogo.svg";
import resetForm from "../../../assets/svg/resetForm.svg";
import resetComplete from "../../../assets/svg/resetComplete.svg";
import { Button, Input } from "pyxis-ui-kit";
import { Loading3QuartersOutlined } from "@ant-design/icons";

import "./index.scss";
import { openToast } from "../../../Toasts";
import { isValidEmail, hideEmail } from "../../../utilities/common-function";

enum FORM_STATE {
  SEND_EMAIL = "SEND_EMAIL",
  COMPLETE = "COMPLETE",
}

const ResetPassword: React.FunctionComponent = () => {
  const { isLoggedIn } = useSelector((state: { authentication: AuthState }) => state.authentication);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoader] = useState<boolean>(false);
  const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.SEND_EMAIL);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();
  const referer = location?.state?.referer ?? "/";

  const handleReset = () => {
    if (!isValidEmail(email)) return openToast("error", "Please enter a valid email address.");
    setLoader(true);
      dispatch(sendResetLink({ email }, (_, error) => {
        setLoader(false);
        if (!error) {
          setFormState(FORM_STATE.COMPLETE);
        }
      }));
  }


  // Once login, redirect to last visited page while logout or homepage(first time login)
  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="reset-wrapper">
      <div className="banner">
        <div className="img-container">
          <img src={formState === FORM_STATE.SEND_EMAIL ? resetForm : resetComplete} alt="resetPassword" className="reset-img" />
        </div>
        <div className="title">Reset your password?</div>
        <div className="sub-title">
          Don"t worry, it happens to the best of us. Just follow the instructions and get back to being social in no time!
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="header">
            <img className="logo" alt="pixislogo" src={pyxisLogo} />
            <div className="title">Password reset request</div>
            <div className="description" style={{ textAlign: "left", padding: 0 }}>
              {
                formState === FORM_STATE.SEND_EMAIL
                  ? `Enter your email address in the space below & we will mail you the password reset instructions.`
                  : `An email with password reset link sent to your email address: ${hideEmail(email)}. Follow the instructions in the mail to reset your account password and get back into your account.`
              }
            </div>
          </div>
          <div className="reset-form">
            {formState === FORM_STATE.SEND_EMAIL ? <div className="form-field email">
              <div className="reset-label">
                <span className="primary-lable">Email address</span>
              </div>
              <Input
                className="email"
                placeholder="Enter your email address to reset"
                value={email}
                type="email"
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleReset();
                  }
                }}
              />
            </div> : null}
            <Button
              className="reset-pass"
              btnsize="xl"
              type="primary"
              onClick={formState === FORM_STATE.SEND_EMAIL ? () => handleReset() : () => history.push("/login")}
              disabled={!email || loading}
            >
              <span>{formState === FORM_STATE.SEND_EMAIL ? "Reset Password" : "Back to Login"}</span>
              {loading && (
                <span>
                  <Loading3QuartersOutlined spin />
                </span>
              )}
            </Button>
          </div>
          {formState === FORM_STATE.SEND_EMAIL ? <div className="footer-container">
            <p className="footer">
              Already have an account?
              <Link to="/login"> Log in </Link>
            </p>
          </div> : null}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;