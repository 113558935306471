import React, {  Suspense, FC, useState  } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import {LeftSideBar,Content} from '../../components/DashboardSection';

import './AiquireHomepage.scss';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';
import { SearchBar } from '../../components/shared/SearchBar';


const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const Homepage: FC<any> = ({userPermissions}) => {
  
  const SuspenseComponent = () => {
    return (
      <>
        <SearchBar type="project"/>
        <div className="dashboard-wrap">
          {userPermissions.indexOf('view-all-assets') > -1 || userPermissions.indexOf('view-banner-library') > -1 ? <> 
            {/* left menu, top menu and dashboard import homepage */}
            <LeftSideBar folderType="project"/>
            <div className="flex-column">
              <div className="flex h-100">
                <Content type="project" />
              </div>
            </div>
          </>:<div className="flex-column" style={{width:"100vw"}}>
            <PermissionNotAllowed title="You do not have access to this feature as per your role" />
          </div>}
        </div>
     
      </>
    );
  }
  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default Homepage;

