import { fabric } from 'fabric';
import React, { FC, lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { clearDataEditor, setErrMsg } from '../../store/actions';
import './AiquireCreate.scss';



const HyperPersonalizeSingle: FC = () => {
  //weird hack fabric undefined issue
  const temp = fabric;
  const Design = lazy(() => import('../../components/DesignSection/DesignSectionHyperSingle'));
  const dispatch = useDispatch();
  const params:any = useParams();
  useEffect(() => {
    return () => {
      dispatch(setErrMsg(''));
      dispatch(clearDataEditor());
    };
  });
  
  return (
    <div className="aiquire-create-page-wrapper">
      <ErrorBoundary>
        <Suspense fallback={<FullScreenSpinner />}>
          <Design />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default HyperPersonalizeSingle;
