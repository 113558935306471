import axios from 'axios';
import { put, takeLatest, all, takeEvery, call } from 'redux-saga/effects';
import { DESIGN_URI, IMAGE_BASE_URL } from '../../utilities/paths';
import { FetchTypography, FETCH_TYPOGRAPHY, DELETE_TYPOGRAPHY, DeleteTypography, UPLOAD_TYPOGRAPHY, UploadTypography, FetchColors, FETCH_COLORS, UploadColor, UPLOAD_COLOR, DeleteColors, DELETE_COLORS, FetchMyTemplates, MyTemplate, FETCH_MY_TEMPLATES, DELETE_MY_TEMPLATES, DeleteMyTemplates, UpdateColor, UPDATE_COLOR, FETCH_TEMPLATE_OPTIONS, FetchTemplateOptions, UpdateTemplateOptions, UPDATE_TEMPLATE_OPTIONS, CreateProjectFromTemplate, CREATE_PROJECT_FROM_TEMPLATE, GET_FONTS_LIST_SUCCESS_UPLOAD } from '../types';
import { setLoader, addTypography, setMyTemplates,removeMyTemplates, setSelectedMyTemplates, changeColor, setTemplateOptions, changeTemplateOption, setTemplatePageData } from '../actions';
import { store } from '../store';
import { templateAPI } from '../../MappedAPI/MappedAPI';
import HttpService from '../../HttpService/HttpService';
import { openToast } from '../../Toasts';
import { detectQueryString } from '../../utilities/common-function';

function* getTypograpgies(action: FetchTypography){
    let { payload: {page,webfont,page_size},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    try{
        let query = `${DESIGN_URI}/api/typography?product_id=${product_id}&webfont=${webfont}&page=${page}&page_size=${page_size}`;
        let response = yield axios.get(query,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
        
        if (!response.data.error) {
            callback && callback(response.data,false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}


function* deleteTypography(action: DeleteTypography){
    // let { payload: {index,id} } = action;
    let {payload:{index,id},callback} = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${DESIGN_URI}/api/typography/${id}`;
        let response = yield axios.delete(query,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
        if(!response.data.error) {
            callback && callback(response.data,false);
            openToast('success',response?.data?.data?.message || "Typography deleted!")
        }
    }catch(error){
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}

function* uploadTypography(action: UploadTypography){
    let {payload:{files},callback} = action;
    // let { payload: {files} } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    const uploadedFonts = store.getState().create.uploadedFonts;
    try{
        let query = `${DESIGN_URI}/api/typography/new`;
        let typos = [];
        for(const file of files){
            let fileform:any = new FormData();
            fileform.append("file",file);
            fileform.append("product_id",product_id);
            let response = yield axios.post(query,fileform,
                {
                    headers:{
                        token: TOKEN
                    }
                }
            );
            if(callback){
                callback(response.data,false)
               
            }
    
            if(!response.data.error){
                typos.push({ ...response.data.data, can_manage: true });
            }else{
                callback && callback(response,true)
            }
        }
        yield put(setLoader(false));
        yield put(addTypography(typos));
        yield put({
            type: GET_FONTS_LIST_SUCCESS_UPLOAD,
            payload: { results: [ ...uploadedFonts, ...typos ] },
        });

    }catch(error){
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}


function* fetchColors(action: FetchColors){
    let { payload: {page,page_size},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    try{
        let query = `${DESIGN_URI}/api/brandcolor?page=${page}&page_size=${page_size}&product_id=${product_id}`;
    
        let response = yield axios.get(query,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
        
        if (!response.data.error) {
            // let {results} = response.data.data;
            // yield put(setColors(results));
            callback && callback(response.data,false);
        } else {
          callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}



function* uploadColor(action: UploadColor){
    let { payload: {name,color_code},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    try{
        let query = `${DESIGN_URI}/api/brandcolor/new`;
        
        let response = yield axios.post(query,
            {
                name,color_code,product_id
            },
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        if(!response.data.error) {
            callback && callback(response.data,false);
        }else {
            callback && callback(response, true);
        }
    }catch(error){
        callback && callback(error.response, true);
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}



function* deleteColor(action: DeleteColors){
    let { payload, callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${DESIGN_URI}/api/brandcolor/${payload.length>1? 'delete':payload[0]}`;
        
        let response = yield axios.delete(query,
            {
                headers:{
                    token: TOKEN
                },
                data: payload.length > 1? {
                    brand_ids: payload
                }:null
            }
        );
    
        if(!response.data.error){
            callback && callback(response.data,false);
        } else {
            callback && callback(response, true);
        }
    }catch(error){
        callback && callback(error.response, true);
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}


function* updateColor(action: UpdateColor){
    let { payload: {id},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let query = `${DESIGN_URI}/api/brandcolor/${id}`;
        
        let response = yield axios.put(query,action.payload,
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        if(!response.data.error){
            yield put(setLoader(false));
            response.data.data['can_manage'] = true;
            yield put(changeColor(response.data.data));
            callback && callback(response.data,false)
            openToast('success',response?.data?.data?.message || "Color updated!")
        }else{
            callback && callback(response.data,true)
        }
    }catch(error){
        callback && callback(error?.response,true)
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}



function* 
fetchTemplates(action: FetchMyTemplates){
    let { payload: {page,page_size},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    let query = `${DESIGN_URI}/api/mytemplate?page=${page}&page_size=${page_size}&product_id=${product_id}`;
    
    let response = yield axios.get(query,
        {
            headers:{
                token: TOKEN
            }
        }
    );
    if(!response.data.error){
        let templates = store.getState().library.templates;
        let { results } = response.data.data;
        let arr:MyTemplate[] = [];
        results.forEach((data:any)=>{
            arr.push({...data,...{
                rendered_file: IMAGE_BASE_URL + data.rendered_file + (detectQueryString(data.rendered_file)? '&q=40': '?q=40'),
            }})
        })
        yield put(setTemplatePageData({
            count: response.data.data.count,
            current_page: response.data.data.current_page,
            page_size: response.data.data.page_size,
            pages: response.data.data.pages
        }))
        yield put(setMyTemplates(response.data.data.current_page > 1? [...templates,...arr]: arr))
        callback && callback(response.data,false)
    }else{
        callback && callback(response.data,true)
    }
}


function* deleteMyTemplates(action: DeleteMyTemplates){
    let { payload, callback } = action;
    let selectedTemplates = store.getState().library.selectedTemplates;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let yieldArr = [];
        for(const id of payload){
            let query = `${DESIGN_URI}/api/mytemplate/${id}`;
            
            let response = yield axios.delete(query,
                {
                    headers:{
                        token: TOKEN
                    }
                }
            );
        
            if(!response.data.error){
                callback && callback(response.data,false);
                yieldArr.push(put(removeMyTemplates(id)))
            } else {
                callback && callback(response, true);
            }
        }
        yield put(setLoader(false));
        yield put(setSelectedMyTemplates([]));
        yield all(yieldArr);
        openToast('success', `Template${selectedTemplates.length>1?'s':''} deleted!`)
    }catch(error){
        callback && callback(error.response, true);
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}


function* fetchTemplateOptions(action: FetchTemplateOptions){
    let { payload } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    let query = `${DESIGN_URI}/api/typography/defaults?product_id=${product_id}`;
    
    let response = yield axios.get(query,
        {
            headers:{
                token: TOKEN
            }
        }
    );

    if(!response.data.error){
        let { data } = response.data;
        let arr:MyTemplate[] = [];
        yield put(setLoader(false));
        yield put(setTemplateOptions(data))
    }
}


function* updateTemplateOption(action: UpdateTemplateOptions){
    let { payload: {option} } = action;
    const TOKEN:any = store.getState().authentication.token;
    const product_id = store.getState().roles.selectedProductId;
    try{
        let query = `${DESIGN_URI}/api/typography/defaults`;
        let response = yield axios.put(query,{...option,product_id},
            {
                headers:{
                    token: TOKEN
                }
            }
        );
    
        if(!response.data.error){
            yield put(changeTemplateOption(response.data.data));
            openToast('success',response?.data?.data?.message || "Typography options updated!")
        }
    }catch(error){
        openToast('error',error?.response?.data?.message || "Something went wrong!")
    }
}

function* createProjectFromTemplate(action: CreateProjectFromTemplate){
    let { payload:{templateId,title,folder},callback } = action;
    const TOKEN:any = store.getState().authentication.token;
    try{
        let params = `/${templateId}/project`
        let response = yield call(HttpService.post,DESIGN_URI,`${templateAPI}${params}`,TOKEN,{ title })
        
        if (!response.data.error) {
          callback && callback(response.data,false);
        } else {
          callback && callback(response, true);
        }
      } catch (error) {
        callback && callback(error.response, true);
    }
}

export function* libraryWatcher() {
    yield takeLatest(DELETE_TYPOGRAPHY, deleteTypography)
    yield takeLatest(UPLOAD_TYPOGRAPHY, uploadTypography)
    yield takeLatest(FETCH_COLORS, fetchColors)
    yield takeEvery(UPLOAD_COLOR, uploadColor)
    yield takeLatest(DELETE_COLORS, deleteColor)
    yield takeLatest(UPDATE_COLOR, updateColor)
    yield takeLatest(FETCH_MY_TEMPLATES, fetchTemplates)
    yield takeLatest(DELETE_MY_TEMPLATES, deleteMyTemplates)
    yield takeLatest(FETCH_TEMPLATE_OPTIONS, fetchTemplateOptions)
    yield takeLatest(UPDATE_TEMPLATE_OPTIONS, updateTemplateOption)
    yield takeEvery(FETCH_TYPOGRAPHY, getTypograpgies)
    yield takeEvery(CREATE_PROJECT_FROM_TEMPLATE, createProjectFromTemplate)
}