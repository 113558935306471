import React, { useEffect, useState, useRef, EventHandler, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faLayerGroup,faTrash, faSpinner, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { WorkflowState, Round, PageData, RolesState } from '../../../store/types';
import { fetchWorkFlows, deleteWorkFlow, duplicateWorkflow, addWorkFlow, getUsersData, setWorkFlows, setWorkflowPageData } from '../../../store/actions';
import Menu from '../../shared/DropdownMenu';
import { WorkFlow,WorkFlowList } from './WorkFlowList' ;
import { permissionDeniedClick } from '../../../utilities/common-function';
import EmptyPlaceHolder from '../../shared/EmptyPlaceHolder';
import EmptyWorkflow from '../../../assets/img/emptyworkflows.png';
import { getTourStateValue, NO_WORKFLOWS_TOUR_ID,SOME_WORKFLOWS_TOUR_ID, startTour, updateTourState } from '../../../utilities/services/intercom';
import Img from '../../ImageLoader/ImageLoader';

interface ContainerInterface {
    className: string
}
interface HeaderInterface {
    title: string;
    description: string;
}

interface AddWorkFlowInterface {}
const colors = [
    "#D32F2F",
    "#B85600",
    "#F55F44",
    "#F2994A",
    "#20007F",
    "#9E54FD",
    "#025D8C",
    "#006666"
]

const Container: React.FunctionComponent<ContainerInterface> = ({children,className},props) =>{
    return <div className={className} {...props}>
        {children}
    </div>;
}

const Header: React.FunctionComponent<HeaderInterface> = ({title,description}) => {
    return <Container className="header">
        <div className="title">
            {title}
        </div>
        {description && <div className="description">{description}</div>}
    </Container>
}

const AddWorkFlow:React.FunctionComponent<AddWorkFlowInterface> = (props) => {
    let history = useHistory();
    return <div className="workflow" onClick={()=>history.push("/cocreate/workflow/new")}>
        <div className="add">
            <FontAwesomeIcon icon={faPlus} />
        </div>
    </div>
}

const WorkFlows: React.FunctionComponent = () => {
    const [loading,setLoader] = useState(false);
    const state = useSelector((state:{ workflows:WorkflowState })=>(state.workflows));
    const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
    const { workflows,pageData } = state;
    const dispatch = useDispatch();
    const history = useHistory()

    // console.log("state: " + JSON.parse(state.toString()));
    useEffect(()=>{
        setLoader(true);
        dispatch(fetchWorkFlows({page:1},(data:any,err:boolean)=>{
            if(!err){
                let workflows:any = data.results;

                dispatch(setWorkflowPageData({
                    count: data.count,
                    page_size: data.page_size,
                    current_page: data.current_page,
                    pages: data.pages
                }))
                
                if(workflows.length > 0) {
                    let ids:string[] = workflows.reduce((acc:any,val:any)=>{
                        return [...acc,val.created_by,...val.rounds.reduce((acc1:any,val1:any)=>{
                            return [...acc1,...val1.approvers]
                        },[])]
                    },[]) 
                    if(ids.length > 0){
                        dispatch(getUsersData({userIds: ids},(userres:any,usererr:boolean)=>{
                            setLoader(false);
                            if(!usererr){
                                let { users } = userres.data;
                                workflows.map((workflow:any,windex:number)=>{
                                    workflow.created_by = users.find((u:any)=>u._id === workflow.created_by)||"";
                                    workflow.rounds.map((round:any,index:number)=>{
                                        round.approvers.map((a:any,r:number)=>{
                                            round.approvers[r] = users.find((u:any)=>u._id === a)||"";
                                        });
                                    })
                                })
                                dispatch(setWorkFlows(workflows))
                                if(!getTourStateValue('NO_WORKFLOWS_TOUR') && !getTourStateValue('SOME_WORKFLOWS_TOUR')){
                                    startTour(SOME_WORKFLOWS_TOUR_ID);
                                    updateTourState('SOME_WORKFLOWS_TOUR',true);
                                }
                            }
                        }))
                    }else{
                        setLoader(false);
                        console.log("before set: " + workflows);
                        dispatch(setWorkFlows(workflows))
                        if(!getTourStateValue('NO_WORKFLOWS_TOUR') && !getTourStateValue('SOME_WORKFLOWS_TOUR')){
                            startTour(SOME_WORKFLOWS_TOUR_ID);
                            updateTourState('SOME_WORKFLOWS_TOUR',true);
                        }
                    }
                }else{
                    setLoader(false);
                    dispatch(setWorkFlows([]))
                    if(!getTourStateValue('NO_WORKFLOWS_TOUR')){
                        startTour(NO_WORKFLOWS_TOUR_ID);
                        updateTourState('NO_WORKFLOWS_TOUR',true);
                    }
                }
            }
        }))
    },[])

    return (<React.Fragment>
        <div className="workflows-listing">
            <Header title="Approval Workflows" description="" />
            {loading && <div className="loader">
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>}
            {!loading && workflows.length > 0 && <WorkFlowList workflows={workflows} pageData={pageData} setLoader={setLoader} isDisabled={userPermission.indexOf('manage-approval-workflow') === -1} />}
            {!loading && workflows.length === 0 && <EmptyPlaceHolder 
                icon={<img src={EmptyWorkflow} />}
                text="No approval workflows added yet. Add a new workflow to get started"
                submitText="New Workflow"
                onSubmit={()=>{
                    history.push('/cocreate/workflow/new');
                }}
                style={{paddingTop:'10vh'}}
            />}
        </div>
    </React.Fragment>
    )
}

export default WorkFlows;