// import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import { authorize, setIsLoggedIn, setToken, setUserData } from '../store/actions';
import { AuthState } from '../store/types';
import { identifyMixpanel, trackSignIn } from '../utilities/services/mixpanel';

const RedirectRoute = () => { 
    const { isLoggedIn } = useSelector((state: { authentication: AuthState }) => state.authentication);
    const dispatch = useDispatch();
    const location:any = useLocation();
    const history = useHistory();
    const params:any = new URLSearchParams(location.search);
    const referer = location?.state?.referer || '/';

    useEffect(() => {
        let token:any = params.get('t');
        if(token)
        dispatch(authorize({token},(response:any, err:boolean) => {
            if(!err){
                if(!isLoggedIn){
                    dispatch(setToken(token));
                    dispatch(setUserData({...response.data, emailId: response.data.email}));

                    const { userId, firstName, lastName, email, phoneNumber } = response.data;
                    // LogRocket.identify(userId, {
                    //     name: `${firstName} ${lastName}`,
                    //     email: email,
                    //     phoneNumber,
                    // });
                    identifyMixpanel(email, `${firstName} ${lastName}`);
                    trackSignIn(email);
                    dispatch(setIsLoggedIn(true));
                } else {
                    history.replace('/');
                }
            } else {
                history.replace('/login');
            }
        }))
    }, [])

    if (isLoggedIn) {
        return <Redirect to={referer} />;
    }

    return null;
}

export default RedirectRoute;