export const SET_TOPBAR_ACTIVE_TAB = "SET_TOPBAR_ACTIVE_TAB";
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const FETCH_OTHERS_PRODUCT_LIST = "FETCH_OTHERS_PRODUCT_LIST";
export const SET_NAVBAR_PRODUCT_LIST = "SET_NAVBAR_PRODUCT_LIST";
export const FETCH_MEMBERS_LIST = "FETCH_MEMBERS_LIST";
export const SET_MEMBERS_LIST = "SET_MEMBERS_LIST";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const SET_ITEM_LIST = "SET_ITEM_LIST";
export const SET_SELECTED_PRODUCT_ID = "SET_SELECTED_PRODUCT_ID";
export const SET_ADDMEMBER_POPUP_PROPS = "SET_ADDMEMBER_POPUP_PROPS";
export const GET_USER_DATA = "GET_USER_DATA";
export const FETCH_ROLES_LIST = "FETCH_ROLES_LIST";
export const SET_ROLES_LIST = "SET_ROLES_LIST";
export const LINK_USER_WITH_ENTITY = "LINK_USER_WITH_ENTITY";
export const UNLINK_USER_WITH_ENTITY = "UNLINK_USER_WITH_ENTITY";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const SET_ALL_ROLES = "SET_ALL_ROLES";
export const SET_ALL_PERMISSIONS = "SET_ALL_PERMISSIONS"; 
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const CREATE_USER = "CREATE_USER";
export const CREATE_CUSTOM_ROLE = "CREATE_CUSTOM_ROLE";
export const UPDATE_CUSTOM_ROLE = "UPDATE_CUSTOM_ROLE";
export const DELETE_CUSTOM_ROLE = "DELETE_CUSTOM_ROLE";
export const FETCH_USER_PERMISSIONS = "FETCH_USER_PERMISSIONS";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const SET_SHOW_BRAND_ADMIN = "SET_SHOW_BRAND_ADMIN";
export const SET_SHOW_PRODUCT_ADMIN = "SET_SHOW_PRODUCT_ADMIN";
export const SET_BRAND_PERMISSIONS = "SET_BRAND_PERMISSIONS";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_BRAND_ID = "SET_BRAND_ID";

export type RolesState = {
  activeTopBarTab: number;
  navProductList: any;
  selectedProductId: string | null;
  rolesList: any[];
  membersList: any;
  itemList: any;
  selectedItem: number;
  productsList: any;
  allRoles: any;
  allPermission: any;
  userPermissions: string[];
  brandPermissions: string[];
  showBrandAdmin: boolean;
  showProductAdmin: boolean;
  org_id: string|null;
  brand_id: string|null;
};

export type SetTopBarActiveTab = {
  type: typeof SET_TOPBAR_ACTIVE_TAB;
  payload: number;
};

export type FetchProductList = {
  type: typeof FETCH_PRODUCT_LIST;
  payload: {
    entityType?: string;
    entityId?: string;
    userId?: string;
  };
  callback?: Function;
};

export type SetNavBarProductList = {
  type: typeof SET_NAVBAR_PRODUCT_LIST;
  payload: any;
};

export type FetchMembersList = {
  type: typeof FETCH_MEMBERS_LIST;
  payload: {
    entityType: string;
    entityId?: string;
    permissions?: string;
    roles?: string;
    page?: number
  };
  callback?: Function;
};

export type SetMembersList = {
  type: typeof SET_MEMBERS_LIST;
  payload: any;
};

export type SetSelectedItem = {
  type: typeof SET_SELECTED_ITEM;
  payload: number;
};

export type SetProductsList = {
  type: typeof SET_PRODUCTS_LIST;
  payload: any;
};

export type SetItemList = {
  type: typeof SET_ITEM_LIST;
  payload: any;
};

export type SetSelectedProductId = {
  type: typeof SET_SELECTED_PRODUCT_ID;
  payload: string | null;
};

export type SetAddMembersPopUpProps = {
  type: typeof SET_ADDMEMBER_POPUP_PROPS;
  payload: {
    isOpen: boolean;
  };
};

export type FetchOthersProductList = {
  type: typeof FETCH_OTHERS_PRODUCT_LIST;
  payload: {
    userId: string;
    level: string;
    entityId: string;
  };
  callback?: Function;
};

export type GetUsersData = {
  type: typeof GET_USER_DATA;
  payload: {
    userIds: string[];
  };
  callback?: Function;
};

export type FetchRolesList = {
  type: typeof FETCH_ROLES_LIST;
  payload: {
    entityType: string;
  };
  callback?: Function;
};

export type SetRolesList = {
  type: typeof SET_ROLES_LIST;
  payload: any[];
};

export type LinkUserWithEntity = {
  type: typeof LINK_USER_WITH_ENTITY;
  payload: {
    userId: string;
    entityType: string;
    entityId: string;
    role: string;
  };
  callback?: Function;
};

export type UnLinkUserWithEntity = {
  type: typeof UNLINK_USER_WITH_ENTITY;
  payload: {
    userId: string;
    entityType: string;
    entityId: string;
  };
  callback?: Function;
};

export type UpdateProduct = {
  type: typeof UPDATE_PRODUCT;
  payload: {
    name: string;
    product_id: string;
  };
  callback?: Function;
};

export type CreateProduct = {
  type: typeof CREATE_PRODUCT;
  payload: {
    name: string;
  };
  callback?: Function;
};

export type DeleteProduct = {
  type: typeof DELETE_PRODUCT;
  payload: {
    productId: string;
  };
  callback?: Function;
};

export type SetAllRoles = {
  type: typeof SET_ALL_ROLES;
  payload: any
}

export type SetAllPermissions = {
  type: typeof SET_ALL_PERMISSIONS;
  payload: any
}

export type FetchPermissions = {
  type: typeof FETCH_PERMISSIONS;
  callback?: Function
}

export type CreateUser = {
  type: typeof CREATE_USER,
  payload: {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    role: string;
    entityType: string;
    entityId: string;
    phoneNumber?: string;
  },
  callback?:Function
}

export type CreateCustomRole = {
  type: typeof CREATE_CUSTOM_ROLE,
  payload: {
    roleId: string,
    name: string,
    type: string,
    entityType: string,
    permissions: string[]
  },
  callback?:Function
}

export type UpdateCustomRole = {
  type: typeof UPDATE_CUSTOM_ROLE,
  payload: {
    roleId: string,
    name: string,
    permissions: string[]
  },
  callback?:Function
}

export type DeleteCustomRole = {
  type: typeof DELETE_CUSTOM_ROLE,
  payload: string,
  callback?:Function
}

export type FetchUserPermissions = {
  type: typeof FETCH_USER_PERMISSIONS,
  payload: {
    entityType: string;
    entityId: string;
  },
  callback?:Function
}

export type SetUserPermissions = {
  type: typeof SET_USER_PERMISSIONS,
  payload: string[]
}

export type SetShowBrandAdmin = {
  type: typeof SET_SHOW_BRAND_ADMIN,
  payload: boolean
}

export type SetShowProductAdmin ={
  type: typeof SET_SHOW_PRODUCT_ADMIN,
  payload: boolean
}

export type SetBrandPermissions = {
  type: typeof SET_BRAND_PERMISSIONS,
  payload: string[]
}

export type SetBrandId = {
  type: typeof SET_BRAND_ID,
  payload: string|null;
}

export type SetOrgId = {
  type: typeof SET_ORG_ID,
  payload: string|null;
}