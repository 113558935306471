import React, { FC, useCallback } from "react";
import { Button } from "pyxis-ui-kit";
import "./index.scss";
import { Modal } from "antd";

interface DeleteResourceModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalTitle: string;
  modalDesc: string;
  handleDeleteClick: Function;
}

const DeleteResourceModal: FC<DeleteResourceModalProps> = ({
  isModalOpen,
  setModalOpen,
  modalTitle = "",
  modalDesc = "",
  handleDeleteClick,
}) => {
  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <Modal
      centered
      visible={isModalOpen}
      footer={null}
      className="delete-modal"
      onCancel={handleCancelButton}
    >
      <div className="modal-content-title">{modalTitle}</div>
      <div className="modal-content-desc">{modalDesc}</div>

      <div className="delete-modal-buttons">
        <Button
          btnsize="sm"
          type="primary"
          className="primary cancel-button action-button"
          onClick={handleCancelButton}
        >
          Cancel
        </Button>
        <Button
          btnsize="sm"
          className="primary delete-button action-button"
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteResourceModal;
