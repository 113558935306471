import React, {FC } from 'react';
import success from '../../../assets/svg/success.svg';
import './index.scss'

interface SuccessScreenInterface{
    title:string,
    subTitle:string,
    successImg?:any
}

export const SuccessScreen:FC<SuccessScreenInterface>=({ title,subTitle,successImg})=>{
    return <div className="success">
            <img src={successImg?successImg:success} className="success-img"/>
            <h2 className="success-title">{title}</h2>
            <h4 className="success-sub-title">{subTitle}</h4>
        </div>
}