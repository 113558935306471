import {
  RolesState,
  SetTopBarActiveTab,
  SET_TOPBAR_ACTIVE_TAB,
  SET_NAVBAR_PRODUCT_LIST,
  SetNavBarProductList,
  SET_MEMBERS_LIST,
  SetMembersList,
  SetSelectedItem,
  SET_SELECTED_ITEM,
  SET_PRODUCTS_LIST,
  SetProductsList,
  SetItemList,
  SET_ITEM_LIST,
  SetSelectedProductId,
  SET_SELECTED_PRODUCT_ID,
  SET_ADDMEMBER_POPUP_PROPS,
  SetAddMembersPopUpProps,
  SET_ROLES_LIST,
  SetRolesList,
  SetAllPermissions,
  SetAllRoles,
  SET_ALL_PERMISSIONS,
  SET_ALL_ROLES,
  SetUserPermissions,
  SET_USER_PERMISSIONS,
  SetShowBrandAdmin,
  SetShowProductAdmin,
  SET_SHOW_PRODUCT_ADMIN,
  SET_SHOW_BRAND_ADMIN,
  SetBrandPermissions,
  SET_BRAND_PERMISSIONS,
  SetBrandId,
  SetOrgId,
  SET_BRAND_ID,
  SET_ORG_ID
} from "../types";

const initialState: RolesState = {
  activeTopBarTab: -1,
  navProductList: [],
  selectedProductId: null,
  rolesList: [],
  membersList: [],
  productsList: [],
  itemList: [],
  selectedItem: -1,
  allPermission: [],
  allRoles: [],
  userPermissions: [],
  brandPermissions: [],
  showBrandAdmin: false,
  showProductAdmin: false,
  org_id: null,
  brand_id: null
};

export const rolesReducer = (
  state: RolesState = initialState,
  action:
    | SetTopBarActiveTab
    | SetNavBarProductList
    | SetMembersList
    | SetSelectedItem
    | SetProductsList
    | SetItemList
    | SetSelectedProductId
    | SetAddMembersPopUpProps
    | SetRolesList
    | SetAllPermissions
    | SetAllRoles
    | SetUserPermissions
    | SetShowBrandAdmin
    | SetShowProductAdmin
    | SetBrandPermissions
    | SetBrandId
    | SetOrgId
) => {
  switch (action.type) {
    case SET_TOPBAR_ACTIVE_TAB: {
      return {
        ...state,
        activeTopBarTab: action.payload,
      };
    }
    case SET_NAVBAR_PRODUCT_LIST: {
      return {
        ...state,
        navProductList: action.payload,
      };
    }
    case SET_MEMBERS_LIST: {
      return {
        ...state,
        membersList: action.payload,
      };
    }
    case SET_SELECTED_ITEM: {
      return {
        ...state,
        selectedItem: action.payload,
      };
    }
    case SET_PRODUCTS_LIST: {
      return {
        ...state,
        productsList: action.payload,
      };
    }
    case SET_ITEM_LIST: {
      return {
        ...state,
        itemList: action.payload,
      };
    }
    case SET_SELECTED_PRODUCT_ID: {
      return {
        ...state,
        selectedProductId: action.payload,
      };
    }
    case SET_ADDMEMBER_POPUP_PROPS: {
      return {
        ...state,
        addMemberPopUpProps: action.payload,
      };
    }
    case SET_ROLES_LIST: {
      return {
        ...state,
        rolesList: action.payload,
      };
    }
    case SET_ALL_PERMISSIONS: {
      return {
        ...state,
        allPermission: action.payload
      }
    }
    case SET_ALL_ROLES: {
      return {
        ...state,
        allRoles: action.payload
      }
    }
    case SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload
      }
    }
    case SET_SHOW_PRODUCT_ADMIN:{
      return {
        ...state,
        showProductAdmin: action.payload
      }
    }
    case SET_SHOW_BRAND_ADMIN:{
      return {
        ...state,
        showBrandAdmin: action.payload
      }
    }
    case SET_BRAND_PERMISSIONS:{
      return{
        ...state,
        brandPermissions: action.payload
      }
    }
    case SET_BRAND_ID: {
      return{
        ...state,
        brand_id: action.payload
      }
    }
    case SET_ORG_ID: {
      return{
        ...state,
        org_id: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
