import React, { Dispatch, FC,useCallback,useEffect,useMemo,useRef,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExportState,AuthState } from "../../../../store/types";
import { IMAGE_BASE_URL, WEBSOCKET_ENDPOINT } from "../../../../utilities/paths";
import { cancelHyperProject, getHyperProject, setProjectsForReadyCheck } from "../../../../store/actions";
import { HyperProjectFoldersState } from "../../../../store/types";
import useWebSocket from "../../hooks/useWebSocket";
import "../index.scss";
import iconComplete from '../../../../assets/svg/iconComplete.svg'
import { Collapse, CollapsePanel } from "pyxis-ui-kit";
import { CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { enableEdit } from '../../../../utilities/contants';
import { openToast } from "../../../../Toasts";

let timer:any;
const ProjectItem:FC<{
    data: any;
    index: number;
    handleUpdate: Function;
    isEditEnabled:boolean
}> = ({data,index,handleUpdate,isEditEnabled}) => {
    const token = useSelector((state: {authentication: AuthState})=>(state.authentication.token)); 
    const [client,setClientActions] = useWebSocket(`${WEBSOCKET_ENDPOINT}/hp-project/${data.id}/ready-update?token=${token}`,!(data.ready_status === "COMPLETED" || data.ready_status === "FAILED"));
    const dispatch = useDispatch();

    useEffect(()=>{
        setClientActions((val:any)=>({
            ...val,
            onError:(err:any)=>{console.error('error','Not able to connect!')},
            onRecieve:(data:any)=>{
                console.log(data,"data")
                data['rendered_file'] = IMAGE_BASE_URL + data['rendered_file'];
                handleUpdate(index,data)
                if(data.ready_status === "COMPLETED"||data.ready_status === "FAILED"&&client){
                    client.close();
                }
            }
        }))

        if(data.ready_status === "NOT_STARTED" && !data.ready_failure_reason){
            setTimeout(()=>{
                dispatch(getHyperProject({projectId: data.id,with_default_json: false},(res:any,err:boolean)=>{
                    const { data } = res;
                    if(data.ready_status === "FAILED"||data.ready_status === "COMPLETED"){
                        data['rendered_file'] = IMAGE_BASE_URL + data['rendered_file'];
                        if(client){ client.close() }
                        handleUpdate(index,data);
                    }
                }))
            },5000)
        }

        return ()=>{
            if(client) { client.close() }
        }
    },[])

    useEffect(()=>{
        if(data.status === "COMPLETED"||data.status === "FAILED" && client){
            client.close()
        }
    },[data.status])

    return <div className="item">
        <div className="detail">
            <div className="about">
                <span className="thumbnail">
                    <img src={data.rendered_file} />
                </span>
                <div className="flex-column">
                    {data.title ?<p className="name">{data.title}</p>
                        :<p className="name">{data.ready_status === 'FAILED'? 'Unable to Setup': 'getting ready...'}</p>}
                    {data.ready_progress === 100 ? <span>
                        {data.ready_status !== "FAILED" && <a 
                        onClick={(e)=>{if(!isEditEnabled){ openToast('warn','You don\'t have access to edit.'); e.stopPropagation(); e.preventDefault();}}}
                        href={`${((data.type === "image_set" || data.type === "variant_set") ? `#/cocreate/creative-automation/select/${data.id}`:
                            `#/cocreate/creative-automation/editor/${data.id}`)}`}>
                                <span className={!isEditEnabled? "permission-denied-lock": ""}>View</span>
                                </a>}
                    </span>:
                        <span className="progress">{data.ready_status === "FAILED"? "Failed": data.ready_progress+"%"}</span>
                    }
                </div>
            </div>
            {data.ready_status === "FAILED" && <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#D32F2F"/>
                </svg>
            </span>}
            {data.ready_progress === 100 && data.ready_status === "COMPLETED" && <span>
                <img src={iconComplete}/>
            </span>}
        </div>
    </div>
}

const CollapseHeader: FC<{
    isMinimized: boolean,
    dispatch:Dispatch<any>,
    projects:any
  }> = ({isMinimized,dispatch,projects}) => {
    return <div>
        <div className="header_container">
            <span className="header">Creative automation</span>
            <span className="actions">
                {isMinimized?<span className="minimize">Maximize <PlusCircleOutlined className="icon"/></span>
                :<span className="minimize">Minimize <MinusCircleOutlined className="icon"/></span>}
                <span className="minimize close" onClick={()=>{
                    dispatch(setProjectsForReadyCheck([]))
                    dispatch(cancelHyperProject())
                }}>Close<CloseCircleOutlined className="icon"/></span>
            </span>
        </div>
    </div>
  }
  

const HyperPersonalizeReadyPopUp: FC = () => {
    const { projectsForReadyCheck } = useSelector((state: {hyperProject:HyperProjectFoldersState})=>(state.hyperProject));
    const [isMinimized,setIsMinimized] = useState(false);
    const downloadListRef = useRef(null);
    const dispatch = useDispatch();
    const authState = useSelector((state: {authentication:AuthState})=>state.authentication);
    const { userData } = authState;
    const isEditEnabled:boolean = enableEdit.some((val:string)=>(userData?.emailId.includes(val)));
    const { isLoggedIn } = useSelector((state: { authentication: AuthState }) => state.authentication ? state.authentication : { isLoggedIn: null });


    const handleUpdate = useCallback((index:number,updatedData:any)=>{
        projectsForReadyCheck[index] = updatedData;
        // checkForPopUpClose(projectsForReadyCheck);
        dispatch(setProjectsForReadyCheck(projectsForReadyCheck))
    },[projectsForReadyCheck])

    const checkForPopUpClose = useCallback((projects:any[])=>{
        if(projects.length > 0 && !isMinimized){
            const completedCount:number = projects.reduce((acc:any,val:any)=>{
                if(val.ready_status === "COMPLETED"){
                    return acc+1;
                }
                return acc;
            },0)
            
            if(completedCount === projects.length){
                timer =  setTimeout(()=>dispatch(setProjectsForReadyCheck([])),5000)
            }
            return completedCount === projects.length
        }
    },[isMinimized])

    useEffect(()=>{
        if(isMinimized && timer){
            clearTimeout(timer);
        }else if(!isMinimized && checkForPopUpClose(projectsForReadyCheck)){
            timer = setTimeout(()=>dispatch(setProjectsForReadyCheck([])),5000)
        }
    },[projectsForReadyCheck,isMinimized])

    useEffect(()=>{
        let node:any = downloadListRef?.current;
        if(timer){
            clearTimeout(timer);
        }
        if(isMinimized){
            setIsMinimized(false)
        }
        if(node){
            node.scrollIntoView({ behavior: "smooth" })
        }
    },[projectsForReadyCheck])

    // onMouseEnter={()=>{
    //     if(timer){
    //         clearTimeout(timer);
    //     }
    // }}
    // onMouseLeave={()=>{
    //     if(timer){
    //         clearTimeout(timer);
    //     }
    //     if(!isMinimized)
    //     timer = setTimeout(()=>dispatch(setExportedProjects([])),5000)
    // }}
    return (projectsForReadyCheck.length > 0 && isLoggedIn?
        // collapse is minimized and maximized also from activekey
        <Collapse activeKey={[isMinimized?'0':'1']} onChange={() => setIsMinimized(prev => !prev)}>
            <CollapsePanel 
                header={<CollapseHeader 
                        isMinimized={isMinimized} 
                        dispatch={dispatch}
                        projects={projectsForReadyCheck}
                        />}
                key="1"
                showArrow={false}
                >
            {projectsForReadyCheck.map((item: any, index: number) => (
                <ProjectItem key={item.id} data={item} handleUpdate={handleUpdate} index={index} isEditEnabled={isEditEnabled}/>
            ))}
             <div style={{ float:"left", clear: "both" }}
                ref={downloadListRef}>
            </div>
            </CollapsePanel>
        </Collapse>
    :null);
};

export default HyperPersonalizeReadyPopUp;
