import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthState } from '../../store/types';
import DownloadPopUp from '../shared/DownloadUploadPopUp/DownloadPopUp';
import HyperPersonalizeReadyPopUp from '../shared/DownloadUploadPopUp/HyperProjectsPopUp/index';
import Modal from '../shared/Modal';
// import { TutorialPopUp } from '../shared/TutorialPopUp';
import UploadedDesignsPopUp from '../shared/UploadedDesignsPopUp';

// root level modals
const ProgressiveModals:React.FC = () => {
  const history = useHistory();
  const isPublicLink = history.location.pathname.startsWith('/cocreate/public');
  const token = useSelector((state: { authentication: AuthState }) => state.authentication.token);
  const publicLink = useSelector((state: { publicLink: any }) => state.publicLink);
  
  if (!token && !isPublicLink && publicLink.token) {
    return null;
  }

  return <Modal>
    {/* <div className='action-popups-container left-side'>
      <TutorialPopUp />
    </div> */}
    <div className='action-popups-container right-side'>
      {/* we are using three different component because we need three different popup */}
      <DownloadPopUp />
      {token && <>
        <HyperPersonalizeReadyPopUp />
        <UploadedDesignsPopUp />
      </>}
    </div>
  </Modal>
}

export default ProgressiveModals;