import { GENERATE_COMBINATIONS, GET_ASSET_DATA_BULK, GET_ASSET_COMBINATION_DATA, UPDATE_ASSET_COMBINATION_DATA, UPDATE_ASSET_DATA, ADD_ASSETS, DELETE_ASSET, UPDATE_HYPER_PROJECT_DETAILS, RENAME_HYPER_LAYER,UPDATE_ALL_ASSETS_READY_STATUS } from './../types/hyperPersonalize';
import { UPDATE_HYPER_DETAILS, LOADING_HYPER, LOADING_HYPER_OFF, GET_LAYER_ASSETS, GET_HYPER_PROJECT, GET_ASSET_DATA, UPDATE_PROJECT_HYPER } from '../types';

export const loadingHyper = () => ({
  type: LOADING_HYPER,
});

export const loadingHyperOff = () => ({
  type: LOADING_HYPER_OFF,
});

export const getHyperProject = (params:any, callback:any) => ({
  type: GET_HYPER_PROJECT,
  callback,
  params,
});

export const getAsset = (params:any, callback:any) => ({
  type: GET_ASSET_DATA,
  callback,
  params,
});

export const renameHyperLayer = (params:any, callback:any) => ({
  type: RENAME_HYPER_LAYER,
  callback,
  params,
});

export const getAssetsBulk = (params:any, callback:any) => ({
  type: GET_ASSET_DATA_BULK,
  callback,
  params,
});

export const updateAsset = (params:any, callback:any) => ({
  type: UPDATE_ASSET_DATA,
  callback,
  params,
});

export const addAssets = (params:any, callback:any) => ({
  type: ADD_ASSETS,
  callback,
  params,
});

export const deleteAsset = (params:any, callback:any) => ({
  type: DELETE_ASSET,
  callback,
  params,
});

export const updateProjectHyper = (params:any, callback:any) => ({
  type: UPDATE_PROJECT_HYPER,
  callback,
  params,
});

export const updateHyperDetails = (payload:any) => ({
  type: UPDATE_HYPER_DETAILS,
  payload,
});

export const updateHyperProjectDetails = (params:any, callback:any) => ({
  type: UPDATE_HYPER_PROJECT_DETAILS,
  callback,
  params,
});

export const getAssetsCombination = (params:any, callback:any) => ({
  type: GET_ASSET_COMBINATION_DATA,
  callback,
  params,
});

export const updateAssetsCombination = (params:any, callback:any) => ({
  type: UPDATE_ASSET_COMBINATION_DATA,
  callback,
  params,
});

export const getLayerAssets = (params:any, callback:any) => ({
  type: GET_LAYER_ASSETS,
  callback,
  params,
});

export const generateCombinations = (params:any, callback:any) => ({
  type: GENERATE_COMBINATIONS,
  callback,
  params,
});

export const updateAllAssetsReadyStatus = (payload:boolean) => ({
  type: UPDATE_ALL_ASSETS_READY_STATUS,
  payload
})