export const CREATE_HYPER_PROJECT = 'CREATE_HYPER_PROJECT';
export const CANCEL_HYPER_PROJECT = 'CANCEL_HYPER_PROJECT';
export const DELETE_HYPER_PROJECTS = 'DELETE_HYPER_PROJECTS';
export const MOVE_HYPER_PROJECTS = 'MOVE_HYPER_PROJECTS';
export const DELETE_ALL_HYPER_PROJECTS = 'DELETE_ALL_HYPER_PROJECTS';
export const FETCH_LAYERS_WITH_ASSETS = 'FETCH_LAYERS_WITH_ASSETS';
export const SET_HYPERPROJECT_DATA = 'SET_HYPERPROJECT_DATA';
export const SET_SELECTED_HYPER_SIZES = 'SET_SELECTED_HYPER_SIZES';
export const ADD_SIZES_TO_HPPROJECT = 'ADD_SIZES_TO_HPPROJECT';
export const DELETE_SIZES_FROM_HPPROJECT = 'DELETE_SIZES_FROM_HPPROJECT';
export const SET_LAYERS_WITH_ASSETS = 'SET_LAYERS_WITH_ASSETS';
export const ADD_LAYER_ASSETS = 'ADD_LAYER_ASSETS';
export const DELETE_LAYER_ASSET = 'DELETE_LAYER_ASSET';
export const FETCH_HYPER_RENDERED_IMAGES = 'FETCH_HYPER_RENDERED_IMAGES';
export const SET_SELECTED_HPPROJECTS = 'SET_SELECTED_HPPROJECTS';
export const SET_SELECTED_SIZE_ID = 'SET_SELECTED_SIZE_ID';
export const SET_SELECTED_IMAGE_LAYER_ID = 'SET_SELECTED_IMAGE_LAYER_ID';
export const SET_SELECTED_TEXT_LAYER_ID = 'SET_SELECTED_TEXT_LAYER_ID';
export const SET_SELECTED_IMAGE_ID = 'SET_SELECTED_IMAGE_ID';
export const SET_SELECTED_TEXT_ID = 'SET_SELECTED_TEXT_ID';
export const SET_SELECTED_LAYER_ID = 'SET_SELECTED_LAYER_ID';
export const SET_SELECTED_ASSET_ID = 'SET_SELECTED_ASSET_ID';
export const DELETE_HYPER_RENDERED_IMAGES = 'DELETE_HYPER_RENDERED_IMAGES';
export const SET_PROJECTS_FOR_READY_CHECK = 'SET_PROJECTS_FOR_READY_CHECK';

export type HyperProjectFoldersState = {
    hyperProjectData: any;
    selectedHyperSizes: number[];
    layersWithAssets: any;
    selectedHyperProjects: number[];
    selectedSizeId: number[]|null;
    selectedImageLayerId: number|null;
    selectedTextLayerId: number|null;
    selectedImageId: any;
    selectedTextId:any;
    selectedLayerId: number|null;
    selectedAssetId: number|null;
    projectsForReadyCheck: any;
}

export type CreateHyperProject = {
    type: typeof CREATE_HYPER_PROJECT,
    payload: {
        project_id: number;
        title: string;
        description: string;
    },
    callback?:Function
}

export type CancelHyperProject = {
    type: typeof CANCEL_HYPER_PROJECT
}

export type DeleteHyperProjects = {
    type: typeof DELETE_HYPER_PROJECTS,
    payload: number|number[],
    callback?:Function
}

export type DeleteAllHyperProjects = {
    type: typeof DELETE_ALL_HYPER_PROJECTS,
    callback?:Function
}

export type MoveHyperProjects ={
    type: typeof MOVE_HYPER_PROJECTS,
    payload: {
        hpproject_ids: number[],
        destination_folder_id: number
    },
    callback?:Function
}

export type SetHyperProjectData = {
    type: typeof SET_HYPERPROJECT_DATA,
    payload: any;
}

export type SetSelectedHyperSizes = {
    type: typeof SET_SELECTED_HYPER_SIZES,
    payload: number[]
}

export type AddSizesToHpproject = {
    type: typeof ADD_SIZES_TO_HPPROJECT,
    payload: {
        project_id: number;
        size_ids: number[];
    },
    callback?:Function
}

export type DeleteSizesFromHpproject = {
    type: typeof DELETE_SIZES_FROM_HPPROJECT,
    payload: {
        project_id: number;
        size_ids: number[];
    },
    callback?:Function
}

export type FetchLayersWithAssets = {
    type: typeof FETCH_LAYERS_WITH_ASSETS,
    payload: {
        project_id:number;
        page:number;
        page_size:number;
    },
    callback?:Function
}

export type SetLayersWithAssets = {
    type: typeof SET_LAYERS_WITH_ASSETS,
    payload: any
}

export type AddLayerAssets = {
    type: typeof ADD_LAYER_ASSETS,
    payload: {
        project_id: number;
        layer_id: number;
        assets: any[]
    },
    callback?:Function
}

export type DeleteLayerAsset = {
    type: typeof DELETE_LAYER_ASSET,
    payload: {
        project_id: number;
        layer_id: number;
        asset_id: number;
    },
    callback?:Function
}

export type FetchHyperRenderedImages = {
    type: typeof FETCH_HYPER_RENDERED_IMAGES,
    payload: {
        project_id:number;
        page: number;
        page_size: number;
        size_id?: number[];
        text_layer_asset_id?: any;
        image_layer_asset_id?: any;
        layer_asset_id?: number;
    },
    callback?:Function
}

export type SetSelectedHpProjects = {
    type: typeof SET_SELECTED_HPPROJECTS,
    payload: number[]
}

export type SetSelectedSizeId = {
    type: typeof SET_SELECTED_SIZE_ID,
    payload: number[]|null
}

export type SetSelectedLayerId = {
    type: typeof SET_SELECTED_LAYER_ID,
    payload: number|null
}

export type SetSelectedAssetId = {
    type: typeof SET_SELECTED_ASSET_ID,
    payload: number|null
}


export type SetSelectedImageLayerId = {
    type: typeof SET_SELECTED_IMAGE_LAYER_ID,
    payload: number|null
}

export type SetSelectedTextLayerId = {
    type: typeof SET_SELECTED_TEXT_LAYER_ID,
    payload: number|null
}

export type SetSelectedImageId = {
    type: typeof SET_SELECTED_IMAGE_ID,
    payload: any
}

export type SetSelectedTextId = {
    type: typeof SET_SELECTED_TEXT_ID,
    payload: any
}

export type DeleteHyperRenderedImages = {
    type: typeof DELETE_HYPER_RENDERED_IMAGES,
    payload: {
        project_id: number;
        ids: number[]
    },
    callback?:Function
}

export type SetProjectsForReadyCheck = {
    type: typeof SET_PROJECTS_FOR_READY_CHECK,
    payload: any
}