import { isObjectScalable } from "./helpers/isScalable";
import { resizeObjectSimple } from "./resize/simpleResize";
import { getObjectWidth, getObjectHeight, getObjectSize } from "./helpers/getObjectSize";
import { resizeObj } from "./helpers/resizeObj";
import { CLASSIFICATION_TYPES } from "./classification/constants";

export const isBackgroundObj = (obj: any, canvasWidth: number, canvasHeight: number) => {
  if (obj?.classificationType === CLASSIFICATION_TYPES.BACKGROUND) {
    return true;
  }

  const objVisibleSquare = (getObjectWidth(obj) - Math.abs(obj.left)) * (getObjectHeight(obj) - Math.abs(obj.top));
  const canvasSquare = canvasWidth * canvasHeight;

  return (objVisibleSquare / canvasSquare) * 100 >= 90;
};

export const getUpdatedCanvasBackgroundNew = (obj: any, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  const isScalable = isObjectScalable(obj);
  if (isScalable) {
    return resizeObjectSimple(obj, prevWidth, prevHeight, canvasWidth, canvasHeight);
  }
  const maxSide = Math.max(canvasWidth, canvasHeight);
  const [width, height] = getObjectSize(obj)
  const ratioX = maxSide / width
  const ratioY = maxSide / height

  const maxRatio = Math.max(ratioX, ratioY);
  const resizedBackground = resizeObj(obj, maxRatio, maxRatio)
  const [resizedWidth, resizedHeight] = getObjectSize(resizedBackground);

  const top = (resizedHeight - canvasHeight) / -2;
  const left = (resizedWidth - canvasWidth) / -2;

  return {
    ...resizedBackground,
    top,
    left,
  };
};

export const resizeAllExistBackgrounds = (objList: Array<any>, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  return objList.map(back => getUpdatedCanvasBackgroundNew(back, prevWidth, prevHeight, canvasWidth, canvasHeight))
}
