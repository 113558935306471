const timeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
} as Intl.DateTimeFormatOptions;
const defaultLocale = 'ban';
export const getFormattedTime = (dateString?: string | null) =>
    dateString ? new Date(dateString).toLocaleTimeString(defaultLocale, timeFormat) : 'Not Available';
const dateFormat = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
} as Intl.DateTimeFormatOptions;
export const getFormattedDate = (dateString: string | null) => {
    console.log("toLocaleTimeString", new Date(dateString)
        .toLocaleTimeString(defaultLocale, dateFormat))
    return dateString
        ? new Date(dateString)
            .toLocaleTimeString(defaultLocale, dateFormat)
            .split(', ')
            .filter(Boolean)[0]
            .split(' ')
            .join(' ')
        : 'Not Available';
}