import React, { useState, useRef, useCallback } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData, updateProfile } from '../../store/actions';
import { getObjectDiff, isValidEmail, isValidName, isValidPhone, isValidPhoneNo } from '../../utilities/common-function';
import { openToast } from '../../Toasts';
import CreatePasswordPopUp from './CreatePasswordPopUp';
import UploadImagePopUp from './UploadImagePopUp';
import Modal from '../shared/Modal';
import { AuthState } from '../../store/types';
import Avatar from '../shared/Avatar';
import { Row, Col, Button, Input } from 'pyxis-ui-kit';
import AddImageIcon from "../../assets/svg/iconAddImage.svg";
import ImagePlaceholder from "../../assets/svg/iconImagePlaceholder.svg";
import SuccessDrawer from "../shared/CommonSuccessWarnDrawer";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const ViewProfile:React.FunctionComponent = () => {
    const [loading, setLoader] = useState(false);
    const [passwordVisible, setPasswordVisibility] = useState(false);
    const dispatch = useDispatch();

    const { userData } = useSelector((state:{authentication:AuthState})=>({...state.authentication}));
    const focusRef = useRef(null);
    const [showCreatePasswordPopUp, setShowCreatePasswordPopUp] = useState(false);
    const [showUploadImagePopUp, setShowUploadImagePopUp] = useState(false);
    const [firstName, setFirstName] = useState(userData?.firstName || '');
    const [lastName, setLastName] = useState(userData?.lastName || '');
    const [email, setEmail] = useState(userData?.emailId || '');
    const [phone, setPhone] = useState(userData?.phoneNumber || '');
    const [password, setPassword] = useState('password');
    const [editing, setEditing] = useState(false);
    const [isFormDirty, setFormDirty] = useState(false);
    const [completionPopUpState, setCompletionPopUpState] = useState<any>(null);

    const updateProfileInfo = useCallback((updatedData:any, callback?:Function) => {
        setLoader(true);
        dispatch(updateProfile(updatedData, (res:any, err:boolean) => {
            setLoader(false);
            if(!err) {
                callback && callback(res,false);
            }else{
                callback && callback(res,true);
            }
        }))
    },[])

    const handleSave = useCallback(() => {
        const prevUserData:any = {
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            phoneNumber: userData?.phoneNumber||""
        }
        const stateUserData:any = {
            firstName,
            lastName,
            phoneNumber: phone
        }
        const updatedKeys:string[] = getObjectDiff(prevUserData,stateUserData);

        if(updatedKeys.length === 0) {
            openToast('info','Please make some changes before saving');
            return;
        }

        let updatedData:any = {};
        updatedKeys.forEach((key:string)=>{
            updatedData[key] = stateUserData[key];
        })

        if(updatedKeys.includes('lastName') && !isValidName(updatedData['lastName'])){
            openToast('warn','Name should not contain special characters or number and should have minimum 1 character!')
            return;
        }
        
        if(updatedKeys.includes('firstName') && !isValidName(updatedData['firstName'])){
            openToast('warn','Name should not contain special characters or number and should have minimum 1 character!')
            return;
        }
        if(updatedKeys.includes('phoneNumber') && updatedData['phoneNumber'] === undefined){
            updatedData['phoneNumber'] = "";
        }
        if(updatedKeys.includes('phoneNumber') && updatedData['phoneNumber'] !== "" && !isValidPhoneNo(updatedData['phoneNumber'])){
            openToast('warn','Phone number is invalid')
            return;
        }
        
        updateProfileInfo(updatedData, (res:any,err:boolean) => {
            if(!err){
                setCompletionPopUpState({
                    ...completionPopUpState,
                    isOpen: true, 
                    type: 0, 
                    title: `Great! Profile changed successfully`,
                    subText:'',
                    cancelButtonText: "Close",
                    cancelButtonFunction: () => {
                        setEditing(false);
                        setCompletionPopUpState(null);
                        dispatch(setUserData({...userData,...res.data}))
                    }
                  });
                openToast('success', 'Details updated successfully');
            }else{
                openToast('error',res.data.message||'Unable to update Info!')
            }
        })
    },[userData,firstName, lastName, password, phone])

    const handleCancel = useCallback(() => {
        setEditing(false);
        setFirstName(userData ? userData.firstName : '');
        setLastName(userData ? userData.lastName : '');
        setEmail(userData ? userData.emailId : '');
        setPhone(userData?.phoneNumber || '');
        setPassword('password');
    },[]);

    const handleEdit = useCallback(() => {
        setEditing(true);
        const node:any = focusRef?.current;
        if(node) node.focus();
    },[]);

    const handlePasswordSubmit = useCallback(({newPassword, oldPassword}) => {
        updateProfileInfo({ newPassword, confirmPassword: newPassword, currentPassword: oldPassword }, (res:any,err:boolean)=>{
            if(!err){
                setCompletionPopUpState({
                    ...completionPopUpState,
                    isOpen: true, 
                    type: 0, 
                    title: `Great! Password changed successfully`,
                    subText:'',
                    cancelButtonText: "Close",
                    cancelButtonFunction: () => {
                        setShowCreatePasswordPopUp(false);
                        setCompletionPopUpState(null);
                        dispatch(setUserData({...userData,...res.data}));
                    }
                  });
                openToast('success', 'Password updated successfully'); 
            } else {
                if(res.data.message==="Authenitcation failed"){
                    res.data.message = 'Current password is incorrect';
                }
                res.data.message = res.data.message;
            }
        })
    },[])

    const handleProfileImageUpload = useCallback((file:File,setImgLoader:Function)=>{ 
        updateProfileInfo({ profilePicture: file }, (res:any,err:boolean)=>{
            if(!err) {
                openToast('success', 'Profile Picture updated successfully');
                dispatch(setUserData({...userData,...res.data}))
                setShowUploadImagePopUp(false);
            }
        })
    },[])

    return <div className="container-profile">
        <div className="user-header">
            <div className="profile-banner">
                <span>
                    <p>Profile information</p>
                    {/* <p>Add your personal information here. </p> */}
                </span>
            </div>
            <div className="profile-content">
            <Row>
                <Col span={24}>
                    <Row>
                        <Col className="profile-avatar" span={6}>
                            <div>
                                {userData && userData.profilePicture && <Avatar 
                                    size="x-large"
                                    initials={userData? userData.firstName[0]:""} 
                                    showInitials={!!(userData && !userData.profilePicture)}
                                    backgroundColor={userData?.profileColour}
                                    src={userData?userData.profilePicture:""} />}
                                {userData && !userData.profilePicture && <span><img src={ImagePlaceholder} alt="img"/></span>}
                                <span className="edit-icon" onClick={()=>setShowUploadImagePopUp(true)}>
                                    <img src={AddImageIcon} alt="add"/>
                                </span>
                            </div>
                        </Col>
                        <Col className="profile-information" span={18}>
                            <Row>
                                <Col span={12}>
                                    <div className="form-field">
                                        <div>
                                            <label htmlFor="name">{ editing ? "First name" : 'First and last name' }</label>
                                            { !editing && <div className="profile-name">
                                                { userData?.firstName } { userData?.lastName}
                                            </div>}
                                        </div>
                                        { editing && <div>
                                            <Input 
                                                ref={focusRef}
                                                type="text" 
                                                className={!editing ? "input-disabled" : ""}
                                                value={editing ? firstName : userData?.firstName} 
                                                readOnly={!editing}
                                                maxLength={30}
                                                onChange={(e:any)=>{setFormDirty(true); setFirstName(e.target.value)}}
                                                onPressEnter={(e:any)=>{setFormDirty(true); setFirstName(e.target.value); handleSave();}} />
                                        </div>}
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-field">
                                        <div>
                                            <label htmlFor="emailId">Email</label>
                                        </div>
                                        <div className="profile-email">
                                            <Input 
                                                type="email" 
                                                className={!editing ? "input-disabled" : ""}
                                                value={userData?.emailId}
                                                readOnly={true} 
                                                onChange={(e:any)=>{setFormDirty(true); setEmail(e.target.value)}}
                                                style={{border: 'none', cursor: 'auto', background: 'transparent', padding: '0'}} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    { !editing && <div className="form-field">
                                        <div>
                                            <label htmlFor="phone">Phone number</label>
                                        </div>
                                        <div className="profile-phone">
                                            {editing ? <PhoneInput
                                                defaultCountry="IN"
                                                placeholder="Enter phone number"
                                                value={phone}
                                                onChange={(val)=>{setFormDirty(true); setPhone(val)}}
                                                readOnly={!editing}
                                            /> : <Input 
                                            type="text" 
                                            className={!editing ? phone? "input-disabled" : "input-disabled empty-phone" : ""}
                                            value={phone}
                                            placeholder="Enter phone number"
                                            readOnly={!editing}
                                            maxLength={10}
                                            onChange={(e:any)=>{setFormDirty(true); setPhone(e.target.value)}} />}
                                        </div> 
                                    </div>}
                                    {
                                        editing && <div>
                                            <div>
                                                <label>Last name</label>
                                            </div>
                                            <div>
                                                <Input 
                                                    type="text" 
                                                    className={!editing ? "input-disabled" : ""}
                                                    value={editing ? lastName : userData?.lastName}
                                                    readOnly={!editing}
                                                    maxLength={30}
                                                    onChange={(e:any)=>{setFormDirty(true); setLastName(e.target.value)}}
                                                    onPressEnter={(e:any)=>{setFormDirty(true); setLastName(e.target.value); handleSave();}} />
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col span={12}>
                                    { editing && <div className="form-field">
                                            <div>
                                                <label htmlFor="phone">Phone number</label>
                                            </div>
                                            <div className="profile-phone">
                                                {editing ? <PhoneInput
                                                    defaultCountry="IN"
                                                    placeholder="Enter phone number"
                                                    value={phone}
                                                    onChange={(val)=>{setFormDirty(true); setPhone(val)}}
                                                    readOnly={!editing}
                                                /> : <Input 
                                                type="text" 
                                                className={!editing ? "input-disabled" : ""}
                                                value={phone}
                                                placeholder="eg 9999999999"
                                                readOnly={!editing}
                                                maxLength={10}
                                                onChange={(e:any)=>{setFormDirty(true); setPhone(e.target.value)}}
                                                onPressEnter={(e:any)=>{setFormDirty(true); setPhone(e.target.value); handleSave();}} />}
                                            </div> 
                                        </div>}
                                </Col>
                            </Row>
                        </Col>   
                    </Row>   
                </Col>
            </Row>
            </div>
        </div>
        <div className="profile-footer">
            {!editing && <div className="change-password-wrapper">
                <Button 
                    className="change-password-button" 
                    type="link"
                    onClick={()=>setShowCreatePasswordPopUp(true)}>
                    Change Password
                </Button>
            </div>}
            <div className="edit-wrapper">
                {editing ? 
                    <div className="save-edit">
                        <Button className="secondary" onClick={()=>handleCancel()}>
                            Cancel
                        </Button>
                        <Button 
                            className="primary"
                            type="primary"
                            onClick={()=>handleSave()} 
                            disabled={!firstName || !lastName || loading}>
                                {loading&&<span><FontAwesomeIcon icon={faSpinner} spin /></span>}
                            Save
                        </Button>
                    </div>
                    :
                    <Button type="primary" className="edit" onClick={()=>handleEdit()}>
                        Edit Profile
                    </Button>
                }
            </div>
        </div>
        {showCreatePasswordPopUp && <Modal>
            <CreatePasswordPopUp onCancel={()=>setShowCreatePasswordPopUp(false)} 
                onSubmit={handlePasswordSubmit} />
        </Modal>}
        {showUploadImagePopUp && <Modal>
            <UploadImagePopUp onCancel={()=>setShowUploadImagePopUp(false)} 
                onSubmit={handleProfileImageUpload} setShowUploadImagePopUp={setShowUploadImagePopUp}  />
        </Modal>}
        {completionPopUpState && completionPopUpState.isOpen && <SuccessDrawer {...completionPopUpState} handleClose={()=> setCompletionPopUpState(null)}/>}
    </div>
}

export default ViewProfile;