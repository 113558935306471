import { ExportProject, EXPORT_PROJECT, SetExportedProjects, SET_EXPORTED_PROJECTS, ExportMultiple, EXPORT_MULTIPLE } from "../types/export";

export const exportProject = (
    payload: {
        project_type: string;
        projects?: number[];
        hpprojects?: number[];
        output_format: string;
        quality?: number;
        filters?: {
            dc_ids?: number[];
            size_ids?: number[];
        }
    },
    callback?: Function
): ExportProject => ({
    type: EXPORT_PROJECT,
    payload,
    callback
})

export const exportMultiple = (
    payload: {
        project_type: string,
        output_format: string,
        quality: string,
        projects: number[],
        folders: number[],
    },
    callback?: Function
): ExportMultiple => ({
    type: EXPORT_MULTIPLE,
    payload,
    callback
})

export const setExportedProjects = (payload: any[]): SetExportedProjects => ({
    type: SET_EXPORTED_PROJECTS,
    payload
})