import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import CreateContextAiquireProvider from '../../CreateContextAiquire/CreateContextAiquire';
import { setActiveFolderCreate } from '../../store/actions';

export default function AiquireCreate({ userPermissions }:any) {
  const AiquireCAChooseDesign = lazy(() => import('../../components/CreativeAutomationOnboarding/ChooseDesign'))
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {dispatch(setActiveFolderCreate({ folder: 0 }))};
  });
  
  return userPermissions.includes('create-new-banners') ? (
    <CreateContextAiquireProvider>
      <Suspense fallback={<FullScreenSpinner />}>
        <AiquireCAChooseDesign type="project" />
      </Suspense>
    </CreateContextAiquireProvider>
  ) : (
    <div className="aiquire-create-permission-container">
      <PermissionNotAllowed title="You do not have access to this feature as per your role" />
    </div>
  )
}