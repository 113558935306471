export const EXPORT_PROJECT = "EXPORT_PROJECT";
export const SET_EXPORTED_PROJECTS = "SET_EXPORTED_PROJECTS";
export const EXPORT_MULTIPLE = "EXPORT_MULTIPLE"
export type ExportState = {
    exportedProjects: any
}

export type ExportProject = {
    type: typeof EXPORT_PROJECT,
    payload: {
        project_type: string,
        projects?: number[],
        hpprojects?: number[],
        output_format: string,
        quality?: number,
        filters?: {
            dc_ids?: number[];
            size_ids?: number[];
        }
    },
    callback?: Function
}

export type ExportMultiple = {
    type: typeof EXPORT_MULTIPLE,
    payload: {
        project_type: string,
        output_format: string,
        quality: string,
        projects: number[],
        folders: number[],
    },
    callback?: Function

}

export type SetExportedProjects = {
    type: typeof SET_EXPORTED_PROJECTS,
    payload: any
}