import React,{useState, useEffect, useRef} from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button, Input } from 'pyxis-ui-kit';

const PromptPopUp: React.FC<{
    isUpdate: boolean;
    isVleRequired?:boolean;
    handleClose: Function;
    onSubmit: Function;
    title: string;
    text: string;
    placeholder: string;
    submitBtnText?: string;
    inputText: string;
}> = ({
    isUpdate,
    isVleRequired = false,
    handleClose,
    onSubmit,
    title,
    text,
    placeholder,
    inputText,
    submitBtnText = 'Submit'
}) =>{
    const [value,setValue] = useState(inputText?inputText: "");
    const [vleValue,setvleValue] = useState(inputText?inputText: "");
    const [loading,setLoading] = useState(false);
    
    useEffect(()=>{
        setValue(inputText);
    },[inputText]) 

    return <div className="popup-overlay">
        <div className="addfolder-popup">
            <div className="title">
                <h3>{title}</h3>
            </div>

            {isVleRequired && 
                <div className="content">
                    <p>Please enter your VLE Code</p>
                    <Input type="text" autoFocus placeholder={'Enter VLE code'} value={vleValue}
                        onChange={(e:any)=>setvleValue(e.target.value)}
                    />
                </div>
            }

            <div className="content">
                <p>{text}</p>
                <Input type="text" autoFocus placeholder={placeholder} value={value}
                    onChange={(e:any)=>setValue(e.target.value)}
                    onKeyPress={(e:any)=>{
                        if(e.key.toLowerCase() === 'enter' && onSubmit) onSubmit({value:value,vle_code:vleValue},setLoading);
                    }}
                />
            </div>
            <div className="footer">
                <Button type="link" onClick={(e:any)=>{e.stopPropagation(); handleClose();}}>Cancel</Button>
                <Button type="primary" 
                    disabled={(isUpdate? (value === inputText || !!!value): !!!value)||loading}
                    onClick={(e:any)=>{
                        e.stopPropagation();
                        if(onSubmit) onSubmit({vle_code:vleValue,value:value},setLoading);
                    }}
                >
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                    {submitBtnText}
                </Button>
            </div>
        </div>
    </div>;
}

export default PromptPopUp;