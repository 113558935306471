import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import { Button } from "pyxis-ui-kit";

function ErrorDialog({ open, setOpen }) {
  const rTime = localStorage.getItem("aidesigner_prompts_error") || -1;
  const classes: any = useStyles();

  const handleClose = (value) => {
    setOpen(false);
  };

  const errorText = () => {
    switch (rTime) {
      case "FAILED":
        return (
          <div className='error-dialog-header'>
            <div className='error-dialog-title'>
              Our servers are currently experiencing high traffic.
            </div>
            <div className='error-dialog-sub-title'>
              Please try again in a while.
            </div>
          </div>
        );

      case "NOT RESPONDING":
        return (
          <div className='error-dialog-header'>
            <div className='error-dialog-title'>Server is not responding.</div>
            <div className='error-dialog-sub-title'>Kindly try again.</div>
          </div>
        );
      default:
        return (
          <div className='error-dialog-header'>
            <div className='error-dialog-title'>
              Our servers are busy at the moment
            </div>
            <div className='error-dialog-sub-title'>
              Please try again in {calculateAndFormatTimeRemaining(rTime)} mm:ss
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      fullWidth
      open={open}
      onClose={() => handleClose(false)}
    >
      <div className='wait-time'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='66'
          height='67'
          viewBox='0 0 66 67'
          fill='none'
        >
          <g opacity='0.2'>
            <path
              d='M33 1.5C50.6608 1.5 65 15.8391 65 33.4998C65 51.1606 50.6608 65.4997 33 65.4997C15.3392 65.4997 1 51.1606 1 33.4998C1 15.8391 15.3392 1.5 33 1.5Z'
              stroke='#8CB5E7'
              stroke-width='1.1728'
            />
          </g>
          <g opacity='0.896'>
            <path
              d='M32.9738 5.45996C48.4751 5.45996 61.0609 18.0457 61.0609 33.5469C61.0609 49.0481 48.4751 61.6339 32.9738 61.6339C17.4725 61.6339 4.88672 49.0481 4.88672 33.5469C4.88672 18.0457 17.4725 5.45996 32.9738 5.45996Z'
              fill='#F2A851'
            />
          </g>
          <path
            d='M33.003 29.2563C35.3417 29.2563 37.2405 31.1551 37.2405 33.4937C37.2405 35.8324 35.3417 37.7312 33.003 37.7312C30.6644 37.7312 28.7656 35.8324 28.7656 33.4937C28.7656 31.1551 30.6644 29.2563 33.003 29.2563Z'
            fill='white'
          />
          <path
            d='M41.918 42.647L33.1807 34.0632'
            stroke='white'
            stroke-width='6.85908'
            stroke-linecap='round'
          />
          <path
            d='M39.1098 23.1982L32.7946 33.7646'
            stroke='white'
            stroke-width='6.85908'
            stroke-linecap='round'
          />
        </svg>
      </div>
      {/* <div className='error-dialog-header'>
                <div className='error-dialog-title'>
                    Our servers are busy at the moment
                </div>
                <div className='error-dialog-sub-title'>
                    Please try again in {calculateAndFormatTimeRemaining(rTime)} mm:ss
                </div>
            </div> */}
      {errorText()}
      <Button
        btnsize='md'
        type='primary'
        className='primary submit'
        style={{ alignSelf: "center" }}
        onClick={() => setOpen(false)}
      >
        Go back
      </Button>
    </Dialog>
  );
}

export default ErrorDialog;

const useStyles = makeStyles({
  dialogContainer: {
    borderRadius: "20px", // Change the border radius value as per your requirement
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "28px",
    padding: "35px",
    maxWidth: "450px",
  },

  hoverRow: {
    "&:hover": {
      backgroundColor: "#EEEEFF",
    },
    height: "48px",
  },
  headerRow: {
    backgroundColor: "#FCFCFF",
    "& .MuiTableCell-root": {
      padding: "16px", // Adjust the padding as per your requirements
    },
  },
  deleteButton: {
    backgroundColor: "#FFF9F9",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    color: "#F16060",
    fontSize: "1.4rem",
    padding: "6px 12px",
    lineHeight: "1.43",
    height: "36px",
  },

  dialogContent: {
    padding: "3rem 2rem",
    fontSize: "16px",
    lineHeight: "135.02%",
    height: "320px",
    overflow: "scroll",
    margin: "3rem 3rem 2rem 3rem",
    background: "#FCFCFF",
    borderRadius: "12px",
  },
});

function calculateAndFormatTimeRemaining(dateString) {
  const gmtTime = new Date(dateString);
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime: any = new Date(gmtTime.getTime() + istOffset);
  const currentDate: any = new Date();
  const timeDifference = (istTime - currentDate) / 1000;

  const minutesRemaining = Math.floor(timeDifference / 60);
  const secondsRemaining = Math.floor(timeDifference % 60);

  const formattedTime = `${
    minutesRemaining < 10 ? "0" : ""
  }${minutesRemaining}:${secondsRemaining < 10 ? "0" : ""}${secondsRemaining}`;
  return formattedTime;
}
