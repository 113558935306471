import React, {  Suspense, FC } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import LibraryOnboarding from '../../components/LibraryOnboarding';
import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const AiquireLibraryOnboarding: FC<any> = () => {
  const SuspenseComponent = () => {
    return (
      <>
        <div className="library-onboarding-wrap">
            <LibraryOnboarding/>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-library-onboarding-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default AiquireLibraryOnboarding;

