import { fabric } from 'fabric';
if (new fabric.WebglFilterBackend()) {
  console.log(fabric.textureSize);
}

//@ts-ignore
fabric.Image.filters.Redify = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  type: 'Redify',

  /**
   * Fragment source for the redify program
   */
  fragmentSource: `precision highp float;
    uniform sampler2D uTexture;
    uniform float val;
    varying vec2 vTexCoord;
    void main() {
      gl_FragColor = vec4(0.3, 0.3, 0.3, 0.3);
    }`,

  /**
     * 
     * @param options  
     *  r = data[i + 0];
      g = data[i + 1];
      b = data[i + 2];

      data[i + 0] = Math.min(255, r * (0.393 + 0.607 * (1 - val)) + g * 0.769 * val + b * 0.189 * val);
      data[i + 1] = Math.min(255, r * 0.349 * val + g * (0.686 + 0.314 * (1 - val)) + b * 0.168 * val);
      data[i + 2] = Math.min(255, r * 0.272 * val + g * 0.534 * val + b * (0.131 + 0.869 * (1 - val)));
     */

  applyTo2d: function(options: any) {
    const imageData = options.imageData,
      data = imageData.data,
      nPixels = data.length;
    const val = 3;
    let i, r, g, b;
    for (i = 0; i < nPixels; i += 4) {
      r = data[i + 0];
      g = data[i + 1];
      b = data[i + 2];

      data[i + 0] = Math.min(255, r * (0.393 + 0.607 * (1 - val)) + g * 0.769 * val + b * 0.189 * val);
      data[i + 1] = Math.min(255, r * 0.349 * val + g * (0.686 + 0.314 * (1 - val)) + b * 0.168 * val);
      data[i + 2] = Math.min(255, r * 0.272 * val + g * 0.534 * val + b * (0.131 + 0.869 * (1 - val)));
    }
  },
});
// @ts-ignore
fabric.Image.filters.Redify.fromObject = fabric.Image.filters.BaseFilter.fromObject;

export const CUSTOM_FILTERS = {
  CUSTOMXPROCESS: 'CUSTOMXPROCESS',
};

export const FILTER_NAMES = {
  XPROCESS: 'XPROCESS',
  BLUR: 'Blur',
  SATURATION: 'Saturation',
  BRIGHTNESS: 'Brightness',
  CONTRAST: 'Contrast',
  TINT: 'Tint',
  HUE_ROTATION: 'HueRotation',
  GRAYSCALE: 'Grayscale',
  BLEND_COLOR: 'BlendColor',
};

export const CustomXprogressFilter = (val: any) => {
  return (image: any) => {
    const data = image.data,
      nPixels = data.length;
    let i, r, g, b;

    for (i = 0; i < nPixels; i += 4) {
      r = data[i + 0];
      g = data[i + 1];
      b = data[i + 2];

      data[i + 0] = Math.min(255, r * (0.393 + 0.607 * (1 - val)) + g * 0.769 * val + b * 0.189 * val);
      data[i + 1] = Math.min(255, r * 0.349 * val + g * (0.686 + 0.314 * (1 - val)) + b * 0.168 * val);
      data[i + 2] = Math.min(255, r * 0.272 * val + g * 0.534 * val + b * (0.131 + 0.869 * (1 - val)));
    }
  };
};

type NORMALIZE_VALUE = {
  BRIGHTNESS: number;
  CONTRAST: number;
  SATURATION: number;
  BLUR: number;
  XPROCESS: number;
  TINT: number;
  HUE_ROTATION: number;
};

type REDUCE_VALUE = {
  BRIGHTNESS: number;
  CONTRAST: number;
};

export const REDUCE_VALUE: REDUCE_VALUE = {
  BRIGHTNESS: 0.67,
  CONTRAST: 0.67,
};

export const NORMALIZE_VALUE: NORMALIZE_VALUE = {
  BRIGHTNESS: 300,
  CONTRAST: 300,
  SATURATION: 50,
  BLUR: 300,
  XPROCESS: 50,
  TINT: 100,
  HUE_ROTATION: 100,
};

export const makeBrightnessFilter = (value: number) => {
  return new fabric.Image.filters.Brightness({
    brightness: value,
  });
};

export const makeBlurFilter = (value: number) => {
  // @ts-ignore
  return new fabric.Image.filters.Blur({
    blur: value,
  });
};

export const makeContrastFilter = (value: number) => {
  return new fabric.Image.filters.Contrast({
    contrast: value,
  });
};

export const makeSaturationFilter = (value: number) => {
  return new fabric.Image.filters.Saturation({
    saturation: value,
  });
};

export const makeTintFilter = (value: number) => {
  // @ts-ignore
  return new fabric.Image.filters.HueRotation({
    rotation: value,
  });
};

export const makeBlendColorFilter = (color: string, alpha = 0.35, mode: string) => {
  return new fabric.Image.filters.BlendColor({
    color,
    alpha,
    mode,
  });
};

export const makeGrayscaleFilter = () => {
  return new fabric.Image.filters.Grayscale();
};

export const makeXProcessFilter = (value: number) => {
  //@ts-ignore
  return new fabric.Image.filters.Redify();
};

export const findFilterIndex = (filterName: string, image: fabric.Image) => {
  // @ts-ignore
  return image.filters?.findIndex(filter => filter.type === filterName);
};

export const removeFilter = (filterName: string, image: fabric.Image) => {
  // @ts-ignore
  image.filters = image.filters?.filter(filter => filter.type !== filterName);
  image.canvas?.fire('object:modified', {});
};

export const pushFilter = (filter: any, index: number | undefined, image: fabric.Image) => {
  if (index === undefined || index < 0 || image.filters === undefined) {
    image.filters?.push(filter);
  } else {
    image.filters[index] = filter;
  }
  image.canvas?.fire('object:modified', {});
};
