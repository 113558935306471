import { fabric } from 'fabric';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActiveElementContext } from '../ActiveElementContextProvider/ActiveElementContextProvider';
import { onObjectSnapingEnd, onObjectSnapping } from '../components/Main/handleSnaping';
import { CUSTOM_PROPS } from '../components/Main/utils';
import { ExtendedCanvas, useFabricCanvas } from '../FabricCanvasContext/FabricCanvasContext';
import { listenKeyboardEvents } from '../FabricCanvasContext/utils';
import { CropState } from '../store/types';
import { resizeModule } from '../components/LeftMenu/ItemTray/Elements/ResizeTray/utils';
import { ZoomContext } from '../ZoomContext/ZoomContext';

export const ConversionContext = createContext<any>({} as any);

export const customizedKeysArr = CUSTOM_PROPS;

const isWallpaperBool = (W:number, H:number, w:number, h:number, x:number, y:number):Boolean => {
  let objAreaOnCanvas = 0;
  if (x >= 0 && y >= 0 && x < W && y < H) {
    objAreaOnCanvas = (W - x > w ? w : W - x) * (H - y > h ? h : H - y);
  } else if (x < 0 && y < 0 && (x * -1) < w && (y * -1) < h) {
    objAreaOnCanvas = (w + x > W ? W : w + x) * (h + y > H ? H : h + y);
  } else if (x >= 0 && y < 0 && x < W && (y * -1) < h) {
    objAreaOnCanvas = (W - x > w ? w : W - x) * (h + y > H ? H : h + y);
  } else if (x < 0 && y >= 0 && (x * -1) < w && y < H) {
    objAreaOnCanvas = (w + x > W ? W : w + x) * (H - y > h ? h : H - y);
  }
  return objAreaOnCanvas >= (W * H * 0.9);
}

const getUpdatedCanvasObj = (canvas:fabric.Canvas, drawingData:any, zoom:number) => {
  const canvasObj = canvas.toObject(customizedKeysArr);
  const modifiedObjArr = canvasObj.objects;
  const updatedObj = {
    dpi: drawingData.dpi || 96,
    width: canvas.getWidth() / zoom,
    height: canvas.getHeight() / zoom,
    children: modifiedObjArr,
    backgroundColor: canvasObj.background,
    backgroundImage: canvasObj.backgroundImage,
  };
  return updatedObj;
};

// active object behaviour set for active canvas
export const setSelectionStyle = (canvas:fabric.Canvas) => {
  const set = (obj:fabric.Object) => {
    obj.set({
      borderColor: 'rgba(23, 0, 92, 0.5)',
      cornerColor: 'rgba(23, 0, 92, 1)',
      cornerSize: 10,
      cornerStyle: 'circle',
      transparentCorners: false,
    })
  } 
  canvas.on('selection:created', (e) => e.target && set(e.target));
  canvas.on('selection:updated', (e) => e.target && set(e.target));
}

export const initialDefaultConversionDataArr = [
  { key: 'defaultSquare', height: 1000, width: 1000, data: null },
  { key: 'defaultHorizontal', height: 500, width: 1000, data: null },
  { key: 'defaultVertical', height: 1000, width: 500, data: null },
];

export const getReplaceObject = (obj:any, canvasWidth:number, canvasHeight:number, newTextUrl:string, newImageWidth?:number, newImageHeight?:number)  => {
  if (obj.type === 'textbox') {
    const newTextWidth = (obj.width * newTextUrl.length) / obj.text.length;
    const left = obj.left;
    const textAlign = obj.textAlign;
    const diff = obj.width - newTextWidth;
    return {
      ...obj,
      width: newTextWidth,
      left: textAlign === 'right' ? left + diff : textAlign === 'center' ? left + (diff / 2) : left,
    };
  }
  if (obj.type === 'image') {
    if (!newImageHeight || !newImageWidth || !newTextUrl) return obj;
    const isWallpaper = isWallpaperBool(canvasWidth, canvasHeight, obj.width * obj.scaleX, obj.height * obj.scaleY, obj.left, obj.top);
    const aspectRatio = (obj.width * obj.scaleX) / (obj.height * obj.scaleY);
    const newAspectRatio = newImageWidth / newImageHeight;
    const centerXPercent = (obj.left + (obj.width * obj.scaleX / 2)) / canvasWidth;
    const centerYPercent = (obj.top + (obj.height * obj.scaleY / 2)) / canvasHeight;
    const newCenterX = canvasWidth * centerXPercent;
    const newCenterY = canvasHeight * centerYPercent;
    const newScaleY = obj.scaleY * (obj.height / newImageHeight);
    const newScaleX = obj.scaleX * (obj.width / newImageWidth);
    const common = {
      cropX: 0,
      cropY: 0,
      naturalWidth: newImageWidth,
      naturalHeight: newImageHeight,
      height: newImageHeight,
      width: newImageWidth,
      src: newTextUrl,
      exportedAsset: newTextUrl,
    }
    if ((aspectRatio >= newAspectRatio && !isWallpaper) || (aspectRatio < newAspectRatio && isWallpaper)) {
      return {
        ...obj,
        ...common,
        scaleX: newScaleY,
        scaleY: newScaleY,
        left: newCenterX - (newImageWidth * newScaleY / 2),
      };
    }
    if ((aspectRatio >= newAspectRatio && isWallpaper) || (aspectRatio < newAspectRatio && !isWallpaper)) {
      return {
        ...obj,
        ...common,
        scaleX: newScaleX,
        scaleY: newScaleX,
        top: newCenterY - (newImageHeight * newScaleX / 2),
      };
    }
    return obj;
  }
  return obj;
}

export const getUpdatedCanvasObjectSingle = (obj:any, prevWidth:number, prevHeight:number, canvasWidth:number, canvasHeight:number) => {
  const isWallpaper = isWallpaperBool(prevWidth, prevHeight, obj.width * obj.scaleX, obj.height * obj.scaleY, obj.left, obj.top);
  const paddingXpercent = obj.left / prevWidth;
  const paddingYpercent = obj.top / prevHeight;
  const newHeight = (obj.height * obj.scaleY * canvasHeight) / prevHeight;
  const newWidth = (obj.width * obj.scaleX * canvasWidth) / prevWidth;
  const centerXPercent = (obj.left + (obj.width * obj.scaleX / 2)) / prevWidth;
  const centerYPercent = (obj.top + (obj.height * obj.scaleY / 2)) / prevHeight;
  const newCenterX = canvasWidth * centerXPercent;
  const newCenterY = canvasHeight * centerYPercent;
  const newX = newCenterX - (newWidth / 2);
  const newY = newCenterY - (newHeight / 2);
  const aspectRatio = (obj.width * obj.scaleX) / (obj.height * obj.scaleY);
  const newAspectRatio = newWidth / newHeight;

  if (aspectRatio >= newAspectRatio && obj.type === 'textbox') {
    return {
      ...obj,
      fontSize: obj.fontSize * canvasWidth / prevWidth,
      width: obj.width * canvasWidth / prevWidth,
      left: newX,
      top: newCenterY - ((newWidth / aspectRatio) / 2),
    };
  }
  if (aspectRatio < newAspectRatio && obj.type === 'textbox') {
    return {
      ...obj,
      fontSize: obj.fontSize * canvasHeight / prevHeight,
      width: obj.width * canvasHeight / prevHeight,
      top: newY,
      left: newCenterX - ((newHeight * aspectRatio) / 2),
    };
  }
  if (aspectRatio >= newAspectRatio && !isWallpaper) {
    return {
      ...obj,
      scaleX: obj.scaleX * canvasWidth / prevWidth,
      scaleY: obj.scaleY * canvasWidth / prevWidth,
      left: newX,
      top: newCenterY - ((newWidth / aspectRatio) / 2),
    };
  }
  if (aspectRatio >= newAspectRatio && isWallpaper) {
    return {
      ...obj,
      scaleX: obj.scaleX * canvasHeight / prevHeight,
      scaleY: obj.scaleY * canvasHeight / prevHeight,
      left: ((newHeight * aspectRatio) - canvasWidth) / -2,
      top: canvasHeight * paddingYpercent,
    };
  }
  if (aspectRatio < newAspectRatio && !isWallpaper) {
    return {
      ...obj,
      scaleX: obj.scaleX * canvasHeight / prevHeight,
      scaleY: obj.scaleY * canvasHeight / prevHeight,
      top: newY,
      left: newCenterX - ((newHeight * aspectRatio) / 2),
    };
  }
  if (aspectRatio < newAspectRatio && isWallpaper) {
    return {
      ...obj,
      scaleX: obj.scaleX * canvasWidth / prevWidth,
      scaleY: obj.scaleY * canvasWidth / prevWidth,
      top: ((newWidth / aspectRatio) - canvasHeight) / -2,
      left: canvasWidth * paddingXpercent,
    };
  }
  return { ...obj, change: 'no change' };
}

const getConvertedCanvasData = (arr:any, drawingData:any) => {
    return new Promise((res) => {
      Promise.all(arr.map((canvasObj:any) => new Promise((resolve) => {
        const canvasHeight = canvasObj.height;
        const canvasWidth = canvasObj.width;
        const prevWidth = drawingData.width;
        const prevHeight= drawingData.height;
        resizeModule(drawingData.children || [], drawingData.backgroundImage, prevWidth, prevHeight, canvasWidth, canvasHeight).then((data:any) => {
          const bImage = data.backgroundImage;
          const updatedChildren = data.children;
          const obj = {
            ...canvasObj,
            data: {
              dpi: drawingData.dpi || 96,
              width: canvasWidth,
              height: canvasHeight,
              backgroundColor: drawingData.backgroundColor,
              backgroundImage: bImage,
              children: updatedChildren,
            }
          };
          resolve(obj);
        });
      }))).then((resArr:any) => {
        res(resArr);
      });
    });
}

export const getCanvasType = (width:number, height:number) : 'square' | 'horizontal' | 'vertical' => {
  const aspectRatio = width / height;
  return aspectRatio < 0.8 ? 'vertical' : aspectRatio > 1.2 ? 'horizontal' : 'square';
}


export const setTextBoxEvents = (o:any) => {
  o.setControlsVisibility({ mt: false, mb: false });
  o.on('scaled', (e:any) => {
    if (e.transform?.action === 'scaleX') return;
    o.set('fontSize', (o.fontSize as number) * (o.scaleX as number));
    o.set('width', (o.width as number) * (o.scaleX as number));
    o.set('scaleX', 1);
    o.set('scaleY', 1);
    o.setCoords();
    o.canvas?.requestRenderAll();
  });
  o.on('changed', () => {
    if (!o.isNameEdited) o.set('name', o.text);
  });
}

export const setShapeEvents = (o:any) => {
  o.on('scaled', (e:any) => {
    const { action, scaleX, scaleY } = e.transform;
    const { rx, ry } = e.target;
    if (action === 'scaleX') o.set('rx', rx / Math.abs(o.scaleX / scaleX));
    if (action === 'scaleY') o.set('ry', ry / Math.abs(o.scaleY / scaleY));
    o.setCoords();
    o.canvas?.requestRenderAll();
  });
}


export const setEvents = (canvas:ExtendedCanvas) => {
  setTimeout(() => {
    canvas.forEachObject((o:any) => {
      if (o.type === 'textbox') setTextBoxEvents(o);
      if (o.type === 'rect') setShapeEvents(o);
    });
  }, 0);
}

const ConversionContextProvider = ({ children }: any) => {
  const { currentElementRight, currentElement, setCurrentElementRight } = useContext(ActiveElementContext);
  const [originalCanvasData, setOriginalCanvasData] = useState<any>(null);
  const [viewModePreview, setViewModePreview] = useState<'Grid' | 'Individual'>('Grid');
  const [openResize, setOpenResize] = useState<boolean>(false);
  const [defaultConversionCanvasDataArr, setDefaultConversionCanvasDataArr] = useState<any[]>(initialDefaultConversionDataArr);
  const [previewConversionCanvasDataArr, setPreviewConversionCanvasDataArr] = useState<any[]>([]);
  const [isLoadingCanvas, setIsLoadingCanvas] = useState<Boolean>(false);
  const [isCanvasBusy, setIsCanvasBusy] = useState<Boolean>(false);
  const [conversionStep, setConversionStep] = useState<'original' | 'default' | 'preview'>('original');
  const [defaultCurrentCanvas, setDefaultCurrentCanvas] = useState<'defaultSquare' | 'defaultHorizontal' | 'defaultVertical'>('defaultSquare');
  const [previewCurrentCanvas, setPreviewCurrentCanvas] = useState<String>('');
  const [previewUpdateCount, setPreviewUpdateCount] = useState<number>(0);
  const [cloneCount, setCloneCount] = useState<number>(1);
  const [layers, setLayers] = useState<any[]>([]);
  const [activeLayerId, setActiveLayerId] = useState<any>(null);
  const [saveTimeout, setSaveTimeout] = useState<any>(null);
  const [saveCount, setSaveCount] = useState(0);
  const [saveCountSync, setSaveCountSync] = useState(0);
  const [saving, setSaving] = useState(false);
  const { zoomLevel, setZoom, setSelectedZoomOption } = useContext(ZoomContext);
  const [updateZoomCount, setUpdateZoomCount] = useState(0);
  const [keyboardSaveCount, setKeyboardSaveCount] = useState(0);
  const [generateSetHyperCount, setGenerateSetHyperCount] = useState(0);
  const [layerTrayCollapse, setLayerTrayCollapse] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const { activeObject, activeCanvasId, fabricCanvases, fabricCanvas, setActiveCanvasId, setActiveFabricObjectType, fabricCanvasesDefault, setFabricCanvasesDefault, setFabricCanvasesPreview, setActiveObject, fabricCanvasesPreview, fabricCanvasesHyper, setFabricCanvasesHyper } = useFabricCanvas();

  const cropEnabled = useSelector((state: { crop: CropState }) => state.crop).cropEnabled;
  const [objectToPaste, setObjectToPaste] = useState<fabric.Object | null>(null);
  const params:any = useParams();

  useEffect(() => {
    const listener = (e: any) => listenKeyboardEvents({
      e,
      activeObject,
      setActiveObject,
      cropEnabled,
      fabricCanvases,
      fabricCanvasesPreview,
      fabricCanvasesHyper,
      fabricCanvas,
      objectToPaste,
      setObjectToPaste,
      setZoom,
      activeCanvasId,
      setActiveCanvasId,
      setSaveCountSync,
      setKeyboardSaveCount,
      setCurrentElementRight,
      zoomLevel,
      setSelectedZoomOption,
      params,
      conversionStep,
      viewModePreview,
      isCanvasBusy,
      setIsCanvasBusy,
      layers,
      setLayers,
    });

    window.addEventListener('keydown', listener);
    window.addEventListener('wheel', listener, { passive: false });
    return () => {
      window.removeEventListener('keydown', listener);
      window.removeEventListener('wheel', listener);
    };
  }, [
    activeObject,
    setActiveObject,
    cropEnabled,
    fabricCanvases,
    fabricCanvas,
    objectToPaste,
    setObjectToPaste,
    setZoom,
    activeObject?.type,
    saveCountSync,
    keyboardSaveCount,
    zoomLevel,
    conversionStep,
    viewModePreview,
  ]);

  useEffect(() => {
    setTimeout(() => {
      const fabricCanvasesNew = previewConversionCanvasDataArr.map((canvasObj:any) => {
        let canvas:any;
        if (fabricCanvasesPreview.map((canvas) => canvas.getElement().id).includes(`fabric-canvas-${canvasObj.key}`)) {
          canvas = fabricCanvasesPreview.find((canvas) => canvas.getElement().id === `fabric-canvas-${canvasObj.key}`);
          canvas?.setWidth(canvasObj.width * zoomLevel);
          canvas?.setHeight(canvasObj.height * zoomLevel);
          canvas?.setZoom(zoomLevel);
        } else {
          canvas = new fabric.Canvas(`fabric-canvas-${canvasObj.key}`, {
            preserveObjectStacking: true,
            backgroundColor: '#ffffff',
            renderOnAddRemove: false,
          });
          canvas.setWidth(canvasObj.width * zoomLevel);
          canvas.setHeight(canvasObj.height * zoomLevel);
          setSelectionStyle(canvas);
          canvas.setZoom(zoomLevel);
          canvas.on('mouse:down', (e:any) => {
            setActiveFabricObjectType(e.target?.type || null);
            setActiveCanvasId(`fabric-canvas-${canvasObj.key}`);
            setPreviewCurrentCanvas(canvasObj.key);
            setActiveObject(e.target);
            setLayers(layers => layers.map((l:any) => ({ ...l, isOpen: false })));
          });
          canvas.on('object:moving', (e:any) => onObjectSnapping(e.target, canvas));
          canvas.on('object:moved', () => onObjectSnapingEnd(canvas));
          // canvas.on('object:modified', () => setSaveCount((c:any) => c + 1));
          // canvas.on('object:added', () => setSaveCount((c:any) => c + 1));
          // canvas.on('object:removed', () => setSaveCount((c:any) => c + 1));
        }
        return canvas;
      });
  
      setFabricCanvasesPreview(fabricCanvasesNew);
  
      // new async
      // for added size only
  
      const existingDataArr = previewConversionCanvasDataArr.filter((obj:any) => (obj.data && obj.data.key));
      const newlyAddedDataArr = previewConversionCanvasDataArr.filter((obj:any) => !(obj.data && obj.data.key));
  
      Promise.all(newlyAddedDataArr.map((obj:any) => new Promise((res) => {
        let data;
        let dataObj:any = {};
        if (existingDataArr.length) {
          // map over existing
          let closestMatchAspectRatioKey = '';
          let closestMatchAspectRatio = 0;
          const squareArr = existingDataArr.filter((obj3) => obj3.type === 'square');
          const horizontalArr = existingDataArr.filter((obj3) => obj3.type === 'horizontal');
          const verticalArr = existingDataArr.filter((obj3) => obj3.type === 'vertical');
          let dataArr = [];
          if (obj.type === 'square' && squareArr.length) {
            dataArr = squareArr;
          } else if (obj.type === 'horizontal' && horizontalArr.length) {
            dataArr = horizontalArr;
          } else if (obj.type === 'vertical' && verticalArr.length) {
            dataArr = verticalArr;
          } else {
            dataArr = existingDataArr;
          }
  
          dataArr.forEach((obj1) => {
            const aspectRatioNew = obj.width / obj.height;
            const aspectRatioExisting = obj1.width / obj1.height;
            if (!closestMatchAspectRatio || !closestMatchAspectRatioKey) {
              closestMatchAspectRatio = aspectRatioExisting;
              closestMatchAspectRatioKey = obj1.key;
              dataObj = obj1;
            } else {
              // put closest aspect ratio algo here
              const change = Math.abs(aspectRatioExisting - closestMatchAspectRatio) > Math.abs(aspectRatioExisting - aspectRatioNew);
              if (change) {
                closestMatchAspectRatio = aspectRatioExisting;
                closestMatchAspectRatioKey = obj1.key;
                dataObj = obj1;
              }
            }
          });
        } else {
          dataObj = defaultConversionCanvasDataArr.find((obj3) => getCanvasType(obj3.width, obj3.height) === obj.type);
        }
        const canvasHeight = obj.height;
        const canvasWidth = obj.width;
        const prevWidth = dataObj.width;
        const prevHeight= dataObj.height;
        if (!dataObj.data) {
          dataObj.data = {
            dpi: 96,
            backgroundColor: '#ffffff',
            children: [],
          }
        }
        resizeModule(dataObj.data.children || [], dataObj.data.backgroundImage, prevWidth, prevHeight, canvasWidth, canvasHeight).then((result:any) => {
          const bImage = result.backgroundImage;
          const updatedChildren = result.children;
          data = {
            key: obj.key,
            dpi: dataObj.data.dpi,
            backgroundColor: dataObj.data.backgroundColor,
            backgroundImage: bImage,
            children: updatedChildren, 
          }
          res({ ...obj, data });
        });
      }))).then((newlyAddedDataArrUpdated) => {
        const finalArr = newlyAddedDataArrUpdated.concat(existingDataArr);
        setPreviewConversionCanvasDataArr(finalArr);
      });
    }, 0);
  }, [previewUpdateCount]);

  // triggering history event for inactive canvases preview
  // on object modified individual in grid mode
  useEffect(() => {
    if (!fabricCanvasesPreview.length) return;
    fabricCanvasesPreview.forEach((canvas) => {
      canvas.on('object:modified', () => {
        const currentId = canvas.getElement().id;
        // setSaveCount((c:any) => c + 1);
        fabricCanvasesPreview.forEach((c:any) => {
          c.getElement().id !== currentId && c._historySaveAction.call(c);
        })
      });
    });
    return () => {
      fabricCanvasesPreview.forEach((canvas) => {
        canvas.off('object:modified');
      });
    };
  }, [fabricCanvasesPreview])

  const createAndRenderUpdatedPreviewSet = (newPreviewArr:any) => {
    setZoom(1);
    setIsLoadingCanvas(true);
    setTimeout(() => {
      setOpenResize(false);
      setActiveObject(null);
      // save preview changes from fabricCanvasPreview
      const addedArray = newPreviewArr.filter((obj:any) => {
        const key = obj.key.split('-').reverse()[0];
        const prevKeysArr = previewConversionCanvasDataArr.map((obj:any) => obj.key);
        return !(prevKeysArr.includes(key) || prevKeysArr.includes(parseInt(key, 10)));
      }).map((obj:any) => {
        const { height, width, value } = obj;
        const key = obj.key.split('-').reverse()[0];
        return {
          height, width, value,
          key: (typeof key === 'string' && key.includes('custom')) ? key : parseInt(key, 10),
          type: getCanvasType(width, height),
        }
      });
      const updatedPreviewWithRemoved = previewConversionCanvasDataArr.filter((obj:any) => {
        const newArrKeys = newPreviewArr.map((obj1:any) => obj1.key);
        const found = newArrKeys.find((key:any) => {
          const k = key.split('-').reverse()[0];
          return k == obj.key
        });
        return !!found;
      }).map((o) => {
        const canvas = fabricCanvasesPreview.find((c) => c.getElement().id.replace('fabric-canvas-', '') == o.key);
        const cData = canvas?.toObject(customizedKeysArr);
        if (!cData) return o;
        const data = {
          ...o.data,
          backgroundColor: cData.background,
          backgroundImage: cData.backgroundImage,
          children: cData.objects,
        }
        return { ...o, data };
      });
      const updatedData = updatedPreviewWithRemoved.concat(addedArray);
      setPreviewConversionCanvasDataArr(updatedData);
      // use effect re render with added arr
      // trigger effect
      // in main new hook to re render added canvas element with id
      setPreviewUpdateCount(previewUpdateCount + 1);
    }, 0);
  }

  const createAndRenderDefaultView = (previewArr:any) => {
    setIsLoadingCanvas(true);
    setTimeout(() => {
      setActiveObject(null);
      if (!fabricCanvas) return;
      const fabricCanvasesNew = defaultConversionCanvasDataArr.map((canvasObj) => {
        const canvas = new fabric.Canvas(`fabric-canvas-${canvasObj.key}`, {
          preserveObjectStacking: true,
          renderOnAddRemove: false,
        });
        canvas.setWidth(canvasObj.width * zoomLevel);
        canvas.setHeight(canvasObj.height * zoomLevel);
        setSelectionStyle(canvas);
        canvas.setZoom(zoomLevel);
        canvas.on('mouse:down', e => {
          setActiveFabricObjectType(e.target?.type || null);
          setActiveCanvasId(`fabric-canvas-${canvasObj.key}`);
          setActiveObject(e.target);
        });
        canvas.on('object:moving', (e:any) => onObjectSnapping(e.target, canvas));
        canvas.on('object:moved', () => onObjectSnapingEnd(canvas));
        return canvas;
      });
      // set data for first time initiate dpi data
      const originalData = getUpdatedCanvasObj(fabricCanvas, {}, zoomLevel);
      getConvertedCanvasData(defaultConversionCanvasDataArr, originalData).then((canvasDataArr:any) => {
        setOriginalCanvasData({
          data: originalData,
          key: 'original',
          value: `Original (${originalData.width} X ${originalData.height})`,
          type: getCanvasType(originalData.width, originalData.height),
          height: originalData.height,
          width: originalData.width,
          category: 'Parent',
        });
        setFabricCanvasesDefault(fabricCanvasesNew);
        setConversionStep('default');
        setPreviewConversionCanvasDataArr(previewArr);
        setDefaultConversionCanvasDataArr(canvasDataArr);
      });
    }, 0);
  }

  const createAndRenderPreviewSet = (resp:any) => {
    setIsLoadingCanvas(true);
    setTimeout(() => {
      setActiveObject(null);
      let updatedDefaultDataArr = defaultConversionCanvasDataArr.map((obj) => {
        const data = resp.data.project_json.default.find((c:any) => obj.key === c.key);
        return { ...obj, data }
      });

      const fabricCanvasesNew = resp.data.project_json.set.map((canvasObj:any) => {
        const canvas = new fabric.Canvas(`fabric-canvas-${canvasObj.size.id || canvasObj.size.name}`, {
          preserveObjectStacking: true,
          backgroundColor: '#ffffff',
          renderOnAddRemove: false,
        });
        canvas.setWidth(canvasObj.size.width * zoomLevel);
        canvas.setHeight(canvasObj.size.height * zoomLevel);
        canvas.setZoom(zoomLevel);
        setSelectionStyle(canvas);
        canvas.on('mouse:down', e => {
          setActiveCanvasId(`fabric-canvas-${canvasObj.size.id || canvasObj.size.name}`);
          setActiveFabricObjectType(e.target?.type || null);
          setActiveObject(e.target);
          setLayers(layers => layers.map((l:any) => ({ ...l, isOpen: false })));
        });
        canvas.on('object:moving', e => onObjectSnapping(e.target, canvas));
        canvas.on('object:moved', () => onObjectSnapingEnd(canvas));
        return canvas;
      });

      const defaultDataSquare = updatedDefaultDataArr.find((obj) => obj.key === 'defaultSquare');
      const defaultDataHorizontal = updatedDefaultDataArr.find((obj) => obj.key === 'defaultHorizontal');
      const defaultDataVertical = updatedDefaultDataArr.find((obj) => obj.key === 'defaultVertical');

      const previewTypesArr = resp.data.project_json.set.map((obj:any) => {
        const { height, width, id, name } = obj.size;
        const type = getCanvasType(width, height);
        const data = obj.template_json?.key ? obj.template_json : undefined;
        return { data, height, width, type, key: id || name, value: name };
      });
      const existingDataArr = previewTypesArr.filter((obj:any) => (obj.data && obj.data.key));
      const defObjArr = [
        { key: 'square', def: defaultDataSquare },
        { key: 'horizontal', def: defaultDataHorizontal },
        { key: 'vertical', def: defaultDataVertical },
      ]
      Promise.all(defObjArr.map((defObj:any) => new Promise((res) => {
        const { key, def } = defObj;
        getConvertedCanvasData(previewTypesArr.filter((obj:any) => (obj.type === key && !(obj.data && obj.data.key))), { ...def.data, height: def.height, width: def.width }).then((result) => {
          res(result);
        });
      }))).then((dataArr) => {
        const concatedArr = existingDataArr;
        dataArr.forEach((arr:any) => {
          concatedArr.push(...arr)
        });
        setFabricCanvasesPreview(fabricCanvasesNew);
        setDefaultConversionCanvasDataArr(updatedDefaultDataArr);
        setPreviewConversionCanvasDataArr(concatedArr);
      });
    }, 0);
  }

  const createAndRenderHyperPersonalize = (resp:any) => {
    setIsLoadingCanvas(true);
    setTimeout(() => {
      setActiveObject(null);
      const fabricCanvasesNew = resp.data.sizes.filter((obj:any) => obj.selected_for_combination).map((canvasObj:any) => {
        const canvas = new fabric.Canvas(`fabric-canvas-${canvasObj.id || canvasObj.name}`, {
          preserveObjectStacking: true,
          backgroundColor: '#ffffff',
          // renderOnAddRemove: false,
        });
        canvas.setWidth(canvasObj.width * zoomLevel);
        canvas.setHeight(canvasObj.height * zoomLevel);
        canvas.setZoom(zoomLevel);
        setSelectionStyle(canvas);
        canvas.on('mouse:down', e => {
          setActiveCanvasId(`fabric-canvas-${canvasObj.id || canvasObj.name}`);
          if (e.target?.selectable) {
            setActiveFabricObjectType(e.target?.type || null);
            setActiveObject(e.target);
          }
        });
        canvas.on('object:moving', e => onObjectSnapping(e.target, canvas));
        canvas.on('object:moved', () => onObjectSnapingEnd(canvas));
        return canvas;
      });
      
      const previewTypesArr = resp.data.sizes.filter((obj:any) => obj.selected_for_combination).map((obj:any) => {
        const { height, width, id, name, default_json } = obj;
        const type = getCanvasType(width, height);
        const data = default_json?.key ? default_json : undefined;
        return { data, height, width, type, key: id || name, value: name };
      });
      setFabricCanvasesHyper(fabricCanvasesNew);
      setPreviewConversionCanvasDataArr(previewTypesArr);
    } , 0);
  }

  return (
    <ConversionContext.Provider
      value={{
        originalCanvasData,
        setOriginalCanvasData,
        defaultConversionCanvasDataArr,
        setDefaultConversionCanvasDataArr,
        previewConversionCanvasDataArr,
        setPreviewConversionCanvasDataArr,
        conversionStep,
        setConversionStep,
        defaultCurrentCanvas,
        setDefaultCurrentCanvas,
        isLoadingCanvas,
        setIsLoadingCanvas,
        createAndRenderDefaultView,
        createAndRenderPreviewSet,
        viewModePreview,
        setViewModePreview,
        previewCurrentCanvas,
        setPreviewCurrentCanvas,
        createAndRenderUpdatedPreviewSet,
        openResize,
        setOpenResize,
        cloneCount,
        setCloneCount,
        layers,
        setLayers,
        createAndRenderHyperPersonalize,
        saving,
        setSaving,
        saveTimeout,
        setSaveTimeout,
        saveCount,
        setSaveCount,
        saveCountSync,
        setSaveCountSync,
        activeLayerId,
        setActiveLayerId,
        updateZoomCount,
        setUpdateZoomCount,
        keyboardSaveCount,
        setKeyboardSaveCount,
        setLayerTrayCollapse,
        layerTrayCollapse,
        setIsCanvasBusy,
        isCanvasBusy,
        setGenerateSetHyperCount,
        generateSetHyperCount,
        loadingImage,
        setLoadingImage
      }}
    >
      {children}
    </ConversionContext.Provider>
  );
};

export default ConversionContextProvider;
