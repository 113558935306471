import { isObjectScalable } from "../helpers/isScalable";
import { cloneDeep, isPlainObject } from "lodash";
import { fabric } from "fabric";
import { cropImage } from "./cropImage";
import { getObjectSize } from "./getObjectSize";

export const resizeObj = (obj: any, ratioX: number, ratioY: number, withCrop = false) => {
  const newObj = cloneDeep(obj);
  const isScalable = isObjectScalable(newObj);
  const isText = newObj.layerType === "Text";
  const isImage = newObj.layerType === "Image";
  const minRatio = Math.min(ratioX, ratioY);

  //   ratio = height / width

  // #let's use newHeight of the bounding box
  // newWidth = newHeight / (ratio * cos(alpha) + sin(alpha))
  // newHeight = ratio * newWidth
  // x = select_el.left - object.left;
  // y = select_el.top - object.top;

  // x *= 1 / scale;
  // y *= 1 / scale;

  // width = select_el.width * 1 / scale;
  // heigh = select_el.height * 1 / scale;

  // object.clipTo = function (ctx) {
  //     ctx.rect (x, y, width, height);
  // }

  if (isImage && withCrop) {
    const [oldWidth, oldHeight] = getObjectSize(obj);
    const objMaxWidth = obj?.naturalWidth ? obj.naturalWidth : oldWidth;
    const objMaxHeight = obj?.naturalHeight ? obj.naturalHeight : oldHeight;
    const newWidth = Math.min(oldWidth, objMaxWidth);
    const newHeight = Math.min(oldHeight, objMaxHeight);
    const diffWidth = oldWidth - newWidth;
    const diffHeight = oldHeight - newHeight;

    const crop = {
      x: diffWidth / 2,
      y: diffHeight / 2,
      width: oldWidth - diffWidth,
      height: oldHeight - diffHeight
    };

    const croppedImage = cropImage(newObj, crop);

    return {
      ...croppedImage
    };
  }

  if (isText) {
    return {
      ...newObj,
      fontSize: newObj.fontSize * minRatio,
      width: newObj.width * minRatio,
      height: newObj.height * minRatio
    };
  }

  if (isScalable) {
    return {
      ...newObj,
      scaleX: ratioX * newObj.scaleX,
      scaleY: ratioY * newObj.scaleY
    };
  } else {
    return {
      ...newObj,
      scaleX: minRatio * newObj.scaleX,
      scaleY: minRatio * newObj.scaleY
    };
  }
};
