import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, Banner, FolderState } from '../../../store/types';
import { getIsTypeEditable } from '../../../utilities/common-function';
import {
  setUploadedBanners,
  setCurrentFolderFile,
  setUploadedFolder,
  setUploadFileList,
  fetchFolders,
  addFolder,
  setFolderContentCount,
  setTempFolderContentCount,
} from '../../../store/actions';
import './index.scss';
import { Link } from 'react-router-dom';
import Icon, {
  CloseCircleOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Spin } from 'pyxis-ui-kit';
import { enableEdit } from '../../../utilities/contants';
import { openToast } from '../../../Toasts';
import { ReactComponent as iconFolder } from '../../../assets/svg/iconFolder.svg';
import { ReactComponent as iconComplete } from '../../../assets/svg/iconComplete.svg';
import RandomPreview from '../../../assets/img/random_preview.png';
const {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} = require('react-virtualized');

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
let timer: any;
const ProjectItem: FC<{
  data: any;
  index: number;
  isEditEnabled: boolean;
  uploadedBanners: Banner[];
  uploadFileList: any[];
}> = ({ data, index, isEditEnabled, uploadedBanners, uploadFileList }) => {
  const dispatch = useDispatch();
  return (
    <div className="item">
      <div className="detail">
        <div className="about">
          <span className="thumbnail">
            <img
              src={data?.thumbnail || ''}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = RandomPreview;
              }}
            />
          </span>
          <div className="flex-column">
            {data.title ? (
              <p className="name">{data.title}</p>
            ) : (
              <p className="name">No Name</p>
            )}
            {data.status === 'COMPLETED' && data.id ? (
              <span>
                {isEditEnabled &&
                getIsTypeEditable(data.file_type, data.type) ? (
                  <Link
                    to={`/cocreate/editor/${data.id}`}
                    onClick={(e) => {
                      if (!isEditEnabled) {
                        openToast('warn', "You don't have access to edit.");
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                  >
                    <span
                      className={!isEditEnabled ? 'permission-denied-lock' : ''}
                    >
                      Edit
                    </span>
                  </Link>
                ) : (
                  <Link to={`/cocreate/banner-detail/?banner=${data.id}`}>
                    View
                  </Link>
                )}
              </span>
            ) : (
              <span className="progress">
                {data.status === 'FAILED' ? data.reason : data.progress + '%'}
              </span>
            )}
          </div>
        </div>
        {(data.status === 'INPROGRESS' || data.status === 'NOT_STARTED') &&
          data.progress !== 100 && (
            <span className="cancel-upload">
              <CloseCircleOutlined
                className="close"
                onClick={() => {
                  if (data.token !== undefined) {
                    // API is called
                    data.token.cancel('Upload cancelled!');
                  } else {
                    // API is not called, file is only in list of upload 
                    let fileList: any = uploadFileList;
                    fileList[index].status = 'CANCELLED';
                    fileList[index].reason = 'Upload cancelled!';
                    dispatch(setUploadFileList(fileList));
                  }
                  // Will reflect changes immediately to user
                  let banners: any = uploadedBanners;
                  banners[index].status = 'FAILED';
                  banners[index].reason = 'Upload cancelled!';
                  dispatch(setUploadedBanners(banners));
                }}
              />
            </span>
          )}
        {data.progress < 100 && data.status === 'INPROGRESS' ? (
          <span className="loader-container">
            <Spin indicator={antIcon} className="loader" />{' '}
          </span>
        ) : data.status === 'FAILED' || data.status === 'CANCELLED' ? (
          <span className="banner-failed">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                fill="#D32F2F"
              />
            </svg>
          </span>
        ) : (
          data.status === 'COMPLETED' &&
          data.id &&
          data.status !== 'FAILED' && (
            <Icon component={iconComplete} className="complete-icon" />
          )
        )}
      </div>
    </div>
  );
};

const FolderItem: FC<{
  key: number;
  data: any;
  folderContentCount: any;
  uploadFileList: any;
  dispatch: any;
  uploadedBanners: any;
}> = ({
  key,
  data,
  folderContentCount,
  uploadFileList,
  dispatch,
  uploadedBanners,
}) => {
  const [cancelledFolder, setCancelledFolder] = useState<any>([]);
  return (
    <div className="folder-item" key={key}>
      <div className="folder-info">
        <Icon component={iconFolder} className="folder-icon" />
        <span className="folder-name">
          {data.name}{' '}
          <span className="count">
            {' '}
            ({folderContentCount[data.id]?.rootAndChildComplete}/
            {data.totalCount})
          </span>
        </span>
      </div>
      <div className="folder-action">
        {cancelledFolder.includes(data.id) ? (
          <span className="folder-fail">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                fill="#D32F2F"
              />
            </svg>
          </span>
        ) : folderContentCount[data.id]?.rootAndChildInprogress === 0 &&
          folderContentCount[data.id]?.rootAndChildComplete > 0 ? (
          <Icon component={iconComplete} className="complete-icon" />
        ) : (
          <>
            <CloseCircleOutlined
              className="cancel-folder-upload"
              onClick={() => {
                let banners: any = uploadFileList.reduce(
                  (files: number[], file: any, index: number) => {
                    if (
                      data.hashedStructureFolderIds.includes(file.parent?.id)
                    ) {
                      if (file.token !== undefined) {
                        // API is called
                        file.token.cancel();
                      } else {
                        // API is not called
                        file.status = 'CANCELLED';
                      }
                    }
                    files.push(file);
                    return files;
                  },
                  []
                );
                dispatch(setUploadFileList(banners));
                setCancelledFolder((cancelledFolders: any) => [
                  ...cancelledFolders,
                  data.id,
                ]);
              }}
            />
            {folderContentCount[data.id]?.rootAndChildInprogress > 0 && (
              <Spin indicator={antIcon} className="loader" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const UploadedDesignsPopUp: FC = () => {
  const {
    uploadedBanners,
    fileToProjectQueue,
    currentFolderFile,
    activeFolderId,
    uploadedFolder,
    uploadFileList,
    folderContentCount,
  } = useSelector((state: { folders: FolderState }) => state.folders);
  const [isMinized, setIsMinimized] = useState(false);
  const [uploadActiveFolderId, setUploadActiveFolderId] = useState(
    activeFolderId
  );
  const uploadListRef = useRef(null);
  const dispatch = useDispatch();
  const authState = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const { userData } = authState;
  const isEditEnabled: boolean = enableEdit.some((val: string) =>
    userData?.emailId.includes(val)
  );
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
      }),
    []
  );
  const { isLoggedIn } = useSelector((state: { authentication: AuthState }) =>
    state.authentication ? state.authentication : { isLoggedIn: null }
  );

  useEffect(() => {
    setUploadActiveFolderId(activeFolderId);
  }, [uploadedFolder, currentFolderFile]);

  const checkForPopUpClose = useCallback(
    (projects: any[]) => {
      if (projects.length > 0 && !isMinized) {
        const completedCount: number = projects.reduce((acc: any, val: any) => {
          if (val?.rendered_status === 'COMPLETED') {
            return acc + 1;
          }
          return acc;
        }, 0);

        if (completedCount === projects.length) {
          timer = setTimeout(() => dispatch(setUploadedBanners([])), 5000);
        }
        return completedCount === projects.length;
      }
    },
    [isMinized]
  );

  useEffect(() => {
    if (isMinized && timer) {
      clearTimeout(timer);
    } else {
      if (!isMinized && checkForPopUpClose(uploadedBanners)) {
        timer = setTimeout(() => dispatch(setUploadedBanners([])), 5000);
      }
    }
  }, [uploadedBanners, isMinized]);

  useEffect(() => {
    let node: any = uploadListRef?.current;
    if (timer) {
      clearTimeout(timer);
    }
    // if(isMinized){
    //     setIsMinimized(false)
    // }
    // if(node){
    //     node.scrollIntoView({ behavior: "smooth" })
    // }
    let completedCount: {} = {};
    uploadedFolder.forEach((folder: any) => {
      let rootAndChildComplete: number = 0,
        rootAndChildInprogress: number = 0,
        rootComplete: number = 0,
        rootInprogress: number = 0;
      uploadedBanners.forEach((banners: any) => {
        // Total complete file in parent and sub folder
        if (
          folder.hashedStructureFolderIds.includes(banners?.parent) &&
          (banners?.status === 'COMPLETED' ||
            (banners?.status === 'INPROGRESS' && banners?.progress === 100))
        ) {
          rootAndChildComplete = rootAndChildComplete + 1;
        // Total inprogress file in parent and sub folder
        } else if (
          folder.hashedStructureFolderIds.includes(banners?.parent?.id) &&
          banners?.status === 'INPROGRESS'
        ) {
          rootAndChildInprogress = rootAndChildInprogress + 1;
        }
        // Total complete file in only parent folder
        if (
          folder.id === banners?.parent &&
          (banners?.status === 'COMPLETED' ||
            (banners?.status === 'INPROGRESS' && banners?.progress === 100))
        ) {
          rootComplete = rootComplete + 1;
        }
      });
      completedCount[folder.id] = {
        rootAndChildComplete,
        rootComplete,
        rootAndChildInprogress,
      };
    });
    dispatch(setFolderContentCount(completedCount));
  }, [uploadedBanners]);

  return uploadedBanners.length > 0 &&
    (uploadedFolder.length > 0 || currentFolderFile > 0) &&
    isLoggedIn ? (
    // return (uploadedBanners.length > 0 ?
    <div className="download-box">
      <div className="title">
        <div className="detail">
          <span>Uploads</span>
        </div>
        <div className="action">
          {isMinized ? (
            <span className="minimize" onClick={() => setIsMinimized(false)}>
              Maximize <PlusCircleOutlined className="icon" />
            </span>
          ) : (
            <span className="minimize" onClick={() => setIsMinimized(true)}>
              Minimize <MinusCircleOutlined className="icon" />
            </span>
          )}
          <span
            className="minimize close"
            onClick={() => {
              dispatch(setTempFolderContentCount(folderContentCount));
              if (
                (uploadedFolder.length === 1
                  ? uploadedFolder[0].id !== null
                  : true) &&
                activeFolderId !== -1 &&
                uploadActiveFolderId === activeFolderId
              ) {
                dispatch(
                  fetchFolders(
                    { type: 'project', parent: [activeFolderId] },
                    (res: any, err: boolean) => {
                      if (!err) {
                        dispatch(addFolder(res.results));
                        dispatch(setTempFolderContentCount([]));
                      }
                    }
                  )
                );
              }
              setIsMinimized(false);
              uploadFileList.forEach((file: any) => {
                if (file.token !== undefined) {
                  file.token.cancel();
                }
              });
              dispatch(setUploadedBanners([]));
              dispatch(setCurrentFolderFile(0));
              fileToProjectQueue.kill();
              dispatch(setUploadedFolder([]));
            }}
          >
            Close
            <CloseCircleOutlined className="icon" />
          </span>
        </div>
      </div>
      {!isMinized && (
        <div className={currentFolderFile > 0 ? 'list' : 'list folder-list'}>
          {uploadedFolder.length > 0 &&
            (uploadedFolder.length === 1
              ? uploadedFolder[0].id !== uploadActiveFolderId &&
                uploadedFolder[0].id != null
              : true) && (
              <>
                <div className="upload-popup-heading">
                  <div className="popup-heading">Folders</div>
                </div>
                {uploadedFolder.map(
                  (item: any, index: number) =>
                    item &&
                    item.id !== uploadActiveFolderId &&
                    item.id !== null && (
                      <FolderItem
                        key={index}
                        data={item}
                        folderContentCount={folderContentCount}
                        uploadedBanners={uploadedBanners}
                        uploadFileList={uploadFileList}
                        dispatch={dispatch}
                      />
                    )
                )}
              </>
            )}
          {currentFolderFile > 0 && (
            <>
              <div className="upload-popup-heading">
                <div className="popup-heading">
                  Files (
                  {
                    folderContentCount[
                      uploadActiveFolderId === 0 ? null : uploadActiveFolderId
                    ]?.rootAndChildComplete
                  }
                  /{currentFolderFile})
                </div>
              </div>
              {/* <AutoSizer disableWidth>
          {({ height }:any) => {
            return <List
              width={450}
              height={height}
              rowCount={uploadedBanners.length}
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              rowRenderer={rowRenderer}
              overscanRowCount={10}
            />
          }}
        </AutoSizer> */}
              {uploadedBanners.map(
                (item: any, index: number) =>
                  item &&
                  (item.parent === uploadActiveFolderId ||
                    item.parent === null) && (
                    <ProjectItem
                      key={item?.title + index}
                      data={item}
                      index={index}
                      isEditEnabled={isEditEnabled}
                      uploadedBanners={uploadedBanners}
                      uploadFileList={uploadFileList}
                    />
                  )
              )}
            </>
          )}
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={uploadListRef}
          ></div>
        </div>
      )}
    </div>
  ) : null;
};

export default UploadedDesignsPopUp;
