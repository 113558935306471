import React, { FC, useState } from 'react';
import CardItem from '../CardItem'
import brandColor from '../../../assets/svg/brandColor.svg'
import brandLogo from '../../../assets/svg/brandLogo.svg'
import customFiles from '../../../assets/svg/customFiles.svg'
import typography from '../../../assets/svg/typography.svg'
import { Card, Button } from 'pyxis-ui-kit'
import Typography from 'pyxis-ui-kit'
import './index.scss';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
const Title = Typography.Title;

const items = [
  {
    icon: customFiles,
    title: "custom files",
    description: "Add your files here",
    permission: 'create-new-banners'
  },
  {
    icon: typography,
    title: "typography",
    description: "Add your preferred typography",
    permission: 'manage-typography'
  },
  {
    icon: brandLogo,
    title: "brand logo",
    description: "Add your brand logo",
    permission: 'create-new-banners'
  },
  {
    icon: brandColor,
    title: "brand color",
    description: "Add your brand color palette",
    permission: 'manage-brand-colors'
  }
]


const ChooseToUpload: FC<{ routePage: any }> = ({ routePage }) => {
  const history = useHistory()
  const [selectedType, setSelectedType] = useState('');
  return <div className="choose-to-upload">
    <div className="container">
      <Title className="title">Upload Your Brand Assets</Title>
      <Title className="subtitle">Add everything from custom files, brand typography, to colors, logos, and templates</Title>
      <Card className="choose-card">
        <div className="item-container">
          {items.map((item: any, index: any) => (
            <CardItem item={item} key={index} index={index} setSelectedType={setSelectedType} selectedType={selectedType}/>
          ))}
        </div>
        <div className="btn-container">
          <Button
            btnsize="md"
            type="link"
            className="later"
            onClick={() => {
              history.push('/cocreate/library/images')
            }}
          >
            I will do it later
          </Button>
          <Button
            disabled={!selectedType}
            btnsize="md"
            type="primary"
            className="continue"
            onClick={() => { routePage(selectedType) }}>
            <span>Continue</span><RightOutlined className="right-arrow" />
          </Button>
        </div>
      </Card>
    </div>
  </div>
}

export default ChooseToUpload;
