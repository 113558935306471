import { fabric } from 'fabric';
import React, { FC, lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { clearDataEditor, renderImages, setErrMsg } from '../../store/actions';
import './AiquireCreate.scss';


const CreateSet: FC = () => {
  // hack
  const temp = fabric;
  const Design = lazy(() => import('../../components/DesignSection/DesignSectionAiquireSet'));
  const dispatch = useDispatch();
  const params:any = useParams();
  useEffect(() => {
    return () => {
      dispatch(setErrMsg(''));
      dispatch(clearDataEditor());
      // temp disable auto save
      dispatch(renderImages({ projectId: params.projectIdSet }, (res:any, isError:any) => {
        // console.log(res);
        // if (isError) openToast('error', 'Rendering failed.');
        // if (!isError) openToast('success', 'Rendering...');
      }));
    };
  });

  return (
    <div className="aiquire-create-page-wrapper">
      <ErrorBoundary>
        <Suspense fallback={<FullScreenSpinner />}>
          <Design />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default CreateSet;
