import React, { FC, useRef, useState, useEffect } from "react";
import PixisMagic from "../../assets/img/pixisMagic.png";
import { useParams } from "react-router";
import "./index.scss";
import { Button } from "pyxis-ui-kit";
import { Input } from "antd";
import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { HistoryRounded } from "@material-ui/icons";
import MyCreationsTable from "./MyCreationsTable";
import MyCreationsDetails from "./CreationDetailModel";
import { toast } from "react-toastify";
import httpservice from "../../HttpService/HttpService";
import { useSelector } from "react-redux";
import { RolesState } from "../../store/types";
import OutputLoading from "../../assets/img/loading.gif";
import { Pagination, RepromptActionBar, ActionButtons } from "./components";

const AiquireEditProfile: FC<any> = (props) => {
  const { TextArea } = Input;
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const classes = useStyles();
  const state = useSelector((state) => state);
  const params: any = useParams();
  const cardsRef = useRef(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMyCreationsOpen, setisMyCreationsOpen] = useState(false);
  const [userInput, setUserInput] = React.useState("");
  const [output, setOutput] = React.useState("");
  const [pureOutput, setPureOutput] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [isMyCreationsDetailsOpen, setisMyCreationsDetails] = useState(false);
  const [detailsItem, setDetailsItem] = useState(null);
  const selectedProductId = useSelector(
    (state: { roles: RolesState }) => state.roles.selectedProductId
  );
  const getTokenOrPublic = (state: any) =>
    state.authentication.token || state.publicLink.token;
  const [deletingRowIndex, setDeletingRowIndex] = useState(-1);
  const [creations, setCreations] = useState([]);
  const [rempromptActive, setRepromptActive] = useState(false);
  const [reprompt, setReprompt] = useState("");
  const [repromptLoading, setRepromptLoading] = useState(false);
  const [isRepromptEmpty, setIsRepromptEmpty] = useState(false);
  const [creativeId, setCreativeId] = useState(null);
  const [reprompts, setReprompts] = useState<any[]>([]);
  const [activeRepromptIndex, setActiveRepromptIndex] = useState<number>(0);

  useEffect(() => {
    getCreations();
    inputRef.current.focus();
  }, []);

  const subCategory =
    params.product.split("-").length > 1 ? params.product.split("-")[1] : "";
  const category =
    params.product.split("-").length > 0 ? params.product.split("-")[0] : "";

  const postCreative = async () => {
    setLoading(true);
    setOutput("");
    setPureOutput("");
    cardsRef.current.className = "cards-container";
    const subCategory =
      params.product.split("-").length > 1 ? params.product.split("-")[1] : "";
    const requestBody = {
      workspace_id: selectedProductId,
      category: mapCategory[category],
      subcategory: subCategory.toLowerCase(),
      user_input: userInput,
      reprompt: "",
    };

    await httpservice
      .post(
        process.env.REACT_APP_DESIGN_URI,
        "/api/creation/create",
        getTokenOrPublic(state),
        requestBody
      )
      .then((response) => {
        scrollRef.current.scrollBehaviour = "smooth";

        setOutput(parseTextToHTML(response?.data.data.output));
        setPureOutput(response?.data.data.output);
        setCreativeId(response?.data.data.id);
        cardsRef.current.className = "cards-container generated-output animate";
        setLoading(false);
        getCreations();
        setUserInput("");
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  const rePrompt = async () => {
    setRepromptLoading(true);
    setLoading(true);
    setOutput("");
    setPureOutput("");
    const subCategory =
      params.product.split("-").length > 1 ? params.product.split("-")[1] : "";
    const requestBody = {
      workspace_id: selectedProductId,
      category: mapCategory[category],
      subcategory: subCategory.toLowerCase(),
      user_input: reprompt.length == 0 ? "Regenerate text" : reprompt,
      reprompt: creativeId,
    };

    if (rePrompt.length == 0) setIsRepromptEmpty(true);

    await httpservice
      .post(
        process.env.REACT_APP_DESIGN_URI,
        "/api/creation/create",
        getTokenOrPublic(state),
        requestBody
      )
      .then((response) => {
        setOutput(parseTextToHTML(response?.data.data.output));
        setPureOutput(response?.data.data.output);
        // cardsRef.current.className = 'cards-container generated-output animate'
        setRepromptLoading(false);
        setLoading(false);
        getCreations();
        getHistory(creativeId);

        setRepromptActive(false);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  const getCreations = async () => {
    await httpservice
      .get(
        process.env.REACT_APP_DESIGN_URI,
        `/api/creation/list/${selectedProductId}`,
        getTokenOrPublic(state)
      )
      .then((response) => {
        setCreations(response.data.data);
      });
  };

  const handlePaste = () => {
    inputRef.current.focus();

    navigator.clipboard.readText().then((text) => {
      setUserInput(text);
    });
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(pureOutput);
    toast.success("Text Copied to Clip Board");
  };

  const handleDeleteRow = (index) => {
    setIsDeleting(true);
    setDeletingRowIndex(index);
  };

  const handleCancelDelete = () => {
    setDeletingRowIndex(-1);
    setIsDeleting(false);
  };

  const handleConfirmDelete = (creationId) => {
    setIsDeleting(false);
    httpservice
      .delete(
        process.env.REACT_APP_DESIGN_URI,
        `/api/creation/delete/${creationId}`,
        getTokenOrPublic(state)
      )
      .then((response) => {
        toast.error("Deleted Successfully");
        setDeletingRowIndex(-1);
        getCreations();
        setisMyCreationsDetails(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMyCreationDetailsDialog = (bool, item) => {
    setisMyCreationsDetails(bool);
    setDetailsItem(item);
  };

  const getHistory = async (id) => {
    await httpservice
      .get(
        process.env.REACT_APP_DESIGN_URI,
        `/api/creation/history/${id}`,
        getTokenOrPublic(state)
      )
      .then((response) => {
        setReprompts(response.data.data.sort((a: any, b: any) => a.id - b.id));
        setActiveRepromptIndex(response.data.data.length - 1);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postCreative();
  };

  const handleRepromptSubmit = (e) => {
    e.preventDefault();
    rePrompt();
  };

  return (
    <>
      <div className='profile-wrap'>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className='header'>
            <div className='title'>AI Text Simplifier</div>
            <div className='sub-title'>
              {output
                ? "Here is your final message, fine tuned to your liking"
                : "Simply enter your text and generate a message fine tuned to your liking, in a matter of seconds!"}
            </div>
            <div className='bread-crump'>
              <span className='simplify-coms'>AI Text Simplifier &nbsp;</span>
              <span className='arrow'>&nbsp;{"  >  "}&nbsp;</span>
              <span className={subCategory ? "simplify-coms" : "category"}>
                &nbsp; {category}
              </span>
              {subCategory && (
                <>
                  <span className='arrow'>&nbsp;{"  >  "}&nbsp;</span>
                  <span className='category'>
                    &nbsp; {mapSubCategory[subCategory]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div style={{ position: "absolute", right: "2rem" }}>
            <Button
              onClick={() => {
                setisMyCreationsOpen(true);
              }}
            >
              <div style={{ display: "flex", alignContent: "center" }}>
                {" "}
                <HistoryRounded style={{ fontSize: "16px" }} />
                <span style={{ marginLeft: "1rem" }}> My Creations</span>
              </div>
            </Button>
          </div>
        </div>
        <div className='main-container' ref={scrollRef}>
          <div ref={cardsRef} className='cards-container'>
            <div className='input-card empty'>
              <TextArea
                ref={inputRef}
                key={"fdf"}
                className='textarea'
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                rows={76}
                style={{ border: "none", resize: "none" }}
                bordered={false}
              />
              {userInput.length == 0 && (
                <div className='content'>
                  <div className='text'>
                    You can start writing your text here or can directly paste
                    here.
                  </div>
                  <div className='paste-btn' onClick={handlePaste}>
                    Paste Text
                  </div>
                </div>
              )}
              <Button
                btnsize='lg'
                type='primary'
                disabled={userInput.length > 0 && !loading ? false : true}
                className='primary submit'
                onClick={postCreative}
                style={{ position: "relative", margin: "2rem 0rem" }}
              >
                Generate
              </Button>
            </div>
            <div className='pixis-magic'>
              <img src={PixisMagic} />
            </div>
            {!loading ? (
              output ? (
                <div className='output-card '>
                  <div
                    className='output-text'
                    dangerouslySetInnerHTML={{ __html: output }}
                  ></div>
                  <div
                    className=''
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0rem 5rem",
                    }}
                  >
                    {rempromptActive ? (
                      <RepromptActionBar
                        setPureOutput={setPureOutput}
                        inputRef={inputRef}
                        isRepromptEmpty={isRepromptEmpty}
                        reprompt={reprompt}
                        setReprompt={setReprompt}
                        rePrompt={rePrompt}
                        loading={loading}
                        setRepromptActive={setRepromptActive}
                        handleRepromptSubmit={handleRepromptSubmit}
                        reprompts={reprompts}
                        activeRepromptIndex={activeRepromptIndex}
                        setActiveRepromptIndex={setActiveRepromptIndex}
                        setOutput={setOutput}
                      />
                    ) : (
                      <>
                        <div
                          className='pagination-container'
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {reprompts.length > 0 && (
                            <Pagination
                              setReprompt={setReprompt}
                              setPureOutput={setPureOutput}
                              setOutput={setOutput}
                              reprompts={reprompts}
                              activeRepromptIndex={activeRepromptIndex}
                              setActiveRepromptIndex={setActiveRepromptIndex}
                            />
                          )}
                        </div>
                        <ActionButtons
                          setRepromptActive={setRepromptActive}
                          copyToClipBoard={copyToClipBoard}
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className='default-output-card'>
                  <div className='default-text'>
                    <div className='text' style={{ textAlign: "center" }}>
                      Your fine tuned text will be displayed here upon
                      submission
                    </div>
                  </div>
                </div>
              )
            ) : (
              <>
                {repromptLoading ? (
                  <div className='output-card'>
                    <div
                      className='loading-container'
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={OutputLoading}
                        style={{ height: "150px", width: "150px" }}
                      />
                    </div>
                    <div
                      className=''
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 5rem",
                      }}
                    >
                      {rempromptActive ? (
                        <RepromptActionBar
                          setPureOutput={setPureOutput}
                          inputRef={inputRef}
                          isRepromptEmpty={isRepromptEmpty}
                          reprompt={reprompt}
                          setReprompt={setReprompt}
                          rePrompt={rePrompt}
                          loading={loading}
                          setRepromptActive={setRepromptActive}
                          handleRepromptSubmit={handleRepromptSubmit}
                          reprompts={reprompts}
                          activeRepromptIndex={activeRepromptIndex}
                          setActiveRepromptIndex={setActiveRepromptIndex}
                          setOutput={setOutput}
                        />
                      ) : (
                        <>
                          <div
                            className='pagination-container'
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {reprompts.length > 0 && (
                              <Pagination
                                setPureOutput={setPureOutput}
                                setReprompt={setReprompt}
                                setOutput={setOutput}
                                reprompts={reprompts}
                                activeRepromptIndex={activeRepromptIndex}
                                setActiveRepromptIndex={setActiveRepromptIndex}
                              />
                            )}
                          </div>
                          <ActionButtons
                            setRepromptActive={setRepromptActive}
                            copyToClipBoard={copyToClipBoard}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='input-card empty'>
                    <div className='loading-container'>
                      <img
                        src={OutputLoading}
                        style={{ height: "150px", width: "150px" }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Dialog
          classes={{ paper: classes.dialogContainer }}
          fullWidth
          maxWidth={"md"}
          open={isMyCreationsOpen}
          onClose={() => setisMyCreationsOpen(false)}
        >
          <div className='dialog-header'>
            <div className='dialog-title'>
              <div
                className='my-creations'
                style={{ fontSize: "18px", color: "#18236B" }}
              >
                My creations
              </div>
              <div className='sub-title'>
                Select any text to add new suggestions
              </div>
            </div>
            <IconButton
              aria-label='close'
              className='close-btn'
              onClick={() => setisMyCreationsOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className='dialog-content' style={{ paddingBottom: "8rem" }}>
            <div className='table'>
              <MyCreationsTable
                handleConfirmDelete={handleConfirmDelete}
                handleCancelDelete={handleCancelDelete}
                deletingRowIndex={deletingRowIndex}
                handleDeleteRow={handleDeleteRow}
                creations={creations}
                handleMyCreationDetailsDialog={(bool, item) =>
                  handleMyCreationDetailsDialog(bool, item)
                }
              />
            </div>
          </div>
        </Dialog>
        <MyCreationsDetails
          isDeleting={isDeleting}
          handleDeleteRow={handleDeleteRow}
          handleConfirmDelete={handleConfirmDelete}
          handleCancelDelete={handleCancelDelete}
          item={detailsItem}
          open={isMyCreationsDetailsOpen}
          handleClose={setisMyCreationsDetails}
        />
      </div>
    </>
  );
};

function parseTextToHTML(text) {
  const encodedText = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  const finalHTML = `<div style="white-space: pre-line;">${encodedText}</div>`;
  return finalHTML;
}

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
  },
  cell: {
    border: "none",
    padding: "8px",
    fontSize: "12px",
    height: "54px",
  },
  actionCell: {
    display: "flex",
    alignItems: "center",
    maxWidth: "200px",
  },
  hoverRow: {
    "&:hover": {
      backgroundColor: "#EEEEFF",
    },
    height: "48px",
  },
  headerRow: {
    backgroundColor: "#FCFCFF",
    "& .MuiTableCell-root": {
      padding: "16px", // Adjust the padding as per your requirements
    },
  },
  deleteButton: {
    backgroundColor: "#FFF9F9",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    color: "#F16060",
    height: "4.5rem",
  },
  cancelDeleteButton: {
    background: "#FFF9F9",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    padding: "6px 12px",
    fontSize: "12px",
    // width: '64px',
    height: "28px",
    lineHeight: "1.43",

    color: "#F16060",
  },
  confirmDeleteButton: {
    padding: "6px 12px",
    fontSize: "12px",
    // width: '64px',
    height: "28px",
    background: "#F16060",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    color: "white",
    marginLeft: "2rem",
    whiteSpace: "nowrap",
  },
  deleteIcon: {
    color: "#F16060",
    fontSize: "14px",
  },
  deletingRow: {
    background: "#FFEBEB",
  },
  dialogContainer: {
    borderRadius: "20px", // Change the border radius value as per your requirement
    zIndex: 300,
    overflow: "hidden",
  },
});

const mapCategory = {
  "Transaction alert": "transaction",
  "Regulatory or Informational": "regulatory",
  "Complaint Desk Response": "complaint",
};

const mapSubCategory = {
  sms: "SMS alert",
  Email: "Email alert",
};

export default AiquireEditProfile;
