export const GET_HYPER_PROJECT = 'GET_HYPER_PROJECT';
export const GET_HYPER_PROJECT_SUCCESS = 'GET_HYPER_PROJECT_SUCCESS';
export const LOADING_HYPER = 'LOADING_HARPER';
export const LOADING_HYPER_OFF = 'LOADING_HARPER_OFF';
export const GET_LAYER_ASSETS = 'GET_LAYER_ASSETS';
export const GET_LAYER_ASSETS_SUCCESS = 'GET_LAYER_ASSETS_SUCCESS';
export const GET_ASSET_DATA = 'GET_ASSET_DATA';
export const GET_ASSET_DATA_BULK = 'GET_ASSET_DATA_BULK';
export const UPDATE_PROJECT_HYPER = 'UPDATE_PROJECT_HYPER';
export const UPDATE_HYPER_PROJECT_DETAILS = 'UPDATE_HYPER_PROJECT_DETAILS';
export const GET_ASSET_COMBINATION_DATA = 'GET_ASSET_COMBINATION_DATA';
export const GET_ASSET_COMBINATION_DATA_SUCCESS = 'GET_ASSET_COMBINATION_DATA_SUCCESS';
export const UPDATE_ASSET_COMBINATION_DATA = 'UPDATE_ASSET_COMBINATION_DATA';
export const UPDATE_ASSET_DATA = 'UPDATE_ASSET_DATA';
export const GENERATE_COMBINATIONS = 'GENERATE_COMBINATION';
export const ADD_ASSETS = 'ADD_ASSETS';
export const DELETE_ASSET = 'DELETE_ASSET';
export const UPDATE_HYPER_DETAILS = 'UPDATE_HYPER_DETAILS';
export const RENAME_HYPER_LAYER = 'RENAME_HYPER_LAYER';
export const UPDATE_ALL_ASSETS_READY_STATUS = 'UPDATE_ALL_ASSETS_READY_STATUS';