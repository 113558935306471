// Component used in
// 1. Add new folder (Library,design,creative automation->same API)
// 2. Upload file (Library)
// Drawerstep 0 and 1->Add new folder
// Drawerstep 2 and 3->File upload
import React, {FC,useState,useEffect } from 'react';
import './index.scss'
import { fetchFolders, setActiveFolder,  setSelectedFolders,
    setProjects, addNewFolder, uploadLibraryFile,setFolderLoader,addFolder, setFolderBreadcrumb} from '../../../store/actions';
import {Banner, FolderState} from '../../../store/types';
import { CloseOutlined, Loading3QuartersOutlined, RightOutlined,UploadOutlined} from '@ant-design/icons';
import {Button, Drawer, Input, DragAndDrop} from 'pyxis-ui-kit';
import { SuccessScreen } from '../../shared/SuccessScreen';
import { S3_BASE_URL } from '../../../utilities/paths';
import { ReactComponent as upload} from '../../../assets/svg/upload.svg';
import Icon from '@ant-design/icons'
import { openToast } from '../../../Toasts';
import { useDispatch, useSelector } from 'react-redux';

interface FolderAndFileUploadAlertInterface{
    isDrawerVisible:boolean,
    type:string,
    changeActiveFolderInRouteForward?:Function,
    projectType:string,
    drawerVisibleFor:string,
    setDrawerVisible:Function,
    isGlobalCreate:boolean
}

export const FolderAndFileUploadAlert:FC<FolderAndFileUploadAlertInterface>=({type,changeActiveFolderInRouteForward,projectType,isDrawerVisible,drawerVisibleFor,setDrawerVisible,isGlobalCreate})=>{ 
    const folderRef = React.useRef<any>(null);
    const [folderName,setFolderName]=useState('')
    const [drawerStep,setDrawerStep]=useState(0)
    const [files,setFiles]=useState<any>([])
    const [currentFolder,setCurrentFolder]=useState<any>([])
    const [loader,setLoader]=useState(false)
    const state = useSelector((state: {folders: FolderState})=>(state.folders));
    const {activeFolderId,banners,folderBreadcrumb} = state;
    const dispatch = useDispatch();

    useEffect(()=>{
        if(drawerVisibleFor==='uploadFile' || drawerVisibleFor === 'globalUploadFile'){
            setDrawerStep(2)
        }
    },[isDrawerVisible])

    useEffect(()=>{
        if(drawerVisibleFor==='folder' || drawerVisibleFor === 'globalFolder'){
            setTimeout(()=>{
                folderRef.current!.focus({
                    cursor: 'end',
                });
            },[50])
        }
    },[folderRef])

    const onChange=(info:any)=>{
        setFiles(info.fileList)
    }
    const handleAddDrawerClose=()=>{
        setDrawerVisible('')
    }
    const appendProject = (banner:any)=>{
        dispatch(setProjects([...banner,...banners]));
    }
    const handleUpload=()=>{
        let file = files.reduce((acc: any[], val: any) => {
              return [...acc, val.originFileObj]
        },[]);
        setLoader(true)
        dispatch(uploadLibraryFile({file:file,type: "direct",folder: activeFolderId !== -1? activeFolderId:undefined},(res:any,err:boolean)=>{
            if(!err){
                let banners:any[] = (Array.isArray(res.data)?res.data:[res.data]).reduce((acc:any,data:any)=>{
                    return [...acc,{
                        id: data.id,
                        parent: 0,
                        title: data.title,
                        type: data.resource_type,
                        can_manage: true,
                        rendered_file: S3_BASE_URL + data.file_url
                    }]
                },[])
                const resBanner = banners;
                resBanner.sort(function(
                  a: Banner,
                  b: Banner
                ) {
                  return a.id - b.id;
                }).reverse();
                appendProject(resBanner)
                setLoader(false)
              }else{
                 setLoader(false)
              }
              setDrawerStep(3)
        }))
    }

    const handleAddFolder=()=>{
        setLoader(true)
        if(projectType==="folder"){
            if(folderName.length===0){
                openToast('warn', 'Please enter a valid name.')
                setLoader(false)
            }else if(folderName.length>265){
                openToast('warn', 'Folder name can have maximum 256 characters.')
                setLoader(false)
            }else{
                dispatch(addNewFolder({name:folderName,type:isGlobalCreate ? "file" : type,parent:activeFolderId},(res:any,err:boolean)=>{
                if(!err){
                    setCurrentFolder(res.data)
                    setFolderName('')
                    setDrawerStep(1)
                }
                setLoader(false)
                }))  
            }
        } 
    } 
    const handleAddFile=()=>{
        setDrawerStep(2)
        changeActiveFolderInRouteForward&&changeActiveFolderInRouteForward(currentFolder.id);
        dispatch(setSelectedFolders([]));
        dispatch(setActiveFolder(currentFolder.id));
        dispatch(setFolderLoader(true))
        dispatch(setFolderBreadcrumb([...folderBreadcrumb, currentFolder]))
        dispatch(fetchFolders({type: type,parent: [currentFolder.id]},(res:any,err:boolean)=>{
            dispatch(setFolderLoader(false))
            if(!err){
                dispatch(addFolder(res.results));
            }
        }));
    }

    const closeDrawer=()=>{
        setFiles([])
        setDrawerStep(0)
        setDrawerVisible('')
        setFolderName('')
    }
    return <Drawer 
    className="add-drawer" 
    closable={false}
    visible={isDrawerVisible} 
    onClose={closeDrawer} 
    keyboard
    footer={
        <div className="add-drawer-footer">
        {/* Add folder */}
        {drawerStep ===0 ? 
        <>
            <Button className="add-drawer-cancel" onClick={handleAddDrawerClose} type="link">
                Cancel
            </Button>
            <Button 
            className="add-drawer-submit"
            onClick={handleAddFolder}
            disabled={!folderName}
            type="primary">
                Save
                <span className="add-drawericon"><RightOutlined/></span>
                {loader&&<span><Loading3QuartersOutlined spin /></span>}
            </Button>
        </>
        : drawerStep === 1 ?
        <div className="success-footer">
            <Button className="success-close" onClick={closeDrawer} type="link">
                Close
            </Button>
            <div className="action-container">
                <Button type="default" className="success-add-folder" onClick={()=>{
                    setDrawerStep(0)
                    setTimeout(()=>{
                        folderRef.current!.focus({
                            cursor: 'end',
                        });
                    },[100])
                }}>Add one more folder</Button>
                {type==="file"&&<Button type="primary" className="success-add-file" onClick={handleAddFile}>Add files to folder</Button>} 
            </div>
        </div>
        // upload file
        : drawerStep === 2 ?
        <div className="upload-footer">
            <Button type="link" className="upload-go-back" onClick={closeDrawer}>Close</Button>
            <Button type="primary" className="upload-btn" onClick={handleUpload} disabled={!files.length||loader}><span className="upload">Upload</span><UploadOutlined/>{loader&&<span className="loader"><Loading3QuartersOutlined spin /></span>}</Button>
        </div> :
        <div className="file-success-footer">
            <Button type="primary" className="close" onClick={closeDrawer} autoFocus>Close</Button>
        </div>
        }
        </div> 
    } 
>
    {/* Add folder */}
    {drawerStep ===0 ? 
    <div className="upload-alert">
        <h3 className="add-drawer-title">Create new folder</h3>
        <span className="add-drawer-subtitle">Create custom folders to organize all your files</span>
        <span className="add-drawer-label">Folder name</span>
        <Input
            placeholder="Enter your folder name"
            className="add-drawer-input"
            value={folderName}
            onChange={(e:any)=>setFolderName(e.target.value)}  
            onKeyDown={(e:any)=>{if(e.key === 'Enter'){handleAddFolder()}}}
            ref={folderRef}
        />
    </div>  
    : drawerStep === 1 ? <SuccessScreen
        title="Great! Folder created successfully"
        subTitle="Discovering your files just got easier!"
    />
    // Upload File
    : drawerStep === 2 ? 
    <div className="upload-file">
        <h3 className="upload-title noselect">Upload files</h3>
        <h4 className="upload-sub-title noselect">Drag and drop files here. Or simply choose a file to upload.</h4>
        <DragAndDrop 
          className={files.length===0 ? `` : `small-box`}
          listType="picture-card"
          multiple={true}
          accept="image/jpg,image/jpeg,image/png,image/gif"
          onChange={onChange}
          showUploadList={{ showPreviewIcon: false,removeIcon:<CloseOutlined/> }}
          beforeUpload={()=>false}   
          disabled={loader}
        >
          <p className="ant-upload-drag-icon"><Icon component={upload}/></p>
          <p className="noselect">Drop files here</p>
          <p className="noselect">or</p>
          <div className="upload-btn noselect"><span>Choose from desktop</span>
          </div>
          <p className="max-size noselect">JPG, PNG, GIF are supported</p>
        </DragAndDrop>
    </div> : <SuccessScreen
        title="Great! Files uploaded successfully"
        subTitle="Now you have all your files in one place!"
    />
    }
    
</Drawer>
}