import {
  ENABLE_CROP,
  CANCEL_CROP,
  CROP_DONE,
  ENABLE_SVG_CROP,
  EnableCropAction,
  CancelCropAction,
  CropDoneAction,
  CropState,
  RESET_CROP,
  ResetCropStateAction,
  EnableSvgCropAction,
} from '../types/cropTypes';

const initialState: CropState = {
  cropEnabled: false,
  cropDone: false,
  svgCropDone: false,
  svgCropEnabled: false,
};

export const cropStateReducer = (
  state = initialState,
  action:
    | EnableCropAction
    | CancelCropAction
    | CropDoneAction
    | ResetCropStateAction
    | EnableCropAction
    | EnableSvgCropAction
): CropState => {
  switch (action.type) {
    case ENABLE_CROP:
    case CANCEL_CROP:
    case CROP_DONE:
    case RESET_CROP:
    case ENABLE_SVG_CROP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
