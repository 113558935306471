import React, { useState } from 'react';
import { Dropdown, Menu, MenuItem, SubMenu, Checkbox } from 'pyxis-ui-kit';
import { getStatusColor } from '../../../utilities/common-function';

const StatusFilter:React.FC<{
    label:string;
    changeFilter: Function;
    value: string[];
    icon?:any;
}> = ({label,icon,changeFilter,value}) => {
    const [visible,setVisible] = useState(false);
    

    return <div className="filter">
        <div className="label">{label}</div>
        <Dropdown 
        visible={visible}
        onVisibleChange={(flag)=>setVisible(flag)}
        trigger={['click']}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        overlay={
            <Menu>
                <MenuItem>
                    <Checkbox checked={value.length === 0}
                        onChange={()=>{
                            changeFilter(null)
                        }}
                    >All Statuses</Checkbox>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={value.includes('Approved')}
                        onChange={(e)=>{
                            changeFilter({status: 'approved',value: 'Approved',checked: e.target.checked});
                        }}
                    >Approved</Checkbox>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={value.includes('Pending')}
                        onChange={(e)=>{
                            changeFilter({status: 'pending',value: 'Pending',checked: e.target.checked});
                        }}
                    >Pending</Checkbox>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={value.includes('Rejected')}
                        onChange={(e)=>{
                            changeFilter({status: 'rejected',value: 'Rejected',checked: e.target.checked});
                        }}
                    >Rejected</Checkbox>
                </MenuItem>
            </Menu>
        }>
            <div className="action">
                <div className="name">
                    {value.length === 1 && <span className="circle" style={{background: getStatusColor(value[0].toLowerCase())}}></span>}
                    <span className={value.length === 1? "ml-10 text-ellipsis":"text-ellipsis"}>{value.length === 0? 'All Statuses':value.join(',')}</span>
                </div>
                {value.length !== 1 ? <span className="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6L8 10L13 6" stroke="#8090E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>:<span className="icon" onClick={(e)=>{
                    changeFilter(null)
                    e.stopPropagation()
                }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.9497 12.9497C10.2161 15.6834 5.78389 15.6834 3.05026 12.9497C0.316581 10.2161 0.316581 5.78389 3.05026 3.05026C5.78394 0.31658 10.2161 0.31658 12.9497 3.05026C15.6834 5.78393 15.6834 10.2161 12.9497 12.9497ZM5.83453 6.70069L7.13384 8.00001L5.83453 9.29932C5.59545 9.53839 5.59545 9.92645 5.83453 10.1655C6.0736 10.4046 6.46166 10.4046 6.70073 10.1655L8.00005 8.86621L9.29936 10.1655C9.53843 10.4046 9.92649 10.4046 10.1656 10.1655C10.4046 9.92645 10.4046 9.53839 10.1656 9.29932L8.86625 8.00001L10.1656 6.70069C10.4046 6.46162 10.4046 6.07356 10.1656 5.83449C9.92649 5.59541 9.53843 5.59541 9.29936 5.83449L8.00005 7.1338L6.70073 5.83449C6.46166 5.59541 6.0736 5.59541 5.83453 5.83449C5.59545 6.07356 5.59545 6.46162 5.83453 6.70069Z" fill="#4253A9"/>
                    </svg>    
                </span>}
            </div>
        </Dropdown>
    </div>
}

export default StatusFilter;