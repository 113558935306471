import { Button, Dropdown, Menu, MenuItem } from "pyxis-ui-kit";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import activePin from '../../../assets/img/activePin.png';
import inactivePin from '../../../assets/img/inactivePin.png';
import { deleteApprovalComment, fetchMembersList, setApprovalComments } from "../../../store/actions";
import { ApprovalState, AuthState, FolderState, HyperProjectFoldersState, RolesState } from "../../../store/types";
import { openToast } from "../../../Toasts";
import { permissionDeniedClick } from "../../../utilities/common-function";
import { Avatar, EditorInput } from '../../shared';
import './index.scss';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import CheckCircleOutlined from "../../../assets/svg/check-circle-outline.svg";
import CheckCircleOutlinedFilled from "../../../assets/svg/check-circle-outline-filled.svg";
import moment from "moment";

interface AddCommentInterface {
  pinCoordinates: any;
  data: any;
  setShowAddComment: Function;
  entityType: string;
  isNewComment: boolean;
  selectedBanner: number;
  commentIndex: number;
  setCommentIndex: Function;
  setReplyCommentIndex: Function;
  client: WebSocket;
  setInFocus: Function;
  placeLeft: boolean;
  placeTop: boolean;
  zoom: number;
  coordinatesRatio: any;
}

interface CommentInterface {
  comment: any;
  canComment: boolean;
  client: WebSocket;
  setReplyCommentIndex: Function;
  userSuggestions: any[];
  setInFocus: Function;
}

const CommentDisplay: FC<CommentInterface> = ({
  comment, 
  canComment, 
  client, 
  setReplyCommentIndex,
  userSuggestions,
  setInFocus
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: { approval: ApprovalState }) => state.approval);
  const { comments } = state;
  const commentResolution = useSelector((state: { approval: ApprovalState }) => state.approval.comments_resolution);
  const authState = useSelector((state: { authentication: AuthState }) => state.authentication);
  const { userData } = authState;

  const editorEditRef = useRef<any>(null);
  let hours = moment().diff(moment(comment.modified_at), "hours");
  const created_by = comment?.user_id?.firstName + " " + comment?.user_id?.lastName;
  const profilePicture = comment?.user_id?.profilePicture;
  const profileColour = comment?.user_id.profileColour;
  const isChildComment = comment.parent_comment_id;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [resolutionChanged, setResolutionChanged] = useState(false);
  const shouldRenderChild = useDelayUnmount(resolutionChanged, 500);
  const isOtherUser = userData.userId !== comment?.user_id?.userId;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleEditClick = useCallback((text: string) => {
    if(!text.trim()) {
      openToast("info", "Comment cannot be empty!");
      return;
    }
    if(client.readyState === 1) {
      client.send(JSON.stringify({
        comment: text,
        comment_id: comment.id,
        action: "comment_updated",
      }));
    }
    setEditMode(false);
  }, [comment.id, setEditMode, client]);

  const handleEdit = useCallback(() => {
    setInFocus(false);
    if(editorEditRef?.current?.isInitialValueChanged()) {
      handleEditClick(editorEditRef?.current?.getRawText());
    } else {
      openToast("warn", "Please make some changes before saving");
    }
  }, [handleEditClick]);

  const handleActionClick = useCallback((type: string, index: number) => {
    setInFocus(false);
    switch (type) {
      case "delete": {
        const indexToDelete = comments.findIndex((comment:any) => comment.id === index);
        const replyCommentsToDelete = comments.filter((comment:any) => comment.parent_comment_id === index);
        if(comments[indexToDelete]?.pin_number && (indexToDelete + 1) < comments.length) {
          for(let i = (indexToDelete + 1); i < comments.length; i++) {
            if(!comments[i].parent_comment_id && comments[i].pin_number) {
              comments[i].pin_number -= 1;
            }
          }
        }
        replyCommentsToDelete.length > 0 && replyCommentsToDelete.forEach((comment:any, idx:number) => {
          dispatch(deleteApprovalComment(replyCommentsToDelete[idx].id, (response:any, error:boolean) => {
            if (!error) {
              replyCommentsToDelete.splice(idx, 1);
              dispatch(setApprovalComments(comments));
            }
          }));
        });
        dispatch(deleteApprovalComment(comments[indexToDelete]?.id, (response:any, error:boolean) => {
          if (!error) {
            comments.splice(indexToDelete, 1);
            dispatch(setApprovalComments(comments));
          }
        }));
        return;
      }
    }
  }, [comments, comments.length]);

  function useDelayUnmount(resolutionChanged:boolean, delayTime:number) {
    useEffect(() => {
      let timeoutId:number;
      if (resolutionChanged) {
        timeoutId = setTimeout(() => changeCommentResolution(comment.resolved_status), delayTime);
      }
      return () => clearTimeout(timeoutId);
    }, [resolutionChanged, delayTime]);
  }

  const changeCommentResolution = (resolved_status:boolean) => {
    if(!comment.parent_comment_id) {
      setReplyCommentIndex(-1);
    }
    comment.resolved_status = !resolved_status;
    comments.forEach((c:any) => {
      if(c.parent_comment_id === comment.id && client && client.readyState === 1) {
        client.send(
          JSON.stringify({
            comment: c.comment,
            comment_id: c.id,
            action: "resolution_updated",
            resolution_status: comment.resolved_status,
          })
        );
        if(commentResolution === -1) {
          setResolutionChanged(!resolutionChanged);
        }
      }
    });
    if(client && client.readyState === 1) {
      client.send(JSON.stringify({
        comment: comment,
        comment_id: comment.id,
        action: "resolution_updated",
        resolution_status: comment.resolved_status,
      }));
      if(commentResolution === -1) {
        setResolutionChanged(!resolutionChanged);
      }
      openToast("success", "Comment resolution has been updated!");
    }
  };

  useEffect(() => {
    comments.forEach((c:any) => {
      if(c.parent_comment_id === comment.id && client && client.readyState === 1 && commentResolution !== -1) {
        client.send(
          JSON.stringify({
            comment: c.comment,
            comment_id: c.id,
            action: "resolution_updated",
            resolution_status: comment.resolved_status,
          })
        );
        if(commentResolution === -1) {
          setResolutionChanged(!resolutionChanged);
        }
      }
    });
  }, [comments.length, client]);

  return (
    <div className="comment-display-container">
      <div className={`title ${isOtherUser ? "other-user" : ""}`}>
        <Avatar 
          size="x-small"
          initials={created_by[0] ? created_by[0] : ""}  
          showInitials={profilePicture ? !profilePicture : true}
          backgroundColor={profileColour ? profileColour : ""}
          src={profilePicture ? profilePicture : ""}
        />
        <div className="user-info">
          <h3 className="creator">{created_by}</h3>
          <div className="last-modified">
            {hours <= 1
              ? moment(comment.modified_at)
                .startOf("minute")
                .fromNow()
              : hours <= 24
              ? moment(comment.modified_at)
                .startOf("hour")
                .fromNow()
              : hours <= 48
              ? "Yesterday"
              : moment(comment.modified_at).isSame(new Date(), "week")
              ? moment(comment.modified_at).format("dddd")
              : moment(comment.modified_at).format("ll")}
          </div>
        </div>
        <div className="actionable-section">
          {(canComment && !isOtherUser) && (
            <Dropdown
              trigger={["click"]}
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              visible={isOpen}
              onVisibleChange={(flag:boolean) => {
                setIsOpen(flag);
              }}
              overlay={
                <Menu>
                  <MenuItem
                    className="action-edit"
                    onClick={() => {
                      setEditMode(true);
                      setIsOpen(false);
                    }}>
                      <EditOutlined className="menu-icon" />
                      Edit
                  </MenuItem>
                  <MenuItem
                    className="comment-delete"
                    onClick={() => {
                      handleActionClick("delete", comment.id);
                      setIsOpen(false);
                    }}>
                      <DeleteOutlined className="menu-icon" />
                      Delete
                  </MenuItem>
                </Menu>
              }>
                <EllipsisOutlined className="comment-menu" />
            </Dropdown>
          )}
          {!comment.parent_comment_id && <div
            className="resolution-section"
            onClick={() => {
              setResolutionChanged(!resolutionChanged);
            }}>
              {comment.resolved_status === true ? (
                <img src={CheckCircleOutlinedFilled} />
              ) : (
                <img src={CheckCircleOutlined} />
              )}
          </div>}
        </div>
      </div>
      <div className="comment-info">
        {isEditMode ? (
          <div className="comment-editable" onBlur={() => setEditMode(false)}>
            <EditorInput
              ref={editorEditRef}
              initialValue={comment.comment.replace("\n", "<pre/>")}
              placeholder="Write your reply here"
              onPressEnter={handleEdit}
              mentions={userSuggestions}
              tagsArr={[]}
              className="edit-editor"
              onEscape={() => {setInFocus(false); setEditMode(false);}}
              setInFocus={setInFocus}
            />
          </div>
        ) : (
          <p
            className="comment-text"
            onClick={toggleReadMore}
            dangerouslySetInnerHTML={{
              __html: isReadMore
                ? comment.comment.slice(0, 60) +
                  `<span class="read-or-hide">${
                    comment.comment.length > 60
                      ? `<span class="ellipsis">...</span>` + " Read more"
                      : ""
                  }</span>`
                : comment.comment +
                  `<span class="read-or-hide">${
                    comment.comment.length > 60 ? " Read less" : ""
                  }</span>`,
            }}
          ></p>
        )}
      </div>
    </div>
  );
}

const AddComment: FC<AddCommentInterface> = ({
  pinCoordinates, 
  data, 
  setShowAddComment, 
  entityType, 
  isNewComment, 
  selectedBanner,
  commentIndex,
  setCommentIndex,
  setReplyCommentIndex,
  client,
  setInFocus,
  placeLeft,
  placeTop,
  zoom,
  coordinatesRatio
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: { 
    approval: ApprovalState, folders: FolderState, hyperProject: HyperProjectFoldersState, authentication: AuthState
  }) => ({...state.approval, ...state.folders, hyperProjectData: state.hyperProject.hyperProjectData, ...state.authentication}));
  const banners = useSelector((state: { folders: FolderState }) => state.folders.bannerData.banners);
  const { userPermissions, selectedProductId }: any = useSelector((state: { roles: RolesState }) => state.roles);
  const { comments, bannerData, hyperProjectData } = state;
  const commentResolution = useSelector((state: { approval: ApprovalState }) => state.approval.comments_resolution);

  const canComment = userPermissions.indexOf("add-new-comments") > -1;
  const editorInputRef = useRef<any>(null);
  const editorReplyRef = useRef<any>(null);
  const commentsRef = useRef<any>(null);
  const [userSuggestions, setUserSuggestion] = useState([]);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showReplyOption, setShowReplyOption] = useState(false);

  const handlePost = useCallback((comment: string) => {
    const xCoordinate:number = pinCoordinates?.x * coordinatesRatio?.widthRatio; 
    const yCoordinate:number = pinCoordinates?.y * coordinatesRatio?.heightRatio;
    if(canComment) {
      if(!comment.trim()) return;
      editorInputRef?.current?.clearEditorState();
      setShowAddComment(false);
      let child_id = selectedBanner !== -1 ? banners[selectedBanner].id : undefined;
      if(client.readyState === 1) {
        client.send(
          JSON.stringify({
            comment: comment,
            child_id,
            xcoordinate: xCoordinate / zoom,
            ycoordinate: yCoordinate / zoom
          })
        );
      }
    } else {
      permissionDeniedClick();
    }
  }, [selectedBanner, client]);

  const handlePressEnter = useCallback(() => {
    handlePost(editorInputRef?.current?.getRawText());
  }, [handlePost]);

  const handleReplyPost = useCallback((comment: string) => {
    setShowReplyBox(true);
    if(canComment) {
      if(!comment.trim()) return;
      editorReplyRef?.current?.clearEditorState();
      let child_id = selectedBanner !== -1 ? banners[selectedBanner].id : undefined;
      if(client.readyState === 1) {
        client.send(
          JSON.stringify({
            comment: comment,
            parent_comment_id: data?.id,
            child_id
          })
        );
      }
    } else {
      permissionDeniedClick();
    }
  }, [data?.id, selectedBanner, client]);

  const handleReplyPressEnter = useCallback(() => {
    handleReplyPost(editorReplyRef?.current?.getRawText());
  }, [handleReplyPost]);

  const getUsersList:any = useCallback(() => {
    return new Promise((resolve) => {
      dispatch(fetchMembersList(
        {
          entityType: "product",
          entityId: selectedProductId ? selectedProductId : undefined,
          permissions: "view-all-assets",
        }, (res:any, err:boolean) => {
            if (!err) {
              resolve(res?.data?.users || []);
            } else {
              resolve([]);
              openToast("error", "Unable to fetch Members!");
            }
          }
        )
      );
    });
  }, [selectedProductId]);

  const getUserSuggestions = useCallback(async () => {
    const users: any[] = await getUsersList();
    setUserSuggestion(
      users.map((val: any) => ({
        ...val,
        id: val._id,
        name: `${val.firstName} ${val.lastName}`,
      }))
    );
  }, []);

  useEffect(() => {
    getUserSuggestions();
  }, [selectedBanner]);

  useEffect(()=>{
    let node:any = commentsRef?.current;
    if(node){
      node.scrollIntoView({ behavior: "smooth" })
    }
  }, [comments.length]);

  return (
    <div className="comment-box" style={{position: 'absolute', left: `${pinCoordinates?.x}px`, top: `${pinCoordinates?.y}px`}}>
      <div className={`comment-pin-container ${(data?.id === commentIndex || isNewComment) ? "active" : ""}`} onClick={()=>{
        if(commentIndex !== -1) {
          setCommentIndex(-1); 
        } else {
          setShowReplyOption(false);
          setCommentIndex(data?.id); 
        }
        setShowAddComment(false);
        setInFocus(false);
      }}>
        <img 
          className={`comment-pin ${(data?.id === commentIndex) ? "active" : ""}`}
          src={(data?.id === commentIndex) ? activePin : inactivePin}
        />
        {data?.pin_number && <span className={`pin-number ${(data?.id === commentIndex) ? "active" : ""}`}>
          {data?.pin_number}
        </span>}
      </div>
      {(data?.id === commentIndex) ?
        <div className={`show-comments-container ${placeLeft ? "left" : ""} ${placeTop ? "top": ""}`}>
          <CommentDisplay 
            comment={data} 
            canComment={canComment} 
            client={client} 
            setReplyCommentIndex={setReplyCommentIndex}
            userSuggestions={userSuggestions}
            setInFocus={setInFocus}
          />
          {(showReplyBox || (data.id === commentIndex)) && <div className="replies-list">
            <ul className="list">
              {comments.reduce((acc:any, comment:any, index:number) => {
                return [
                  ...acc, comment.parent_comment_id === commentIndex &&
                  <li className="list-item" key={index} ref={commentsRef}>
                    <CommentDisplay 
                      comment={comment} 
                      canComment={canComment} 
                      client={client} 
                      setReplyCommentIndex={setReplyCommentIndex}
                      userSuggestions={userSuggestions}
                      setInFocus={setInFocus}
                    />
                  </li>
                ]
              }, [])}
            </ul>
          </div>}
          <div className="comment-reply" onMouseDown={()=>setShowReplyOption(true)} onKeyDown={(e:any) => {
            if(e.key === 'Escape' && !showReplyOption && commentResolution !== 1) {
              setInFocus(false);
              setCommentIndex(-1);
            } else if(e.key !== 'Escape') {
              setShowReplyOption(true);
              setInFocus(true);
            }
          }}>
            <EditorInput
              ref={editorReplyRef}
              placeholder="Reply"
              onPressEnter={handleReplyPressEnter}
              mentions={userSuggestions}
              className="reply-comment-input"
              onEscape={() => {setInFocus(true); setShowReplyOption(false);}}
              setInFocus={setInFocus}
            />
            {showReplyOption && <div className="reply-footer">
              <Button
                className="cancel-btn"
                onClick={() => {editorReplyRef?.current?.clearEditorState(); setShowReplyOption(false);}}>
                  Cancel
              </Button>
              <Button
                className="post-btn"
                type="primary"
                onClick={() => handleReplyPost(editorReplyRef?.current?.getRawText())}>
                  Post
              </Button>
            </div>}
          </div>
        </div> :
        isNewComment && <div className={`add-comment-container ${placeLeft ? "left" : ""} ${placeTop ? "top": ""}`}>
          <div className="comment-text-editor">
            <EditorInput
              ref={editorInputRef}
              placeholder="Add a comment"
              onPressEnter={handlePressEnter}
              mentions={userSuggestions}
              className="comment-input"
              onEscape={() => {setInFocus(false); setShowAddComment(false);}}
              setInFocus={setInFocus}
            />
          </div>
          <div className="comment-footer">        
            <Button
              className="cancel-btn"
              onClick={() => setShowAddComment(false)}>
                Cancel
            </Button>
            <Button
              className="post-btn"
              type="primary"
              onClick={() => handlePost(editorInputRef?.current?.getRawText())}>
                Post
            </Button>
          </div>
        </div>
      }
    </div>
  );
}

export default AddComment;