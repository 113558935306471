import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateRound,
  deleteRound,
  duplicateApprovalRound,
  duplicateWorkflowRound,
  addRound,
  fetchWorkFlowData,
  fetchMembersList,
  addUserToRound,
  changeRound,
  removeUserFromRound,
  getUsersData,
  setWorkFlowData,
  createRound
} from '../../../store/actions';
import Menu from '../../shared/DropdownMenu';
import { ClickAwayListener } from '@material-ui/core';
import { RolesState, WorkFlowData, WorkflowState } from '../../../store/types';
import AlertPopUp from '../../shared/AlertPopUp';
import Modal from '../../shared/Modal';
import { openToast } from '../../../Toasts';
import Avatar from '../../shared/Avatar';
import { Button, Input } from 'pyxis-ui-kit';
import { HDFC_MFILTERIT_USER } from '../../../utilities/paths';

interface RoundInterface {
  data: any;
  workFlowId: number | undefined;
  index: number;
  isEditable: boolean;
  isViewCard: boolean;
}

interface ChooseMemberInterface {
  setOpenMembers: Function;
  members: any[];
  data: any;
  workFlowId: number | undefined;
  index: number;
}

const ChooseMemberDropDown: React.FunctionComponent<ChooseMemberInterface> = ({
  setOpenMembers,
  members,
  data,
  workFlowId,
  index,
}) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const addMemberToRound = (roundId:number,userId: string) => {
    if(workFlowId)
    dispatch(
      addUserToRound(
        {
          workflowId: workFlowId,
          roundId,
          userId
        },
        (res: any, err: boolean) => {
          if (!err) {
            if(res.data.approvers.length > 0){
              dispatch(
                getUsersData(
                  { userIds: res.data.approvers },
                  (userres: any, usererr: boolean) => {
                    if (!usererr) {
                      res.data['approvers'] =
                        userres.data.users;
                      dispatch(changeRound(res.data, index));
                      setOpenMembers(false);
                    }
                  }
                )
              );
            }else{
              dispatch(changeRound(res.data, index));
              setOpenMembers(false);
            }
          }
        }
      )
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenMembers(false)}>
      <div
        className='dropdown-container addpeople-dropdown'
        style={{ minWidth: '20.0rem' }}
      >
        <div className='list'>
          <div className='search'>
            <Input
              type='text'
              placeholder='Search'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          {members.map(
            (member: any, memberIndex: number) =>
              (member.firstName + ' ' + member.lastName)
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1 && (
                <div
                  className='item'
                  key={member._id}
                  onClick={() => {
                    if(workFlowId && data.id === -1){
                      dispatch(createRound({workFlowId,round:{
                        name: data.name,
                        settings: {
                          everyonemustapprove: data.settings.everyonemustapprove
                        }
                      }},(res:any,err:boolean)=>{
                        if(!err){
                          dispatch(changeRound(res.data,index))
                          addMemberToRound(res.data.id, member._id);
                        }
                      }))
                    }
                    if (data.id !== -1){
                      addMemberToRound(data.id, member._id);
                    }
                  }}
                >
                  <div className='circle'>
                    <Avatar 
                      size="x-small"
                      initials={member.firstName[0]||""} 
                      showInitials={!!(member && !member.profilePicture)}
                      backgroundColor={member?.profileColour}
                      src={member?member.profilePicture:""}
                    />
                    {/* <span>{member.firstName[0]}</span> */}
                  </div>
                  <div className='name'>
                    <span>{member.firstName + ' ' + member.lastName}</span>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

const Round: React.FunctionComponent<RoundInterface> = ({
  workFlowId,
  isEditable,
  isViewCard,
  data,
  index,
}) => {
  const state = useSelector((state: { roles: RolesState }) => state.roles);
  const dispatch = useDispatch();
  const [roundName, setRoundName] = useState(data.name);
  const [roundSettings,setRoundSettings] = useState(data.settings);
  const [open, setOpen] = useState(false);
  const [openMembers, setOpenMembers] = useState(false);
  const [members, setMembers] = useState([]);
  const [openPreferences, setPreferences] = useState(false);
  const moreRef = useRef(null);
  const prefRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const filteredMembers = members.filter((m:any)=> !data.approvers.find((a:any)=> a._id === (m._id)));
  const { selectedProductId } = state;
  const workflowState = useSelector((state: {workflows: WorkflowState}) =>(state.workflows));
  const { workflowData } = workflowState;
  const [isAlertModal, openAlertModal] = useState(false);
  const [roundData,setRoundData]=useState('')

  useEffect(() => {
    // setLoading(false);
    setRoundName(data.name);
  }, [data.name]);

  useEffect(() => {
    setRoundSettings(data.settings);
  }, [data.settings]);

  useEffect(() => {
    setOpen(false);
  }, [isEditable]);

  const fetchUsers = () => {
    dispatch(
      fetchMembersList(
        {
          entityType: 'product',
          entityId: selectedProductId ? selectedProductId : undefined,
          permissions: 'grand-approval',
        },
        (res: any, err: boolean) => {
          if(!err){
            // hiding third party user mfilterit
            const updatedUsers = (res?.data?.users||[]).filter(({ _id }) => ![HDFC_MFILTERIT_USER].includes(_id))
            setMembers(updatedUsers);
          }else{
            setMembers([])
            openToast('error','Unable to fetch Members!')
          }
        }
      )
    );
  };

  const handleAddOrEditChange=(e?:any)=>{
    if(roundName.length === 0) setRoundName(data.name)
    if(data.name!==roundName){
      if (workFlowId && data.id !== -1 && roundName.length > 0){
        setRoundData('')
        dispatch(
          updateRound(workFlowId, { ...data, name: roundName },(updatedRound:any,err:boolean)=>{
            if (!err) {
              if(updatedRound.approvers.length > 0){
                dispatch(
                  getUsersData(
                    { userIds: updatedRound.approvers },
                    (
                      userres: any,
                      usererr: boolean
                    ) => {
                      if (!usererr) {
                        updatedRound['approvers'] =
                          userres.data.users;
                        dispatch(changeRound(updatedRound))
                      }
                    }
                  )
                );
              }else{
                dispatch(changeRound(updatedRound))
              }
            }
          })
        );
        e&&e.target.blur();
      }
      else{
        if(roundData===''||roundData.trim()!==e.target.value.trim()){
          setRoundData(e.target.value)
          openToast('success','Round Updated!')
        }
      }
    }
  }

  return (
    <div className={isViewCard ? 'round-card view' : 'round-card'} key={index}>
      <div
        className={
          isViewCard && isEditable
            ? 'flex space-between w-100'
            : 'flex space-between'
        }
      >
        <div className='flex'>
          <div className='symbol'>R{index + 1}</div>
          {isEditable ? (
            <>
              <div className='name'>
                <Input
                 className="round-name"
                  type='text'
                  value={roundName}
                  onBlur={(e:any)=>{handleAddOrEditChange(e)}}
                  onChange={(e:any) => {
                    setRoundName(e.target.value);
                  }}
                  onKeyDown={(e:any)=>{
                    if (e.key === 'Enter') {
                      handleAddOrEditChange(e);
                    }
                  }}
                />
              </div>
              {roundName !== data.name && loading && (
                <div
                  className={'check loading'}
                >
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                  />
                </div>
              )}
            </>
          ) : (
            <div className='name'>{data.name}</div>
          )}
        </div>
        {isEditable && (
          <>
             <span
              className='options'
              onClick={() => setOpen(true)}
              ref={moreRef}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z" fill="black"/>
              </svg>
            </span>
            <Menu
              open={open}
              anchorRef={moreRef}
              handleClose={() => setOpen(false)}
            >
              <div className='dropdown'>
                <div className='list action-list'>
                {data.id !== -1 &&<div
                    className='item'
                    onClick={(e) => {
                      e.stopPropagation();
                      if (workFlowId)
                        dispatch(
                          duplicateWorkflowRound(
                            { workflow_id: workFlowId, round_id: data.id },
                            (res: any, err: boolean) => {
                              setOpen(false);
                              if (!err) {
                                dispatch(
                                  fetchWorkFlowData(
                                    workFlowId,
                                    (res:any,err:boolean)=>{
                                      if(!err){
                                        let ids:string[] = res.data.rounds.reduce((acc:any,val:any)=>{
                                          return [...acc,...val.approvers]
                                        },[]);
                                        if(ids.length > 0){
                                          dispatch(getUsersData({userIds: ids},(userres:any,usererr:boolean)=>{
                                              if(!usererr){
                                                  let { users } = userres.data;
                                                  res.data.rounds.map((round:any,index:number)=>{
                                                      round.approvers.map((a:any,r:number)=>{
                                                          round.approvers[r] = users.find((u:any)=>u._id === a)||"";
                                                      });
                                                  })
                                                  dispatch(setWorkFlowData(res.data))
                                              }
                                          }))
                                        }else{
                                          dispatch(setWorkFlowData(res.data))
                                        }
                                      }
                                    }
                                  )
                                );
                              }
                            }
                          )
                        );
                    }}
                  >
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L3 7L4.63 8.27L12 14L19.36 8.27L21 7L12 0ZM19.37 10.73L12 16.47L4.62 10.74L3 12L12 19L21 12L19.37 10.73ZM19.37 15.73L12 21.47L4.62 15.74L3 17L12 24L21 17L19.37 15.73Z" fill="#515151"/>
                    </svg>
                    <span className='ml-10'>Duplicate</span>
                  </div>
                }
                  <div
                    className='item'
                    onClick={() => {
                      setOpen(false);
                      openAlertModal(true);
                    }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4ZM6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z" fill="#515151"/>
                    </svg>
                    <span className='ml-10'>Delete</span>
                  </div>
                </div>
              </div>
            </Menu>
            {isAlertModal && (
              <Modal>
                  <AlertPopUp
                      title="Are you sure?"
                      text="Are you sure you want to remove this round?"
                      submittext="Yes"
                      canceltext="No"
                      onSubmit={(setLoader:Function) => {
                          setLoader(true);
                          if(workFlowId) {
                            if(data.id!==-1){
                              dispatch(deleteRound(workFlowId, data.id, (res:any, err:boolean) => {
                                setLoader(false);
                                  if(!err) {
                                      openAlertModal(false);
                                  }
                              }))
                            }else{
                              let workFlowRound:any=workflowData?.rounds.filter((round:any) => round.id !== -1)
                              workflowData.rounds=workFlowRound
                              dispatch(setWorkFlowData(workflowData))
                            }
                            
                          }
                      }}
                      onCancel={() => {
                          openAlertModal(false);
                      }}
                  />
              </Modal>
            )}
          </>
        )}
      </div>
      {!isEditable && data.approvers.length > 0 && (
        <div className='members'>
          {data.approvers.map((approver: any, approverIndex: number) => (
            <div className='member flex space-between' key={approver._id}>
              <div className='flex'>
                <Avatar 
                  size="x-small"
                  initials={approver.firstName[0]||""} 
                  showInitials={!!(approver && !approver.profilePicture)}
                  backgroundColor={approver?.profileColour}
                  src={approver?approver.profilePicture:""}
                />
                <span>{approver.firstName + " " + approver.lastName}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {isEditable && (
        <>
          {data.approvers.length > 0 ? (
            <div className='members'>
              {data.approvers.map((approver: any, approverIndex: number) => (
                <div className='member flex space-between' key={approver._id}>
                  <div className='flex'>
                    <Avatar 
                      size="x-small"
                      initials={approver.firstName[0]||""} 
                      showInitials={!!(approver && !approver.profilePicture)}
                      backgroundColor={approver?.profileColour}
                      src={approver?approver.profilePicture:""}
                    />
                    <span>{approver.firstName + " " + approver.lastName}</span>
                  </div>
                  {data.approvers.length > 1 && <span
                    className='cross'
                    onClick={() => {
                      if (workFlowId)
                        dispatch(
                          removeUserFromRound(
                            {
                              workflowId: workFlowId,
                              roundId: data.id,
                              userId: approver._id,
                            },
                            (res: any, err: boolean) => {
                              if (!err) {
                                if (res.data.approvers.length > 0){
                                  dispatch(
                                    getUsersData(
                                      { userIds: res.data.approvers },
                                      (userres: any, usererr: boolean) => {
                                        if (!usererr) {
                                          res.data['approvers'] =
                                            userres.data.users;
                                          dispatch(
                                            changeRound(res.data, index)
                                          );
                                        }
                                      }
                                    )
                                  );
                                }else{
                                  dispatch(
                                    changeRound(res.data, index))
                                }
                              }
                            }
                          )
                        );
                    }}
                  >
                    <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#515151"/>
                    </svg>
                  </span>}
                </div>
              ))}
              <div style={{ margin: '1rem 0 0 1rem' }}>
                <button
                  className='add'
                  onClick={() => {
                    setOpenMembers(true);
                    fetchUsers();
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="black"/>
                  </svg>
                </button>
                {openMembers && (
                  <ChooseMemberDropDown
                    setOpenMembers={setOpenMembers}
                    members={filteredMembers}
                    workFlowId={workFlowId}
                    index={index}
                    data={{...data,name: roundName,settings: roundSettings}}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className='flex add-member'>
              <Button
                type="default"
                onClick={() => {
                  setOpenMembers(true);
                  fetchUsers();
                }}
              >
                <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#7335cb"/>
                </svg>
                Add people
              </Button>
              {openMembers && (
                <ChooseMemberDropDown
                  setOpenMembers={setOpenMembers}
                  members={filteredMembers}
                  workFlowId={workFlowId}
                  index={index}
                  data={{...data,name: roundName,settings: roundSettings}}
                />
              )}
            </div>
          )}
        </>
      )}
      {isEditable ? (
        <>
          <div
            className={
              isViewCard && isEditable ? 'preferences w-100' : 'preferences'
            }
          >
            <p>Round Preferences</p>
            <div
              className='select'
              ref={prefRef}
              onClick={() => setPreferences(true)}
            >
              <span>
                {roundSettings.everyonemustapprove
                  ? 'Everyone must Approve'
                  : 'Anyone can Approve'}
              </span>
              <svg width="10" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.41 0.580078L6 5.17008L10.59 0.580078L12 2.00008L6 8.00008L0 2.00008L1.41 0.580078Z" fill="black"/>
              </svg>
            </div>
            {openPreferences && (
              <ClickAwayListener onClickAway={() => setPreferences(false)}>
                <div className='dropdown full'>
                  <div className='list'>
                    <div
                      className='item'
                      onClick={(e) => {
                        e.stopPropagation();
                        if(data.id === -1 && !data.settings.everyonemustapprove){
                          setRoundSettings((setting:any)=>({...setting,everyonemustapprove: true}))
                        }
                        if (workFlowId && !data.settings.everyonemustapprove && data.id !== -1)
                          dispatch(
                            updateRound(workFlowId, {
                              ...data,
                              settings: {
                                ...data.settings,
                                everyonemustapprove: true,
                              },
                            },(updatedRound:any,err:boolean)=>{
                              if (!err) {
                                if(updatedRound.approvers.length > 0){
                                  dispatch(
                                    getUsersData(
                                      { userIds: updatedRound.approvers },
                                      (
                                        userres: any,
                                        usererr: boolean
                                      ) => {
                                        if (!usererr) {
                                          updatedRound['approvers'] =
                                            userres.data.users;
                                          dispatch(changeRound(updatedRound))
                                        }
                                      }
                                    )
                                  );
                                }else{
                                  dispatch(changeRound(updatedRound))
                                }
                              }
                            })
                          );
                        setPreferences(false);
                      }}
                    >
                      Everyone must Approve
                    </div>
                    <div
                      className='item'
                      onClick={(e) => {
                        e.stopPropagation();
                        if(data.id === -1 && !data.settings.everyonemustapprove){
                          setRoundSettings((setting:any)=>({...setting,everyonemustapprove: false}))
                        }
                        if (workFlowId && data.settings.everyonemustapprove)
                          dispatch(
                            updateRound(workFlowId, {
                              ...data,
                              settings: {
                                ...data.settings,
                                everyonemustapprove: false,
                              },
                            },(updatedRound:any,err:boolean)=>{
                              if (!err) {
                                if(updatedRound.approvers.length > 0){
                                  dispatch(
                                    getUsersData(
                                      { userIds: updatedRound.approvers },
                                      (
                                        userres: any,
                                        usererr: boolean
                                      ) => {
                                        if (!usererr) {
                                          updatedRound['approvers'] =
                                            userres.data.users;
                                          dispatch(changeRound(updatedRound))
                                        }
                                      }
                                    )
                                  );
                                }else{
                                  dispatch(changeRound(updatedRound))
                                }
                              }
                            })
                          );
                        setPreferences(false);
                      }}
                    >
                      Anyone can Approve
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </>
      ) : (
        <div className='preferences'>
          {data.settings.everyonemustapprove ? (
            <p>Everyone must Approve</p>
          ) : (
            <p>Anyone can Approve</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Round;
