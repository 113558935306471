export const DEFAULT_SHAPE_PROPS = {
  width: 200,
  height: 200,
};

export const DEFAULT_PROJECT_PROPS = {
  width: 800,
  height: 600,
  zoom: 1,
  dpi: 76,
  children: [],
};
