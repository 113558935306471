import { INTERCOM_APPID } from '../paths';

export const WELCOME_TOUR_ID = 179913;
export const SINGLE_DESIGN_TOUR_ID = 179936;
export const SINGLE_DESIGN_TOPBAR_TOUR_ID = 181798;
export const SET_DESIGN_FIRST_TOUR_ID = 181361;
export const SET_DESIGN_SECOND_TOUR_ID = 181466;
export const HYPER_PERSONALIZE_TOUR_ID = 181445;
export const HYPER_PERSONALIZE_SINGLE_TOUR_ID = 181683;
export const HYPER_PERSONALIZE_SET_TOUR_ID = 181685;
export const APPROVAL_WORKFLOW_TOUR_ID = 181371;
export const NO_WORKFLOWS_TOUR_ID = 181697;
export const SOME_WORKFLOWS_TOUR_ID = 181695;
export const CHOOSE_DESIGN_TOUR_ID = 184955;
export const CHOOSE_SET_DESIGN_TOUR_ID = 184956;
export const CHOOSE_SET_DESIGN_SECOND_TOUR_ID = 184962;

export const initialTourState = {
    WELCOME_TOUR: false,
    SINGLE_DESIGN_TOUR: false,
    SET_DESIGN_FIRST_TOUR: false,
    SET_DESIGN_SECOND_TOUR: false,
    HYPER_PERSONALIZE_TOUR: false,
    HYPER_PERSONALIZE_SINGLE_TOUR: false,
    HYPER_PERSONALIZE_SET_TOUR: false,
    APPROVAL_WORKFLOW_TOUR: false,
    NO_WORKFLOWS_TOUR: false,
    SOME_WORKFLOWS_TOUR: false,
    CHOOSE_SET_DESIGN_SECOND_TOUR: false
}
export const finalTourState = {
    WELCOME_TOUR: true,
    SINGLE_DESIGN_TOUR: true,
    SET_DESIGN_FIRST_TOUR: true,
    SET_DESIGN_SECOND_TOUR: true,
    HYPER_PERSONALIZE_TOUR: true,
    HYPER_PERSONALIZE_SINGLE_TOUR: true,
    HYPER_PERSONALIZE_SET_TOUR: true,
    APPROVAL_WORKFLOW_TOUR: true,
    NO_WORKFLOWS_TOUR: true,
    SOME_WORKFLOWS_TOUR: true,
    CHOOSE_SET_DESIGN_SECOND_TOUR: true
}

declare global {
    interface Window { Intercom: any; }
}

const tutorialState = localStorage.getItem('tutorial-state')
if(!tutorialState){
    localStorage.setItem('tutorial-state',JSON.stringify(initialTourState))
}

export const updateTourState = (key:string,value:boolean) => {
    // disabling
    return;
    let tutorialState = localStorage.getItem('tutorial-state');
    if(!tutorialState) return;
    let state = JSON.parse(tutorialState);
    if(state[key] === value){ return; }
    state[key] = value
    localStorage.setItem('tutorial-state',JSON.stringify(state));
}

export const getTourStateValue = (key:string) => {// disabling
    return;
    let tutorialState = localStorage.getItem('tutorial-state');
    if(!tutorialState) return;
    let state = JSON.parse(tutorialState);
    return state[key];
}

export const loadIntercom = (settings:any) => {// disabling
    return;
    window.Intercom = window.Intercom || new Function;
    window?.Intercom('boot',{
        app_id: INTERCOM_APPID,
        ...settings
    });
}

export const updateIntercom = (updatedData?:any) => {// disabling
    return;
    window.Intercom = window.Intercom || new Function;
    if(updatedData){
        window?.Intercom('update',updatedData);
    }else{
        window?.Intercom('update');
    }
}

export const closeIntercom = () => {// disabling
    return;
    window.Intercom('shutdown');
}

export const startTour = (tourId:number) => {// disabling
    return;
    window.Intercom('startTour',tourId);
}

export const turnOffAutoTour = () => {// disabling
    return;
    let tutorialState = localStorage.getItem('tutorial-state');
    if(!tutorialState) return;
    localStorage.setItem('tutorial-state',JSON.stringify(finalTourState));
}

// const timeout = setTimeout(() => clearInterval(interval), 30000);
// const interval = setInterval(() => {
//     if (window.Intercom.booted) {
//     // Intercom is booted!

//     let frameElement = document.getElementById("intercom-frame");
//     console.log(frameElement)
//     if(frameElement){
//         let doc = frameElement.contentDocument;
//         doc.body.innerHTML = doc.body.innerHTML + '<style>.intercom-tour-step.intercom-tour-step-pointer {width:30rem;}</style>';
//     }
//     clearInterval(interval);
//     clearTimeout(timeout);
//     }
// }, 100);