import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApprovalState, FolderState } from "../../../../../store/types";
import Comment from "../Comment";
import {
  setApprovalComments,
  deleteApprovalComment,
  setBannerView,
  setCommentsResolutionType,
} from "../../../../../store/actions";
import { LeftOutlined } from "@ant-design/icons";
import { Dropdown, Menu, MenuItem } from "pyxis-ui-kit";
import CommentFilter from "../../../../../assets/svg/comments-filter.svg";
import CommenActiveListtFilter from "../../../../../assets/svg/comment-active-list.svg";
import { useHistory } from "react-router-dom";

interface CommentsInterface {
  comments: any;
  userData: any;
  userSuggestions: any[];
  updateComment: Function;
  canComment: boolean;
  setSelectedBanner: Function;
  client: WebSocket;
  selectedBanner: any;
  fetchComments: Function;
  commentIndex: number;
  setCommentIndex: Function;
  replyCommentIndex: number;
  setReplyCommentIndex: Function;
  setInFocus: Function;
}

const Comments: React.FunctionComponent<CommentsInterface> = ({
  comments,
  userData,
  userSuggestions,
  updateComment,
  canComment,
  client,
  setSelectedBanner,
  selectedBanner,
  fetchComments,
  commentIndex,
  setCommentIndex,
  replyCommentIndex,
  setReplyCommentIndex,
  setInFocus
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location: { search } } = history;
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get("banner");
  const { bannerData, isBannerDetailView } = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const commentResolution = useSelector(
    (state: { approval: ApprovalState }) => state.approval.comments_resolution
  );
  const activeChildBannerName = isBannerDetailView && selectedBanner !== -1
    ? bannerData?.banners[selectedBanner]?.size_name
    : null;
  const currentChildIDActive = selectedBanner !== -1 ? bannerData?.banners[selectedBanner]?.id : -1;

  const handleActionClick = useCallback(
    (type: string, index: number) => {
      setInFocus(false);
      switch (type) {
        case "delete": {
          const indexToDelete = comments.findIndex((comment:any) => comment.id === index);
          const replyCommentsToDelete = comments.filter((comment:any) => comment.parent_comment_id === index);
          if(comments[indexToDelete]?.pin_number && (indexToDelete + 1) < comments.length) {
            for(let i = (indexToDelete + 1); i < comments.length; i++) {
              if(!comments[i].parent_comment_id && comments[i].pin_number) {
                comments[i].pin_number -= 1;
              }
            }
          }
          replyCommentsToDelete.length > 0 && replyCommentsToDelete.forEach((comment:any, idx:number) => {
            dispatch(
              deleteApprovalComment(
                replyCommentsToDelete[idx].id,
                (response: any, error: boolean) => {
                  if (!error) {
                    replyCommentsToDelete.splice(idx, 1);
                    dispatch(setApprovalComments(comments));
                  }
                }
              )
            );
          });
          dispatch(
            deleteApprovalComment(
              comments[indexToDelete]?.id,
              (response: any, error: boolean) => {
                if (!error) {
                  comments.splice(indexToDelete, 1);
                  dispatch(setApprovalComments(comments));
                  if(index === replyCommentIndex) {
                    setReplyCommentIndex(-1);
                  }
                }
              }
            )
          );
          return;
        }
      }
    },
    [comments, comments.length]
  );

  const changeBanner = ({ child_index }: any) => {
    setSelectedBanner(child_index);
    dispatch(setBannerView(true));
  };

  return (
    <div className="comments">
      <div className="filters">
        {isBannerDetailView && activeChildBannerName && (bannerData.type === "image_set" || bannerData.type === "variant_set") && (replyCommentIndex === -1) && (
          <div className="comment-breadcrumb-section">
            <div
              onClick={() => {
                dispatch(setApprovalComments([]));
                dispatch(setBannerView(false));
                setSelectedBanner(-1);
              }}
              className="comment-breadcrumb"
            >
              <LeftOutlined className="icon" />
              <span className="child-banner-name">{activeChildBannerName}</span>
            </div>
          </div>
        )}
        <div className="filter-fixed-menu">
          <Dropdown
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            overlay={
              <Menu
                selectable={true}
                defaultSelectedKeys={[commentResolution?.toString()]}
              >
                <MenuItem
                  className="action-unresolved"
                  onClick={() => {
                    fetchComments(1, 0, (currentChildIDActive || -1));
                    dispatch(setCommentsResolutionType(0));
                    setCommentIndex(-1);
                  }}
                  key="0"
                >
                  <img src={CommenActiveListtFilter} />{" "}
                  <span>Show un-resolved</span>
                </MenuItem>
                <MenuItem
                  className="action-resolved"
                  onClick={() => {
                    fetchComments(1, 1, (currentChildIDActive || -1));
                    dispatch(setCommentsResolutionType(1));
                    setCommentIndex(-1);
                  }}
                  key="1"
                >
                  <img src={CommenActiveListtFilter} />{" "}
                  <span>Show resolved</span>
                </MenuItem>
                <MenuItem
                  className="comment-show-all"
                  onClick={() => {
                    fetchComments(1, -1, (currentChildIDActive || -1));
                    dispatch(setCommentsResolutionType(-1));
                    setCommentIndex(-1);
                  }}
                  key="-1"
                >
                  <img src={CommenActiveListtFilter} /> <span>Show All</span>
                </MenuItem>
              </Menu>
            }
          >
            <img src={CommentFilter} className="comment-filter-icon" />
          </Dropdown>
        </div>
      </div>
      
      {(replyCommentIndex !== -1) &&
      comments.reduce((acc:any, comment:any, index:number) => {
        return [
          ...acc, comment.id === replyCommentIndex && 
          <Comment
            data={comment}
            key={comment.id}
            index={index}
            handleActionClick={handleActionClick}
            isOtherUser={userData.userId !== comment.user_id.userId}
            userSuggestions={userSuggestions}
            updateComment={updateComment}
            canComment={canComment}
            client={client}
            selectedBanner={selectedBanner}
            commentIndex={commentIndex}
            setCommentIndex={setCommentIndex}
            replyCommentIndex={replyCommentIndex}
            setReplyCommentIndex={setReplyCommentIndex}
            setInFocus={setInFocus}
          />
        ]
      }, [])}
      {(replyCommentIndex !== -1) ? comments.reduce((acc:any, comment:any, index:number) => {
        return [
          ...acc, comment.parent_comment_id === replyCommentIndex &&
          <Comment
            data={comment}
            key={comment.id}
            index={index}
            handleActionClick={handleActionClick}
            isOtherUser={userData.userId !== comment.user_id.userId}
            userSuggestions={userSuggestions}
            updateComment={updateComment}
            canComment={canComment}
            client={client}
            selectedBanner={selectedBanner}
            commentIndex={commentIndex}
            setCommentIndex={setCommentIndex}
            replyCommentIndex={replyCommentIndex}
            setReplyCommentIndex={setReplyCommentIndex}
            setInFocus={setInFocus}
          />
        ]
      }, []) :
      comments
        // .sort((a: any, b: any) => a.id - b.id)
        .map(function(comment: any, index: number) {
          const replyCommentsLength:number = comments.filter((c:any) => comment.id === c.parent_comment_id).length;
          comment.replyCount = replyCommentsLength;
          if(isBannerDetailView && activeChildBannerName && !comment.comments && !comment.parent_comment_id) {
            return (
              <Comment
                data={comment}
                key={comment.id}
                index={index}
                handleActionClick={handleActionClick}
                isOtherUser={userData.userId !== comment.user_id.userId}
                userSuggestions={userSuggestions}
                updateComment={updateComment}
                canComment={canComment}
                client={client}
                selectedBanner={selectedBanner}
                commentIndex={commentIndex}
                setCommentIndex={setCommentIndex}
                replyCommentIndex={replyCommentIndex}
                setReplyCommentIndex={setReplyCommentIndex}
                setInFocus={setInFocus}
              />
            );
          } else if(comment.child_id && !comment.parent_comment_id) {
            return (
              <>
                {comment["comments"] && (
                  <div className="child-banner-button" key={comment.child_id}>
                    <button onClick={() => changeBanner(comment)}>
                      {comment["comments"].length}{" "}
                      {comment["comments"].length > 1 ? "Comments" : "Comment"}{" "}
                      on{" "}
                      <span className="child-name">
                        {comment["child_name"]}
                      </span>
                    </button>
                  </div>
                )}
              </>
            );
          } else if(comment.child_id === null && !comment.parent_comment_id) {
            return (
              <Comment
                data={comment}
                key={comment.id}
                index={index}
                handleActionClick={handleActionClick}
                isOtherUser={userData.userId !== comment.user_id.userId}
                userSuggestions={userSuggestions}
                updateComment={updateComment}
                canComment={canComment}
                client={client}
                selectedBanner={selectedBanner}
                commentIndex={commentIndex}
                setCommentIndex={setCommentIndex}
                replyCommentIndex={replyCommentIndex}
                setReplyCommentIndex={setReplyCommentIndex}
                setInFocus={setInFocus}
              />
            );
          }
        })}
    </div>
  );
};

export default Comments;