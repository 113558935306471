import { Layout } from "pyxis-ui-kit";
import React from "react";

const PublicAgencyView = () => {
  return (
    <>
      <div style={{ margin: "0rem 2rem" }}>
        <Layout>
          <div
            className="agency-files-header"
            style={{
              height: "8.4rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="header-title" style={{ fontSize: "2rem" }}>
              Agency File Drop
            </div>
          </div>
        </Layout>

        <div className="agency-header">
          <div style={{ margin: "0 1rem" }}>
            <div className="agency-header__content">
              <div className="agency-header__info">
                <div className="agency-header__name">
                  HDFC shared this folder with you
                </div>
                <div className="agency-header__data">
                  Created on:&nbsp;23 Fed 2024
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "calc(100vh - 20rem)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        </div>
      </div>
    </>
  );
};

export default PublicAgencyView;
