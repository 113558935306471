import { LibraryState, FetchTypography, AddTypography, DeleteTypography, SetTypography, 
    FETCH_TYPOGRAPHY, SetLoader, SET_LOADER, ADD_TYPOGRAPHY, DELETE_TYPOGRAPHY, SET_TYPOGRAPHY, 
    UploadTypography, UPLOAD_TYPOGRAPHY, SetColors,AddColor,DeleteColors,RemoveColor,UploadColor,
    FetchColors, SET_COLORS, FETCH_COLORS, ADD_COLORS, DELETE_COLORS, REMOVE_COLORS, UpdateColor, 
    ChangeColor, UPDATE_COLOR, CHANGE_COLOR, SetSelectedColors, SET_SELECTED_COLORS, FETCH_MY_TEMPLATES,
    FetchMyTemplates,SetMyTemplates,SET_MY_TEMPLATES, DELETE_MY_TEMPLATES, REMOVE_MY_TEMPLATE, 
    SET_SELECTED_MY_TEMPLATES, DeleteMyTemplates, RemoveMyTemplate, SetSelectedMyTemplates, 
    FETCH_TEMPLATE_OPTIONS, FetchTemplateOptions, SetTemplateOptions, SET_TEMPLATE_OPTIONS, 
    CHANGE_TEMPLATE_OPTIONS,ChangeTemplateOption, SET_AVAILABLE_TYPOGRAPHIES, SetAvailableTypography, SetTemplatePageData, SET_TEMPLATE_PAGE_DATA
} from "../types"

const initialState: LibraryState = {
    typographies: [],
    availableTypographies: [],
    colors: [],
    selectedColors: [],
    templates: [],
    templateOptions: [],
    selectedTemplates: [],
    templatePageData: null,
    loading: false
}

export const libraryReducer = (state = initialState, action: FetchTypography|AddTypography|
    DeleteTypography|SetTypography|SetLoader|UploadTypography|SetColors|AddColor|DeleteColors
    |RemoveColor|UploadColor|FetchColors|UpdateColor|ChangeColor|SetSelectedColors|FetchMyTemplates
    |SetMyTemplates|DeleteMyTemplates|RemoveMyTemplate|SetSelectedMyTemplates|FetchTemplateOptions
    |SetTemplateOptions|ChangeTemplateOption|SetAvailableTypography|SetTemplatePageData): LibraryState => {
  switch(action.type) {
    case FETCH_TYPOGRAPHY: {
        return {
            ...state,
            loading: true
        }
    }
    case SET_LOADER: {
        return {
            ...state,
            loading: action.payload
        }
    }
    case ADD_TYPOGRAPHY: {
        return {
            ...state,
            typographies: [...state.typographies,...action.payload]
        }
    }
    case DELETE_TYPOGRAPHY: {
        state.typographies.splice(action.payload.index,1);
        return {
            ...state,
            typographies: state.typographies
        }
    }
    case SET_TYPOGRAPHY: {
        return {
            ...state,
            typographies: action.payload
        }
    }
    case SET_AVAILABLE_TYPOGRAPHIES: {
        return {
            ...state,
            availableTypographies: action.payload
        }
    }
    case UPLOAD_TYPOGRAPHY: {
        return {
            ...state,
            loading: true
        }
    }
    case SET_COLORS: {
        return {
            ...state,
            colors: action.payload
        }
    }
    case ADD_COLORS: {
        return {
            ...state,
            colors: [...state.colors,...action.payload]
        }
    }
    case DELETE_COLORS: {
        return {
            ...state,
            loading: true
        }
    }
    case REMOVE_COLORS: {
        let colors = state.colors.filter(color=>action.payload.indexOf(color.id) == -1);
        return {
            ...state,
            colors: colors
        }
    }
    case UPDATE_COLOR: {
        return {
            ...state,
            loading: true
        }
    }
    case CHANGE_COLOR: {
        let index = state.colors.findIndex(color=>color.id === action.payload.id);
        if(index > -1){
            state.colors[index] = action.payload;
        }
        return {
            ...state,
            colors: state.colors
        }
    }
    case SET_SELECTED_COLORS: {
        return {
            ...state,
            selectedColors: action.payload
        }
    }
    case FETCH_MY_TEMPLATES: {
        return {
            ...state,
            loading: true
        }
    }
    case SET_MY_TEMPLATES: {
        return {
            ...state,
            templates: action.payload
        }
    }
    case DELETE_MY_TEMPLATES: {
        return {
            ...state,
            loading: true
        }
    }
    case REMOVE_MY_TEMPLATE: {
        let templates = state.templates.filter(temp=>temp.id !== action.payload);
        return {
            ...state,
            templates: templates
        }
    }
    case SET_SELECTED_MY_TEMPLATES: {
        return {
            ...state,
            selectedTemplates: action.payload
        }
    }
    case FETCH_TEMPLATE_OPTIONS: {
        return {
            ...state,
            loading: true
        }
    }
    case SET_TEMPLATE_OPTIONS: {
        return {
            ...state,
            templateOptions: action.payload
        }
    }
    case CHANGE_TEMPLATE_OPTIONS: {
        let found = state.templateOptions.findIndex(obj=>obj.section === action.payload.section);
        if(found > -1){
            state.templateOptions[found] = action.payload;
        }
        return {
            ...state,
            templateOptions: state.templateOptions
        }
    }
    case SET_TEMPLATE_PAGE_DATA: {
        return {
            ...state,
            templatePageData: action.payload
        }
    }
    default:
      return state
  }
}