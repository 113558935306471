import React, {Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import './index.scss';
import { ProjectContainer } from '../../components/DashboardSection/ProjectContainer';
import PublicNavBar from '../../components/Public/PublicNavBar';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const HyperProjectDetail: FC<any> = ({history}) => {
  let [show, setShow] = useState(true);
  const isPublicLink = history.location.pathname.startsWith('/cocreate/public');

  const SuspenseComponent = () => {
    return (
      <>
      <div className="hyper-project-detial-wrap">
          <div className="flex-column">
            <div className="flex h-100">
              <ProjectContainer entityType="hpproject" setShowTopBar={setShow} isPublicLink={isPublicLink} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={!isPublicLink ? "aiquire-hyper-project-detail-wrapper" : "cocreate-wrapper"}>
      {isPublicLink && <PublicNavBar />}
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default HyperProjectDetail;

