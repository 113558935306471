import React, { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
import { Button, Drawer } from "pyxis-ui-kit";

const ArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3L6 8L10 13"
      stroke="#7335CB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface CommonDrawerInterface {
  title: string;
  subText: string;
  cancelButtonText: string;
  cancelButtonFunction?: Function;
  submitButtonText: any;
  submitButtonIcon?: any;
  cancelButtonIcon?: any;
  submitButtonFunction: Function;
  isFormValid: boolean;
  customFooterStyle?: React.CSSProperties | undefined;
  children?: any;
  loading?: boolean;
  isAddMember?: boolean;
}

const CommonDrawerComponent: FC<CommonDrawerInterface> = ({
  title,
  subText,
  cancelButtonText,
  submitButtonText,
  submitButtonIcon,
  cancelButtonIcon,
  cancelButtonFunction,
  submitButtonFunction,
  customFooterStyle,
  isFormValid,
  children,
  loading,
  isAddMember
}) => {
  if(!cancelButtonIcon) cancelButtonIcon = <ArrowIcon />;
  const [isDrawerOpen, setDrawerVisibility] = useState(true);
  const submitRef = useRef<any>(null);

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    if (cancelButtonFunction) cancelButtonFunction();
  };

  useEffect(() => {
    if(isAddMember) {
      setTimeout(() => {
        submitRef.current!.focus();
      }, [50])
    }
  }, [isFormValid, submitRef]);

  return (
    <Drawer
      className="commonDrawerContainer"
      destroyOnClose
      visible={isDrawerOpen}
      title={

        <span className="common-title">
          {title}
          <p className="common-sub-title">
            {subText}
          </p>
        </span>
      }
      onClose={handleCloseDrawer}
      closable={false}
      closeIcon={<></>}
      headerStyle={{ padding: "3.2rem", paddingBottom: 0, border: "none" }}
      bodyStyle={{ padding: "0 3.2rem", position: "relative" }}
      footerStyle={{ padding: "1.0rem 3.2rem" }}
      footer={
        <div className="commonDrawerFooter" style={customFooterStyle}>
          {cancelButtonText && (
            <Button
              type="link"
              className="close"
              onClick={() => handleCloseDrawer()}>
                {cancelButtonIcon}
                {cancelButtonText}
            </Button>
          )}
          {submitButtonText && (
            <Button
              ref={submitRef}
              type="primary"
              className="common-drawer-btn"
              loading={loading}
              disabled={loading || !isFormValid}
              onClick={() =>
                submitButtonFunction && submitButtonFunction()
              }>
                {submitButtonText}
                {submitButtonIcon && submitButtonIcon}
            </Button>
          )}
        </div>
      }
    >
      <div className="common-drawer-content-container">{children}</div>
    </Drawer>
  );
};

export default CommonDrawerComponent;
