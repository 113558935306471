import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Menu,
  MenuItem,
  SubMenu,
  Checkbox,
  Space,
  Tooltip,
} from "pyxis-ui-kit";
import { getStatusColor } from "../../../utilities/common-function";

const toggleAscending = (str: string) => {
  if (str.startsWith("-")) {
    return str.slice(1);
  } else {
    return "-" + str;
  }
};
const SortedByFilter: React.FC<{
  selectedSortBy: string;
  type: string;
  changeFilter: Function;
  value: string;
}> = ({ changeFilter, value, selectedSortBy, type }) => {
  const [visible, setVisible] = useState(false);
  const [isAscending, setIsAscending] = useState(
    !selectedSortBy.startsWith("-")
  );

  return (
    <div className='secondary-filter'>
      {value !== "Relevance" && (
        <Tooltip
          title={isAscending ? "Ascending" : "Descending"}
          content={
            <span
              className='icon'
              onClick={() => {
                changeFilter({
                  value,
                  sortedBy: toggleAscending(selectedSortBy),
                });
                setIsAscending((val) => !val);
              }}
            >
              {isAscending ? (
                <svg
                  width='11'
                  height='14'
                  viewBox='0 0 11 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.9931 5.69092L9.47631 0.948178C9.46646 0.916442 9.44665 0.888723 9.41981 0.869124C9.39298 0.849525 9.36055 0.839089 9.32732 0.839361H8.03156C7.96292 0.839361 7.90433 0.882887 7.88256 0.948178L6.3541 5.69092C6.34908 5.70599 6.34573 5.72273 6.34573 5.73947C6.34573 5.82485 6.41604 5.89516 6.50142 5.89516H7.44562C7.51593 5.89516 7.5762 5.84829 7.59629 5.78133L7.88926 4.75008H9.37921L9.66883 5.77965C9.68725 5.84662 9.74919 5.89349 9.8195 5.89349H10.8441C10.8608 5.89349 10.8759 5.89182 10.8909 5.88679C10.9311 5.8734 10.9629 5.84662 10.983 5.80978C11.0014 5.77295 11.0048 5.7311 10.9931 5.69092ZM8.0416 3.87787L8.59071 1.92083H8.69618L9.23357 3.87787H8.0416ZM10.4439 12.1295H8.2743V12.1228L10.4942 8.96045C10.5126 8.93367 10.5226 8.90354 10.5226 8.87005V8.26068C10.5226 8.1753 10.4523 8.10499 10.3669 8.10499H6.95176C6.86638 8.10499 6.79607 8.1753 6.79607 8.26068V8.98054C6.79607 9.06592 6.86638 9.13624 6.95176 9.13624H9.00421V9.14293L6.77598 12.3053C6.75738 12.3318 6.74743 12.3634 6.74752 12.3957V13.0051C6.74752 13.0905 6.81783 13.1608 6.90321 13.1608H10.4423C10.5277 13.1608 10.598 13.0905 10.598 13.0051V12.2852C10.5982 12.2649 10.5944 12.2447 10.5867 12.2258C10.5791 12.2069 10.5678 12.1897 10.5534 12.1752C10.5391 12.1608 10.5221 12.1493 10.5033 12.1414C10.4845 12.1336 10.4643 12.1295 10.4439 12.1295ZM3.90153 10.1809H2.62921V1.30811C2.62921 1.23445 2.56895 1.17418 2.49528 1.17418H1.55778C1.48412 1.17418 1.42386 1.23445 1.42386 1.30811V10.1809H0.151535C0.0393694 10.1809 -0.0242467 10.3115 0.0460658 10.3985L1.92107 12.7741C1.93359 12.7901 1.9496 12.803 1.96787 12.8119C1.98615 12.8208 2.00621 12.8255 2.02653 12.8255C2.04686 12.8255 2.06692 12.8208 2.0852 12.8119C2.10347 12.803 2.11948 12.7901 2.132 12.7741L4.007 10.3985C4.07564 10.3115 4.0137 10.1809 3.90153 10.1809Z'
                    fill='#887FB7'
                  />
                </svg>
              ) : (
                <svg
                  width='11'
                  height='14'
                  viewBox='0 0 11 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.9931 5.69092L9.47631 0.948178C9.46646 0.916442 9.44665 0.888723 9.41981 0.869124C9.39298 0.849525 9.36055 0.839089 9.32732 0.839361H8.03156C7.96292 0.839361 7.90433 0.882887 7.88256 0.948178L6.3541 5.69092C6.34908 5.70599 6.34573 5.72273 6.34573 5.73947C6.34573 5.82485 6.41604 5.89516 6.50142 5.89516H7.44562C7.51593 5.89516 7.5762 5.84829 7.59629 5.78133L7.88926 4.75008H9.37921L9.66883 5.77965C9.68725 5.84662 9.74919 5.89349 9.8195 5.89349H10.8441C10.8608 5.89349 10.8759 5.89182 10.8909 5.88679C10.9311 5.8734 10.9629 5.84662 10.983 5.80978C11.0014 5.77295 11.0048 5.7311 10.9931 5.69092ZM8.0416 3.87787L8.59071 1.92083H8.69618L9.23357 3.87787H8.0416ZM10.4439 12.1295H8.2743V12.1228L10.4942 8.96045C10.5126 8.93367 10.5226 8.90354 10.5226 8.87005V8.26068C10.5226 8.1753 10.4523 8.10499 10.3669 8.10499H6.95176C6.86638 8.10499 6.79607 8.1753 6.79607 8.26068V8.98054C6.79607 9.06592 6.86638 9.13624 6.95176 9.13624H9.00421V9.14293L6.77598 12.3053C6.75738 12.3318 6.74743 12.3634 6.74752 12.3957V13.0051C6.74752 13.0905 6.81783 13.1608 6.90321 13.1608H10.4423C10.5277 13.1608 10.598 13.0905 10.598 13.0051V12.2852C10.5982 12.2649 10.5944 12.2447 10.5867 12.2258C10.5791 12.2069 10.5678 12.1897 10.5534 12.1752C10.5391 12.1608 10.5221 12.1493 10.5033 12.1414C10.4845 12.1336 10.4643 12.1295 10.4439 12.1295ZM2.132 1.22608C2.11948 1.21007 2.10347 1.19713 2.0852 1.18822C2.06692 1.17932 2.04686 1.17469 2.02653 1.17469C2.00621 1.17469 1.98615 1.17932 1.96787 1.18822C1.9496 1.19713 1.93359 1.21007 1.92107 1.22608L0.0460658 3.60164C-0.0242467 3.69037 0.0393694 3.81927 0.151535 3.81927H1.42386V12.692C1.42386 12.7657 1.48412 12.826 1.55778 12.826H2.49528C2.56895 12.826 2.62921 12.7657 2.62921 12.692V3.81927H3.90153C4.0137 3.81927 4.07732 3.68869 4.007 3.60164L2.132 1.22608Z'
                    fill='#887FB7'
                  />
                </svg>
              )}
            </span>
          }
        ></Tooltip>
      )}
      <Dropdown
        visible={visible}
        onVisibleChange={(flag) => setVisible(flag)}
        trigger={["click"]}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        overlay={
          <Menu>
            <MenuItem
              onClick={() => {
                changeFilter({
                  value: "None",
                  sortedBy: isAscending ? "none" : "-none",
                });
                setVisible(false);
              }}
            >
              None
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeFilter({
                  value: "Date Created",
                  sortedBy: isAscending ? "created_at" : "-created_at",
                });
                setVisible(false);
              }}
            >
              Date Created
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeFilter({
                  value: "Date Modified",
                  sortedBy: isAscending ? "modified_at" : "-modified_at",
                });
                setVisible(false);
              }}
            >
              Date Modified
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeFilter({
                  value: "Name",
                  sortedBy: isAscending ? "title" : "-title",
                });
                setVisible(false);
              }}
            >
              Name
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeFilter({ value: "Relevance", sortedBy: "relevance" });
                setVisible(false);
              }}
            >
              Relevance
            </MenuItem>
          </Menu>
        }
      >
        <Space align='center'>
          <span className='name'>
            Sorted By: <b>{value}</b>
          </span>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2 5.3335L8 10.6668L14 5.3335'
              stroke='#1B0A7A'
              strokeOpacity='0.7'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Space>
      </Dropdown>
    </div>
  );
};

export default SortedByFilter;
