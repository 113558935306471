import React, { FC,useEffect,useState } from 'react';
import emptyFile from '../../../assets/svg/emptyFile.svg';
import {Button, Card, Tooltip } from 'pyxis-ui-kit';
import './index.scss'
import Img from '../../ImageLoader/ImageLoader';

interface EmptyFileInterface{
    title:string,
    subtitle:string,
    btnTxt?:any,
    handleClick?:Function,
    isPrimaryBtn?:boolean,
    img?:any,
    isDisabled?:boolean,
    className?:string,
    isSecondaryBtn?:boolean,
    handleSecondaryBtnClick?:Function,
    isSecondaryDisabled?:boolean,
    secondaryBtnTxt?:any,
    isToolTip?:boolean,
    toolTipText?:string
}

export const EmptyFile:FC<EmptyFileInterface> = ({img,title,subtitle,btnTxt,handleClick,isDisabled,className,isSecondaryBtn,handleSecondaryBtnClick,isSecondaryDisabled,secondaryBtnTxt,isPrimaryBtn,isToolTip,toolTipText}) => {
    const [didMount, setDidMount] = useState(false); 

    // useEffect(()=>{console.log(isPrimaryBtn,isPrimaryBtn?isPrimaryBtn:true)},[isPrimaryBtn])

    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])

    if(!didMount) {
        return null;
    }

    return <div className={`empty-file-container ${className}`}>
        <Card className="empty-file">
            <img src={img ? img : emptyFile} className="empty-file-img"/>
            <h3 className="empty-file-title noselect">{title}</h3>
            <div className="empty-file-subtitle noselect">{subtitle}</div>
            <div className="btn-container">
                {isPrimaryBtn!==false &&
                <Tooltip placement="bottom" title={isToolTip ? toolTipText : ""} content={
                    <span>
                        <Button 
                            onClick={handleClick}
                            type="primary" 
                            btnsize="lg" 
                            className={isDisabled ? "empty-file-btn btn-disabled" : "empty-file-btn"}
                            disabled={isDisabled}
                            >{btnTxt}
                        </Button>
                    </span>
                    }>
                </Tooltip>
                }
                {isSecondaryBtn &&
                    <Button 
                        onClick={handleSecondaryBtnClick}
                        btnsize="lg"
                        type="default"
                        className={isSecondaryDisabled ? "empty-file btn-disabled" : "empty-file"}
                        disabled={isSecondaryDisabled?isSecondaryDisabled:false}
                        >
                        {secondaryBtnTxt}
                    </Button>
                }
            </div>
            
        </Card>
    </div>
}