import React, { useEffect, useState, useCallback } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../store/types';
import IconUsers from "../../../assets/svg/iconUsersSidebar.svg";
import IconFlow from "../../../assets/svg/iconFlow.svg";
interface ItemInterface {
    name: string;
    icon: any;
    isActive: boolean;
    changeRoute: Function;
}
interface ContainerInterface {
    className: string
}

const Container: React.FunctionComponent<ContainerInterface> = ({children,className}) =>{
    return <div className={className}>
        {children}
    </div>;
}

const Item: React.FunctionComponent<ItemInterface> = ({name,icon,isActive,changeRoute}) =>{
    return <React.Fragment>
        <div className={isActive?"item active": "item"}
            onClick={()=>changeRoute()}
        >
            <div className="left">
                <span>{icon}</span>
                <p>{name}</p>
            </div>
        </div>
    </React.Fragment>;
}

const LeftSideBar: React.FunctionComponent = () => {
    const state = useSelector((state:{roles:RolesState})=>(state.roles));
    const [activeTab,setActiveTab] = useState(0);
    const history = useHistory();
    const { showBrandAdmin,showProductAdmin } = state;

    useEffect(()=>{
        const {location: {pathname}} = history;
        if(pathname === '/cocreate/roles') setActiveTab(2);
        else setActiveTab(1);
    },[])

    const changeRoute = useCallback((tabIndex) => {
        if(tabIndex !== activeTab){
            if(tabIndex === 1) {
                if(showBrandAdmin){
                    history.push('/cocreate/members');
                }else{
                    history.push('/cocreate/products');
                }
            }
            else history.push('/cocreate/roles');
        }
    },[activeTab]);

    return (<React.Fragment>
        <Container className="dashboard-leftsidebar-role">
            <Container className="list show">
                <Item 
                name="Users & Workspaces" 
                icon={<img src={IconUsers} alt="users"/>} 
                isActive={activeTab === 1} 
                changeRoute={()=>changeRoute(1)} />

                <Item 
                name="Roles & Responsibilities" 
                icon={<img src={IconFlow} alt="flow"/>} 
                isActive={activeTab === 2} 
                changeRoute={()=>changeRoute(2)} />
            </Container>
        </Container>
    </React.Fragment>
    )
}

export default LeftSideBar;