import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RolesState } from '../../../../../store/types';
import { fetchMembersList, setMembersList, setSelectedItem, fetchProductList, setProductsList, setItemList,createProduct,setNavBarProductList,setSelectedProductId } from '../../../../../store/actions';
import EmptyPlaceHolder from '../../../../shared/EmptyPlaceHolder';
import Modal from '../../../../shared/Modal';
import AddMemberPopUp from '../../../AddMemberPopUp';
import { permissionDeniedClick } from '../../../../../utilities/common-function';
import PromptPopUp from '../../../../shared/PromptPopUp';
import Avatar from '../../../../shared/Avatar';

const TableLeftBar:React.FunctionComponent = () => {
  const state = useSelector((state: {roles:RolesState})=>(state.roles));
  // const [total,setTotal] = useState(0);
  // const [membersPage,setMembersPage] = useState(1);
  const selectedItemRef = useRef(null)
  const [loading,setLoader] = useState(false);
  const [promptPopUpProps,setPromptPopUpProps] = useState<any>(null)
  const [addMemberPopUpProps,setAddMemberPopUpProps] = useState<any>(null);
  const dispatch = useDispatch();
  const { activeTopBarTab,selectedItem,itemList,selectedProductId,brand_id,showBrandAdmin,brandPermissions,userPermissions,navProductList } = state;
  const canAddDeleteProduct:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('add-delete-product') > -1;

  useEffect(()=>{
    dispatch(setItemList([]))
    if(!loading) setLoader(true)
    if(activeTopBarTab === 1 && brand_id){
      dispatch(fetchMembersList({entityType: "brand",entityId: brand_id,roles: "brand-user"},(response:any,error:boolean)=>{
        if(!error){
          dispatch(setItemList(response.data.users));
          if(response.data.users.length > 0){
            // setTotal(response.data.total)
            dispatch(setSelectedItem(0))
          }
        }
        setLoader(false)
      }))
    }
    if(activeTopBarTab === 2){
      dispatch(fetchProductList({entityType: "product"},(response:any,error:boolean)=>{
        if(!error){
          dispatch(setItemList(response.data.products));
          if(response.data.products.length > 0){
            dispatch(setSelectedItem(0))
          }
        }
        setLoader(false)
      }))
    }
    return ()=>{
      dispatch(setSelectedItem(-1))
      dispatch(setItemList([]))
      // setTotal(0);
    }
  },[activeTopBarTab]);
  
  useEffect(()=>{
    scrollToSelected()
  },[selectedItem])

  const scrollToSelected = () => {
    let item:any = selectedItemRef?.current;
    if(item){
      item.scrollIntoView({behavior: "smooth", block: "center"})
    }
  }

  return <div className="table-left" id="table-left">
    {!loading && itemList.length === 0 && <EmptyPlaceHolder  
        icon={null}
        text={activeTopBarTab === 1?"There are no members currently in this brand. Start adding members by clicking on the button below":
        "There are no workspaces currently in this brand. Start adding workspaces by clicking on the button below"}
        submitText={activeTopBarTab === 1? "Add new member":"Add new workspace"}
        onSubmit={()=>{
          if(activeTopBarTab === 1){
            setAddMemberPopUpProps({
              type: "member",
              initailStep: 1,
              onCancel: ()=>{
                setAddMemberPopUpProps(null);
              },
              onSubmit: (data:any,callback:Function) => {
                dispatch(setItemList([...itemList,data]))
                if(itemList.length === 0) {
                  dispatch(setSelectedItem(0))
                }else{
                  dispatch(setSelectedItem(itemList.length))
                }
              }
            })
          }else{
            if(!canAddDeleteProduct){
              permissionDeniedClick();
            }else{
              setPromptPopUpProps({
                submitBtnText: "Submit",
                isUpdate:false,
                title: "New Workspace",
                placeholder: "Workspace name",
                text: "Choose a name for your workspace",
                inputText: "",
                onSubmit: (value:string,setLoader:Function)=>{
                  setLoader(true)
                  dispatch(createProduct({name:value},(res:any,err:boolean)=>{
                    setLoader(false)
                    if(!err){
                      dispatch(setNavBarProductList([...navProductList,res.data.product]))
                      if(selectedProductId === "none"||!selectedProductId){
                        dispatch(setSelectedProductId(res.data.product._id))
                      }
                      dispatch(setItemList([...itemList,res.data.product]))
                      if(itemList.length === 0) {
                        dispatch(setSelectedItem(0))
                      }else{
                        dispatch(setSelectedItem(itemList.length))
                      }
                      setPromptPopUpProps(null)
                    }
                  }))
                }
              })
            }
          }
        }}
        isDisabled={activeTopBarTab === 1?false:!canAddDeleteProduct}
      />}
   {!loading && itemList.length > 0 && <div className="list">
    {/* <InfiniteScroll
      dataLength={itemList.length}
      next={()=>{
        if(activeTopBarTab === 1)
        dispatch(fetchMembersList({entityType: "brand",entityId: BRAND_ID,roles: "brand-user",page: membersPage+1},(response:any,error:boolean)=>{
          if(!error){
            dispatch(setItemList([...itemList,...response.data.users]));
            setMembersPage(val=>val+1)
          }
        }))
      }}
      hasMore={activeTopBarTab === 1? itemList.length < total:false}
      loader={<div className="item" style={{justifyContent: 'center'}}><FontAwesomeIcon icon={faSpinner} spin /></div>}
      className="list"
      scrollableTarget="table-left"
    > */}
      {itemList.map((data:any,index:number)=>(
        <div className={selectedItem === index? "item active" : "item"} 
          onClick={()=>dispatch(setSelectedItem(index))}
          ref={selectedItem === index? selectedItemRef:null}
          key={data._id}
        >
          <Avatar 
            size="large"
            initials={activeTopBarTab === 1? (data.firstName ? data.firstName[0] : "" )
              :(data.name ? data.name[0]: "")} 
            showInitials={activeTopBarTab === 1? !!(data && !data.profilePicture):true}
            backgroundColor={activeTopBarTab === 1? data?.profileColour:""}
            src={activeTopBarTab === 1? (data.profilePicture?data.profilePicture:""):""}
          />
          <div className="about">
            <div className="name">{activeTopBarTab === 1? ((data.firstName && data.lastName)? data.firstName+" "+data.lastName:""):(data.name? data.name: "")}</div>
            {activeTopBarTab === 1 && <div className="email">{data.email? data.email:""}</div>}
            {activeTopBarTab === 1 && <div className="email">{data.phoneNumber? data.phoneNumber:""}</div>}
          </div>
        </div>
      ))}
    {/* </InfiniteScroll> */}
    </div>}
    {addMemberPopUpProps && <Modal>
      <AddMemberPopUp {...addMemberPopUpProps} />
    </Modal>}
    {promptPopUpProps && <Modal>
      <PromptPopUp {...promptPopUpProps} handleClose={()=>setPromptPopUpProps(null)} />  
    </Modal>}
  </div>
}

export default TableLeftBar;