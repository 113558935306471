import {
  Drawer,
  Button,
  Input,
  Table,
  SelectOption,
  Select,
} from "pyxis-ui-kit";
import React, { FC, useState, useEffect } from "react";
const options = [
  { label: "DV360", value: "dv360" },

  { label: "Facebook", value: "facebook" },
  { label: "GoogleAds", value: "googleAds" },
  { label: "Instagram", value: "instagram" },
  { label: "LinkedIn", value: "linkedin" },
  { label: "Pinterest", value: "pinterest" },
  { label: "Snapchat", value: "snapchat" },
  { label: "Twitter", value: "twitter" },
  { label: "YouTube", value: "youtube" },
  { label: "Custom", value: "custom" },
];
export const PlatFormTypePage = ({ platformValue, onChange }) => {
  return (
    <div className='approval'>
      <div className='title'>Select the platform</div>
      {/* <div className="sub-title">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div> */}
      <div className='description'>
        Select the platform for which you will be using the ad creative for
      </div>
      <div className='select-wrapper'>
        <span className='platform-label'>Platform</span>
        <Select
          className='select-platform'
          value={platformValue}
          onSelect={(text: string) => {
            onChange(text);
            // setShowInput(false)
          }}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        >
          {options.map((name, index) => (
            <SelectOption
              className='options'
              key={name.label}
              value={name.value}
            >
              {name.label}
            </SelectOption>
          ))}
        </Select>
      </div>
    </div>
  );
};
