import React, { useState } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { RolesState } from "../../../../../store/types";
import {
  updateProduct,
  setProductsList,
  setItemList,
  deleteProduct,
  setSelectedItem,
  unlinkUserWithEntity,
  linkUserWithEntity,
  setNavBarProductList,
  setSelectedProductId, setUserPermissions
} from "../../../../../store/actions";
import AddMemberPopUp from "../../../AddMemberPopUp";
import AddMemberDrawer from "../../../AddMemberDrawer";
import CommonSuccessWarnDrawer from "../../../../shared/CommonSuccessWarnDrawer"
import { permissionDeniedClick } from "../../../../../utilities/common-function";
import Avatar from "../../../../shared/Avatar";
import { DeleteIcon } from "../../../../LayerMenu/Icons/Icons"
import AddIcon from "../../../../../assets/svg/iconAddAccent.svg"
import CommonDrawerComponent from "../../../../shared/CommonDrawer";
import { Button, Input } from "pyxis-ui-kit";
import { HDFC_MFILTERIT_USER } from "../../../../../utilities/paths";
import { openToast } from "../../../../../Toasts";

const TableTopBar: React.FunctionComponent = () => {
  const state = useSelector((state: { roles: RolesState }) => state.roles);
  const [isAlertDrawer, openAlertDrawer] = useState(false);
  const [newWorkspaceName,setNewWorkspaceName] = useState<any>("");
  const [isEditWorkspaceDrawerOpen,setIsEditWorkspaceDrawerOpen] = useState<boolean>(false);
  const [completionPopUpState, setCompletionPopUpState] = useState<any>(null);
  const [addMemberPopUpProps, setAddMemberPopUpProps] = useState<any>(null);
  const [promptPopUpProps, setPromptPopUpProps] = useState<any>(null);
  const dispatch = useDispatch();
  const { activeTopBarTab, selectedItem, itemList,navProductList,userPermissions,brandPermissions,showBrandAdmin,brand_id } = state;
  const data: any =
    selectedItem !== -1 && itemList.length > 0 ? itemList[selectedItem] : null;
  const canAddDeleteProducts:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('add-delete-product') > -1;
  const canEditProduct:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('edit-product') > -1;
  const canManageUsers:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('manage-product-users') > -1;
  const [loading, setLoader] = useState(false);

  return (
    <div className="table-top">
      <div className="detail">
        <Avatar 
          size="large"
          initials={activeTopBarTab === 1? (data?.firstName ? data?.firstName[0] : "" )
            :(data?.name ? data?.name[0]: "")} 
          showInitials={activeTopBarTab === 1? !!(data && !data?.profilePicture):true}
          backgroundColor={activeTopBarTab === 1? data?.profileColour:""}
          src={activeTopBarTab === 1? (data?.profilePicture?data?.profilePicture:""):""}
        />
        <div className="about">
          <div className="name">
            {activeTopBarTab === 1
              ? (data && data?.firstName && data?.lastName)? data?.firstName + " " + data?.lastName:""
              : (data?.name? data?.name: "")}
          </div>
          {activeTopBarTab === 1 && data && (
            <div className="email">{data?.email? data?.email:""}</div>
          )}
          {activeTopBarTab === 1 && data && (
            <div className="email">{data?.phoneNumber? data?.phoneNumber:""}</div>
          )}
        </div>
      </div>
      {selectedItem !== -1 && <div className="actions">

        <Button
          className={(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers)?"action": "action permission-denied-lock"}
          onClick={() => {
            if (activeTopBarTab === 1 && HDFC_MFILTERIT_USER === data._id) {
              return openToast("error", "Restricted: Can not add Third party user to a workspace");
            }
            if(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers){
              setAddMemberPopUpProps({
                type: activeTopBarTab === 1 ? "product" : "member",
                onCancel: () => {
                  setAddMemberPopUpProps(null);
                },
                onSubmit: (data:any,callback:Function) => {
                  let {selectedId,role,text} = data;
                  // callback(true)
                  // dispatch(unlinkUserWithEntity({
                  //   userId: activeTopBarTab === 1?  itemList[selectedItem]._id: selectedId,
                  //   entityType: 'product',
                  //   entityId: activeTopBarTab === 1? selectedId: itemList[selectedItem]._id 
                  // },(res:any,err:boolean)=>{
                  //   callback(false)
                  //   if(!err){
                      setLoader(true);
                      dispatch(linkUserWithEntity({
                        userId: activeTopBarTab === 1?  itemList[selectedItem]._id: selectedId,
                        entityType: 'product',
                        entityId: activeTopBarTab === 1? selectedId: itemList[selectedItem]._id,
                        role
                      },(res:any,err:boolean)=>{
                        setLoader(false);
                        setAddMemberPopUpProps(null);
                        if(!err){
                          setCompletionPopUpState({	
                            ...completionPopUpState,	
                            isOpen: true,	
                            type: 0,	
                            title: `Great Job! ${activeTopBarTab === 1 ? itemList[selectedItem].email : text} was invited to workspace!`,	
                            subText:'',	
                            cancelButtonText: "Close"	
                          })
                          dispatch(setSelectedItem(-1));
                          dispatch(setSelectedItem(selectedItem));
                        }
                      }))
                    }
              });
            }else{
              permissionDeniedClick()
            }
          }}>
              <img className='icon' src={AddIcon} alt="add" />
              <span>{activeTopBarTab === 1 ? "Add to workspace" : "Invite users"}</span>
        </Button>
        {activeTopBarTab === 2 && (
            <div className={canEditProduct? "editIconBtn":"editIconBtn permission-denied-lock"} onClick={() => {
              if(canEditProduct){
                setIsEditWorkspaceDrawerOpen(true)
              }else{
                permissionDeniedClick()
              }
            }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 13.332H14" stroke="#8090E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11 2.33218C11.2652 2.06697 11.6249 1.91797 12 1.91797C12.1857 1.91797 12.3696 1.95455 12.5412 2.02562C12.7128 2.09669 12.8687 2.20086 13 2.33218C13.1313 2.4635 13.2355 2.61941 13.3066 2.79099C13.3776 2.96257 13.4142 3.14647 13.4142 3.33218C13.4142 3.5179 13.3776 3.7018 13.3066 3.87338C13.2355 4.04496 13.1313 4.20086 13 4.33218L4.66667 12.6655L2 13.3322L2.66667 10.6655L11 2.33218Z" stroke="#8090E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
        )}
        <div className={(activeTopBarTab === 2? canAddDeleteProducts: canManageUsers)? "action danger":"action danger permission-denied-lock"} onClick={()=>{ 
          if (activeTopBarTab === 1 && HDFC_MFILTERIT_USER === data._id) {
            return openToast("error", "Restricted: Can not delete Third party user");
          }
          if(activeTopBarTab === 2? canAddDeleteProducts: canManageUsers){
            openAlertDrawer(true);
            setCompletionPopUpState({
              ...completionPopUpState,
              isOpen: true, 
              type: 1, 
              title: `Remove ${(activeTopBarTab === 1 ? 'the user':'workspace')}?`,
              subText: '',
              cancelButtonText: "Cancel",
              cancelButtonFunction:() => {
                openAlertDrawer(false);
              },
              submitButtonText: "Delete",
              submitButtonFunction: () => {
                setLoader(true);
                if (activeTopBarTab === 2) {
                  dispatch(
                    deleteProduct(
                      { productId: itemList[selectedItem]?._id },
                      (res: any, err: boolean) => {
                        setLoader(false);
                        if (!err) {
                          let navIndex:any = navProductList.findIndex((p:any)=>p._id === itemList[selectedItem]._id);
                          itemList.splice(selectedItem, 1);
                          dispatch(setSelectedItem(itemList.length > 0 ? 0 : -1));
                          dispatch(setItemList(itemList));
                          if(navIndex !== -1){
                            navProductList.splice(navIndex,1);
                            dispatch(setNavBarProductList(navProductList))
                            if(navProductList.length > 0){
                              dispatch(setSelectedProductId(navProductList[0]._id))
                            }else{
                              dispatch(setSelectedProductId("none"))
                              dispatch(setUserPermissions([]))
                            }
                          }
                          setCompletionPopUpState({
                            ...completionPopUpState,
                            isOpen: true, 
                            type: 0, 
                            title: `Great Job! Workspace removed successfully`,
                            subText:'',
                            cancelButtonText: "Close",
                            cancelButtonFunction: () => {openAlertDrawer(false)}
                          })
                        }
                      }
                    )
                  );
                }
                if(activeTopBarTab === 1 && brand_id) {
                  dispatch(unlinkUserWithEntity({
                    userId: itemList[selectedItem]._id,
                    entityType: 'brand',
                    entityId: brand_id 
                  },(res:any,err:boolean)=>{
                      setLoader(false);
                      if(!err){
                        itemList.splice(selectedItem, 1);
                        dispatch(setSelectedItem(itemList.length > 0 ? 0 : -1));
                        dispatch(setItemList(itemList));
                        setCompletionPopUpState({
                          ...completionPopUpState,
                          isOpen: true, 
                          type: 0, 
                          title: `Great Job! User removed successfully`,
                          subText:'',
                          cancelButtonText: "Close",
                          cancelButtonFunction: () => {openAlertDrawer(false)}
                        })
                      }
                    }
                  ))
                }
              }
            })
          }else{
            permissionDeniedClick()
          }
        }}>
          <DeleteIcon />
        </div>
      </div>}
      {isAlertDrawer && <CommonSuccessWarnDrawer {...completionPopUpState} handleClose={()=> setCompletionPopUpState(null)}/>}
      {addMemberPopUpProps && <AddMemberDrawer {...addMemberPopUpProps}/>}
      {isEditWorkspaceDrawerOpen && (
      <CommonDrawerComponent 
        title="Edit workspace name"
        subText=""
        cancelButtonText="Cancel and return"
        submitButtonText="Update"
        cancelButtonFunction={()=>{setNewWorkspaceName("");setIsEditWorkspaceDrawerOpen(false)}}
        submitButtonFunction={() => {
          setLoader(true);
          dispatch(
            updateProduct(
              { name: newWorkspaceName ,product_id: itemList[selectedItem]._id },
              (res: any, err: boolean) => {
                setLoader(false);
                if (!err) {
                  let navIndex:number = navProductList.findIndex((p:any)=>(p._id === itemList[selectedItem]._id));
                  if(navIndex > -1){
                    navProductList[navIndex] = res.data.product;
                    dispatch(setNavBarProductList(navProductList))
                  }
                  itemList[selectedItem] = res.data.product;
                  dispatch(setItemList(itemList));
                  setIsEditWorkspaceDrawerOpen(false);
                  setCompletionPopUpState({
                    ...completionPopUpState,
                    isOpen: true, 
                    type: 0, 
                    title: `Great Job! Workspace name updated successfully`,
                    subText:'',
                    cancelButtonText: "Close",
                    cancelButtonFunction: () => {setCompletionPopUpState(null)}
                  });
                  setNewWorkspaceName("");
                }
              }
            )
          );
        }}
        isFormValid={newWorkspaceName.length > 0}
        loading={loading}>
        <React.Fragment>
      <div className="createWorkspaceContainer">
          <div>
            <label>Workspace name</label>
          </div>
          <div className="inputFieldDiv">
            <Input
              type={'text'}
              value={newWorkspaceName || (activeTopBarTab !== 1 && (data?.name? data?.name: ""))}
              onChange={(e:any) => {
                setNewWorkspaceName(e.target.value);
              }}
              onPressEnter={() => {
                setLoader(true);
                dispatch(
                  updateProduct(
                    { name: newWorkspaceName ,product_id: itemList[selectedItem]._id },
                    (res: any, err: boolean) => {
                      setLoader(false);
                      if (!err) {
                        let navIndex:number = navProductList.findIndex((p:any)=>(p._id === itemList[selectedItem]._id));
                        if(navIndex > -1){
                          navProductList[navIndex] = res.data.product;
                          dispatch(setNavBarProductList(navProductList))
                        }
                        itemList[selectedItem] = res.data.product;
                        dispatch(setItemList(itemList));
                        setIsEditWorkspaceDrawerOpen(false);
                        setCompletionPopUpState({
                          ...completionPopUpState,
                          isOpen: true, 
                          type: 0, 
                          title: `Great Job! Workspace name updated successfully`,
                          subText:'',
                          cancelButtonText: "Close",
                          cancelButtonFunction: () => {setCompletionPopUpState(null)}
                        });
                        setNewWorkspaceName("");
                      }
                    }
                  )
                );
              }}
            />
          </div>
        </div>
      </React.Fragment>
      </CommonDrawerComponent> 
    )}
    { completionPopUpState && <CommonSuccessWarnDrawer {...completionPopUpState} handleClose={()=> setCompletionPopUpState(null)}/>}
    </div>
  );
};

export default TableTopBar;