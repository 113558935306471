import { getObjectSize } from "../helpers/getObjectSize";
import { IGroup } from "./types";
import { CLASSIFICATION_TYPES } from "../classification/constants";
import { isBackgroundPart } from "../helpers/isBackgroundPart";
import { getVisibleSide } from "../helpers/getVisibleSide";
import { cloneDeep } from "lodash";

export const getDividedGroups = (groupFlattered: IGroup, initialCanvasChildren: Array<any>, canvasWidth: number, canvasHeight: number) => {
  // const defaultGroups: IGroup = { ...groupFlattered };
  // const defaultGroupsWrapped: IGroup = {};
  // const backgroundPartGroups: IGroup = {};
  // const parents = [];
  // // Get groups with backgroundParts, calculate wrapper size for them
  // for (const parentId of Object.keys(groupFlattered)) {
  //   const parent = initialCanvasChildren.find(c => c.id === parentId);
  //   if (isBackgroundPart(parent)) {
  //     const wrapperId = `${parentId}-wrapper`;
  //     let wrapper = {
  //       classificationType: parent.classificationType
  //     };
  //     const parentWrapper = recalculateBackgroundPartParent(wrapper, canvasWidth, canvasHeight);
  //     const hisChildren = groupFlattered[parentId] || []
  //     backgroundPartGroups[wrapperId] = [parent, ...hisChildren];
  //     delete defaultGroups[parentId];
  //     parents.push(parentWrapper);
  //   }
  // }
  // // Get default groups, calculate wrapper size for them
  // const backgroundPartExist = Object.keys(backgroundPartGroups)?.length;
  // const defaultGroupsExist = Object.keys(defaultGroups)?.length;
  // if (!backgroundPartExist && defaultGroupsExist) {
  //   for (const parentId of Object.keys(defaultGroups)) {
  //     const parent = initialCanvasChildren.find(c => c.id === parentId);
  //     let hisChildren = defaultGroups[parentId];
  //     const wrapperId = `${parentId}-wrapper`;
  //     let parentWrapper = {
  //       top: parent.top,
  //       left: parent.left,
  //       width: parent.width,
  //       height: parent.height,
  //       scaleX: parent.scaleX,
  //       scaleY: parent.scaleY,
  //       angle: parent.angle,
  //       id: wrapperId,
  //       classificationType: CLASSIFICATION_TYPES.DEFAULT
  //     };
  //     defaultGroupsWrapped[wrapperId] = [parent, ...hisChildren];
  //     hisChildren = defaultGroupsWrapped[wrapperId];
  //     if (hisChildren?.length) {
  //       for (const child of hisChildren) {
  //         const [parentObjWidth, parentObjHeight] = getObjectSize(parentWrapper);
  //         const [childObjWidth, childObjHeight] = getObjectSize(child);
  //         if (child.left < parentWrapper.left) {
  //           parentWrapper.left = child.left;
  //           parentWrapper.width += parentWrapper.left - child.left;
  //         }
  //         if (child.left + childObjWidth > parentObjWidth + parentWrapper.left) {
  //           parentWrapper.width += child.left + childObjWidth - (parentObjWidth + parent.left);
  //         }
  //         if (child.top < parentWrapper.top) {
  //           parentWrapper.top = child.top;
  //           parentWrapper.height += parentWrapper.top - child.top;
  //         }
  //         if (child.top + childObjHeight > parentWrapper.top + parentObjHeight) {
  //           parentWrapper.height += child.top + childObjHeight - (parentWrapper.top + parentObjHeight);
  //         }
  //       }
  //     }
  //     parents.push(parentWrapper);
  //   }
  //   return { defaultGroups: defaultGroupsWrapped, backgroundPartGroups: null, parents };
  // } else if (backgroundPartExist && defaultGroupsExist) {
  //   const backgroundParts = Object.keys(backgroundPartGroups).map((key: any) => initialCanvasChildren[key]);
  //   const defaultGroupWrapper = getWrapperForDefaultGroups(backgroundParts, canvasWidth, canvasHeight);
  //   defaultGroupsWrapped[defaultGroupWrapper.id] = [];
  //   for (const defaultParentId of Object.keys(defaultGroups)) {
  //     const parent = initialCanvasChildren.find(c => c.id === defaultParentId)
  //     const hisChildren = defaultGroups[defaultParentId]
  //     defaultGroupsWrapped[defaultGroupWrapper.id].push(parent, ...hisChildren);
  //   }
  //   parents.push(defaultGroupWrapper);
  //   return { defaultGroups: defaultGroupsWrapped, backgroundPartGroups, parents };
  // }
  // return { defaultGroups: null, backgroundPartGroups, parents };
};

export const getFakeBackgroundPart = (backgroundParts: Array<any>, canvasWidth: number, canvasHeight: number) => {
  const wrapperId = "defaultGroupWrapper";
  let parentWrapper = {
    top: 0,
    left: 0,
    width: canvasWidth,
    height: canvasHeight,
    initialScaleX: 1,
    initialScaleY: 1,
    cropX: 0,
    cropY: 0,
    leftMargin: 0,
    rightMargin: 0,
    topMargin: 0,
    bottomMargin: 0,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    id: wrapperId,
    classificationType: CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT
  };

  const backgroundParents = backgroundParts.filter(p => isBackgroundPart(p));
  const backgroundTop = backgroundParents.find((p) => p.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_TOP);
  const backgroundLeft = backgroundParents.find((p) => p.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT);
  const backgroundRight = backgroundParents.find((p) => p.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT);
  const backgroundBottom = backgroundParents.find((p) => p.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM);
  if (backgroundParents.length >= 2) {
    if (backgroundTop && backgroundBottom) {
      parentWrapper.top = (backgroundTop.topMargin >= 0 ? backgroundTop.topMargin : 0) + backgroundTop.visibleHeight;
      parentWrapper.left = 0;
      parentWrapper.width = canvasWidth;
      parentWrapper.height = backgroundBottom.topMargin - parentWrapper.top;
      parentWrapper.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_CENTER;
    } else if (backgroundLeft && backgroundRight) {
      parentWrapper.top = 0;
      parentWrapper.left = (backgroundLeft.leftMargin >= 0 ? backgroundLeft.leftMargin : 0) + backgroundLeft.visibleWidth;
      parentWrapper.width = backgroundRight.leftMargin - parentWrapper.left;
      parentWrapper.height = canvasHeight;
      parentWrapper.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_CENTER;
    }
  } else {
    for (const parent of backgroundParents) {
      if (parent.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_TOP) {
        parentWrapper.top += parent.visibleHeight;
        parentWrapper.height -= parent.visibleHeight;
        parentWrapper.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM;
      } else if (parent.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT) {
        parentWrapper.left += parent.visibleWidth;
        parentWrapper.width -= parent.visibleWidth;
        parentWrapper.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT;
      } else if (parent.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM) {
        parentWrapper.height -= parent.visibleHeight;
        parentWrapper.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_TOP;
      } else if (parent.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT) {
        parentWrapper.width -= parent.visibleWidth;
        parentWrapper.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT;
      }
    }
  }

  return {
    ...parentWrapper,
    visibleHeight: parentWrapper.height,
    visibleWidth: parentWrapper.width
  }
};

export const recalculateBackgroundPartParent = (wrapper: any, prevWidth: number, prevHeight: number) => {
  const newWrapper = cloneDeep(wrapper)
  const [width, height] = getObjectSize(wrapper);
  const yObjCenter = wrapper.top + height / 2;
  const topMargin = wrapper.top;
  const bottomMargin = prevHeight - (yObjCenter + height / 2);

  const xObjCenter = wrapper.left + width / 2;
  const leftMargin = wrapper.left;
  const rightMargin = prevWidth - (xObjCenter + width / 2);

  const visibleWidth = getVisibleSide(width, leftMargin, rightMargin);
  const visibleHeight = getVisibleSide(height, topMargin, bottomMargin);

  const [left, top] = getLeftAndLeftBackgroundPart(newWrapper, visibleWidth, visibleHeight, prevWidth, prevHeight);

  return {
    ...newWrapper,
    left,
    top,
    leftMargin,
    rightMargin,
    topMargin,
    bottomMargin,
    initialScaleX: wrapper.scaleX,
    initialScaleY: wrapper.scaleY,
    scaleX: 1,
    scaleY: 1,
    visibleWidth,
    visibleHeight
  };
};

const getLeftAndLeftBackgroundPart = (wrapper: any, visibleWidth: number, visibleHeight: number, canvasWidth: number, canvasHeight: number) => {
  const classificationType = wrapper.classificationType;

  switch (classificationType) {
    case CLASSIFICATION_TYPES.BACKGROUND_PART_TOP:
      return [0, 0];
    case CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT:
      return [0, 0];
    case CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM:
      return [0, canvasHeight - visibleHeight];
    case CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT:
      return [canvasWidth - visibleWidth, 0];
    default:
      return [0, 0];
  }
};
