import { Folder } from "../../../store/types";

export const arrangeFoldersByParent = (folders: Folder[]): Folder[] => {
    // Map to keep track of folders by their IDs for easy lookup
    const folderMap: { [key: number]: Folder } = {};
    folders.forEach(folder => {
      folderMap[folder.id] = folder;
    });
  
    const sortedFolders: Folder[] = [];
  
    // Recursive arrow function to add a folder and its children to the sorted array
    const addFolderAndChildren = (folderId: number) => {
      // Add the folder itself to the sorted array
      const folder = folderMap[folderId];
      if (folder) {
        sortedFolders.push(folder);
  
        // Find and add all child folders
        folders
          .filter(child => child.parent === folderId) // Get children of the current folder
          .forEach(child => addFolderAndChildren(child.id)); // Recursively add each child and its children
      }
    };
  
    // Start with root folders (parent === 0)
    folders
      .filter(folder => folder.parent === 0)
      .forEach(rootFolder => addFolderAndChildren(rootFolder.id));
  
    return sortedFolders;
  };