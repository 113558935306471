import React, { lazy, Suspense, FC } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import { CreateWorkFlow } from '../../components/WorkFlow';

import './index.scss';
import { withRouter, Redirect } from 'react-router-dom';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const NewWorkFlow: FC<any> = ({userPermissions}) => {

  const SuspenseComponent = () => {
    return (
      <>
        <div className="workflow-wrap">
          {userPermissions.indexOf('manage-approval-workflow') > -1?
            <CreateWorkFlow />  
            :<PermissionNotAllowed title="You do not have access to this feature as per your role" />
          }
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-workflow-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default NewWorkFlow;

