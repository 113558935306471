import { any } from "prop-types";
import {  UploadVLEImageProofs,VLE_Data, GET_VLE_DATA,UPLOAD_VLE_PROOFS} from "../types";
export const UPLOAD_VLE_PROOF_IMAGES = 'UPLOAD_VLE_PROOF_IMAGES';
export const VLE_DATA = 'VLE_DATA';
export const UPLOAD_IMAGES_FAILURE = 'UPLOAD_IMAGES_FAILURE';
export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_SUCCESS';

export const uploadVleProof = (payload: { vle_Id: string; images: File[] },
  callback?: Function): UploadVLEImageProofs =>  ({
    type: UPLOAD_VLE_PROOFS,
    payload:payload,
    callback
  });


export const GET_VLE_DATA_ACTION = (payload: {vle_Id: string;}, callback?:Function): VLE_Data => ({
  type: GET_VLE_DATA,
  payload:payload,
  callback
});

export const updateVleExpirationDateAction = (vle_id: any, expiration_date: any, submit_proof: any) => ({
    payload: {
      vle_id: any,
      expiration_date: any,
      submit_proof: any
    },
    callback: Function 
});