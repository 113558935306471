import React, {  Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import {LeftSideBar,Content} from '../../components/DashboardSection';

import './index.scss';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';
import { SearchBar } from '../../components/shared/SearchBar';


const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const HyperPersonalizeDesigns: FC<any> = ({userPermissions}) => {
  const SuspenseComponent = () => {
    return (
      <>
        <SearchBar type="hpproject"/>
        <div className="dashboard-wrap">
            {/* left menu, top menu and dashboard import homepage */}
            <LeftSideBar folderType="hpproject"/>
            <div className="flex-column">
              <div className="flex h-100">
                <Content type="hpproject" />
              </div>
            </div>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default HyperPersonalizeDesigns;

