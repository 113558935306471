export const ADD_TYPOGRAPHY = "ADD_TYPOGRAPHY";
export const UPLOAD_TYPOGRAPHY = "UPLOAD_TYPOGRAPHY";
export const FETCH_TYPOGRAPHY = "FETCH_TYPOGRAPHY";
export const DELETE_TYPOGRAPHY = "DELETE_TYPOGRAPHY";
export const SET_TYPOGRAPHY = "SET_TYPOGRAPHY";
export const SET_LOADER = "SET_LOADER";
export const SET_COLORS = "SET_COLORS";
export const ADD_COLORS = "ADD_COLORS";
export const DELETE_COLORS = "DELETE_COLORS";
export const REMOVE_COLORS = "REMOVE_COLORS";
export const FETCH_COLORS = "FETCH_COLORS"; 
export const UPLOAD_COLOR = "UPLOAD_COLOR";
export const UPDATE_COLOR = "UPDATE_COLOR";
export const CHANGE_COLOR = "CHANGE_COLOR";
export const SET_SELECTED_COLORS = "SET_SELECTED_COLORS";
export const FETCH_MY_TEMPLATES = "FETCH_MY_TEMPLATES";
export const SET_MY_TEMPLATES = "SET_MY_TEMPLATES";
export const DELETE_MY_TEMPLATES = "DELETE_MY_TEMPLATES";
export const REMOVE_MY_TEMPLATE = "REMOVE_MY_TEMPLATE";
export const SET_SELECTED_MY_TEMPLATES = "SET_SELECTED_MY_TEMPLATES"; 
export const FETCH_TEMPLATE_OPTIONS = "FETCH_TEMPLATE_OPTIONS";
export const UPDATE_TEMPLATE_OPTIONS = "UPDATE_TEMPLATE_OPTIONS";
export const CHANGE_TEMPLATE_OPTIONS = "CHANGE_TEMPLATE_OPTIONS";
export const SET_TEMPLATE_OPTIONS = "SET_TEMPLATE_OPTIONS";
export const SET_AVAILABLE_TYPOGRAPHIES = "SET_AVAILABLE_TYPOGRAPHIES";
export const SET_TEMPLATE_PAGE_DATA = "SET_TEMPLATE_PAGE_DATA";
export const CREATE_PROJECT_FROM_TEMPLATE = "CREATE_PROJECT_FROM_TEMPLATE";

export type LibraryState = {
    typographies: Typography[],
    availableTypographies: Typography[],
    colors: Color[],
    selectedColors: number[],
    templates: MyTemplate[],
    templateOptions: TemplateOption[],
    selectedTemplates: number[],
    templatePageData: any,
    loading: boolean
}

export type Typography = {
    id: number,
    font_family: string,
    font_style: string,
    file: string,
    is_web_font: boolean,
    auth_user_id: string
}

export type TemplateOption = {
    id: number,
    section: string,
    font_family: string,
    font_style: string,
    font_size: number,
    font_file: string,
    is_web_font: boolean,
    product_id: number
}

export type FetchTypography = {
    type: typeof FETCH_TYPOGRAPHY,
    payload: {
        page: number,
        webfont: boolean,
        page_size: number,
        product_id: number
    },
    callback?:Function
}

export type UploadTypography = {
    type: typeof UPLOAD_TYPOGRAPHY,
    payload: {
        files: object[],
        product_id: number
    },
    callback?:Function
}

export type AddTypography = {
    type: typeof ADD_TYPOGRAPHY,
    payload: Typography[]
}

export type DeleteTypography = {
    type: typeof DELETE_TYPOGRAPHY,
    payload: {
        index: number
        id: number
    },
    callback?: Function
}

export type SetTypography = {
    type: typeof SET_TYPOGRAPHY,
    payload: Typography[]
}

export type SetLoader = {
    type: typeof SET_LOADER,
    payload: boolean
}

export type Color = {
    id: number,
    name: string,
    color_code: string
}

export type SetColors = {
    type: typeof SET_COLORS,
    payload: Color[]
}

export type AddColor = {
    type: typeof ADD_COLORS,
    payload: Color[]
}

export type DeleteColors = {
    type: typeof DELETE_COLORS,
    payload: number[],
    callback?: Function
}

export type RemoveColor = {
    type: typeof REMOVE_COLORS,
    payload: number[]
}

export type UploadColor = {
    type: typeof UPLOAD_COLOR,
    payload: {
        name: string,
        color_code: string,
        product_id: number
    },
    callback?: Function
}

export type FetchColors = {
    type: typeof FETCH_COLORS,
    payload: {
        page: number,
        page_size: number,
        product_id: number
    },
    callback?:Function
}

export type UpdateColor = {
    type: typeof UPDATE_COLOR,
    payload: Color,
    callback?: Function
}

export type ChangeColor = {
    type: typeof CHANGE_COLOR,
    payload: Color
}

export type SetSelectedColors = {
    type: typeof SET_SELECTED_COLORS,
    payload: number[]
}

export type MyTemplate = {
    id: number,
    title: string,
    type: string,
    description:string,
    rendered_file: string,
    can_manage:boolean,
    sizes:any[],
    thumbnail:string
}

export type FetchMyTemplates = {
    type: typeof FETCH_MY_TEMPLATES,
    payload: {
        page:number,
        page_size:number,
        product_id: number
    },
    callback?:Function
}

export type SetMyTemplates = {
    type: typeof SET_MY_TEMPLATES,
    payload: MyTemplate[]
}

export type DeleteMyTemplates = {
    type: typeof DELETE_MY_TEMPLATES,
    payload: number[],
    callback?: Function
}

export type RemoveMyTemplate = {
    type: typeof REMOVE_MY_TEMPLATE,
    payload: number
}

export type SetSelectedMyTemplates = {
    type: typeof SET_SELECTED_MY_TEMPLATES,
    payload: number[]
}

export type SetTemplateOptions = {
    type: typeof SET_TEMPLATE_OPTIONS,
    payload: TemplateOption[]
}

export type UpdateTemplateOptions = {
    type: typeof UPDATE_TEMPLATE_OPTIONS,
    payload: {
        option: TemplateOption,
        product_id: number
    }
}

export type ChangeTemplateOption = {
    type: typeof CHANGE_TEMPLATE_OPTIONS,
    payload: TemplateOption
}

export type FetchTemplateOptions = {
    type: typeof FETCH_TEMPLATE_OPTIONS,
    payload: {
        product_id: number
    }
}

export type SetAvailableTypography = {
    type: typeof SET_AVAILABLE_TYPOGRAPHIES,
    payload: Typography[]
}

export type SetTemplatePageData = {
    type: typeof SET_TEMPLATE_PAGE_DATA,
    payload: any
}

export type CreateProjectFromTemplate = {
    type: typeof CREATE_PROJECT_FROM_TEMPLATE,
    payload: {
        templateId: number,
        title: string,
        folder?: number
    },
    callback?:Function
}
