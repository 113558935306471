import { CLASSIFICATION_TYPES } from "../classification/constants";

export const getBackgroundPartLeftAndTop = (part: any, width: number, height: number, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  const type = part?.classificationType;

  switch (type) {
    case CLASSIFICATION_TYPES.BACKGROUND_PART_TOP:
      return {
        top: part.topMargin >= 0 ? part.topMargin / prevHeight * canvasHeight : 0,
        left: part.leftMargin >= 0 ? part.leftMargin / prevWidth * canvasWidth : 0,
      };
    case CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT:
      return {
        top: part.topMargin >= 0 ? part.topMargin / prevHeight * canvasHeight : 0,
        left: canvasWidth - width - (part.rightMargin >= 0 ? part.rightMargin / prevWidth * canvasWidth : 0),
      };

    case CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM:
      return {
        left: part.leftMargin >= 0 ? part.leftMargin / prevWidth * canvasWidth : 0,
        top: canvasHeight - height - (part.bottomMargin >= 0 ? part.bottomMargin / prevHeight * canvasHeight : 0),
      };

    case CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT:
      return {
        left: part.leftMargin >= 0 ? part.leftMargin / prevWidth * canvasWidth : 0,
        top: part.topMargin >= 0 ? part.topMargin / prevHeight * canvasHeight : 0,
      };
    case CLASSIFICATION_TYPES.BACKGROUND_PART_CENTER:
      return {
        left: part.left >= 0 ? part.left / prevWidth * canvasWidth : 0,
        top: part.top >= 0 ? part.top / prevHeight * canvasHeight : 0,
      };
  }
};
