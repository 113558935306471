import React, { FC } from 'react';
import {Button, Card } from 'pyxis-ui-kit';
import './index.scss'

interface EmptyFolderInerface{
    title:string,
    subtitle:string,
    btnTxt:any,
    handleClick:Function,
    icon?:any
}

export const EmptyFolder:FC<EmptyFolderInerface> = ({title,subtitle,btnTxt,icon,handleClick}) => {
    return <div className="empty-folder-container">
        <Card className="empty-folder">
            <h3 className="empty-title">{title}</h3>
            <div className="empty-subtitle">{subtitle}</div>
            <Button 
                onClick={handleClick}
                type="primary" 
                btnsize="lg" 
                className="empty-btn">
                    {btnTxt}
            </Button>
        </Card>
    </div>
}