import "./index.scss";
import React, { useEffect } from "react";
import { TabPane, Tabs } from "pyxis-ui-kit";
import AboutTab from "./AboutTab";
import ApprovalTab from "./ApprovalTab";
import CommentsTab from "./CommentTab";
import { AUDIT_URI } from "../../../utilities/paths";
import axios from "axios";
import { setCommentsResolutionType } from "../../../store/actions";
import { useDispatch } from "react-redux";

interface ActivityBarInterface {
  entityType: string;
  isSentForApproval: boolean;
  approvalLoader: boolean;
  fetchStatus: Function;
  loading: boolean;
  handleStatusChange: Function;
  activeTab: string;
  setIsApprover: Function;
  setIsRejectDrawer: Function;
  projectRename: string;
  userPermission: any;
  setActiveTab: Function;
  isPublicLink: boolean;
  setSelectedBanner: Function;
  selectedBanner: any;
  setCommentMode: Function;
  userId: any;
  entity_id: any;
  commentIndex: number;
  setCommentIndex: Function;
  replyCommentIndex: number;
  setReplyCommentIndex: Function;
  retainedComments: any;
  client: WebSocket;
  setClientActions: Function;
  setInFocus: Function;
}

const ActivityBar: React.FunctionComponent<ActivityBarInterface> = ({
  entityType,
  isSentForApproval,
  approvalLoader,
  fetchStatus,
  loading,
  handleStatusChange,
  activeTab,
  setIsApprover,
  setIsRejectDrawer,
  projectRename,
  userPermission,
  setActiveTab,
  isPublicLink,
  setSelectedBanner,
  selectedBanner,
  setCommentMode,
  userId,
  entity_id,
  commentIndex, 
  setCommentIndex, 
  replyCommentIndex, 
  setReplyCommentIndex, 
  retainedComments, 
  client, 
  setClientActions,
  setInFocus
}) => {
  const dispatch = useDispatch();
  const handleSelect = (key: any) => {
    if (key == 2) {
      const eventData = {};
      eventData["event_meta"] = {};
      eventData["event_meta"]["entity_id"] = entity_id;
      eventData["event_meta"]["entity_type"] = entityType;
      eventData["event_meta"]["user_id"] = userId;
      eventData["event_name"] = "comment_clicked";
      axios
        .post(AUDIT_URI + "/event", eventData)
        .then((response) => {
          if (response.status === 200) {
            return true;
          }
        })
        .catch((e) => {
          console.log("User Click event has an error");
        });
      // formData.append('user_id', );
    }
  };

  useEffect(() => {
    return (() => {
      dispatch(setCommentsResolutionType(0));
    })
  }, []);

  return (
    <div className="right-tab-bar">
      {(replyCommentIndex !== -1) ? (
        <Tabs activeKey="0" className="replies-tab">
          <TabPane
            tab={
              <span style={{ display: "flex", alignItems: "center" }} onClick={() => setReplyCommentIndex(-1)}>
                <span style={{ display: "flex", marginRight: "0.5rem" }}>
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3L6 8L10 13"
                      stroke="#152370"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>Comments</span>
              </span>
            }
            key="0"
          >
            <CommentsTab
              entityType={entityType}
              setSelectedBanner={setSelectedBanner}
              selectedBanner={selectedBanner}
              commentIndex={commentIndex}
              setCommentIndex={setCommentIndex}
              replyCommentIndex={replyCommentIndex}
              setReplyCommentIndex={setReplyCommentIndex}
              retainedComments={retainedComments}
              client={client}
              setClientActions={setClientActions}
              setInFocus={setInFocus}
            />
          </TabPane>
        </Tabs>
      ) : (
        <Tabs
          activeKey={isPublicLink ? "0" : activeTab?.toString()}
          className="approval-tab"
          onChange={(key: string) => setActiveTab(key)}
          onTabClick={(key: string) => {
            handleSelect(key);
            if(key === "2") {
              setCommentMode(true);
            }
          }}
        >
          {!approvalLoader && (
            <>
              <TabPane tab={<span>Description</span>} key="0">
                <AboutTab
                  entityType={entityType}
                  projectRename={projectRename}
                  isPublicLink={isPublicLink}
                  setSelectedBanner={setSelectedBanner}
                  selectedBanner={selectedBanner}
                />
              </TabPane>
              {userPermission.indexOf("view-approval-and-comments") > -1 && (
                <>
                  {isSentForApproval && (
                    <TabPane tab={<span>Approvals</span>} key="1">
                      <ApprovalTab
                        entityType={entityType}
                        fetchStatus={fetchStatus}
                        loading={loading}
                        handleStatusChange={handleStatusChange}
                        setIsApprover={setIsApprover}
                        setIsRejectDrawer={setIsRejectDrawer}
                      />
                    </TabPane>
                  )}
                  <TabPane tab={<span>Comments</span>} key="2">
                    <CommentsTab
                      entityType={entityType}
                      setSelectedBanner={setSelectedBanner}
                      selectedBanner={selectedBanner}
                      commentIndex={commentIndex}
                      setCommentIndex={setCommentIndex}
                      replyCommentIndex={replyCommentIndex}
                      setReplyCommentIndex={setReplyCommentIndex}
                      retainedComments={retainedComments}
                      client={client}
                      setClientActions={setClientActions}
                      setInFocus={setInFocus}
                    />
                  </TabPane>
                </>
              )}
            </>
          )}
        </Tabs>
      )}
    </div>
  );
};

export default ActivityBar;
