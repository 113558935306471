import React, { useEffect, useState } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { RolesState } from '../../../store/types';
import { fetchRolesList, setAllRolesList, fetchAllPermissions, setAllPermissionsList, createCustomRole, 
  setLoader, deleteCustomRole, updateCustomRole, setRolesList } from '../../../store/actions';
import { permissionDeniedClick } from '../../../utilities/common-function';
import { isEqual } from 'lodash';
import Modal from '../../shared/Modal';
import DeleteRolePopUp from '../DeleteRolePopUp';
import { permissionsConstant } from './constants';
import { Tooltip } from 'pyxis-ui-kit';

// const isEqual = (arr1:string[],arr2:string[]) => {
  
// }

const TopBar:React.FunctionComponent<{setShowCreateRow:Function,canCreateRole:boolean}> = ({setShowCreateRow,canCreateRole}) => {
  return <div className="roles-header">
    <div className="title">Roles & Responsibilities</div>
    {/* <button className={!canCreateRole? "permission-denied-lock":""} onClick={()=>{
      if(!canCreateRole){
        permissionDeniedClick();
      }else{
        setShowCreateRow(true)
      }
    }}>
      <FontAwesomeIcon icon={faPlus} />
      Create New Role
    </button> */}
  </div>
}
const PermissionsTable:React.FunctionComponent<{showCreateRow:boolean;setShowCreateRow:Function;canEditRole:boolean}> = ({showCreateRow,setShowCreateRow,canEditRole}) => {
  const state = useSelector((state:{roles:RolesState})=>(state.roles));
  const { allPermission,allRoles,rolesList } = state;
  const roles = Object.keys(allRoles);
  const permissions = Object.keys(allPermission);
  const [loading,setLoading] = useState(false);
  const [fullLoading,setFullLoading] = useState(false);
  const [activeEditRow,setActiveEditRow] = useState(-1);
  const [activeRoleId, setActiveRoleId] = useState(-1);

  const [updatedRoleName,setupdatedRoleName] = useState<string>('');
  const [updatedRolePermissions,setupdatedRolePermissions] = useState<string[]>([]);
  const [customRoleName,setCustomRoleName] = useState<string>('Role Name');
  const [customRolePermissions,setCustomRolePermissions] = useState<string[]>([]);
  const [showDeleteRolePopUp, setShowDeleteRolePopUp] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(()=>{
    setFullLoading(true)
    dispatch(fetchRolesList({entityType: "brand"},(res:any,err:boolean)=>{
      if(!err){
        // hiding third party custom role mfilterit
        delete res.data['third-party-approver'];
        dispatch(setAllRolesList(res.data));
      }else{
        setFullLoading(false)
      }
    }))
    dispatch(fetchAllPermissions((res:any,err:boolean)=>{
      setFullLoading(false)
      if(!err){
        dispatch(setAllPermissionsList(res.permissions))
      }
    }))
  },[])
  
  return <table className="roles-table">
    {fullLoading && <tbody className="loader">
      <tr>
        <td>
          <FontAwesomeIcon icon={faSpinner} spin />
        </td> 
      </tr> 
    </tbody> }
    {!fullLoading &&<>
    <thead className="header">
      <tr>
        <th className="title">Role</th>
        {permissions.map((permission:string,index:number)=>(
          <Tooltip title={permissionsConstant.tooltips[permission] || allPermission[permission]?.name} content={
            <th key={index}>
                {allPermission[permission]?.name==="Grand approval"?"Grant approval":allPermission[permission]?.name}
            </th>
          }>
          </Tooltip>
        ))}
      </tr>
    </thead>
    <tbody className="content">
      {roles.map((role:string,index:number)=>(
        <tr key={role}>
        <td className="title">
          {canEditRole && !allRoles[role].default && <div className="actions">
            <span onClick={(e)=>{
              e.stopPropagation()
              setActiveRoleId(val=>(val === -1? index:-1));
              setShowDeleteRolePopUp(true);
              dispatch(deleteCustomRole(role,(res:any,err:boolean)=>{
                if(!err){
                  let tmpRolesList:any[] = Object.keys(res.data.rolesData.roles).reduce((acc:any,val:string)=>(
                    [...acc,{ roleId: val,name:  res.data.rolesData.roles[val]?.name}]
                  ),[])
                  dispatch(setRolesList(tmpRolesList));
                  dispatch(setAllRolesList(res.data.rolesData.roles))
                }
              }))
            }}>
              {/* <FontAwesomeIcon icon={faTrash} /> */}
            </span>
            <span onClick={()=>{
              setActiveEditRow(val=>(val === -1? index:-1))
              setupdatedRoleName(allRoles[role].name);
              setupdatedRolePermissions(allRoles[role].permissions);
            }}>
              {/* <FontAwesomeIcon icon={faPencilAlt} /> */}
            </span>
            {/* <span><FontAwesomeIcon icon={activeEditRow === index? faLockOpen: faLock} /> </span> */}  
          </div>}
          {(activeEditRow === index) ?
            <input type="text" autoFocus value={updatedRoleName} onChange={(e)=>setupdatedRoleName(e.target.value)} />
            :<span className={allRoles[role].default||!canEditRole?"ml-100 text":"text"}>{allRoles[role].name}</span>
          }
          {(activeRoleId === index) ? showDeleteRolePopUp && <Modal>
            <DeleteRolePopUp roles={roles} activeRoleId={activeRoleId} onCancel={()=>setShowDeleteRolePopUp(false)} 
              onSubmit={()=>setShowDeleteRolePopUp(false)} />
          </Modal> : null}
        </td>
        {permissions.map((p:any,i:number)=>(
          <td key={p}><input type="checkbox" disabled={activeEditRow === index?false:true} 
            checked={activeEditRow === index? updatedRolePermissions.indexOf(p) > -1: allRoles[role].permissions.indexOf(p) > -1}  
            onChange={(e)=>{
              if(e.target.checked){
                setupdatedRolePermissions(val=>([...val,p]))
              }else{
                let index:number = updatedRolePermissions.indexOf(p);
                setupdatedRolePermissions(val=>(val.filter((f:any,r:number)=>r!== index)));
              }
            }}
          /></td>
        ))}
        </tr>
      ))}
      {showCreateRow && <tr className="create-cutom-role">
        <td className="title">
          <input type="text" className="ml-100" value={customRoleName} autoFocus onChange={(e)=>setCustomRoleName(e.target.value)} />
        </td>
        {permissions.map((p:any,i:number)=>(
          <td key={p}><input type="checkbox" checked={!!(customRolePermissions.indexOf(p) > -1)} onChange={(e)=>{
            if(e.target.checked){
              setCustomRolePermissions(val=>([...val,p]))
            }else{
              let index = customRolePermissions.indexOf(p);
              setCustomRolePermissions(customRolePermissions.filter((cp:any,i)=>i !== index))
            }
          }}  /></td>
        ))}
      </tr>}
    </tbody>
    </>}
    {(showCreateRow || activeEditRow !== -1) && <tfoot className="table-footer">
      <button className="primary" 
        disabled={loading ||  (activeEditRow !== -1) ?  
          !(updatedRoleName && (updatedRoleName !== allRoles[roles[activeEditRow]]?.name || !isEqual(updatedRolePermissions,allRoles[roles[activeEditRow]].permissions)) ): 
          !(customRoleName && customRolePermissions.length)}
        onClick={()=>{
          setLoading(true);
          if(showCreateRow){
            dispatch(createCustomRole({
              roleId: customRoleName.trim().toLowerCase().split(' ').join('-'),
              name: customRoleName,
              type: "employee",
              entityType: "product",
              permissions: customRolePermissions
            },(res:any,err:boolean)=>{
              setLoading(false)
              if(!err){
                setShowCreateRow(false)
                setCustomRoleName('Role Name');    
                setCustomRolePermissions([]);
                let tmpRolesList:any[] = Object.keys(res.data.rolesData.roles).reduce((acc:any,val:string)=>(
                  [...acc,{ roleId: val,name:  res.data.rolesData.roles[val]?.name}]
                ),[])
                dispatch(setRolesList(tmpRolesList));
                dispatch(setAllRolesList(res.data.rolesData.roles))
              }
            }))
          }
          if(activeEditRow !== -1){
            dispatch(updateCustomRole({
              roleId: roles[activeEditRow],
              name: updatedRoleName,
              permissions: updatedRolePermissions
            },(res:any,err:boolean)=>{
              setLoading(false)
              if(!err){
                let i:number = rolesList.findIndex((r:any)=>r.roleId === roles[activeEditRow]);
                rolesList[i].name = updatedRoleName;
                dispatch(setRolesList(rolesList));
                setActiveEditRow(-1)
                setupdatedRoleName('')
                setupdatedRolePermissions([])
                dispatch(setAllRolesList(res.data.rolesData.roles))
              }
            }))
          }
        }}
      >
        {loading && <span><FontAwesomeIcon icon={faSpinner} spin /></span>}
        <span>Save</span>
      </button>
      <button className="secondary" onClick={()=>{
        if(activeEditRow !== -1){
          setActiveEditRow(-1)
          setupdatedRoleName('')
          setupdatedRolePermissions([])
        }else{
          setCustomRolePermissions([]);
          setShowCreateRow(false);
          setCustomRoleName('Role Name')
        }
      }}>Cancel</button>
    </tfoot>}
  </table>
}
const Roles:React.FunctionComponent = () => {
  const state = useSelector((state: {roles:RolesState})=>(state.roles));
  const { userPermissions,brandPermissions,showBrandAdmin } = state;
  const [showCreateRow,setShowCreateRow] = useState(false);
  const canCreateRole:boolean = (showBrandAdmin? brandPermissions:userPermissions).indexOf('create-custom-role') > -1 

  return <div className="auiquire-roles">
    <TopBar setShowCreateRow={setShowCreateRow} canCreateRole={canCreateRole} />
    <PermissionsTable showCreateRow={showCreateRow} setShowCreateRow={setShowCreateRow} canEditRole={canCreateRole} />
  </div>
}

export default Roles;