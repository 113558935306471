import React from "react";
import FileIcon from "../../assets/img/VLEFile.png";
import "./VLEStatus.scss";
import { Button } from "pyxis-ui-kit";
import { Table } from "antd";
import httpService from "../../HttpService/HttpService";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as RestoreStatusIcon } from "../../assets/svg/restore-status-log.svg";

export const VLEProductId = "65028c6ed5600c0013f6e0a8";

export default function VLEStatus() {
  const [step, setStep] = React.useState(0);
  const getTokenOrPublic = (state: any) =>
    state.authentication.token || state.publicLink.token;
  const state = useSelector((state: any) => state);
  const [folderList, setFolderList] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [folderName, setFolderName] = React.useState([]);
  const history = useHistory();

  const selectedProductId = useSelector(
    (state: { roles: any }) => state.roles.selectedProductId
  );

  React.useEffect(() => {
    if (selectedProductId != VLEProductId) {
      history.push("/");
    }
  }, [selectedProductId]);

  React.useEffect(() => {
    httpService
      .get(
        process.env.REACT_APP_DESIGN_URI,
        `/api/foldersWithSecurity/?type=project&product_id=${VLEProductId}`,
        getTokenOrPublic(state)
      )
      .then((res) => {
        console.log(res.data.data.result);
        setFolderList(res.data.data.result.reverse());
      });
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className='profile-wrapper'>
      <div className='VLE-status-container'>
        <div className='header' onClick={handleBack}>
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z'
                fill='#18236C'
              />
            </svg>
          </span>
          <span className='header-title'>Download VLE status</span>
        </div>

        <div className='list'>
          {folderList.map((item, index) => (
            <div className='list-item'>
              <div className='left'>
                <RestoreStatusIcon
                  onClick={() => {
                    history.push(
                      `/cocreate/vle-logs/${item.name}/${item.url_hash}`
                    );
                  }}
                />
                <img className='img-folder' src={FileIcon} />
                <div className='folder-name'>{item.name}</div>
              </div>
              <div className='right'>
                <div className='date'>
                  {new Date(item.share_link_created_at).toLocaleString()}
                </div>
                <Button
                  className='view-btn'
                  onClick={() => {
                    history.push(
                      `/cocreate/vle-status/${item.name}/${item.url_hash}`
                    );
                  }}
                >
                  View
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
