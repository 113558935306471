import { ExtendedFabricImage } from "../../../../../shared/types";
import { getObjectWidth, getObjectHeight } from "./getObjectSize";

const getInitialCrop = (image: any) => {
  const newCrop = {
    x: (image.cropX as number) * (image.scaleX as number),
    y: (image.cropY as number) * (image.scaleY as number),
    width: getObjectWidth(image),
    height: getObjectHeight(image)
  };

  return newCrop;
};

export const cropImage = (image: ExtendedFabricImage, crop: any) => {
  image.naturalWidth = !image.naturalWidth ? (image.width as number) : image.naturalWidth;
  image.naturalHeight = !image.naturalHeight ? (image.height as number) : image.naturalHeight;

  const { newX, newY, diffX, diffY } = getUpdatedPosition(image, crop);
  const rotatedCrop = rotatePoint(
    {
      x: diffX,
      y: diffY
    },
    image.angle as number
  );
  const rotatedLength = rotatePoint(
    {
      x: (image.cropX as number) * (image.scaleX as number),
      y: (image.cropY as number) * (image.scaleY as number)
    },
    image.angle as number
  );

  const newImage = { ...image };

  const newWidth = (crop.width * (image.width as number)) / getObjectWidth(image);
  const newHeight = (crop.height * (image.height as number)) / getObjectHeight(image)

  return {
    ...newImage,
    left: (image.left as number) - rotatedLength.x + rotatedCrop.x,
    top: (image.top as number) - rotatedLength.y + rotatedCrop.y,
    cropX: (newX * (image.width as number)) / getObjectWidth(image),
    cropY: (newY * (image.height as number)) / getObjectHeight(image),
    width: Math.min(image.naturalWidth, newWidth),
    height: Math.min(image.naturalHeight, newHeight)
  };
};

const getUpdatedPosition = (obj: any, crop: any): { newX: number; newY: number; diffX: number; diffY: number } => {
  const initialCrop: any = getInitialCrop(obj);
  const tX = obj.cropX - (obj.width - (crop.x + crop.width));
  const tY = obj.cropY - (obj.height - (crop.y + crop.height));
  const width = hasCrop(obj) ? obj.naturalWidth * (obj.scaleX || 1) : getObjectWidth(obj);
  const height = hasCrop(obj) ? obj.naturalHeight * (obj.scaleY || 1) : getObjectHeight(obj);

  let x = crop.x;
  let y = crop.y;
  // x and y updated based on flips before crop
  let diffX = x;
  let diffY = y;
  if (obj?.flipX && !obj?.flipY) {
    diffX = initialCrop.x - (initialCrop.x - tX - crop.x);
    x = width - crop.width - crop.x;
  }
  if (obj?.flipY && !obj?.flipX) {
    diffY = initialCrop.y - (initialCrop.y - tY - crop.y);
    y = height - crop.height - crop.y;
  }
  if (obj?.flipY && obj?.flipX) {
    diffY = initialCrop.y - (initialCrop.y - tY - crop.y);
    diffX = initialCrop.x - (initialCrop.x - tX - crop.x);
    y = height - crop.height - crop.y;
    x = width - crop.width - crop.x;
  }
  return {
    newX: x,
    newY: y,
    diffX,
    diffY
  };
};

const rotatePoint = ({ x, y }: { x: number; y: number }, deg: number) => {
  const degToRad = Math.PI / 180;
  const rcos = Math.cos(deg * degToRad),
    rsin = Math.sin(deg * degToRad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
};

const hasCrop = (obj: any) => {
  return obj?.cropX || obj?.cropY;
};
