import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import { TopBar,Approvals,FilterBar } from '../../components/Approval';

import './index.scss';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const Homepage: FC<any> = ({userPermissions}) => {

  const SuspenseComponent = () => {
    return (
      <>
        <div className="pending-approval-wrap">
          {userPermissions.indexOf('grand-approval') > -1?
            <>
              <TopBar />
              <FilterBar />
              <div className="flex h-100">
                <Approvals status="pending" />
              </div>
            </>
            :<PermissionNotAllowed title="You do not have access to this feature as per your role" />
          }
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default Homepage;

