import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  ApprovalState,
  RolesState,
  AuthState,
} from '../../../../store/types';
import {
  setApprovalStatus,
  duplicateApprovalRound,
  deleteApprovalRound,
  editApprovalRound,
  changeApprovalRound,
  addApprovalRound,
  appendApprovalRound,
  getUsersData,
  fetchMembersList,
  addUserToApprovalRound,
  deleteUserFromApprovalRound,
  continueApproval,
  reSendForApproval,
  fetchMyApprovalStatus
} from "../../../../store/actions";
import { useHistory } from "react-router-dom";
import { ReactComponent as iconComplete} from '../../../../assets/svg/iconComplete.svg';
import { ReactComponent as iconRemove} from '../../../../assets/svg/iconRemove.svg';
import { openToast } from "../../../../Toasts";
import { permissionDeniedClick } from '../../../../utilities/common-function';
import Avatar from '../../../shared/Avatar';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  MenuItem,
  SelectOption,
  Select,
  Tooltip,
} from 'pyxis-ui-kit';
import { DeleteAlert } from '../../../shared';
import Icon, {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { HDFC_MFILTERIT_USER } from '../../../../utilities/paths';

interface ContainerInterface {
  className: string;
}
interface RoundsInterface {
  approvalStatus: any;
  onGoingRoundId: number;
  showAddRound: boolean;
  setShowAddRound: Function;
  entityType: string;
  fetchStatus: Function;
  canUpdate: boolean;
  userData: any;
  banner: string | null;
  handleStatusChange: Function;
  setIsApprover: Function;
  setIsRejectDrawer: Function;
  editIndex: number;
  setEditIndex: Function;
}
interface RoundInterface {
  data: any;
  index: number;
  isOnGoing: boolean;
  handleActionClick: Function;
  canUpdate: boolean;
  userData: any;
  entityType: string;
  banner: string | null;
  fetchStatus: Function;
  handleStatusChange: Function;
  approvalStatus: any;
  setEditIndex: Function;
  setIsRejectDrawer: Function;
  handleRemoveRound: Function;
  rounds: any;
}
interface AddRoundDataInterface {
  setShowAddRound: Function;
  data?: any;
  roundNumber: number;
  entityId: number;
  handleRemoveRound: Function;
  setEditIndex: Function;
}

interface ApprovalTabInterface {
  entityType: string;
  fetchStatus: Function;
  loading: boolean;
  handleStatusChange: Function;
  setIsApprover: Function;
  setIsRejectDrawer: Function;
}

const Container: React.FunctionComponent<ContainerInterface> = ({
  children,
  className,
}) => {
  return <div className={className}>{children}</div>;
};

const Rounds: React.FunctionComponent<RoundsInterface> = ({
  approvalStatus,
  onGoingRoundId,
  showAddRound,
  setShowAddRound,
  entityType,
  fetchStatus,
  canUpdate,
  userData,
  banner,
  handleStatusChange,
  setIsApprover,
  setIsRejectDrawer,
  editIndex,
  setEditIndex,
}) => {
  const [continueIndex, setContinueIndex] = useState(-1);
  const [continueLoader, setContinueLoader] = useState(false);
  const dispatch = useDispatch();
  const rounds = approvalStatus?.rounds || [];
  let status: string = approvalStatus?.status || '';
  let isContinueDisabled: any = !rounds.every(
    (r: any) => r.approvers.length > 0
  );

  const handleActionClick = useCallback(
    (type: string, roundIndex: number) => {
      let entity_id = approvalStatus?.id;
      let round_id = rounds[roundIndex].id;
      switch (type) {
        case 'duplicate': {
          dispatch(
            duplicateApprovalRound(
              { entity_id, round_id },
              (response: any, error: boolean) => {
                if (!error) {
                  fetchStatus(false);
                  setEditIndex(-1);
                }
              }
            )
          );
          return;
        }
        case 'delete': {
          dispatch(
            deleteApprovalRound(
              { entity_id, round_id },
              (response: any, error: boolean) => {
                if (!error) {
                  rounds.splice(roundIndex, 1);
                  let data = {
                    ...approvalStatus,
                    rounds,
                  };

                  dispatch(setApprovalStatus(data));
                }
              }
            )
          );
          return;
        }
        case 'edit': {
          setEditIndex(roundIndex);
          return;
        }
      }
    },
    [approvalStatus, rounds.length]
  );

  const handleRemoveRound = (index: number) => {
    rounds.splice(index, 1);
    let data = {
      ...approvalStatus,
      rounds,
    };
    dispatch(setApprovalStatus(data));
  };

  useEffect(() => {
    if (status === 'approved' && !onGoingRoundId) {
      let index: number = rounds.findIndex((r: any) => r.status === 'pending');
      if (index !== -1) setContinueIndex(index);
    }
    if (showAddRound) {
      setEditIndex(rounds.length - 1);
    } else {
      setEditIndex(-1);
    }
  }, [showAddRound, rounds]);

  return (
    <div className="rounds">
      {rounds.map((round: any, index: number) => {
        return (
          <React.Fragment key={round.id}>
            {continueIndex === index && (
              <div className="continue-round">
                <p className="text">
                  All approved up to this round. Click below to continue the
                  apporval process
                </p>
                <Button
                  type="primary"
                  className="continue-btn"
                  disabled={continueLoader || isContinueDisabled}
                  loading={continueLoader}
                  onClick={() => {
                    if (approvalStatus?.entity_id) {
                      setContinueLoader(true);
                      dispatch(
                        continueApproval(
                          {
                            entity_id: approvalStatus.entity_id,
                            entity_type: entityType,
                          },
                          (res: any, err: boolean) => {
                            setContinueLoader(false);
                            if (!err) {
                              dispatch(
                                setApprovalStatus({
                                  ...approvalStatus,
                                  current_round: round.id,
                                })
                              );
                              setContinueIndex(-1);
                              fetchStatus(false);
                              dispatch(
                                fetchMyApprovalStatus(
                                  {
                                    entity_id: approvalStatus?.entity_id,
                                    user_id: userData.userId,
                                    entity_type: entityType,
                                  },
                                  (res: any, err: boolean) => {
                                    if (!err) {
                                      setEditIndex(-1);
                                      setShowAddRound(false);
                                      // value of enabledecisionbox is set from backend
                                      if (res.data.enabledecisionbox) {
                                        setIsApprover(true);
                                      } else {
                                        setIsApprover(false);
                                      }
                                    } else {
                                      setIsApprover(false);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        )
                      );
                    }
                  }}
                >
                  Continue
                </Button>
              </div>
            )}
            {editIndex === index && round.status === 'pending' ? (
              <div className="round" style={{ height: 'auto' }}>
                <AddRoundData
                  setEditIndex={setEditIndex}
                  setShowAddRound={setShowAddRound}
                  data={round}
                  roundNumber={index}
                  entityId={approvalStatus?.id}
                  handleRemoveRound={handleRemoveRound}
                />
              </div>
            ) : (
              <Round
                data={round}
                index={index}
                isOnGoing={onGoingRoundId === round.id}
                handleActionClick={handleActionClick}
                canUpdate={canUpdate}
                userData={userData}
                entityType={entityType}
                banner={banner}
                fetchStatus={fetchStatus}
                handleStatusChange={handleStatusChange}
                approvalStatus={approvalStatus}
                setEditIndex={setEditIndex}
                setIsRejectDrawer={setIsRejectDrawer}
                handleRemoveRound={handleRemoveRound}
                rounds={rounds}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
const Round: React.FunctionComponent<RoundInterface> = ({
  data,
  index,
  isOnGoing,
  handleActionClick,
  canUpdate,
  userData,
  entityType,
  banner,
  fetchStatus,
  handleStatusChange,
  approvalStatus,
  setEditIndex,
  setIsRejectDrawer,
  handleRemoveRound,
  rounds,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isApproveClicked, setIsApprovedClicked] = useState(false);
  const showOptions = !!(data.status === 'pending' && !isOnGoing);
  const [drawerVisible, setDrawerVisible] = useState('');

  return (
    <>
      <div className="round">
        <div className="round-number">
          <div className="round-number">
            <span className="round-index">{index + 1}</span>
          </div>
          <div className="round-header">
            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              overlayStyle={{}}
              overlayClassName="round-tooltip"
              title={data.name}
              placement="topLeft"
              content={<div className="round-name">{data.name}</div>}
            ></Tooltip>
            {(data.status === 'pending' ? isOnGoing : true) && (
              <div className="round-approve">
                <span>
                  {data.settings.everyonemustapprove
                    ? 'Everyone must Approve, '
                    : 'Anyone can Approve, '}
                </span>
                <span className="round-status">
                  {isOnGoing && data.status === 'pending'
                    ? 'ongoing'
                    : data.status}
                </span>
              </div>
            )}
          </div>
          <div className="actions">
            {/* {( */}
            {showOptions && (
              <Dropdown
                trigger={['click']}
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                visible={isOpen}
                onVisibleChange={(flag: boolean) => {
                  setIsOpen(flag);
                }}
                overlay={
                  <Menu>
                    <MenuItem
                      className={
                        canUpdate
                          ? 'approver-menu'
                          : 'approver-menu permission-denied-lock'
                      }
                      onClick={() => {
                        if (canUpdate) {
                          handleActionClick('duplicate', index);
                          setIsOpen(false);
                        } else {
                          permissionDeniedClick();
                        }
                      }}
                    >
                      <CopyOutlined className="menu-icon" />
                      Duplicate
                    </MenuItem>
                    <MenuItem
                      className={
                        canUpdate
                          ? 'approver-menu'
                          : 'approver-menu permission-denied-lock'
                      }
                      onClick={() => {
                        if (canUpdate) {
                          // Remove unsaved round
                          const unSavedRound = rounds.some(
                            (round: any) => round.id === -1
                          );
                          if (unSavedRound) {
                            handleRemoveRound(rounds.length - 1);
                          }
                          handleActionClick('edit', index);
                          setIsOpen(false);
                        } else {
                          permissionDeniedClick();
                        }
                      }}
                    >
                      <EditOutlined className="menu-icon" />
                      Edit
                    </MenuItem>
                    <MenuItem
                      className={
                        canUpdate
                          ? 'approver-menu'
                          : 'approver-menu permission-denied-lock'
                      }
                      onClick={() => {
                        if (canUpdate) {
                          setDrawerVisible('deleteRound');
                          setIsOpen(false);
                        } else {
                          permissionDeniedClick();
                        }
                      }}
                    >
                      <DeleteOutlined className="menu-icon" />
                      Delete
                    </MenuItem>
                  </Menu>
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                    stroke="#8090E7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#8090E7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                    stroke="#8090E7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Dropdown>
            )}
          </div>
        </div>
        {(data.status === 'pending' ? (
          isOnGoing
        ) : (
          true
        )) ? (
          <div className="round-detail">
            {data.approvers.map((approver: any, index: number) => (
              <div className="member" key={approver.approver_id._id}>
                <div className="approver-info">
                  <div className="flex">
                    <Avatar
                      size="small"
                      initials={
                        approver.approver_id?.firstName
                          ? approver.approver_id.firstName[0]
                          : ''
                      }
                      showInitials={
                        !!(approver.approver_id
                          ? !approver.approver_id?.profilePicture
                          : true)
                      }
                      backgroundColor={approver.approver_id.profileColour}
                      src={
                        approver.approver_id
                          ? approver.approver_id?.profilePicture
                          : ''
                      }
                      className="approver-img"
                    />
                    {userData.userId === approver.approver_id._id ? (
                      <p className="approverName you">You</p>
                    ) : (
                      <p className="approver-name">
                        {approver.approver_id.firstName +
                          ' ' +
                          approver.approver_id.lastName}
                      </p>
                    )}
                  </div>

                  {approver.status === 'approved' ? (
                    <div className="flex">
                      <span className="flex">
                        <Icon
                          className="status-icon"
                          component={iconComplete}
                        />
                      </span>
                      <p className="approval-status approved">Approved</p>
                    </div>
                  ) : approver.status === 'pending' &&
                    data.status !== 'approved' ? (
                    userData.userId === approver.approver_id._id ? (
                      <>
                        <div className="flex">
                          <span
                            className={`approver-action ${isApproveClicked?"disable-approve":""}`}
                            onClick={() => {
                              handleStatusChange('approved');
                              setIsApprovedClicked(true)
                            }}
                          >
                            <Icon
                              className="status-icon"
                              component={iconComplete}
                            />
                            <span className="approver-option approve noselect">
                              Approve
                            </span>
                          </span>
                          <span
                            className="approver-action"
                            onClick={() => {
                              setIsRejectDrawer(true);
                            }}
                          >
                            <Icon
                              className="status-icon"
                              component={iconRemove}
                            />
                            <span className="approver-option noselect">
                              Reject
                            </span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="flex">
                        <p className="approval-status">Waiting for vote</p>
                      </div>
                    )
                  ) : (
                    // : approver.status === "ongoing"?
                    // <p className="approval-status">{approver.approver_id.status}</p>
                    approver.status === 'rejected' && (
                      <div className="flex">
                        <span className="flex">
                          <Icon
                            className="status-icon"
                            component={iconRemove}
                          />
                        </span>
                        <p className="approval-status rejected">Rejected</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="not-started-container">
            <div className="approver-detail">
              {data.approvers.map((approver: any, index: number) => (
                <Tooltip
                placement="top"
                title={approver.approver_id?.firstName + ' ' + approver.approver_id?.lastName}
                key={index}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                content={ 
                  <div> 
                    <Avatar 
                      size="small"
                      initials={approver.approver_id?.firstName?approver.approver_id.firstName[0]:""} 
                      showInitials={!!(approver.approver_id ? !approver.approver_id?.profilePicture : true)}
                      src={approver.approver_id?approver.approver_id?.profilePicture:""}
                      backgroundColor={approver.approver_id?.profileColour}
                      className="approver-detail-img"
                    />
                  </div>
                  }
                ></Tooltip>
              ))}
            </div>
            <div className="not-started">
              Starts after completion of pervious round
            </div>
          </div>
        )}
      </div>
      {drawerVisible === 'deleteRound'&&<DeleteAlert
            title="Delete selected round?"
            subTitle="Are you sure you want to delete selected round?"
            isDeleteDrawerVisible={drawerVisible === 'deleteRound'} 
            setDrawerVisible={setDrawerVisible}
            roundId={data.id}
            currentProject={approvalStatus?.id}
            componentCaller="approvalDelete"
      />}
  </>);
};

const AddRound: React.FunctionComponent<{
  setShowAddRound: Function;
  entityId: number;
  canAdd: boolean;
}> = ({ setShowAddRound, entityId, canAdd }) => {
  const [loading, setLoader] = useState(false);
  const dispatch = useDispatch();
  return (
    <div
      className="add-round noselect"
      onClick={() => {
        if (canAdd) {
          dispatch(
            appendApprovalRound({
              id: -1,
              name: `Round Name`,
              settings: {
                everyonemustapprove: false,
              },
              order: -1,
              approvers: [],
              status: 'pending',
            })
          );
          setShowAddRound(true);
        } else {
          permissionDeniedClick();
        }
      }}
    >
      <svg
        width="8"
        height="8"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#20007F" />
      </svg>
      Add Round
      {loading && (
        <span style={{ marginLeft: '1rem' }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      )}
    </div>
  );
};

const AddRoundData: React.FunctionComponent<AddRoundDataInterface> = ({
  setShowAddRound,
  data,
  roundNumber,
  entityId,
  handleRemoveRound,
  setEditIndex,
}) => {
  const state = useSelector((state: { roles: RolesState }) => ({
    selectedProductId: state.roles.selectedProductId,
  }));
  const [isMembersOpen, setIsMembersOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const [members, setMembers] = useState([]);
  const [isEditable, setIsEditable] = useState(!!data);
  const { selectedProductId } = state;
  const dispatch = useDispatch();
  const [round, setRound] = useState(data);
  const filteredMembers = members.filter(
    (m: any) => !round.approvers.find((a: any) => a.approver_id._id === m._id)
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setRound(data);
  }, [data]);

  const handleSubmit = useCallback(() => {
    if (isEditable) {
      setLoader(true);
      dispatch(
        editApprovalRound(
          { entity_id: entityId, round_id: data.id, round },
          (response: any, error: boolean) => {
            if (!error) {
              let ids: string[] = response.data.approvers.reduce(
                (acc: any, val: any) => {
                  return [...acc, val.approver_id];
                },
                []
              );
              if (ids.length > 0) {
                dispatch(
                  getUsersData(
                    { userIds: ids },
                    (userres: any, usererr: boolean) => {
                      setLoader(false);
                      if (!usererr) {
                        response.data.approvers.map((a: any, r: number) => {
                          a.approver_id = userres.data.users[r];
                        });
                        dispatch(
                          changeApprovalRound(response.data, roundNumber)
                        );
                      }
                    }
                  )
                );
              } else {
                setLoader(false);
                dispatch(changeApprovalRound(response.data, roundNumber));
              }
            } else {
              setLoader(false);
              openToast(
                'warn',
                response.data.message || 'something went wrong!'
              );
            }
          }
        )
      );
    }
  }, [isEditable, entityId, data?.id, round]);

  const fetchUsers = () => {
    dispatch(
      fetchMembersList(
        {
          entityType: 'product',
          entityId: selectedProductId ? selectedProductId : undefined,
          permissions: 'grand-approval',
        },
        (res: any, err: boolean) => {
          // hiding third party mfilterit user
          const updatedUsers = (res?.data?.users||[]).filter(({ _id }) => ![HDFC_MFILTERIT_USER].includes(_id))
          setMembers(updatedUsers);
        }
      )
    );
  };

  const setPreference = (value: string) => {
    const roundInfo = Object.assign({}, round);
    if (value === 'anyone') {
      roundInfo.settings.everyonemustapprove = false;
    } else if (value === 'everyone') {
      roundInfo.settings.everyonemustapprove = true;
    }
    setRound(roundInfo);
  };

  const addMemberToRound = (roundId: number, memberId: string) => {
    dispatch(
      addUserToApprovalRound(
        { entity_id: entityId, roundId, userId: memberId },
        (res: any, err: boolean) => {
          if (!err) {
            let ids: string[] = res.data.approvers.reduce(
              (acc: any, val: any) => {
                return [...acc, val.approver_id];
              },
              []
            );
            if (ids.length > 0) {
              dispatch(
                getUsersData(
                  { userIds: ids },
                  (userres: any, usererr: boolean) => {
                    setLoader(false);
                    if (!usererr) {
                      res.data.approvers.map((a: any, r: number) => {
                        a.approver_id = userres.data.users[r];
                      });
                      setIsMembersOpen(false);
                      dispatch(changeApprovalRound(res.data, roundNumber));
                    }
                  }
                )
              );
            } else {
              setIsMembersOpen(false);
              setLoader(false);
              dispatch(changeApprovalRound(res.data, roundNumber));
            }
          } else {
            setIsMembersOpen(false);
            setLoader(false);
            openToast(
              'warn',
              res.data.message || 'Issue while adding the user!'
            );
          }
        }
      )
    );
  };

  const handleApproverSelection = (member_id: string) => {
    if (!loading && data.id === -1) {
      setLoader(true);
      dispatch(
        addApprovalRound(
          { entity_id: entityId, round },
          (response: any, error: boolean) => {
            if (!error) {
              addMemberToRound(response.data.id, member_id);
              dispatch(changeApprovalRound(response.data, roundNumber));
            } else {
              openToast('error', 'Something went wrong while adding a round!');
            }
          }
        )
      );
    }
    if (!loading && data.id !== -1) {
      setLoader(true);
      addMemberToRound(data.id, member_id);
    }
  };

  // useEffect(()=>{console.log(round.approvers,"approvers")},[round])

  return (
    <>
      <div className="new-round">
        <Input
          type="text"
          value={round.name}
          placeholder="Round Name"
          onBlur={(e: any) => {
            if (
              e.target.value &&
              e.target.value !== data.name &&
              data.id !== -1
            ) {
              handleSubmit();
            }
          }}
          onChange={(e: any) => {
            let value = e.target.value;
            setRound((obj: any) => ({ ...obj, name: value }));
          }}
          className="round-name"
        />
        <Select
          showSearch
          placeholder="Add an approver"
          className="search-member"
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          filterOption={(input, option) =>
            option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // Appending empty value so that we can display placeholder on selection of approver
          value={[]}
          onChange={handleApproverSelection}
        >
          {filteredMembers.map((member: any, index: number) => (
            <SelectOption
              value={member._id}
              className="round-members"
              title={member.firstName + ' ' + member.lastName}
            >
              <Avatar
                size="small"
                initials={member ? member?.firstName[0] : ''}
                showInitials={!!(member ? !member?.profilePicture : true)}
                backgroundColor={member?.profileColour}
                src={member ? member?.profilePicture : ''}
                className="member-picture"
              />
              <span className="name">
                {member.firstName + ' ' + member.lastName}
              </span>
            </SelectOption>
          ))}
        </Select>
        {round.approvers.length > 0 && (
          <div className="approvers">
            {round.approvers.map((approver: any, index: number) => (
              <div className="approver" key={approver._id}>
                <div className="approver-detail">
                  <Avatar
                    size="small"
                    initials={
                      approver.approver_id
                        ? approver.approver_id?.firstName[0]
                        : ''
                    }
                    showInitials={
                      !!(approver.approver_id
                        ? !approver.approver_id?.profilePicture
                        : true)
                    }
                    backgroundColor={approver.approver_id.profileColour}
                    src={
                      approver.approver_id
                        ? approver.approver_id?.profilePicture
                        : ''
                    }
                  />
                  <span className="approver-name">
                    {approver.approver_id.firstName +
                      ' ' +
                      approver.approver_id.lastName}
                  </span>
                </div>
                <div className="delete-approver">
                  {round.approvers.length > 1 && (
                    <div
                      onClick={() => {
                        if (!loading) {
                          setLoader(true);
                          dispatch(
                            deleteUserFromApprovalRound(
                              {
                                entity_id: entityId,
                                roundId: data.id,
                                userId: approver.approver_id._id,
                              },
                              (res: any, err: boolean) => {
                                if (!err) {
                                  let ids: string[] = res.data.approvers.reduce(
                                    (acc: any, val: any) => {
                                      return [...acc, val.approver_id];
                                    },
                                    []
                                  );
                                  if (ids.length > 0) {
                                    dispatch(
                                      getUsersData(
                                        { userIds: ids },
                                        (userres: any, usererr: boolean) => {
                                          setLoader(false);
                                          if (!usererr) {
                                            res.data.approvers.map(
                                              (a: any, r: number) => {
                                                a.approver_id =
                                                  userres.data.users[r];
                                              }
                                            );
                                            dispatch(
                                              changeApprovalRound(
                                                res.data,
                                                roundNumber
                                              )
                                            );
                                          }
                                        }
                                      )
                                    );
                                  } else {
                                    setLoader(false);
                                    dispatch(
                                      changeApprovalRound(res.data, roundNumber)
                                    );
                                  }
                                } else {
                                  setLoader(false);
                                  openToast(
                                    'warn',
                                    res.data.message ||
                                      'Issue while removing the user!'
                                  );
                                }
                              }
                            )
                          );
                        }
                      }}
                    >
                      <Icon component={iconRemove} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
      )}
      <p className="preferences">Preferences</p>
      <Select 
        defaultValue={round.settings.everyonemustapprove?"everyone":"anyone"} 
        className="round-prefernce" 
        onChange={setPreference}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      >
        <SelectOption value="anyone">Anyone can Approve</SelectOption>
        <SelectOption value="everyone">Everyone must Approve</SelectOption>
      </Select>
      <div className="footer">
        {data.id === -1 && <Button className="secondary" 
          onClick={()=>{
            handleRemoveRound(roundNumber)
            setEditIndex(-1)
          }}
            >
              Cancel
            </Button>}
          <Button
            className="save"
            type="primary"
            disabled={round.approvers.length === 0}
            loading={loading}
            onClick={() => {
              setEditIndex(isEditable ? -1 : false);
              setShowAddRound(false);
              handleSubmit();
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
};

const ApprovalTab: React.FunctionComponent<ApprovalTabInterface> = ({
  entityType,
  fetchStatus,
  loading,
  handleStatusChange,
  setIsApprover,
  setIsRejectDrawer,
}) => {
  const state = useSelector(
    (state: { approval: ApprovalState }) => state.approval
  );
  const authState = useSelector((state: {authentication:AuthState})=>state.authentication);
  const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
  const { approvalStatus } = state;
  const { userData } = authState;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get('banner');
  const [showAddRound, setShowAddRound] = useState(false);
  const [reSendLoading, setReSendLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  return (
    <Container className="approval-tab">
        {loading ? <div className="approval-loader"><Loading3QuartersOutlined spin className="loader" /></div> : 
        <>
          {approvalStatus?.note && (
            <>
              <div className="note">Note</div>
              <div className="approval-note">{approvalStatus?.note}</div>
            </>
          )}
          <Rounds
            approvalStatus={approvalStatus}
            showAddRound={showAddRound}
            setShowAddRound={setShowAddRound}
            onGoingRoundId={approvalStatus?.current_round || 0}
            entityType={entityType}
            fetchStatus={fetchStatus}
            canUpdate={
              userPermission.indexOf('start-cancel-approval-flow') > -1
            }
            setIsRejectDrawer={setIsRejectDrawer}
            userData={userData}
            banner={banner}
            handleStatusChange={handleStatusChange}
            setIsApprover={setIsApprover}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
          />
          {approvalStatus?.status === "rejected"? 
          <div className="action-btn">
            <Button className="resend-approval" type="primary" disabled={reSendLoading} onClick={()=>{
              setReSendLoading(true)
              dispatch(reSendForApproval({entity_id: parseInt(approvalStatus?.entity_id),entity_type: entityType},(res:any,err:boolean)=>{
                setReSendLoading(false)
                if(!err)
                fetchStatus(false)
              }))
            }}
            loading={reSendLoading}
            >
              Resend For Approval
            </Button>
          </div>
          :editIndex===-1&&<AddRound setShowAddRound={setShowAddRound} entityId={approvalStatus?.id} 
            canAdd={userPermission.indexOf('start-cancel-approval-flow') > -1}/>}
        </>}
    </Container>
  );
};

export default ApprovalTab;
