import {
  SET_TOPBAR_ACTIVE_TAB,
  SetTopBarActiveTab,
  SetNavBarProductList,
  SET_NAVBAR_PRODUCT_LIST,
  FetchProductList,
  FETCH_PRODUCT_LIST,
  FetchMembersList,
  FETCH_MEMBERS_LIST,
  SetMembersList,
  SET_MEMBERS_LIST,
  SetSelectedItem,
  SET_SELECTED_ITEM,
  SetProductsList,
  SET_PRODUCTS_LIST,
  SetItemList,
  SET_ITEM_LIST,
  SetSelectedProductId,
  SET_SELECTED_PRODUCT_ID,
  SET_ADDMEMBER_POPUP_PROPS,
  FetchOthersProductList,
  FETCH_OTHERS_PRODUCT_LIST,
  GetUsersData,
  GET_USER_DATA,
  FetchRolesList,
  FETCH_ROLES_LIST,
  SetRolesList,
  SET_ROLES_LIST,
  LinkUserWithEntity,
  LINK_USER_WITH_ENTITY,
  UnLinkUserWithEntity,
  UNLINK_USER_WITH_ENTITY,
  UpdateProduct,
  UPDATE_PRODUCT,
  CreateProduct,
  CREATE_PRODUCT,
  DeleteProduct,
  DELETE_PRODUCT,
  SetAllRoles,
  SET_ALL_ROLES,
  SetAllPermissions,
  SET_ALL_PERMISSIONS,
  FetchPermissions,
  FETCH_PERMISSIONS,
  CreateUser,
  CREATE_USER,
  CreateCustomRole,
  CREATE_CUSTOM_ROLE,
  UpdateCustomRole,
  UPDATE_CUSTOM_ROLE,
  DeleteCustomRole,
  DELETE_CUSTOM_ROLE,
  FetchUserPermissions,
  FETCH_USER_PERMISSIONS,
  SetUserPermissions,
  SET_USER_PERMISSIONS,
  SetShowBrandAdmin,
  SET_SHOW_BRAND_ADMIN,
  SetShowProductAdmin,
  SET_SHOW_PRODUCT_ADMIN,
  SetBrandPermissions,
  SET_BRAND_PERMISSIONS,
  SetBrandId,
  SET_BRAND_ID,
  SetOrgId,
  SET_ORG_ID
} from "../types";

export const setTopBarActiveTab = (payload: number): SetTopBarActiveTab => ({
  type: SET_TOPBAR_ACTIVE_TAB,
  payload,
});

export const setNavBarProductList = (payload: any): SetNavBarProductList => ({
  type: SET_NAVBAR_PRODUCT_LIST,
  payload,
});

export const fetchProductList = (
  payload: {
    entityType?: string;
    entityId?: string;
    userId?: string;
  },
  callback?: Function
): FetchProductList => ({
  type: FETCH_PRODUCT_LIST,
  payload,
  callback,
});

export const fetchMembersList = (
  payload: { entityType: string; entityId?: string; permissions?: string; roles?: string; page?: number; },
  callback?: Function
): FetchMembersList => ({
  type: FETCH_MEMBERS_LIST,
  payload,
  callback,
});

export const setMembersList = (payload: any): SetMembersList => ({
  type: SET_MEMBERS_LIST,
  payload,
});

export const setSelectedItem = (payload: number): SetSelectedItem => ({
  type: SET_SELECTED_ITEM,
  payload,
});

export const setProductsList = (payload: any): SetProductsList => ({
  type: SET_PRODUCTS_LIST,
  payload,
});

export const setItemList = (payload: any): SetItemList => ({
  type: SET_ITEM_LIST,
  payload,
});

export const setSelectedProductId = (
  payload: string | null
): SetSelectedProductId => ({
  type: SET_SELECTED_PRODUCT_ID,
  payload,
});

// export const setAddMemberPopUpProps = (payload: {isOpen: boolean,type:string}) => ({
//   type: SET_ADDMEMBER_POPUP_PROPS,
//   payload
// })

export const fetchOthersProductList = (
  payload: { userId: string; level: string; entityId: string },
  callback?: Function
): FetchOthersProductList => ({
  type: FETCH_OTHERS_PRODUCT_LIST,
  payload,
  callback,
});

export const getUsersData = (
  payload: { userIds: string[] },
  callback?: Function
): GetUsersData => ({
  type: GET_USER_DATA,
  payload,
  callback,
});

export const fetchRolesList = (
  payload: { entityType: string },
  callback?: Function
): FetchRolesList => ({
  type: FETCH_ROLES_LIST,
  payload,
  callback,
});

export const setRolesList = (payload: any[]): SetRolesList => ({
  type: SET_ROLES_LIST,
  payload,
});

export const linkUserWithEntity = (
  payload: {
    userId: string;
    entityType: string;
    entityId: string;
    role: string;
  },
  callback?: Function
): LinkUserWithEntity => ({
  type: LINK_USER_WITH_ENTITY,
  payload,
  callback,
});

export const unlinkUserWithEntity = (
  payload: { userId: string; entityType: string; entityId: string },
  callback?: Function
): UnLinkUserWithEntity => ({
  type: UNLINK_USER_WITH_ENTITY,
  payload,
  callback,
});

export const updateProduct = (
  payload: { name: string; product_id: string; },
  callback?: Function
): UpdateProduct => ({
  type: UPDATE_PRODUCT,
  payload,
  callback,
});

export const createProduct = (
  payload: { name: string },
  callback?: Function
): CreateProduct => ({
  type: CREATE_PRODUCT,
  payload,
  callback,
});

export const deleteProduct = (
  payload: { productId: string },
  callback?: Function
): DeleteProduct => ({
  type: DELETE_PRODUCT,
  payload,
  callback,
});

export const setAllRolesList = (payload: any): SetAllRoles => ({
  type: SET_ALL_ROLES,
  payload,
});

export const setAllPermissionsList = (payload: any): SetAllPermissions => ({
  type: SET_ALL_PERMISSIONS,
  payload,
});

export const fetchAllPermissions = (callback?: Function): FetchPermissions => ({
  type: FETCH_PERMISSIONS,
  callback,
});

export const createUser = (
  payload: {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    role: string;
    entityType: string;
    entityId: string;
    phoneNumber?: string;
  },
  callback?: Function
): CreateUser => ({
  type: CREATE_USER,
  payload,
  callback,
});

export const createCustomRole = (
  payload: {
    roleId: string;
    name: string;
    type: string;
    entityType: string;
    permissions: string[];
  },
  callback?: Function
): CreateCustomRole => ({
  type: CREATE_CUSTOM_ROLE,
  payload,
  callback,
});

export const updateCustomRole = (
  payload: { roleId: string; name: string; permissions: string[] },
  callback?: Function
): UpdateCustomRole => ({
  type: UPDATE_CUSTOM_ROLE,
  payload,
  callback,
});

export const deleteCustomRole = (
  payload: string,
  callback?: Function
): DeleteCustomRole => ({
  type: DELETE_CUSTOM_ROLE,
  payload,
  callback,
});

export const fetchUserPermissions = (
  payload: { entityType: string; entityId: string; },
  callback?: Function
): FetchUserPermissions => ({
  type: FETCH_USER_PERMISSIONS,
  payload,
  callback,
});

export const setUserPermissions = (payload: string[]):SetUserPermissions => ({
  type: SET_USER_PERMISSIONS,
  payload
})

export const setShowBrandAdmin = (payload:boolean):SetShowBrandAdmin => ({
  type: SET_SHOW_BRAND_ADMIN,
  payload
})

export const setShowProductAdmin = (payload:boolean):SetShowProductAdmin => ({
  type: SET_SHOW_PRODUCT_ADMIN,
  payload
})

export const setBrandPermissions = (payload:string[]):SetBrandPermissions => ({
  type: SET_BRAND_PERMISSIONS,
  payload
})

export const setBrandId = (payload:string|null):SetBrandId => ({
  type: SET_BRAND_ID,
  payload
})

export const setOrgId = (payload:string|null):SetOrgId => ({
  type: SET_ORG_ID,
  payload
})
