import { CANVAS_GRID_POSITION_X, CANVAS_GRID_POSITION_Y } from "../constants";
import { getObjectSize } from "./getObjectSize";
import { isPointInRectangle, IPoint } from "./isPointInRectangle";

const MAX_TOLERANCE = 0.05;

export const isObjectInside = (insObj: any, backObj: any) => {
  const objInsCoords = insObj?.coordinates;
  const objBackCoords = backObj?.coordinates;

  const isInside = objInsCoords.every((p: IPoint) => isPointInRectangle(p, objBackCoords));

  return isInside;
};

export const getNearestBackgroundPart = (obj: any, backgroundParts: Array<any>) => {
  const [insObjWidth, insObjHeight] = getObjectSize(obj);

  let min = null
  let resultBpId = backgroundParts[0].id

  for (const bp of backgroundParts) {
    const [backObjWidth, backObjHeight] = getObjectSize(bp);
    const maxLeftTolerance = backObjWidth * MAX_TOLERANCE;
    const maxTopTolerance = backObjHeight * MAX_TOLERANCE;

    if (isObjectInside(obj, bp))  {
      return bp.id
    }

    const left = bp.left - (obj.left + maxLeftTolerance) + (backObjWidth + bp.left) - (obj.left + insObjWidth - maxLeftTolerance);
    const top = bp.top - (obj.top + maxTopTolerance) +  (backObjHeight + bp.top) - (obj.top + insObjHeight - maxTopTolerance);

    if (min === null || (left + top) < min) {
      min = left+top
      resultBpId = bp.id
    }
  }

  return resultBpId
};

export const isObjectPartOutsideCanvas = (obj: any, canvasWidth: number, canvasHeight: number) => {
  const [width, height] = getObjectSize(obj);
  const xObjCenter = obj.left + width / 2;
  const yObjCenter = obj.top + height / 2;

  const leftMargin = obj.left;
  const rightMargin = canvasWidth - (xObjCenter + width / 2);
  const horizontalMargins = [leftMargin, rightMargin];

  const topMargin = obj.top;
  const bottomMargin = canvasHeight - (yObjCenter + height / 2);
  const verticalMargins = [topMargin, bottomMargin];

  const horizontalTolerance = 0.2 * width;
  const verticalTolerance = 0.2 * height;

  return horizontalMargins.some(m => m <= -horizontalTolerance) || verticalMargins.some(m => m <= -verticalTolerance);
};

export const isObjectPartBackground = (obj: any, canvasWidth: number, canvasHeight: number) => {
  // Object is part of background if it takes 30% or more of canvas and touches both left and right sides

  if (obj.layerType !== "Image") {
    return false;
  }

  const [width, height] = getObjectSize(obj);
  const xObjCenter = obj.left + width / 2;
  const yObjCenter = obj.top + height / 2;

  const positionY = getObjectGridPositionY(yObjCenter, height, canvasHeight);

  if (positionY === CANVAS_GRID_POSITION_Y.CENTER) {
    return false;
  }

  const leftMargin = obj.left;
  const rightMargin = canvasWidth - (xObjCenter + width / 2);

  const horizontalTolerance = 0.05 * width;

  const objSquare = width * height;
  const canvasSquare = canvasWidth * canvasHeight;

  return leftMargin <= 0 + horizontalTolerance && rightMargin <= 0 + horizontalTolerance && objSquare >= 0.4 * canvasSquare;
};

export const getObjectsPartlyOutsideCanvas = (objList: Array<any>, canvasWidth: number, canvasHeight: number) => {
  return objList.filter(obj => isObjectPartOutsideCanvas(obj, canvasWidth, canvasHeight));
};

export const getObjectGridPositionX = (xObjCenter: number, width: number, canvasWidth: number) => {
  const leftMargin = ((xObjCenter - width / 2) / canvasWidth) * 100;
  const rightMargin = ((canvasWidth - (xObjCenter + width / 2)) / canvasWidth) * 100;

  if ((xObjCenter >= canvasWidth / 3 && xObjCenter <= (canvasWidth / 3) * 2) || (leftMargin <= 5 && rightMargin <= 5)) {
    return CANVAS_GRID_POSITION_X.CENTER;
  }
  if (xObjCenter < canvasWidth / 3 || leftMargin <= 5) {
    return CANVAS_GRID_POSITION_X.LEFT;
  }

  if (xObjCenter > (canvasWidth * 2) / 3 || rightMargin <= 5) {
    return CANVAS_GRID_POSITION_X.RIGHT;
  }

  return CANVAS_GRID_POSITION_X.LEFT;
};

export const getObjectGridPositionY = (yObjCenter: number, height: number, canvasHeight: number) => {
  const topMargin = ((yObjCenter - height / 2) / canvasHeight) * 100;
  const bottomMargin = ((canvasHeight - (yObjCenter + height / 2)) / canvasHeight) * 100;

  if ((yObjCenter >= canvasHeight / 3 && yObjCenter <= (canvasHeight / 3) * 2) || (topMargin <= 5 && bottomMargin <= 5)) {
    return CANVAS_GRID_POSITION_Y.CENTER;
  }

  if (yObjCenter < canvasHeight / 3 || topMargin <= 5) {
    return CANVAS_GRID_POSITION_Y.TOP;
  }

  if (yObjCenter > (canvasHeight * 2) / 3 || bottomMargin <= 5) {
    return CANVAS_GRID_POSITION_Y.BOTTOM;
  }

  return CANVAS_GRID_POSITION_Y.TOP;
};

type Size = Array<number>;

export const isTextGroupedWithImage = (image: any, text: any, imageSize: Size, textSize: Size) => {
  if (image.layerType !== "Image" || text.layerType !== "Text") {
    return false;
  }

  const [imageWidth, imageHeight] = imageSize;
  const [textWidth, textHeight] = textSize;
  if (imageHeight < textHeight || imageWidth / textWidth < 0.5) return false;

  const lineHeight = text.fontSize;

  const verticalDistanceBetween = text.top - (image.top + imageHeight);

  const textIsUnderImage = Math.abs(verticalDistanceBetween) <= Math.pow(lineHeight, 0.9) * 2;

  if (!textIsUnderImage) {
    return false;
  }

  const textCenterX = text.left + textWidth / 2;
  const textCenterXIsUnderImage = textCenterX >= image.left && textCenterX <= image.left + imageWidth;

  if (!textCenterXIsUnderImage) {
    return false;
  }

  if (textHeight * 2 >= imageHeight) {
    return false;
  }

  return true;
};
