import React, { createContext, useState } from 'react';

type ActiveElementContextProviderProps = {
  children: React.ReactNode;
};

export type ClassColor = {
  color: string;
  attribute: string;
  className?: string | boolean;
};

export type ActiveElementContext = {
  currentElement: string;
  setCurrentElement: (currentElement: string) => void;
  currentElementRight: string;
  setCurrentElementRight: (currentElement: string) => void;
  svgColorToChange: ClassColor;
  setSvgColorToChange: any; // (svgColorToChange: ClassColor) => void;
  atributesColor: ClassColor[];
  setAtributeColors: any;
  applyCounter: number;
  setApplyCounter: Function;
  showCreativeVariantsPopUp: boolean;
  setShowCreativeVariantsPopUp: Function;
};

export const ActiveElementContext = createContext({} as ActiveElementContext);

const ActiveElementContextProvider = ({ children }: ActiveElementContextProviderProps) => {
  const [currentElement, setCurrentElement] = useState('');
  const [currentElementRight, setCurrentElementRight] = useState('');
  const [svgColorToChange, setSvgColorToChange] = useState<ClassColor>({ color: '', attribute: '' });
  const [atributesColor, setAtributeColors] = useState<ClassColor[]>([]);
  const [applyCounter, setApplyCounter] = useState(0);
  const [showCreativeVariantsPopUp, setShowCreativeVariantsPopUp] = useState(false);

  return (
    <ActiveElementContext.Provider
      value={{
        currentElement,
        setCurrentElement,
        currentElementRight,
        setCurrentElementRight,
        svgColorToChange,
        setSvgColorToChange,
        atributesColor,
        setAtributeColors,
        applyCounter,
        setApplyCounter,
        showCreativeVariantsPopUp,
        setShowCreativeVariantsPopUp
      }}
    >
      {children}
    </ActiveElementContext.Provider>
  );
};

export default ActiveElementContextProvider;
