import aiDesignerTemplate1 from "../../assets/img/aiDesignerTemplate1.png";
import aiDesignerTemplate2 from "../../assets/img/aiDesignerTemplate2.png";
import aiDesignerTemplate3 from "../../assets/img/aiDesignertemplate3.png";
import aiDesignerTemplate4 from "../../assets/img/aiDesignerTemplate4.png";
import React from "react";
import { useState } from "react";
import { getImagesByBrandOwner } from "./utils";
import { useSelector } from "react-redux";
// const images = [aiDesignerTemplate2, aiDesignerTemplate3, aiDesignerTemplate4]

const Carousel = ({ selectedTemplate, setSelectedTemplate }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const organistationId = useSelector((state: any) => state.roles.org_id);
  const images = getImagesByBrandOwner(organistationId);
  const lengt = images.length % 3;
  const prevIndex = (currentIndex - 1 + images.length) % images.length;
  const nextIndex = (currentIndex + 1) % images.length;
  const [isSelected, setIsSelected] = useState(false);
  // console.log("logs")
  const handlePrev = () => {
    setCurrentIndex(prevIndex);
  };

  const handleNext = () => {
    setCurrentIndex(nextIndex);
  };

  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === images.length - 1;

  return (
    <div className='carousel-container'>
      <div className='carousel'>
        <div
          className='carousel-track'
          style={{ transform: `translateX(-${currentIndex * 33.3333}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={
                selectedTemplate == index + 1
                  ? "model-image-container selected carousel-item"
                  : "model-image-container carousel-item"
              }
              onClick={() => {
                setSelectedTemplate(index + 1);
              }}
            >
              <img className='model-image' key={index} src={image} />
              {/* <img src={image} alt={`Image ${index}`} /> */}
            </div>
          ))}
        </div>
      </div>
      <button
        className='prev-button'
        disabled={currentIndex === 0}
        onClick={handlePrev}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='28'
          viewBox='0 0 28 28'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M18.4434 7.78908L12.9493 14.0002L18.4434 20.2113L16.752 21.778L9.55447 14.0002L16.752 6.22241L18.4434 7.78908Z'
            fill='#787B80'
          />
        </svg>
      </button>
      <button
        disabled={currentIndex === lengt}
        className='next-button'
        onClick={handleNext}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='28'
          viewBox='0 0 28 28'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M9.55469 20.2114L15.0488 14.0003L9.55469 7.78919L11.2461 6.22252L18.4436 14.0003L11.2461 21.7781L9.55469 20.2114Z'
            fill='#0B1829'
          />
        </svg>
      </button>
    </div>
    // <div key={"1"} className={isSelected ? "model-image-container selected " : "model-image-container"}
    //     onClick={() => { setIsSelected(!isSelected); setSelectedTemplate(1) }}
    //     style={{ height: 'fit-content' }}
    // >
    //     <img className="model-image" key={"1"} src={aiDesignerTemplate2} />
    //     {/* <img src={image} alt={`Image ${index}`} /> */}
    // </div>
    // <div key={"1"} className={isSelected ? "model-image-container selected " : "model-image-container"}
    //     onClick={() => { setIsSelected(!isSelected); setSelectedTemplate(2) }}
    //     style={{ height: 'fit-content' }}
    // >
    //     <img className="model-image" key={"1"} src={aiDesignerTemplate2} />
    //     {/* <img src={image} alt={`Image ${index}`} /> */}
    // </div>
  );
};

export default Carousel;
