import { MIXPANEL_TOKEN, ENV_TYPE } from "./../paths";
import mixpanel from "mixpanel-browser";

enum MIXPANEL_EVENTS  {
    SIGN_IN = "Sign In",
    SIGN_OUT = "Sign Out",
    UPLOAD_FILES = "Upload Files",
    ADAPT_GENERATE = "Adapt Generate",
    ADAPT_UPDATE = "Adapt Update",
    SHARABLE_LINK_OPEN = "Link Opened",
    DOWNLOAD_LINK = "Download File",
}

enum MIXPANEL_PEOPLE_PROPS {
    FIRST_FILE_UPLOAD = "First File Upload",
    LAST_FILE_UPLOAD = "Last File Upload",
    TOTAL_FILES_UPLOADED = "Successful File Upload",
    TOTAL_FILES_TRIED = "Total File Upload",
    FIRST_ADAPT_GENERATED = "First Adapt Generate",
    LAST_ADAPT_GENERATED = "Last Adapt Generate",
    TOTAL_ADAPTS_GENERATED = "Total Adapts Generate",
}

type TrackUploadFiles = {
    name: string,
    email: string,
    workspace: string,
    numberOfFiles: number,
    fileTypes: Array<string>,
}

type TrackGenerateAdapt = {
    name: string,
    email: string,
    workspace: string,
    numberOfAdapts: number,
    adaptTypes: Array<string>,
}

type TrackUpdateAdapt = {
    name: string,
    email: string,
    workspace: string,
    adaptTypes: Array<string>,
    addedAdapts: Array<string>,
    removedAdapts: Array<string>,
}

export const initializeMixpanel = (): void => {
    mixpanel.init(MIXPANEL_TOKEN, { debug: ENV_TYPE !== "prod" });
}

export const trackSignIn = (email: string): void => {
    mixpanel.track(MIXPANEL_EVENTS.SIGN_IN, { $email: email });
}

export const trackSignOut = (email: string): void => {
    mixpanel.track(MIXPANEL_EVENTS.SIGN_OUT, { $email: email });
    mixpanel.reset();
}

export const identifyMixpanel = (email: string, name: string): void => {
    mixpanel.identify(email);
    mixpanel.people.set({ $email: email, $name: name });
}

export const trackUploadFiles = ({ name, email, workspace, numberOfFiles, fileTypes }: TrackUploadFiles): void => {
    mixpanel.track(MIXPANEL_EVENTS.UPLOAD_FILES, {
        $name: name,
        $email: email,
        "Workspace Name": workspace,
        [MIXPANEL_PEOPLE_PROPS.TOTAL_FILES_TRIED]: numberOfFiles,
        "File Types": fileTypes,
    });
    mixpanel.people.set_once(MIXPANEL_PEOPLE_PROPS.FIRST_FILE_UPLOAD, new Date().toISOString());
    mixpanel.people.set_once(MIXPANEL_PEOPLE_PROPS.TOTAL_FILES_UPLOADED, 0);
    mixpanel.people.set_once(MIXPANEL_PEOPLE_PROPS.TOTAL_FILES_TRIED, 0);
    mixpanel.people.increment(MIXPANEL_PEOPLE_PROPS.TOTAL_FILES_TRIED, numberOfFiles);
}

export const incrementFileUploaded = () => {
    mixpanel.people.increment(MIXPANEL_PEOPLE_PROPS.TOTAL_FILES_UPLOADED);
    mixpanel.people.set(MIXPANEL_PEOPLE_PROPS.LAST_FILE_UPLOAD, new Date().toISOString());
}

export const trackAdaptGenerated = ({ name, email, workspace, numberOfAdapts, adaptTypes }: TrackGenerateAdapt): void => {
    mixpanel.track(MIXPANEL_EVENTS.ADAPT_GENERATE, {
        $name: name,
        $email: email,
        "Workspace Name": workspace,
        [MIXPANEL_PEOPLE_PROPS.TOTAL_ADAPTS_GENERATED]: numberOfAdapts,
        "Adapt Types": adaptTypes,
        
    });
    mixpanel.people.set_once(MIXPANEL_PEOPLE_PROPS.FIRST_ADAPT_GENERATED, new Date().toISOString());
    mixpanel.people.set(MIXPANEL_PEOPLE_PROPS.LAST_ADAPT_GENERATED, new Date().toISOString());
    mixpanel.people.set_once(MIXPANEL_PEOPLE_PROPS.TOTAL_ADAPTS_GENERATED, 0);
    mixpanel.people.increment(MIXPANEL_PEOPLE_PROPS.TOTAL_ADAPTS_GENERATED, numberOfAdapts);
}

export const trackAdaptUpdated = ({ name, email, workspace, addedAdapts, adaptTypes, removedAdapts }: TrackUpdateAdapt): void => {
    mixpanel.track(MIXPANEL_EVENTS.ADAPT_UPDATE, {
        $name: name,
        $email: email,
        "Workspace Name": workspace,
        "Added Adapts": addedAdapts,
        "Removed Adapts": removedAdapts,
        "New Adapt Types": adaptTypes,
        
    });
    mixpanel.people.set(MIXPANEL_PEOPLE_PROPS.LAST_ADAPT_GENERATED, new Date().toISOString());
}

export const linkOpened = (vle_id : string): void => {
    mixpanel.track(MIXPANEL_EVENTS.SHARABLE_LINK_OPEN, { $VLE_Id: vle_id });
}

export const downloadLink = ( vleid: string, FileURL: string, FileName: string ) : void => {
    mixpanel.track(MIXPANEL_EVENTS.DOWNLOAD_LINK, { $VLE_Id: vleid, FileURL: FileURL, FileName: FileName  });
}