import { cropImage } from "../helpers/cropImage";
import { getObjectSize, getObjectHeight } from "../helpers/getObjectSize";
import { getVisibleSide } from "../helpers/getVisibleSide";
import { sortBackgroundParts } from "../helpers/sortBackgroundParts";
import { CLASSIFICATION_TYPES } from "../classification/constants";
import { resizeObj } from "../helpers/resizeObj";
import { isFirstBackgroundPart } from "../helpers/isFirstBackgroundPart";
import { cloneDeep } from "lodash";

export const resizeBackgroundPartsVertical = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  const result: any = [];
  for (const part of children) {
    if (part.classificationType == CLASSIFICATION_TYPES.BACKGROUND_PART_CENTER) {
      continue;
    }
    const { visibleWidth, visibleHeight } = part;
    const isHorizontal = part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT ||
      part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT;

    let positioningLeftMargin = part.leftMargin / prevWidth <= 0.05 && part.leftMargin >= 0 ? part.leftMargin / prevWidth * canvasWidth : 0;
    let positioningRightMargin = part.rightMargin / prevWidth <= 0.05 && part.rightMargin >= 0 ? part.rightMargin / prevWidth * canvasWidth : 0;
    let positioningTopMargin = part.topMargin / prevHeight <= 0.05 && part.topMargin >= 0 ? part.topMargin / prevHeight * canvasHeight : 0;
    let positioningBottomMargin = part.bottomMargin / prevHeight <= 0.05 && part.bottomMargin >= 0 ? part.bottomMargin / prevHeight * canvasHeight : 0;

    if (isHorizontal) {
      positioningLeftMargin = part.bottomMargin / prevHeight <= 0.05 && part.bottomMargin >= 0 ? part.bottomMargin / prevHeight * canvasWidth : 0;
      positioningRightMargin = part.topMargin / prevHeight <= 0.05 && part.topMargin >= 0 ? part.topMargin / prevHeight * canvasWidth : 0;
      positioningTopMargin = part.leftMargin / prevWidth <= 0.05 && part.leftMargin >= 0 ? part.leftMargin / prevWidth * canvasHeight : 0;
      positioningBottomMargin = part.rightMargin / prevWidth <= 0.05 && part.rightMargin >= 0 ? part.rightMargin / prevWidth * canvasHeight : 0;
    }

    const newHeight = isHorizontal ? (visibleWidth / prevWidth) * canvasHeight : (visibleHeight / prevHeight) * canvasHeight;
    let newWidth = canvasWidth - positioningLeftMargin - positioningRightMargin;

    const naturalWidth = !part.naturalWidth ? (part.width as number) : part.naturalWidth;
    const naturalHeight = !part.naturalHeight ? (part.height as number) : part.naturalHeight;
    [part.scaleX, part.scaleY] = [part.initialScaleX, part.initialScaleY];
    const [oldWidth, oldHeight] = [part.width * part.scaleX, part.height * part.scaleY]

    const rightMargin = part.rightMargin <= 0 ? part.rightMargin : 0;
    const leftMargin = part.leftMargin <= 0 ? part.leftMargin : 0;
    const topMargin = part.topMargin <= 0 ? part.topMargin : 0;
    const bottomMargin = part.bottomMargin <= 0 ? part.bottomMargin : 0;

    const cropRight = naturalWidth - part?.cropX - part?.width;
    const generalRight = cropRight + Math.abs(rightMargin) / part?.scaleX;
    const generalLeft = part.cropX + Math.abs(leftMargin) / part?.scaleX;
    const generalTop = part.cropY + Math.abs(topMargin) / part?.scaleY;
    const cropBottom = naturalHeight - part?.cropY - part?.height;
    const generalBottom = cropBottom + Math.abs(bottomMargin) / part?.scaleY;
    const area = visibleHeight * visibleWidth;

    const newRatio = newHeight / newWidth;

    const coeffArea = Math.sqrt(area);
    const coeffRatio = Math.sqrt(newRatio);

    const intermediateWidth = coeffArea / coeffRatio;
    const intermediateWidthNatural = intermediateWidth / part.scaleX;

    let maxCropWidth = 0;
    let maxCropHeight = 0;
    switch (part.classificationType) {
      case CLASSIFICATION_TYPES.BACKGROUND_PART_TOP: {
        maxCropWidth = naturalWidth - generalRight;
        maxCropHeight = naturalHeight - generalBottom;
      };
      case CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM: {
        maxCropWidth = naturalWidth - generalLeft;
        maxCropHeight = naturalHeight - generalTop;
      };
      case CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT: {
        maxCropWidth = naturalWidth - generalRight;
        maxCropHeight = naturalHeight - generalBottom;
      };
      case CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT: {
        maxCropWidth = naturalWidth - generalLeft;
        maxCropHeight = naturalHeight - generalTop;
      };
    };

    let finalWidth = intermediateWidthNatural > maxCropWidth ? maxCropWidth : intermediateWidthNatural;
    let finalRatio = newWidth / newHeight;
    let finalHeight = finalWidth / finalRatio;
    finalHeight = finalHeight > maxCropHeight ? maxCropHeight : finalHeight;
    finalWidth = finalHeight * finalRatio;

    const scaleRatio = newWidth / finalWidth;
    part.scaleX = scaleRatio;
    part.scaleY = scaleRatio;
    const scaleX = part?.scaleX;
    const scaleY = part?.scaleY;


    const getCropFunc = isHorizontal ? getCropForVerticalPart : getCropForHorizontalPart;

    const crop = getCropFunc(part, naturalWidth, naturalHeight, generalLeft, generalRight, generalTop, generalBottom, finalWidth, newHeight, scaleX, scaleY);
    const croppedPart = cropImage(part, crop);

    const isFirst = isFirstBackgroundPart(croppedPart);

    if (part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_TOP ||
      part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT) {
      result.push({
        ...croppedPart,
        initialSize: [visibleWidth, visibleHeight],
        resultSize: [newWidth, newHeight],
        left: positioningLeftMargin,
        top: positioningTopMargin
      });
    }
    if (part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM ||
      part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT) {
      result.push({
        ...croppedPart,
        initialSize: [visibleWidth, visibleHeight],
        resultSize: [newWidth, newHeight],
        left: positioningLeftMargin,
        top: canvasHeight - newHeight - positioningBottomMargin
      });
    }
  }
  const centerPart = children.find(part => part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_CENTER)
  if (centerPart) {
    const leftPart = result.find((part: any) => part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT || part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_TOP)
    const rightPart = result.find((part: any) => part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT || part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM)

    const newWidth = canvasWidth;
    const newHeight = canvasHeight - leftPart.resultSize[1] - rightPart.resultSize[1];

    const positioningLeftMargin = 0;
    const positioningTopMargin = leftPart.top + leftPart.resultSize[1];
    result.push({
      ...centerPart,
      resultSize: [newWidth, newHeight],
      left: positioningLeftMargin,
      top: positioningTopMargin
    })
  }
  return result;
};

const getCropForVerticalPart = (
  part: any,
  naturalWidth: number,
  naturalHeight: number,
  generalLeft: number,
  generalRight: number,
  generalTop: number,
  generalBottom: number,
  finalWidth: number,
  newHeight: number,
  scaleX: number,
  scaleY: number,
) => {
  switch (part.classificationType) {
    case CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT: {
      return {
        x: (naturalWidth - generalRight - finalWidth) * scaleX,
        y: naturalHeight * scaleY - newHeight - generalBottom * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      };
    };
    case CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT: {
      return {
        x: generalLeft * scaleX,
        y: generalTop * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      }
    };
    default: console.log("DEFAULT VERT")
  }
};

const getCropForHorizontalPart = (
  part: any,
  naturalWidth: number,
  naturalHeight: number,
  generalLeft: number,
  generalRight: number,
  generalTop: number,
  generalBottom: number,
  finalWidth: number,
  newHeight: number,
  scaleX: number,
  scaleY: number,
) => {
  switch (part.classificationType) {
    case CLASSIFICATION_TYPES.BACKGROUND_PART_TOP: {
      return {
        x: (naturalWidth - generalRight - finalWidth) * scaleX,
        y: naturalHeight * scaleY - newHeight - generalBottom * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      };
    };
    case CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM: {
      return {
        x: generalLeft * scaleX,
        y: generalTop * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      }
    };
    default: console.log("DEFAULT HORIZ")
  }
};