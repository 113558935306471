import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import CreativeFilters from '../../components/CreativeFilters';

import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const AdvanceSearch: FC<any> = () => {

  const SuspenseComponent = () => {
    return (
      <>
        <div className="creatives-advance-search-wrap">
          <div className="flex h-100" id="creatives-advance-search">
            <CreativeFilters />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default AdvanceSearch;

