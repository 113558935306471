import {
  WorkflowState,
  SetWorkFlows,
  SET_WORKFLOWS,
  ChangeWorkFlow,
  RemoveWorkFlow,
  CHANGE_WORKFLOW,
  REMOVE_WORKFLOW,
  FETCH_WORKFLOWS,
  FetchWorkFlows,
  SET_WORKFLOW_LOADER,
  SetWorkFlowLoader,
  DeleteWorkFlow,
  DELETE_WORKFLOW,
  CreateWorkFlow,
  AddWorkFlow,
  CREATE_WORKFLOW,
  ADD_WORKFLOW,
  SetWorkFlowData,
  FetchWorkFlowData,
  FETCH_WORKFLOW_DATA,
  SET_WORKFLOW_DATA,
  AddRound,
  CreateRound,
  CREATE_ROUND,
  ADD_ROUND,
  UPDATE_ROUND,
  CHANGE_ROUND,
  ChangeRound,
  UpdateRound,
  DeleteRound,
  RemoveRound,
  DELETE_ROUND,
  REMOVE_ROUND,
  UpdateWorkFlowData,
  ChangeWorkFlowData,
  UPDATE_WORKFLOW_DATA,
  CHANGE_WORKFLOW_DATA,
  SET_APPROVAL_POPUP_PROPS,
  SetApprovalPopUpPropsAction,
  SetWorkflowPageData,
  SET_WORKFLOW_PAGE_DATA,
  AddUserToRound,
  RemoveUserFromRound,
  ADD_USER_TO_ROUND,
  SetWorkFlowTopBarTab,
  SET_WORKFLOW_TOPBAR_TAB,
} from "../types";

const initialState: WorkflowState = {
  workflows: [],
  workflowData: null,
  pageData: null,
  loading: false,
  workflowTopBarTab: 2,
  approvalPopUpProps: {
    isOpen: false,
    projectId: -1,
  },
};

export const workflowReducer = (
  state = initialState,
  action:
    | SetWorkFlows
    | ChangeWorkFlow
    | RemoveWorkFlow
    | FetchWorkFlows
    | SetWorkFlowLoader
    | DeleteWorkFlow
    | CreateWorkFlow
    | AddWorkFlow
    | FetchWorkFlowData
    | SetWorkFlowData
    | AddRound
    | CreateRound
    | ChangeRound
    | UpdateRound
    | DeleteRound
    | RemoveRound
    | UpdateWorkFlowData
    | ChangeWorkFlowData
    | SetApprovalPopUpPropsAction
    | SetWorkflowPageData
    | SetWorkFlowTopBarTab
) => {
  switch (action.type) {
    case FETCH_WORKFLOWS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_WORKFLOWS: {
      return {
        ...state,
        workflows: action.payload,
      };
    }
    case CHANGE_WORKFLOW: {
      let { workFlowIndex, updatedWorkFlow } = action.payload;
      if (typeof workFlowIndex === "number") {
        state.workflows[workFlowIndex] = updatedWorkFlow;
      } else {
        let found = state.workflows.findIndex(
          (obj) => obj.id === updatedWorkFlow.id
        );
        if (found > -1) {
          state.workflows[found] = updatedWorkFlow;
        }
      }
      return {
        ...state,
        workflows: state.workflows,
      };
    }
    case REMOVE_WORKFLOW: {
      let newWorkFLow = state.workflows.filter(
        (obj) => obj.id !== action.payload
      );
      return {
        ...state,
        workflows: newWorkFLow,
      };
    }
    case SET_WORKFLOW_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case DELETE_WORKFLOW: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_WORKFLOW: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_WORKFLOW: {
      return {
        ...state,
        workflows: [...state.workflows, ...action.payload],
      };
    }
    case FETCH_WORKFLOW_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_WORKFLOW_DATA: {
      return {
        ...state,
        workflowData: action.payload,
      };
    }
    case CREATE_ROUND: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_ROUND: {
      return {
        ...state,
        workflowData: {
          ...state.workflowData,
          rounds: [...(state.workflowData?.rounds || []), action.payload],
        },
      };
    }
    case UPDATE_ROUND: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHANGE_ROUND: {
      let { roundIndex, updatedRound } = action.payload;
      let rounds = state.workflowData?.rounds || [];
      let index = roundIndex;
      if (typeof index !== "number") {
        index = state.workflowData?.rounds.findIndex(
          (obj) => obj.id === updatedRound.id
        );
      }
      if (typeof index === "number") rounds[index] = updatedRound;

      return {
        ...state,
        workflowData: { ...state.workflowData, rounds: rounds },
      };
    }
    case DELETE_ROUND: {
      return {
        ...state,
        loading: true,
      };
    }
    case REMOVE_ROUND: {
      let newRounds = (state.workflowData?.rounds || []).filter(
        (obj) => obj.id !== action.payload
      );
      return {
        ...state,
        workflowData: { ...state.workflowData, rounds: newRounds },
      };
    }
    case UPDATE_WORKFLOW_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHANGE_WORKFLOW_DATA: {
      return {
        ...state,
        workflowData: { ...state.workflowData, ...action.payload },
      };
    }
    case SET_APPROVAL_POPUP_PROPS: {
      return {
        ...state,
        approvalPopUpProps: action.payload,
      };
    }
    case SET_WORKFLOW_PAGE_DATA: {
      return {
        ...state,
        pageData: action.payload,
      };
    }
    case SET_WORKFLOW_TOPBAR_TAB: {
      return {
        ...state,
        workflowTopBarTab: action.payload
      }
    }
    default:
      return state;
  }
};
