import React from "react";
import ReactDOM from "react-dom";
import 'pyxis-ui-kit/dist/pyxis-ui-kit.css';
import 'pyxis-ui-kit/dist/pyxis-ui-kit-global.css';
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { createHashHistory } from 'history';
// import * as Sentry from "@sentry/react";
// import { SENTRY_DSN, LOGROCKET_DSN, MIXPANEL_TOKEN, ENV_TYPE } from "./utilities/paths";
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import 'pyxis-ui-kit/dist/pyxis-ui-kit.css';
import 'pyxis-ui-kit/dist/pyxis-ui-kit-global.css';
import { initializeMixpanel } from "./utilities/services/mixpanel";

// Sentry.init({
//   dsn: SENTRY_DSN
// });
// LogRocket.init(LOGROCKET_DSN);
// setupLogRocketReact(LogRocket);

// LogRocket.getSessionURL(sessionURL => {
//   Sentry.configureScope(scope => {
//     scope.setExtra("sessionURL", sessionURL);
//   });
// });

initializeMixpanel();

const history = createHashHistory();
let app = document.getElementById('root');
if (app) {
 /* eslint-disable */ 
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Routes />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
