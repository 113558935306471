import React, { useRef, useEffect,useState } from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { LibraryState, Typography, TemplateOption, RolesState } from '../../../store/types';
import { fetchTypographies, deleteTypography,uploadTypography, fetchTemplateOptions, updateTemplateOption, setTypography, setavailableTypography, setLoader, clearDataEditor } from '../../../store/actions';
import FontFaceObserver from 'fontfaceobserver';
import { getVarientJson, permissionDeniedClick } from '../../../utilities/common-function';
import { openToast } from '../../../Toasts';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import AlertPopUp from '../../shared/AlertPopUp';
import Modal from '../../shared/Modal';
import { Button,Tabs,TabPane, Dropdown,Menu, MenuItem } from 'pyxis-ui-kit';
import { DownOutlined } from '@ant-design/icons';

interface TypographyOptionsInterface {
    templateOptions: TemplateOption[],
    uploadedFonts: any[],
    availableFonts: any[],
    isDisabled: boolean
}
interface UploadedFontsInterface {
    typographies: Typography[],
    loading: boolean,
    handleClick: any,
    isDisabled: boolean
}
interface FontsInterface {
    handleClick: any,
    loading: boolean,
    isDisabled: boolean,
    typogrphies: Typography[]
}
interface OptionInterface{
    data: TemplateOption,
    optionIndex:number,
    isDisabled: boolean,
    uploadedFonts: any[],
    availableFonts: any[],
    templateOptions: TemplateOption[]
}
interface ContainerInterface {
    className: string
}

interface TabListInterface {
    fonts:any[],
    isDisabled:boolean,
    templateOptions:TemplateOption[],
    optionIndex:number
}

const sizes = [10,12,14,16,18,20,32,42,56,64];

const Container: React.FunctionComponent<ContainerInterface> = ({children,className}) =>{
    return <div className={className}>
        {children}
    </div>;
}

const UploadedFonts: React.FunctionComponent<UploadedFontsInterface> = ({handleClick,typographies,isDisabled,loading}) =>{
    return <React.Fragment>
        <Container className="uploaded-fonts">
            <p>Fonts</p>
            {(typographies.length === 0) ?
                <div className="uploader">
                    <p>Upload any TTF or OTF fonts to this library</p>
                    <Button type="primary" className="upload-btn" loading={loading} onClick={handleClick}>
                        <span>
                            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 9V13H10V9H7L12 4L17 9H14ZM19.35 6.03C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.03C2.34 4.36 0 6.9 0 10C0 11.5913 0.632141 13.1174 1.75736 14.2426C2.88258 15.3679 4.4087 16 6 16H19C19.6566 16 20.3068 15.8707 20.9134 15.6194C21.52 15.3681 22.0712 14.9998 22.5355 14.5355C22.9998 14.0712 23.3681 13.52 23.6194 12.9134C23.8707 12.3068 24 11.6566 24 11C24 8.36 21.95 6.22 19.35 6.03Z" fill="#FFFFFF"/>
                            </svg>
                        </span>
                        <span className="ml-10">Upload</span>
                    </Button>
                </div>
            :<Fonts typogrphies={typographies.sort((a:any, b:any)=>a.id - b.id)} loading={loading} handleClick={handleClick} isDisabled={isDisabled} />
        }
        </Container>
    </React.Fragment>;
}

const TabList:React.FC<TabListInterface>=({fonts,isDisabled,templateOptions,optionIndex})=>{
    const dispatch = useDispatch();
   return <div className="list">
        {fonts.map((font:any,index:number)=>(
            <div className="item" 
                key={font.name+font.file}
                onClick={()=>{
                    if(isDisabled){
                        permissionDeniedClick()
                    }else{
                        if(templateOptions[optionIndex].font_family!==font.name){
                            dispatch(
                                updateTemplateOption({...templateOptions[optionIndex],
                                    font_family: font.name,
                                    font_style:  (font.varients.length === 0? '': font.varients[0]),
                                    is_web_font: font.is_web_font,
                                    font_file: font.file
                                })
                            );
                        }
                    }
                }}
                style={{fontFamily: font.name+(font?.varients?.length === 0? '': (font && font.varients? '-'+font.varients[0]: ''))}}
            >{font.name}</div>
        ))}
    </div>
}

const TypographyOptions: React.FunctionComponent<TypographyOptionsInterface> = ({templateOptions,availableFonts,isDisabled,uploadedFonts}) =>{
    const dispatch = useDispatch();
    
    return <React.Fragment>
        <Container className="typography-options">
            <p className="title">Typography Options</p>
            {templateOptions.map((option,index)=>(
              <Option key={option.id} data={option} isDisabled={isDisabled} optionIndex={index}
              availableFonts={availableFonts} uploadedFonts={uploadedFonts} templateOptions={templateOptions}
              />  
            ))}
        </Container> 
    </React.Fragment>;
}

const Option: React.FunctionComponent<OptionInterface> = ({isDisabled,optionIndex,data,availableFonts,uploadedFonts,templateOptions}) => {
    const dispatch = useDispatch();
    const familyRef = useRef(null);
    const styleRef = useRef(null);
    const { section:title,font_family,font_size,font_style,is_web_font,font_file  } = data;
    const [loading,setLoading] = useState(false);
    const [fontFamily, setFontFamily] = useState(font_family+'-'+font_style);

    const addFont = (font: string | null) => {
        if (font === null) return;
        setLoading(true);
        const fontData = {
          weight: 400,
          src: `url('${IMAGE_BASE_URL}${font_file}')`,
        };
        const obs = new FontFaceObserver(font.toLowerCase(), fontData);
        // @ts-ignore
        const ff = new FontFace(font.toLowerCase(), fontData.src);
        ff.load()
          // @ts-ignore
          .then(f => {
            // @ts-ignore
            document.fonts.add(f);
          })
          .catch(() => {
            openToast('error', `Missing Font: ${font}`);
            setLoading(false)
        });
        obs
          .load()
          .then(() => {
            setLoading(false);
            setFontFamily(font);
        })
          .catch(() => {
            setLoading(false);
            setFontFamily('Helvetica-Black');
        });
    };

    useEffect(()=>{
        addFont(font_family+'-'+font_style);
    },[font_family,font_style])

    const getItemArr = ():any => {
        return getVarients(templateOptions[optionIndex].font_family);
    }

    const getVarients = (fontName: string):string[] => {
        let fonts = availableFonts;
        if(!templateOptions[optionIndex].is_web_font){
            fonts = uploadedFonts;
        }
        let found = fonts.findIndex(font=>font.name === fontName);
        if(found > -1){
            return fonts[found].varients;
        }
        return [];
    }

    return <React.Fragment>
        <Container className="typography-option">
            <p className="title">{title}</p>
            <p className={loading?"text loading":"text"}
                style={{fontFamily: fontFamily,fontSize: font_size}}
            >
                The quick brown fox jumps over the lazy dog
            </p>
            <div className="footer">
            <Dropdown 
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            className="dropdown" overlay={
            <Menu>
                <Tabs 
                    defaultActiveKey="1" 
                    className="typography-tab"
                >
                    <TabPane tab="All" key="1">
                        <TabList optionIndex={optionIndex} fonts={[...availableFonts,...uploadedFonts]} isDisabled={isDisabled} templateOptions={templateOptions}/>
                    </TabPane>
                    <TabPane tab="Web Fonts" key="2">
                        <TabList optionIndex={optionIndex} fonts={availableFonts} isDisabled={isDisabled} templateOptions={templateOptions}/>
                    </TabPane>
                    { uploadedFonts.length>0 &&
                        <TabPane tab="Uploads" key="3">
                            <TabList optionIndex={optionIndex} fonts={uploadedFonts} isDisabled={isDisabled} templateOptions={templateOptions}/>
                        </TabPane>
                    }
                </Tabs>
            </Menu>} trigger={['click']}>
                <span onClick={(e: any) => e.stopPropagation()} onDoubleClick={(e: any) => { e.stopPropagation() }}>
                    {font_family} <DownOutlined />
                </span>
            </Dropdown>
            <Dropdown 
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            className={`${getItemArr().length === 1 ? 'single-style' :''} dropdown`} overlay={
            getItemArr().length > 1 ? <Menu>
                
                <MenuItem>
                    <div className="list">
                        {getItemArr().map((text:string,index:number)=>(
                                <div className="item" key={index} onClick={()=>{
                                    if(isDisabled){
                                        permissionDeniedClick()
                                    }else{
                                        if(templateOptions[optionIndex].font_style!==text)
                                        dispatch(
                                            updateTemplateOption(
                                                {...templateOptions[optionIndex],
                                                    font_style:  text,
                                                    font_file: templateOptions[optionIndex].font_file.replace(templateOptions[optionIndex].font_style,text)
                                                }
                                            )
                                        );
                                    }
                                }}>{text}</div>
                            ))
                        }
                    </div>
                </MenuItem>
            </Menu>:<></>} trigger={['click']}>
                <span className="noselect" onClick={(e: any) => e.stopPropagation()} onDoubleClick={(e: any) => { e.stopPropagation() }}>
                    {font_style} {getItemArr().length > 1 && <DownOutlined />}
                </span>
            </Dropdown>
            </div>
        </Container>
    </React.Fragment>
}

const Fonts: React.FunctionComponent<FontsInterface> = ({typogrphies,isDisabled,loading,handleClick}) =>{
    const dispatch = useDispatch();
    const [isAlertModal, openAlertModal] = useState(-1);
    const uniqueTypo = typogrphies.filter((v,i,a)=>a.findIndex((t:any)=>(t.id === v.id))===i)

    return <Container className="fonts">
        <table className="grid">
            <thead>
                <tr className="item title">
                    <th>Font Name</th>
                    <th>Font Style</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {uniqueTypo.map((typo,index)=>(
                    <tr className="item" key={typo.id}>
                        <td>{typo.font_family}</td>
                        <td>{typo.font_style}</td>
                        <td className={isDisabled? "red permission-denied-lock" : "red"}
                            onClick={()=>openAlertModal(index)}>
                        {!isDisabled && 
                            <svg width="12" height="16" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1ZM1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z" fill="#D32F2F"/>
                            </svg>
                        }
                        </td>
                        {isAlertModal === index && (
                            <Modal>
                                <AlertPopUp
                                    title="Are you Sure?"
                                    text="Are you sure you want to remove this typography?"
                                    submittext="Delete"
                                    canceltext="Cancel"
                                    onSubmit={(setLoader:Function) => {
                                        if(isDisabled){
                                            permissionDeniedClick();
                                        }else{
                                            setLoader(true);
                                            dispatch(deleteTypography(index, typo.id, (res:any, err:boolean)=> {
                                                let typographies: any = typogrphies.filter((obj: any) => (typo.id!==obj.id))
                                                dispatch(setTypography(typographies))
                                                setLoader(false);
                                                openAlertModal(-1);
                                            }))
                                        }
                                    }}
                                    onCancel={() => {
                                        openAlertModal(-1);
                                    }}
                                />
                            </Modal>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
        <Button onClick={()=>{
            if(isDisabled){
                permissionDeniedClick()
            }else{
                handleClick()
            }
        }} 
        type="primary"
        loading={loading}
        className={isDisabled? "mt-20 permission-denied" : loading ? "mt-20 loading":"mt-20"}>
            Add another font
        </Button>
    </Container>
}

const LibraryTypography: React.FunctionComponent = () => {
    const state = useSelector((state: {library: LibraryState})=>(state.library));
    const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
    const [uploadedFonts,setUploadedFonts] = useState([]);
    const [availableFonts,setAvailableFonts] = useState([]);
    const upload = useRef(null);
    const dispatch = useDispatch();
    const { typographies,availableTypographies,loading,templateOptions } = state;
    const uniqueTypo = typographies.filter((v,i,a)=>a.findIndex((t:any)=>(t.id === v.id))===i)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        dispatch(fetchTypographies({page: 1,webfont:false},(response:any,error:boolean)=>{
            if(!error){
                setUploadedFonts(getVarientJson(response.data.results));
                dispatch((setLoader(false)))
                dispatch(setTypography(response.data.results))
            }
        }))
        dispatch(fetchTypographies({page: 1,webfont: true},(response:any,error:boolean)=>{
            if(!error){
                dispatch((setLoader(false)))
                dispatch(setavailableTypography(response.data.results))
            }
        }))
        dispatch(fetchTemplateOptions(1))

        return ()=>{
            dispatch(clearDataEditor())
        }
    },[]);
    
    useEffect(()=>{
        setUploadedFonts(getVarientJson(uniqueTypo))
    },[typographies.length])

    useEffect(()=>{
        setAvailableFonts(getVarientJson(availableTypographies))
    },[availableTypographies])

    const handleClick = () =>{
        // setIsLoading(true);
        let node:any = upload?.current;
        if(node){
            node?.click()
        }
    }

    return (<React.Fragment>
        <Container className="library-typography">
            <UploadedFonts handleClick={handleClick} loading={isLoading} typographies={typographies} isDisabled={userPermission.indexOf('manage-typography') === -1} />
            <TypographyOptions templateOptions={templateOptions} availableFonts={availableFonts} uploadedFonts={uploadedFonts} isDisabled={userPermission.indexOf('manage-typography') === -1} />
        </Container>
        <input 
            ref={upload}
            style={{display: 'none'}}
            type="file" 
            onClick={(e:any)=>e.target.value = null}
            onChange={(e:any)=>{
                setIsLoading(true);
                dispatch(uploadTypography(e.target.files,1,(res:any,err:boolean)=>{    
                    dispatch((setLoader(false)));
                    if(!err){
                        if(uniqueTypo.some((typo:any)=>typo.id===res.data.id)) {
                            openToast('warn',`${res.data.font_family} ${res.data.font_style} is already uploaded.`)
                        } else {
                            openToast('success', "Typography uploaded!")
                        }
                    }else{
                        openToast('warn','Something went wrong!')
                    }
                    setIsLoading(false);
                }))
            }}
            accept=".ttf,.otf,.woff" 
            multiple
        />
    </React.Fragment>
    )
}

export default LibraryTypography;