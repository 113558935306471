import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_NEW_AGENCY,
  DELETE_AGENCY,
  FETCH_AGENCY_LIST,
  DeleteAgency,
  FetchAgencyList,
  FETCH_AGENCY_FOLDERS,
  FetchAgencyFolders,
  CREATE_NEW_FOLDER,
  CreateNewFolder,
  FETCH_AGENCY_DATA,
  DELETE_AGENCY_FOLDER,
  DeleteAgencyFolder,
  RENAME_AGENCY_FOLDER,
  RenameAgencyFolder,
  GET_AGENCY_SHAREABLE_KEY,
  GetAgencyShareableKey,
} from "../types";
import HttpService from "../../HttpService/HttpService";
import { store } from "../store";
import { DESIGN_URI } from "../../utilities/paths";
import { agenciesAPI, agenciesFoldersAPI } from "../../MappedAPI/MappedAPI";
import { openToast } from "../../Toasts";
import {
  addNewAgency,
  addNewAgencyFolder,
  fetchAgencyListAction,
  setAgencyFolders,
  getAgencyList,
  fetchAgencyData,
  setAgencyData,
  setAgencyShareableKey,
} from "../actions/agencyFile";
import { getUrlProperties } from "../../utilities/common-function";
import { getAgencyFromParams } from "../../Pages/AgencyFileDrop/utils";

function* fetchAgencyList(action: FetchAgencyList) {
  let { workspace, page } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  let shouldLoading: boolean = false;
  try {
    let params = `?workspace=${workspace}&page=${page ? page : 1}&page_size=11`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesAPI}${params}`,
      TOKEN
    );
    if (!response.data.error) {
      const { search } = getUrlProperties();
      const selectedAgencyId = getAgencyFromParams(search);
      const isAgencyExist = response.data.data.results.some(
        (agency) => String(agency.id) === selectedAgencyId
      );
      if (!isAgencyExist && !page && !!selectedAgencyId) {
        yield put(fetchAgencyData(selectedAgencyId));
        shouldLoading = true;
      }
      yield put(
        getAgencyList({
          data: response.data.data,
          page,
          shouldLoading,
        })
      );
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* createNewAgency(action: FetchAgencyList) {
  let { payload } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesAPI}new`,
      TOKEN,
      payload
    );
    if (!response.data.error) {
      yield put(addNewAgency(response.data.data));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* fetchAgencyDataSaga(action: FetchAgencyFolders) {
  let { payload } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesAPI}${payload}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(setAgencyData(response.data.data));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* deleteAgencySaga(action: DeleteAgency) {
  let { id, workspace } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.delete,
      DESIGN_URI,
      `${agenciesAPI}${id}/delete/`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(fetchAgencyListAction({ workspace: workspace }));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* fetchAgencyFoldersSaga(action: FetchAgencyFolders) {
  let { agency_id, page } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `?type=file&agency_id=${agency_id}&page=${
      page ? page : 1
    }&page_size=22`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesFoldersAPI}${params}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(setAgencyFolders({ data: response.data.data, page }));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* createNewAgencyFolderSaga(action: CreateNewFolder) {
  let { payload } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let requiredPayload = {
      ...payload,
      type: "file",
      product_id: null,
      parent: null,
    };
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesFoldersAPI}new/`,
      TOKEN,
      requiredPayload
    );
    if (!response.data.error) {
      yield put(addNewAgencyFolder(response.data.data));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* deleteAgencyFolderSaga(action: DeleteAgencyFolder) {
  let { id } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    const requiredPayload = { folder: [id] };
    yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesFoldersAPI}trash/`,
      TOKEN,
      requiredPayload
    );
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* renameAgencyFolderSaga(action: RenameAgencyFolder) {
  let { id, name } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    yield call(
      HttpService.put,
      DESIGN_URI,
      `${agenciesFoldersAPI}${id}`,
      TOKEN,
      { name }
    );
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* getAgencyShareableKeySaga(action: GetAgencyShareableKey) {
  let { password_flag, agency } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesAPI}${agency}/share`,
      TOKEN,
      { password_flag }
    );
    if (!response.data.error) {
      yield put(setAgencyShareableKey(response.data.data));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

export function* agenciesWatcher() {
  yield takeEvery(FETCH_AGENCY_LIST, fetchAgencyList);
  yield takeEvery(CREATE_NEW_AGENCY, createNewAgency);
  yield takeEvery(DELETE_AGENCY, deleteAgencySaga);
  yield takeEvery(FETCH_AGENCY_FOLDERS, fetchAgencyFoldersSaga);
  yield takeEvery(CREATE_NEW_FOLDER, createNewAgencyFolderSaga);
  yield takeEvery(FETCH_AGENCY_DATA, fetchAgencyDataSaga);
  yield takeEvery(DELETE_AGENCY_FOLDER, deleteAgencyFolderSaga);
  yield takeEvery(RENAME_AGENCY_FOLDER, renameAgencyFolderSaga);
  yield takeEvery(GET_AGENCY_SHAREABLE_KEY, getAgencyShareableKeySaga);
}
