import React, { useState,useEffect } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCross, faArrowLeft, faArrowRight, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import GreyBackground from '../../../assets/img/grey-background.png';
import TopBar from '../../shared/TopBar';
import Modal from '../../shared/Modal';
import AddTextPopUp from '../AddTextPopUp';
import { useHistory,useParams } from 'react-router-dom';
import { fetchLayersWithAssets,setLayersWithAssests,addLayerAssets,deleteLayerAsset } from '../../../store/actions';
import { AuthState, HyperProjectFoldersState } from '../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import GalleryPopUp from '../../shared/GalleryPopUp';
import { openToast } from '../../../Toasts';
import Img from '../../ImageLoader/ImageLoader';
import useWebSocket from '../../shared/hooks/useWebSocket';
import { WEBSOCKET_ENDPOINT } from '../../../utilities/paths';
import Lottie from 'react-lottie';
import loaderAnimation from '../../../assets/animation/loader.json';
import { layer } from '@fortawesome/fontawesome-svg-core';
import { getTourStateValue, HYPER_PERSONALIZE_SINGLE_TOUR_ID, startTour, updateTourState } from '../../../utilities/services/intercom';
import Footer from '../Footer';
import { Title } from 'pyxis-ui-kit';

interface LayersContainerInterface {
    titleIcon: any;
    title: string;
    addLayerText: string;
    layers: any[];
    layerType:string
    addLayerClick: Function;
    layerIndex:number;
    removeLayerClick: Function;
    updateAsset: Function;
}

const Asset:React.FC<{
    layer: any;
    removeLayerClick: Function;
    layerType: string;
    index: number;
    layerIndex: number;
    updateAsset: Function;
}> = ({layer,removeLayerClick,layerType,index,layerIndex,updateAsset}) => {
    const token = useSelector((state: {authentication: AuthState})=>(state.authentication.token));
    const [client,setClientActions] = useWebSocket(`${WEBSOCKET_ENDPOINT}/hp-project-asset/${layer?.id}/ready-update?token=${token}`,layer?.ready_status === "NOT_STARTED")
    const [dataLoading,setDataLoading] = useState(false);

    useEffect(()=>{
        if(client)
        setClientActions((val:any)=>({
            ...val,
            onError:(err:any)=>{console.error('error','Not able to connect!')},
            onRecieve:(data:any)=>{
                updateAsset(layerIndex,index,data);
            }
        }))
    },[])

    useEffect(()=>{
        if((layer.ready_status === "COMPLETED"||layer.ready_status === "FAILED") && client){
            client.close()
        }
    },[layer.ready_status])

    return <div className="layer" key={layer.id}>
        {layer?.ready_status === "FAILED" && <div className="overlay">
            <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#D32F2F"/>
            </svg>
        </div>}
        {(layer?.ready_status === "NOT_STARTED"||layer?.ready_status === "INPROGRESS") && !layer?.ready_failure_reason && <div className="overlay">
            <Lottie options={{
                    animationData: loaderAnimation,
                    loop: true,
                    autoplay: true, 
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={100}
                height={100}
            />
        </div>}
        {!dataLoading && !layer.is_default && <div className="cross-icon" onClick={()=>{
            removeLayerClick(layerIndex,index,setDataLoading)
        }}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#D32F2F"/>
                <path d="M22.2237 11.0312L20.9704 9.77783L16.0015 14.7467L11.0326 9.77783L9.7793 11.0312L14.7482 16.0001L9.7793 20.9689L11.0326 22.2223L16.0015 17.2534L20.9704 22.2223L22.2237 20.9689L17.2549 16.0001L22.2237 11.0312Z" fill="white"/>
            </svg>
        </div>}
        <div className="image">
            {layerType.toLowerCase() === 'text' ? <div className="text">{layer.text}</div> :
                <Img src={layer?.link||""} />
            }
        </div>
    </div>
}

const LayersContainer:React.FC<LayersContainerInterface> = ({titleIcon,title,layers,layerType,addLayerText,addLayerClick,layerIndex,removeLayerClick,updateAsset}) => {
    return <div className="layers-container">
        <div className="title">
            <div className="icon">
                {titleIcon}
            </div>
            <div className="text ml-10">
                {title}
            </div>
        </div>
        <div className="layers">
            <div className="add-layer" onClick={()=>addLayerClick()}>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.3327 30.3332H30.3327V44.3332H25.666V30.3332H11.666V25.6665H25.666V11.6665H30.3327V25.6665H44.3327V30.3332Z" fill="#515151"/>
                </svg>
                <span>{addLayerText}</span>
            </div>
            {layers.map((layer:any,index:number)=>(
                <Asset layer={layer} removeLayerClick={removeLayerClick} layerType={layerType} 
                index={index} layerIndex={layerIndex} updateAsset={updateAsset} key={layer.id} />
            ))}
        </div>
    </div>
}

const Layers:React.FC = () => {
    const layersWithAssets = useSelector((state:{hyperProject: HyperProjectFoldersState})=>(state.hyperProject.layersWithAssets)) 
    const history = useHistory();
    const [openTextPopUp,setOpenTextPopUp] = useState(-1);
    const [openImagePopUp,setOpenImagePopUp] = useState(-1);
    const [dataLoading,setDataLoading] = useState(false);
    const params:any = useParams();
    const dispatch = useDispatch();
    const allAssetsReady:boolean = !!!layersWithAssets?.results.find((l:any)=>{
        return l?.assets.find((a:any)=>a.ready_status !== "COMPLETED"&&a.ready_status !== "FAILED");
    })
    
    useEffect(()=>{
        if(!allAssetsReady){
            setTimeout(()=>{
                dispatch(fetchLayersWithAssets({project_id: params.projectId,page:1},(res:any,err:boolean)=>{
                    if(!err){
                        dispatch(setLayersWithAssests(res.data))
                    }
                }))
            },10000)
        }
    },[allAssetsReady])

    const addLayerClick = (type:string,id:number) => {
        switch(type){
            case 'text': setOpenTextPopUp(id); break;
            case 'image': setOpenImagePopUp(id); break;
        }
    }

    const removeLayerClick = (layerIndex:number,assetIndex:number,setDataLoading:any) => {
        setDataLoading(true);
        if(params.projectId)
        dispatch(deleteLayerAsset({project_id:params.projectId,layer_id: layersWithAssets?.results[layerIndex].id,asset_id: layersWithAssets?.results[layerIndex].assets[assetIndex].id},(res:any,err:boolean)=>{
            setDataLoading(false);
            if(!err){
                let { results } = layersWithAssets;
                results[layerIndex].assets.splice(assetIndex,1);
                dispatch(setLayersWithAssests({...layersWithAssets,results}))
                openToast('success','Asset Removed!')
            }else{
                openToast('error','Unable to Remove the asset!')
            }
        }))
    }

    const updateAsset = (layerIndex:number,assetIndex:number,updatedAsset:any) => {
        let { results } = layersWithAssets;
        results[layerIndex].assets[assetIndex] = updatedAsset;
        dispatch(setLayersWithAssests({...layersWithAssets,results}))
    }
    
    useEffect(()=>{
        if(params.projectId){
            setDataLoading(true)
            dispatch(fetchLayersWithAssets({project_id: params.projectId,page:1,page_size:999},(res:any,err:boolean)=>{
                setDataLoading(false)
                if(!err){
                    dispatch(setLayersWithAssests(res.data))
                }
            }))
        }

        if(!getTourStateValue('HYPER_PERSONALIZE_SINGLE_TOUR')){
            startTour(HYPER_PERSONALIZE_SINGLE_TOUR_ID);
            updateTourState('HYPER_PERSONALIZE_SINGLE_TOUR',true);
        }

        return ()=>{
            dispatch(setLayersWithAssests(null))
        }
    },[])

    return <div className="hyper-personlize-layers-listing">
        {/* <TopBar  
            title="Customize Assets for Creative" subtitle={dataLoading? <FontAwesomeIcon icon={faSpinner} spin />: `${!layersWithAssets?.results.length? "No":layersWithAssets?.results.length} Layers`} 
            leftBtn={ <button className="back-btn-secondary" onClick={()=>history.go(-1)}>
                    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0002 7.00008V9.00008H4.00016L9.50016 14.5001L8.08016 15.9201L0.160156 8.00008L8.08016 0.0800781L9.50016 1.50008L4.00016 7.00008H16.0002Z" fill="#20007F"/>
                    </svg>
                <span className="ml-10">Back</span>
            </button> }
            rightBtn={
                <button className="primary" 
                    disabled={layersWithAssets?.results.length === 0}
                    onClick={()=>{
                        if(allAssetsReady){
                            history.push(`/cocreate/creative-automation/editor/${params.projectId}`)
                        }else{
                            openToast('info','Some Assets are still processing!')
                        }
                    }}
                >
                    <span className="mr-10">Open in Editior</span>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.16 8.99992L0.160001 6.99992L12.16 6.99992L6.66 1.49992L8.08 0.079921L16 7.99992L8.08 15.9199L6.66 14.4999L12.16 8.99992L0.16 8.99992Z" fill="white"/>
                        </svg>
                </button>
            }
        /> */}
        <Title className="heading">Layers</Title>
        <Title className="subheading">Lock layers that you do not want to change in creative variants.</Title>
        <Title className="layers-number">{dataLoading? <FontAwesomeIcon icon={faSpinner} spin />: `${!layersWithAssets?.results.length? "No":layersWithAssets?.results.length} layers`}</Title>
        {(layersWithAssets?.results||[]).map((layer:any,layerIndex:number)=>(
            <LayersContainer  
                titleIcon={!dataLoading && layer.type.toLowerCase() === "image" ? 
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="4" fill="#FBBC1A"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 22H12.5C11.3953 22 10.5 21.1047 10.5 20V12C10.5 10.8953 11.3953 10 12.5 10H20.5C21.6047 10 22.5 10.8953 22.5 12V20C22.5 21.1047 21.6047 22 20.5 22Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.1586 13.0087C15.6142 13.4643 15.6142 14.203 15.1586 14.6586C14.703 15.1142 13.9643 15.1142 13.5087 14.6586C13.0531 14.203 13.0531 13.4643 13.5087 13.0087C13.9643 12.5531 14.703 12.5531 15.1586 13.0087" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.5003 14.7397C22.2003 14.697 21.8957 14.667 21.5837 14.667C18.0397 14.667 15.167 17.5397 15.167 21.0837C15.167 21.3957 15.197 21.7003 15.2397 22.0003" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> :
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="4" fill="#12C8B2"/>
                        <g clip-path="url(#clip0)">
                            <rect x="14.133" y="11.0998" width="0.6" height="10.0667" fill="#4253A9" stroke="white" stroke-width="0.6"/>
                            <rect x="10.133" y="11.5662" width="0.6" height="8.52" transform="rotate(-90 10.133 11.5662)" fill="#4253A9" stroke="white" stroke-width="0.6"/>
                            <rect x="19.467" y="15.0998" width="0.6" height="6.06667" fill="#4253A9" stroke="white" stroke-width="0.6"/>
                            <rect x="16.8" y="15.5662" width="0.6" height="5.71333" transform="rotate(-90 16.8 15.5662)" fill="#4253A9" stroke="white" stroke-width="0.6"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="13.3333" height="13.3333" fill="white" transform="translate(9.83301 9.33301)"/>
                            </clipPath>
                        </defs>
                    </svg>}
                title={layer.name}
                layerType={layer.type}
                addLayerText={layer.type.toLowerCase() === "image"? "Add Image":"Add Text"}
                addLayerClick={()=>addLayerClick(layer.type.toLowerCase(),layerIndex)}
                removeLayerClick={removeLayerClick}
                layers={layer.assets}
                layerIndex={layerIndex}
                updateAsset={updateAsset}
                key={layer.id}
            />
        ))}
        {openTextPopUp !== -1 && <Modal>
            <AddTextPopUp onCancel={()=>setOpenTextPopUp(-1)} onSubmit={(assets:any[],setLoader:Function)=>{
                setLoader(true);
                dispatch(addLayerAssets({project_id: params.projectId,layer_id: layersWithAssets.results[openTextPopUp].id,assets},(res:any,err:boolean)=>{
                    if(!err){
                        let assets = [...layersWithAssets.results[openTextPopUp].assets,...res.data.results];
                        layersWithAssets.results[openTextPopUp].assets = assets;
                        dispatch(setLayersWithAssests(layersWithAssets));
                        openToast('success','Asset Added!')
                    }else{
                        openToast('error','Unable to add Assets!');
                    }
                    setLoader(false);
                    setOpenTextPopUp(-1);
                }))
            }} />
        </Modal>}
        {openImagePopUp !== -1 && <Modal>
            <GalleryPopUp onCancel={()=>setOpenImagePopUp(-1)} onSubmit={(selectedImages:string[],setLoader:Function)=>{
                setLoader(true);
                let assets:any[] = selectedImages.reduce((acc:any,val:any)=>(
                    [...acc,{
                        type: "Image",
                        link: val
                    }]
                ),[]);
                dispatch(addLayerAssets({project_id: params.projectId,layer_id: layersWithAssets.results[openImagePopUp].id,assets},(res:any,err:boolean)=>{
                    if(!err){
                        let assets = [...layersWithAssets.results[openImagePopUp].assets,...res.data.results];
                        layersWithAssets.results[openImagePopUp].assets = assets;
                        dispatch(setLayersWithAssests(layersWithAssets));
                        openToast('success','Asset Added!')
                    }else{
                        openToast('error','Unable to add Assets!');
                    }
                    setLoader(false);
                    setOpenImagePopUp(-1);
                }))
            }} />    
        </Modal>}
        <Footer layersWithAssets={layersWithAssets} allAssetsReady={allAssetsReady} projectId={params.projectId} />
    </div>
}

export default Layers;