import {
  SetIsLoggedIn,
  SET_IS_LOGGED_IN,
  SetToken,
  SET_TOKEN,
  Login,
  LOGIN,
  UserData,
  SetUserData,
  SET_USER_DATA,
  LOGOUT,
  AuthState,
  SET_AUTH_DATA,
  SET_ACCESS_TOKEN,
  SetAccessToken,
  FetchAccessToken,
  FETCH_ACCESS_TOKEN,
  UpdateProfile,
  UPDATE_PROFILE,
  Authorize,
  AUTHORIZE,
  CallbackFunctionAPI,
  SEND_RESET_LINK,
  SendResetLinkPayload,
  SendResetLink
} from "../types";

export const setIsLoggedIn = (payload: boolean): SetIsLoggedIn => ({
  type: SET_IS_LOGGED_IN,
  payload,
});

export const setToken = (payload: string | null): SetToken => ({
  type: SET_TOKEN,
  payload,
});

export const login = (
  payload: { email: string; password: string },
  callback?: Function
): Login => ({
  type: LOGIN,
  payload,
  callback,
});

export const setUserData = (payload: UserData): SetUserData => ({
  type: SET_USER_DATA,
  payload,
});

export const logout = () => ({type: LOGOUT});

export const setAccessToken = (payload: string|null):SetAccessToken => ({
  type: SET_ACCESS_TOKEN,
  payload
});

export const fetchAccessToken = (callback?:Function):FetchAccessToken => ({
  type: FETCH_ACCESS_TOKEN,
  callback
});

export const updateProfile = (
  payload: { 
    firstName?: string;
    lastName?: string;
    newPassword?: string;
    phoneNumber?: string;
    profileColour?: string;
    confirmPassword?: string;
    currentPassword?: string;
    profilePicture?: File;
    username?: string; 
  },
  callback?:Function
): UpdateProfile => ({
  type: UPDATE_PROFILE,
  payload,
  callback
});

export const authorize = (payload: {
  token: string;
}, callback?: Function ):Authorize => ({
  type: AUTHORIZE,
  payload,
  callback
});

export const sendResetLink = (payload: SendResetLinkPayload, callback?: CallbackFunctionAPI): SendResetLink => ({
  type: SEND_RESET_LINK,
  payload,
  callback,
})