import { SetPublicUser, SetPublicToken, SET_PUBLIC_TOKEN, SET_PUBLIC_USER } from "../types/publicLink";

export const setPublicUser = (payload: any): SetPublicUser => ({
    type: SET_PUBLIC_USER,
    payload,
});

export const setPublicToken = (payload: string | null): SetPublicToken => ({
    type: SET_PUBLIC_TOKEN,
    payload,
});