import React, { useEffect } from "react";
import "./index.scss";
import PromptingBackground from "../../assets/img/PromptingBackground.png";
import AiDesignerImageBoxBackground from "../../assets/img/AiDesignerImageBoxBackground.png";
import SimplePromptingImage from "../../assets/img/advancepromptingbackground.png";
import { Select } from "antd";
import { Button } from "pyxis-ui-kit";
import LoadingCard from "./components/LoadingCard";
import { ImageCard } from "./components/ImageCard";
import AiDesignerModel2 from "../../assets/img/AiDesignerModel2.png";
import { SideSelect } from "./components/SideSelect";
import { useParams } from "react-router-dom";
import { Input } from "antd";
import starsIcon from "../../assets/svg/StarsIcon.svg";
import { HistoryRounded } from "@material-ui/icons";
import { FileOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import { Radio, Space } from "antd";
import ModePopup from "./ModePopup";
import MustContain from "./MustContain";
import FieldPopup from "./MustContainPopup";
import ProductPopup from "./ProductPopup";
import { openToast } from "../../Toasts";
import {
  incrementFileUploaded,
  trackAdaptGenerated,
  trackUploadFiles,
} from "../../utilities/services/mixpanel";
import axios from "axios";
import { AuthState, RolesState } from "../../store/types";
import { saveAiDesignData } from "../../store/actions/createAiquireActions";
import aiDesignerTemplate1 from "../../assets/img/aiDesignerTemplate1.png";
import aiDesignerTemplate2 from "../../assets/img/aiDesignerTemplate2.png";
import aiDesignerTemplate3 from "../../assets/img/aiDesignertemplate3.png";
import BackArrowAiDesigner from "../../assets/svg/BackArrowAiDesigner.svg";
import { createProject } from "../../store/actions";
import AiDesignerSavedIcon from "../../assets/img/AiDesignerSavedIcon.png";
import Carousel from "./Carousel";
import { useDispatch, useSelector } from "react-redux";
import prompts from "./prompts";
import AiDesignerDownloadIcon from "../../assets/svg/AiDesignerDownloadIcon.svg";
import { set } from "lodash";
import { makeStyles, Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "antd";
import PhotoCarousel from "./PhotoCarousel";
import ErrorDialog from "./ErrorDialog";
import HistroyModal from "./HistroyModal";
import { getCoverByBrandOwner, getCoverTextByBrandOwner } from "./utils";
const { TextArea } = Input;

const AdvancePromptingCard = () => {
  const [selectedCard, setSelectedCard] = React.useState<any>(null);
  const [advancePrompt, setAdvancePrompt] = React.useState<string>("");
  const [productPopup, setProductPopup] = React.useState<any>(false);
  const [product, setProduct] = React.useState("");
  const [selectedTemplate, setSelectedTemplate] = React.useState<any>(null);
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [selectedImage, setSelectedImage] = React.useState<any>(null);
  const [loadingScreen, setLoadingScreen] = React.useState<boolean>(false);
  const [aiResponse, setAIResponse] = React.useState<any>({});
  const [showOverlay, setShowOverlay] = React.useState<boolean>(true);
  const { userData } = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const [selectedImages, setSelectedImages] = React.useState<any>([]);
  const [wannaStartFresh, setWannaStartFresh] = React.useState<boolean>(false);
  const [hashtag, setHashtag] = React.useState<any>(null);
  const [isMyCreationsOpen, setisMyCreationsOpen] = React.useState<boolean>(
    false
  );
  const classes = useStyles();
  const selectedProductId = useSelector(
    (state: { roles: RolesState }) => state.roles.selectedProductId
  );
  const dispatch = useDispatch();
  const [requestId, setRequestId] = React.useState("");
  const props = useSelector((state: any) => ({
    templateSizes: state.create.templateSizes,
    popularSizes: state.create.popularTemplateSizes,
    productId: state.roles.selectedProductId,
    folder: state.folders.activeFolderIdCreate,
  }));
  const organistationId = useSelector((state: any) => state.roles.org_id);
  const CoverImage = getCoverByBrandOwner(organistationId, true);
  const coverText = getCoverTextByBrandOwner(organistationId);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const checkState = async (exportId: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/status/${exportId}`,
      {
        headers: {
          //   header1: value1,
          "ngrok-skip-browser-warning": "true",
        },
      }
    );
    return response.data.request.status;
  };

  const nextHandler = async () => {
    setLoadingScreen(true);
    const { lastName, firstName, emailId } = userData;
    trackUploadFiles({
      name: `${firstName} ${lastName}`,
      email: emailId,
      workspace: "",
      numberOfFiles: 1,
      fileTypes: ["image"],
    });

    const postData: any = {
      title: "My Project",
      type: "image",
      product_id: props.productId,
      sizes: [{ name: "custom1", type: "custom", width: 1080, height: 1080 }],
      template: null,
      folder: null,
    };
    dispatch(
      createProject(postData, (res: any, isError: any) => {
        if (isError) openToast("error", "project creation failed");
        if (!isError) {
          incrementFileUploaded();
          const aiData = {
            ...aiResponse,
            images: selectedImages,
            requestId: requestId,
          };
          dispatch(
            saveAiDesignData({
              aiData: aiData,
              selectedImage: selectedImage,
              selectedTemplate: selectedTemplate,
            })
          );
          return history.push(
            `/cocreate/editor/${res.data.id}?type=aidesigner`
          );
        }
      })
    );
  };

  const finished = function(id) {
    return new Promise<void>((resolve, reject) => {
      let secondsPassed = 0;
      const checker = setInterval(async () => {
        let state = await checkState(id);
        if (state == "PROCESSING" || state == "PENDING") {
          if (secondsPassed >= 4800) {
            setLoadingScreen(false);
            localStorage.setItem("aidesigner_prompts_error", null);
            localStorage.setItem("aidesigner_prompts_error", "NOT RESPONDING");
            setOpen(true);
            clearInterval(checker);
          } else {
            secondsPassed += 10; // Increment by 10 minutes (interval is 10000 ms)
          }
        } else if (state == "FAILED") {
          setLoadingScreen(false);
          localStorage.setItem("aidesigner_prompts_error", null);
          localStorage.setItem("aidesigner_prompts_error", "FAILED");
          setOpen(true);
          clearInterval(checker);
          resolve();
        } else {
          clearInterval(checker);
          resolve();
        }
      }, 10000);
    });
  };

  const getStatus = async (uuid) => {
    await axios
      .get(
        `${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/status/${uuid}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        if (res.data.request.status == "COMPLETED") {
          setAIResponse(res.data);
          setActiveStep(3);
          setLoadingScreen(false);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGenerate = async () => {
    setLoadingScreen(true);

    await axios
      .post(
        `${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/generate-image/advance-prompt`,
        {
          scenario: advancePrompt,
          user_id: userData.userId,
          product_type: product,
          hashtag: hashtag,
          workspace_id: selectedProductId,
        }
      )
      .then(async (res) => {
        await finished(res.data.uuid)
          .then(() => {
            getStatus(res.data.uuid);
            setRequestId(res.data.uuid);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        const error = err?.response.data.error;
        const timeString = error.match(/after (.+?) GMT/)[1];
        localStorage.setItem("aidesigner_prompts_error", null);
        localStorage.setItem("aidesigner_prompts_error", timeString);

        setOpen(true);
        setLoadingScreen(false);
      });
  };

  const onChangeTextArea = (e: any) => {
    setAdvancePrompt(e.target.value);
  };

  if (loadingScreen) return <LoadingCard />;

  if (activeStep == 3)
    return (
      <div className='image-card'>
        <div style={{ position: "absolute", right: "2rem" }}>
          <Button
            onClick={() => {
              setisMyCreationsOpen(true);
            }}
          >
            <div style={{ display: "flex", alignContent: "center" }}>
              {" "}
              <HistoryRounded style={{ fontSize: "16px" }} />
            </div>
          </Button>
        </div>
        <div className='back-btn'>
          <img src={BackArrowAiDesigner} onClick={() => setActiveStep(1)} />
        </div>
        <div className='header'>
          <div className='title'>Choose an image</div>
          <div className='desc'>
            Click on the image that resonates most with your product/ad
          </div>
        </div>
        <div className='content'>
          <PhotoCarousel
            requestId={requestId}
            images={aiResponse.request.imageResponse.imageUrls}
            setSelectedImage={setSelectedImage}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
          />
        </div>
        <div style={{ display: "flex", alignSelf: "flex-end", gap: "10px" }}>
          <Button
            btnsize='md'
            style={{ alignSelf: "flex-end" }}
            className='primary submit refresh-btn'
            onClick={handleGenerate}
          >
            Refresh
          </Button>
          <Button
            btnsize='md'
            style={{ alignSelf: "flex-end" }}
            type='primary'
            className='primary submit'
            onClick={() => setActiveStep(4)}
            disabled={selectedImage == null}
          >
            Next
          </Button>
        </div>
        <HistroyModal
          isHistoryOpen={isMyCreationsOpen}
          setisHistoryOpen={setisMyCreationsOpen}
        />
      </div>
    );
  if (activeStep == 4)
    return (
      <div className='image-card'>
        <div className='back-btn'>
          <img src={BackArrowAiDesigner} onClick={() => setActiveStep(3)} />
        </div>
        <div className='header'>
          <div className='title'>Pick a style</div>
          <div className='desc'>
            Choose a layout that works best for your ad
          </div>
        </div>
        <div className='content' style={{ alignItems: "center" }}>
          <Carousel
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </div>
        <Button
          btnsize='md'
          type='primary'
          style={{ alignSelf: "flex-end" }}
          className='primary submit'
          onClick={nextHandler}
          disabled={!selectedTemplate}
        >
          Next
        </Button>
      </div>
    );

  return (
    <div className='advanceprompting-card'>
      <div></div>
      {activeStep == 1 ? (
        <div className='input-box' style={{ position: "relative" }}>
          <div style={{ position: "absolute", right: "2rem" }}>
            <Button
              onClick={() => {
                setisMyCreationsOpen(true);
              }}
            >
              <div style={{ display: "flex", alignContent: "center" }}>
                {" "}
                <HistoryRounded style={{ fontSize: "16px" }} />
              </div>
            </Button>
          </div>
          <div className='content'>
            <div className='header'>
              <div className='title'>Advance Prompting</div>
              <div className='desc'>
                Describe your design as you would to your designer
              </div>
            </div>
            <div className='steps'>
              <div className='stepOf'>Step 1 OF 2</div>
              <div className='step-title-container'>
                <div
                  className='step-tile'
                  style={{ backgroundColor: "#6B38C4" }}
                ></div>
                <div
                  className='step-tile'
                  style={{ backgroundColor: "#EAEAEC" }}
                ></div>
              </div>
            </div>
            <div className='description-container'>
              <div className='description-title'>Describe your product</div>
              <div className='description-content'>
                Please provide the requested details as accurately as possible
                for the best customized copy
              </div>
            </div>
            <div className='input-container'>
              <div className='adv-pmpt-field'>
                <label>Product Category</label>
                <input
                  placeholder='E.g. Gold Loan'
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  className='ipt'
                />
              </div>
              <div className='adv-pmpt-field'>
                <label>Product hashtag</label>
                <input
                  value={hashtag}
                  onChange={(e) => setHashtag(e.target.value)}
                  placeholder='E.g. InstantGoldLoan'
                  className='ipt'
                />
              </div>
            </div>
            <Button
              btnsize='md'
              type='primary'
              className='primary submit'
              style={{ alignSelf: "flex-end" }}
              onClick={() => setActiveStep(2)}
              disabled={!hashtag?.length}
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <div className='input-box' style={{ position: "relative" }}>
          <div style={{ position: "absolute", right: "2rem" }}>
            <Button
              onClick={() => {
                setisMyCreationsOpen(true);
              }}
            >
              <div style={{ display: "flex", alignContent: "center" }}>
                {" "}
                <HistoryRounded style={{ fontSize: "16px" }} />
              </div>
            </Button>
          </div>
          <div className='content'>
            <div className='header'>
              <div className='title'>Advance Prompting</div>
              <div className='desc'>
                Describe your design as you would to your designer
              </div>
            </div>
            <div className='steps'>
              <div className='stepOf'>Step 2 OF 2</div>
              <div className='step-title-container'>
                <div
                  className='step-tile'
                  style={{ backgroundColor: "#6B38C4" }}
                ></div>
                <div
                  className='step-tile'
                  style={{ backgroundColor: "#6B38C4" }}
                ></div>
              </div>
            </div>
            <div className='description-container'>
              <div className='description-title'>Describe the Scenario</div>
              <div className='description-content'>
                Please provide the requested details
              </div>
            </div>
            <div className='input-container'>
              <div className='adv-pmpt-field'>
                <label>Scenario</label>
                <TextArea
                  onChange={onChangeTextArea}
                  style={{ resize: "none" }}
                  placeholder='E.g. A confident 25-year-old Indian businessman, who has opened his own mobile phone store from his personal income, stands smartly with his hands folded. He gazes into the camera, behind him is a blurred view of his newly inaugurated mobile store, minimally decorated and bathed in soft white light.'
                  className='txt'
                />
              </div>
            </div>
            <Button
              btnsize='md'
              type='primary'
              className='primary submit'
              style={{ alignSelf: "flex-end" }}
              onClick={handleGenerate}
              disabled={!advancePrompt?.length}
            >
              Generate
            </Button>
          </div>
        </div>
      )}
      <div className='image-box'>
        <img src={CoverImage} />
        <div className='prompt-card-container'>
          <PromptCard coverText={coverText} />
        </div>
      </div>
      <Dialog
        classes={{ paper: classes.dialogContainer }}
        fullWidth
        maxWidth={"md"}
        open={wannaStartFresh}
        onClose={() => setWannaStartFresh(false)}
      >
        <div className='dialog-header'>
          <div className='dialog-title'>
            <div
              className='wanna-start-fresh-dialog'
              style={{
                fontSize: "20px",
                color: "#4A4A4A",
                textAlign: "center",
              }}
            >
              Want to start fresh?
            </div>
            <div
              className='sub-title'
              style={{ fontSize: "16px", color: "#BFBFBF", padding: "1rem" }}
            >
              Choose if you want to upload your PSD file or start with black
              canvas
            </div>
          </div>
          <IconButton
            aria-label='close'
            className='close-btn'
            style={{ top: "1rem" }}
            onClick={() => setWannaStartFresh(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className='dialog-content' style={{ paddingBottom: "8rem" }}>
          <div className='wanna-start-btn-container'>
            <div className='wanna-start-btn'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26'
                  height='25'
                  viewBox='0 0 26 25'
                  fill='none'
                >
                  <g clip-path='url(#clip0_9743_10638)'>
                    <path
                      d='M4.66634 19.7917H21.333V12.5H23.4163V20.8333C23.4163 21.1096 23.3066 21.3745 23.1112 21.5699C22.9159 21.7652 22.6509 21.875 22.3747 21.875H3.62467C3.34841 21.875 3.08346 21.7652 2.8881 21.5699C2.69275 21.3745 2.58301 21.1096 2.58301 20.8333V12.5H4.66634V19.7917ZM14.0413 9.375V16.6667H11.958V9.375H6.74967L12.9997 3.125L19.2497 9.375H14.0413Z'
                      fill='#030303'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_9743_10638'>
                      <rect
                        width='25'
                        height='25'
                        fill='white'
                        transform='translate(0.5)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>Upload Design Files</div>
            </div>
            <div className='wanna-start-btn'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26'
                  height='27'
                  viewBox='0 0 26 27'
                  fill='none'
                >
                  <g clip-path='url(#clip0_9743_10645)'>
                    <path
                      d='M9.75003 2.67122V2.66797H21.6645C22.2625 2.66797 22.75 3.16089 22.75 3.74264V23.26C22.7497 23.5451 22.6363 23.8184 22.4346 24.0199C22.2329 24.2215 21.9594 24.3346 21.6743 24.3346H4.32578C4.18351 24.3336 4.04283 24.3046 3.91178 24.2493C3.78072 24.1939 3.66185 24.1133 3.56196 24.012C3.46206 23.9107 3.3831 23.7907 3.32958 23.6589C3.27606 23.5271 3.24903 23.386 3.25003 23.2437V9.16797L9.75003 2.67122ZM6.31586 9.16797H9.75003V5.7338L6.31586 9.16797ZM11.9167 4.83464V10.2513C11.9167 10.5386 11.8026 10.8142 11.5994 11.0173C11.3962 11.2205 11.1207 11.3346 10.8334 11.3346H5.41669V22.168H20.5834V4.83464H11.9167Z'
                      fill='black'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_9743_10645'>
                      <rect
                        width='26'
                        height='26'
                        fill='white'
                        transform='translate(0 0.5)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>Start from blank canvas</div>
            </div>
          </div>
        </div>
      </Dialog>
      <HistroyModal
        isHistoryOpen={isMyCreationsOpen}
        setisHistoryOpen={setisMyCreationsOpen}
      />

      {open && <ErrorDialog open={open} setOpen={setOpen} />}
    </div>
  );
};

const CustomSelect = ({ ...props }) => {
  return (
    <div className='custom-select'>
      <Select {...props} />
    </div>
  );
};

const PromptCard = ({ coverText }) => {
  return (
    <div className='prompt-card'>
      <img src={starsIcon} />
      <div className='prompt-card-content'>{coverText}</div>
    </div>
  );
};

const options = [
  { label: "Education Loan", key: "EDUCATION_LOAN" },
  { label: "Home Loan", key: "HOME_LOAN" },
];

export default AdvancePromptingCard;

function convertSnakeCaseToTitleCase(str) {
  const words = str.split("_");

  const titleCaseStr = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  return titleCaseStr;
}

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
  },
  cell: {
    border: "none",
    padding: "8px",
    fontSize: "12px",
    height: "54px",
  },
  actionCell: {
    display: "flex",
    alignItems: "center",
    maxWidth: "200px",
  },
  hoverRow: {
    "&:hover": {
      backgroundColor: "#EEEEFF",
    },
    height: "48px",
  },
  headerRow: {
    backgroundColor: "#FCFCFF",
    "& .MuiTableCell-root": {
      padding: "16px",
    },
  },
  deleteButton: {
    backgroundColor: "#FFF9F9",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    color: "#F16060",
    height: "4.5rem",
  },
  cancelDeleteButton: {
    background: "#FFF9F9",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    padding: "6px 12px",
    fontSize: "12px",
    height: "28px",
    lineHeight: "1.43",

    color: "#F16060",
  },
  confirmDeleteButton: {
    padding: "6px 12px",
    fontSize: "12px",
    // width: '64px',
    height: "28px",
    background: "#F16060",
    border: "1px solid #FFEBEB",
    borderRadius: "6px",
    color: "white",
    marginLeft: "2rem",
    whiteSpace: "nowrap",
  },
  deleteIcon: {
    color: "#F16060",
    fontSize: "14px",
  },
  deletingRow: {
    background: "#FFEBEB",
  },
  dialogContainer: {
    borderRadius: "20px",
    zIndex: 300,
    overflow: "hidden",
    padding: "20px 60px 29px 60px",
    width: "fit-content",
  },
});

function calculateTimeRemaining(dateString) {
  const gmtTime = new Date(dateString);
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime: any = new Date(gmtTime.getTime() + istOffset);
  const currentDate: any = new Date();
  const timeDifference = istTime - currentDate;

  const minutesRemaining = Math.floor(timeDifference / (1000 * 60));

  return minutesRemaining;
}
