import React,{useEffect,useState} from 'react'
import Img from '../../components/ImageLoader/ImageLoader';
import NotFound from '../../assets/img/404-page.png'
import './index.scss';
import { Link, Redirect } from 'react-router-dom';


export default function PageNotFound() {
  const [redirect,setRedirect]=useState(false)  
  const [timer,setTimer]=useState(10)
  useEffect(()=>{
    setTimeout(()=>{
      setRedirect(true)
    },[10000])
    let timer=setInterval(()=>{
      if(!redirect){
        setTimer((timer)=>timer-1)
      }
    },[1000])
    return () => {
      clearInterval(timer)
    }
  },[])

  const url = `https://pyxis-test1.imgix.net/uploaded_files/5f3cddc6eb2a3dcaadb02e8e/errorrImage.jpg`;
  return (
    redirect?<Redirect to="/" />
      :<div className="page-not-found">
          <div className="detail">
            <h1 className="not-found-heading">Sorry, we can't find that page!</h1>
            <div className="not-found-subheading">The page you're looking for is now beyond our reach.</div>
            <div className="subtitle">Let's get you...</div>
            <Link to="/" className="back-home"> Back home in 00:00:{("0" + timer).slice(-2)}</Link>
            <Link className="home" to='/'>Home page</Link>
          </div>
          <div className="image-container">
            <Img src={NotFound} className="not-found-image"/>
          </div>
    </div>
      // {/* <img style={{ height: '100%', width: '100%' }} src={url} alt="Page not found." /> */}
  )
}
