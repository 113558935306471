export const GET_SIZES = 'GET_SIZES';
export const GET_SIZES_SUCCESS = 'GET_SIZES_SUCCESS';
export const GET_POPULAR_SIZES = 'GET_POPULAR_SIZES';
export const GET_POPULAR_SIZES_SUCCESS = 'GET_POPULAR_SIZES_SUCCESS';
export const LOADING = 'LOADING';
export const LOADING_OFF = 'LOADING_OFF';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const SAVE_MULTIPLE_TEMPLATES = 'SAVE_MULTIPLE_TEMPLATES';
export const SAVE_MULTIPLE_TEMPLATES_SUCCESS = 'SAVE_MULTIPLE_TEMPLATES_SUCCESS';
export const UPLOAD_MULTIPLE_FILES = 'UPLOAD_MULTIPLE_FILES';
export const UPLOAD_MULTIPLE_FILES_SUCCESS = 'UPLOAD_MULTIPLE_FILES_SUCCESS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_TEMPLATE_DATA = 'GET_TEMPLATE_DATA';
export const GET_TEMPLATE_DATA_SUCCESS = 'GET_TEMPLATE_DATA_SUCCESS';
export const GET_CATEGORIES_ELEMENTS = 'GET_CATEGORIES_ELEMENTS';
export const GET_CATEGORIES_ELEMENTS_SUCCESS = 'GET_CATEGORIES_ELEMENTS_SUCCESS';
export const GET_ELEMENTS_CATEGORY = 'GET_ELEMENTS_CATEGORY';
export const GET_ELEMENTS_CATEGORY_SUCCESS = 'GET_ELEMENTS_CATEGORY_SUCCESS';
export const GET_UPLOADED_FILES = 'GET_UPLOADED_FILES';
export const GET_UPLOADED_FILES_SUCCESS = 'GET_UPLOADED_FILES_SUCCESS';
export const DELETE_MULTIPLE_FILES = 'DELETE_MULTIPLE_FILES';
export const UPDATE_SIZE_CANVAS = 'UPDATE_SIZE_CANVAS';
export const GET_PSD_JSON = 'GET_PSD_JSON';
export const GET_PSD_JSON_SUCCESS = 'GET_PSD_JSON_SUCCESS';
export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA';
export const GET_FONTS_LIST = 'GET_FONTS_LIST';
export const GET_FONTS_LIST_SUCCESS_WEB = 'GET_FONTS_LIST_SUCCESS_WEB';
export const GET_FONTS_LIST_SUCCESS_UPLOAD = 'GET_FONTS_LIST_SUCCESS_UPLOAD';
export const SET_ERR_MSG_PROJECT = 'SET_ERR_MSG_PROJECT';
export const RENDER_IMAGES = 'RENDER_IMAGES';
export const SAVE_RENDER_IMAGES = 'SAVE_RENDER_IMAGES';
export const CLEAR_DATA_EDITOR = 'CLEAR_DATA_EDITOR';
export const UPDATE_STATUS_DATA = 'UPDATE_STATUS_DATA';
export const GET_URL_STOCK_IMAGE = 'GET_URL_STOCK_IMAGE';
export const CANCEL_PSD_UPLOAD = 'CANCEL_PSD_UPLOAD';
export const SAVE_AI_DESIGN_DATA = 'SAVE_AI_DESIGN_DATA';
