import {
  Drawer,
  Button,
  Checkbox,
  DatePicker,
  Row,
  Divider,
  Col,
  Input,
  Tree,
} from "pyxis-ui-kit";
import React, { FC, useState, useRef, useEffect } from "react";
// import deleteSvg from '../../../assets/svg/delete.svg';
import moment from "moment";
import "./index.scss";
import { openToast } from "../../../Toasts";
import { getUrlProperties } from "../../../utilities/common-function";
import { useDispatch, useSelector } from "react-redux";
import { deleteShareableLink, getShareableLink } from "../../../store/actions";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/tree-icon-downlload.svg";
import { ReactComponent as TreeFolderIcon } from "../../../assets/svg/tree-folder-icon.svg";
import { ReactComponent as TreeFileIcon } from "../../../assets/svg/tree-file-icon.svg";
import { ReactComponent as DownloadTickIcon } from "../../../assets/svg/download-tick.svg";
import { ReactComponent as ViewDetailsNoScreen } from "../../../assets/svg/viewDetails-no-data.svg";

import { Spin } from "pyxis-ui-kit";
import {
  CarryOutOutlined,
  FormOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { AuthState, ExportState } from "../../../store/types";
import {
  exportMultiple,
  setExportedProjects,
} from "../../../store/actions/export";

const Loader = <LoadingOutlined style={{ fontSize: 34 }} spin />;
const dateFormat = "YYYY-MM-DD";

const CopyIcon: FC<{ id: string; handleCopy: Function }> = ({
  id,
  handleCopy,
}) => {
  return (
    <button
      id='shareable-link1'
      type='button'
      className='copy-link-button'
      onClick={() => {
        handleCopy(id);
      }}
    >
      Copy Link
    </button>
  );
};

const ViewDetailsDrawer: FC<{
  projectId: number;
  projectType: string;
  shareable_type: string;
  onSubmit: Function;
  handleClose: Function;
}> = ({ projectType, projectId, shareable_type, onSubmit, handleClose }) => {
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [viewDetails, setViewDetails] = useState<any>({});
  const [nestedFolders, setNestedFolders] = useState<any>([]);
  const token = useSelector(
    (state: { authentication: AuthState }) => state.authentication.token
  );
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const { exportedProjects } = useSelector(
    (state: { export: ExportState }) => state.export
  );
  const formatAMPM = (date) => {
    var hours = date.split(":")[0];
    var minutes = date.split(":")[1];
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? (hours < 10 ? "0" + hours : hours) : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  const dispatch = useDispatch();
  const handleOriginalExport = (id) => {
    const handleExport = (exportType: string, qualityVal?: number) => {
      let payload: any = {
        project_type: projectType,
        output_format: exportType,
        quality: qualityVal ? qualityVal : undefined,
        projects: [id],
        folders: [],
      };
      let isDownloadEnable: boolean = true;

      // Multiple project or multiple hp download
      // For selected download in hp view wait until hp is loaded in backend
      // In project view or hp view wait until project or hp is loaded in backend

      if (isDownloadEnable) {
        setLoader(true);
        dispatch(
          exportMultiple(payload, (res: any, err: boolean) => {
            if (!err) {
              // after dispatch of setExportedProjects 'DownloadPopup' component will appear
              dispatch(setExportedProjects([...exportedProjects, res.data]));
              // setIsOpenMoreVisible(false)
              // setSelectedKeys([])
            } else {
              openToast("error", "Unable to export!");
            }
            setLoader(false);
          })
        );
      }
    };

    handleExport("png");
  };
  const changeChildrenPermission = (data) => {
    return data?.map((items) => ({
      key: items?.folder_id ? items?.folder_id : items?.id,
      icon: items?.folder_id ? (
        <>
          <span className='tree-vertical-divider'></span>
          <TreeFolderIcon />{" "}
        </>
      ) : null,
      switcherIcon: items?.folder_id ? null : <TreeFileIcon />,

      title: items?.id ? (
        <span className='tree-file-title'>
          {items?.title}
          <DownloadIcon
            style={{
              marginLeft: "1rem",
              height: "1.6rem",
              width: "1.6rem",
              position: "absolute",
              bottom: "0.5rem",
            }}
            onClick={() => handleOriginalExport(items?.id)}
          />
        </span>
      ) : (
        items?.title
      ),
      isLeaf: items?.id ? true : false,
      file_type: items?.file_type,
      ...(!items?.file_type
        ? {
            children: changeChildrenPermission(
              items?.projects?.length
                ? [...items?.projects, ...items?.children]
                : items?.children
            ),
          }
        : null),
    }));
  };
  const treeData = changeChildrenPermission([nestedFolders]);

  const handleCopy = (id: string) => {
    let node: any = document.getElementById(id);
    if (node) {
      node.select();
      document.execCommand("copy");
    }
  };
  const fetchDataFromApis = async (apiUrl, setViewDetails) => {
    const responses = await fetch(apiUrl, {
      method: "GET",
      headers: { token },
    }).then((response) => response.json());
    setViewDetails(responses);
    return;
  };

  useEffect(() => {
    fetchDataFromApis(
      `${process.env.REACT_APP_DESIGN_URI}/api/project/${projectId}/generate-public-link/?shareable_type=${shareable_type}`,
      setViewDetails
    );
    if (shareable_type !== "design") {
      fetchDataFromApis(
        `${process.env.REACT_APP_DESIGN_URI}/api/getNestedFolders?folder_id=${projectId}`,
        setNestedFolders
      );
    }
  }, []);
  if (!Object.keys(viewDetails?.data || {})?.length) return null;
  return (
    <Drawer
      visible={true}
      closable={false}
      className='view-link-drawer'
      destroyOnClose
      forceRender
      onClose={() => {
        handleClose();
      }}
      width={680}
      footer={
        !initialLoader ? (
          <>
            <Button
              type='link'
              className='secondary'
              onClick={() => handleClose()}
              icon={
                <span>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10 3L6 8L10 13'
                      stroke='#7335CB'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </span>
              }
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            type='link'
            className='secondary'
            onClick={() => handleClose()}
            icon={
              <span>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10 3L6 8L10 13'
                    stroke='#7335CB'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </span>
            }
          >
            Cancel
          </Button>
        )
      }
    >
      <>
        <span className='file-name'>
          {shareable_type !== "design"
            ? nestedFolders?.title
            : viewDetails?.data?.projectname}
        </span>
        <span className='file-info'>
          {`${nestedFolders?.total_projects || 0} File${
            nestedFolders?.total_projects > 1 ? "s" : ""
          }`}{" "}
        </span>
        <Divider style={{ margin: "2rem 0" }} />
        {viewDetails?.data?.shareable_link_key ? (
          <>
            <div className='link-box'>
              <label className='details-label'>Sharable Link</label>
              <div className='input-wrapper'>
                <Input
                  id='shareable-link1'
                  className='input-custom'
                  value={`${window.location.origin}#/cocreate/public/${
                    projectType === "hpproject"
                      ? "creative-automation"
                      : "project"
                  }/${viewDetails?.data?.shareable_link_key}`}
                />
                <CopyIcon id='shareable-link1' handleCopy={handleCopy} />
              </div>
            </div>
            <Divider style={{ margin: "2rem 0" }} />
            <Row>
              <Col span={12}>
                <div className='info-box'>
                  <span className='file-key'>Expiration date</span>
                  <span className='file-value'>
                    {viewDetails?.data?.expiration_date || "N/A"}
                  </span>
                </div>
                <div className='info-box'>
                  <span className='file-key'>Password</span>
                  <span className='file-value'>
                    {viewDetails?.data?.password || "N/A"}
                  </span>
                </div>
                <div className='info-box'>
                  <span className='file-key'>Share via email</span>
                  {viewDetails?.data?.transfer_details?.length ? (
                    viewDetails?.data?.transfer_details?.map((details) => (
                      <div>
                        <span className='file-value'>
                          {details?.email || "N/A"}
                        </span>
                        <span className='file-Dwonload-date'>
                          {details?.downloadDate ? (
                            <>
                              <DownloadTickIcon />
                              {`Downloaded ${
                                details?.downloadDate?.split(" ")[0]
                              },at ${formatAMPM(
                                details?.downloadDate?.split(" ")[1]
                              )}`}
                            </>
                          ) : (
                            <>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='12'
                                height='13'
                                viewBox='0 0 12 13'
                                fill='none'
                              >
                                <path
                                  d='M10.5 3.99992L4.5 9.99992L1.75 7.24992L2.455 6.54492L4.5 8.58492L9.795 3.29492L10.5 3.99992Z'
                                  fill='#C6C6C6'
                                />
                              </svg>
                              Not Downloaded
                            </>
                          )}
                        </span>
                      </div>
                    ))
                  ) : (
                    <span className='file-value'>N/A</span>
                  )}
                </div>
                <div className='info-box'>
                  <span className='file-key'> Total downloads</span>
                  <span className='file-value'>
                    {viewDetails?.data?.downloadCount || 0}
                  </span>
                </div>
              </Col>
              {shareable_type !== "design" ? (
                <Col span={12}>
                  <Tree
                    className='tree-node'
                    showLine={true}
                    showIcon={true}
                    defaultExpandedKeys={["0-0-0"]}
                    // onSelect={onSelect}
                    treeData={treeData}
                  />
                </Col>
              ) : null}
            </Row>
          </>
        ) : (
          <div
            style={{
              flexGrow: 1,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ViewDetailsNoScreen />
          </div>
        )}
      </>
    </Drawer>
  );
};

export default ViewDetailsDrawer;
