import { DELETE_DROP_SRC, SET_DROP_SRC, DeleteDropSrcAction, SetDropSrcAction, DropSrcState } from '../types';

const initialState: DropSrcState = {
  dropImageSrc: false,
};

export const drangAndDropImageReducer = (state = initialState, action: DeleteDropSrcAction | SetDropSrcAction) => {
  switch (action.type) {
    case DELETE_DROP_SRC:
    case SET_DROP_SRC:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
