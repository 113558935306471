export const FETCH_WORKFLOWS = "FETCH_WORKFLOWS";
export const SET_WORKFLOWS = "SET_WORKFLOWS";
export const SET_WORKFLOW_PAGE_DATA = "SET_WORKFLOW_PAGE_DATA";
export const CHANGE_WORKFLOW = "CHANGE_WORKFLOW";
export const REMOVE_WORKFLOW = "REMOVE_WORKFLOW";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const ADD_WORKFLOW = "ADD_WORKFLOW";
export const FETCH_WORKFLOW_DATA = "FETCH_WORKFLOW_DATA";
export const SET_WORKFLOW_DATA = "SET_WORKFLOW_DATA";
export const UPDATE_WORKFLOW_DATA = "UPDATE_WORKFLOW_DATA";
export const CHANGE_WORKFLOW_DATA = "CHANGE_WORKFLOW_DATA";
export const REMOVE_ROUND = "REMOVE_ROUND";
export const DELETE_ROUND = "DELETE_ROUND";
export const UPDATE_ROUND = "UPDATE_ROUND";
export const CHANGE_ROUND = "CHANGE_ROUND";
export const ADD_ROUND = "ADD_ROUND";
export const CREATE_ROUND = "CREATE_ROUND";
export const SET_WORKFLOW_LOADER = "SET_WORKFLOW_LOADER";
export const SET_APPROVAL_POPUP_PROPS = "SET_APPROVAL_POPUP_PROPS";
export const DUPLICATE_WORKFLOW_ROUND = "DUPLICATE_WORKFLOW_ROUND";
export const DUPLICATE_WORKFLOW = "DUPLICATE_WORKFLOW";
export const ADD_USER_TO_ROUND = "ADD_USER_TO_ROUND";
export const REMOVE_USER_FROM_ROUND = "REMOVE_USER_FROM_ROUND";
export const SET_WORKFLOW_TOPBAR_TAB = "SET_WORKFLOW_TOPBAR_TAB";

export type WorkflowState = {
    workflows: WorkFLow[],
    workflowData: WorkFlowData|null,
    pageData: PageData|null
    loading: boolean,
    approvalPopUpProps: SetApprovalPopUpProps,
    workflowTopBarTab: number
}

export type WorkFLow = {
    id: number,
    name: string,
    description: string,
    created_by: string,
    round_count: number,
    rounds: Round[],
}

export type WorkFlowData = {
    id: number,
    name: string,
    description: string,
    created_by: string,
    rounds: Round[]
}

export type Round = {
    id: number,
    name: string,
    settings: {
        everyonemustapprove: boolean
    },
    order: number,
    approvers: any[]
}

export type FetchWorkFlows = {
    type: typeof FETCH_WORKFLOWS,
    payload: {
        page: number,
        page_size: number,
        product_id: number
    },
    callback?:Function
}

export type SetWorkFlows = {
    type: typeof SET_WORKFLOWS,
    payload: WorkFLow[]
}

export type ChangeWorkFlow = {
    type: typeof CHANGE_WORKFLOW,
    payload: {
        updatedWorkFlow: WorkFLow,
        workFlowIndex?: number
    }
}

export type RemoveWorkFlow = {
    type: typeof REMOVE_WORKFLOW,
    payload: number
}

export type SetWorkFlowLoader = {
    type: typeof SET_WORKFLOW_LOADER,
    payload: boolean
}

export type DeleteWorkFlow = {
    type: typeof DELETE_WORKFLOW,
    payload: number,
    callback?:Function
}

export type CreateWorkFlow = {
    type: typeof CREATE_WORKFLOW,
    payload: {
        name: string,
        description: string,
        product_id: number
    }
}

export type AddWorkFlow = {
    type: typeof ADD_WORKFLOW,
    payload: WorkFLow[]
}

export type FetchWorkFlowData = {
    type: typeof FETCH_WORKFLOW_DATA,
    payload: number,
    callback?:Function
}

export type SetWorkFlowData = {
    type: typeof SET_WORKFLOW_DATA,
    payload: WorkFlowData|null
}

export type AddRound = {
    type: typeof ADD_ROUND,
    payload: Round
}

export type RoundCreateQuery = {
    name: string,
    settings: {
        everyonemustapprove: boolean
    }
}
export type CreateRound = {
    type: typeof CREATE_ROUND,
    payload: {
        workFlowId: number,
        round: RoundCreateQuery
    },
    callback?:Function
}

export type UpdateRound = {
    type: typeof UPDATE_ROUND,
    payload: {
        workFlowId: number,
        round: Round
    },
    callback?:Function
}

export type ChangeRound = {
    type: typeof CHANGE_ROUND,
    payload: {
        roundIndex?: number,
        updatedRound: Round
    }
}

export type DeleteRound = {
    type: typeof DELETE_ROUND,
    payload: {
        workFlowId: number,
        roundId: number
    },
    callback?: Function
}

export type RemoveRound = {
    type: typeof REMOVE_ROUND,
    payload: number
}

export type UpdateWorkFlowData = {
    type: typeof UPDATE_WORKFLOW_DATA,
    payload: {
        workFlowId: number,
        name: string
    },
    callback?:Function
}

export type ChangeWorkFlowData = {
    type: typeof CHANGE_WORKFLOW_DATA,
    payload: WorkFlowData
}

export type SetApprovalPopUpPropsAction = {
    type: typeof SET_APPROVAL_POPUP_PROPS,
    payload: SetApprovalPopUpProps
}

export type SetApprovalPopUpProps = {
    isOpen: boolean,
    projectId: number,
    callback?: Function
}

export type SetWorkflowPageData = {
    type: typeof SET_WORKFLOW_PAGE_DATA,
    payload: PageData
}

export type PageData = {
    count: number,
    current_page: number,
    page_size: number,
    pages: number
}

export type DuplicateWorkFlowRound = {
    type: typeof DUPLICATE_WORKFLOW_ROUND,
    payload: {
        workflow_id: number,
        round_id: number
    },
    callback?: Function
}

export type DuplicateWorkflow = {
    type: typeof DUPLICATE_WORKFLOW,
    payload: number,
    callback?: Function
}

export type AddUserToRound = {
    type: typeof ADD_USER_TO_ROUND,
    payload: {
        workflowId:number,
        roundId:number,
        userId: string
    },
    callback?:Function
}

export type RemoveUserFromRound = {
    type: typeof REMOVE_USER_FROM_ROUND,
    payload: {
        workflowId:number,
        roundId:number,
        userId: string
    },
    callback?:Function
}

export type SetWorkFlowTopBarTab = {
    type: typeof SET_WORKFLOW_TOPBAR_TAB,
    payload: number
}