import { SetFileToProjectQueue, SET_FILE_TO_PROJECT_QUEUE, SetUploadedFolder, SET_UPLOADED_FOLDER, SetCurrentFolderFile, SET_CURRENT_FOLDER_FILE, SetUploadFileList, SET_UPLOAD_FILE_LIST, SET_FOLDER_CONTENT_COUNT, FileUploader, FILE_UPLOADER, UploadToS3, UPLOAD_TO_S3, ProjectUpload, PROJECT_UPLOAD, BannerSize, SET_TEMP_FOLDER_CONTENT_COUNT, UpdateProjectDescription, CANCEL_FILE_TO_PROJECT, CancelFileToProject, RESET_FILE_TO_PROJECT, SEARCH_META, SearchMeta, FetchDesignMeta, FETCH_DESIGN_META, ADD_META, AddMeta, DeleteMeta, DELETE_META } from './../types/projectFolders';
import {
  FetchFolders,
  FETCH_FOLDERS,
  Folder,
  SetFolders,
  SET_FOLDERS,
  SetActiveFolder,
  SET_ACTIVE_FOLDER,
  AddNewFolderAction,
  ADD_NEW_FOLDER,
  ADD_FOLDER,
  AddFolder,
  AddSelectedFolder,
  ADD_SECLECTED_FOLDER,
  SetSelectedFolder,
  SET_SELECTED_FOLDER,
  MOVE_FOLDER,
  DeleteFolderAction,
  DELETE_FOLDER,
  UpdateFolderAction,
  UPDATE_FOLDER,
  FetchProjectsAction,
  FETCH_PROJECTS,
  Banner,
  SetProjects,
  SET_PROJECTS,
  SetBannerView,
  SET_BANNER_VIEW,
  BannerData,
  SetBannerData,
  SET_BANNER_DATA,
  FETCH_PROJECT,
  FetchProjectAction,
  SetSelectedProject,
  SET_SELECTED_PROJECTS,
  DeleteProjects,
  DELETE_PROJECTS,
  MOVE_PROJECTS,
  FETCH_FILES,
  FetchFilesAction,
  MoveFiles,
  MOVE_FILES,
  DeleteFiles,
  DELETE_FILES,
  AddProjectToTemplate,
  ADD_PROJECT_TO_TEMPLATE,
  Project,
  UploadLibraryFile,
  UPLOAD_LIBRARY_FILE,
  SetFoldersPageData,
  SET_FOLDERS_PAGE_DATA,
  SetFolderLoader,
  SET_FOLDER_LOADER,
  FetchHyperProjects,
  FETCH_HYPER_PROJECTS,
  FetchAllFiles,
  FETCH_ALL_FILES,
  SET_ACTIVE_FOLDER_CREATE,
  DuplicateProject,
  DUPLICATE_PROJECT,
  SET_PROJECT_LOADER,
  SetProjectLoader,
  FetchProjectFromShareableLink,
  FETCH_PROJECT_FROM_SHAREABLE_LINK,
  DELETE_SHAREABLE_LINK,
  DeleteShareableLink,
  GenerateShareableLink,
  GENERATE_SHAREABLE_LINK,
  FetchTags,
  FETCH_TAGS,
  SetFilter,
  SET_FILTER,
  AddOrDeleteTag,
  ADD_OR_DELETE_TAG,
  SearchTags,
  SEARCH_TAGS,
  GetShareableLink,
  GET_SHAREABLE_LINK,
  GET_TOKEN_FROM_SHAREABLE_LINK,
  GetTokenFromShareableLink,
  FILE_TO_PROJECT,
  FileToProject,
  UPDATE_FILE_TO_PROJECT,
  UpdateFileToProject,
  UPDATE_VARIANT,
  UpdateVariant,
  CREATE_VARIANT,
  CreateVariant,
  DELETE_VARIANT,
  DeleteVariant,
  SET_FOLDER_BREADCRUMB,
  SetFolderBreadcrumb,
  SET_UPLOADED_BANNERS,
  SetUploadedBanners,
  MoveProjects,
  FetchLibraryFile,
  FETCH_LIBRARY_FILE,
  CREATE_FOLDER_STRUCTURE,
  CreateFolderSutructure,
  GetQuickViewBucket,
  GET_QUICK_VIEW_BUCKET,
  UPDATE_PROJECT_DESCRIPTION,
} from "../types";

export const fetchFolders = (
  payload: {
    type: string;
    parent?: number[];
    product_id?: string;
    created_at_start?: string;
    created_at_end?: string;
    search?: string;
    name_contains?: string;
    created_by?: string;
    order_by?: string;
  },
  callback?: Function
): FetchFolders => ({
  type: FETCH_FOLDERS,
  payload: { ...payload, product_id: "1" },
  callback,
});

export const setFolders = (folders: Folder[]): SetFolders => ({
  type: SET_FOLDERS,
  payload: folders,
});

export const setActiveFolder = (payload: number): SetActiveFolder => ({
  type: SET_ACTIVE_FOLDER,
  payload: payload,
});

export const addNewFolder = (
  payload: {
    name: string;
    type: string;
    parent?: number;
    product_id?: number;
  },
  callback?: Function
): AddNewFolderAction => ({
  type: ADD_NEW_FOLDER,
  payload: {
    ...payload,
    product_id: payload.product_id || 1,
  },
  callback,

});

export const addFolder = (payload: Folder[]): AddFolder => ({
  type: ADD_FOLDER,
  payload: payload,
});

export const addSelectedFolders = (payload: number[]): AddSelectedFolder => ({
  type: ADD_SECLECTED_FOLDER,
  payload: payload,
});

export const setSelectedFolders = (payload: number[]): SetSelectedFolder => ({
  type: SET_SELECTED_FOLDER,
  payload: payload,
});

export const moveFolders = (
  payload: {
    selectedFolders: number[],
    destinationFolderId: number,
    product_id?: number;
  },
  callback?: Function
) => ({
  type: MOVE_FOLDER,
  payload: {
    ...payload,
    product_id: payload.product_id || 1,
  },
  callback
});

export const deleteFolders = (
  payload: number,
  callback?: Function
): DeleteFolderAction => ({
  type: DELETE_FOLDER,
  payload: payload,
  callback
});

export const updateFolder = (payload: Folder, callback?: Function): UpdateFolderAction => ({
  type: UPDATE_FOLDER,
  payload: payload,
  callback
});

export const fetchProjects = (
  payload: {
    page: number;
    folder_id?: number;
    project_id?: number[] | number;
    page_size?: number;
    product_id?: number;
    created_at_start?: string;
    created_at_end?: string;
    approved_at_start?: string;
    approved_at_end?: string;
    statuses?: string[];
    search?: string;
    name_contains?: string;
    approved_by?: string[];
    created_by?: string[];
    tags?: string[];
    order_by?: string;
  },
  callback?: Function
): FetchProjectsAction => ({
  type: FETCH_PROJECTS,
  payload: {
    ...payload,
    page_size: payload.page_size || 20,
    product_id: payload.product_id || 1,
  },
  callback,
});

export const setProjects = (banners: Banner[]): SetProjects => ({
  type: SET_PROJECTS,
  payload: banners,
});

export const setBannerView = (payload: boolean): SetBannerView => ({
  type: SET_BANNER_VIEW,
  payload: payload,
});

export const setProject = (payload: Project | null): SetBannerData => ({
  type: SET_BANNER_DATA,
  payload: payload,
});

export const fetchProject = (
  payload: { id: number; editor_mode: boolean },
  callback?: Function
): FetchProjectAction => ({
  type: FETCH_PROJECT,
  payload,
  callback,
});

export const setSelectedProject = (payload: number[]): SetSelectedProject => ({
  type: SET_SELECTED_PROJECTS,
  payload: payload,
});

export const deleteProjects = (payload: number[], callback?: Function): DeleteProjects => ({
  type: DELETE_PROJECTS,
  payload: payload,
  callback
});

// export const moveProjects = (
//   selectedProjects: number[],
//   destinationFolderId: number,
//   product_id: number = 1
// ) => ({
//   type: MOVE_PROJECTS,
//   payload: {
//     selectedProjects,
//     destinationFolderId,
//     product_id,
//   },
// });

export const moveProjects = (
  payload: {
    selectedProjects: number[],
    destinationFolderId: number,
    product_id?: number
  },
  callback?: Function
): MoveProjects => ({
  type: MOVE_PROJECTS,
  payload: {
    ...payload,
    product_id: payload.product_id || 1,
  },
  callback
});



export const fetchFiles = (
  payload: {
    page: number,
    folder?: number,
    page_size?: number,
    type?: string,
  },
  callback?: Function
): FetchFilesAction => ({
  type: FETCH_FILES,
  payload: { ...payload, page_size: payload.page_size || 20, type: payload.type || "direct", product_id: 1 },
  callback
});

export const moveFiles = (
  payload: {
    selectedFiles: number[],
    destinationFolderId: number,
    product_id?: number
  },
  callback?: Function
): MoveFiles => ({
  type: MOVE_FILES,
  payload: {
    ...payload,
    product_id: payload.product_id || 1,
  },
  callback
});

export const deleteFiles = (
  payload: number[],
  callback?: Function
): DeleteFiles => ({
  type: DELETE_FILES,
  payload,
  callback,
});

export const addProjectToTemplate = (
  payload: {
    id: number,
    title: string
  }, callback?: Function): AddProjectToTemplate => ({
    type: ADD_PROJECT_TO_TEMPLATE,
    payload,
    callback
  });

export const uploadLibraryFile = (
  payload: {
    file: any;
    type: string;
    folder?: number;
    product_id?: string;
  },
  callback?: Function
): UploadLibraryFile => ({
  type: UPLOAD_LIBRARY_FILE,
  payload: { ...payload, product_id: "1" },
  callback,
});

export const fetchLibraryFile = (
  payload: {
    project_id: number[]
  },
  callback?: Function
): FetchLibraryFile => ({
  type: FETCH_LIBRARY_FILE,
  payload: { ...payload },
  callback,
});

export const setFoldersPageData = (payload: any): SetFoldersPageData => ({
  type: SET_FOLDERS_PAGE_DATA,
  payload,
});

export const setFolderLoader = (payload: boolean): SetFolderLoader => ({
  type: SET_FOLDER_LOADER,
  payload,
});



export const fetchHyperProjects = (
  payload: {
    hpproject_ids?: number[];
    folder?: number;
    page: number;
    page_size?: number;
    product_id?: number;
    created_at_start?: string,
    created_at_end?: string
    approved_at_start?: string,
    approved_at_end?: string,
    statuses?: string[],
    search?: string,
    created_by?: string[],
    approved_by?: string[],
    tags?: string[],
    order_by?: string
  },
  callback?: Function
): FetchHyperProjects => ({
  type: FETCH_HYPER_PROJECTS,
  payload: {
    ...payload,
    page_size: payload.page_size || 10,
    product_id: payload.product_id || 1,
  },
  callback,
});

export const fetchAllFiles = (
  payload: {
    type: string;
    resource_type?: string;
    folder?: number;
    page: number;
    page_size?: number;
  },
  callback?: Function
): FetchAllFiles => ({
  type: FETCH_ALL_FILES,
  payload: { ...payload, page_size: payload.page_size || 20 },
  callback,
});

export const setActiveFolderCreate = (payload: { folder: number }) => ({
  type: SET_ACTIVE_FOLDER_CREATE,
  payload,
});

export const duplicateProject = (
  payload: {
    id: number;
  },
  callback?: Function
) => ({
  type: DUPLICATE_PROJECT,
  payload,
  callback,
});

export const setProjectLoader = (payload: boolean): SetProjectLoader => ({
  type: SET_PROJECT_LOADER,
  payload,
});

export const fetchTags = (payload: {
  type?: string;
  page: number;
  page_size?: number;
  folder?: number;
}, callback?: Function): FetchTags => ({
  type: FETCH_TAGS,
  payload,
  callback
})

export const setFilter = (payload: any): SetFilter => ({
  type: SET_FILTER,
  payload
})

export const generateShareableLink = (payload: {
  project_id: number;
  password_flag: boolean;
  vleId_flag: boolean;
  expiration_date: string;
  projectType: string;
  shareable_type: string;
}, callback?: Function): GenerateShareableLink => ({
  type: GENERATE_SHAREABLE_LINK,
  payload,
  callback
})

export const addOrDeleteTag = (payload: {
  entity_id: number;
  entity_type: 'project' | 'hpproject';
  name: string;
  type: 'add' | 'delete';
}, callback?: Function): AddOrDeleteTag => ({
  type: ADD_OR_DELETE_TAG,
  payload,
  callback
})

export const fetchProjectFromShareableLink = (payload: {
  password?: string;
  shareable_link: string;
  projectType: string;
}, callback?: Function): FetchProjectFromShareableLink => ({
  type: FETCH_PROJECT_FROM_SHAREABLE_LINK,
  payload,
  callback
})

export const deleteShareableLink = (payload: {
  projectId: number;
  projectType: string;
  shareable_type: string;
}, callback?: Function): DeleteShareableLink => ({
  type: DELETE_SHAREABLE_LINK,
  payload,
  callback
})

export const getShareableLink = (payload: {
  project_id: number;
  project_type: string;
  shareable_type: string;
}, callback?: Function): GetShareableLink => ({
  type: GET_SHAREABLE_LINK,
  payload,
  callback
})

export const searchTags = (payload: {
  search: string;
  entityType?: string;
}, callback?: Function): SearchTags => ({
  type: SEARCH_TAGS,
  payload,
  callback
})

export const getTokenFromShareableLink = (payload: {
  hash_code: string;
  project_type: string;
  password?: string;
  vle_code?: string;
}, callback?: Function): GetTokenFromShareableLink => ({
  type: GET_TOKEN_FROM_SHAREABLE_LINK,
  payload,
  callback
})

export const fileToProject = (payload: {
  file: any;
  type: string;
  folder?: number;
  cancelToken: any;
}, callback?: Function
  , config?: any): FileToProject => ({
    type: FILE_TO_PROJECT,
    payload,
    callback,
    config
  })

export const updateFileToProject = (payload: {
  project_id: number;
  file: any;
}, callback?: Function): UpdateFileToProject => ({
  type: UPDATE_FILE_TO_PROJECT,
  payload,
  callback
})
export const projectUpload = (payload: {
  title: string,
  type: string,
  file_type: string,
  template?: any,
  folder?: number,
  rendered_file: string,
  sizes?: BannerSize[],
  psdUrl?: string,
  projectJson?: any
  cancelToken: any,
}, callback?: Function): ProjectUpload => ({
  type: PROJECT_UPLOAD,
  payload,
  callback
})

export const updateVariant = (payload: {
  project_id: number;
  file: any;
  project_size: number;
}, callback?: Function): UpdateVariant => ({
  type: UPDATE_VARIANT,
  payload,
  callback
})

export const createVariant = (payload: {
  project_id: number;
  file: any;
}, callback?: Function): CreateVariant => ({
  type: CREATE_VARIANT,
  payload,
  callback
})

export const deleteVariant = (payload: {
  project_id: number;
  project_size: number[];
}, callback?: Function): DeleteVariant => ({
  type: DELETE_VARIANT,
  payload,
  callback
})

export const cancelFileToProject = (): CancelFileToProject => ({
  type: CANCEL_FILE_TO_PROJECT
})

export const resetFileToProject = (): any => ({
  type: RESET_FILE_TO_PROJECT
})

export const setUploadedBanners = (payload: Banner[]): SetUploadedBanners => ({
  type: SET_UPLOADED_BANNERS,
  payload
})
export const fileUploader = (
  payload: {
    file_name: any;
    file_size: any;
    file: any;
    config?: any
    cancelToken: any;
  }, callback?: Function): FileUploader => ({
    type: FILE_UPLOADER,
    payload,
    callback,
  })

export const uploadToS3 = (
  payload: {
    file_url: any;
    file: any;
    cancelToken: any;
  }, callback?: Function,
  config?: any
): UploadToS3 => ({
  type: UPLOAD_TO_S3,
  payload,
  callback,
  config
})

export const setFileToProjectQueue = (payload: any): SetFileToProjectQueue => ({
  type: SET_FILE_TO_PROJECT_QUEUE,
  payload
})

export const setFolderBreadcrumb = (payload: Folder[]): SetFolderBreadcrumb => ({
  type: SET_FOLDER_BREADCRUMB,
  payload,
});

export const createFolderStructure = (payload: {
  structure: any;
  projectType: string;
  parent_id?: number;
}, callback?: Function): CreateFolderSutructure => ({
  type: CREATE_FOLDER_STRUCTURE,
  payload,
  callback
})

export const getQuickViewBucket = (payload: { role: string; },
  callback?: Function): GetQuickViewBucket => ({
    type: GET_QUICK_VIEW_BUCKET,
    payload: payload,
    callback
  })
export const setUploadedFolder = (payload: Folder[]): SetUploadedFolder => ({
  type: SET_UPLOADED_FOLDER,
  payload,
});

export const setUploadFileList = (payload: any[]) => ({
  type: SET_UPLOAD_FILE_LIST,
  payload,
})

export const setCurrentFolderFile = (payload: number): SetCurrentFolderFile => ({
  type: SET_CURRENT_FOLDER_FILE,
  payload: payload,
});

export const setFolderContentCount = (payload: {}) => ({
  type: SET_FOLDER_CONTENT_COUNT,
  payload,
})

export const setTempFolderContentCount = (payload: {}) => ({
  type: SET_TEMP_FOLDER_CONTENT_COUNT,
  payload,
})
export const updateProjectDescription = (payload: {
  project_id: number;
  body: any;
}, callback?: Function): UpdateProjectDescription => ({
  type: UPDATE_PROJECT_DESCRIPTION,
  payload,
  callback
})

export const searchMeta = (payload: {
  search: string;
  entityType: string;
}, callback?: Function): SearchMeta => ({
  type: SEARCH_META,
  payload,
  callback
})

export const addMeta = (payload: {
  type: string;
  project_size_id: any,
  data: string;
}, callback?: Function): AddMeta => ({
  type: ADD_META,
  payload,
  callback
})

export const deleteMeta = (payload: {
  type: string;
  project_size_id: number;
  id: number;
}, callback?: Function): DeleteMeta => ({
  type: DELETE_META,
  payload,
  callback
})

export const fetchDesignMeta = (
  payload: { project_size_id: number },
  callback?: Function
): FetchDesignMeta => ({
  type: FETCH_DESIGN_META,
  payload,
  callback,
});