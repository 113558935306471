import { CLASSIFICATION_TYPES } from "../classification/constants";

export const isBackgroundPart = (child: any) =>
  [
    CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM,
    CLASSIFICATION_TYPES.BACKGROUND_PART_TOP,
    CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT,
    CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT
  ].indexOf(child?.classificationType) !== -1;

export const canvasHasBackgroundPart = (objList: Array<any>) => {
  for (const obj of objList) {
    if (isBackgroundPart(obj?.type)) {
      return true;
    }
  }
};
