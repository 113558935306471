export const getObjectSize = (obj: any) => {

  const width = obj.width * obj.scaleX
  const height = obj.height * obj.scaleY
  const topRightCoords = {
    x: width, y: height
  }
  const bottomRightCoords = {
    x: width, y: -height
  }
    
  const angle = obj.angle / 180 * Math.PI
  const angleSin = Math.sin(angle)
  const angleCos = Math.cos(angle)

  const topRightCoordsNew: any = {}

  topRightCoordsNew.x = topRightCoords.x * angleCos + topRightCoords.y * angleSin
  topRightCoordsNew.y = - topRightCoords.x * angleSin + topRightCoords.y * angleCos
  
  const bottomRightCoordsNew: any = {}
  bottomRightCoordsNew.x = bottomRightCoords.x * angleCos + bottomRightCoords.y * angleSin
  bottomRightCoordsNew.y = - bottomRightCoords.x * angleSin + bottomRightCoords.y * angleCos 
  
  const newWidth = Math.max(Math.abs(topRightCoordsNew.x), Math.abs(bottomRightCoordsNew.x))
  const newHeight = Math.max(Math.abs(topRightCoordsNew.y), Math.abs(bottomRightCoordsNew.y))

  return [newWidth, newHeight]
};

export const getObjectWidth = (obj: any) => {
  const width = obj.width * obj.scaleX
  const height = obj.height * obj.scaleY
  const topRightCoords = {
    x: width, y: height
  }
  const bottomRightCoords = {
    x: width, y: -height
  }
    
  const angle = obj.angle / 180 * Math.PI
  const angleSin = Math.sin(angle)
  const angleCos = Math.cos(angle)

  const topRightCoordsNew: any = {}

  topRightCoordsNew.x = topRightCoords.x * angleCos + topRightCoords.y * angleSin
  topRightCoordsNew.y = - topRightCoords.x * angleSin + topRightCoords.y * angleCos
  
  const bottomRightCoordsNew: any = {}
  bottomRightCoordsNew.x = bottomRightCoords.x * angleCos + bottomRightCoords.y * angleSin
  bottomRightCoordsNew.y = - bottomRightCoords.x * angleSin + bottomRightCoords.y * angleCos 
  
  const newWidth = Math.max(Math.abs(topRightCoordsNew.x), Math.abs(bottomRightCoordsNew.x))
  const newHeight = Math.max(Math.abs(topRightCoordsNew.y), Math.abs(bottomRightCoordsNew.y))


  return newWidth
};

export const getObjectHeight = (obj: any) => {
  const width = obj.width * obj.scaleX
  const height = obj.height * obj.scaleY
  const topRightCoords = {
    x: width, y: height
  }
  const bottomRightCoords = {
    x: width, y: -height
  }
    
  const angle = obj.angle / 180 * Math.PI
  const angleSin = Math.sin(angle)
  const angleCos = Math.cos(angle)

  const topRightCoordsNew: any = {}

  topRightCoordsNew.x = topRightCoords.x * angleCos + topRightCoords.y * angleSin
  topRightCoordsNew.y = - topRightCoords.x * angleSin + topRightCoords.y * angleCos
  
  const bottomRightCoordsNew: any = {}
  bottomRightCoordsNew.x = bottomRightCoords.x * angleCos + bottomRightCoords.y * angleSin
  bottomRightCoordsNew.y = - bottomRightCoords.x * angleSin + bottomRightCoords.y * angleCos 
  
  const newWidth = Math.max(Math.abs(topRightCoordsNew.x), Math.abs(bottomRightCoordsNew.x))
  const newHeight = Math.max(Math.abs(topRightCoordsNew.y), Math.abs(bottomRightCoordsNew.y))

  return newHeight
};
