import React, { EventHandler } from 'react';
import { withRouter } from 'react-router';
import iconUpload from '../../../assets/svg/iconUpload.svg';
import iconTypography from '../../../assets/svg/iconTypography.svg';
import iconTemplate from '../../../assets/svg/iconTemplate.svg';
import iconColor from '../../../assets/svg/iconColor.svg';
import iconArrow from '../../../assets/svg/iconArrow.svg';
import './index.scss';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../store/types';
import { permissionDeniedClick } from '../../../utilities/common-function';

interface ItemInterface {
    name: string,
    isActive: boolean,
    icon: any,
    disabled: boolean,
    handleClick: Function
}
interface ContainerInterface {
    className: string
}

const tabs = [
    {name: "My Uploads", 
    path: "images",
    icon: iconUpload,
    permission: ['view-all-assets','create-new-banners']},

    {name: "Typography",
    path: "typography",
    icon:iconTypography,
    permission: ['view-typography']},

    {name: "Brand Colors",
    path: "color-palette",
    icon: iconColor,
    permission: ['view-brand-colors']},
    
    {name: "Templates",
    path: "templates",
    icon: iconTemplate,
    permission: ['view-all-assets','create-new-banners']}
];

const Container: React.FunctionComponent<ContainerInterface> = ({children,className}) =>{
    return <div className={className}>
        {children}
    </div>;
}

const Item: React.FunctionComponent<ItemInterface> = ({name,icon,disabled,isActive,handleClick}) =>{
    return <React.Fragment>
        <div className={!disabled? (isActive?"item active":"item"):"item permission-denied-lock"}
            onClick={(e)=>{if(disabled){
                permissionDeniedClick()
            }else{
                handleClick(e)
            }}}
        >
            <div className="title-container">
                <img src={icon} className="icon"/>
                <span className="title">{name}</span>
            </div>
            {!disabled && <img src={iconArrow} className="arrow"/>}
        </div>
    </React.Fragment>;
}



const LeftNavBar: React.FunctionComponent<any> = ({history, match}) => {
    const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
    const { params: {librarytype} } = match;

    return (<React.Fragment>
        <Container className="library-leftsidebar">
            <Container className="list show">
                <div className="list-title">brand library</div>
                {tabs.map(({name,path,icon,permission},index)=>(
                    <Item name={name} key={name} isActive={path === librarytype}
                        icon={icon}
                        disabled={permission ? permission.every((p:string)=>userPermission.indexOf(p) === -1) :false}
                        handleClick={(e:any)=>{
                            if(!permission || (permission && !permission.every((p:string)=>userPermission.indexOf(p) === -1))){
                                history.push(`/cocreate/library/${path}`)
                            }else{
                                e.preventDefault()
                            }
                        }}
                    />
                ))}
            </Container>
        </Container>
    </React.Fragment>
    )
}

export default withRouter(LeftNavBar);