export const SCALABLE_OBJECT_TYPES = [
  'Shape'
]

export enum CANVAS_GRID_POSITION_X {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum CANVAS_GRID_POSITION_Y {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum RESIZE_TYPE {
  SIMPLE = 'simple',
  TO_EXTREME = 'toExtreme',
  FROM_EXTREME = 'fromExtreme'
}
