import { HyperProjectFoldersState,SetHyperProjectData,SET_HYPERPROJECT_DATA,
    SET_SELECTED_HYPER_SIZES,SetSelectedHyperSizes,SET_LAYERS_WITH_ASSETS,SetSelectedTextId,
    SetLayersWithAssets,SetSelectedHpProjects,SET_SELECTED_HPPROJECTS,SetSelectedImageId,
    SetSelectedImageLayerId,SetSelectedTextLayerId,SET_SELECTED_IMAGE_ID,SET_SELECTED_TEXT_ID,SET_SELECTED_IMAGE_LAYER_ID,
    SET_SELECTED_SIZE_ID,SetProjectsForReadyCheck, SET_PROJECTS_FOR_READY_CHECK,SET_SELECTED_TEXT_LAYER_ID,SetSelectedAssetId,
    SetSelectedLayerId,SetSelectedSizeId,SET_SELECTED_ASSET_ID,SET_SELECTED_LAYER_ID, } from "../types/hyperProjectFolders"

const initialState:HyperProjectFoldersState = {
    hyperProjectData: null,
    selectedHyperSizes: [],
    layersWithAssets: null,
    selectedHyperProjects: [],
    selectedImageId: null,
    selectedTextId:null,
    selectedImageLayerId: null,
    selectedTextLayerId: null,
    selectedSizeId: null,
    selectedAssetId: null,
    selectedLayerId: null,
    projectsForReadyCheck: []
}

export const hyperProjectFolderReducer = (state = initialState,
    action: SetHyperProjectData
    |SetSelectedHyperSizes
    |SetLayersWithAssets
    |SetSelectedHpProjects
    |SetSelectedImageId
    |SetSelectedTextId
    |SetSelectedImageLayerId
    |SetSelectedTextLayerId
    |SetSelectedAssetId
    |SetSelectedLayerId
    |SetSelectedSizeId
    |SetProjectsForReadyCheck
):HyperProjectFoldersState => {
    switch(action.type) {
        case SET_HYPERPROJECT_DATA:{
            return {
                ...state,
                hyperProjectData: action.payload
            }
        }
        case SET_SELECTED_HYPER_SIZES: {
            return {
                ...state,
                selectedHyperSizes: action.payload
            }
        }
        case SET_LAYERS_WITH_ASSETS: {
            return {
                ...state,
                layersWithAssets: action.payload
            }
        }
        case SET_SELECTED_HPPROJECTS: {
            return {
                ...state,
                selectedHyperProjects: action.payload
            }
        }
        case SET_SELECTED_SIZE_ID: {
            return {
                ...state,
                selectedSizeId: action.payload
            }
        }
        case SET_SELECTED_LAYER_ID: {
            return {
                ...state,
                selectedLayerId: action.payload
            }
        }
        case SET_SELECTED_ASSET_ID: {
            return {
                ...state,
                selectedAssetId: action.payload
            }
        }
        case SET_SELECTED_IMAGE_LAYER_ID: {
            return {
                ...state,
                selectedImageLayerId: action.payload
            }
        }
        case SET_SELECTED_TEXT_LAYER_ID: {
            return {
                ...state,
                selectedTextLayerId: action.payload
            }
        }
        case SET_SELECTED_IMAGE_ID: {
            return {
                ...state,
                selectedImageId: action.payload
            }
        }
        case SET_SELECTED_TEXT_ID: {
            return {
                ...state,
                selectedTextId: action.payload
            }
        }
        case SET_PROJECTS_FOR_READY_CHECK: {
            return {
                ...state,
                projectsForReadyCheck: action.payload
            }
        }
        default:
            return state;
    }
}
