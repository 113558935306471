import React, { useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PageData } from "../../../../store/types";
import { useDispatch } from "react-redux";
import {
  fetchWorkFlows,
  getUsersData,
  addWorkFlow,
  duplicateWorkflow,
  setWorkFlows,
  deleteWorkFlow,
  setWorkflowPageData,
} from "../../../../store/actions";
import { permissionDeniedClick } from "../../../../utilities/common-function";
import moment from 'moment';
import AlertPopUp from '../../../shared/AlertPopUp';
import Modal from '../../../shared/Modal';
import Avatar from "../../../shared/Avatar";
import { Button, Tooltip } from "pyxis-ui-kit";
import { HDFC_DIGITAL_MFILTERIT_WORKFLOW, HDFC_PRINT_MFILTERIT_WORKFLOW, HDFC_BANK_MFILTERIT_WORKFLOW } from "../../../../utilities/paths";
import { openToast } from "../../../../Toasts";

interface WorkFlowListInterface {
  workflows: any;
  isDisabled: boolean;
  pageData: PageData | null;
  setLoader: Function;
}
interface WorkFlowInterface {
  data: any;
  index: number;
  isDisabled: boolean;
}

const WorkFlowList: React.FunctionComponent<WorkFlowListInterface> = ({
  workflows,
  isDisabled,
  pageData,
  setLoader,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return workflows.length > 0 ? (
    <InfiniteScroll
      dataLength={workflows.length}
      next={() => {
        dispatch(fetchWorkFlows({ page: pageData ? pageData.current_page + 1 : 1 }, (data:any, err:boolean) => {
          if(!err) {
            let workflows:any = data.results;
            dispatch(setWorkflowPageData({
              count: data.count,
              page_size: data.page_size,
              current_page: data.current_page,
              pages: data.pages
            }))
            if(workflows.length > 0) {
              let ids:string[] = workflows.reduce((acc:any, val:any) => {
                return [...acc, val.created_by, ...val.rounds.reduce((acc1: any, val1: any) => {
                  return [...acc1, ...val1.approvers];
                }, []),];
              },[]);
              if(ids.length > 0) {
                dispatch(getUsersData({ userIds: ids }, (userres:any, usererr:boolean) => {
                  if(!usererr) {
                    let { users } = userres.data;
                    workflows.map((workflow:any, windex:number) => {
                      workflow.created_by = users.find((u:any) => u._id === workflow.created_by) || "";
                      workflow.rounds.map((round:any, index:number) => {
                        round.approvers.map((a:any, r:number) => {
                          round.approvers[r] = users.find((u:any) => u._id === a) || "";
                        });
                      });
                    });
                    dispatch(addWorkFlow(workflows));
                  }
                }));
              } else {
                dispatch(addWorkFlow(workflows));
              }
            }
          }
        }));
      }}
      hasMore={(pageData?.current_page || 0) < (pageData?.pages || 0)}
      loader={
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      }
      className="table-responive"
      scrollableTarget="workflow">
      <table className="custom-table" cellPadding="0" cellSpacing="0">
        <thead>
          <tr className="first-row">
            <th>S.No.</th>
            <th>Workflow name</th>
            <th>Round name</th>
            <th>Created on</th>
            <th>Created by</th>
            <th>Round approvers</th>
            <th>Round preference</th>
            <th>
              <Button
                className={isDisabled? "workflow-btn permission-denied-lock": "workflow-btn"}
                type="primary"
                onClick={() => { 
                  if(isDisabled) { 
                    permissionDeniedClick(); 
                  } else { 
                    history.push(`workflow/new`)
                  }
                }}>
                  <svg width="8" height="8" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white"/>
                  </svg>
                  <span>Create Workflow</span>
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {workflows.map((workflow:any, index:number) => (
            <WorkFlow data={workflow} key={workflow.id} isDisabled={isDisabled} index={index} />
          ))}
        </tbody>
      </table>
    </InfiniteScroll>
  ):null;
};

const WorkFlow: React.FunctionComponent<WorkFlowInterface> = ({
  data,
  index,
  isDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const [duplicateLoading, setDuplicateLoader] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const moreRef = useRef(null);
  const [isAlertModal, openAlertModal] = useState(false);
  // const getColor = useCallback(()=>{
  //     return colors[Math.floor(Math.random() * colors.length)];
  // },[])

  const roundNames: string =
    data.rounds.sort((a:any, b:any) => a.order - b.order).reduce((acc:any, val:any, index:number) => {
      return (
        acc +
        `R${index + 1}: ${val.name} ${
          data.rounds.length === index + 1 ? "" : "| "
        }`
      );
    }, "") 
  || "";

  return (
    <tr>
      <td>{index+1}</td>
      <td>{data.name}</td>
      <td>{roundNames}</td>
      <td>{moment(data.created_at).format('LT')} | {moment(data.created_at).format('MMMM Do YYYY')}</td>
      <td>{data.created_by && data.created_by.firstName + data.created_by.lastName}</td>
      <td>
        {data.rounds.map((round:any, roundIndex:number) => (
          <div className="round-approver-common" key={round.id}>
            <span className="round-text">R{roundIndex + 1}:</span>
            <div className="ml-10">
              {(round.approvers.length <= 3 ? round.approvers : round.approvers.slice(0, 3)).map((approver:any, approverIndex:number) => (
                <Tooltip title={approver.firstName || approver.email} key={approver._id} content={
                  <div className="round-img">
                    <Avatar 
                      key={approver._id}
                      size="small"
                      initials={approver.firstName[0]||""} 
                      showInitials={!!(approver && !approver.profilePicture)}
                      backgroundColor={approver?.profileColour}
                      src={approver?approver.profilePicture:""}
                    />
                  </div>
                }>
                </Tooltip>
              ))}
            </div>
          </div>
        ))}
      </td>
      <td>
        {data.rounds.map((round:any, roundIndex:number) => (
          <div className="round-approver-common" key={round.id}>
            <span className="round-text">R{roundIndex + 1}: </span>
            <div className="round-text mrgn-l7">
              {round.settings.everyonemustapprove
                ? "Everyone Must Approve"
                : "Anyone Can Approve"}
            </div>
          </div>
        ))}
      </td>
      <td>
        <Button
          className={isDisabled ? "delete-btn permission-denied-lock" : "delete-btn"}
          disabled={deleteLoading}
          onClick={() => { 
            if ([HDFC_DIGITAL_MFILTERIT_WORKFLOW, HDFC_PRINT_MFILTERIT_WORKFLOW, HDFC_BANK_MFILTERIT_WORKFLOW].includes(data.id.toString())) {
              return openToast("error", "Restricted: Can not delete third party workflow.");
            }
            if(isDisabled) { 
              permissionDeniedClick(); 
            } else { 
              openAlertModal(true); 
            }
          }}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#EFF1FC"/>
            <path d="M24 16V24.5C24 25.3287 23.3153 26 22.4873 26H17.4873C16.6587 26 16 25.3287 16 24.5V16" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25 15.9993H15" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.666 13.9993H21.3327" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.3333 18.666V23.3327" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.6673 23.3327V18.666" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {deleteLoading && (
            <span className="loader">
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
        </Button>
        {isAlertModal && (
          <Modal>
            <AlertPopUp
              title="Are you sure?"
              text="Are you sure you want to remove this workflow?"
              submittext="Yes"
              canceltext="No"
              onSubmit={(setLoader:Function) => {
                if(!isDisabled) {
                  setLoader(true);
                  setDeleteLoading(true);
                  dispatch(deleteWorkFlow(data.id, (res:any, err:boolean) => {
                    setLoader(false);
                    setDeleteLoading(false);
                    if(!err) {
                      openAlertModal(false);
                    }
                  }));
                } else {
                  permissionDeniedClick()
                }
              }}
              onCancel={() => openAlertModal(false)} />
          </Modal>
        )}
        <Button
          className={isDisabled ? "duplicate-btn permission-denied-lock" : "duplicate-btn"}
          disabled={duplicateLoading}
          onClick={(e:any) => {
            e.stopPropagation();
            if ([HDFC_DIGITAL_MFILTERIT_WORKFLOW, HDFC_PRINT_MFILTERIT_WORKFLOW, HDFC_BANK_MFILTERIT_WORKFLOW].includes(data.id.toString())) {
              return openToast("error", "Restricted: Can not duplicate third party workflow.");
            }
            if(!isDisabled) {
              setDuplicateLoader(true);
              dispatch(duplicateWorkflow(data.id, (res:any, err:boolean) => {
                if(!err) {
                  dispatch(fetchWorkFlows({ page: 1 }, (data:any, err:boolean) => {
                    if(!err) {
                      let workflows:any = data.results;
                      dispatch(setWorkflowPageData({
                        count: data.count,
                        page_size: data.page_size,
                        current_page: data.current_page,
                        pages: data.pages
                      }))
                      if(workflows.length > 0) {
                        let ids:string[] = workflows.reduce((acc:any, val:any) => {
                            return [...acc, val.created_by, ...val.rounds.reduce((acc1: any, val1: any) => {
                              return [...acc1, ...val1.approvers];
                            }, []),];
                          }, []);
                        if(ids.length > 0) {
                          dispatch(getUsersData({ userIds: ids }, (userres:any, usererr:boolean) => {
                            if(!usererr) {
                              let { users } = userres.data;
                              workflows.map((workflow:any, windex:number) => {
                                workflow.created_by = users.find((u:any) => u._id === workflow.created_by) || "";
                                workflow.rounds.map((round:any, index:number) => {
                                  round.approvers.map((a:any, r:number) => {
                                    round.approvers[r] = users.find((u:any) => u._id === a) || "";
                                    }
                                  );
                                });
                              });
                              setDuplicateLoader(false);
                              dispatch(setWorkFlows(workflows));
                            }
                          }));
                        } else {
                          setDuplicateLoader(false);
                          dispatch(setWorkFlows(workflows));
                        }
                      }
                    } else {
                      setDuplicateLoader(false);
                    }
                  }));
                }
                setOpen(false);
              }));
            } else {
              permissionDeniedClick();
            }
          }}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="4" fill="#EFF1FC"/>
            <path d="M23.2736 12H14.5463C13.7463 12 13.0918 12.6545 13.0918 13.4545V23.6364H14.5463V13.4545H23.2736V12ZM25.4554 14.9091H17.4554C16.6554 14.9091 16.0009 15.5636 16.0009 16.3636V26.5455C16.0009 27.3455 16.6554 28 17.4554 28H25.4554C26.2554 28 26.91 27.3455 26.91 26.5455V16.3636C26.91 15.5636 26.2554 14.9091 25.4554 14.9091ZM25.4554 26.5455H17.4554V16.3636H25.4554V26.5455Z" fill="#8090E7"/>
          </svg>
          {duplicateLoading && (
            <span className="loader">
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
        </Button>
        <Link
          to={`/cocreate/workflow/${data.id}`}
          onClick={(e:any) => {
            if ([HDFC_DIGITAL_MFILTERIT_WORKFLOW, HDFC_PRINT_MFILTERIT_WORKFLOW, HDFC_BANK_MFILTERIT_WORKFLOW].includes(data.id.toString())) {
              e.preventDefault();
              return openToast("error", "Restricted: Can not duplicate third party workflow.");
            }
            if(isDisabled) {
              e.preventDefault(); 
              permissionDeniedClick();
            } else { 
            }
          }}
          className={isDisabled ? "edit-btn permission-denied-lock" : "edit-btn"}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#EFF1FC"/>
            <path d="M20 25.334H26" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23 14.3341C23.2652 14.0689 23.6249 13.9199 24 13.9199C24.1857 13.9199 24.3696 13.9565 24.5412 14.0276C24.7128 14.0986 24.8687 14.2028 25 14.3341C25.1313 14.4655 25.2355 14.6214 25.3066 14.7929C25.3776 14.9645 25.4142 15.1484 25.4142 15.3341C25.4142 15.5199 25.3776 15.7038 25.3066 15.8753C25.2355 16.0469 25.1313 16.2028 25 16.3341L16.6667 24.6675L14 25.3341L14.6667 22.6675L23 14.3341Z" stroke="#8090E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Link>
      </td>
    </tr>
  );
};

export { WorkFlow, WorkFlowList };