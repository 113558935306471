export const SET_PUBLIC_USER = "SET_PUBLIC_USER";
export const SET_PUBLIC_TOKEN = "SET_PUBLIC_TOKEN";

export type SetPublicUser = {
    type: typeof SET_PUBLIC_USER,
    payload: {
        name: String,
        profilePicture: String
    },
}
export type SetPublicToken = {
    type: typeof SET_PUBLIC_TOKEN,
    payload: string|null
  }