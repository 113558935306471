import { fabric } from 'fabric';
import React, { FC, lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { setErrMsg } from '../../store/actions';
import './HyperPersonalize.scss';

const HyperPersonalize: FC = () => {
  // hack
  const temp = fabric;
  const Design = lazy(() => import('../../components/DesignSection/DesignSectionHyper'));
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setErrMsg(''));
    };
  });

  return (
    <div className="hyper-personalize-wrapper">
      <ErrorBoundary>
        <Suspense fallback={<FullScreenSpinner />}>
          <Design />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default HyperPersonalize;

