import React from "react";
import "./index.scss";
import { Modal } from "antd";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-modal-icon.svg";
export const ConfirmationModal = ({
  isVisible,
  title,
  textButton,
  handleClose,
  onSuccess,
}) => {
  return (
    <Modal
      className='modal-custom'
      title={<DeleteIcon />}
      centered
      visible={isVisible}
      onOk={onSuccess}
      onCancel={handleClose}
      width={480}
      cancelText='Cancel'
      okText='Delete'
    >
      <p className='delete-title'>Delete data?</p>
      <p>
        Are you sure you want to Delete available data from the data base and re
        enable submission option the VLE?
      </p>
    </Modal>
  );
};