import { cloneDeep } from "lodash";
import { sortObjectListInReadOrder } from "../helpers/objectListSort";
import { resizeObjectListSimple } from "./simpleResize";
import { getObjectHeight, getObjectWidth } from "../helpers/getObjectSize";
import { resizeObj } from "../helpers/resizeObj";
import { resizeText } from "../helpers/resizeText";
import { CLASSIFICATION_TYPES } from "../classification/constants";

const resizeChildrenHorizontalExtreme = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number
): Array<any> => {
  if (!Array.isArray(children) || children.length === 0) {
    return [];
  }
  const sqrtRoot = Math.sqrt(prevWidth * prevHeight);
  const k = Math.pow(canvasHeight / canvasWidth, 1 / 2);
  const tmpCanvasWidth = sqrtRoot / k;
  const tmpCanvasHeight = sqrtRoot * k;

  const tmpCanvasChildren = normalToExtreme(children, tmpCanvasWidth, tmpCanvasHeight, true);

  return resizeObjectListSimple(tmpCanvasChildren, tmpCanvasWidth, tmpCanvasHeight, canvasWidth, canvasHeight);
};

const resizeChildrenVerticalExtreme = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number
): Array<any> => {
  if (!Array.isArray(children) || children.length === 0) {
    return [];
  }
  const sqrtRoot = Math.sqrt(prevWidth * prevHeight);
  const k = Math.pow(canvasWidth / canvasHeight, 1 / 2);
  const tmpCanvasWidth = sqrtRoot * k;
  const tmpCanvasHeight = sqrtRoot / k;

  const tmpCanvasChildren = normalToExtreme(children, tmpCanvasWidth, tmpCanvasHeight, false);

  return resizeObjectListSimple(tmpCanvasChildren, tmpCanvasWidth, tmpCanvasHeight, canvasWidth, canvasHeight);
};

const normalToExtreme = (children: any, tmpCanvasWidth: number, tmpCanvasHeight: number, isHorizontal: boolean) => {
  // The column width is width of the widest element
  const sortedObjects = sortObjectListInReadOrder(cloneDeep(children));
  const columns = isHorizontal ? getColumnsHorizontal(sortedObjects, tmpCanvasHeight) : getColumnsVertical(sortedObjects, tmpCanvasWidth);
  const updatedColumns: Array<object> = [];
  isHorizontal ? setColumnWidth(columns) : setColumnHeight(columns);
  isHorizontal ? arrangeColumnsHorizontal(columns, tmpCanvasWidth) : arrangeColumnsVertical(columns, tmpCanvasHeight);
  for (const column of columns) {
    isHorizontal ? arrangeElementsInsideColumnHorizontal(column, tmpCanvasHeight) : arrangeElementsInsideColumnVertical(column, tmpCanvasWidth);
  }
  for (const column of columns) {
    column?.elements.forEach((obj: object) => updatedColumns.push(obj));
  }
  return updatedColumns;
};

const resize = (params: any, isHorizontal: boolean) => {
  if (params?.object.layerType === "Text" && !params?.object.text.match(/\n/)) {
    return resizeText(params?.object, params?.dimension, isHorizontal);
  } else {
    return resizeObj(params?.object, params?.ratioX, params?.ratioY);
  }
};

// Summary height of the objects in column
const getSumHeight = (objects: Array<any>) => {
  let sumHeight = 0;
  objects.map(obj => (sumHeight += obj === undefined ? 0 : getObjectHeight(obj)));
  return sumHeight;
};
const getSumWidth = (objects: Array<any>) => {
  let sumWidth = 0;
  objects.map(obj => (sumWidth += obj === undefined ? 0 : getObjectWidth(obj)));
  return sumWidth;
};
// Check if the object fits the canvas and the next objects if yes add them in one column
const getColumnsHorizontal = (sortedObjects: any, tmpCanvasHeight: number) => {
  const columns: Array<any> = [];
  for (let i = 0; i < sortedObjects.length; ++i) {
    let object = { ...sortedObjects[i] };
    const column = {
      elements: [] as any,
      left: 0,
      width: 0
    };

    if (getObjectHeight(sortedObjects[i]) > tmpCanvasHeight) {
      const heightRatio = tmpCanvasHeight / getObjectHeight(sortedObjects[i]);
      object = resize({ object: sortedObjects[i], ratioX: heightRatio, ratioY: heightRatio, dimension: tmpCanvasHeight }, true);
    }

    column.elements.push(object);

    while (
      getSumHeight([...column.elements, sortedObjects[i + 1]]) <= tmpCanvasHeight &&
      sortedObjects[i + 1] !== undefined &&
      getObjectWidth(column.elements[column.elements.length - 1]) / getObjectWidth(sortedObjects[i + 1]) < 3 &&
      getObjectWidth(column.elements[column.elements.length - 1]) / getObjectWidth(sortedObjects[i + 1]) > 0.3
    ) {
      column.elements.push(sortedObjects[i + 1]);
      ++i;
    }
    columns.push(column);
  }
  return columns;
};
const getColumnsVertical = (sortedObjects: any, tmpCanvasWidth: number) => {
  const columns: Array<any> = [];
  for (let i = 0; i < sortedObjects.length; ++i) {
    let object = { ...sortedObjects[i] };
    const column = {
      elements: [] as any,
      top: 0,
      height: 0
    };

    if (getObjectWidth(sortedObjects[i]) > tmpCanvasWidth) {
      const widthRatio = tmpCanvasWidth / getObjectWidth(sortedObjects[i]);
      object = resize({ object: sortedObjects[i], ratioX: widthRatio, ratioY: widthRatio, dimension: tmpCanvasWidth }, false);
    }

    column.elements.push(object);

    while (
      getSumWidth([...column.elements, sortedObjects[i + 1]]) <= tmpCanvasWidth &&
      sortedObjects[i + 1] !== undefined &&
      getObjectHeight(column.elements[column.elements.length - 1]) / getObjectHeight(sortedObjects[i + 1]) < 3 &&
      getObjectHeight(column.elements[column.elements.length - 1]) / getObjectHeight(sortedObjects[i + 1]) > 0.3
    ) {
      column.elements.push(sortedObjects[i + 1]);
      ++i;
    }
    columns.push(column);
  }
  return columns;
};

const setColumnWidth = (columns: any) => {
  for (const column of columns) {
    const maxWidth = column?.elements.map((obj: object) => getObjectWidth(obj));
    column.width = Math.max(...maxWidth);
  }
};
const setColumnHeight = (columns: any) => {
  for (const column of columns) {
    const maxHeight = column?.elements.map((obj: object) => getObjectHeight(obj));
    column.height = Math.max(...maxHeight);
  }
};

const arrangeColumnsHorizontal = (columns: any, tmpCanvasWidth: number) => {
  let sumWidth = 0;
  columns.map((col: { width: number }) => (sumWidth += col.width));

  if (tmpCanvasWidth < sumWidth) {
    const ratioWidth = tmpCanvasWidth / sumWidth;
    for (const column of columns) {
      column?.elements.map((object: { layerType: string }) => {
        const obj = { ...object };
        return resize({ object: obj, ratioX: ratioWidth, ratioY: ratioWidth, tmpCanvasWidth }, true);
      });
    }
  }
  const emptySpace = tmpCanvasWidth - sumWidth;
  const spaceBetween = emptySpace / (columns.length + 1);

  let startLeft = spaceBetween;
  for (const column of columns) {
    column.left = startLeft;
    startLeft += column.width + spaceBetween;
  }
};
const arrangeColumnsVertical = (columns: any, tmpCanvasHeight: number) => {
  let sumHeight = 0;
  columns.map((col: { height: number }) => (sumHeight += col.height));

  if (tmpCanvasHeight < sumHeight) {
    const ratioHeight = tmpCanvasHeight / sumHeight;
    for (const column of columns) {
      column?.elements.map((object: { layerType: string }) => {
        const obj = { ...object };
        return resize({ object: obj, ratioX: ratioHeight, ratioY: ratioHeight, tmpCanvasHeight }, false);
      });
    }
  }
  const emptySpace = tmpCanvasHeight - sumHeight;
  const spaceBetween = emptySpace / (columns.length + 1);

  let startTop = spaceBetween;
  for (const column of columns) {
    column.top = startTop;
    startTop += column.height + spaceBetween;
  }
};

const arrangeElementsInsideColumnHorizontal = (column: any, tmpCanvasHeight: number) => {
  const emptySpace = tmpCanvasHeight - getSumHeight([...column.elements]);
  const spaceBetween = emptySpace / (column?.elements.length + 1);
  let startY = spaceBetween;

  for (const obj of column?.elements) {
    obj.left = column.left + (column.width - getObjectWidth(obj)) / 2;
    obj.top = startY;
    startY += getObjectHeight(obj) + spaceBetween;
  }
};
const arrangeElementsInsideColumnVertical = (column: any, tmpCanvasWidth: number) => {
  const emptySpace = tmpCanvasWidth - getSumWidth([...column.elements]);
  const spaceBetween = emptySpace / (column?.elements.length + 1);
  let startX = spaceBetween;

  for (const obj of column?.elements) {
    obj.top = column.top + (column.height - getObjectHeight(obj)) / 2;
    obj.left = startX;
    startX += getObjectWidth(obj) + spaceBetween;
  }
};

export const resizeChildrenExtreme = (children: Array<any>, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  const ratioX = canvasWidth / prevWidth;
  const ratioY = canvasHeight / prevHeight;
  const isHorizontalResize = ratioX > ratioY;

  const objectsPartlyOutsideCanvas = [];
  const objectsInsideCanvas = [];

  for (const child of children) {
    if (child.classificationType === CLASSIFICATION_TYPES.OUTSIDE_CANVAS) {
      objectsPartlyOutsideCanvas.push(child);
    } else {
      objectsInsideCanvas.push(child);
    }
  }

  const extremeResizeFunc = isHorizontalResize ? resizeChildrenHorizontalExtreme : resizeChildrenVerticalExtreme;

  const objectsInsideCanvasResized = extremeResizeFunc(objectsInsideCanvas, prevWidth, prevHeight, canvasWidth, canvasHeight);
  const objectsPartlyOutsideCanvasResized = resizeObjectListSimple(objectsPartlyOutsideCanvas, prevWidth, prevHeight, canvasWidth, canvasHeight);

  return [...objectsInsideCanvasResized, ...objectsPartlyOutsideCanvasResized];
};
