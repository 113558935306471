import React, { useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ApprovalState, AuthState, FolderState, HyperProjectFoldersState } from '../../../../store/types';
import TagSuggestionInput from './TagSuggestionInput';
import { addOrDeleteTag, setBannerView, setHyperProjectData, setProject, updateProjectDescription } from '../../../../store/actions';
import { openToast } from '../../../../Toasts';
import { Avatar } from '../../../shared';
import { Input } from 'antd';
import { ReactComponent as iconEdit} from '../../../../assets/svg/iconEdit.svg';
import { Icon } from '@material-ui/core';
import { LeftOutlined } from '@ant-design/icons';
import MetaDataDesign from './MetaDataDesign';
import { isItImage } from '../../../../utilities/common-function';


interface ContainerInterface {
    className: string
}
interface HeadingInterface {
    created_by: string,
    profilePicture:string,
    profileColour:string,
    isPublicLink: boolean,
    setSelectedBanner: Function;
    selectedBanner: any;
}
interface DetailInterface {
    created_at: string 
    created_by: string
    title: string
    type:string
    requested_on: string
    tags: string[]
    custom_tags: string[]
    can_manage: boolean
    projectRename:string
    handleAddOrRemoveTag: Function;
    entityType: string;
    isLoggedIn: boolean;
    isPublicLink: boolean;
    headline: string;
    description: string;
    primary_text: string;
    entityId: number;
    bannerData: any;
    hyperProjectData: any;
    selectedBanner: any;
}

const Container: React.FunctionComponent<ContainerInterface> = ({children,className}) =>{
    return <div className={className}>
        {children}
    </div>;
}

const Heading: React.FunctionComponent<HeadingInterface> = ({created_by,profilePicture,profileColour,isPublicLink,selectedBanner,setSelectedBanner}) =>{
    const publicLinkUser = useSelector((state: { publicLink: any }) => state.publicLink);
    const dispatch = useDispatch();
    const { bannerData, isBannerDetailView } = useSelector(
        (state: { folders: FolderState }) => state.folders
      );
      const activeChildBannerName = selectedBanner !== -1 && bannerData.banners.length > 0 && bannerData.banners[selectedBanner].size_name
      ? bannerData.banners[selectedBanner].size_name
        : null;
    
    return <div className="head">
        {selectedBanner != -1 && bannerData.banners.length > 1 ? (
            <div
            onClick={() => {
              dispatch(setBannerView(false));
              setSelectedBanner(-1);
            }}
            className="header-breadcrumb"
          >
            <LeftOutlined className="icon" />
            <span className="child-banner-name">{activeChildBannerName}</span>
          </div>
        ) : (
            <div className="info-container">
                <Avatar 
                    size="large"
                    initials={isPublicLink ? publicLinkUser?.name[0] : (created_by[0] ? created_by[0] : "")}  
                    showInitials={isPublicLink ? publicLinkUser?.profilePicture : (profilePicture ? !profilePicture : true)}
                    backgroundColor={isPublicLink ? publicLinkUser?.profileColour : (profileColour ? profileColour : "")}
                    src={isPublicLink ? publicLinkUser?.profilePicture : (profilePicture ? profilePicture : "")}
                />
                <div className="user-info">
                    <b className="created-by">Created by:</b>
                    <span className="creator">{isPublicLink ? publicLinkUser?.name : created_by}</span>
                </div>
            </div>
        )}
        
    </div>;
}

const Detail: React.FunctionComponent<DetailInterface> = ({
    title,
    created_at,
    created_by,
    requested_on,
    type,
    tags,
    custom_tags,
    can_manage,
    handleAddOrRemoveTag,
    projectRename,
    entityType,
    isLoggedIn,
    isPublicLink,
    headline,
    description,
    primary_text,
    entityId,
    bannerData,
    hyperProjectData,
    selectedBanner,
}) =>{
    const [editKey,setEditKey] = useState('');
    const [editKeyValue,setEditKeyValue] = useState('');
    const dispatch = useDispatch();

    const handleEditClick = (key) => {
        if(editKey === key) { setEditKey(''); setEditKeyValue(''); return; }
        setEditKeyValue((entityType === 'project' ? bannerData[key]: hyperProjectData[key])||'');
        setEditKey(key);
    }

    const handleUpdateProject = () => {
        if (!editKey || !editKeyValue) return openToast('error', `${editKey.toUpperCase().replace('_', ' ')} cannot be empty.`)
        dispatch(updateProjectDescription({project_id: entityId,body: { [editKey]: editKeyValue }},(res, err) => {
            if(!err){
                if(entityType === 'project'){
                    dispatch(setProject({...bannerData, [editKey]: editKeyValue}));
                } else if(entityType === 'hpproject'){
                    dispatch(setHyperProjectData({...hyperProjectData, [editKey]: editKeyValue}));
                }
                setEditKey('');
                setEditKeyValue('');
            }
        }))
    }

    return <>
   
    {(selectedBanner === -1 || (selectedBanner != -1 && bannerData.banners.length < 2)) &&  (
        <>
        <div className="detail">
            {created_at && <div className="title">Created at:</div>}
            {created_at && <div className="info">{created_at&&moment(created_at).format('MMMM D, YYYY')}</div>}

            {type && <div className="title">Project Type</div>}
            {type && <div className="info">{(type === "image_set" || type === "variant_set") ? "Banner Set" : type === "image" ? "Banner" : type}</div>}

            {(projectRename || title) && <div className="title">Artwork title</div>}
            {(projectRename || title) && <div className="info">{projectRename?projectRename:title}</div>}
        
            {requested_on && <div className="title">Approval Submission Date</div>}
            {requested_on && <div className="info">{moment(requested_on).format('MMMM D, YYYY')}</div>}

        </div>
        <div className="tags">
        {can_manage && 
        <>
            <div>Custom tags</div>
            <p className="description">Add tags for better searchability of your creatives. Your tags could be campaign names, brand objectives, target audience, channels, etc.</p>
            <div className="wrapper">
                {can_manage && isLoggedIn && !isPublicLink &&
                    <TagSuggestionInput handleAddOrRemoveTag={handleAddOrRemoveTag} customTags={custom_tags} entityType={entityType} />}
                    {/* // <span className="pill" onClick={()=>setShowInput(true)}>
                    //     + Add Tag
                    // </span> */}
                {/* {custom_tags.map((name:any,index:number)=>(
                    <span className="pill">
                        <span className="name tooltip-primary top" data-tip={name}>{name}</span>
                        {can_manage && <span className="icon" onClick={()=>handleAddOrRemoveTag(name,'delete',index)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
                            </svg>
                        </span>}
                    </span>
                ))} */}
            </div>
        </>}

        

            {/* {tags.length > 0 && <div>Auto Tags</div>}
            {tags.length > 0 &&<div className="wrapper">
                {(tags.length <= 4 ? tags: tags.slice(0,4)).map((name)=>(
                    <Tooltip placement="bottomRight" title={name} key={name} content={
                        <span className="pill">
                            <span className="name">{name}</span>   
                        </span>
                    }>
                    </Tooltip> 
                ))}
                {tags.length > 4 && 
                    <Tooltip placement="bottomRight" title={tags.slice(4,tags.length).join(`, \n `)} content={
                        <span className="pill">
                            {`+${tags.length - 4} more`}
                        </span>
                    }> 
                    </Tooltip>
                }
            </div>} */}
        </div>
        <div className="detail" style={{ marginTop: '15px' }}>
            <div className={`title`}>
                <span>{!headline && 'ADD'} HEADLINE</span>
                <span onClick={() => handleEditClick('headline')} style={{ cursor: 'pointer' }}>
                    <Icon component={iconEdit} />
                </span>
            </div>
            {editKey === 'headline' && <div className="info"><Input placeholder="Headline" value={editKeyValue} onChange={(e)=>setEditKeyValue(e.target.value)} onPressEnter={handleUpdateProject} autoFocus /></div>}
            {headline && editKey !== 'headline' && <div className="info">{headline}</div>}

            <div className={`title`}>
                <span>{!description && 'ADD'} DESCRIPTION</span>
                <span onClick={() => handleEditClick('description')} style={{ cursor: 'pointer' }}>
                    <Icon component={iconEdit} />
                </span>
            </div>
            {editKey === 'description' && <div className="info"><Input placeholder="Description" value={editKeyValue} onChange={(e)=>setEditKeyValue(e.target.value)} onPressEnter={handleUpdateProject} autoFocus /></div>}
            {description && editKey !== 'description' && <div className="info">{description}</div>}

            <div className={`title`}>
                <span>{!primary_text && 'ADD'} PRIMARY TEXT</span>
                <span onClick={() => handleEditClick('primary_text')} style={{ cursor: 'pointer' }}>
                    <Icon component={iconEdit} />
                </span>
            </div>
            {editKey === 'primary_text' && <div className="info"><Input placeholder="Primary Text" value={editKeyValue} onChange={(e)=>setEditKeyValue(e.target.value)} onPressEnter={handleUpdateProject} autoFocus /></div>}
            {primary_text && editKey !== 'primary_text' && <div className="info">{primary_text}</div>}
        </div>
        </>)}
        {bannerData && isItImage(bannerData?.type,bannerData?.file_type) && selectedBanner != -1 && (
            <>
               {bannerData.banners.length <= 1 && (
                   <>
                    <hr className="meta-design-border"></hr>
                    <div className="meta-heading">DESIGN INFORMATION</div>
                   </>
               )} 
                <MetaDataDesign projectSizeID={bannerData.banners[selectedBanner].id} selectedBanner={selectedBanner}  />
            </>
        )}
        
    </>
}

const AboutTab: React.FunctionComponent<any> = ({entityType,projectRename,isPublicLink,selectedBanner,setSelectedBanner}) => {
    const state = useSelector(
        (state: { 
            approval: ApprovalState, folders: FolderState, hyperProject: HyperProjectFoldersState, 
            authentication: AuthState
        }) => ({
            ...state.approval,...state.folders,hyperProjectData: state.hyperProject.hyperProjectData, ...state.authentication
        })
    );
    
    const dispatch = useDispatch();
    const { bannerData,approvalStatusSummary,hyperProjectData } = state;
    const entityId:number = entityType === 'project'? (bannerData?.id||0):(hyperProjectData?.id||0)
    const created_at = entityType === 'project'? (bannerData?.created_at?bannerData?.created_at:""):(hyperProjectData?.created_at?hyperProjectData?.created_at:"")
    const created_by = entityType === 'project'? (bannerData?.created_by?bannerData?.created_by?.firstName+" "+bannerData?.created_by?.lastName:""):(hyperProjectData?.created_by?hyperProjectData?.created_by?.firstName+" "+hyperProjectData?.created_by?.lastName:"")
    const profilePicture = entityType === 'project'? (bannerData?.created_by?.profilePicture||""):(hyperProjectData?.created_by?.profilePicture||"")
    const profileColour = entityType === 'project'? (bannerData?.created_by?.profileColour||""):(hyperProjectData?.created_by?.profileColour||"")
    const type = entityType === 'project'? (bannerData?.type||""):(hyperProjectData?.type||"")
    const title = entityType === 'project'? (bannerData?.title||""):(hyperProjectData?.title||"")
    const tags = entityType === 'project'? (bannerData?.auto_tags||[]):(hyperProjectData?.auto_tags||[])
    const custom_tags = entityType === 'project'? bannerData?.tags||[] : hyperProjectData?.tags||[]
    const can_manage = entityType === 'project'? (bannerData?.can_manage||false):(hyperProjectData?.can_manage||true);
    const headline = entityType === 'project'? (bannerData?.headline||""):(hyperProjectData?.headline||"");
    const description = entityType === 'project'? (bannerData?.description||""):(hyperProjectData?.description||"");
    const primary_text = entityType === 'project'? (bannerData?.primary_text||""):(hyperProjectData?.primary_text||"");

    const handleAddOrRemoveTag = (tagName:string,type:'add'|'delete',index?:number) => {
        dispatch(addOrDeleteTag({entity_id:entityId,entity_type:entityType,name:tagName,type},(res:any,err:boolean)=>{
            if(!err){
                if(type === 'delete' && typeof index === 'number'){
                    handleDeleteCustomTag(index);
                }else{
                    handleAddCustomTag(res.data.name);
                }
            }else{
                openToast('error',res.data.message||`Unable to ${type} Tag`);
            }
        }))
    }

    const handleDeleteCustomTag = (index:number) => {
        if(entityType === 'project'){
            let tempTags:string[] = bannerData?.tags.filter((_:string,i:number)=>(i !== index));
            dispatch(setProject({...bannerData,tags: tempTags}));
        }else if(entityType === 'hpproject'){
            let tempTags:string[] = hyperProjectData?.tags.filter((_:string,i:number)=>(i !== index));
            dispatch(setHyperProjectData({...hyperProjectData,tags: tempTags}));
        }
    }

    const handleAddCustomTag = (tag:string) => {
        if(entityType === 'project'){
            let tempTags:string[] = [tag,...bannerData?.tags||[]];
            dispatch(setProject({...bannerData,tags: tempTags}));
        }else if(entityType === 'hpproject'){
            let tempTags:string[] = [tag,...hyperProjectData?.tags||[]];
            dispatch(setHyperProjectData({...hyperProjectData,tags: tempTags}));
        }
    }

    return (<Container className="about-tab">
        <Heading created_by={created_by} profilePicture={profilePicture} profileColour={profileColour} isPublicLink={isPublicLink} setSelectedBanner={setSelectedBanner} selectedBanner={selectedBanner}/>
        {/* <Detail created_at={created_at} title={title} can_manage={can_manage} projectRename={projectRename}
            type={type} requested_on={approvalStatusSummary?.requested_on||""}   */}
        {/* <Heading title={title} /> */}
        <Detail title={title} created_at={created_at} created_by={created_by} can_manage={can_manage}
            type={type} requested_on={approvalStatusSummary?.requested_on||""} 
            tags={tags} custom_tags={custom_tags} handleAddOrRemoveTag={handleAddOrRemoveTag} 
            projectRename={projectRename} entityType={entityType} entityId={entityId}
            isLoggedIn={state.isLoggedIn} isPublicLink={isPublicLink} bannerData={bannerData}
            headline={headline} description={description} primary_text={primary_text}
            hyperProjectData={hyperProjectData}
            selectedBanner={selectedBanner}
            // defaultTargeting={defaultTargeting}
            // defaultChannel={defaultChannel}
        />
    </Container>)
}

export default AboutTab;

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
