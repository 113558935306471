import React, {
  useState,
  useEffect,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faEye,
  faSearch,
  faSpinner,
  faEyeSlash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { RolesState } from "../../../store/types";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMembersList,
  fetchProductList,
  setSelectedItem,
  createUser,
  setItemList
} from "../../../store/actions";
import { ClickAwayListener } from "@material-ui/core";
import { string } from "prop-types";
import { openToast } from "../../../Toasts";
import Avatar from "../../shared/Avatar";
import { csvJSON, isValidEmail, isValidName } from "../../../utilities/common-function";

interface AddMemberPopUpInterface {
  type: string;
  initailStep?: number;
  onCancel: Function;
  onSubmit: Function;
}

const ChooseEmailAndRole: React.FunctionComponent<{
  step: number;
  setStep: Function;
  type: string;
  initailStep: number;
  onCancel: Function;
  onSubmit: Function;
  setUserData: Function;
  initialEmail: string;
  initialSelectedId: string;
}> = ({ step, setStep, type, initailStep, onCancel, onSubmit, setUserData, initialEmail,initialSelectedId }) => {
  const state = useSelector((state: { roles: RolesState }) => state.roles);
  const [text, setText] = useState(initialEmail);
  const [isRoleDropdown, setRoleDropdown] = useState(false);
  const [role, setRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setLoader] = useState(false);
  const [selectedId, setSelectedId] = useState(initialSelectedId);
  const [isMemberDropdown, setMemberDropdown] = useState(false);
  const [dropdownItemList, setDropdownItemList] = useState([]);
  const dispatch = useDispatch();
  const rolesHashRef = useRef(new Map());
  const list = dropdownItemList.filter((data:any) =>(
    type === "member"
      ? (data.email.toLowerCase().indexOf(text.trim().toLowerCase()) > -1 
        || (data.firstName+" "+data.lastName).toLowerCase().indexOf(text.trim().toLowerCase()) > -1)
      : data.name.toLowerCase().indexOf(text.trim().toLowerCase()) > -1
  ));
  const { rolesList, brand_id } = state;

  useEffect(()=>{
    rolesList.map((r:any)=>{
      rolesHashRef.current.set(r.roleId,r.name);
    })
  },[rolesList])

  const getRoleName = useCallback((role:string)=>{
    return rolesHashRef.current.get(role)||role.split('-').join(' ');
  },[rolesHashRef.current])
  
  useEffect(() => {
    if (type === "member" && step === 0 && brand_id) {
      dispatch(
        fetchMembersList(
          { entityType: "brand", entityId: brand_id },
          (response: any, error: boolean) => {
            if (!error) {
              let users = response.data.users.filter(
                (u: any) => u.role === "brand-user"
              );
              setDropdownItemList(users);
            }
          }
        )
      );
    }
    if (type === "product" && step === 0) {
      dispatch(
        fetchProductList(
          { entityType: "product" },
          (response: any, error: boolean) => {
            if (!error) {
              setDropdownItemList(response.data.products);
            }
          }
        )
      );
    }
  }, []);

  function validate() {
    // if(!text.trim() || !firstName.trim() || !lastName.trim()) {
    //   openToast('error', 'One or more fields are empty');
    //   return false;
    // }
    // if(!isValidEmail(text)) {
    //   openToast('error', 'Email Id is invalid');
    //   return false;
    // }
    // if(!isValidName(firstName)) {
    //   openToast('error', 'First name is invalid');
    //   return false;
    // }
    // if(!isValidName(lastName)) {
    //   openToast('error', 'Last name is invalid');
    //   return false;
    // }
    return true;
  }

  const handleTrigger = (e:any) => {
    e.preventDefault();
    e.stopPropagation()
    setMemberDropdown(true)
  }

  return (
    <div className="add-member-popup">
      <div className="title">
        <span>
          {type === "member"
            ? step === 0
              ? "Add User"
              : "Invite User"
            : "Add Workspace"}
        </span>
      </div>
      <div className="body">
        <ClickAwayListener
          onClickAway={() => {
            setMemberDropdown(false);
          }}
        >
          <div className="input-field">
            <label>{type === "member" ? "Email Id" : "Workspace name"}</label>
            <input
              autoFocus
              type={type === "member" ? "email" : "text"}
              value={text}
              placeholder={type === "member" ? "Member email ID" : "Workspace name"}
              onFocus={handleTrigger}
              onChange={(e) => {
                if (!isMemberDropdown && step === 0) {
                  setMemberDropdown(true);
                }
                if (selectedId){
                  setSelectedId('')
                }
                setText(e.target.value);
              }}
            />
            {isMemberDropdown && step === 0 && (
              
                <div className="dropdown-container">
                  {(type === "member" && list.length === 0 && text.length > 0) && <div className="invite" onClick={()=>setStep(1)}>
                    <div className="icon">
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <span className="text">{`Invite "${text}"`}</span>
                  </div>}
                  {list.map((data: any, index: number) => (
                    <div
                      className="dropdown-item"
                      key={data._id}
                      onClick={() => {
                        setSelectedId(data._id);
                        setText(type === "member" ? data.email : data.name);
                        setMemberDropdown(false);
                      }}
                    >
                      <Avatar 
                        size="x-small"
                        initials={type === "member"? (data.firstName ? data.firstName[0] : "" )
                          :(data.name ? data.name[0]: "")} 
                        showInitials={type === "member"? !!(data && !data.profilePicture):true}
                        backgroundColor={type === "member"? data?.profileColour:""}
                        src={type === "member"? (data.profilePicture?data.profilePicture:""):""}
                      />
                      <div className="detail">
                        <div className="name">
                          {type === "product" && data.name}
                          {type === "member" &&
                            data.firstName + " " + data.lastName}
                        </div>
                        {type === "member" && (
                          <div className="email">{data.email}</div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
            )}
          </div>
        </ClickAwayListener>
        {step === 0 && (
          <div className="input-field">
            <label>Role</label>
            <div className="select" onClick={() => setRoleDropdown(true)}>
              <span>{role ? getRoleName(role) : "Select member role"}</span>
              <span>
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </div>
            {isRoleDropdown && (
              <ClickAwayListener
                onClickAway={() => {
                  setRoleDropdown(false);
                }}
              >
                <div className="dropdown-container">
                  {rolesList.map((role: any, index: number) => (
                    <div
                      key={role.roleId}
                      className="dropdown-item"
                      onClick={() => {
                        setRole(role.roleId);
                        setRoleDropdown(false);
                      }}
                    >
                      {role.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}
        {step === 1 && (
          <>
            <div className="input-field">
              <label>First Name</label>
              <input
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="input-field">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="footer">
        <button className="secondary" onClick={() => onCancel && onCancel()}>
          Cancel
        </button>
        <button
          className="primary"
          // disabled={
          //   step === 0
          //     ? !(type === "member"
          //         ? (text && role && isValidEmail(text) && selectedId && !!dropdownItemList.find((a:any)=>a._id === selectedId) )
          //         : (text && role && selectedId && !!dropdownItemList.find((a:any)=>a._id === selectedId)))
          //     : !(text && firstName && lastName && isValidEmail(text))
          // }
          disabled={isLoading}
          onClick={() => {
            if (onSubmit) {
              if (step === 0) {
                onSubmit({ selectedId, role }, setLoader);
              }
              if (step === 1 && brand_id && validate()) {
                setLoader(true);
                dispatch(
                  createUser(
                    {
                      firstName,
                      lastName,
                      username: firstName + "-" + lastName,
                      email: text,
                      role: "brand-user",
                      entityType: "brand",
                      entityId: brand_id,
                    },
                    (res: any, err: boolean) => {
                      setLoader(false);
                      if (!err) {
                        let users:any = res.userList;
                        if(users.length > 0){
                          setUserData(users[0]);
                          if(initailStep === 1)
                          onSubmit && onSubmit(users[0],setLoader)
                          setStep(2);
                        }else{
                          openToast("error",'Unable to Invite');
                        }
                      } else {
                        openToast("error", res.message);
                      }
                    }
                  )
                );
              }
            }
          }}
        >
          {isLoading && (
            <span>
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
          <span>Add</span>
        </button>
      </div>
    </div>
  );
};

const UserAddedPropy: React.FunctionComponent<{
  onCancel: Function;
  userData: any;
  initailStep: number;
  setStep: Function;
}> = ({ onCancel, initailStep, userData, setStep }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopy] = useState(false);
  const dispatch = useDispatch();
  const { firstName, lastName, email, password } = userData;

  const copy = useCallback(() => {
    navigator.clipboard.writeText(password).then(
      () => {
        setIsCopy(true);
      },
      (err) => {
        console.log("Some issue while copying text!");
      }
    );
  }, [password]);

  return (
    <div className="add-member-popup">
      <div className="title colored">
        <span>New User Added</span>
      </div>
      <div className="body column">
        <div className="circle">
          <svg
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M59.0003 19.6665C64.2162 19.6665 69.2185 21.7385 72.9068 25.4267C76.595 29.115 78.667 34.1172 78.667 39.3332C78.667 44.5491 76.595 49.5514 72.9068 53.2396C69.2185 56.9278 64.2162 58.9998 59.0003 58.9998C53.7844 58.9998 48.7821 56.9278 45.0939 53.2396C41.4057 49.5514 39.3337 44.5491 39.3337 39.3332C39.3337 34.1172 41.4057 29.115 45.0939 25.4267C48.7821 21.7385 53.7844 19.6665 59.0003 19.6665ZM59.0003 68.8332C80.732 68.8332 98.3336 77.634 98.3336 88.4998V98.3332H19.667V88.4998C19.667 77.634 37.2687 68.8332 59.0003 68.8332Z"
              fill="#515151"
            />
          </svg>
        </div>
        <div className="details">
          <div className="name">{firstName + " " + lastName}</div>
          <div className="email">{email}</div>
        </div>
        <div className="password">
          <span>Password</span>
          <div className="copy">
            <input
              type={isVisible ? "text" : "password"}
              value={password}
              readOnly
            />
            <span onClick={() => setIsVisible((val) => !val)}>
              <FontAwesomeIcon icon={!isVisible ? faEye : faEyeSlash} />
            </span>
          </div>
          <button className="secondary" onClick={() => copy()}>
            {isCopied ? "COPIED CREDENTIALS" : "COPY CREDENTIALS"}
          </button>
        </div>
      </div>
      <div className="footer" style={{ justifyContent: "center" }}>
        <button className="secondary" onClick={() => {
          if(initailStep === 1){
            onCancel && onCancel()
          }else{
            setStep(0)
          }
        }}>
          Close
        </button>
      </div>
    </div>
  );
};

const AddMemberPopUp: React.FC<AddMemberPopUpInterface> = ({
  type,
  initailStep = 0,
  onCancel,
  onSubmit
}) => {
  const state = useSelector((state: { roles: RolesState }) => state.roles);
  const [step, setStep] = useState(initailStep);
  const [userData, setUserData] = useState<any>(null);

  switch (step) {
    case 0:
    case 1:
      return (
        <div className="popup-overlay">
          <ChooseEmailAndRole
            step={step}
            setStep={setStep}
            onCancel={onCancel}
            onSubmit={onSubmit}
            initailStep={initailStep}
            setUserData={setUserData}
            initialEmail={!!userData?(userData.email):""}
            initialSelectedId={!!userData?(userData._id):""}
            type={type}
          />
        </div>
      );
      break;
    case 2:
      return (
        <div className="popup-overlay">
          <UserAddedPropy 
            onCancel={onCancel} 
            userData={userData} 
            setStep={setStep} 
            initailStep={initailStep}
          />
        </div>
      );
      break;
  }
  return null;
};

export default AddMemberPopUp;
