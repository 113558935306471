export const SEND_FOR_APPROVAL = "SEND_FOR_APPROVAL";
export const FOLDER_APPROVAL = 'FOLDER_APPROVAL';
export const SET_APPROVAL_LOADER = "SET_APPROVAL_LOADER";
export const GET_STATUS_SUMMARY = "GET_STATUS_SUMMARY";
export const SET_APPROVAL_SUMMARY = "SET_APPROVAL_SUMMARY";
export const GET_APPROVAL_STATUS = "GET_APPROVAL_STATUS";
export const SET_APPROVAL_STATUS = "SET_APPROVAL_STATUS";
export const SET_IS_SENT_FOR_APPROVAL = "SET_IS_SENT_FOR_APPROVAL";
export const DUPLICATE_APPROVAL_ROUND = "DUPLICATE_APPROVAL_ROUND";
export const EDIT_APPROVAL_ROUND = "EDIT_APPROVAL_ROUND";
export const DELETE_APPROVAL_ROUND = "DELETE_APPROVAL_ROUND";
export const CHANGE_APPROVAL_ROUND = "CHANGE_APPROVAL_ROUND";
export const ADD_APPROVAL_ROUND = "ADD_APPROVAL_ROUND";
export const APPEND_APPROVAL_ROUND = "APPEND_APPROVAL_ROUND";
export const FETCH_APPROVAL_COMMENTS = "FETCH_APPROVAL_COMMENTS";
export const SET_APPROVAL_COMMENTS = "SET_APPROVAL_COMMENTS";
export const SEND_APPROVAL_COMMENT = "SEND_APPROVAL_COMMENT";
export const ADD_APPROVAL_COMMENT = "ADD_APPROVAL_COMMENT";
export const ADD_APPROVAL_REPLY_COMMENT = "ADD_APPROVAL_REPLY_COMMENT";
export const SET_COMMENTS_RESOLUTION_TYPE = "SET_COMMENTS_RESOLUTION_TYPE";
export const EDIT_APPROVAL_COMMENT = "EDIT_APPROVAL_COMMENT";
export const CHANGE_APPROVAL_COMMENT = "CHANGE_APPROVAL_COMMENT";
export const DELETE_APPROVAL_COMMENT = "DELETE_APPROVAL_COMMENT";
export const FETCH_PENDING_APPROVAL_COUNT = "FETCH_PENDING_APPROVAL_COUNT";
export const SET_PENDING_APPROVAL_COUNT = "SET_PENDING_APPROVAL_COUNT";
export const FETCH_PENDING_APPROVALS = "FETCH_PENDING_APPROVALS";
export const SET_PENDING_APPROVALS = "SET_PENDING_APPROVALS";
export const SET_PENDING_ENTITIES = "SET_PENDING_ENTITIES";
export const ADD_USER_TO_APPROVAL_ROUND = "ADD_USER_TO_APPROVAL_ROUND";
export const DELETE_USER_FROM_APPROVAL_ROUND = "DELETE_USER_FROM_APPROVAL_ROUND";
export const MARK_APPROVAL_STATUS = "MARK_APPROVAL_STATUS";
export const FETCH_MY_APPROVAL_STATUS = "FETCH_MY_APPROVAL_STATUS";
export const CANCEL_APPROVAL_REQUEST = "CANCEL_APPROVAL_REQUEST";
export const CONTINUE_APPROVAL = "CONTINUE_APPROVAL";
export const SET_ACTIVE_APPROVAL_STATUS = "SET_ACTIVE_APPROVAL_STATUS";
export const FETCH_APPROVALS_LIST = "FETCH_APPROVALS_LIST";
export const SET_ACTIVE_APPROVAL_ENTITY_TYPE = "SET_ACTIVE_APPROVAL_ENTITY_TYPE";
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const RESEND_FOR_APPROVAL = 'RESEND_FOR_APPROVAL';

export type ApprovalState = {
  approvalStatus: any,
  comments: any[],
  approvalStatusSummary: any,
  pendingApprovals: any,
  pendingEntities: any,
  pendingApprovalCount: number,
  loading: boolean,
  activeApprovalStatus: string,
  activeApprovalEntityType: string | null,
  isSentForApproval: boolean,
  comments_resolution: number
}

export type SendForApproval = {
  type: typeof SEND_FOR_APPROVAL,
  payload: {
    entity_id: number,
    workflow_id: number,
    entity_type: string,
    note: string,
    platform?: string | null;
  },
  callback?: Function
}

export type FolderApproval = {
  type: typeof FOLDER_APPROVAL,
  payload: {
    entity_ids: number[],
    workflow_id: number,
    entity_type: string,
    note: string,
    platform?: string | null,
  },
  callback?: Function
}

export type SetApprovalLoader = {
  type: typeof SET_APPROVAL_LOADER,
  payload: boolean
}

export type GetStatusSummary = {
  type: typeof GET_STATUS_SUMMARY,
  payload: {
    ids: number[]
    entity_type: string;
  },
  callback?: Function
}

export type GetApprovalStatus = {
  type: typeof GET_APPROVAL_STATUS,
  payload: {
    entity_id: number;
    entity_type: string;
  },
  callback?: Function
}

export type SetApprovalStatus = {
  type: typeof SET_APPROVAL_STATUS,
  payload: any
}

export type SetIsSentForApproval = {
  type: typeof SET_IS_SENT_FOR_APPROVAL,
  payload: boolean
}

export type DuplicateApprovalRound = {
  type: typeof DUPLICATE_APPROVAL_ROUND,
  payload: {
    entity_id: number,
    round_id: number
  }
  callback?: Function
}

export type EditApprovalRound = {
  type: typeof EDIT_APPROVAL_ROUND,
  payload: {
    entity_id: number,
    round_id: number,
    round: any
  },
  callback?: Function
}

export type DeleteApprovalRound = {
  type: typeof DELETE_APPROVAL_ROUND,
  payload: {
    entity_id: number,
    round_id: number
  }
  callback?: Function
}

export type ChangeApprovalRound = {
  type: typeof CHANGE_APPROVAL_ROUND,
  payload: {
    round: any,
    roundIndex: number
  }
}

export type AddApprovalRound = {
  type: typeof ADD_APPROVAL_ROUND,
  payload: {
    entity_id: number,
    round: any
  }
  callback?: Function
}

export type AppendApprovalRound = {
  type: typeof APPEND_APPROVAL_ROUND,
  payload: any,
}

export type FetchApprovalComments = {
  type: typeof FETCH_APPROVAL_COMMENTS,
  payload: {
    entity_id: number,
    page: number,
    page_size: number,
    entity_type: string,
    order_by?: string,
    child_id?: number,
    resolution_status?: number
  }
  callback?: Function
}

export type SetApprovalComments = {
  type: typeof SET_APPROVAL_COMMENTS,
  payload: any[]
}

export type SendApprovalComment = {
  type: typeof SEND_APPROVAL_COMMENT,
  payload: {
    entity_id: number,
    comment: string,
    file?: File,
    entity_type: string;
  },
  callback?: Function
}

export type AddApprovalComment = {
  type: typeof ADD_APPROVAL_COMMENT,
  payload: any
}

export type SetCommentsResolutionType = {
  type: typeof SET_COMMENTS_RESOLUTION_TYPE,
  payload: number
}

export type EditApprovalComment = {
  type: typeof EDIT_APPROVAL_COMMENT,
  payload: {
    comment_id: number,
    comment: string
  },
  callback?: Function
}

export type DeleteApprovalComment = {
  type: typeof DELETE_APPROVAL_COMMENT,
  payload: number,
  callback?: Function
}

export type ChangeApprovalComment = {
  type: typeof CHANGE_APPROVAL_COMMENT,
  payload: {
    commentIndex: number,
    comment: any
  }
}

export type SetApprovalStatusSummary = {
  type: typeof SET_APPROVAL_SUMMARY,
  payload: any
}

export type FetchPendingApproval = {
  type: typeof FETCH_PENDING_APPROVALS,
  payload: {
    page: number,
    page_size: number,
    user_id: string,
    product_id: string
  },
  callback?: Function
}

export type SetPendingApproval = {
  type: typeof SET_PENDING_APPROVALS,
  payload: any
}

export type SetPendingEntities = {
  type: typeof SET_PENDING_ENTITIES,
  payload: any
}

export type FetchPendingApprovalCount = {
  type: typeof FETCH_PENDING_APPROVAL_COUNT,
  payload: {
    user_id: string,
    product_id: string
  },
  callback?: Function
}

export type SetPendingApprovalCount = {
  type: typeof SET_PENDING_APPROVAL_COUNT,
  payload: number
}

export type AddUserToApprovalRound = {
  type: typeof ADD_USER_TO_APPROVAL_ROUND,
  payload: {
    entity_id: number,
    roundId: number,
    userId: string
  },
  callback?: Function
}

export type DeleteUserFromApprovalRound = {
  type: typeof DELETE_USER_FROM_APPROVAL_ROUND,
  payload: {
    entity_id: number,
    roundId: number,
    userId: string
  },
  callback?: Function
}

export type MarkApprovalStatus = {
  type: typeof MARK_APPROVAL_STATUS,
  payload: {
    userId: string;
    entity_id: number;
    status: string;
    entity_type: string;
  },
  callback?: Function
}

export type FetchMyApprovalStatus = {
  type: typeof FETCH_MY_APPROVAL_STATUS,
  payload: {
    entity_id: number | number[];
    user_id: string | string[];
    entity_type: string;
  },
  callback?: Function
}

export type CancelApprovalRequest = {
  type: typeof CANCEL_APPROVAL_REQUEST,
  payload: {
    entity_id: number;
    entity_type: string;
  }
  callback?: Function
}

export type ContinueApproval = {
  type: typeof CONTINUE_APPROVAL,
  payload: {
    entity_id: number;
    entity_type: string;
  }
  callback?: Function
}

export type SetActiveApprovalStatus = {
  type: typeof SET_ACTIVE_APPROVAL_STATUS,
  payload: string;
}

export type FetchApprovalsList = {
  type: typeof FETCH_APPROVALS_LIST,
  payload: {
    page: number;
    page_size: number;
    entity_type?: string;
    status?: string;
  },
  callback?: Function
}

export type SetActiveApprovalEntityType = {
  type: typeof SET_ACTIVE_APPROVAL_ENTITY_TYPE,
  payload: string | null
}

export type ReSendForApproval = {
  type: typeof RESEND_FOR_APPROVAL,
  payload: {
    entity_id: number;
    entity_type: string;
  },
  callback?: Function
}