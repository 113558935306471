import React from 'react';
import './DashboardOverview.scss';
export default function Dashboardoverview() {
  return (
   <>
     <div className="job-approval-wrapper">
       <div className="job-approval-row">
       <div className="job-approval-col">
         <div className="job-approval-white-wrapper">
           <div className="approval-common-head">
             <h6>Jobs that need your attention</h6>
             <p>Jobs of High Priority.</p>
           </div>
           <div className="approval-common-body">
             <div className="approval-status-bar">
             <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle danger"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>
             <div className="approval-status-bar">
             <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle primary"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>
             </div>

             </div>
             </div>

           </div>

         </div>
       </div>
       <div className="job-approval-col">
         <div className="job-approval-white-wrapper">
           <div className="approval-common-head">
             <h6>Overall Jobs  that need Approval</h6>
             <p>Jobs of High Priority.</p>
           </div>
           <div className="approval-common-body">
             <div className="approval-status-bar">
               <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle warning"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             <div className="approval-description-wrapper m-t0">
             <div className="approval-left-description">
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>
             <div className="approval-status-bar">
               <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle warning"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>

           </div>

         </div>
       </div>
       <div className="job-approval-col">
         <div className="job-approval-white-wrapper">
           <div className="approval-common-head">
             <h6>Inactive Jobs</h6>
             <p>Jobs of High Priority.</p>
           </div>
           <div className="approval-common-body">
             <div className="approval-status-bar">
               <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>
             <div className="approval-status-bar">
               <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>

           </div>

         </div>
       </div>
       <div className="job-approval-col">
         <div className="job-approval-white-wrapper">
           <div className="approval-common-head">
             <h6>Last Activity</h6>
             <p>Jobs of High Priority.</p>
           </div>
           <div className="approval-common-body">
             <div className="approval-status-bar">
               <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle warning"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>
             <div className="approval-status-bar">
               <div className="approval-description-wrapper">
             <div className="approval-left-description">
              <span className="proress-circle sucess"></span>
              <div className="profile-wrapper">
                <div className="prfl-detail">
                  <span className="prfl-img-outer">
                  <img src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
                  </span>
                  <p>John Eric</p>
                </div>
                <p className="prfl-description"><strong>Lorem ipsum dolor sit amet </strong> consetetur sadipscing elitr</p>
              </div>
             </div>
             <div className="approval-right-description">
               <div className="modified-date">
                 <span className="modified-text">Last Modified</span>
                 <span  className="modified-text  text-color-blue m-t3">5 mins ago</span>
               </div>

             </div>
             </div>
             </div>

           </div>

         </div>
       </div>


       </div>
       </div>
     </>
  );
}