import { ElementsTrayState, FETCH_ELEMENTS, SET_ELEMENTS, FetchElementsAction, SetElementsAction, FETCH_CATEGORIES, SET_CATEGORIES, SetCategoriesAction, FetchCategoriesAction,LoadingOffAction, LOADER_OFF } from "../types";

const initialState: ElementsTrayState = {
  elements: [],
  categories: [],
  categoryPage: 1,
  categoryPages: 1,
  page: 1,
  catName: '',
  search: '',
  loading: false,
  seeAll: false,
  selectedElementId: 0,
  page_size: 30,
  totalPages: 1,
  type: 'element'
}

export const elementsTrayReducer = (state = initialState, action: SetElementsAction|FetchElementsAction|SetCategoriesAction|FetchCategoriesAction|LoadingOffAction): ElementsTrayState => {
  switch(action.type) {
    case FETCH_ELEMENTS:
      return {
        ...state,
        loading: true
      }
    case SET_ELEMENTS:
      return {
        ...state,
        ...action.payload,
        elements: action.payload.page > 1 ? [...state.elements, ...action.payload.elements] : action.payload.elements
      }
    case FETCH_CATEGORIES:
      if(action.payload.categoryPage > 1){
        return {
          ...state,
          loading: false
        }
      }
      return {
        ...state,
        ...initialState,
        loading: true
      }
    case SET_CATEGORIES:
      return {
        ...state,
        ...action.payload,
        loading: false,
        categories: action.payload.categoryPage > 1 ? [...state.categories, ...action.payload.categories] : action.payload.categories
      }
    case LOADER_OFF:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}