
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios'
import { FETCH_ELEMENTS, FetchElementsAction, LOADER_OFF, FetchCategoriesAction, FETCH_CATEGORIES } from "../types";
import { DESIGN_URI } from '../../utilities/paths';
import { setElementsAction, setCategoriesAction } from '../actions/elementsTrayActions';
import { store } from '../store';
import { API_URL, SUB_KEY, TOKEN } from '../../utilities/paths';


function* fetchElements(action: FetchElementsAction) {
  const { search: query, selectedElementId, page, page_size, seeAll, type } = action.payload

  let queryString = '';
  if (query) {
    queryString += `?search=${query}`;
  }
  if (seeAll) {
    queryString += queryString.includes('?') ? `&type=${type}` : `?type=${type}`;
  }
  if (selectedElementId) {
    queryString += queryString.includes('?') ? `&category=${selectedElementId}` : `?category=${selectedElementId}`;
  }
  if (page) {
    queryString += queryString.includes('?') ? `&page=${page}` : `?page=${page}`;
  }
  if (page_size) {
    queryString += queryString.includes('?') ? `&page_size=${page_size}` : `?page_size=${page_size}`;
  }

  try {
    const response = yield axios.get(`${API_URL}/element${queryString}`, {
      headers: {
        'Ocp-Apim-Subscription-Key': SUB_KEY,
        token: TOKEN,
      },
    });

    if (!response.data.error) {
      yield put(
        setElementsAction(
          response.data.data.results,
          response.data.data.current_page,
          action.payload.catName,
          page_size,
          query,
          seeAll,
          selectedElementId,
          response.data.data.pages,
          type
        )
      )
    } else {
      yield put({type: LOADER_OFF});
    }
  } catch (error) {
    yield put({type: LOADER_OFF});
  }
}

export function* getElementsWatcher() {
  yield takeLatest(FETCH_ELEMENTS, fetchElements)
}

function* fetchCategories(action: FetchCategoriesAction) {
  if (action.payload.categoryPage > action.payload.categoryPages) {
    return
  }

  let url = `${API_URL}/element/category?page=${action.payload.categoryPage}&type=${action.payload.type}&is_default=True`;
  try {
    const response = yield axios.get(url, {
      headers: {
        'Ocp-Apim-Subscription-Key': SUB_KEY,
        token: TOKEN,
      },
    });
    if (!response.data.error) {
      yield put(
        setCategoriesAction(
          response.data.data.categories,
          response.data.data.current_page,
          response.data.data.pages
        )
      )
    } else {
      yield put({type: LOADER_OFF});
    }
  } catch (error) {
    yield put({type: LOADER_OFF});
  }
}

export function* getCategoriesWatcher() {
  yield takeLatest(FETCH_CATEGORIES, fetchCategories)
}
