import React, { useEffect, Suspense, FC,useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import {LeftNavBar,Typography,Colors,Templates} from '../../components/LibrarySection';
import {Content } from '../../components/DashboardSection';

import './index.scss';
import { withRouter, Redirect } from 'react-router-dom';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';
import LibraryOnboarding from '../../components/LibraryOnboarding';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const getContent = (librarytype: string) => {
  switch(librarytype){
    case "images": return <React.Fragment>
        <div className="flex h-100">
          <Content type="file"/>
        </div>
      </React.Fragment>;
    case "typography": return <Typography />
    case "color-palette": return <React.Fragment>
        <div className="flex h-100">
          <Colors />
        </div>
      </React.Fragment>
    case "templates": return <React.Fragment>
        <div className="flex h-100">
          <Templates />
        </div>
      </React.Fragment> 
    default: return <Redirect to={'/cocreate/library/images'} />
  }
}

const Library: FC<any> = ({match,userPermissions}) => {
  const { params: {librarytype} } = match;
  
  const checkPermission = (type:string) => {
    if(type === "typography"){
      return userPermissions.indexOf('view-typography') > -1
    }else if(type === "color-palette"){
      return userPermissions.indexOf('view-brand-colors') > -1
    }else{
      return userPermissions.indexOf('view-all-assets') > -1 || userPermissions.indexOf('view-banner-library') > -1
    }
    return true;
  }

  const SuspenseComponent = () => {
    const [pageCount,setPageCount]=useState(0)
    useEffect(() => {
      if(localStorage.getItem('logged-in')===null){
        localStorage.setItem('logged-in',JSON.stringify('true'));
        
      }else{
        // If this in not first login then skip onboarding part
        setPageCount(2)
      }
    }, []); 
    return (
      <>
      { pageCount === 2 ? 
        <div className="library-wrap">
          <LeftNavBar />
          <div className="flex-column">
            {checkPermission(librarytype) ? getContent(librarytype):
              <PermissionNotAllowed title="You do not have access to this feature as per your role" />}
          </div>
        </div>
      
      : 
      <div className="library-welcome-wrap">
        <LibraryOnboarding />
      </div>
    }
    </>
    );
  }

  return (
    <div className="aiquire-library-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default withRouter(Library);

