import { getImage } from './FabricLoaders/objectGetters';
import { getDefaultProps } from '../../FabricCanvasContext/utils';
import { NODE_TYPE } from './FabricLoaders/node-type';
import { IEvent } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import AiTemplates1 from "./aiTemplates";
import AiHsbcTemplates from "./aiHsbcTemplates";
import aiAxisTemplates from "./aiAxisTemplate";
export const setActiveObject = (
  setActiveFabricObjectType: Function,
  setActiveObject: Function,
  setActiveCanvasId: Function,
  id: string,
  activeCanvasId: string
) => {
  return (e: fabric.IEvent) => {
    setActiveFabricObjectType(e.target?.type || null);
    setActiveObject(e.target);
    if (id === activeCanvasId) return;
    setActiveCanvasId(id);
  };
};

export const replaceImage = (img: fabric.Image, src: string) => {
  const imageElem = new Image(),
    width = img.getScaledWidth();
  imageElem.onload = () => {
    img.setElement(imageElem);
    img.set({
      scaleX: width / imageElem.width,
      scaleY: width / imageElem.width,
    });
    img.setCoords();

    img.canvas?.requestRenderAll();
  };
  imageElem.src = src;
};

export const handleDrop = (e: IEvent, canvas: fabric.Canvas, src: string) => {
  // if (e.target && e.target?.type === 'image') {
  //   replaceImage(e.target as fabric.Image, src);
  //   return;
  // }
  const pos = canvas.getPointer(e.e);
  getImage(
    getDefaultProps(pos.x, pos.y, {
      exportedAsset: src,
      subType: NODE_TYPE.IMAGE,
      name: 'Image',
    })
  ).then(image => {
    canvas.add(image as fabric.Image);
    canvas.requestRenderAll()
  });
};


export const getHeightWeight = (hashStr: string) => {
  const hashSplitArr = hashStr.split('#');
  const [w, h] = hashSplitArr[1]?.split('X').map((i) => parseInt(i, 10))
  return {
    w: w || 1000,
    h: h || 1000,
  };
}

export const CUSTOM_PROPS = [
  'svgUid',
  'id',
  'layerType',
  'isSvg',
  'exportedAsset',
  'cropX',
  'cropY',
  'cropWidth',
  'cropHeight',
  'naturalWidth',
  'naturalHeight',
  'src',
  'elements',
  'filters',
  'name',
  'isCustomFont',
  'isNameEdited',
  'isBackground',
];
export const getTemplatesByBrandOwner = (orgId) => {
  let templates = AiTemplates1
  switch (orgId) {
    case "66ea5ca5ee915e738ae65959":
      templates = AiHsbcTemplates
      break;
    case "66e41f7e5f76671d90b04a38":
      templates = aiAxisTemplates
      break;
    default:
      templates = AiTemplates1

  }
  return templates;
}