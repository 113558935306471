import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, withRouter } from "react-router-dom";
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductList,
  setNavBarProductList,
  logout,
  setSelectedProductId,
  setShowBrandAdmin,
  setShowProductAdmin,
  setPendingApprovalCount,
  setOrgId,
  setBrandId,
  setUserPermissions,
  setBrandPermissions,
  setFolderBreadcrumb,
  setUploadedBanners,
  setProjectsForReadyCheck
} from '../../store/actions';
import { setExportedProjects } from "../../store/actions/export";
import { RolesState, AuthState, ApprovalState, FolderState, HyperProjectFoldersState, Folder } from '../../store/types';
import { permissionDeniedClick } from '../../utilities/common-function';
import useWebSocket from '../shared/hooks/useWebSocket';
import { WEBSOCKET_ENDPOINT } from '../../utilities/paths';
import { getUrlProperties } from '../../utilities/common-function';
import { Dropdown, Menu, MenuItem, Button, MenuItemGroup, Tooltip } from 'pyxis-ui-kit';
import Avatar from '../shared/Avatar';
import { FolderAndFileUploadAlert } from '../DashboardSection/FolderAndFileUploadAlert';
import { enableEdit } from '../../utilities/contants';
import moment from 'moment';
import pyxisLogo from '../../assets/svg/pyxisLogo.svg';
import agencyFileDropIcon from "../../assets/svg/agencyFileDropIcon.svg";
import { trackSignOut } from '../../utilities/services/mixpanel';
import { Drawer } from '@material-ui/core';
import AskAnything from '../../Pages/AskAnything';
const NavBar: React.FunctionComponent = (props: any) => {
  // const state = useSelector((state: { roles: RolesState, authentication: AuthState }) => ({ ...state.roles, ...state.authentication }));
  const state = useSelector(
    (state: { folders: FolderState, hyperProject: HyperProjectFoldersState, roles: RolesState, authentication: AuthState }) =>
      ({ ...state.folders, ...state.hyperProject, ...state.roles, ...state.authentication })
  );
  const { folders, activeFolderId, userPermissions, selectedProductId, navProductList, userData, showBrandAdmin, showProductAdmin, token,brandPermissions } = state;
  const pendingApprovalCount = useSelector((state: { approval: ApprovalState }) => (state.approval.pendingApprovalCount));
  const optionsRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const workspaceRef = useRef(null);
  const [openWorkSpace, setOpenWorkSpace] = useState(false);
  const pathname = (props?.location?.pathname) || "";
  const dispatch = useDispatch();
  // const { navProductList, userData, selectedProductId, userPermissions, showBrandAdmin, showProductAdmin, token, accessToken } = state;
  const selectedProductName = selectedProductId && navProductList.length > 0 ? navProductList.find((obj: any) => obj._id === selectedProductId)?.name : "No Products";
  const [client, setClientActions] = useWebSocket(`${WEBSOCKET_ENDPOINT}/pending-approval/product/${selectedProductId}?token=${token}`)
  const [trialDate, setTrialDate] = useState<any>(null);

  const removeSearchParamsFromRoute = useCallback(() => {
    const { baseUrl, search } = getUrlProperties();
    if (search)
      window.history.pushState({ path: baseUrl }, '', baseUrl);
  }, [])

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [drawerVisible, setDrawerVisible] = useState('');

  const changeActiveFolderInRouteForward = useCallback((id: number) => {
    const { baseUrl, search } = getUrlProperties();
    let newurl = baseUrl + (search ? ("?" + search) : "")
      + (search.length === 0 ? '?folder=' + id : '-' + id);
    window.history.pushState({ path: newurl }, '', newurl);
  }, [])

  useEffect(() => {
    setLoader(true)
    dispatch(fetchProductList({}, (response: any, error: boolean) => {
      setLoader(false)
      if (!error && response.data.organisations && response.data.organisations[0] && response.data.organisations[0].trialPeriodEndDate) {
        const trialPeriodEndDate = moment(response.data.organisations[0].trialPeriodEndDate);
        setTrialDate(trialPeriodEndDate);
      }
      if (!error && response.data.products) {
        const productAdminOrManager: any = response.data.products.find((p: any) => p.role === 'admin' || p.role === 'product-admin' || p.role === 'product-manager');
        const brandAdmin: any = response.data.brands.find((b: any) => b.role === 'admin' || b.role === 'brand-admin');
        const hasProductAdminOrManager: boolean = !!productAdminOrManager;
        const hasBrandAdmin: boolean = !!brandAdmin;
        const organisation = hasBrandAdmin ? brandAdmin.organisation : hasProductAdminOrManager ? productAdminOrManager.organisation : (response.data.products.length > 0 ? response.data.products[0].organisation : null);
        if (hasProductAdminOrManager && hasBrandAdmin || hasBrandAdmin && !hasProductAdminOrManager) {
          dispatch(setShowBrandAdmin(true))
          if (showProductAdmin)
            dispatch(setShowProductAdmin(false))
        }
        if (hasProductAdminOrManager && !hasBrandAdmin) {
          dispatch(setShowProductAdmin(true))
          if (showBrandAdmin)
            dispatch(setShowBrandAdmin(false))
        }
        if (!hasProductAdminOrManager && !hasBrandAdmin) {
          if (showBrandAdmin)
            dispatch(setShowBrandAdmin(false))
          if (showProductAdmin)
            dispatch(setShowProductAdmin(false))
        }
        dispatch(setNavBarProductList(response.data.products));
        if (organisation)
          dispatch(setOrgId(organisation))
        if (hasBrandAdmin) {
          dispatch(setBrandId(brandAdmin._id))
        } else {
          dispatch(setBrandId(hasProductAdminOrManager ? productAdminOrManager.brand : null))
        }
        if (response.data.products.length > 0 && !selectedProductId) {
          dispatch(setSelectedProductId(response.data.products[0]._id))
        } else if (response.data.products.length === 0) {
          dispatch(setSelectedProductId("none"));
        }
      }
    }))

    if (client) {
      setClientActions((val: any) => ({
        ...val,
        onError: (err: any) => { console.log(err) },
        onRecieve: (data: any) => {
          dispatch(setPendingApprovalCount(data.count))
        }
      }))
    }
  }, [])

  useEffect(() => {
    let hasProductAdminOrManager: boolean = !!navProductList.find((p: any) => p.role === 'admin' || p.role === 'product-admin' || p.role === 'product-manager');
    if (!showBrandAdmin && hasProductAdminOrManager && !showProductAdmin) {
      dispatch(setShowProductAdmin(true))
    } else if (!showBrandAdmin && !hasProductAdminOrManager && showProductAdmin) {
      dispatch(setShowProductAdmin(false))
    }
  }, [navProductList.length])

  const hideSelectProduct: boolean = pathname.includes('/editor/')
    || pathname.includes('/set/') || pathname === '/cocreate/members'
    || pathname === '/cocreate/products' || pathname === '/cocreate/roles'
    || pathname === '/cocreate/banner-detail/' || pathname === '/cocreate/creative-automation/detail/'
    || pathname.includes('/cocreate/creative-automation/layers') || pathname.includes('/cocreate/creative-automation/select');

  const currentDate = moment(new Date());
  const days: any = trialDate?.diff(currentDate, 'days') > 0 ? trialDate?.diff(currentDate, 'days') : 0;

  const [visible, setVisible] = useState(false);
  const [isAskAnythingOpen, setAskAnythingOpen] = useState(false)
  const handleMenuClick = (e: any) => {
    setVisible(false);
  };

  const handleVisibleChange = (flag: any) => {
    setVisible(flag);
  };

  const handleAgencyClick = useCallback(() =>{
    props.history.push('/cocreate/agency-files')
  },[props.history])

  

  const workspaceMenu = (
    navProductList.length > 1 ? <Menu className="dropdown-container" onClick={handleMenuClick}>
      <MenuItemGroup className="workspace-group" title="Switch to">
        {navProductList.map((product: any, index: number) => ((product._id !== selectedProductId) &&
          <MenuItem className="dropdown-item dropdown-product-item" onClick={() => {
            setOpenWorkSpace(false);
            removeSearchParamsFromRoute()
            dispatch(setSelectedProductId(product._id))
          }}
            key={product._id}>
            <span>
              {product.name}
            </span>
          </MenuItem>
        ))}
      </MenuItemGroup>
    </Menu> : <></>
  );

  const createMenu = (
    <Menu className="create-container" style={{ width: 'auto', display: 'flex' }}>
      <MenuItemGroup className="create-container-items" title="Library">
        <MenuItem className={userPermissions.indexOf('create-new-banners') > -1 ? "" : "permission-denied-lock"} onClick={() => {
          if (userPermissions.indexOf('create-new-banners') > -1) {
            setDrawerVisible('globalFolder');
          } else {
            permissionDeniedClick();
          }
        }}>
          <svg width="24" height="24" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="4" fill="#EFF1FC" />
            <path d="M31 28C31 28.5304 30.7893 29.0391 30.4142 29.4142C30.0391 29.7893 29.5304 30 29 30H13C12.4696 30 11.9609 29.7893 11.5858 29.4142C11.2107 29.0391 11 28.5304 11 28V14C11 13.4696 11.2107 12.9609 11.5858 12.5858C11.9609 12.2107 12.4696 12 13 12H18L20 15H29C29.5304 15 30.0391 15.2107 30.4142 15.5858C30.7893 15.9609 31 16.4696 31 17V28Z" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 20V26" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 23H24" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div className="item-container">
            <span className="head">Create new folder</span>
            {/* <span className="sub-head">Amet minim mollit non deserunt ullamco.</span> */}
          </div>
        </MenuItem>
        <MenuItem className={userPermissions.indexOf('create-new-banners') > -1 ? "" : "permission-denied-lock"} onClick={() => {
          if (userPermissions.indexOf('create-new-banners') > -1) {
            setDrawerVisible('globalUploadFile');
          } else {
            permissionDeniedClick();
          }
        }}>
          <svg width="24" height="24" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="4" fill="#EFF1FC" />
            <path d="M21 12.001L21 26.001" stroke="#152370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M30 26C30 28.209 28.209 30 26 30H16C13.791 30 12 28.209 12 26" stroke="#152370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.999 17.001L21 12L26 17.001" stroke="#152370" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div className="item-container">
            <span className="head">Upload files</span>
            {/* <span className="sub-head">Amet minim mollit non deserunt ullamco.</span> */}
          </div>
        </MenuItem>
      </MenuItemGroup>
      <MenuItemGroup className="create-container-items" title="Design">
        <MenuItem className={userPermissions.indexOf('create-new-banners') > -1 ? "" : "permission-denied-lock"}
          onClick={() => {
            if (userPermissions.indexOf('create-new-banners') > -1) {
              history.push('/cocreate/create');
            } else {
              permissionDeniedClick();
            }
          }}>
          <svg width="24" height="24" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="4" fill="#EFF1FC" />
            <path d="M29 14V12" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28 13H30" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8705 29.705L12.2955 28.13C11.9015 27.736 11.9015 27.098 12.2955 26.705L21.8705 17.13C22.2645 16.736 22.9025 16.736 23.2955 17.13L24.8705 18.705C25.2645 19.099 25.2645 19.737 24.8705 20.13L15.2955 29.705C14.9025 30.098 14.2635 30.098 13.8705 29.705Z" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.5097 22.31L19.6797 19.48" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M30 24H28" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M29 23V25" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 12V14" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 13H17" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div className="item-container">
            <span className="head">Create new design project</span>
            {/* <span className="sub-head">Amet minim mollit non deserunt ullamco.</span> */}
          </div>
        </MenuItem>
      </MenuItemGroup>
      <MenuItemGroup className="create-container-items" title="Creative Automation">
        <MenuItem className={userPermissions.indexOf('create-new-banners') > -1 ? "" : "permission-denied-lock"}
          onClick={() => {
            if (userPermissions.indexOf('create-new-banners') > -1) {
              history.push('/cocreate/creative-automation-choose-design');
            } else {
              permissionDeniedClick();
            }
          }}>
          <svg width="24" height="24" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="4" fill="#EFF1FC" />
            <path d="M21 11L11 16L21 21L31 16L21 11Z" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11 26L21 31L31 26" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11 21L21 26L31 21" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div className="item-container">
            <span className="head">Create new project</span>
            {/* <span className="sub-head">Amet minim mollit non deserunt ullamco.</span> */}
          </div>
        </MenuItem>
      </MenuItemGroup>
    </Menu>
  );

  const profileMenu = (
    <Menu className="dropdown-container" style={{ width: 'auto' }}>
      <MenuItem className="dropdown-item" onClick={() => {
        setOpen(false);
        props.history.push('/cocreate/view-profile')
      }}>
        {/* <div className="profile"> */}
        {/* <Avatar
            size="small"
            initials={userData ? userData.firstName[0] : ""}
            showInitials={!!(userData && !userData.profilePicture)}
            backgroundColor={userData?.profileColour}
            src={userData ? userData.profilePicture : ""}
          />
          <span className="details">
            <h2>{userData && (userData.firstName + " " + userData.lastName)}</h2>
            <p>{userData && userData.emailId}</p>
            <p className="view" onClick={() => {
              setOpen(false);
              props.history.push('/cocreate/view-profile')
            }}>View Profile</p>
          </span> */}
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#4253A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#4253A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>Profile settings</span>
        {/* </div> */}
      </MenuItem>
      <MenuItem className={userPermissions.indexOf('view-approval-workflow') > -1 ? "dropdown-item" : "dropdown-item permission-denied-lock"} onClick={() => {
        setOpen(false)
        if (userPermissions.indexOf('view-approval-workflow') > -1) {
          props.history.push('/cocreate/workflows')
        } else {
          permissionDeniedClick();
        }
      }}>
        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.99984 1.3335V5.3335H7.33317V7.3335H3.33317C2.59317 7.3335 1.99984 7.92683 1.99984 8.66683V10.6668H0.666504V14.6668H4.6665V10.6668H3.33317V8.66683H7.33317V10.6668H5.99984V14.6668H9.99984V10.6668H8.6665V8.66683H12.6665V10.6668H11.3332V14.6668H15.3332V10.6668H13.9998V8.66683C13.9998 7.92683 13.4065 7.3335 12.6665 7.3335H8.6665V5.3335H9.99984V1.3335H5.99984Z" fill="#515151" />
        </svg> */}
        <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.125 3.625H8V2.75H7.125V1H1V5.375H7.125V3.625ZM6.25 4.5H1.875V1.875H6.25V4.5Z" fill="#4253A9" />
          <path d="M8.875 12.375H8V13.25H8.875V15H15V10.625H8.875V12.375ZM9.75 11.5H14.125V14.125H9.75V11.5Z" fill="#4253A9" />
          <path d="M14.125 5.59375C14.125 6.68105 13.2436 7.5625 12.1562 7.5625H11.9375V8.4375H12.1562C13.7268 8.4375 15 7.16432 15 5.59375C15 4.02318 13.7268 2.75 12.1562 2.75H10.625V3.625H12.1562C13.2431 3.6262 14.1238 4.50695 14.125 5.59375Z" fill="#4253A9" />
          <path d="M9.75 2.75H8.875V3.625H9.75V2.75Z" fill="#4253A9" />
          <path d="M9.93112 8.43747H11.0625V7.56247H9.93112L8 5.63135L6.06888 7.56247H4.9375V8.43747H6.06888L8 10.3686L9.93112 8.43747ZM8 6.8686L9.13138 7.99997L8 9.13135L6.86862 7.99997L8 6.8686Z" fill="#4253A9" />
          <path d="M1.875 10.4062C1.875 9.31893 2.75643 8.4375 3.84375 8.4375H4.0625V7.5625H3.84375C2.27318 7.5625 1 8.83568 1 10.4062C1 11.9768 2.27318 13.25 3.84375 13.25H5.375V12.375H3.84375C2.75695 12.3738 1.8762 11.4931 1.875 10.4062Z" fill="#4253A9" />
          <path d="M7.125 12.375H6.25V13.25H7.125V12.375Z" fill="#4253A9" />
        </svg>
        <span>Approval workflows</span>
      </MenuItem>

      {!!brandPermissions?.includes("add-delete-product") ?
      <MenuItem className={"dropdown-item"} onClick={handleAgencyClick}>
        <img src={agencyFileDropIcon} alt='agencyFileDropIcon' />
        <span>Agency File Drop</span>
      </MenuItem> 
       : <></> 
    }
      <MenuItem className={!showBrandAdmin && !showProductAdmin ? "dropdown-item permission-denied-lock" : "dropdown-item"} onClick={() => {
        setOpen(false)
        if (showBrandAdmin) {
          props.history.push('/cocreate/members')
        } else if (!showBrandAdmin && showProductAdmin) {
          props.history.push('/cocreate/products')
        } else {
          permissionDeniedClick()
        }
      }}>
        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6668 11.3332V12.6665H1.3335V11.3332C1.3335 11.3332 1.3335 8.66654 6.00016 8.66654C10.6668 8.66654 10.6668 11.3332 10.6668 11.3332ZM8.3335 4.99987C8.3335 4.53838 8.19665 4.08726 7.94026 3.70354C7.68387 3.31983 7.31945 3.02076 6.89309 2.84415C6.46673 2.66755 5.99757 2.62134 5.54495 2.71137C5.09233 2.80141 4.67657 3.02363 4.35025 3.34996C4.02392 3.67628 3.8017 4.09204 3.71166 4.54466C3.62163 4.99728 3.66784 5.46644 3.84444 5.8928C4.02105 6.31916 4.32012 6.68358 4.70383 6.93997C5.08755 7.19636 5.53867 7.33321 6.00016 7.33321C6.619 7.33321 7.21249 7.08737 7.65008 6.64979C8.08766 6.2122 8.3335 5.61871 8.3335 4.99987ZM10.6268 8.66654C11.0367 8.9837 11.372 9.38683 11.6093 9.84754C11.8465 10.3083 11.98 10.8154 12.0002 11.3332V12.6665H14.6668V11.3332C14.6668 11.3332 14.6668 8.91321 10.6268 8.66654ZM10.0002 2.66654C9.54129 2.66398 9.09251 2.80118 8.7135 3.05987C9.11846 3.6257 9.3362 4.30406 9.3362 4.99987C9.3362 5.69568 9.11846 6.37405 8.7135 6.93987C9.09251 7.19857 9.54129 7.33576 10.0002 7.33321C10.619 7.33321 11.2125 7.08737 11.6501 6.64979C12.0877 6.2122 12.3335 5.61871 12.3335 4.99987C12.3335 4.38103 12.0877 3.78754 11.6501 3.34996C11.2125 2.91237 10.619 2.66654 10.0002 2.66654Z" fill="#515151" />
        </svg> */}
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 18C3 15.791 4.791 14 7 14H11C13.209 14 15 15.791 15 18" stroke="#4253A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16 4.65088C17.381 4.65088 18.5 5.76988 18.5 7.15088C18.5 8.53188 17.381 9.65088 16 9.65088" stroke="#4253A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11.4035 4.99613C12.7317 6.3243 12.7317 8.4777 11.4035 9.80587C10.0754 11.134 7.92195 11.134 6.59378 9.80587C5.26561 8.4777 5.26561 6.3243 6.59378 4.99613C7.92195 3.66796 10.0754 3.66796 11.4035 4.99613" stroke="#4253A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M17 13C19.209 13 21 14.791 21 17" stroke="#4253A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>Users & Roles Management</span>
      </MenuItem>
      <MenuItem className="dropdown-item" onClick={() => {
        setOpen(false)
        trackSignOut(userData.emailId)
        dispatch(setSelectedProductId(null))
        dispatch(setBrandId(null))
        dispatch(setOrgId(null))
        dispatch(setUserPermissions([]))
        dispatch(setBrandPermissions([]))
        dispatch(setUploadedBanners([]))
        dispatch(setExportedProjects([]))
        dispatch(setProjectsForReadyCheck([]))
        dispatch(logout())
      }}>
        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6667 11.3335V9.3335H6V6.66683H10.6667V4.66683L14 8.00016L10.6667 11.3335ZM9.33333 1.3335C9.68696 1.3335 10.0261 1.47397 10.2761 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016H9.33333V2.66683H3.33333V13.3335H9.33333V12.0002H10.6667V13.3335C10.6667 13.6871 10.5262 14.0263 10.2761 14.2763C10.0261 14.5264 9.68696 14.6668 9.33333 14.6668H3.33333C2.97971 14.6668 2.64057 14.5264 2.39052 14.2763C2.14048 14.0263 2 13.6871 2 13.3335V2.66683C2 2.31321 2.14048 1.97407 2.39052 1.72402C2.64057 1.47397 2.97971 1.3335 3.33333 1.3335H9.33333Z" fill="#515151" />
        </svg> */}
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#4253A9" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16 17L21 12L16 7" stroke="#4253A9" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 12H9" stroke="#152370" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>Log out</span>
      </MenuItem>
    </Menu>
  );

  const handleAskAnything = () => {

  }

  return <div className="aiquire-navbar noselect">
    <div className="flex">
      <span className="app-dropdown">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1V5C6 5.55228 5.55228 6 5 6H1C0.447715 6 0 5.55228 0 5V1Z" fill="#152370" />
          <path d="M18 1C18 0.447715 18.4477 0 19 0H23C23.5523 0 24 0.447715 24 1V5C24 5.55228 23.5523 6 23 6H19C18.4477 6 18 5.55228 18 5V1Z" fill="#152370" />
          <path d="M9 1C9 0.447715 9.44772 0 10 0H14C14.5523 0 15 0.447715 15 1V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V1Z" fill="#152370" />
          <path d="M0 10C0 9.44772 0.447715 9 1 9H5C5.55228 9 6 9.44772 6 10V14C6 14.5523 5.55228 15 5 15H1C0.447715 15 0 14.5523 0 14V10Z" fill="#152370" />
          <path d="M18 10C18 9.44772 18.4477 9 19 9H23C23.5523 9 24 9.44772 24 10V14C24 14.5523 23.5523 15 23 15H19C18.4477 15 18 14.5523 18 14V10Z" fill="#152370" />
          <path d="M9 10C9 9.44772 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V14C15 14.5523 14.5523 15 14 15H10C9.44772 15 9 14.5523 9 14V10Z" fill="#152370" />
          <path d="M0 19C0 18.4477 0.447715 18 1 18H5C5.55228 18 6 18.4477 6 19V23C6 23.5523 5.55228 24 5 24H1C0.447715 24 0 23.5523 0 23V19Z" fill="#152370" />
          <path d="M18 19C18 18.4477 18.4477 18 19 18H23C23.5523 18 24 18.4477 24 19V23C24 23.5523 23.5523 24 23 24H19C18.4477 24 18 23.5523 18 23V19Z" fill="#152370" />
          <path d="M9 19C9 18.4477 9.44772 18 10 18H14C14.5523 18 15 18.4477 15 19V23C15 23.5523 14.5523 24 14 24H10C9.44772 24 9 23.5523 9 23V19Z" fill="#152370" />
        </svg>
      </span>
      <Link className="logo" to='/'>
        <img src={pyxisLogo} />
        {/* <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.0395 25.9208H14.0791V13.9604C14.0791 7.3658 19.4449 2 26.0395 2C32.6341 2 37.9999 7.3658 37.9999 13.9604C37.9999 20.555 32.6341 25.9208 26.0395 25.9208ZM18.4004 21.6036H26.0395C30.2543 21.6036 33.6827 18.1752 33.6827 13.9604C33.6827 9.74559 30.2543 6.32131 26.0395 6.32131C21.8247 6.32131 18.3963 9.74969 18.3963 13.9645V21.6036H18.4004Z" fill="url(#paint0_linear)" />
          <path d="M10.2002 37.9999C5.67823 37.9999 2 34.3217 2 29.7997C2 25.2777 5.67823 21.6035 10.2002 21.6035H18.4005V29.8038C18.4005 34.3217 14.7223 37.9999 10.2002 37.9999ZM10.2002 25.9207C8.05803 25.9207 6.31721 27.6615 6.31721 29.8038C6.31721 31.946 8.05803 33.6868 10.2002 33.6868C12.3425 33.6868 14.0833 31.946 14.0833 29.8038V25.9207H10.2002Z" fill="url(#paint1_linear)" />
          <path d="M18.4005 25.9209H14.0833V29.8039H18.4005V25.9209Z" fill="url(#paint2_linear)" />
          <path d="M18.4005 17.7207H14.0833V21.6037H18.4005V17.7207Z" fill="url(#paint3_linear)" />
          <defs>
            <linearGradient id="paint0_linear" x1="6.63841" y1="36.2948" x2="33.3386" y2="5.56076" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2BB3F4" />
              <stop offset="0.5" stopColor="#3F64FA" />
              <stop offset="1" stopColor="#2BB3F4" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="5.45364" y1="35.2653" x2="32.1538" y2="4.53134" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2BB3F4" />
              <stop offset="0.5" stopColor="#3F64FA" />
              <stop offset="1" stopColor="#2BB3F4" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="16.2403" y1="29.8026" x2="16.2403" y2="25.9204" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2BB3F4" stopOpacity="0" />
              <stop offset="1" stopColor="#3F42B7" />
            </linearGradient>
            <linearGradient id="paint3_linear" x1="16.2403" y1="17.721" x2="16.2403" y2="21.6031" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2BB3F4" stopOpacity="0" />
              <stop offset="1" stopColor="#3F42B7" />
            </linearGradient>
          </defs>
        </svg> */}
      </Link>
      {navProductList.length > 0 &&
        <Dropdown trigger={['click']} overlay={workspaceMenu} onVisibleChange={handleVisibleChange}
          visible={visible} getPopupContainer={trigger => trigger.parentNode as HTMLElement}>
          <Tooltip title="Select workspace" getPopupContainer={trigger => trigger.parentNode as HTMLElement} content={
            <div className="dropdown" style={{ visibility: hideSelectProduct ? "hidden" : "visible" }} ref={workspaceRef} onClick={() => setOpenWorkSpace(true)}>
              {loader ?
                <span><FontAwesomeIcon icon={faSpinner} spin /></span> :
                <span className="text">{navProductList.length ? selectedProductName : "No Products"}</span>}
              {navProductList.length > 1 && <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.41 8.58008L12 13.1701L16.59 8.58008L18 10.0001L12 16.0001L6 10.0001L7.41 8.58008Z" fill="black" />
                </svg>
              </span>}
            </div>
          }></Tooltip>
        </Dropdown>
      }
    </div>
    <div className="flex">
      <Menu className="navbar-tabs" mode="horizontal">
        {/* <div className="navbar-tabs"> */}

        {/* create tab start */}
        {/* <Link 
            to='/cocreate/create'
            onClick={(e)=>{if(userPermissions.indexOf('create-new-banners') === -1){ e.preventDefault() }}}
            className={userPermissions.indexOf('create-new-banners') > -1 ? pathname === '/cocreate/create' ? "tab active" : "tab": "tab permission-denied-lock"} 
          >
            <span className="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="black"/>
              </svg>  
            </span>
            <span className="name">
              Create  
            </span>
          </Link> */}
        {/* <Link 
          to='/cocreate/create'
          onClick={(e)=>{if(userPermissions.indexOf('create-new-banners') === -1 || !isEditEnabled){ e.preventDefault() }}}
          className={userPermissions.indexOf('create-new-banners') > -1 && isEditEnabled ? pathname === '/cocreate/create' ? "tab active intercom-create" : "tab intercom-create": "tab permission-denied-lock"} 
        >
          <span className="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="black"/>
            </svg>  
          </span>
          <span className="name">
            Create  
          </span>
        </Link> */}
        {/* create tab end */}

        {/* designs tab start */}
        <MenuItem key='design'>
          <Link to='/cocreate/homepage'
            onClick={(e) => { if (userPermissions.indexOf('view-banner-library') === -1 && userPermissions.indexOf('view-all-assets') === -1) { e.preventDefault() } }}
            className={userPermissions.indexOf('view-banner-library') > -1 || userPermissions.indexOf('view-all-assets') > -1 ? ((pathname === '/' || pathname === '/cocreate/homepage' || pathname === '/cocreate/design-onboarding' || pathname.includes('/cocreate/editor') || pathname.includes('/cocreate/set') || pathname === '/cocreate/create' || pathname === '/cocreate/AIDesigner/simple_prompting' || pathname === '/cocreate/AIDesigner/advance_prompting' || (params.get('project_type') === 'project' && pathname.includes('/cocreate/advance-search'))) ? "tab active" : "tab") : "tab permission-denied-lock"}>
            {/* <span className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 3V9H21V3H13ZM13 21H21V11H13V21ZM3 21H11V15H3V21ZM3 13H11V3H3V13Z" fill="black"/>
                </svg>
              </span> */}
            <span className="name">
              Design
            </span>
          </Link>
        </MenuItem>
        {/* designs tab end */}

        {/* brand library tab start */}
        <MenuItem key='library'>
          <Link to='/cocreate/library/images'
            onClick={(e) => {
              if (userPermissions.indexOf('view-banner-library') === -1 && userPermissions.indexOf('view-all-assets') === -1) {
                e.preventDefault()
              }
              else {
                dispatch(setFolderBreadcrumb([]))
              }
            }}
            className={userPermissions.indexOf('view-banner-library') > -1 || userPermissions.indexOf('view-all-assets') > -1 ? pathname.indexOf('/cocreate/library/') === 0 ? "tab active" : "tab" : "tab permission-denied-lock"}>
            {/* <span className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 16V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2H8C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H20C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16ZM11 12L13.03 14.71L16 11L20 16H8L11 12ZM2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18V20H4V6" fill="black"/>
                </svg>
              </span> */}
            <span className="name">
              Library
            </span>
          </Link>
        </MenuItem>
        {/* brand library tab end */}

        {/* hyper tab start */}
        <MenuItem key='creative-automation'>
          <Link to='#'
            className={pathname.includes('/cocreate/creative-automation') || (params.get('project_type') === 'hpproject' && pathname.includes('/cocreate/advance-search')) ? "tab active" : "tab"}
            style={{ cursor: "not-allowed", pointerEvents: 'none', opacity: '0.5' }}
          >
            {/* <span className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 18H12V12.21C11.34 12.82 10.47 13.2 9.5 13.2C7.46 13.2 5.8 11.54 5.8 9.5C5.8 8.5187 6.18982 7.57759 6.88371 6.88371C7.57759 6.18982 8.5187 5.8 9.5 5.8C11.54 5.8 13.2 7.46 13.2 9.5C13.2 10.47 12.82 11.34 12.21 12H18V18ZM19 3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 3.89 20.1 3 19 3Z" fill="black"/>
                </svg>
              </span> */}
            <span className="name">
              Creative Automation
            </span>
          </Link>
        </MenuItem>
        {/* hyper tab end */}

        {/* creative intelligence tab start */}
        {/* {accessToken && <a 
            href={`${REPORT_REDIRECT_ORIGIN}/custom-auth/?token=${accessToken}&path=creative-intelligence`}
            target="_blank"
            className="tab"
          >
            <span className="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 21H2V3H4V19H6V17H10V19H12V16H16V19H18V17H22V21ZM18 14H22V16H18V14ZM12 6H16V9H12V6ZM16 15H12V10H16V15ZM6 10H10V12H6V10ZM10 16H6V13H10V16Z" fill="black"/>
              </svg>
            </span>
            <span className="name">
              Creative Intelligence
            </span>
          </a>} */}
        {/* creative intelligence end */}

        {/* </div> */}
      </Menu>
    </div>
    <div className="flex">
      {(trialDate && days > 0) && <Tooltip
        title={`Your trial expires on ${trialDate.format('Do MMMM')}`}
        content={
          <span className="trial-period-text">
            {days} {days === 1 ? ' day' : ' days'} left!
          </span>
        }>
      </Tooltip>}

      <Button btnsize="md"
        type="primary"
        className="primary"
        onClick={() => setAskAnythingOpen(true)}>
        <span className={"flex"}>
          Ask Anything
        </span>
      </Button>
      <Tooltip title="Pending for approval" content={
        <Link to='/cocreate/approval/pending'
          onClick={(e) => { if (userPermissions.indexOf('grand-approval') === -1) { e.preventDefault(); permissionDeniedClick() } }}
          className={pathname === '/cocreate/approval/pending' ? "action active" : "action"}>
          <Button className="approval-btn" btnsize="lg" icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.125 3.625H8V2.75H7.125V1H1V5.375H7.125V3.625ZM6.25 4.5H1.875V1.875H6.25V4.5Z" fill="#7335CB" />
            <path d="M8.875 12.375H8V13.25H8.875V15H15V10.625H8.875V12.375ZM9.75 11.5H14.125V14.125H9.75V11.5Z" fill="#7335CB" />
            <path d="M14.125 5.59375C14.125 6.68105 13.2436 7.5625 12.1562 7.5625H11.9375V8.4375H12.1562C13.7268 8.4375 15 7.16432 15 5.59375C15 4.02318 13.7268 2.75 12.1562 2.75H10.625V3.625H12.1562C13.2431 3.6262 14.1238 4.50695 14.125 5.59375Z" fill="#7335CB" />
            <path d="M9.75 2.75H8.875V3.625H9.75V2.75Z" fill="#7335CB" />
            <path d="M9.93112 8.43747H11.0625V7.56247H9.93112L8 5.63135L6.06888 7.56247H4.9375V8.43747H6.06888L8 10.3686L9.93112 8.43747ZM8 6.8686L9.13138 7.99997L8 9.13135L6.86862 7.99997L8 6.8686Z" fill="#7335CB" />
            <path d="M1.875 10.4062C1.875 9.31893 2.75643 8.4375 3.84375 8.4375H4.0625V7.5625H3.84375C2.27318 7.5625 1 8.83568 1 10.4062C1 11.9768 2.27318 13.25 3.84375 13.25H5.375V12.375H3.84375C2.75695 12.3738 1.8762 11.4931 1.875 10.4062Z" fill="#7335CB" />
            <path d="M7.125 12.375H6.25V13.25H7.125V12.375Z" fill="#7335CB" />
          </svg>}>
            <span className={userPermissions.indexOf('grand-approval') > -1 ? "flex" : "flex permission-denied-lock"}>
              Awaiting approval
              {pendingApprovalCount > 0 &&
                <span className="count">
                  {pendingApprovalCount}
                </span>
              }
            </span>
          </Button>
        </Link>
      }>
      </Tooltip>
      {/* <Dropdown overlay={createMenu} trigger={['click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement}>
        <Button className="global-create" type="primary">
          <span>
            <svg width="30" height="30" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="16" y1="10" x2="16" y2="24" stroke="white" stroke-width="2" />
              <line x1="9" y1="17" x2="23" y2="17" stroke="white" stroke-width="2" />
            </svg>
          </span>
        </Button>
      </Dropdown> */}
      <div className="notification">
        {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 19V20H3V19L5 17V11C5 7.9 7.03 5.17 10 4.29C10 4.19 10 4.1 10 4C10 3.46957 10.2107 2.96086 10.5858 2.58579C10.9609 2.21071 11.4696 2 12 2C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4C14 4.1 14 4.19 14 4.29C16.97 5.17 19 7.9 19 11V17L21 19ZM14 21C14 21.5304 13.7893 22.0391 13.4142 22.4142C13.0391 22.7893 12.5304 23 12 23C11.4696 23 10.9609 22.7893 10.5858 22.4142C10.2107 22.0391 10 21.5304 10 21" fill="#20007F"/>
        </svg> */}
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.98 2.64094C11.8026 1.15337 10.5367 0 9.0013 0C7.46596 0 6.2 1.15337 6.02257 2.64094C3.5199 3.77546 1.77908 6.29555 1.77908 9.22222V14.2399L0.108582 17.5494C-0.227141 18.2145 0.25626 19 1.0013 19H6.17201C6.58385 20.1652 7.69509 21 9.0013 21C10.3075 21 11.4188 20.1652 11.8306 19H17.0013C17.7463 19 18.2297 18.2145 17.894 17.5494L16.2235 14.2399V9.22222C16.2235 6.29555 14.4827 3.77546 11.98 2.64094ZM14.3308 14.9286L15.3764 17H2.62623L3.6718 14.9286C3.74234 14.7888 3.77908 14.6345 3.77908 14.478V9.22222C3.77908 6.33807 6.11715 4 9.0013 4C11.8855 4 14.2235 6.33807 14.2235 9.22222V14.478C14.2235 14.6345 14.2603 14.7888 14.3308 14.9286Z" fill="#152370" />
        </svg>
      </div>
      <Dropdown overlay={profileMenu} trigger={['click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement}>
        <div className="options" ref={optionsRef}>
          {/* <div className="circle">
            {userData && userData.firstName[0]}
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6L8 10L13 6" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
          <Avatar
            size="large"
            initials={userData ? userData.firstName[0] : ""}
            showInitials={!!(userData && !userData.profilePicture)}
            backgroundColor={userData?.profileColour}
            src={userData ? userData.profilePicture : ""}
          />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 8.58008L12 13.1701L16.59 8.58008L18 10.0001L12 16.0001L6 10.0001L7.41 8.58008Z" fill="black" />
          </svg>
        </div>
      </Dropdown>
    </div>
    {(drawerVisible === "globalFolder" || drawerVisible === "globalUploadFile") && <FolderAndFileUploadAlert
      isDrawerVisible={drawerVisible === "globalFolder" || drawerVisible === "globalUploadFile"}
      type="file"
      changeActiveFolderInRouteForward={changeActiveFolderInRouteForward}
      projectType="folder"
      drawerVisibleFor={drawerVisible}
      setDrawerVisible={setDrawerVisible}
      isGlobalCreate={true}
    />}
    {<Drawer
      anchor={"bottom"}
      open={isAskAnythingOpen}
      onClose={() => { setAskAnythingOpen(false) }}

      style={{ borderRadius: "30px" }}
    >
      <AskAnything setAskAnythingOpen={setAskAnythingOpen} />
    </Drawer>}
  </div>
}

export default withRouter(NavBar);