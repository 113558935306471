import React, { lazy, Suspense, FC } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import ViewProfile from '../../components/ViewProfile';
import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const AiquireEditProfile: FC<any> = ({userPermissions}) => {

  const SuspenseComponent = () => {
    return (
      <>
        <div className="profile-wrap">
            <ViewProfile />
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-profile-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default AiquireEditProfile;