export const ENABLE_CROP = 'ENABLE_CROP';
export const ENABLE_SVG_CROP = 'ENABLE_SVG_CROP';
export const CROP_DONE = 'CROP_DONE';
export const CANCEL_CROP = 'CANCEL_CROP';
export const RESET_CROP = 'RESET_CROP';

export type EnableCropAction = {
  type: typeof ENABLE_CROP;
  payload: {
    cropEnabled: true;
  };
};

export type EnableSvgCropAction = {
  type: typeof ENABLE_SVG_CROP;
  payload: {
    svgCropEnabled: true;
  };
};

export type CancelCropAction = {
  type: typeof CANCEL_CROP;
  payload: {
    cropEnabled: false;
    svgCropEnabled: false;
  };
};

export type CropDoneAction = {
  type: typeof CROP_DONE;
  payload: {
    cropDone: true;
  };
};

export type ResetCropStateAction = {
  type: typeof RESET_CROP;
  payload: {
    cropEnabled: false;
    cropDone: false;
    svgCropDone: false;
    svgCropEnabled: false;
  };
};

export type CropState = {
  cropEnabled: boolean;
  cropDone: boolean;
  svgCropDone: boolean;
  svgCropEnabled: boolean;
};
