import React, { Suspense, FC, useState, useCallback, useEffect } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import './index.scss';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FolderState, HyperProjectFoldersState } from '../../store/types';
import { ProjectContainer } from '../../components/DashboardSection/ProjectContainer';
import { setSelectedHpProjects } from '../../store/actions';
import Export from '../../components/shared/Export';
import Menu from '../../components/shared/DropdownMenu';
import PublicNavBar from '../../components/Public/PublicNavBar';

const getSuspense = (ComponentRef: FC): JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const TopBar: React.FunctionComponent<{ type: string }> = ({ type }) => {
  const dispatch = useDispatch()
  const { selectedHyperProjects } = useSelector((state: { hyperProject: HyperProjectFoldersState }) => state.hyperProject);

  const { bannerData } = useSelector((state: { folders: FolderState }) => state.folders);
  const checkboxRef = React.useRef<HTMLInputElement>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isPartiallySelected, setIsPartiallySelected] = useState(false);
  const exportRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { location: { search } } = history;
  const searchParam = new URLSearchParams(search);
  const banner = parseInt(searchParam.get("banner") || '0', 10);
  const [menuProps, setMenuProps] = useState<{
    items: [];
    anchorRef: any;
    open: boolean;
    isMove?: boolean;
    isExport?: boolean;
  }>({
    items: [],
    anchorRef: null,
    open: false,
    isMove: false,
    isExport: false
  });

  const handleChange = useCallback(() => {
    if (selectedHyperProjects.length > 0) {
      dispatch(setSelectedHpProjects([]));
    } else {
      if (bannerData && bannerData.banners) {
        dispatch(setSelectedHpProjects(bannerData.banners.map((ele: any) => ele.id)))
      }
    }
  }, [bannerData, selectedHyperProjects]);

  useEffect(() => {
    if (checkboxRef.current && bannerData && bannerData.banners) {
      if (bannerData.banners.length === selectedHyperProjects.length) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
        setIsAllSelected(true);
        setIsPartiallySelected(false);
      } else if (selectedHyperProjects.length > 0) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
        setIsPartiallySelected(true);
        setIsAllSelected(false);
      } else {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
        setIsPartiallySelected(false);
        setIsAllSelected(false);
      }
    }
  }, [bannerData, selectedHyperProjects])


  const handleClose = (event?: React.MouseEvent<EventTarget>) => {
    setMenuProps(obj => ({
      items: [],
      anchorRef: null,
      open: false,
      isMove: false,
      isExport: false
    }));
  };

  return <div className="dashboard-topfilterbar">
    <div className="flex">
      <div className="border-right pr-10">
        <div className="icon" aria-haspopup="true">
          <label className="checkbox-container">
            <input type="checkbox"
              ref={checkboxRef}
              onChange={(e) => {
                e.stopPropagation()
                handleChange();
              }} />
            <span className="checkmark"></span>
            {isPartiallySelected && !isAllSelected && <span className="minus"></span>}
          </label>
        </div>
      </div>
      <div className="ml-10 icon tooltip-primary bottom" aria-haspopup="true" data-tip="Export"
        ref={exportRef}
        onClick={() => setMenuProps((obj: any) => (
          {
            isMove: false,
            items: [],
            anchorRef: exportRef,
            isExport: true,
            open: !obj.open
          }
        ))}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="black"></path>
        </svg>
      </div>
    </div>
    {menuProps.anchorRef && menuProps.isExport && <Menu {...menuProps} handleClose={handleClose}>
      <div>
        <Export projectIds={[banner]} projectType={type} handleClose={handleClose} />
      </div>
    </Menu>}
  </div>
}

const BannerDetail: FC<any> = ({ history }) => {
  const isPublicLink = history.location.pathname.startsWith('/cocreate/public');
  const SuspenseComponent = () => {
    let [show, setShow] = useState(true);
    const { isBannerDetailView } = useSelector((state: { folders: FolderState }) => state.folders);
    return (
      <>
        <div className={!isPublicLink || isBannerDetailView ? "banner-detail-wrap" : "banner-detail-wrap"}>
          <div className="flex-column">
            <div className="flex h-100">
              <ProjectContainer entityType="project" setShowTopBar={setShow} isPublicLink={isPublicLink} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={!isPublicLink ? "aiquire-banner-detail-wrapper" : "cocreate-wrapper"}>
      {isPublicLink && <PublicNavBar />}
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default withRouter(BannerDetail);

