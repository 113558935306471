import React, { useState, useEffect, createRef, useCallback } from 'react';
import BrokenImage from '../../assets/img/BrokenImage.png';
import { imageLoaderParameter, isItImageFileType } from '../../utilities/common-function';
import './ImageMap.scss';
import loader5 from '../../assets/img/loader5.gif';
import AddComment from './AddComment';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalState, FolderState } from '../../store/types';
import { setApprovalComments } from '../../store/actions';
import { getSvg1 } from '../Main/FabricLoaders/objectGetters';
import { IMAGE_BASE_URL, S3_BASE_URL } from '../../utilities/paths';

const ImgMap:any = ({ 
    src, 
    alt, 
    style, 
    className, 
    commentMode, 
    zoom, 
    type, 
    selectedBanner, 
    showAddComment,
    setShowAddComment,
    commentIndex,
    setCommentIndex,
    setReplyCommentIndex,
    setRetainedComments,
    imageDimensions,
    setImageDimensions,
    client,
    setInFocus,
    ...props 
}:any) => {  
    const contextRef = createRef();
    const [loading, setLoading] = useState(true);
    const state = useSelector((state: { approval: ApprovalState }) => state.approval);
    const bannerData = useSelector((state: {folders: FolderState})=>state.folders.bannerData);
    const commentResolution = useSelector((state: { approval: ApprovalState }) => state.approval.comments_resolution);
    const [pinCoordinates, setPinCoordinates] = useState({
        x: 0,
        y: 0
    });
    const { comments } = state;
    const isCommentMode:boolean = commentMode && isItImageFileType(bannerData?.type, bannerData?.file_type) && commentResolution !== 1;
    const dispatch = useDispatch();
    const [placeLeft, setPlaceLeft] = useState(false);
    const [placeTop, setPlaceTop] = useState(false);
    const [coordinatesRatio, setCoordinatesRatio] = useState({
        widthRatio: 0,
        heightRatio: 0
    });

    const handlePinCoordinates = (e:any) => {
        setShowAddComment(!showAddComment);
        setCommentIndex(-1);
        setInFocus(false);
        const node:any = contextRef?.current;
        if(node) {
            let rect = node.getBoundingClientRect();
            let x = Math.abs(Math.round(e.clientX - rect.left));
            let y = Math.abs(Math.round(e.clientY - rect.top));
            if(zoom !== 1) {
                x = Math.round(x / zoom);
                y = Math.round(y / zoom);
            }
            // buffer for right and bottom boundaries
            // x = Math.round(rect.width - x) < 25 ? Math.round(x - 20) : Math.round(x);
            // y = Math.round(rect.height - y) < 35 ? Math.round(y - 35) : Math.round(y);
            // buffer height: 85, buffer width: 220
            setPlaceLeft(((rect.width - x) <= 220) || (x > rect.width / 2));
            setPlaceTop(((rect.height - y) <= 85) || (y > rect.height / 2));
            setPinCoordinates({x, y});
        }
    }

    const createCommentPin = useCallback(() => {
        return (
            <AddComment 
                pinCoordinates={{
                    x: zoom === 1 ? pinCoordinates?.x : pinCoordinates?.x * zoom,
                    y: zoom === 1 ? pinCoordinates?.y : pinCoordinates?.y * zoom
                }}
                data={undefined}
                setShowAddComment={setShowAddComment}
                entityType={type} 
                isNewComment={true}
                selectedBanner={selectedBanner} 
                commentIndex={commentIndex} 
                setReplyCommentIndex={setReplyCommentIndex}
                setCommentIndex={setCommentIndex}
                client={client}
                setInFocus={setInFocus}
                placeLeft={placeLeft}
                placeTop={placeTop}
                zoom={zoom}
                coordinatesRatio={coordinatesRatio}
            />
        );
    }, [pinCoordinates]);

    useEffect(() => {
        setLoading(true);
    }, [src])

    useEffect(() => {
        const node:any = contextRef?.current;
        if(node) {
            for(let i = 0; i < comments.length; i++) {
                if(comments[i].pin_number) {
                    comments[i].scaledXcoordinate = Math.round(comments[i].xcoordinate * zoom);
                    comments[i].scaledYcoordinate = Math.round(comments[i].ycoordinate * zoom);
                }
            }
            dispatch(setApprovalComments(comments));
        }
    }, [zoom, comments]);

    useEffect(() => {
        if(!commentMode) {
            setCommentIndex(-1); 
            setShowAddComment(false);
        }
    }, [commentMode]);

    useEffect(() => {
        const node:any = contextRef?.current;
        if(node && !loading && src) {
            let rect = node.getBoundingClientRect();
            let width = Math.abs(Math.round(rect.width));
            let height = Math.abs(Math.round(rect.height));
            let widthRatio:number = (bannerData?.banners[selectedBanner]?.width) / width;
            let heightRatio:number = (bannerData?.banners[selectedBanner]?.height) / height;
            setImageDimensions({width, height});
            setCoordinatesRatio({widthRatio, heightRatio});
        }
    }, [loading]);

    return <div id="images" style={{position: 'relative', zIndex: 1}}>
        <img 
          className={`${className||""} image-loader ${loading ? 'image-loader-display': 'image-loader-hide'}`} 
          src={loader5}
          style={{objectPosition:'center', width: 'auto', margin: '0 auto'}}
        /> 
        <img 
          id="image"
          className={`${className||""} image-stage-loaded ${loading ? 'image-loading': 'image-loaded'} ${isCommentMode ? 'annotate-mode' : ''}`} 
          ref={contextRef} 
          src={src&&!src.includes("gif")?imageLoaderParameter(src.replace(S3_BASE_URL, IMAGE_BASE_URL),40):src}
          style={{objectFit: 'contain', objectPosition:'top center', ...style||{}}}
          alt={alt}
          ismap={isCommentMode ? "true" : "false"}
          onLoad={() => {
            setLoading(false);
          }}
          onError={(e:any)=>{
            const target = e.target;
            if (src.includes('.svg')) {
              getSvg1(src.replace(S3_BASE_URL, IMAGE_BASE_URL)).then(({ img }: any) => {
                const string = new XMLSerializer().serializeToString(img as any);
                const blob = new Blob([string], { type: 'image/svg+xml' });
                target.src = URL.createObjectURL(blob);
                setLoading(false)
              })
            } else {
              target.src = BrokenImage;
              setLoading(false)
            }
            e.target.onerror = null;
            e.target.style.objectFit='scale-down';
            e.target.style.objectPosition='center center';
          }}
          onClick={isCommentMode ? handlePinCoordinates : null}
            {...props}
        />
        {commentMode && isItImageFileType(bannerData?.type, bannerData?.file_type) && comments.reduce((acc:any, comment:any, index:number) => {
            const xCoordinate:number = comment.xcoordinate / coordinatesRatio?.widthRatio;
            const yCoordinate:number = comment.ycoordinate / coordinatesRatio?.heightRatio;
            return [
                ...acc, comment.pin_number &&
                <AddComment 
                    key={index}
                    pinCoordinates={{
                        x: zoom === 1 ? xCoordinate : comment.scaledXcoordinate / coordinatesRatio?.widthRatio, 
                        y: zoom === 1 ? yCoordinate : comment.scaledYcoordinate / coordinatesRatio?.heightRatio
                    }}
                    data={comment}
                    setShowAddComment={setShowAddComment}
                    entityType={type} 
                    isNewComment={false}
                    selectedBanner={selectedBanner} 
                    commentIndex={commentIndex} 
                    setCommentIndex={setCommentIndex}
                    setReplyCommentIndex={setReplyCommentIndex}
                    client={client}
                    setInFocus={setInFocus}
                    placeLeft={(imageDimensions?.width - xCoordinate <= 220) || (xCoordinate > imageDimensions?.width / 2)}
                    placeTop={(imageDimensions?.height - yCoordinate <= 85) || (yCoordinate > imageDimensions?.height / 2)}
                    zoom={zoom}
                    coordinatesRatio={coordinatesRatio}
                />
            ]
        }, [])}
        {commentMode && showAddComment && <>
            {createCommentPin()}
        </>}
    </div>
}

export default ImgMap;