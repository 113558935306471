import aiDesignerTemplate2 from '../../assets/img/aiDesignerTemplate2.png';
import aiDesignerTemplate3 from '../../assets/img/aiDesignertemplate3.png';
import aiDesignerTemplate4 from '../../assets/img/aiDesignerTemplate4.png';
import aiDesignerAxisTemplate1 from "../../assets/img/aiDesignerAxisTemplate1.jpg";
import aiDesignerAxisTemplate2 from "../../assets/img/aiDesignerAxisTemplate2.jpg";
import aiDesignerAxisTemplate3 from "../../assets/img/aiDesignerAxisTemplate3.jpg";
import aiDesignerHsbcTemplate1 from "../../assets/img/aiDesignerHsbcTemplate1.jpg";
import aiDesignerHsbcTemplate2 from "../../assets/img/aiDesignerHsbcTemplate2.jpg";
import aiDesignerHsbcTemplate3 from "../../assets/img/aiDesignerHsbcTemplate3.jpg";
import SimplePromptingImage from "../../assets/img/SimplePromptingExampleImage.png";
import SimplePromptHsbc from "../../assets/img/SimplePromptHsbc.png";
import SimplePromptHsbcLarge from "../../assets/img/SimplePromptHsbcLarge.png";
export const getImagesByBrandOwner = (orgId) => {
    let images = [aiDesignerTemplate2, aiDesignerTemplate3, aiDesignerTemplate4]
    switch (orgId) {
        case "66ea5ca5ee915e738ae65959":
            // images = [aiDesignerAxisTemplate1, aiDesignerAxisTemplate2, aiDesignerAxisTemplate3]
            images = [aiDesignerHsbcTemplate1, aiDesignerHsbcTemplate2, aiDesignerHsbcTemplate3]
            break;
        case "66e41f7e5f76671d90b04a38":
            // images = [aiDesignerAxisTemplate1, aiDesignerAxisTemplate2, aiDesignerAxisTemplate3]
            images = [aiDesignerAxisTemplate1, aiDesignerAxisTemplate2, aiDesignerAxisTemplate3]
            break;
        default:
            images = [aiDesignerTemplate2, aiDesignerTemplate3, aiDesignerTemplate4]

    }
    return images;
}
export const getCoverByBrandOwner = (orgId, isLarge = false) => {
    let images = SimplePromptingImage
    switch (orgId) {
        case "66ea5ca5ee915e738ae65959":
            // images = [aiDesignerAxisTemplate1, aiDesignerAxisTemplate2, aiDesignerAxisTemplate3]
            images = isLarge ? SimplePromptHsbcLarge : SimplePromptHsbc
            break;
        default:
            images = SimplePromptingImage

    }
    return images;
}
export const getCoverTextByBrandOwner = (orgId) => {
    let text = "An Indian guy, 21 years old, t-shirt attire mild blue toned, feeling happy and accomplished, beaming with joy, standing next to a luxuriou car"
    switch (orgId) {
        case "66ea5ca5ee915e738ae65959":
            // text = [aiDesignerAxisTemplate1, aiDesignerAxisTemplate2, aiDesignerAxisTemplate3]
            text = "Indian man, 21 years old, standing in front of a office interior, hands crossed, feeling happy and pride, looking at the camera and posed, he is wearing a red shirt, white daylighting."
            break;
        default:
            text = "An Indian guy, 21 years old, t-shirt attire mild blue toned, feeling happy and accomplished, beaming with joy, standing next to a luxurious car"

    }
    return text;
}