import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import "./index.scss";
import {
  FolderState,
  HyperProjectFoldersState,
  AuthState,
  RolesState,
  UserData,
} from "../../../store/types";
import {
  fetchProject,
  setProject,
  getUsersData,
  fetchHyperRenderedImages,
  setSelectedHpProjects,
  setBannerView,
  setSelectedHyperSizes,
  getHyperProject,
  setHyperProjectData,
  setIsSentForApproval,
  getApprovalStatus,
  fetchMyApprovalStatus,
  markApprovalStatus,
  deleteHyperRenderedImages,
  setLayersWithAssests,
  fetchLayersWithAssets,
  getTemplateSizes,
  setSelectedImageId,
  setSelectedTextId,
  setSelectedImageLayerId,
  setSelectedTextLayerId,
  setSelectedSizeId,
  setSelectedProject,
} from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ProjectView from "../ProjectView";
import GreyBackground from "../../../assets/img/grey-background.png";
import {
  IMAGE_BASE_URL,
  WEBSOCKET_ENDPOINT,
  S3_BASE_URL,
} from "../../../utilities/paths";
import useWebSocket from "../../shared/hooks/useWebSocket";
import InfiniteScroll from "react-infinite-scroll-component";
import RenderImage from "../../../assets/img/renderimage.png";
import Lottie from "react-lottie";
import projectLoader from "../../../assets/animation/projectLoader.json";
import EmptyPlaceHolder from "../../shared/EmptyPlaceHolder";
import EmptyError from "../../../assets/img/emptyerror.png";
import {
  compareStrings,
  detectQueryString,
  findRatio,
  permissionDeniedClick,
  downloadFile,
} from "../../../utilities/common-function";
import Img from "../../ImageLoader/ImageLoader";
import { ReactComponent as iconDelete } from "../../../assets/svg/iconDelete.svg";
import { ApprovalAlert, TopFilter } from "../../shared";
import { Button, Checkbox, Footer, Tooltip } from "pyxis-ui-kit";
import { oneOfType } from "prop-types";
import Icon, {
  ClockCircleOutlined,
  LeftOutlined,
  Loading3QuartersOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { openToast } from "../../../Toasts";
import DetailTopBar from "../DetailTopBar";
import { IMAGE_SUPPORTED } from "../../../utilities/contants";
import ApprovalStatus from "../ApprovalStatus";
import {
  getCategoryArr,
  getSizesArray,
} from "../../AiquireCreateFlow/SelectSizes";
import { cloneDeep } from "lodash";
import { enableEdit } from "../../../utilities/contants";
import loader5 from "../../../assets/img/loader5.gif";

interface ContainerInterface {
  className: string;
}

interface CardInterface {
  data: any;
  setUpdateLoader: Function;
  setSelectedBanner: Function;
  type: string;
  handleImageSelect: Function;
  isSelected: boolean;
  projectId: string | null;
  bannerData: any;
  userData: UserData;
  length: number;
  isPublicLink: boolean;
}

interface ProjectInterface {
  projects: any[];
  setUpdateLoader: Function;
  setSelectedBanner: Function;
  activeProject?: number;
  type: string;
  getMoreProjects: Function;
  hasMore: boolean;
  selectedProjects: number[];
  projectId: string | null;
  bannerData: any;
  userData: UserData;
  setShowTopBar?: Function;
  isPublicLink: boolean;
}

interface ProjectDetailInterface {
  approvalLoader: boolean;
  type: string;
  isSentForApproval: boolean;
  fetchStatus: Function;
  handleStatusChange: Function;
  isApprover: boolean;
  setIsApprover: Function;
  statusLoader: boolean;
  currentRound: string;
  approvalStatus: any;
  setActiveTab: Function;
  setIsRejectDrawer: Function;
  projectRename: string;
  setProjectRename: Function;
  setShowTopBar: Function;
  selectedBanner: any;
  setSelectedBanner: Function;
  commentMode: boolean;
  setCommentMode: Function;
  commentIndex: number;
  setCommentIndex: Function;
  setReplyCommentIndex: Function;
  imageDimensions: any;
  setRetainedComments: Function;
  setImageDimensions: Function;
  setPrevDimensions: Function;
  commentClient: WebSocket;
  inFocus: boolean;
  setInFocus: Function;
}

const Card: React.FunctionComponent<CardInterface> = ({
  data,
  setUpdateLoader,
  setSelectedBanner,
  type,
  handleImageSelect,
  isSelected,
  projectId,
  bannerData,
  userData,
  length,
  isPublicLink,
}) => {
  const isEditEnabled: boolean = enableEdit.some((val: string) =>
    userData?.emailId.includes(val)
  );
  const dispatch = useDispatch();

  const handleSingleDelete = (e: any, Id: number) => {
    e.stopPropagation();
    if (projectId) {
      let id: number = parseInt(projectId);
      dispatch(
        deleteHyperRenderedImages(
          { project_id: id, ids: [Id] },
          (res: any, err: boolean) => {
            if (!err) {
              bannerData.banners = bannerData.banners.filter(
                (b: any) => b.id !== Id
              );
              bannerData.count = bannerData.count - 1;
              dispatch(setProject(bannerData));
              openToast("success", "Image Removed!");
            }
          }
        )
      );
    }
  };

  const handleDownload = (data: any) => {
    let b = [];
    if (data.psdUrl) {
      b = data?.rendered_image?.split("/");
      b[b?.length - 1] = encodeURIComponent(b[b?.length - 1]);
    }

    console.log("dataaaaaaaaa", data, b.join("/"));
    downloadFile(
      data.psdUrl ? data.psdUrl : IMAGE_BASE_URL + b.join("/"),
      data.size_name
    );
  };

  return (
    <div
      className={
        data.render_status === "INPROGRESS" ? "card rendering" : "card"
      }
      onClick={() => {
        setUpdateLoader(false);
        setSelectedBanner();
      }}
    >
      <div className='card-body'>
        <Img
          src={
            data.render_status === "INPROGRESS"
              ? RenderImage
              : IMAGE_BASE_URL +
                data.rendered_image +
                (detectQueryString(data.rendered_image) ? "&q=40" : "?q=40") +
                "&auto=format&w=240"
          }
        />
      </div>
      {type === "project" && (
        <div className='footer w-100'>
          <p className='text'>{data.size_name}</p>
        </div>
      )}
      {type === "project" && !isPublicLink && (
        <div
          className={
            isSelected
              ? "overlay selected"
              : data.render_status === "INPROGRESS"
              ? "overlay show half"
              : "overlay"
          }
        >
          <div className='options'>
            <Checkbox
              checked={isSelected}
              onClick={(e) => {
                e.stopPropagation();
                handleImageSelect(data, type);
              }}
            />
          </div>
          <div className='middle'>
            {data.render_status === "INPROGRESS" ? (
              <div>
                <Lottie
                  options={{
                    animationData: projectLoader,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={110}
                  height={110}
                />
              </div>
            ) : (
              type === "project" &&
              !isPublicLink && (
                <div className='action-container'>
                  <Button
                    className='download-btn'
                    type='link'
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleDownload(data);
                    }}
                  >
                    <DownloadOutlined className='download-icon' />
                    Download
                  </Button>
                </div>
              )
            )}
          </div>
        </div>
      )}
      {type === "hpproject" && !isPublicLink && (
        <div
          className={
            isSelected
              ? "overlay selected"
              : data.render_status === "INPROGRESS"
              ? "overlay show half"
              : "overlay"
          }
        >
          <div className='options'>
            <Checkbox
              checked={isSelected}
              onClick={(e: any) => {
                e.stopPropagation();
                handleImageSelect(data, type);
              }}
            />
          </div>
          <div className='middle'>
            {data.render_status === "INPROGRESS" ? (
              <div>
                <Lottie
                  options={{
                    animationData: projectLoader,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={110}
                  height={110}
                />
              </div>
            ) : (
              type === "hpproject" &&
              !isPublicLink && (
                <div className='action-container'>
                  <Tooltip
                    placement='top'
                    title={
                      !isEditEnabled
                        ? "Get in touch with our customer success manager to know more about our editing functionality."
                        : ""
                    }
                    content={
                      <Link
                        to={`/cocreate/creative-automation/editor/single/${projectId}/${data?.size}/[${data.combination}]`}
                        onClick={(e) => {
                          if (!isEditEnabled) {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }}
                        className={
                          isEditEnabled
                            ? "edit-btn"
                            : "edit-btn permission-denied-lock"
                        }
                        // className="edit-btn"
                      >
                        <span className='mr-5'>
                          <svg
                            width='17'
                            height='16'
                            viewBox='0 0 17 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M8.5 13.333H14.5'
                              stroke='white'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M11.5 2.33316C11.7652 2.06794 12.1249 1.91895 12.5 1.91895C12.6857 1.91895 12.8696 1.95553 13.0412 2.0266C13.2128 2.09767 13.3687 2.20184 13.5 2.33316C13.6313 2.46448 13.7355 2.62038 13.8066 2.79196C13.8776 2.96354 13.9142 3.14744 13.9142 3.33316C13.9142 3.51888 13.8776 3.70277 13.8066 3.87436C13.7355 4.04594 13.6313 4.20184 13.5 4.33316L5.16667 12.6665L2.5 13.3332L3.16667 10.6665L11.5 2.33316Z'
                              stroke='white'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </span>
                        <span>Edit</span>
                      </Link>
                    }
                  ></Tooltip>
                  <Button
                    onClick={(e: any) => {
                      handleSingleDelete(e, data.id);
                    }}
                    className='delete-btn'
                    type='link'
                  >
                    <Icon component={iconDelete} className='delete-icon' />
                    Delete from render
                  </Button>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Projects: React.FunctionComponent<ProjectInterface> = ({
  projects,
  setUpdateLoader,
  setSelectedBanner,
  getMoreProjects,
  type,
  hasMore,
  selectedProjects,
  projectId,
  bannerData,
  userData,
  setShowTopBar,
  isPublicLink,
}) => {
  const dispatch = useDispatch();
  const handleImageSelect = (data: any, type: string) => {
    let temp = selectedProjects;
    console.log("datadsfdf", data, selectedProjects);
    let index: number = temp.indexOf(data.id);
    // Remove from selection
    if (index > -1) {
      temp = temp.filter((t: any, i: number) => i !== index);
      // Add to select
    } else {
      temp = [...temp, data.id];
    }
    if (type === "project") {
      dispatch(setSelectedProject(temp));
    } else {
      dispatch(setSelectedHpProjects(temp));
    }
  };
  console.log("projects", projects);
  return (
    <>
      <InfiniteScroll
        dataLength={projects.length}
        next={() => getMoreProjects()}
        hasMore={hasMore}
        loader={null}
        className='projects'
        scrollableTarget='aiquare-project-detail'
      >
        <div className='aiquare-project-detail-container'>
          {projects.map((project, index) => (
            <Card
              key={project.id}
              data={project}
              type={type}
              setSelectedBanner={() => {
                setSelectedBanner(index);
                setShowTopBar && setShowTopBar();
              }}
              setUpdateLoader={setUpdateLoader}
              handleImageSelect={handleImageSelect}
              isSelected={selectedProjects.indexOf(project.id) > -1}
              projectId={projectId}
              bannerData={bannerData}
              userData={userData}
              length={projects.length}
              isPublicLink={isPublicLink}
            />
          ))}
        </div>
      </InfiniteScroll>
    </>
  );
};

const ProjectDetail: React.FunctionComponent<ProjectDetailInterface> = ({
  approvalLoader,
  type,
  isSentForApproval,
  fetchStatus,
  handleStatusChange,
  isApprover,
  setIsApprover,
  statusLoader,
  currentRound,
  approvalStatus,
  setActiveTab,
  setIsRejectDrawer,
  projectRename,
  setProjectRename,
  setShowTopBar,
  selectedBanner,
  setSelectedBanner,
  commentMode,
  setCommentMode,
  commentIndex,
  setCommentIndex,
  setReplyCommentIndex,
  setRetainedComments,
  imageDimensions,
  setImageDimensions,
  setPrevDimensions,
  commentClient,
  inFocus,
  setInFocus,
}) => {
  const [loading, setLoader] = useState(true);
  const [combinationsLoading, setCombinationLoader] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState("");
  const [renderData, setRenderData] = useState<any>(null);
  const [hpSizes, setHpSizes] = useState([]);
  const [sizeTabsArr, setSizeTabsArr] = useState([]);

  const bannersHashRef = useRef<any>(new Map());
  const pendingProjects = useRef<any>(new Map());
  const pageCount = useRef<number>(1);
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const hyperState = useSelector(
    (state: { hyperProject: HyperProjectFoldersState }) => state.hyperProject
  );
  const { userPermissions } = useSelector(
    (state: { roles: RolesState }) => state.roles
  );
  const authState = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const { userData } = authState;
  const token = useSelector(
    (state: any) => state.authentication.token || state.publicLink.token
  );
  // const { token } = useSelector((state: {authentication: AuthState})=>state.authentication);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get("banner");
  const { bannerData, isBannerDetailView, selectedProjects } = state;
  const banners = bannerData?.banners || [];
  const [updateLoader, setUpdateLoader] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const img_show_iframe = ["ai", "ps"];
  const supportedFileType =
    type === "project"
      ? IMAGE_SUPPORTED.includes(bannerData?.file_type) &&
        !img_show_iframe.includes(bannerData?.file_type)
      : type === "hpproject";
  const {
    selectedHyperProjects,
    selectedImageId,
    selectedTextId,
    selectedSizeId,
    hyperProjectData,
  } = hyperState;
  let psdExtension: string =
    bannerData?.renderedimages?.length > 0 &&
    bannerData?.renderedimages[
      bannerData?.renderedimages?.length - 1
    ]?.size_name
      ?.split(".")
      .pop()
      .toLowerCase();
  const [client, setClientActions] = useWebSocket(
    `${WEBSOCKET_ENDPOINT}/entity/${banner}/rendered-images-update?token=${token}&type=${type}`,
    psdExtension === "psd"
  );
  // const [projectUpdate,setProjectUpdate] = useWebSocket(`${WEBSOCKET_ENDPOINT}/entity/${banner}/updates?token=${token}&type=project`);
  const [hpUpdate, setHpUpdate] = useWebSocket(
    `${WEBSOCKET_ENDPOINT}/entity/${banner}/updates?token=${token}&type=hpproject`,
    renderData?.render_status === "INPROGRESS"
  );
  const isFiltered =
    selectedSizeId?.length > 0 ||
    selectedImageId?.length > 0 ||
    selectedTextId?.length > 0;
  const isPublicLink = history.location.pathname.startsWith("/cocreate/public");
  const [showAddComment, setShowAddComment] = useState(false);

  // useEffect(()=>{
  //     if(hpUpdate){
  //         setHpUpdate((val:any)=>({
  //             ...val,
  //             onError: (err:any)=>{console.log(err)},
  //             onRecieve:(data:any)=>{
  //                 setRenderData((d:any)=>({...d,...data}));
  //             }
  //         }))
  //     }
  // },[hpUpdate])

  // Fetch all size to append category property
  useEffect(() => {
    let allSizes: any;
    if (hpSizes && hpSizes?.length > 1) {
      dispatch(
        getTemplateSizes({}, (res: any, isError: any) => {
          if (isError) openToast("error", "sizes fetch fail");
          if (!isError) {
            allSizes = getSizesArray(res.data.categories).sort((a, b) =>
              compareStrings(a.value, b.value)
            );
            let size = hpSizes.map((size: any) => {
              let allSize = allSizes.find(
                (allSize: any) => allSize.value === size.name
              );
              if (allSize !== undefined) {
                size.category = allSize.category;
              } else {
                size.category = "custom";
              }
              return size;
            });
            setSizeTabsArr(size);
          }
        })
      );
    }
  }, [hpSizes]);

  useEffect(() => {
    if (banner) {
      let id: number = parseInt(banner);
      if (type === "project" && id) {
        fetchProjectData(id, setLoader, (response: any) => {
          console.log("response", response);
          dispatch(setProject(response));
        });
      } else if (type === "hpproject" && id) {
        dispatch(
          getHyperProject(
            { projectId: id, with_default_json: false },
            (res: any, err: boolean) => {
              if (!err) {
                dispatch(
                  getUsersData(
                    { userIds: res.data.created_by },
                    (userres: any, usererr: boolean) => {
                      if (!usererr) {
                        res.data.rendered_file =
                          IMAGE_BASE_URL +
                          res.data.rendered_file +
                          (detectQueryString(res.data.rendered_file)
                            ? "&q=40"
                            : "?q=40");
                        res.data.created_by = userres.data.users[0];
                        if ((res.data.render_status = "NOT_STARTED")) {
                          res.data.render_status = "INPROGRESS";
                        }
                        setHpSizes(res.data.sizes);
                        dispatch(setHyperProjectData(res.data));
                        setRenderData({
                          render_status: res.data.render_status,
                        });
                      } else {
                        dispatch(setHyperProjectData(res.data));
                      }
                    }
                  )
                );
              }
            }
          )
        );
        dispatch(
          fetchLayersWithAssets(
            { project_id: id, page: 1, page_size: 999 },
            (res: any, err: boolean) => {
              if (!err) {
                dispatch(setLayersWithAssests(res.data));
              }
            }
          )
        );
        // websocket for hp
        if (hpUpdate) {
          setHpUpdate((val: any) => ({
            ...val,
            onError: (err: any) => {
              console.log(err);
            },
            onRecieve: (data: any) => {
              setRenderData((d: any) => ({ ...d, ...data }));
            },
          }));
        }
      }
      // if(client){
      //     setClientActions((val:any)=>({
      //         ...val,
      //         onError: (err:any)=>{console.log(err)},
      //         onRecieve:(data:any)=>{
      //             updateBanner(data);
      //         }
      //     }))
      // }
    }
    return () => {
      dispatch(setProject(null));
      bannersHashRef.current.clear();
      dispatch(setHyperProjectData(null));
      dispatch(setLayersWithAssests(null));
      dispatch(setSelectedImageId(null));
      dispatch(setSelectedTextId(null));
      dispatch(setSelectedImageLayerId(null));
      dispatch(setSelectedTextLayerId(null));
      dispatch(setSelectedSizeId(null));
      if (hpUpdate) {
        hpUpdate.close();
      }
      // if(client){ client.close() }
    };
  }, []);

  // websocket for design
  // useEffect(()=>{
  //     if(projectUpdate){
  //         setProjectUpdate((val:any)=>({
  //             ...val,
  //             onError:(err:any)=>{console.error('error','Not able to connect!')},
  //             onRecieve:(data:any)=>{
  //                 if(data.render_status !== "INPROGRESS") {
  //                     dispatch(setProject({...bannerData, ...data, created_by: bannerData?.created_by}));
  //                 }
  //             }
  //         }));
  //     }
  //     return ()=>{
  //         if(projectUpdate){ projectUpdate.close() }
  //     }
  // },[bannerData])

  const renderedCount: number = useMemo(() => {
    if (bannerData) {
      let count: number = (bannerData?.banners || []).reduce(
        (acc: number, val: any) => {
          if (
            val.render_status === "COMPLETED" ||
            val.render_status === "INPROGRESS"
          ) {
            return acc + 1;
          } else {
            return acc;
          }
        },
        0
      );
      // If project have only one image display image preview
      if (count === 1) {
        setSelectedBanner(0);
      }
      return count;
    } else {
      return 0;
    }
  }, [bannerData, banners.length]);

  const fetchProjectData = (
    id: number,
    setLoader?: Function,
    callback?: Function
  ) => {
    setLoader && setLoader(true);
    dispatch(
      fetchProject({ id, editor_mode: false }, (res: any, err: boolean) => {
        console.log("res111111111111111111111111111111", res);
        if (!err) {
          res.banners.map((banner: any, index: number) => {
            if (pendingProjects.current.has(banner.id)) {
              banner = {
                ...banner,
                ...(pendingProjects.current.get(banner.id) || {}),
              };
            }
            bannersHashRef.current.set(banner.id, index);
            // if(banner.render_status === 'NOT_STARTED'){
            //     banner.render_status = 'INPROGRESS';
            // }
          });
          if (!isPublicLink) {
            dispatch(
              getUsersData(
                { userIds: res.created_by },
                (userres: any, usererr: boolean) => {
                  setLoader && setLoader(false);
                  if (!usererr) {
                    res.created_by = userres.data.users[0];
                    // if (res.render_status === 'NOT_STARTED') { res.render_status = 'INPROGRESS'; }
                  }
                  callback && callback(res);
                }
              )
            );
          } else {
            setLoader && setLoader(false);
            callback && callback(res);
          }
        } else {
          setLoader && setLoader(false);
          callback && callback(null);
        }
      })
    );
  };

  const fetchCombinations = (
    id: number,
    setLoader?: Function,
    callback?: Function
  ) => {
    setLoader && setLoader(true);
    dispatch(
      fetchHyperRenderedImages(
        {
          project_id: id,
          page: 1,
          size_id: selectedSizeId?.length > 0 ? selectedSizeId : undefined,
          text_layer_asset_id: selectedTextId,
          image_layer_asset_id: selectedImageId,
        },
        (res: any, err: boolean) => {
          setLoader && setLoader(false);
          if (!err) {
            res.data.results.map((banner: any, index: number) => {
              if (pendingProjects.current.has(banner.id)) {
                banner = {
                  ...banner,
                  ...(pendingProjects.current.get(banner.id) || {}),
                };
              }
              bannersHashRef.current.set(banner.id, index);
              if (banner.render_status === "NOT_STARTED") {
                banner.render_status = "INPROGRESS";
              }
            });
            res.data["banners"] = res.data.results;
            delete res.data.results;
          }
          callback && callback(res.data);
        }
      )
    );
  };

  const updateBanner = (data: any) => {
    // data.map((banner:any,index:number)=>{
    //     let bannerIndex:any = bannersHashRef.current.has(banner.id)? parseInt(bannersHashRef.current.get(banner.id)):null;
    //     if(typeof bannerIndex === 'number' && bannerIndex > -1 && bannerData && bannerData?.banners[bannerIndex] && bannerData?.banners[bannerIndex].id === banner.id){
    //         bannerData.banners[bannerIndex] = banner;
    //     }else{
    //         pendingProjects.current.set(banner.id,banner);
    //     }
    // })
    // let renderedCount:number = (type === "project"? (bannerData?.banners||[]):data).reduce((acc:number,val:any)=>{
    //     if(val.render_status === "COMPLETED"){
    //         return acc+1;
    //     }else{
    //         return acc;
    //     }
    // },0);
    // if(type === "hpproject") renderedCount = bannerData?.rendered_count + renderedCount;
    // if(bannerData && bannerData.rendered_count !== renderedCount){
    //     bannerData.rendered_count = renderedCount;
    // }
    bannerData.rendered_count = bannerData?.banners?.length;
    if (updateType === "update") {
      if (bannerData?.file_type === "svg") {
        dispatch(setProject(bannerData));
      } else {
        dispatch(
          setProject({
            ...bannerData,
            banners: data,
            renderedimages: data,
            created_by: bannerData?.created_by,
          })
        );
      }
    } else if (updateType.includes("add") || updateType === "update-add") {
      let addBanner: any, bannerToAdd: any, imageToAdd: any;
      if (
        bannerData?.banners?.length > 0 ||
        bannerData?.renderedimages?.length > 0
      ) {
        addBanner = cloneDeep(bannerData);
        bannerToAdd = addBanner.banners.map((banner: any) => {
          if (banner.id === data.id) return (banner = data);
          else return (banner = banner);
        });
        imageToAdd = addBanner.renderedimages.map((renderedImage: any) => {
          if (renderedImage.id === data.id) return (renderedImage = data);
          else return (renderedImage = renderedImage);
        });
      }
      dispatch(
        setProject({
          ...bannerData,
          banners: [...bannerToAdd],
          renderedimages: [...imageToAdd],
          created_by: bannerData?.created_by,
        })
      );
    }
  };

  useEffect(() => {
    if (psdExtension === "psd" && client) {
      setClientActions((val: any) => ({
        ...val,
        onRecieve: (data: any) => {
          bannerData["render_status"] = "COMPLETED";
          if (updateType === "update") {
            if (data[0].render_status === "COMPLETED") {
              setTimeout(() => updateBanner(data), 0);
              openToast("success", "File updated successfully");
            }
          } else if (updateType === "update-add") {
            if (data[0].render_status === "COMPLETED") {
              setTimeout(() => updateBanner(data[0]), 0);
              openToast("success", "File updated successfully");
            }
          } else if (updateType.includes("add")) {
            data.map((banner: any) => {
              if (banner.render_status === "COMPLETED") {
                setTimeout(() => updateBanner(banner), 0);
                openToast("success", "Variant added successfully");
              }
            });
          }
          setCommentIndex(-1);
          setUpdateLoader(false);
        },
      }));
    }
    // return ()=>{
    //     if(client){ client.close() }
    // }
  }, [bannerData, banners.length]);

  useEffect(() => {
    if (banner && type === "hpproject") {
      let id: number = parseInt(banner);
      fetchCombinations(id, setLoader, (response: any) => {
        dispatch(setProject(response));
      });
    }
    return () => {
      dispatch(setProject(null));
      bannersHashRef.current.clear();
      dispatch(setSelectedHpProjects([]));
    };
  }, [selectedSizeId, selectedImageId, selectedTextId]);

  useEffect(() => {
    let timer: any;
    if (renderData?.render_status !== hyperProjectData?.render_status)
      setRenderData((r: any) => ({ ...r, ...hyperProjectData }));
    if (
      banner &&
      type === "hpproject" &&
      hyperProjectData?.render_status === "INPROGRESS"
    ) {
      timer = setTimeout(() => {
        let id: number = parseInt(banner);
        fetchCombinations(id, undefined, (response: any) => {
          dispatch(setProject(response));
        });
        dispatch(
          getHyperProject(
            { projectId: id, with_default_json: false },
            (res: any, err: boolean) => {
              if (!err) {
                let img: string =
                  IMAGE_BASE_URL +
                  res.data.rendered_file +
                  (detectQueryString(res.data.rendered_file)
                    ? "&q=40"
                    : "?q=40");
                dispatch(
                  setHyperProjectData({
                    ...hyperProjectData,
                    ...res.data,
                    created_by: hyperProjectData?.created_by,
                    rendered_file: img,
                  })
                );
              }
            }
          )
        );
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hyperProjectData?.render_status]);

  useEffect(() => {
    let timer: any;
    if (
      banner &&
      type === "project" &&
      bannerData?.render_status === "INPROGRESS" &&
      psdExtension !== "psd"
    ) {
      setUpdateLoader(true);
      timer = setTimeout(() => {
        let id: number = parseInt(banner);
        fetchProjectData(id, undefined, (response: any) => {
          dispatch(setProject(response));
          setCommentIndex(-1);
          setUpdateLoader(false);
          if (updateType.includes("update")) {
            openToast("success", "File updated successfully");
          } else if (updateType.includes("add")) {
            openToast(
              "success",
              `Variant${
                updateType.split("-").slice()[1] !== "1" ? "s" : ""
              } added successfully`
            );
          }
        });
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [bannerData?.render_status]);

  const handleIndexChange = useCallback(
    (value: number) => {
      if (banners.length !== 0) {
        let newvalue = selectedBanner + value;
        if (newvalue > banners.length - 1) {
          newvalue = 0;
        } else if (newvalue < 0) {
          newvalue = banners.length - 1;
        }
        setCommentIndex(-1);
        setShowAddComment(false);
        setSelectedBanner(newvalue);
        setRetainedComments([]);
      }
    },
    [selectedBanner, banners]
  );

  const getMoreProjects = () => {
    let id: number = parseInt(banner as string);
    if (id && type === "hpproject" && !combinationsLoading) {
      setCombinationLoader(true);
      dispatch(
        fetchHyperRenderedImages(
          {
            project_id: id,
            page: pageCount.current + 1,
            size_id: selectedSizeId ? selectedSizeId : undefined,
            // layer_asset_id: (!isEmpty(selectedTextId)&&!isEmpty(selectedImageId))?JSON.stringify({text:selectedTextId,image:selectedImageId}):!isEmpty(selectedTextId)?JSON.stringify({text:selectedTextId}):!isEmpty(selectedImageId)?JSON.stringify({image:selectedImageId}):undefined
            text_layer_asset_id: selectedTextId,
            image_layer_asset_id: selectedImageId,
          },
          (res: any, err: boolean) => {
            pageCount.current = res.data.current_page;
            setCombinationLoader(false);
            if (!err) {
              res.data.results.map((banner: any, index: number) => {
                if (pendingProjects.current.has(banner.id)) {
                  banner = {
                    ...banner,
                    ...(pendingProjects.current.get(banner.id) || {}),
                  };
                }
                bannersHashRef.current.set(
                  banner.id,
                  (res.data.current_page - 1) * 60 + index
                );
                if (banner.render_status === "NOT_STARTED") {
                  banner.render_status = "INPROGRESS";
                }
              });
              res.data["banners"] = [
                ...(bannerData?.banners || []),
                ...res.data.results,
              ];
              delete res.data.results;
              dispatch(setProject({ ...bannerData, ...res.data }));
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(setBannerView(selectedBanner === -1 ? false : true));
    if (selectedHyperProjects.length > 0) {
      dispatch(setSelectedHpProjects([]));
    }
    if (selectedProjects.length > 0) {
      dispatch(setSelectedProject([]));
    }
  }, [selectedBanner]);

  useEffect(() => {
    if (!isBannerDetailView) {
      setSelectedBanner(-1);
      setShowTopBar && setShowTopBar(true);
    }
  }, [isBannerDetailView]);

  return (
    <>
      <DetailTopBar
        selectedBanner={selectedBanner}
        setSelectedBanner={setSelectedBanner}
        renderedCount={renderedCount}
        type={type}
        renderData={renderData}
        isBannerDetailView={isBannerDetailView}
        commentMode={commentMode}
        setCommentMode={setCommentMode}
        setUpdateLoader={setUpdateLoader}
        setActiveTab={setActiveTab}
        setRetainedComments={setRetainedComments}
        setPrevDimensions={setPrevDimensions}
        inFocus={inFocus}
        hyperProjectData={hyperProjectData}
        setProjectRename={setProjectRename}
        projectRename={projectRename}
        isFiltered={isFiltered}
        sizeTabsArr={sizeTabsArr}
        bannerData={bannerData}
        updateType={updateType}
        setUpdateType={setUpdateType}
      />
      {/* :<HpDetailTopBar setSelectedBanner={setSelectedBanner} renderedCount={renderedCount}/>} */}

      {(!loading && bannerData && banners.length === 0) ||
      selectedBanner !== -1 ? (
        <ProjectView
          index={selectedBanner}
          handleIndexChange={handleIndexChange}
          componentCaller='projectDetail'
          updateLoader={updateLoader}
          setInFocus={setInFocus}
          showAddComment={showAddComment}
          setShowAddComment={setShowAddComment}
          commentIndex={commentIndex}
          setCommentIndex={setCommentIndex}
          setReplyCommentIndex={setReplyCommentIndex}
          client={commentClient}
          setRetainedComments={setRetainedComments}
          imageDimensions={imageDimensions}
          setImageDimensions={setImageDimensions}
          commentMode={commentMode}
          handleBackClick={() => setSelectedBanner(-1)}
          type={type}
          style={!isPublicLink ? {} : { width: "100%" }}
          renderedCount={renderedCount}
        />
      ) : (
        <div
          className={
            supportedFileType
              ? "aiquare-projectdetail"
              : "aiquare-projectdetail otherFileType"
          }
          style={!isPublicLink ? {} : { width: "100%" }}
          id='aiquare-project-detail'
        >
          {!loading && bannerData && (
            <TopFilter
              componentCaller='projectDetail'
              projectType={type}
              drawerVisible={drawerVisible}
              setDrawerVisible={setDrawerVisible}
              bannerData={bannerData}
              renderedCount={renderedCount}
              renderData={renderData}
              isFiltered={isFiltered}
              isPublicLink={isPublicLink}
              canManage={userPermissions.indexOf("create-new-banners") > -1}
            />
          )}
          {loading ? (
            <div className='loader'>
              <Loading3QuartersOutlined spin />
            </div>
          ) : !loading && !bannerData ? (
            <EmptyPlaceHolder
              icon={<img src={EmptyError} />}
              text='Oops! There seems to be an error'
              submitText='Retry'
              onSubmit={() => window.location.reload()}
            />
          ) : (
            <>
              {updateLoader ? (
                <div className='loader'>
                  <img src={loader5} />
                </div>
              ) : (
                <Projects
                  projects={banners.sort((a: any, b: any) => a.id - b.id)}
                  setUpdateLoader={setUpdateLoader}
                  setSelectedBanner={setSelectedBanner}
                  type={type}
                  userData={userData}
                  getMoreProjects={getMoreProjects}
                  hasMore={
                    type === "hpproject"
                      ? pageCount.current < bannerData?.pages
                      : false
                  }
                  selectedProjects={
                    type === "hpproject"
                      ? selectedHyperProjects
                      : selectedProjects
                  }
                  projectId={banner}
                  bannerData={bannerData}
                  isPublicLink={isPublicLink}
                />
              )}
            </>
          )}
        </div>
      )}
      <Footer
        className='project-view-footer'
        style={isPublicLink ? { width: "100%" } : {}}
      >
        <div className='footer-container'>
          <Button
            className='footer-btn back'
            type='link'
            onClick={() => {
              if (isBannerDetailView && bannerData.type === "image_set") {
                dispatch(setBannerView(false));
                setSelectedBanner(-1);
              } else {
                history.go(-1);
              }
            }}
          >
            <LeftOutlined className='icon' /> Go back
          </Button>
          {!isPublicLink && (
            <ApprovalStatus
              approvalLoader={approvalLoader}
              isSentForApproval={isSentForApproval}
              isApprover={isApprover}
              approvalStatus={approvalStatus}
              canManage={
                userPermissions.indexOf("start-cancel-approval-flow") > -1
              }
              setDrawerVisible={setDrawerVisible}
              currentRound={currentRound}
              statusLoader={statusLoader}
              handleStatusChange={handleStatusChange}
              setIsRejectDrawer={setIsRejectDrawer}
            />
          )}
        </div>
      </Footer>
      {drawerVisible === "approval" && banner && (
        <ApprovalAlert
          projectId={parseInt(banner)}
          isApproval={drawerVisible === "approval"}
          projectType={type}
          banners={banners}
          setDrawerVisible={setDrawerVisible}
          componentCaller='project'
          callback={(res: any, err: boolean) => {
            if (!err) {
              setActiveTab(1);
              dispatch(setIsSentForApproval(true));
              // fetch approval round info
              fetchStatus(false);
              if (userData) {
                let id: number = parseInt(banner as string);
                dispatch(
                  fetchMyApprovalStatus(
                    {
                      entity_id: id,
                      user_id: userData.userId,
                      entity_type: type,
                    },
                    (res: any, err: boolean) => {
                      if (!err) {
                        // value of enabledecisionbox is set from backend
                        if (res.data.enabledecisionbox) {
                          setIsApprover(true);
                        }
                      }
                    }
                  )
                );
              }
            }
          }}
        />
      )}
    </>
  );
};

export default ProjectDetail;
