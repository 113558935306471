import { Radio, Space } from 'antd';
import React, { useEffect } from 'react';

function ModePopup(props) {
    const ref = React.useRef<any>(null);
    const { onClickOutside, product, setProduct } = props;
    // const options = ['Education Loan', 'Home Loan']
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <div ref={ref} className="category-popup">
            <Radio.Group onChange={(e) => { setProduct(e.target.value); onClickOutside() }} value={product}>
                <Space direction="vertical">
                    {props.options.map((item) => <Radio value={item.key}>{item.label}</Radio>)}
                </Space>
            </Radio.Group>
        </div>
    )
}

export default ModePopup;