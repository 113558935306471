import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../store/types';
import { detectQueryString } from '../../../utilities/common-function';
import Img from '../../ImageLoader/ImageLoader';
import './index.scss';

const loadImage = (src:string) => {
    return new Promise((res,rej)=>{
        let img = new Image();
        img.onload = res;
        img.onerror = rej;
        img.src = src;
    })
}

const Avatar:React.FC<{
    size: 'x-small' | 'small' | 'large' | 'x-large' | 'xx-large';
    showInitials: boolean;
    initials?: string;
    src?: string;
    backgroundColor?: string;
    className?:string
}> = ({size,showInitials,initials,src,backgroundColor='rgb(32, 0, 127)',className}) => {
    const token = useSelector((state: {authentication: AuthState})=>(state.authentication.token));
    const [loading,setLoader] =  useState(false);
    const [isValidImage,setIsValidImage] = useState(true);
    const [imageLoaded,setImageLoaded] = useState(false);
    const bgColor = useMemo(()=>((!backgroundColor || backgroundColor.toLowerCase() === '#fff'
        ||backgroundColor.toLowerCase() === '#ffffff')? 'rgb(32, 0, 127)':backgroundColor),[backgroundColor]);

    useEffect(()=>{
        if(src){
            if(!loading){ setLoader(true) }
            if(!isValidImage){ setIsValidImage(true) }
            loadImage(src).then(()=>{
                setLoader(false);
                setImageLoaded(true);
                setIsValidImage(true)
            }).catch(()=>{
                setLoader(false);
                setIsValidImage(false);
            })
        }else{
            setIsValidImage(false);
        }
    },[src])

    if(loading && isValidImage) {
        return <span className={`avatar ${size} pulsate ${className? className: ''}`} style={{backgroundColor:bgColor}}></span>
    }
    if(!loading && !isValidImage ) {
        return <span className={`avatar ${size} ${className? className: ''}`} style={{backgroundColor:bgColor}}>
            {initials}
        </span>
    }
    return <span className={`avatar ${size} ${className? className: ''}`} style={{backgroundColor:bgColor}}>
        <img src={src||""} />
    </span>
}

export default Avatar;