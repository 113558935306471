import React, { FC, useState } from "react";
import "./index.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { string } from "prop-types";

interface AlertInterface {
  title: string;
  text: string;
  submittext: string;
  canceltext: string;
  onSubmit: Function;
  onCancel: Function;
  isPrimary?: boolean;
}

const AlertPopUp: FC<AlertInterface> = ({
  title,
  text,
  submittext,
  canceltext,
  onSubmit,
  onCancel,
  isPrimary
}) => {
  const [loading, setLoader] = useState(false);

  return (
    <>
    <div className="popup-overlay" onClick={(e)=>{e.stopPropagation(); onCancel && onCancel();}}>
    </div>
    <div className="alert-popup">
      <div className="title">
        {title}
      </div>
      <div className="text">
        {text}
      </div>
      <div className="footer">
        <button className="secondary" onClick={(e)=>{e.stopPropagation(); onCancel && onCancel();}}>
          {canceltext}
        </button>
        <button className={isPrimary? "primary":"danger"} 
          onClick={(e)=>{e.stopPropagation(); onSubmit && onSubmit(setLoader);}} 
          disabled={loading}>
          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
          {submittext}
        </button>
      </div>
    </div>
    </>
  );
};


export default AlertPopUp;