import { Button } from "pyxis-ui-kit";
import React, { FC, Ref, useRef } from "react";
import "./index.scss";

const EmptyPlaceHolder:React.FC<{
  icon:any;
  text: string;
  submitText?: string;
  onSubmit?: Function;
  isDisabled?: boolean;
  isSubmitBtn?: boolean;
  cutomAction?: any;
  style?: any;
}> = ({icon,text,submitText,onSubmit,isDisabled,isSubmitBtn = true,style,cutomAction}) =>{
  const submitRef = useRef(null); 
  return (
    <div className="empty-placeholder-container" style={style}>
      <div className="icon">
        {icon}
      </div>
      <p className="text">
        {text}
      </p>
      {cutomAction? cutomAction:
        (isSubmitBtn && <Button className={isDisabled?"action permission-denied-lock" : "action"} 
          ref={submitRef}
          disabled={isDisabled}
          onClick={(e)=>onSubmit && onSubmit(e,submitRef)}
        >
          {submitText}
        </Button>)
      }
    </div>
  );
}

export default EmptyPlaceHolder;