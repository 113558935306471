import { cloneDeep } from "lodash";

export const resizeText = (obj: any, dimension: number, isHorizontal: boolean) => {
  const newObj = cloneDeep(obj);
  let {fontSize, width, height} = newObj;
  const scaleFactor = 1.3;

  fontSize *= scaleFactor;
  width *= scaleFactor;
  height *= scaleFactor;

  const area = width * height;
  newObj.fontSize = fontSize;

  if (!isHorizontal) {
    newObj.width = dimension;
    newObj.height = area / dimension;
  } else {
    newObj.height = dimension * 0.9;
    newObj.width = area / newObj.height;
  }
  
    return {...newObj};
};