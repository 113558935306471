import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Select, SelectOption , Spin , TreeSelect} from 'pyxis-ui-kit';
import { debounce } from 'lodash';
import { addMeta, deleteMeta, fetchDesignMeta, searchMeta } from '../../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { openToast } from '../../../../../Toasts';
import {  FolderState } from '../../../../../store/types';
import { ReactComponent as iconEdit} from '../../../../../assets/svg/iconEdit.svg';
import { Icon } from '@material-ui/core';
import { IMAGE_BASE_URL } from '../../../../../utilities/paths';
import Img from '../../../../ImageLoader/ImageLoader';

/**
 * Function to render the Metadata of design sets
 * @param {object} props Provides props to the MetaDataDesign Component
 * @param {number} props.projectSizeID Provides design Size ID of banners
 * @param {number} props.selectedBanner Provides Index of the Banner data
 * @returns {React.FunctionComponent<any>} Returns Functional Component for Metadata of Design
 * @author Viral Chonkar <viral.chonkar@pyxispm.com>
 */
const MetaDataDesign: React.FunctionComponent<any> = ({projectSizeID,selectedBanner}) => {
    const [loading,setLoader] = useState(false);
    const [targeting, setTargeting] = useState<{value: string}[]>([]);
    const [channel, setChannel] = useState<{value: string}[]>([]);
    const [ defaultTarget, setDefaultTarget ] = useState([]);
    const [ defaultChannel, setDefaultChannel ] = useState([]);
    const [ isChannelEdit , setIsChannelEdit] = useState(false);
    const [ isTargetEdit , setIsTargetEdit] = useState(false);
    const [isOpen,setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const folders = useSelector((state: {folders:FolderState})=>state.folders);
    const { bannerData } = folders;
    

    /**
     * Function to Add / Remove the targeting and channel from Design meta
     * @param {enum} type Provides type of meta to be added for the design
     * @param {string} name Provides the data to store 
     * @param {enum} methodType Provides the action to be taken
     * @param {number} [index] Provides the index of the array to take necessary action (Optional)
     * @author Viral Chonkar <viral.chonkar@pyxispm.com>
     */
    const handleAddOrRemove = (type:'channel'|'targeting',name:any,methodType:'add'|'delete',index?:number) => {
        if (methodType === "add") {
            dispatch(addMeta({type:type,data:name,project_size_id:projectSizeID},(res:any,err:boolean)=>{
                if(!err){
                    
                    if (type === "channel") {
                        defaultChannel.push(res.data);
                        setDefaultChannel(defaultChannel);
                    } else if (type === "targeting") {
                        defaultTarget.push(res.data);
                        setDefaultTarget(defaultTarget);
                    }        
                    
                } else {
                    openToast('error',res.data.message||`Unable to ${methodType} ${type}`);
                }
            })) 
        } else {
            let id = type === "targeting" ? defaultTarget[index].id : defaultChannel[index].id;
            dispatch(deleteMeta({type:type,project_size_id:projectSizeID,id:id},(res:any,err:boolean)=> {
                if (!err) {
                    if (type === "targeting") {
                        defaultTarget.splice(index, 1);
                        setDefaultTarget(defaultTarget);
                    } else {
                        defaultChannel.splice(index, 1);
                        setDefaultChannel(defaultChannel);
                    }
                    
                }
            }))
        }
    }
    
    /**
     * Function to fetch the list of targeting on search
     * @param {string} text Provides text to be searched 
     * @author Viral Chonkar <viral.chonkar@pyxispm.com>
     */
    const fetchTargetList = useCallback((text:string)=>{
        
        dispatch(searchMeta({search: text,entityType:'targeting'},(res:any,err:boolean)=>{
            setLoader(false)
            if(!err){
                let tempTagrget = res.data.recommendation.reduce((acc:any,tag:string)=>(
                    [...acc,{value: tag}]
                ),[])
                setTargeting(tempTagrget)
            }else{
                setIsOpen(false);
                setTargeting([]);
            }
        }))
    },[])

    /**
     * 
     */
    const handleDelayedSearch = debounce((text:string) =>fetchTargetList(text));

    /**
     * Function to handle the search
     * @param {string} text Provides the string to search 
     * @author Viral Chonkar <viral.chonkar@pyxispm.com>
     */
    const handleSearch = (text:string) => {
        if(text.trim()) { 
            setLoader(true) 
            handleDelayedSearch(text)
        } else {
            setLoader(false)
            setTargeting([]);
        }
    };

    const getMetaData = () => {
        dispatch(fetchDesignMeta({project_size_id:projectSizeID},(res:any,err:boolean)=>{
            setLoader(false)
            if(!err){
                let metadata = res.data.reduce((acc:any,data: any)=>{ 
                    acc[data.type].push(data);
                    return acc;
                },{targeting : [] , channel : []} );
                setDefaultTarget(metadata.targeting);
                setDefaultChannel(metadata.channel);
            }
        }));
    }
    const refEx = useRef(null);

    /**
     * It provides the property to the selectree
     * @author Viral Chonkar <viral.chonkar@pyxispm.com>
     */
    const tProps = {
        treeData: channel,
        defaultValue: defaultChannel && defaultChannel.map((channel)=> channel.name) ,
        onSelect : (value: string) =>{  handleAddOrRemove('channel',value,'add'); },
        onDeselect: (value: string) => { handleAddOrRemove('channel',value,'delete',defaultChannel.findIndex(i => i.name === value));},
        onBlur: ()=>{  if(isChannelEdit) { setIsChannelEdit(!isChannelEdit); } },
        treeCheckable: true,
        placeholder: 'Please select',
        style: {
            width: '100%',
        },
        ref: refEx,
    };

    /**
     * Function to get the list of channel
     * @author Viral Chonkar <viral.chonkar@pyxispm.com>
     */
    const getChannel = () => {
        dispatch(searchMeta({search: "",entityType:'channel'},(res:any,err:boolean)=>{
            setLoader(false)
            if(!err){
                let tempChannel = res.data.recommendation.reduce((acc:any,tag:string)=>(
                    [...acc,{value: tag, title: tag , key: tag}]
                ),[])
                setChannel(tempChannel);
            }else{
                setIsOpen(false);
                setChannel([]);
            }
        }))
    }

    useEffect(() => {
        getMetaData();
        getChannel();
    }, [selectedBanner])

    useEffect(() => {
        if (isChannelEdit) {
            const input: any = refEx?.current;
            if (input) {
                input?.focus();
              }
        }
        
    }, [isChannelEdit])

    return(
        <div className="meta-data-design">
            {bannerData?.banners[selectedBanner].height && bannerData?.banners[selectedBanner].width && (
            <div className="meta-size">
                <div className="meta-title">SIZE</div>
                <div className="meta-description">{bannerData.banners[selectedBanner].width} x {bannerData.banners[selectedBanner].height}</div>
            </div>)}
            <div className="meta-channel">
                <div className="meta-title"><span>{defaultChannel && defaultChannel.length === 0 && ("ADD")} CHANNEL</span> <span onClick={() => {setIsChannelEdit(!isChannelEdit); }} className="edit-icon"> <Icon component={iconEdit} className="left-btn-icon"/></span></div>
                <div id="channel-select" className="meta-description">
                    {isChannelEdit && (<TreeSelect {...tProps} />)}
                    {!isChannelEdit && defaultChannel.length > 0 && (<ul>
                        {defaultChannel.map((value)=>(<li key={value.id}><span className="channel-icon"><Img src={IMAGE_BASE_URL+value.icon}></Img> </span><span>{value.name}</span></li>))}
                        </ul>)}
                </div>
                
            </div>
            <div className="meta-targeting">
                <div className="meta-title"> <span>{defaultTarget && defaultTarget.length === 0 && ("ADD")} TARGETING</span> <span onClick={() => setIsTargetEdit(!isTargetEdit)} className="edit-icon"> <Icon component={iconEdit} className="left-btn-icon"/></span></div>
                <div className="meta-description">
                    {isTargetEdit && (<Select 
                        mode="tags" 
                        placeholder="Add targeting and press enter" 
                        className="target-input"
                        defaultValue={defaultTarget && defaultTarget.map((target)=> target.name)}
                        notFoundContent={targeting ? <Spin size="small" /> : null}
                        onSearch={handleSearch}
                        onDropdownVisibleChange={(open)=>setIsOpen(open)}
                        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                        autoFocus
                        onSelect={(text:string)=>{
                            if (defaultTarget.findIndex(i => i.name === text) === -1) {
                                handleAddOrRemove('targeting',text,'add');
                            }
                        }}
                        onDeselect={(value:any)=>{
                            handleAddOrRemove('targeting',value, 'delete', defaultTarget.findIndex(i => i.name === value));
                        }}
                        onBlur={(e:any)=>{
                            if(isTargetEdit) {
                                setIsTargetEdit(!isTargetEdit);
                            }
                        }}
                        listHeight={150}
                    >
                        {targeting && targeting.map((target)=>(
                            <SelectOption
                                className="options" 
                                value={target.value}>
                                    {target.value}
                            </SelectOption>
                        ))}
                    </Select>)} 
                    {!isTargetEdit && defaultTarget && (<ul>
                        {defaultTarget.map((value)=>(<li key={value.id}>{value.name}</li>))}
                        </ul>)}
                </div>
            </div>
        </div>
    );

}

export default MetaDataDesign;

