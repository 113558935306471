
import React, { useEffect } from 'react';
export default function PresetPopup({ preset, setPreset, setPresetPopup }) {
    useEffect(() => {
        setPreset(presets[0])
    }
        , [])
    return (
        <div className="preset-popup">
            {
                presets.map((item, index) => {
                    return (
                        <div className="preset-item" onClick={() => { setPreset(item); setPresetPopup(false) }}>Preset {` ${index + 1}`}</div>
                    )
                })
            }
        </div>
    )
}


const presets = [
    'Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, subtle vignette, f/1.8 for depth of field, ISO 200 --ar 16:9 --v 5.2 --s 250 --style raw -',
    ' Canon EOS-1D X Mark III, 24mm lens, f/2.8, natural lighting --ar 16:9 --v 5.2 --s 250 --style raw -',
    ' Taken using a Canon EOS 1D, Canon 18mm f/1.4, subtle vignette, f/1.8 for depth of field, ISO 2000 --ar 16:9 --v 5.2 --s 250 --style raw -'
]