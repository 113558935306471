import React, { useRef,useState } from 'react';
import './index.scss';
import { ApprovalState } from '../../../store/types';
import { useSelector,useDispatch } from 'react-redux';
import { setActiveApprovalStatus,setActiveApprovalEntityType } from '../../../store/actions';
import Menu from '../../shared/DropdownMenu';


const tabs:any = {
    "pending":{
    name: "Pending Approvals",
    style: {
      left: '6vw',
      width: '14vw'
    }
  },
  "approved":{
    name: "Approved",
    style: {
      left: '31vw',
      width: '14vw'
    }
},
"rejected":{
    name: "Rejected Approvals",
    style: {
      left: 'calc(100% - 19vw)',
      width: '14vw'
    }
}}   

const FilterBar:React.FC = () => {
    const state = useSelector((state:{approval:ApprovalState})=>(state.approval));
    const creativeTypeRef = useRef(null);
    const dispatch = useDispatch();
    const [openCreativeType,setOpenCreativeType] = useState(false);
    const { activeApprovalStatus,activeApprovalEntityType } = state;

    return <div className="approval-filterbar">
        <div className="filter">
            <div className="entitytype-filter">
                Filters
                <div className="dropdown" ref={creativeTypeRef} onClick={()=>setOpenCreativeType(true)}>
                    <span className="text">{activeApprovalEntityType? (activeApprovalEntityType === "project"?"Project":"Creative Automation Project"): "Creative Type"}</span>
                    <span>
                    <svg width="10" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.41 0.580078L6 5.17008L10.59 0.580078L12 2.00008L6 8.00008L0 2.00008L1.41 0.580078Z" fill="black"/>
                    </svg>
                    </span>
                </div>
                <Menu open={openCreativeType} anchorRef={creativeTypeRef} handleClose={()=>setOpenCreativeType(false)}>
                    <div className="dropdown-container">
                        <div className="dropdown-item" onClick={()=>{
                            setOpenCreativeType(false)
                            dispatch(setActiveApprovalEntityType("project"))
                        }}>
                            <span>
                                Project
                            </span>
                        </div>
                        <div className="dropdown-item" style={{ opacity: 0.5, cursor: "not-allowed" }} onClick={()=>{
                            // setOpenCreativeType(false)
                            // dispatch(setActiveApprovalEntityType("hpproject"))
                        }}>
                            <span>
                                Creative Automation Project
                            </span>
                        </div>
                    </div>
                </Menu>
            </div>
            {activeApprovalEntityType && <span className="danger" onClick={()=>{ dispatch(setActiveApprovalEntityType(null)) }}>Remove Filter</span>}
        </div>
        <div className="tabs">
            {Object.keys(tabs).map((tab,index)=>(
                <div key={tab} className={tab === activeApprovalStatus?"tab active": "tab"}
                    onClick={()=>{
                        if(tab !== activeApprovalStatus)
                        dispatch(setActiveApprovalStatus(tab))
                    }}
                >{tabs[tab].name}</div>
            ))}
            <hr className="highlight" style={tabs[activeApprovalStatus].style} />
        </div>
    </div>
}

export default FilterBar;