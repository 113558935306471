import React, { FC,useEffect } from 'react';
import {Card,Button} from 'pyxis-ui-kit'
import './index.scss';
import { CheckCircleFilled } from '@ant-design/icons';
import { RolesState } from '../../../store/types';
import { useSelector } from 'react-redux';
import { permissionDeniedClick } from '../../../utilities/common-function';


const CardItem: FC<{item: any, setSelectedType:Function,index:number,selectedType:string}> = ({item,setSelectedType,index,selectedType}) => {
  const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
  const isDisabled:boolean=item.permission ? userPermission.indexOf(item.permission) === -1 :false
  const handleClick=()=>{
    if(isDisabled){
      permissionDeniedClick()
    }else{
      setSelectedType(item.title)
    }
    
  }
  return <Button 
    tabIndex={index} 
    className={`card-btn ${selectedType===item.title?'selected-card':''} ${isDisabled ? 'disabled-card' : ''}`} 
    btnsize="xl" 
    onClick={handleClick}
    >
  <CheckCircleFilled className="check-circle"/>
  <img src={item.icon} className="icon"/>
  <span className={`card-item-title ${isDisabled? ' permission-denied-lock':''}`}>{item.title}</span>
  <div className="card-item-subtitle">{item.description}</div>
</Button>
}

export default CardItem;
