import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConversionContext } from '../../../ConversionContext/ConversionContext';
import { CreateContextAiquire } from '../../../CreateContextAiquire/CreateContextAiquire';
import { loading, createProject } from '../../../store/actions';

import './index.scss';
import { openToast } from '../../../Toasts';

import Typography, { Button } from 'pyxis-ui-kit';

const Title = Typography.Title;

const Footer: React.FC<{ layersWithAssets: any, allAssetsReady: boolean, projectId: any }> = ({ layersWithAssets, allAssetsReady, projectId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='backnext-btn-sec'>
      <div className='back-next-btnWrapper'>
        <Button className="back-btn" btnsize="lg" onClick={() => history.go(-1)}>
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 3L6 8L10 13" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span className='ml-2'>Go back</span>
        </Button>

        <Button
          className="continue-btn"
          type="primary"
          btnsize="lg"
          disabled={layersWithAssets?.results.length === 0}
          onClick={() => {
            if (allAssetsReady) {
              history.push(`/cocreate/creative-automation/editor/${projectId}`)
            } else {
              openToast('info', 'Some Assets are still processing!')
            }
          }}>
          <span className='mr-2'>Continue</span>
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 13L10 8L6 3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </Button>
      </div>
    </div>
  );
};

export default Footer;