import axios from "axios";
import { formatObjToURL } from "../common-function";
import { isNil } from "lodash";
import { API_URL, SUB_KEY, TOKEN } from "../../utilities/paths";

const formParams = (paramMap: [any, any][]) =>
  paramMap.reduce(
    (acc, [key, val]) =>
      isNil(val) ? acc : `${acc}${acc === "" ? "?" : "&"}${key}=${val}`,
    ""
  );

export function searchPexelImages(queryString: object) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/pexel/search/images?${formatObjToURL(
          queryString
        )}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchPixabayImages(queryString: object) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/pixabay/search/images?${formatObjToURL(queryString)}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchUnsplashImages(queryString: object) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/unsplash/search/images?${formatObjToURL(queryString)}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log('error while search unsplash images.', err);
        reject(err);
      });
  });
}

export const fetchDefaultSearchElementsAPI = async (params: any) => {
  let qs: string = formParams(Object.entries(params));
  // console.log('qs---------', qs);
  return axios.get(`${API_URL}/element${qs}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": SUB_KEY,
      token: TOKEN,
    },
  });
};

export const fetchDefaultImagesAPI = async (params: any) => {
  return axios.get(
    `${API_URL}/element/category?type=${params.type}&page=${params.page}`,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": SUB_KEY,
        token: TOKEN,
      },
    }
  );
};
