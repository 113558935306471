// Component used in
// 1. Rename folder (library,design,creative automation->same API)
// 2. Rename design project
// 3. Rename creative automation project
// 4. Create creative automation
// 5. Add template
import React, {FC,useEffect,useState} from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Button, Drawer, Input } from 'pyxis-ui-kit';
import { addProjectToTemplate, setProjects } from '../../../store/actions';
import { SuccessScreen } from '../SuccessScreen';
import { updateFolder,updateProject, updateHyperProjectDetails } from '../../../store/actions';
import { openToast } from '../../../Toasts';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss'
import { FolderState } from '../../../store/types';

interface EditAlertInterface{
    title:string,
    subTitle:string,
    label:string,
    isRenameDrawerVisible:boolean,
    successTitle:string,
    successSubTitle:string,
    projectType?:string,
    currentProject:any,
    setDrawerVisible:Function,
    drawerOpenFor?:string,
    createHyperProject?:Function
}

export const EditAlert:FC<EditAlertInterface>=({title,subTitle,label,isRenameDrawerVisible,successSubTitle,successTitle,projectType,currentProject,setDrawerVisible,drawerOpenFor,createHyperProject})=>{
    const state = useSelector((state: { folders: FolderState }) => (state.folders));
    const { banners } = state;
    const [rename,setRename]=useState('')
    const [isRenamed,setIsRenamed]=useState(false)
    const inputRef = React.useRef<any>(null);
    const dispatch = useDispatch()
    const [drawerLoader,setDrawerLoader]=useState(false)
    let renamed=drawerOpenFor==='addToTemplate'?'Template':
                drawerOpenFor==='creativeAutomation'?'Creative Automation':
                drawerOpenFor==="folderRename"?'Folder':
                drawerOpenFor==='rename'&&projectType === 'project'?'Project':'Creative automation'


    useEffect(()=>{
          setRename(currentProject.title||currentProject.name)
    },[isRenameDrawerVisible,currentProject])

    useEffect(()=>{
        setTimeout(()=>{
            inputRef.current!.focus({
                cursor: 'end',
            });
        },[50])
    },[inputRef])

    const closeDrawer=()=>{
        setDrawerVisible('')
        setRename('')
        setIsRenamed(false)
    }

    const handleRename=()=>{
        setDrawerLoader(true)
        
        if(rename.length===0){
            openToast('warn', 'Please enter a valid name')
            setDrawerLoader(false);
        }else if(rename.length>265){
            openToast('warn', `${renamed} name can have maximum 256 characters.`)
            setDrawerLoader(false);
        }else{
            if(drawerOpenFor==='addToTemplate'){
            dispatch(addProjectToTemplate({id:currentProject.id,title:rename},(res:any,err:boolean)=>{
                setDrawerLoader(false);
                setIsRenamed(true)
            })) 
            } else if(drawerOpenFor==='creativeAutomation'){
                if(createHyperProject){
                    createHyperProject({...currentProject,title: rename},(val:boolean)=>{
                        setDrawerLoader(val);
                        closeDrawer()
                    });
                }
            } else if(drawerOpenFor==="folderRename"){
                if(rename===currentProject.name){
                    openToast("warn", "Please make some changes before saving")
                    setDrawerLoader(false)
                }else{
                    dispatch(updateFolder({...currentProject,name:rename},(res:any,err:boolean)=>{
                        if(!err){
                            setIsRenamed(true)
                        }
                        setDrawerLoader(false)
                    }));
                }
                
            } else if(drawerOpenFor==='rename'){
                if(projectType === 'project'){
                    if(rename===currentProject.title){
                        openToast("warn", "Please make some changes before saving")
                        setDrawerLoader(false)
                    }else{
                        dispatch(updateProject({ projectId: currentProject.id,data: {title:rename}},(res:any,err:boolean)=>{
                            if(!err){
                                openToast('success','Project Name Updated!')
                                const projectIndex=banners.findIndex((project)=>project.id===currentProject.id)
                                banners[projectIndex].title = rename
                                dispatch(setProjects(banners))
                                setIsRenamed(true)
                            }else{
                                openToast('error','Something went wrong!')
                            }
                            setDrawerLoader(false)
                        }));
                    }
                }else if(projectType ==='hpproject'){
                    if(rename===currentProject.title){
                        openToast("warn", "Please make some changes before saving")
                        setDrawerLoader(false)
                    }else{
                        dispatch(updateHyperProjectDetails({projectId: currentProject.id,data: { title: rename }} ,(res:any,err:boolean)=>{
                            if(!err){
                                openToast('success','Project Name Updated!')
                                const projectIndex=banners.findIndex((project)=>project.id===currentProject.id)
                                banners[projectIndex].title = rename
                                dispatch(setProjects(banners))
                                setIsRenamed(true)
                            }else{
                                openToast('error','Something went wrong!')
                            }
                            setDrawerLoader(false)
                        }));
                    }
                }
            }
        }
        
    }
    
    
    const handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {handleRename()}
    }
    return <Drawer 
    className="rename-drawer" 
    closable={false}
    visible={isRenameDrawerVisible} 
    onClose={closeDrawer} 
    footer={
        <div className="form-drawer-footer">
            {!isRenamed ?
                <>
                    <Button type="link" className="upload-go-back" onClick={closeDrawer}>Cancel</Button>
                    <Button 
                        type="primary" 
                        className="upload-btn" 
                        onClick={()=>{
                            if(rename){
                                handleRename()
                            }
                        }}
                        loading={drawerLoader}
                        disabled={!rename}
                        >
                        <span>Save</span>
                        <span className="add-drawericon"><RightOutlined/></span>
                    </Button>
                </>
            :
            <div className="rename-footer">
                <Button type="primary" autoFocus className="Cancel" onClick={closeDrawer}>Close</Button>
            </div>
            }
        </div> 
    } 
    >
    {/* Add folder */}
    {!isRenamed ?
    <div className="edit-form">
        <h3 className="form-drawer-title">{title}</h3>
        <span className="form-drawer-subtitle">{subTitle}</span>
        <span className="form-drawer-label">{label}</span>
        <Input
            className="form-drawer-input"
            value={rename}
            onChange={(e:any)=>setRename(e.target.value)}
            onKeyDown={(e:any)=>handleKeyDown(e)}
            ref={inputRef}
        />
    </div>
    :  <>
    <SuccessScreen 
        title={successTitle}
        subTitle={successSubTitle}
    />
    </>
    }
    
</Drawer>
}