import React,{useState,useCallback,useEffect,useRef} from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder,faSpinner,faLevelUpAlt,faSearch,faCloudUploadAlt,faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchImagesAction,fetchAllFiles,uploadLibraryFile, deleteFiles,setImagesAction } from '../../../store/actions';
import { ImageTrayState } from '../../../store/types';
import { useSelector,useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {IMAGE_BASE_URL} from '../../../utilities/paths';
import EmptyPlaceHolder from '../EmptyPlaceHolder';
import Img from '../../ImageLoader/ImageLoader';
import { openToast } from '../../../Toasts';


interface GalleryPopUpProps {
    onCancel?: Function;
    onSubmit?: Function;
}

const tabs = [{
    name: "Stock Images",
    style: {
      left: '1.5rem',
      width: '14.0rem'
    }
},{
//   },{
//     name: "Favourites",
//     style: {
//       left: '18.3rem',
//       width: '11.5rem'
//     }
//   },{
    name: "Uploads",
    style: {
      left: 'calc(100% - 13.0rem)',
      width: '10.5rem'
    }
}]

const Upload: React.FunctionComponent<any> = ({type,setUploadedFile}) => {
    const upload = useRef(null);
    const dragCounter = useRef(0);
    const [loading,setLoader] = useState(false);
    const [dragging,setDragging] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () =>{
        let node:any = upload?.current;
        if(node){
            node?.click()
        }
    }

    const handleDrag = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    const handleDragOut = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current--
        if (dragCounter.current === 0) {
            setDragging(false)
        }
    }

    const handleDrop = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        let flag:boolean = false;
        let node:any = upload?.current;
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            Array.from(e.dataTransfer.files).forEach((f:any)=>{
                if(!['image/jpg','image/jpeg','image/png','image/gif'].includes(f.type)){
                    flag = true;
                }
            })
            if(flag){
                openToast('warn','This file type is not supported')
            }else{
                uploadImages(e.dataTransfer.files);
                e.dataTransfer.clearData()
                dragCounter.current = 0    
            }
        }
    }

    const uploadImages = (uploadFiles:any) => {
        let node:any = upload?.current;
        if(!loading) setLoader(true);
        dispatch(uploadLibraryFile({file: Array.from(uploadFiles),type},(res:any,err:boolean)=>{
            setLoader(false);
            if(node){
                node.value = null;
            }
            if(!err){
                let files:any[] = Array.isArray(res.data)?res.data:[res.data];
                setUploadedFile((file:any)=>{
                    let arr:any[] = [...files,...file.results||[]];
                    return {...file,results: arr}
                })
            }
        }))
    }

    return <div className="image" onClick={()=>handleClick()} 
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}    
    >
        <div className={dragging?"add-card dragging" :"add-card"}>
            <span className="icon">
                <FontAwesomeIcon icon={faCloudUploadAlt} />
            </span>
            <button className="primary">
                {loading && <span>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </span>}
                Upload File(s)
            </button>
            <input 
                ref={upload}
                style={{display: 'none'}}
                type="file" 
                multiple
                onChange={(e:any)=>{
                    uploadImages(e.target.files);
                }}
                accept="image/jpg,image/jpeg,image/png,image/gif"
            />
        </div>
    </div>
}

const Card:React.FC<any> = ({data,isSelected,handleImageSelect,handleDelete,type}) => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);

    return <div className="image">
        <Img src={data.largeImageURL||(IMAGE_BASE_URL + data.file_url)} />
        <div className={isSelected? "overlay show":"overlay"}>
            <div className="options">
                <div>
                    <label className="checkbox-container">
                        <input type="checkbox" checked={isSelected} onChange={()=>handleImageSelect(data)} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                {type === 'uploaded' && <div className="more">
                    <span
                        onClick={()=>{
                            if(!loading)
                            handleDelete([data.id],setLoading)
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                    {/* add to favourite */}
                    {/* <span>
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1 18.55L12 18.65L11.89 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 6 11.07 7.36H12.93C13.46 6 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55ZM16.5 3C14.76 3 13.09 3.81 12 5.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5C2 12.27 5.4 15.36 10.55 20.03L12 21.35L13.45 20.03C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z" fill="white"/>
                        </svg>
                    </span> */}
                </div>}
            </div>
        </div>
    </div>
}

const GalleryPopUp:React.FC<GalleryPopUpProps> = ({onCancel,onSubmit}) => {
    const state = useSelector(
        (state: { images: ImageTrayState }) => state.images
    );
    const dispatch = useDispatch();
    const galleryContainer = useRef(null);
    const [activeTab,setActiveTab] = useState(1);
    const [searchText,setSearchText] = useState("");
    const [loading,setLoader] = useState(false);
    const [uploadLoading,setUploadLoader] = useState(false);
    const [selectedImages,setSelectedImages] = useState<string[]>([]);
    const [uploadedFile,setUploadedFile] = useState<any>(null);
    const isAllSelected:boolean = activeTab === 1? (state.images.length === selectedImages.length && selectedImages.length !== 0)
        : ((uploadedFile?.results||[]).length === selectedImages.length && selectedImages.length !== 0)
    
    const handleSearch = useCallback((page:number = 1)=>{
        if(searchText.trim()){
            if(page === 1 && state.images.length > 0) { dispatch(setImagesAction([],state.page,state.totalPages||0,state.totalRecords,state.search)) }
            if(page === 1 && selectedImages.length > 0){ setSelectedImages([]) }
            dispatch(fetchImagesAction(searchText,page))
        }
    },[searchText,selectedImages])

    const handleImageSelect = useCallback((data:any)=>{
        let imgFound:any = selectedImages.findIndex((img:any)=>(activeTab === 1? img === data.largeImageURL: img === (IMAGE_BASE_URL + data.file_url)  ))
        if(imgFound === -1){
            setSelectedImages((imgs:string[])=>([...imgs,(activeTab === 1? data.largeImageURL: (IMAGE_BASE_URL + data.file_url))]))
        }else{
            setSelectedImages((imgs:string[])=>(imgs.filter((img:any,i:number)=>i!==imgFound)))
        }
    },[selectedImages.length,activeTab])

    const handleSelectAll = useCallback(()=>{
        let reducedImages:string[] = [];
        if(!isAllSelected){
            if(activeTab === 1){
                reducedImages = state.images.reduce((acc:any,val:any)=>([...acc,val.largeImageURL]),[])
            }else if(activeTab === 2){
                reducedImages = (uploadedFile?.results||[]).reduce((acc:any,val:any)=>([...acc,IMAGE_BASE_URL + val.file_url]),[])
            }
        }
        setSelectedImages(reducedImages)
    },[activeTab,state.images.length,uploadedFile,isAllSelected])

    const handleDelete = useCallback((ids:number[],setLoader)=>{
        setLoader(true);
        dispatch(deleteFiles(ids,(res:any,err:boolean)=>{
            setLoader(false)
            if(!err){
                setUploadedFile((up:any)=>{
                    return {...up,results: up.results.filter((data:any)=>ids.indexOf(data.id) === -1)};
                })
                fetchUploadedFiles(1);
            }
        }));
    },[setUploadedFile])
    
    const fetchUploadedFiles = useCallback((page:number)=>{
        setUploadLoader(true)
        dispatch(fetchAllFiles({type: 'hpproject',page},(res:any,err:boolean)=>{
            setUploadLoader(false)
            if(!err){
                if(page > 1){
                    setUploadedFile((files:any)=>({...res.data,results:[...files?.results,...res.data.results]}));
                }else{
                    setUploadedFile(res.data);
                }
            }
        }))
    },[setUploadedFile])

    useEffect(()=>{
        setSearchText('');
        setSelectedImages([])
        if(activeTab === 2){
            fetchUploadedFiles(1)
        }
    },[activeTab])
    console.log(state.page ,state.totalPages)
    console.log(state.images.length)
    return <div className="popup-overlay">
        <div className="gallery-popup">
            <div className="title">
                Select an image to add to layer
            </div>
            <div className="gallery-container" id="gallery-container" ref={galleryContainer}>
                <div className="top-bar">
                <div className="tabs">
                    {tabs.map((tab:any,index)=>(
                        <div key={tab.name} className={activeTab === (index+1)?"tab active": "tab"}
                        onClick={()=>setActiveTab(index+1)}
                        >{tab.name}</div>
                    ))}
                    {activeTab > -1 && <hr className="highlight" style={tabs[activeTab-1].style} />}
                </div>
                {activeTab === 1 && <div className="search-container">
                    <div className="search">
                        <input type="text" autoFocus placeholder="Search Images" 
                            value={searchText}
                            onChange={(e)=>{ 
                                setSearchText(e.target.value)
                            }}
                            onKeyPress={(e)=>{
                                if(e.key.toLowerCase() === 'enter' && activeTab === 1){
                                    handleSearch()
                                }
                            }}
                        />
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="black"/>
                        </svg>
                    </div>
                    <button className="primary" disabled={!!!searchText||state.loading} 
                        onClick={()=>{
                            if(activeTab === 1)
                            handleSearch()
                        }} 
                    >   
                        {state.loading && <span><FontAwesomeIcon icon={faSpinner} spin /></span>}
                        <span>Search</span>
                    </button>
                </div>}
                {(activeTab === 1? state.images.length > 0:(uploadedFile?.results||[]).length > 0) &&<div className="action">
                    {/* <input type="checkbox" checked={isAllSelected} onChange={()=>handleSelectAll()} />
                    <label onClick={()=>handleSelectAll()}>Select All11</label> */}
                </div>}
            </div>
                {activeTab === 1 && state.images.length === 0 && !uploadLoading && !state.loading&& <EmptyPlaceHolder icon={<FontAwesomeIcon icon={faSearch} />} text="Search for Stock Images..." />}
                {activeTab === 1 && <InfiniteScroll
                    dataLength={state.images.length}
                    next={()=>handleSearch(state.page + 1)}
                    hasMore={state.page < (state.totalPages || 1)}
                    loader={null}
                    className="images"
                    // height="calc(70vh - 31.6rem)"
                    scrollableTarget="gallery-container"
                >              
                    {state.images.map((image:any,index:number)=>(
                        <Card data={image} isSelected={selectedImages.indexOf(image.largeImageURL) > -1} 
                        handleImageSelect={handleImageSelect} type="stock" key={image.largeImageURL} />
                    ))}
                </InfiniteScroll>}
                {activeTab === 2 && <InfiniteScroll
                    dataLength={(uploadedFile?.results||[]).length}
                    next={()=>fetchUploadedFiles(uploadedFile?.current_page + 1)}
                    hasMore={uploadedFile?.current_page < (uploadedFile?.pages || 1)}
                    loader={null}
                    // height="calc(70vh - 25.3rem)"
                    className="images"
                    style={{overflow: 'auto !important'}}
                    scrollableTarget='gallery-container'
                >
                    <Upload type="hpproject" setUploadedFile={setUploadedFile} />
                    {(uploadedFile?.results||[]).map((image:any,index:number)=>(
                        <Card data={image} isSelected={selectedImages.indexOf(IMAGE_BASE_URL + image.file_url) > -1} 
                        handleImageSelect={handleImageSelect} handleDelete={handleDelete} type="uploaded" key={image.file_url} />
                    ))}
                </InfiniteScroll>}
                {(uploadLoading || state.loading) && <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} spin />
                </div>}
            </div>
            <div className="footer">
                <button className="secondary" disabled={loading} onClick={(e)=>{e.stopPropagation();  if(onCancel) onCancel();}}>
                    Cancel
                </button>
                <button className="primary" disabled={selectedImages.length === 0||loading}
                    onClick={()=>onSubmit && onSubmit(selectedImages,setLoader)}>
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                    Add
                </button>
            </div>
        </div>
    </div>
}

export default GalleryPopUp;