import React, { FC, useState } from "react";
import "./index.scss";
import { Drawer } from "pyxis-ui-kit";
import SuccessMedia from "../../../assets/svg/iconSuccess.svg";
import WarnMedia from "../../../assets/svg/iconWarning.svg";
import { Button } from "pyxis-ui-kit";

enum types {
  success,
  warn,
}

interface AlertInterface {
  type: types;
  title: string;
  subText: string;
  cancelButtonText: string;
  cancelButtonFunction?: Function;
  submitButtonText?: string;
  submitButtonFunction?: Function;
  handleClose?: Function;
  children: any;
}

const SuccessWarnDrawerComponent: FC<AlertInterface> = ({
  type,
  title,
  subText,
  cancelButtonText,
  submitButtonText,
  cancelButtonFunction,
  submitButtonFunction,
  handleClose,
  children,
}) => {
  const [isDrawerOpen, setDrawerVisibility] = useState(true);
  const [loading, setLoader] = useState(false);

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    if (handleClose) handleClose();
    if (cancelButtonFunction) cancelButtonFunction();
  };

  return (
    <Drawer
      className="drawerContainer"
      destroyOnClose
      visible={isDrawerOpen}
      closable={false}
      onClose={handleCloseDrawer}
      closeIcon={<></>}
      bodyStyle={{ padding: 32, position: "relative" }}
      footer={
        <div
          className="drawerFooter"
          style={{ display: type === 0 ? "block" : "flex" }}
        >
          <Button
            className={type === 1 ? "textButton" : ""}
            onClick={() => handleCloseDrawer()}
          >
            {cancelButtonText}
          </Button>
          {type === 1 && (
            <Button
              disabled={loading}
              loading={loading}
              onClick={() =>
                submitButtonFunction ? submitButtonFunction(setLoader) : null
              }
            >
              {submitButtonText}
            </Button>
          )}
        </div>
      }
    >
      <div className="contentContainer">
        <div className="mediaIcon">
          <img
            src={type === 0 ? SuccessMedia : WarnMedia}
            alt={type === 0 ? "Success" : "Warning"}
            className="media-img"
          />
        </div>
        <div className="titleContainer">
          <p className="mainTitle">{title}</p>
          <p className="subTitle">{subText}</p>
          {children && children}
        </div>
      </div>
    </Drawer>
  );
};

export default SuccessWarnDrawerComponent;