export interface IPoint {
  x: number;
  y: number;
}

export const isPointInRectangle = (point: IPoint, r: Array<IPoint>) => {
  if (!point || !r) {
    return false
  } 
  const [ax, ay] = [r[0].x, r[0].y];
  const [bx, by] = [r[1].x, r[1].y];
  const [cx, cy] = [r[2].x, r[2].y];
  const [dx, dy] = [r[3].x, r[3].y];

  const bax = bx - ax;
  const bay = by - ay;
  const cax = cx - ax;
  const cay = cy - ay;
  const dax = dx - ax;
  const day = dy - ay;

  const { x, y } = point;


  const toleranceB = (bax * bx + bay * by) * 0.05
  const toleranceC = (cax * cx + cay * cy) * 0.05
  const toleranceD = (dax * dx + day * dy) * 0.05
 
  if ((x - ax) * bax + (y - ay) * bay < -toleranceB) return false
  if ((x - bx) * bax + (y - by) * bay > toleranceB)  return false

  if ((x - ax) * cax + (y - ay) * cay < -toleranceC) return false
  if ((x - cx) * cax + (y - cy) * cay > toleranceC) return false

  if ((x - ax) * dax + (y - ay) * day < -toleranceD) return false
  if ((x - dx) * dax + (y - dy) * day > toleranceD)  return false

  return true;
};
