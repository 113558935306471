import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Menu, MenuItem, Checkbox, Input, Space, Button } from 'pyxis-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembersList, fetchTags } from '../../../store/actions';
import { openToast } from '../../../Toasts';
import { compareStrings } from '../../../utilities/common-function';

const TagsFilter:React.FC<{
    label:string;
    value: string[];
    projectType: string;
    changeFilter: Function;
}> = ({label,changeFilter,value,projectType}) => {
    const [visible,setVisible] = useState(false);
    const [tabKey,setTabKey] = useState('1');
    const [tags,setTags] = useState([]);
    const [autoMatedTags,setAutoMatedTags] = useState([]);
    const [selectedTags,setSelectedTags] = useState<string[]>([]);
    const pageData = useRef<any>(null);
    const dispatch = useDispatch();

    const getAllTags = (page:number) => {
        dispatch(fetchTags({page,page_size: 999,type: projectType},(res:any,err:boolean)=>{
            if(!err){
                pageData.current = {
                    count: res.data.count,
                    current_page: res.data.current_page,
                    page_size: res.data.page_size,
                    pages: res.data.pages
                }
                setTags(res.data.results.sort((a:any,b:any)=>compareStrings(a.name,b.name)));
                setAutoMatedTags(res.data.results.filter((tag:any)=>tag.auto_generated).sort((a:any,b:any)=>compareStrings(a.name,b.name)));
            }else{
                pageData.current = null;
                setTags([])
                setAutoMatedTags([])
                openToast('error','Unable to fetch Tags');
            }
        }))
    }

    useEffect(()=>{
        getAllTags(1);
    },[])

    return <div className="filter">
        <div className="label">{label}</div>
        <Dropdown 
        visible={visible}
        onVisibleChange={(flag)=>setVisible(flag)}
        trigger={['click']}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        overlay={<div className="tags-dropdown">
                {tags.map(({name,id},index)=>((name)&&
                    <div className="tags-item" key={id}>
                        <Checkbox style={{width: '100%'}}
                            checked={selectedTags.includes(name)} 
                            onChange={(e)=>{
                                if(e.target.checked){
                                    setSelectedTags((tags:string[])=>([...tags,name]))
                                }else{
                                    setSelectedTags((tags:string[])=>(tags.filter((tag:string)=> tag !== name )))
                                }
                            }}
                        >
                            <span className="tag-text text-ellipsis">{name}</span>
                        </Checkbox>
                    </div>
                ))}
                <div className="footer">
                    <button className="secondary" onClick={()=>{
                        setVisible(false);
                        setSelectedTags([])
                        changeFilter(null);
                    }}>
                        Cancel
                    </button>
                    <button className="primary" onClick={()=>{
                        setVisible(false);
                        changeFilter(selectedTags);
                    }}>
                        Apply
                    </button>
                </div>
            </div>
        }>
            <div className="action">
                <div className="name">
                    <span className={value.length === 1? "ml-10 text-ellipsis":"text-ellipsis"}>{value.length === 0? 'All Tags':value.join(',')}</span>
                    {value.length > 2 &&<span>{`+${value.length - 2}`}</span>}
                </div>
                <span className="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6L8 10L13 6" stroke="#8090E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
        </Dropdown>
    </div>
}

export default TagsFilter;