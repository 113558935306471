import { fabric } from 'fabric';
import 'fabric-history';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { ActiveObjectType } from '../components/shared/types';

type FabricCanvasContextPropsType = {
  children: React.ReactNode;
};

interface InitialPropsInterface {
  subType: string;
  name: string;
}

export type ExtendedCanvas = fabric.Canvas & { historyInit: () => void; undo: (callback?: any) => void; redo: () => void };
export type ExtendedObject = fabric.Object & { id: string; name: string; layerType: string; isBackground?: boolean };

type FabricContextType = {
  setActiveCanvasId: Function,
  fabricCanvasesDefault: ExtendedCanvas[],
  fabricCanvasesPreview: ExtendedCanvas[],
  fabricCanvasesHyper: ExtendedCanvas[],
  setFabricCanvasesDefault: Function,
  setFabricCanvasesPreview: Function,
  setFabricCanvasesHyper: Function,
  fabricCanvases: ExtendedCanvas[];
  fabricCanvas: ExtendedCanvas | undefined;
  setFabricCanvases: Function;
  setActiveFabricObjectType: Function;
  activeFabricObjectType: ActiveObjectType | null;
  activeCanvasId: string | null;
  activeObject: fabric.Object | null;
  setActiveObject: Function;
};

export const FabricCanvasContext = createContext({} as FabricContextType);

export const useFabricCanvas = () => useContext(FabricCanvasContext);

const FabricCanvasContextProvider = ({ children }: FabricCanvasContextPropsType) => {
  const [fabricCanvases, setFabricCanvases] = useState<ExtendedCanvas[]>([]);
  const [fabricCanvasesDefault, setFabricCanvasesDefault] = useState<ExtendedCanvas[]>([]);
  const [fabricCanvasesPreview, setFabricCanvasesPreview] = useState<ExtendedCanvas[]>([]);
  const [fabricCanvasesHyper, setFabricCanvasesHyper] = useState<ExtendedCanvas[]>([]);
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);
  const [activeCanvasId, setActiveCanvasId] =  useState('fabric-canvas-original');
  const [activeFabricObjectType, setActiveFabricObjectType] = useState<ActiveObjectType | null>(null);
  
  const fabricCanvas: any = useMemo(
    () =>
      fabricCanvases.find(
        (canvas) => canvas.getElement().id === activeCanvasId
      ) ||
      fabricCanvasesDefault.find(
        (canvas) => canvas.getElement().id === activeCanvasId
      ) ||
      fabricCanvasesPreview.find(
        (canvas) => canvas.getElement().id === activeCanvasId
      ) ||
      fabricCanvasesHyper.find(
        (canvas) => canvas.getElement().id === activeCanvasId
      ) ||
      undefined,
    [
      activeCanvasId,
      fabricCanvases,
      fabricCanvasesDefault,
      fabricCanvasesPreview,
      fabricCanvasesHyper,
    ]
  );

  return (
    <FabricCanvasContext.Provider
      value={{
        fabricCanvases,
        setFabricCanvases,
        fabricCanvas,
        setActiveFabricObjectType,
        activeFabricObjectType,
        activeObject,
        setActiveObject,
        setActiveCanvasId,
        fabricCanvasesDefault,
        fabricCanvasesPreview,
        setFabricCanvasesDefault,
        setFabricCanvasesPreview,
        activeCanvasId,
        fabricCanvasesHyper,
        setFabricCanvasesHyper,
      }}>
      {children}
    </FabricCanvasContext.Provider>
  );
};

export default FabricCanvasContextProvider;
