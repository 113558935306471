import React, { useEffect, useState } from 'react';
import './index.scss';
import ProjectCard from '../../DashboardSection/ProjectCard';
import { useSelector, useDispatch } from 'react-redux';
import { AuthState, LibraryState, MyTemplate,RolesState } from '../../../store/types';
import { fetchMyTemplates, getUsersData } from '../../../store/actions';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from '../../shared/Masonry';
import { TopFilter } from '../../shared/TopFilter';
import emptyTemplate from '../../../assets/svg/emptyTemplate.svg';
import { EmptyFile } from '../../shared/EmptyFile';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { enableEdit } from '../../../utilities/contants';

const Templates: React.FunctionComponent = () => {
    const state = useSelector((state: { library: LibraryState }) => (state.library));
    const { userData } = useSelector((state: {authentication: AuthState})=>(state.authentication));
    const { templates, templatePageData } = state;
    const dispatch = useDispatch();
    const [loading, setLoader] = useState(false);
    const history = useHistory();
    const [drawerVisible,setDrawerVisible]=useState('')
    const {userPermissions} = useSelector((state: {roles:RolesState})=>(state.roles))
    const isEditEnabled:boolean = enableEdit.some((val:string)=>(userData?.emailId.includes(val)));


    useEffect(() => {
        setLoader(true)
        dispatch(fetchMyTemplates({ page: 1 }, (res: any, err: boolean) => {
            setLoader(false)
        }))
    }, [])

    return (<React.Fragment>
        {!loading && templates.length === 0 ?
            <EmptyFile
            title="No templates here!"
            subtitle="Here will be shown templates that you can create in design section."
            img={emptyTemplate}
            btnTxt="Go to design"
            handleClick={() => { history.push('/cocreate/homepage'); }}
            /> : 
            <div className="library-templates" id="templates">    
                <TopFilter componentCaller="project" projectType="template"  drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} canManage={userPermissions.indexOf('create-new-banners') > -1}/>
                <div className="temaplte-container">
                <InfiniteScroll
                dataLength={templates.length}
                next={() => {
                    dispatch(fetchMyTemplates(templatePageData.current_page + 1))
                }}
                hasMore={(templatePageData?.current_page || 0) < (templatePageData?.pages || 0)}
                loader={<Loading3QuartersOutlined spin />}
                scrollableTarget="templates"
                style={{ overflow: 'none' }}
            >
                <Masonry breakpointCols={{
                    default: 4,
                    1200: 3,
                    1000: 2,
                    800: 1
                }}
                    className="masonry"
                    columnClassName="column"
                    loader={loading ? <div className="project-card">
                    <div className="card">
                        <div className="card-body pulsate"></div>
                    </div>
                    </div> : null}
                >
                    {templates.map((template: MyTemplate, index: number) => (
                        <ProjectCard key={template.id} type="template" data={template} projectIndex={index} componentCaller="project" isEditEnabled={isEditEnabled}/>
                    ))}
                </Masonry>
            </InfiniteScroll>
            </div>  
        </div>
        }
    </React.Fragment>
    )
}

export default Templates;