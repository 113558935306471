import React,{ FC,useEffect,useState } from 'react';
import {Prompt, useHistory} from 'react-router-dom';
import './index.scss';
import AlertPopUp from '../shared/AlertPopUp';
import Modal from './Modal';
import discardSvg from '../../../assets/svg/discard.svg';
import { Location } from 'history';
import { Button, Drawer, Footer } from 'pyxis-ui-kit';
import { SuccessScreen } from '../SuccessScreen';

interface Props {
  when?: boolean | undefined;
  navigate?: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  onSubmit: Function;
}
const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  onSubmit
}: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const history = useHistory();

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = (setLoader?:Function) => {
        setLoader && setLoader(true);
        setModalVisible(false);
        onSubmit(()=>{
            setLoader && setLoader(false);
            console.log(lastLocation)
            setConfirmedNavigation(true)
        })
    }; 

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            history.push(lastLocation.pathname+lastLocation.search);
        }
    }, [confirmedNavigation, lastLocation]);

    return (
        <>
            <Prompt
                when={when}
                message={handleBlockedNavigation}
            />
            {modalVisible && <Drawer 
                className="save-changes-popup" 
                placement="right" 
                closable={false} 
                onClose={closeModal} 
                visible={modalVisible}
                width={680}
                footer={<div className="footer">
                        <Button type="link" className="cancel" onClick={closeModal}>Cancel</Button>
                        <div className="discard-save">
                            <Button type="default" className="discard" onClick={()=>{
                                setModalVisible(false);
                                setConfirmedNavigation(true);
                            }}>Discard</Button>
                            <Button type="primary" className="save" onClick={()=>handleConfirmNavigationClick()}><span>Save</span></Button>
                        </div>
                    </div>
                }>
                {/* <AlertPopUp
                    title="Save your changes?"
                    text="You have unsaved changes, are you sure you want to leave?"
                    submittext="Save"
                    canceltext="Discard"
                    onSubmit={handleConfirmNavigationClick}
                    onCancel={()=>{
                        setModalVisible(false);
                        setConfirmedNavigation(true);
                    }}
                    isPrimary={true}
                /> */}
                <SuccessScreen 
                    title="You have unsaved changes" 
                    subTitle="Your project has unsaved changes. Do you want to discard all changes?"
                    successImg={discardSvg} />
            </Drawer>}
        </>
    )
}
export default RouteLeavingGuard;