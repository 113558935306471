import React, {FC} from 'react';
import './index.scss';
import {Title,Card,Button} from 'pyxis-ui-kit';
import {RightOutlined} from '@ant-design/icons';
import success from '../../../assets/svg/success.svg'


const CongratulationScreen:FC<{routePage:any,selectedType:any}> = ({routePage,selectedType}) => {
return <div className="congo-wrapper">
        <Title className="heading">Great Job! {selectedType} uploaded successfully</Title>
        <Title className="subheading" level={5}>Create your masterpiece here. Start from scratch or mix & match!</Title>
        <Card className="congo-card">
            <div className="img-container">
            <img src={success} className="success-img"/>
            </div>
            <div className="btn-container">
                <Button btnsize="md" 
                type="primary" 
                className="continue" 
                onClick={()=>{routePage(selectedType)}}>
                    Continue<RightOutlined className="right-arrow"/>
                </Button>
            </div>
        </Card>
    </div>
}

export default CongratulationScreen;