import { getAssetsCombinationDataAPI, updateAssetLayerDataAPI, updateAssetsCombinationDataAPI, updateAssetLayerDataBulkAPI, addAssetsAPI, deleteAssetAPI, updateHyperProjectDetailsAPI, renameHyperLayerAPI } from './../../MappedAPI/MappedAPI';
import { GET_ASSET_DATA, GET_ASSET_COMBINATION_DATA, UPDATE_PROJECT_HYPER, UPDATE_ASSET_COMBINATION_DATA, UPDATE_ASSET_DATA, GENERATE_COMBINATIONS, GET_ASSET_DATA_BULK, ADD_ASSETS, DELETE_ASSET, UPDATE_HYPER_PROJECT_DETAILS, RENAME_HYPER_LAYER, GET_ASSET_COMBINATION_DATA_SUCCESS } from './../types/hyperPersonalize';

import { put, takeLatest, call, all, takeEvery, select } from 'redux-saga/effects';
import { GET_LAYER_ASSETS, GET_LAYER_ASSETS_SUCCESS, GET_HYPER_PROJECT, GET_HYPER_PROJECT_SUCCESS, LOADING_HYPER_OFF } from "../types";
import { getHyperProjectAPI, getLayerAssetsAPI, getAssetLayerDataAPI } from '../../MappedAPI/MappedAPI';
import { DESIGN_URI } from '../../utilities/paths';
import HttpService from '../../HttpService/HttpService';

const getToken = (state: any) => state.authentication.token;
const getTokenOrPublic = (state: any) => (!window.location.hash.startsWith('#/cocreate/public') ? state.authentication.token : state.publicLink.token);

function* getHyperProjectSaga(action:any) {
  const TOKEN:any = yield select(getTokenOrPublic);
  // create url from params
  const { with_default_json, projectId } = action.params;
  const urlParams = `${projectId}?with_default_json=${with_default_json === false ? 'false' : 'true'}`;
  try {
    const response = yield call(HttpService.get, DESIGN_URI, `${getHyperProjectAPI}${urlParams}`, TOKEN);
    if (!response.data.error) {
      yield put({
        type: GET_HYPER_PROJECT_SUCCESS,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getLayerAssetsSaga(action:any) {
  const TOKEN:any = yield select(getTokenOrPublic);
  // create url from params
  const urlParams = `${action.params.projectId}/layers?page=1&page_size=999`;
  try {
    const response = yield call(HttpService.get, DESIGN_URI, `${getLayerAssetsAPI}${urlParams}`, TOKEN);
    if (!response.data.error) {
      yield put({
        type: GET_LAYER_ASSETS_SUCCESS,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getAssetSaga(action:any) {
  const TOKEN:any = yield select(getTokenOrPublic);
  const { projectId, layerId, assetId, sizeId } = action.params;
  const urlParams = `${projectId}/layer/${layerId}/asset/${assetId}?size_id=${sizeId}`;
  try {
    const response = yield call(HttpService.get, DESIGN_URI, `${getAssetLayerDataAPI}${urlParams}`, TOKEN);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* addAssetsSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  const { projectId, layerId, data } = action.params;
  const urlParams = `${projectId}/layer/${layerId}/assets`;
  try {
    const response = yield call(HttpService.post, DESIGN_URI, `${addAssetsAPI}${urlParams}`, TOKEN, data);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* deleteAssetSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  const { projectId, layerId, assetId } = action.params;
  const urlParams = `${projectId}/layer/${layerId}/asset/${assetId}`;
  try {
    const response = yield call(HttpService.delete, DESIGN_URI, `${deleteAssetAPI}${urlParams}`, TOKEN);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getAssetsBulkSaga(action:any) {
  const TOKEN:any = yield select(getTokenOrPublic);
  const { projectId, dataArr } = action.params;
  try {
    const response = yield all(dataArr.map((data:any) => {
      const { layerId, assetId, sizeId } = data;
      const urlParams = `${projectId}/layer/${layerId}/asset/${assetId}?size_id=${sizeId}`;
      return call(HttpService.get, DESIGN_URI, `${getAssetLayerDataAPI}${urlParams}`, TOKEN);
    }));
    if (!response.map((res:any) => res.data.error).includes(true)) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getAssetsCombinationSaga(action:any) {
  const TOKEN:any = yield select(getTokenOrPublic);
  const { projectId, combination, sizeId } = action.params;
  const urlParams = `${projectId}/dynamic-combination?size_id=${sizeId}&combination=${combination}`;
  try {
    const response = yield call(HttpService.get, DESIGN_URI, `${getAssetsCombinationDataAPI}${urlParams}`, TOKEN);
    if (!response.data.error) {
      yield put({
        type: GET_ASSET_COMBINATION_DATA_SUCCESS,
        payload: response.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* updateProjectHyperSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  try {
    // make url from action params
    const { projectId, assetData, combinationData } = action.params;
    const urlParams1 = `${projectId}/bulk-update-layer-json`;
    const urlParams2 = `${projectId}/dynamic-combination`;
    
    const response = yield all([
      call(HttpService.put, DESIGN_URI, `${updateAssetLayerDataBulkAPI}${urlParams1}`, TOKEN, assetData ),
      call(HttpService.put, DESIGN_URI, `${updateAssetsCombinationDataAPI}${urlParams2}`, TOKEN, combinationData)
    ]);
    if (!response.map((res:any) => res.data.error).includes(true)) {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* updateAssetSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  // create url from params
  const { projectId, assetId, layerId, data } = action.params;
  const urlParams = `${projectId}/layer/${layerId}/asset/${assetId}`;
  try {
    const response = yield call(HttpService.put, DESIGN_URI, `${updateAssetLayerDataAPI}${urlParams}`, TOKEN, data);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* updateAssetsCombinationSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  // create url from params
  const { projectId, data } = action.params;
  const urlParams = `${projectId}/dynamic-combination`;
  try {
    const response = yield call(HttpService.put, DESIGN_URI, `${updateAssetsCombinationDataAPI}${urlParams}`, TOKEN, data);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* updateHyperProjectDetailsSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  // create url from params
  const { projectId, data } = action.params;
  try {
    const response = yield call(HttpService.put, DESIGN_URI, `${updateHyperProjectDetailsAPI}${projectId}`, TOKEN, data);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* generateCombinationsSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  // create url from params
  const { projectId } = action.params;
  const urlParams = `${projectId}/generate-output`;
  try {
    const response = yield call(HttpService.post, DESIGN_URI, `${updateAssetsCombinationDataAPI}${urlParams}`, TOKEN, {});
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

function* renameHyperLayerSaga(action:any) {
  const TOKEN:any = yield select(getToken);
  // create url from params
  const { projectId, layerId, data } = action.params;
  const urlParams = `${projectId}/layers/${layerId}`;
  try {
    const response = yield call(HttpService.put, DESIGN_URI, `${renameHyperLayerAPI}${urlParams}`, TOKEN, data);
    if (!response.data.error) {
      yield put({
        type: LOADING_HYPER_OFF,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_HYPER_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_HYPER_OFF });
    action.callback && action.callback(error, true);
  }
}

export function* hyperPersonalizeWatcher() {
  yield takeLatest(GET_HYPER_PROJECT, getHyperProjectSaga);
  yield takeLatest(GET_LAYER_ASSETS, getLayerAssetsSaga);
  yield takeLatest(GET_ASSET_DATA, getAssetSaga);
  yield takeLatest(GET_ASSET_DATA_BULK, getAssetsBulkSaga);
  yield takeLatest(GET_ASSET_COMBINATION_DATA, getAssetsCombinationSaga);
  yield takeEvery(UPDATE_PROJECT_HYPER, updateProjectHyperSaga);
  yield takeEvery(UPDATE_ASSET_COMBINATION_DATA, updateAssetsCombinationSaga);
  yield takeEvery(UPDATE_ASSET_DATA, updateAssetSaga);
  yield takeLatest(GENERATE_COMBINATIONS, generateCombinationsSaga);
  yield takeLatest(ADD_ASSETS, addAssetsSaga);
  yield takeLatest(DELETE_ASSET, deleteAssetSaga);
  yield takeLatest(UPDATE_HYPER_PROJECT_DETAILS, updateHyperProjectDetailsSaga);
  yield takeEvery(RENAME_HYPER_LAYER, renameHyperLayerSaga);
}
