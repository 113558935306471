import React, { createContext, useContext, useState } from 'react';
import { ConversionContext } from '../ConversionContext/ConversionContext';

export const CreateContextAiquire = createContext<any>({} as any);


const CreateContextAiquireProvider = ({ children }: any) => {
  const { defaultConversionCanvasDataArr, conversionStep, defaultCurrentCanvas, previewConversionCanvasDataArr, setIsLoading, setViewModePreview, viewModePreview, previewCurrentCanvas, setPreviewCurrentCanvas } = useContext(ConversionContext);

  const [currentStep, setCurrentStep] = useState<'createNew' | 'selectSizes' | 'selectTemplate'>('createNew');
  const [createMode, setCreateMode] = useState<'single' | 'set' | ''>('');
  const [selectedSizes, setSelectedSizes] = useState<any[]>([]);
  const [populatedTemplates, setPopulatedTemplates] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [sizesArray, setSizesArray] = useState<any[]>([]);


  return (
    <CreateContextAiquire.Provider
      value={{
        currentStep,
        setCurrentStep,
        createMode,
        setCreateMode,
        selectedSizes,
        setSelectedSizes,
        populatedTemplates,
        setPopulatedTemplates,
        selectedCategory,
        setSelectedCategory,
        selectedTemplate,
        setSelectedTemplate,
        sizesArray,
        setSizesArray,
      }}
    >
      {children}
    </CreateContextAiquire.Provider>
  );
};

export default CreateContextAiquireProvider;
