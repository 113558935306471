import { ImageTrayState, SetImagesAction, SET_IMAGES, FETCH_IMAGES, FetchImagesAction, SET_PHOTOS_SEARCH, SetPhotosSearchAction,  SetDefaultImagesAction,
  FetchDefaultImagesAction, SET_DEFAULT_IMAGES, FETCH_DEFAULT_IMAGES } from "../types";

const initialState: ImageTrayState = {
  images: [],
  page: 1,
  totalPages: null,
  search: '',
  loading: false,
  noResultsError: false,
  totalRecords: 0,
  defaultImages: null,
  shapes: null,
  background: null,
  humanCutouts: null,
}

export const imagesTrayReducer = (state = initialState, action: SetImagesAction|FetchImagesAction|SetPhotosSearchAction| SetDefaultImagesAction
  | FetchDefaultImagesAction): ImageTrayState => {
  switch(action.type) {
    case FETCH_IMAGES:
      return {
        ...state,
        loading: true,
        noResultsError: false
      }
    case SET_PHOTOS_SEARCH:
        return {
          ...state,
          ...action.payload
        }
    case SET_IMAGES:
      return {
        ...state,
        ...action.payload,
        images: action.payload.page > 1 ? [...state.images, ...action.payload.images] : action.payload.images,
        noResultsError: action.payload.page === 1 && !action.payload.images.length
      }
    case FETCH_DEFAULT_IMAGES:
      return {
        ...state,
        noResultsError: false,
        loading: true,
      };
    case SET_DEFAULT_IMAGES:
      switch (action.payload.params.type) {
        case 'shape':
          if (action.payload.params.page > 1) {
            const categories = [
              ...state.shapes.categories,
              ...action.payload.categories,
            ];
            action.payload.categories = categories;
          }
          return {
            ...state,
            shapes: action.payload,
            loading: false,
          };
        case 'human_cutout':
          if (action.payload.params.page > 1) {
            const categories = [
              ...state.humanCutouts.categories,
              ...action.payload.categories,
            ];
            action.payload.categories = categories;
          }
          return {
            ...state,
            humanCutouts: action.payload,
            loading: false,
          };
        case 'background':
          if (action.payload.params.page > 1) {
            const categories = [
              ...state.background.categories,
              ...action.payload.categories,
            ];
            action.payload.categories = categories;
          }
          return {
            ...state,
            background: action.payload,
            loading: false,
          };
        default:
          if (action.payload.params.page > 1) {
            const categories = [
              ...state.defaultImages.categories,
              ...action.payload.categories,
            ];
            action.payload.categories = categories;
          }
          return {
            ...state,
            defaultImages: action.payload,
            loading: false,
          };
        }
    default:
      return state
  }
}