import React, { useState } from "react";
import { Dropdown, Menu, MenuItem, SubMenu, Checkbox } from "pyxis-ui-kit";
import { getStatusColor } from "../../../utilities/common-function";
import { Select, SelectOption } from "pyxis-ui-kit";
import "./index.scss";
const SearchType: React.FC<{
  label: string;
  changeFilter: Function;
  value: string;
  icon?: any;
}> = ({ label, icon, changeFilter, value }) => {
  return (
    <div className='filter'>
      <div className='label'>{label}</div>
      <Select
        className='quality'
        defaultValue={
          value === "True"
            ? "Contains the search term"
            : "Items with exact search term"
        }
        onSelect={(value) => changeFilter(value)}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      >
        <SelectOption value='True'>Contains the search term </SelectOption>
        <SelectOption value='False'>Items with exact search term</SelectOption>
      </Select>
    </div>
  );
};

export default SearchType;
