import React, { useState, useEffect, useCallback, useRef } from "react";
import { faCommentDots, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { throttle } from "lodash";
import Lottie from "react-lottie";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import iconSend from "../../../../assets/svg/iconSend.svg";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  ApprovalState,
  AuthState,
  FolderState,
  HyperProjectFoldersState,
  RolesState,
} from "../../../../store/types";
import {
  addApprovalComment,
  changeApprovalComment,
  getUsersData,
  fetchMembersList,
  fetchApprovalComments,
  setApprovalComments,
} from "../../../../store/actions";
import { openToast } from "../../../../Toasts";
import {
  extractMentions,
  permissionDeniedClick,
} from "../../../../utilities/common-function";
import commentAnimation from "../../../../assets/animation/comment.json";
import EditorInput from "../../../shared/EditorInput/index";
import Comments from "./Comments";
import { Tooltip } from "pyxis-ui-kit";

interface FooterInterface {
  entityId: number;
  canComment: boolean;
  userData: any;
  scrollToBottom: Function;
  client: WebSocket;
  entityType: string;
  pendingApprovalCount: number;
  userSuggestions: any[];
  selectedBanner: any;
  replyCommentIndex: number;
}

const tabs = [
  { name: "Approval", icon: faThumbsUp },
  { name: "Activity", icon: faCommentDots },
];
const getTagsArr = (mentions: any[], usersMap: any) =>
  mentions.reduce((acc: any, val: any) => {
    if (usersMap.has(val)) {
      const userData: any = usersMap.get(val);
      return [
        ...acc,
        {
          ...userData,
          id: userData._id,
          name: `${userData.firstName} ${userData.lastName}`,
        },
      ];
    }
    return acc;
  }, []);

const Footer: React.FunctionComponent<FooterInterface> = ({
  entityId,
  canComment,
  userData,
  client,
  scrollToBottom,
  entityType,
  pendingApprovalCount,
  userSuggestions,
  selectedBanner,
  replyCommentIndex
}) => {
  const [comment, setComment] = useState("");
  const [showStatusChange, setShowStatusChange] = useState(false);
  const [alertPopUpProps, setAlertPopUpProps] = useState<any>(null);
  const [loading, setLoader] = useState<any>(-1);
  const editorInputRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { bannerData } = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const commentResolution = useSelector(
    (state: { approval: ApprovalState }) => state.approval.comments_resolution
  );

  const handleSubmit = useCallback(
    (comment: string) => {
      if (canComment) {
        if (!entityId || !comment.trim()) return;
        editorInputRef?.current?.clearEditorState();
        let child_id = selectedBanner !== -1 ? bannerData?.banners[selectedBanner]?.id : undefined;
        if(client.readyState === 1) {
          if(replyCommentIndex !== -1) {
            client.send(
              JSON.stringify({
                comment: comment,
                parent_comment_id: replyCommentIndex,
                child_id
              })
            );
          } else {
            client.send(JSON.stringify({ comment, child_id }));
          }
        }
      } else {
        permissionDeniedClick();
      }
    },
    [comment, entityId, bannerData, selectedBanner, client]
  );

  const handlePressEnter = useCallback(() => {
    handleSubmit(editorInputRef?.current?.getRawText());
  }, [handleSubmit]);

  return (
    <Tooltip placement="top" title={commentResolution === 1 ? "Select 'Show un-resolved' from the filter to add new comment" : ""} content={
      <div className={commentResolution === 1 ? "footer read-only" : "footer"}>
        <div className="footer-comment-text">
          <EditorInput
            ref={editorInputRef}
            placeholder={`Enter your ${replyCommentIndex === -1 ? "comment" : "reply"}`}
            onPressEnter={handlePressEnter}
            mentions={userSuggestions}
            className="footer-editor"
            readOnly={commentResolution === 1}
          />
          <button
            className="submit-btn"
            onClick={() => handleSubmit(editorInputRef?.current?.getRawText())}
          >
            <img src={iconSend} />
          </button>
        </div>
      </div>
    }>
    </Tooltip>
  );
};

const CommentsTab: React.FunctionComponent<{
  entityType: string;
  setSelectedBanner: Function;
  selectedBanner: any;
  commentIndex: number;
  setCommentIndex: Function;
  replyCommentIndex: number;
  setReplyCommentIndex: Function;
  retainedComments: any;
  client: WebSocket;
  setClientActions: Function;
  setInFocus: Function;
}> = ({
  entityType,
  selectedBanner,
  setSelectedBanner,
  commentIndex,
  setCommentIndex,
  replyCommentIndex,
  setReplyCommentIndex,
  retainedComments,
  client,
  setClientActions,
  setInFocus
}) => {
  const authState = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const { userPermissions, selectedProductId }: any = useSelector(
    (state: { roles: RolesState }) => state.roles
  );
  const projectData = useSelector(
    (state: { folders: FolderState }) => state.folders.bannerData
  );
  const hyperProjectData = useSelector(
    (state: { hyperProject: HyperProjectFoldersState }) =>
      state.hyperProject.hyperProjectData
  );
  const state = useSelector(
    (state: { approval: ApprovalState }) => state.approval
  );
  const [loading, setLoader] = useState(false);

  const { comments, pendingApprovalCount } = state;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const node: any = document.getElementById("active-section");
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get("banner");
  const commentsRef = useRef(null);
  const isFirstTime = useRef(true);
  const beforeScrollHeight = useRef(0);
  const beforeScrollTop = useRef(0);
  const [pageData, setPageData] = useState<any>(null);
  const usersMap = useRef(new Map());
  const { token, userData } = authState;
  const [userSuggestions, setUserSuggestion] = useState([]);
  const commentResolution = useSelector(
    (state: { approval: ApprovalState }) => state.approval.comments_resolution
  );
  const { bannerData } = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const currentChildIDActive = selectedBanner !== -1 ? bannerData?.banners[selectedBanner]?.id : -1;

  useEffect(() => {
    if (userData) {
      usersMap.current.set(userData.userId, {
        ...userData,
        _id: userData.userId,
      });
    }
  }, [userData]);

  const mantainScroll = () => {
    const node: any = document.getElementById("active-section");
    if (node) {
      node.scrollTop =
        node.scrollHeight -
        beforeScrollHeight.current +
        beforeScrollTop.current;
    }
  };

  const scrollToBottom = () => {
    const commentsContainer: any = commentsRef?.current;
    if (commentsContainer) {
      commentsContainer.scrollIntoView({
        behavior: isFirstTime.current ? "auto" : "smooth",
        block: "end",
        inline: "nearest",
      });
      if (isFirstTime.current) {
        isFirstTime.current = false;
      }
    }
  };

  const groupComment = (comments) => {
    let lastChildId = null;
    let child_name = null;
    let child_index = null;
    return comments.reduce((acc, comment) => {
      if (comment.child_id === null) {
        lastChildId = null;
        return [...acc, comment];
      }
      if (comment.child_id !== lastChildId) {
        const found = bannerData.banners.findIndex(
          (element: any) => element.id === parseInt(comment.child_id)
        );
        if (found >= 0) {
          child_name = bannerData.banners[found].size_name;
          child_index = found;
        }
        acc = [
          ...acc,
          {
            child_id: comment.child_id,
            child_name,
            child_index,
            comments: [comment],
          },
        ];
      }
      if (comment.child_id === lastChildId) {
        acc[acc.length - 1].comments.push(comment);
      }
      lastChildId = comment.child_id;
      return acc;
    }, []);
  };

  const fetchComments = useCallback(
    (page: number, resolution_status: number, child_id?: number) => {
      if (banner) {
        const id: number = parseInt(banner);
        setLoader(true);
        if (!pageData) isFirstTime.current = true;
        dispatch(
          fetchApprovalComments(
            {
              entity_id: id,
              page,
              entity_type: entityType,
              order_by: "created_at",
              child_id,
              resolution_status,
            },
            (response: any, error: any) => {
              if (!error) {
                const {
                  data: { results },
                } = response;
                results.reverse();
                setPageData({
                  count: response.data.count,
                  current_page: response.data.current_page,
                  page_size: response.data.page_size,
                  pages: response.data.pages,
                });
                if (results.length > 0) {
                  let ids: string[] = results.reduce((acc: any, val: any) => {
                    let newAcc: string[] = [];
                    const mentions = extractMentions(val.comment).map(
                      (m: any) => m && m.substring(3, m.length - 3)
                    );
                    mentions.forEach((m: any) => {
                      if (!usersMap.current.has(m)) {
                        newAcc = [...newAcc, m];
                      }
                    });
                    if (!usersMap.current.has(val.user_id)) {
                      newAcc = [...newAcc, val.user_id];
                    }
                    return [...acc, ...newAcc];
                  }, []);
                  ids = ids.filter(
                    (id: string) =>
                      id.match(/^(0x|0h)?[0-9A-F]+$/i) && id.length === 24
                  );
                  if (ids.length > 0) {
                    dispatch(
                      getUsersData(
                        { userIds: ids },
                        (userres: any, usererr: boolean) => {
                          if (!usererr) {
                            const { users } = userres.data;
                            users.map((u: any) => {
                              if (!usersMap.current.has(u._id.toString())) {
                                usersMap.current.set(u._id.toString(), u);
                              }
                            });
                            results.map((a: any, r: number) => {
                              const mentions = extractMentions(a.comment).map(
                                (m: any) => m && m.substring(3, m.length - 3)
                              );
                              mentions.forEach((m: string) => {
                                const u: any = usersMap.current.get(m) || null;
                                if (u) {
                                  a.comment = a.comment.replaceAll(
                                    `@~~${m}~~@`,
                                    `<span>@${u?.firstName} ${u?.lastName}</span>`
                                  );
                                }
                              });
                              a.tagsArr = getTagsArr(
                                mentions,
                                usersMap.current
                              );
                              a.user_id =
                                usersMap.current.get(a.user_id.toString()) ||
                                null;
                            });
                            setLoader(false);
                            if (response.data.current_page > 1) {
                              dispatch(
                                setApprovalComments([
                                  ...results,
                                  ...(selectedBanner === -1
                                    ? groupComment(results)
                                    : results),
                                ])
                              );
                              mantainScroll();
                            } else {
                              dispatch(
                                setApprovalComments(
                                  selectedBanner === -1
                                    ? groupComment(results)
                                    : results
                                )
                              );
                              scrollToBottom();
                            }
                          }
                        }
                      )
                    );
                  } else {
                    results.map((a: any, r: number) => {
                      const mentions = extractMentions(a.comment).map(
                        (m: any) => m && m.substring(3, m.length - 3)
                      );
                      mentions.forEach((m: string) => {
                        const u: any = usersMap.current.get(m) || null;
                        if (u) {
                          a.comment = a.comment.replaceAll(
                            `@~~${m}~~@`,
                            `<span>@${u?.firstName} ${u?.lastName}</span>`
                          );
                        }
                      });
                      a.tagsArr = getTagsArr(mentions, usersMap.current);
                      a.user_id =
                        usersMap.current.get(a.user_id.toString()) || null;
                    });
                    setLoader(false);
                    if (response.data.current_page > 1) {
                      dispatch(
                        setApprovalComments([
                          ...results,
                          ...(selectedBanner === -1
                            ? bannerData
                              ? groupComment(results)
                              : []
                            : results),
                        ])
                      );
                      mantainScroll();
                    } else {
                      dispatch(
                        setApprovalComments(
                          selectedBanner === -1
                            ? groupComment(results)
                            : results
                        )
                      );
                      scrollToBottom();
                    }
                  }
                } else {
                  setLoader(false);
                  if (response.data.current_page === 1)
                    dispatch(setApprovalComments([]));
                }
              } else {
                setLoader(false);
                openToast("error", "Not able to fetch comments!");
              }
            }
          )
        );
      }
    },
    [banner, setPageData, comments, selectedBanner]
  );

  const throttledFetchComments = useCallback(
    throttle((e: any) => {
      beforeScrollHeight.current = node.scrollHeight;
      beforeScrollTop.current = node.scrollTop;
      if (pageData?.current_page < pageData?.pages) {
        if (e.target.scrollTop < 50 && !loading) {
          fetchComments(
            pageData ? pageData.current_page + 1 : 1,
            commentResolution
          );
        }
      } else {
        node.removeEventListener("scroll", throttledFetchComments);
      }
    }, 200),
    [pageData, loading]
  );

  const getUsersList: any = useCallback(
    () =>
      new Promise((resolve) => {
        dispatch(
          fetchMembersList(
            {
              entityType: "product",
              entityId: selectedProductId || undefined,
              permissions: "view-all-assets",
            },
            (res: any, err: boolean) => {
              if (!err) {
                resolve(res?.data?.users || []);
              } else {
                resolve([]);
                openToast("error", "Unable to fetch Members!");
              }
            }
          )
        );
      }),
    [selectedProductId]
  );

  const getUserSuggestions = useCallback(async () => {
    const users: any[] = await getUsersList();
    setUserSuggestion(
      users.map((val: any) => ({
        ...val,
        id: val._id,
        name: `${val.firstName} ${val.lastName}`,
      }))
    );
  }, []);

  useEffect(() => {
    fetchComments(1, commentResolution, (currentChildIDActive || -1));
    getUserSuggestions();
    if (client) {
      setClientActions((val: any) => ({
        ...val,
        onError: (err: any) => {
          openToast("error", "Not able to connect!");
        },
        onRecieve: (data: any) => {
          const { user_id } = data;
          const mentions = extractMentions(data.comment).map(
            (m: any) => m && m.substring(3, m.length - 3)
          );
          let userIds: string[] = [];
          mentions.forEach((m: string) => {
            if (!usersMap.current.has(m)) {
              userIds = [...userIds, m];
            }
          });
          if (!usersMap.current.has(user_id.toString())) {
            userIds = [...userIds, user_id];
          }
          userIds = userIds.filter(
            (id: string) => id.match(/^(0x|0h)?[0-9A-F]+$/i) && id.length === 24
          );
          if(commentResolution === 1 && data.parent_comment_id) {
            data.resolved_status = true;
          }
          let isExistingComment:boolean = comments.some((c:any) => c.id === data.id);
          if(commentIndex === -1 && !data.parent_comment_id && data.pin_number && !isExistingComment) {
            setCommentIndex(data.id);
          }
          if (userIds.length > 0) {
            dispatch(
              getUsersData({ userIds }, (userres: any, usererr: boolean) => {
                if (!usererr) {
                  const { users } = userres.data;
                  users.forEach((u: any) => {
                    usersMap.current.set(u._id.toString(), u);
                  });
                  mentions.forEach((m: string) => {
                    const u: any = usersMap.current.get(m) || null;
                    if (u) {
                      data.comment = data.comment.replaceAll(
                        `@~~${m}~~@`,
                        `<span>@${u?.firstName} ${u?.lastName}</span>`
                      );
                    }
                  });
                  data.tagsArr = getTagsArr(mentions, usersMap.current);
                  data.user_id =
                    usersMap.current.get(data.user_id.toString()) || null;
                  dispatch(addApprovalComment(data));
                } else {
                  mentions.forEach((m: string) => {
                    const u: any = usersMap.current.get(m) || null;
                    if (u) {
                      data.comment = data.comment.replaceAll(
                        `@~~${m}~~@`,
                        `<span>@${u?.firstName} ${u?.lastName}</span>`
                      );
                    }
                  });
                  data.tagsArr = getTagsArr(mentions, usersMap.current);
                  data.user_id =
                    usersMap.current.get(data.user_id.toString()) || null;
                  dispatch(addApprovalComment(data));
                }
                scrollToBottom();
              })
            );
          } else {
            mentions.forEach((m: string) => {
              const u: any = usersMap.current.get(m) || null;
              if (u) {
                data.comment = data.comment.replaceAll(
                  `@~~${m}~~@`,
                  `<span>@${u?.firstName} ${u?.lastName}</span>`
                );
              }
            });
            data.tagsArr = getTagsArr(mentions, usersMap.current);
            data.user_id =
              usersMap.current.get(data.user_id.toString()) || null;
            dispatch(addApprovalComment(data));
            scrollToBottom();
          }
        },
      }));
    }
  }, [selectedBanner, retainedComments, commentResolution]);

  useEffect(() => {
    if (node && comments.length > 0) {
      node.addEventListener("scroll", throttledFetchComments);
    }

    return () => {
      if (node && comments.length > 0) {
        node.removeEventListener("scroll", throttledFetchComments);
      }
    };
  }, [pageData, comments]);

  const updateComment = (data: any, index: number) => {
    const mentions = extractMentions(data.comment).map(
      (m: any) => m && m.substring(3, m.length - 3)
    );
    let userIds: string[] = [];
    mentions.forEach((m: string) => {
      if (!usersMap.current.has(m)) {
        userIds = [...userIds, m];
      }
    });
    userIds = userIds.filter(
      (id: string) => id.match(/^(0x|0h)?[0-9A-F]+$/i) && id.length === 24
    );
    if (userIds.length > 0) {
      dispatch(
        getUsersData({ userIds }, (userres: any, usererr: boolean) => {
          if (!usererr) {
            const { users } = userres.data;
            users.forEach((u: any) => {
              usersMap.current.set(u._id.toString(), u);
            });
            mentions.forEach((m: string) => {
              const u: any = usersMap.current.get(m) || null;
              if (u) {
                data.comment = data.comment.replaceAll(
                  `@~~${m}~~@`,
                  `<span>@${u?.firstName} ${u?.lastName}</span>`
                );
              }
            });
            data.tagsArr = getTagsArr(mentions, usersMap.current);
            dispatch(changeApprovalComment(data, index));
          } else {
            mentions.forEach((m: string) => {
              const u: any = usersMap.current.get(m) || null;
              if (u) {
                data.comment = data.comment.replaceAll(
                  `@~~${m}~~@`,
                  `<span>@${u?.firstName} ${u?.lastName}</span>`
                );
              }
            });
            data.tagsArr = getTagsArr(mentions, usersMap.current);
            dispatch(changeApprovalComment(data, index));
          }
        })
      );
    } else {
      mentions.forEach((m: string) => {
        const u: any = usersMap.current.get(m) || null;
        if (u) {
          data.comment = data.comment.replaceAll(
            `@~~${m}~~@`,
            `<span>@${u?.firstName} ${u?.lastName}</span>`
          );
        }
      });
      data.tagsArr = getTagsArr(mentions, usersMap.current);
      dispatch(changeApprovalComment(data, index));
    }
  };
  return (
    <div className="comments-tab" ref={commentsRef}>
      {/* {approvalStatusSummary && <Heading requestedBy={approvalStatusSummary?.requested_by || ""} requestedOn={approvalStatusSummary?.requested_on || ""}  />} */}
      {loading && (
        <div className="loader">
          <Loading3QuartersOutlined />
        </div>
      )}
      {!loading && comments.length === 0 && (
        <div className="empty-overlay">
          <Lottie
            options={{
              animationData: commentAnimation,
              loop: true,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width={200}
            height={200}
          />
        </div>
      )}

      {!loading && (
        <Comments
          comments={comments}
          userData={userData}
          userSuggestions={userSuggestions}
          updateComment={updateComment}
          client={client}
          canComment={userPermissions.indexOf("add-new-comments") > -1}
          setSelectedBanner={setSelectedBanner}
          selectedBanner={selectedBanner}
          fetchComments={fetchComments}
          commentIndex={commentIndex}
          setCommentIndex={setCommentIndex}
          replyCommentIndex={replyCommentIndex}
          setReplyCommentIndex={setReplyCommentIndex}
          setInFocus={setInFocus}
        />
      )}
      <Footer
        entityId={banner ? parseInt(banner) : 0}
        client={client}
        scrollToBottom={scrollToBottom}
        canComment={userPermissions.indexOf("add-new-comments") > -1}
        userData={userData}
        entityType={entityType}
        pendingApprovalCount={pendingApprovalCount}
        userSuggestions={userSuggestions}
        selectedBanner={selectedBanner}
        replyCommentIndex={replyCommentIndex}
      />
    </div>
  );
};

export default CommentsTab;
