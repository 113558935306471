import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    Button,
    makeStyles
} from '@material-ui/core';
import { DeleteOutline as DeleteIcon, OpenInNew as ExportIcon } from '@material-ui/icons';
import httpService from '../../HttpService/HttpService';
import { useSelector } from 'react-redux';
import { RolesState } from '../../store/types';
import moment from 'moment';
import { Tooltip } from 'pyxis-ui-kit';
const data = [
    { slNo: '01', heading: 'This is just some text', date: '21/02/23' },
    { slNo: '02', heading: 'This is just some text', date: '21/02/23' },
    { slNo: '03', heading: 'This is just some text', date: '21/02/23' },
    { slNo: '04', heading: 'This is just some text', date: '21/02/23' },
];




const useStyles = makeStyles({
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0',
    },
    cell: {
        border: 'none',
        padding: '8px',
        fontSize: '12px',
        height: "54px",
        maxWidth: '300px'
    },
    actionCell: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '160px'
    },
    deleteCell: {
        minWidth: '200px',
        display: 'flex',
        JustifyContent: 'flex-start',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#EEEEFF',
        },
        height: '48px'
    },
    headerRow: {
        backgroundColor: '#FCFCFF',
        '& .MuiTableCell-root': {
            // padding: '16px', // Adjust the padding as per your requirements
        },
    },
    deleteButton: {
        backgroundColor: '#FFF9F9',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        color: '#F16060',
        fontSize: '14px',
        padding: '6px 12px',
        lineHeight: '1.43',
        alignSelf: 'center',
        height: '28px',
    },
    cancelDeleteButton: {
        background: '#FFF9F9',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        padding: '6px 12px',
        fontSize: '12px',
        // width: '64px',
        height: '28px',
        lineHeight: '1.43',
        alignSelf: 'center',
        color: '#F16060',
    },
    confirmDeleteButton: {
        padding: '6px 12px',
        fontSize: '12px',
        // width: '64px',
        height: '28px',
        background: '#F16060',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        color: 'white',
        marginLeft: '2rem',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        alignSelf: 'center'
    },
    deleteIcon: {
        color: '#F16060',
        fontSize: '14px',
    },
    deletingRow: {
        background: '#FFEBEB',
    }
});

const MyTable = ({ handleMyCreationDetailsDialog, deletingRowIndex, handleConfirmDelete, handleDeleteRow, handleCancelDelete, prompts }) => {


    const classes: any = useStyles();


    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell className={`${classes.cell}`}>Sl.no</TableCell>
                        <TableCell className={`${classes.cell}`} style={{ minWidth: '350px' }}>Heading</TableCell>
                        <TableCell className={`${classes.cell}`}>Date</TableCell>
                        <TableCell className={`${classes.cell} ${classes.actionCell}`}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prompts.map((row, index) => (
                        <TableRow
                            key={index}
                            className={`${index === deletingRowIndex ? classes.deletingRow : classes.hoverRow}`}
                        >
                            <TableCell className={classes.cell} style={{ paddingLeft: "3rem", paddingRight: '8rem' }}>0{index + 1}</TableCell>
                            {/* <TableCell className={classes.cell}>{row?.output?.slice(0, 35)}</TableCell> */}
                            <TableCell className={classes.cell} >{row?.request.scenario}</TableCell>
                            <TableCell className={classes.cell}>{moment(row.createdAt).format("DD/MM/YYYY")}</TableCell>
                            {/* <TableCell className={`${classes.cell} ${classes.actionCell}`}>
                                {index === deletingRowIndex ? (
                                    <>
                                        <Button
                                            className={classes.cancelDeleteButton}
                                            onClick={handleCancelDelete}
                                            variant="outlined"
                                            style={{ textTransform: 'none', color: "black" }}
                                        >
                                            Cancel
                                        </Button>
                                        <button
                                            className={classes.confirmDeleteButton}
                                            onClick={() => handleConfirmDelete(row._id)}
                                        >
                                            Confirm Delete
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <IconButton
                                            className={classes.deleteButton}
                                            aria-label="Delete"
                                            onClick={() => handleDeleteRow(index)}
                                        >
                                            <DeleteIcon className={classes.deleteIcon} />
                                            <span style={{ fontSize: '12px', marginLeft: '3px' }}>Delete</span>
                                        </IconButton>
                                        <IconButton aria-label="Export" style={{ marginLeft: '10rem', display: 'none' }} onClick={() => { handleMyCreationDetailsDialog(true, row) }}>
                                            <ExportIcon style={{ fontSize: '14px' }} />
                                        </IconButton>
                                    </>
                                )}
                            </TableCell> */}
                            {index === deletingRowIndex ? (
                                <TableCell className={`${classes.cell} ${classes.deleteCell}`}>
                                    <Button
                                        className={classes.cancelDeleteButton}
                                        onClick={handleCancelDelete}
                                        variant="outlined"
                                        style={{ textTransform: 'none', color: "black" }}
                                    >
                                        Cancel
                                    </Button>
                                    <button
                                        className={classes.confirmDeleteButton}
                                        onClick={() => handleConfirmDelete(row._id)}
                                    >
                                        Confirm Delete
                                    </button>

                                </TableCell>) : (
                                <TableCell className={`${classes.cell} ${classes.deleteCell}`}>
                                    <IconButton
                                        className={classes.deleteButton}
                                        aria-label="Delete"
                                        onClick={() => handleDeleteRow(index)}
                                    >
                                        <DeleteIcon className={classes.deleteIcon} />
                                        <span style={{ fontSize: '12px', marginLeft: '3px' }}>Delete</span>
                                    </IconButton>

                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MyTable;
