import { SetPublicUser, SetPublicToken, SET_PUBLIC_TOKEN, SET_PUBLIC_USER } from "../types";

export const publicLinkReducer = (state: any = null, action: SetPublicUser | SetPublicToken): any => {
    switch(action.type){
        case SET_PUBLIC_USER:{
            return {
                ...state,
                ...action.payload
            }
        }
        case SET_PUBLIC_TOKEN:{
            return {
                ...state,
                token: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}