import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import { AuthWrapper, Register } from '../../components/Authentication';
import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const AiquireRegister: FC = () => {
  const SuspenseComponent = () => {
    return (
      <AuthWrapper>
        <Register />
      </AuthWrapper>
    );
  }

  return (
    <div className="aiquire-auth-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default AiquireRegister;

