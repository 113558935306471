import React, {FC} from 'react';
// import './index.scss';

import CardContainer from '../../shared/CardContainer';
import Typography from 'pyxis-ui-kit';

import {RightOutlined} from '@ant-design/icons';

import PasswordIcon from '../../../assets/svg/iconPassword.svg';
import CloudStorageIcon from '../../../assets/svg/iconCloudStorage.svg';
import OrganizeIcon from '../../../assets/svg/iconOrganize.svg';
import UsersIcon from '../../../assets/svg/iconUsers.svg';

const Title = Typography.Title;

const items = [
    {
        icon: CloudStorageIcon,
        title: "Brand assets in one place",
        description: "A cloud-based storage system for all your brand assets in one place. No reason to misplace valuable digital assets."    
    },
    {
        icon: PasswordIcon,
        title: "Secure storage",
        description: " Your brand is valuable. There are multiple levels of security to protect stored files. Only people you allow can have access to your library."
    },
    {
        icon: OrganizeIcon,
        title: "Organize as you wish",
        description: "Create custom folders, subfolders, and sections for your brand assets to enable quick discovery. Save valuable time looking for files."
    },
    {
        icon: UsersIcon,
        title: "Multiple access",
        description: "Give access to whoever needs to edit, create, view, or approve designs whenever they need it."
    }
]

const WelcomeScreen:FC<{routePage:any}> = ({routePage}) => {
    require("./index.scss");
    return <div className="welcome-wrapper">
        <Title className="heading">Welcome to Library</Title>
        <Title className="subheading" level={5}>Store your brand assets here. Access them whenever you want.</Title>
        <CardContainer items={items} routePage={routePage}><span>Continue</span><RightOutlined className="right-arrow"/></CardContainer>
    </div>
}

export default WelcomeScreen;