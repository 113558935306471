import React,{FC,useCallback,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportProject, setExportedProjects } from '../../../store/actions/export';
import { ExportState, FolderState, HyperProjectFoldersState } from '../../../store/types';
import { openToast } from '../../../Toasts';
import { exportTypes,exportQualities } from './constants/index';
import './index.scss';

const ExportOptionSelection:FC<{
    handleExport: Function;
    setStep: Function;
    setExportType: Function;
}> = ({handleExport,setStep,setExportType}) => {
    return <>
        {exportTypes.map((exportOption,index:number)=>(
            <div className="export-option" style={{backgroundColor: exportOption.backgroundColor}}
                key={exportOption.name+index}
                onClick={()=>{
                    if(!exportOption.qualitySelection){
                        handleExport(exportOption.name)
                    }else{
                        setStep((val:number)=>(val+1));
                        setExportType(exportOption.name);
                    }
                }}
            >
                <div className="icon" style={{backgroundColor: exportOption.iconBackgroundColor}}>
                    <span>{exportOption.icon}</span>
                </div>
                <div className="name">
                    <span>{exportOption.name}</span>
                </div>
            </div>
        ))}
    </>
}

const ExportQualityOptions:FC<{
    handleExport: Function;
    exportType: string|null;
}> = ({handleExport,exportType}) => {
    return <div className="export-qualities">
        {exportQualities.map((quality,index:number)=>(
            <div className="quality" key={quality.percentage} onClick={()=>{
                if(exportType){
                    handleExport(exportType,quality.percentage)
                }else{
                    openToast("warn","Looks like you have not selcted Export Type")
                }
            }}>
                <span className="quality-icon">
                    {quality.icon}
                </span>
                <div className="detail">
                    <div className="name">{quality.name}</div>
                    <div className="description">{`${quality.description}(${quality.percentage}%)`}</div>
                </div>
            </div>
        ))}
    </div>
}

const Export:FC<{
    projectIds: number[];
    projectType: string;
    handleClose: Function;
    filter?: any;
    handleDisable?: Function
}> = ({projectIds,projectType,handleClose,filter,handleDisable}) => {
    const [step,setStep] = useState<number>(1);
    const [title,setTitle] = useState<string>("Export As");
    const [exportType,setExportType] = useState<string|null>(null);
    const { exportedProjects } = useSelector((state: {export:ExportState})=>(state.export));
    const { banners } = useSelector((state: { folders: FolderState }) => (state.folders.bannerData ? state.folders.bannerData: {
        banners: undefined,
    } ));
    const { selectedHyperProjects } = useSelector((state: { hyperProject : HyperProjectFoldersState }) => (state.hyperProject));
    const dispatch = useDispatch();
    const exportSelected =  () => {
        const key = projectType === 'hpproject' ? 'dc_ids' : 'size_ids';
        if (selectedHyperProjects.length > 0) {
            return {
                [key]: selectedHyperProjects
            }
        }
        return banners ? {
            [key]: banners.map((banner: any) => banner.id)
        } : undefined 
    }


    const handleExport = useCallback((exportType:string,qualityVal?:number)=>{
        let payload:any = {
            project_type:projectType,
            output_format: exportType,
            quality: qualityVal?qualityVal:undefined,
            filters:  exportSelected(),
        }
        payload[projectType === "hpproject"?"hpprojects":"projects"] = projectIds;
        if(filter){
            payload['filter'] = filter;
        }
        handleDisable && handleDisable();
        dispatch(exportProject(payload,(res:any,err:boolean)=>{
            if(!err){
                dispatch(setExportedProjects([...exportedProjects,res.data]));
                handleClose()
            }else{
                openToast('error','Unable to export!')
            }
        }))
    },[projectType,projectIds])

    return <div className="export-container">
        <div className="export-title">
            {step > 1 && <span onClick={()=>{
                setExportType("Export As");
                setStep((val:number)=>(val-1));
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0002 11.0001V13.0001H8.00016L13.5002 18.5001L12.0802 19.9201L4.16016 12.0001L12.0802 4.08008L13.5002 5.50008L8.00016 11.0001H20.0002Z" fill="black"/>
                </svg>
            </span>}
            {exportType? exportType.toUpperCase()+ (step === 2? " Download":""): title}
        </div>
        <div className="options">
            {step === 1 && <ExportOptionSelection handleExport={handleExport} setStep={setStep} setExportType={setExportType}  />}
            {step === 2 && <ExportQualityOptions handleExport={handleExport} exportType={exportType} />}
        </div>
    </div>
}

export default Export;