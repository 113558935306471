import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { EditorState, convertToRaw, ContentState, convertFromHTML, Modifier } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "draft-js-mention-plugin";
import {getDefaultKeyBinding} from 'draft-js';
import "draft-js-mention-plugin/lib/plugin.css";
import './index.scss';
import createMentionEntities from "./createMentionEntities";

const positionSuggestions = (upperProps:any) => {
  const { state, props } = upperProps;
  let transform;
  let transition;
  
  if (state.isActive && props.suggestions.length > 0) {
    transform = 'scaleY(1)';
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
  } else if (state.isActive) {
    transform = 'scaleY(0)';
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)';
  }

  return {
    transform,
    transition,
    bottom: '85%',
    maxHeight: '12rem',
    overflowY: 'scroll',
    zIndex: '11'
  };
};

const Mention = (props:any) => {
  const {
    className,
    entityKey,
    mention,
    children,
    decoratedText
  } = props;
  
  return <>
    <span className="mention-tag">
      {children}
    </span>
  </>
}

const Entry = (props:any) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;
  
  return (
    <div {...parentProps}>
      <div className={`${theme.mentionSuggestionsEntryContainer} mentions-suggestions-container`}>
        <div className={`${theme.mentionSuggestionsEntryContainerLeft} mention-suggestion-thumnail`}>
          {mention?.firstName[0]}
        </div>

        <div className={`${theme.mentionSuggestionsEntryContainerRight} mention-suggestions-right`}>
          <div className={`${theme.mentionSuggestionsEntryText} mention-suggestions-title`}>
            {`${mention.name}`}
          </div>

          <div className="mention-suggestions-subtitle">
            {mention.email}
          </div>
        </div>
      </div>
    </div>
  );
};

const EditorInput:React.FC<any> = forwardRef((
  {placeholder,initialValue,onPressEnter,getRawValue,mentions,tagsArr,className,onEscape,readOnly,setInFocus}:{
  placeholder: string;
  initialValue?: string;
  onPressEnter?: Function;
  getRawValue?: Function;
  mentions: any[];
  tagsArr: any[];
  className?: string;
  onEscape?: Function;
  readOnly?: boolean;
  setInFocus?: Function;
},ref) => {
  const [editorState,setEditorState] = useState<any>(initialValue? createMentionEntities(initialValue,tagsArr): EditorState.createEmpty());
  const [suggestions,setSuggestions] = useState<any>(mentions);
  const entityMap = useRef(new Map());
  const editorRef = useRef<any>(null);
  const mentionPlugin = useMemo(()=>createMentionPlugin({
    entityMutability: "IMMUTABLE",
    mentionPrefix: "@",
    supportWhitespace: true,
    positionSuggestions:  initialValue ? (props:any)=>({...positionSuggestions(props),bottom:""}) : positionSuggestions,
    mentionComponent: Mention
  }), []);
  const MentionSuggestions = useMemo(()=>(mentionPlugin.MentionSuggestions),[mentionPlugin]);
  const plugins = useMemo(()=>[mentionPlugin],[mentionPlugin]);
  
  useImperativeHandle(ref,()=>({
    getRawText() {
      const contentState = editorState.getCurrentContent();
      const raw = convertToRaw(contentState);
      let mentionsInBlocks:any[] = [];
      let entityMapArr:any[] = [];
      for (let key in raw.entityMap) {
        entityMapArr.push(raw.entityMap[key]);
      }
      let filteredEntityMap:any[] = entityMapArr.filter((entity:any)=>(entity.type === "mention"));
      filteredEntityMap.forEach((entity:any)=>{
        if(!entityMap.current.has('@'+entity.data.mention.firstName+" "+entity.data.mention.lastName)){
          entityMap.current.set('@'+entity.data.mention.firstName+" "+entity.data.mention.lastName,entity.data.mention._id)
        }
      })
      raw.blocks.forEach((block:any,blockIndex:number)=>{
        block.entityRanges.forEach((range:any,index:number)=>{
          let start:number = range.offset;
          let end:number = range.offset + range.length;
          let key:string = block.text.substring(start,end);
          if(!mentionsInBlocks[blockIndex]){
            mentionsInBlocks.push([]);
          }
          mentionsInBlocks[blockIndex].push(key);
        })
        
        if(mentionsInBlocks[blockIndex]){
          mentionsInBlocks[blockIndex].forEach((mention:string)=>{
            if(entityMap.current.has(mention) || entityMap.current.has('@'+mention)){
              block.text = block.text.replaceAll(mention,
                `@~~${entityMap.current.has(mention)? 
                entityMap.current.get(mention):entityMap.current.get('@'+mention)}~~@`
              );
            }
          })
        }
      })
      
      const textValue = raw.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
      return textValue;
    },
    isInitialValueChanged(){
      const contentState = editorState.getCurrentContent();
      return initialValue ? (contentState.getPlainText().trim() !== initialValue.replaceAll(/<[^>]*>/g,'').trim()) : false;
    },
    clearEditorState(){
      setEditorState(EditorState.createEmpty());
      let node:any = editorRef?.current;
      if(node){
        setTimeout(()=>node.focus(),0);
      }
    }
  }))
  
  useEffect(()=>{
    setSuggestions(mentions);
  },[mentions])

  useEffect(()=>{
    let node:any = editorRef?.current;
    if(node){ 
      setInFocus && setInFocus(true);
      setTimeout(()=>node.focus(),0) 
    }
  },[])

  const onChange = (editorState:any) => {
    setEditorState(editorState);
  };

  const onSearchChange = ({ value }:any) => {
    // console.log("value",defaultSuggestionsFilter(value, mentions));
    setSuggestions(mentions.filter((user:any)=>( 
      user.name.toLowerCase().includes(value.toLowerCase())||
      user.email.toLowerCase().includes(value.toLowerCase())
    )))
  };

  const myKeyBindingFn = (e:any): string | null => {
    if(e.key === "Enter" && !e.shiftKey){
      return "submit-me";
    }else if(e.key === "Escape" && !e.shiftKey){
      return "escape";
    }
    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command:string) => {
    if (command === 'submit-me') {
      onPressEnter && onPressEnter()
      return 'handled'
    } else if(command==='escape'){
      onEscape && onEscape()
    }
    return 'not-handled'
  }

  return (<>
    <div className={`editor-container ${className?className:''}`} onClick={(e)=>{
      e.stopPropagation()
      let node:any = editorRef?.current;
      if(node){ setTimeout(()=>node.focus(),0) }
    }}>
      <Editor
        ref={editorRef}
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        placeholder={placeholder}
        keyBindingFn={myKeyBindingFn}
        handleKeyCommand={handleKeyCommand}
        readOnly={readOnly}
      />
      <MentionSuggestions
        onSearchChange={onSearchChange}
        suggestions={suggestions}
        entryComponent={Entry}
        open={true}
      />
   </div>
  </>)
})

export default EditorInput;