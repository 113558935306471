import {
  ApprovalState, SendForApproval, SetApprovalLoader, SEND_FOR_APPROVAL, SET_APPROVAL_LOADER,
  GET_STATUS_SUMMARY, GetStatusSummary, GET_APPROVAL_STATUS, GetApprovalStatus, SET_APPROVAL_STATUS,
  SetApprovalStatus, SET_IS_SENT_FOR_APPROVAL, SetIsSentForApproval, DUPLICATE_APPROVAL_ROUND,
  DuplicateApprovalRound, CHANGE_APPROVAL_ROUND, ChangeApprovalRound, AppendApprovalRound,
  APPEND_APPROVAL_ROUND, SetApprovalComments, SET_APPROVAL_COMMENTS, ADD_APPROVAL_COMMENT, AddApprovalComment,
  ChangeApprovalComment, CHANGE_APPROVAL_COMMENT, SetApprovalStatusSummary, SET_APPROVAL_SUMMARY,
  SET_PENDING_ENTITIES, SET_PENDING_APPROVAL_COUNT, SetPendingApproval, SetPendingApprovalCount,
  SetPendingEntities, SET_PENDING_APPROVALS, SetActiveApprovalStatus, SET_ACTIVE_APPROVAL_STATUS,
  SetActiveApprovalEntityType, SET_ACTIVE_APPROVAL_ENTITY_TYPE, GET_SUMMARY_SUCCESS, SET_COMMENTS_RESOLUTION_TYPE, SetCommentsResolutionType
} from "../types"


const initialState: ApprovalState = {
  approvalStatus: null,
  comments: [],
  approvalStatusSummary: null,
  pendingApprovalCount: 0,
  pendingApprovals: [],
  pendingEntities: [],
  loading: false,
  activeApprovalStatus: "pending",
  activeApprovalEntityType: null,
  isSentForApproval: false,
  comments_resolution: 0,
}

export const approvalReducer = (state: ApprovalState = initialState, action: SendForApproval | SetApprovalLoader
  | GetStatusSummary | GetApprovalStatus | SetApprovalStatus | SetIsSentForApproval | DuplicateApprovalRound | ChangeApprovalRound
  | AppendApprovalRound | SetApprovalComments | AddApprovalComment | ChangeApprovalComment | SetApprovalStatusSummary
  | SetPendingApproval | SetPendingApprovalCount | SetPendingEntities | SetActiveApprovalStatus | SetActiveApprovalEntityType | SetCommentsResolutionType) => {
  switch (action.type) {
    case SEND_FOR_APPROVAL: {
      return {
        ...state,
        loading: true
      }
    }
    case SET_APPROVAL_LOADER: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_APPROVAL_STATUS: {
      return {
        ...state,
        approvalStatus: action.payload
      }
    }
    case SET_IS_SENT_FOR_APPROVAL: {
      return {
        ...state,
        isSentForApproval: action.payload
      }
    }
    case CHANGE_APPROVAL_ROUND: {
      let { round, roundIndex } = action.payload;
      state.approvalStatus.rounds[roundIndex] = round;
      return {
        ...state,
        approvalStatus: state.approvalStatus
      }
    }
    case APPEND_APPROVAL_ROUND: {
      return {
        ...state,
        approvalStatus: { ...state.approvalStatus, rounds: [...state.approvalStatus.rounds, action.payload] }
      }
    }
    case SET_APPROVAL_COMMENTS: {
      return {
        ...state,
        comments: action.payload
      }
    }
    case SET_COMMENTS_RESOLUTION_TYPE: {
      return {
        ...state,
        comments_resolution: action.payload
      }
    }
    case ADD_APPROVAL_COMMENT: {
      let found = state.comments.findIndex((el) => el.id === action.payload.id);
      let comments_resolution = state.comments_resolution
      let comments = [...state.comments];
      if (found > -1) {
        switch (comments_resolution) {
          case 0:
            if (action.payload.resolved_status === false) {
              comments[found] = action.payload;
            } else {
              comments.splice(found, 1);
            }
            break;
          case 1:
            if (action.payload.resolved_status === true) {
              comments[found] = action.payload;
            } else {
              comments.splice(found, 1);
            }
            break;
          default:
            comments[found] = action.payload;
            break;
        }
      } else {
        switch (comments_resolution) {
          case 0:
            if (action.payload.resolved_status === false) {
              comments.push(action.payload);
            }
            break;
          case 1:
            if (action.payload.resolved_status === true) {
              comments.push(action.payload);
            }
            break;
          default:
            comments.push(action.payload);
            break;
        }
      }
      return {
        ...state,
        comments,
      }
    }
    case CHANGE_APPROVAL_COMMENT: {
      let { comment, commentIndex } = action.payload;
      state.comments[commentIndex] = comment;
      return {
        ...state,
        comments: state.comments
      }
    }
    case SET_APPROVAL_SUMMARY: {
      return {
        ...state,
        approvalStatusSummary: action.payload
      }
    }
    case SET_PENDING_APPROVALS: {
      return {
        ...state,
        pendingApprovals: action.payload
      }
    }
    case SET_PENDING_ENTITIES: {
      return {
        ...state,
        pendingEntities: action.payload
      }
    }
    case SET_PENDING_APPROVAL_COUNT: {
      return {
        ...state,
        pendingApprovalCount: action.payload
      }
    }
    case SET_ACTIVE_APPROVAL_STATUS: {
      return {
        ...state,
        activeApprovalStatus: action.payload
      }
    }
    case SET_ACTIVE_APPROVAL_ENTITY_TYPE: {
      return {
        ...state,
        activeApprovalEntityType: action.payload
      }
    }
    default: {
      return state;
    }
  }
}