import React, { useState, useCallback } from "react";
import "./index.scss";
import { useLocation, Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  setToken,
  setIsLoggedIn,
  setUserData,
} from "../../../store/actions";
import { AuthState } from "../../../store/types";
import { omit } from "lodash";

import pyxisLogo from "../../../assets/svg/pyxisLogo.svg";
import { Button, Input, InputPassword } from "pyxis-ui-kit";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import {
  identifyMixpanel,
  trackSignIn,
} from "../../../utilities/services/mixpanel";
// import LogRocket from 'logrocket';

const Login: React.FunctionComponent = () => {
  const state = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoader] = useState(false);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const referer = location?.state?.referer || "/";
  const { isLoggedIn } = state;
  const handleLogin = useCallback(() => {
    setLoader(true);
    dispatch(
      login(
        { email: email.trim().toLowerCase(), password },
        (response: any, error: boolean) => {
          setLoader(false);
          if (!error) {
            dispatch(setToken(response.data.token));
            let userData: any = omit(response.data, ["token"]);
            dispatch(setUserData(userData));

            const {
              userId,
              firstName,
              lastName,
              emailId,
              phoneNumber,
            } = userData;
            // LogRocket.identify(userId, {
            //   name: `${firstName} ${lastName}`,
            //   email: emailId,
            //   phoneNumber,
            // });
            identifyMixpanel(email, `${firstName} ${lastName}`);
            trackSignIn(emailId);
            dispatch(setIsLoggedIn(true));
          }
        }
      )
    );
  }, [email, password]);

  // Once login, redirect to last visited page while logout or homepage(first time login)
  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="container">
      <div className="header">
        <img className="logo" src={pyxisLogo} />
        <div className="title">Sign in</div>
        <div className="description">
          Manage, Create & Personalize Brand Assets with Ease
        </div>
      </div>
      <div className="login-form">
        <div className="form-field email">
          <div className="login-label">
            <span className="primary-lable">Email address</span>
          </div>
          {/* <Input
          className="email"  
          type="email"
          placeholder="Enter your email address"
          onChange={(e:any)=>setEmail(e.target.value)} 
          onKeyDown={(e:any)=>{if(e.key === 'Enter'){handleLogin()}}}
        /> */}
          <Input
            className="email"
            placeholder="Enter your email address"
            value={email}
            type="email"
            onChange={(e: any) => setEmail(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
        </div>
        <div className="form-field">
          <div className="login-label">
            <span className="primary-lable">Password</span>
            <Link to="/reset-password">
              <span className="secondary-lable">Reset Password?</span>
            </Link>
          </div>
          <InputPassword
            className="password"
            placeholder="Enter your password"
            onChange={(e: any) => setPassword(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
        </div>
        {/* <div className="form-field">
        <Checkbox onChange={(e)=>handleKeep(e)} className="keep-checkbox" checked/><span className="keep"> Keep me sign in</span>
      </div> */}
        <Button
          className="signin"
          btnsize="xl"
          type="primary"
          onClick={() => handleLogin()}
          disabled={!email || !password || loading}
        >
          <span>Sign in</span>
          {loading && (
            <span>
              <Loading3QuartersOutlined spin />
            </span>
          )}
        </Button>
      </div>
      {/* <div className="footer-container">
      <div className="footer">
        Don't have a account?
        <Link to='/register'> Sign Up </Link>
      </div>
    </div> */}
    </div>
  );
};

export default Login;
