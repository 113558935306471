import React, { useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { Button,Input,InputPassword,Checkbox } from 'pyxis-ui-kit'

const Login:React.FunctionComponent = () => {
  

  return <div className="container">
  <div className="header">
      <img className="logo"/>
    <div className="title">Let’s create an account</div>
    <div className="description">Brand Assets Management Made Easy</div>
  </div>
  <div className="form">
  <div className="form-field">
      <div>
        <span>First and Last Name</span>
      </div>
      <Input
        placeholder="Enter your First and Last Name"
      />
    </div>
    <div className="form-field">
      <div>
        <span>Email address</span>
      </div>
      <Input
        type="email"
        placeholder="Enter your email address"
      />
    </div>
    <div className="form-field">
      <div>
        <span>Password</span>
      </div>
      <InputPassword
        placeholder="Enter your password"
      />
    </div>
    <div className="form-field">
      <Checkbox/><span className="keep">I agree with Terms & Privacy Policy</span>
    </div>
    <Button
    type="primary"
    >
      Sign up
    </Button>
  </div>
  <div className="footer-container">
    <div className="footer">
      Already a member?
      <Link to='/login'> Sign in </Link>
    </div>
  </div>
</div>
}

export default Login;