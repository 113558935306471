export const permissionsConstant:any = {
    "tooltips": {
        "manage-users": "Manage users across multiple workspaces, add/remove workspace admins and other users",
        "create-custom-role": "Create, edit, and remove custom user roles",
        "add-delete-product": "Permission to add/remove workspaces",
        "edit-product": "Permission to edit/rename workspace",
        "manage-product-users": "Permission to add users to workspaces or remove them",
        "create-new-banners": "Permission to create a new project",
        "view-all-assets": "Permission to view all projects, Templates, and My Uploads created by all users in given workspace",
        "manage-all-assets": "Permission to edit/remove existing projects, Templates, and My Uploads created by all users in given workspace",
        "view-banner-library": "Permission to view the projects, Templates and My Uploads created only by the user ",
        "manage-banner-library": "Permission to edit the projects, Templates and My Uploads created only by the user ",
        "view-typography": "Permission to view brand typography in brand library",
        "manage-typography": "Permission to add/edit/delete brand typography in the brand library",
        "view-brand-colors": "Permission to view brand colors inside the Brand Library",
        "manage-brand-colors": "Permission to edit/delete brand colors inside the Brand Library",
        "view-approval-workflow": "Permission to view project approval workflows in given workspace",
        "create-delete-approval-workflow": "",
        "manage-approval-workflow": "Permission to create/edit/remove approval workflows",
        "grand-approval": "Permission to approve designs and projects in given workspace",
        "start-cancel-approval-flow": "Permission to submit/cancel approvals in given workspace",
        "view-approval-and-comments": "Permission to view approval workflow for given workspace and comment",
        "add-new-comments": "Permission to add new comments to approvals"
    }
}