import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';


interface MenuInterface {
    anchorRef: React.RefObject<HTMLButtonElement>|React.RefObject<HTMLDivElement>|null;
    handleClose: any;
    open: boolean;
    style?: any;
}

const Menu: React.FunctionComponent<MenuInterface> = ({children,anchorRef,handleClose,open,style}) => {
    return <Popper open={open} anchorEl={anchorRef?.current} style={{zIndex: 9 }} role={undefined} transition disablePortal placement="bottom-start">
    {({ TransitionProps, placement }) => (
        <Grow
            {...TransitionProps}
            style={{...style,transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
        >
            <Paper>
            <ClickAwayListener onClickAway={handleClose}>
                {children}
            </ClickAwayListener>
            </Paper>
        </Grow>
        )}
    </Popper>
}

export default Menu;