import React, { useState } from 'react';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setMembersList, setProductsList, fetchMembersList, fetchProductList, fetchOthersProductList, fetchRolesList, setRolesList, unlinkUserWithEntity, linkUserWithEntity, setSelectedItem } from '../../../store/actions';
import Menu from '../../shared/DropdownMenu';
import { openToast } from '../../../Toasts';

import { FETCH_ROLES_LIST, RolesState } from '../../../store/types';

interface DeleteRolePopUpProps {
    roles: Object;
    activeRoleId: number;
    onCancel?: Function;
    onSubmit?: Function;
}

const DeleteRolePopUp:React.FC<DeleteRolePopUpProps> = ({roles, activeRoleId, onCancel, onSubmit}) => {
    const state = useSelector((state:{roles:RolesState})=>(state.roles));
    const { membersList, selectedItem, itemList, rolesList, allRoles } = state;
    // const arrLength = membersList.length;
    const selectedEntityItemId = (itemList.length > 0 && selectedItem > -1)? itemList[selectedItem]._id:null;
    const [updatedLoading,setUpdateLoading] = useState(-1);
    
    const dispatch = useDispatch();
    const [open, setOpen] = useState(-1);

    const [elRefs, setElRefs] = useState([]);
    const [loading, setLoading] = useState(false);

    // console.log(roles);
    // console.log(elRefs[activeRoleId]);

    return (
        <>
        <div 
            className="popup-overlay" 
            onClick={()=>onCancel && onCancel()}>
        </div>
        <div className="popup">
            <div className="title">
                <h3>Choose a new role for the users with role: {Object.values(roles)[activeRoleId]}</h3>
            </div>
            <div className="assign-roles-section">
                <div className="body-grid">
                    <div className="input-field">
                        <div className="item input-select" onClick={()=>setOpen(activeRoleId)}>
                            <span>Choose role</span> 
                            <span><FontAwesomeIcon icon={faAngleDown} /></span>
                        </div>
                        {/* {updatedLoading === index && <span className="loader">
                        <FontAwesomeIcon icon={faSpinner} spin />
                        </span>} */}
                    </div>
                    <Menu open={open !== -1} anchorRef={elRefs[activeRoleId]} handleClose={()=>setOpen(-1)}>
                        <div className="dropdown-container">
                            {Object.values(roles).map((role:any, index:number)=>((index !== activeRoleId)&&
                            <div className="dropdown-item" onClick={()=>{
                                setOpen(-1);
                                setUpdateLoading(index);
                                // dispatch(unlinkUserWithEntity({
                                //   userId: data._id,
                                //   entityType: 'product',
                                //   entityId: itemList[selectedItem]._id 
                                // },(res:any,err:boolean)=>{
                                //   if(!err){
                                //     dispatch(linkUserWithEntity({
                                //       userId: data._id,
                                //       entityType: 'product',
                                //       entityId: itemList[selectedItem]._id,
                                //       role: role.roleId
                                //     },(res:any,err:boolean)=>{
                                //       setUpdateLoading(-1)
                                //       if(!err){
                                //         let arr = membersList;
                                //         arr[index]["role"] = role.roleId;
                                //           dispatch(setMembersList(arr))
                                //           openToast('success','Member Role Changed!')
                                //       }else{
                                //         openToast('error',res.data.message||"Something went wrong!")
                                //       }
                                //     }))
                                //   }else{
                                //     setUpdateLoading(-1)
                                //     openToast('error',res.data.message||"Something went wrong!")
                                //   }
                                // }))
                              }}
                                key={role}
                              >
                                <span>
                                  {role}
                                </span>
                              </div>
                        ))}
                        </div>
                    </Menu>
                </div>
            </div>
            <div className="footer">
                <button 
                    className="primary"
                    onClick={()=>onSubmit && onSubmit()}>
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        Delete
                </button>
                <button 
                    className="secondary" 
                    onClick={()=>onCancel && onCancel()}>
                        Cancel
                </button>
            </div>
        </div>
        </>
    );
}

export default DeleteRolePopUp;