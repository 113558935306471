export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const FETCH_TEMPLATES_CATEGORIES = 'FETCH_TEMPLATES_CATEGORIES';
export const SET_TEMPLATES_CATEGORIES = 'SET_TEMPLATES_CATEGORIES';

export type TemplatesTrayState = {
  templates: any[],
  page: number,
  totalPages: number|null,
  search: string,
  loading: boolean,
  loadingCategories?: boolean,
  textTemplates?: any,
  type: string,
  categoriesData?: any
}

export type TemplatesCategoriesTrayState = {
  categories: any[],
  count: number,
  current_page: number,
  page_size: number,
  pages: number
}

export type TemplatesQuery = {
  search: string,
  page: number,
  totalPages: number|null,
  type: string,
  category?: string
}

export type TemplatesCategoriesQuery = {
  page: number,
  type: string
}


export type SetTemplates = {
  type: typeof SET_TEMPLATES,
  payload: TemplatesTrayState
}

export type FetchTemplatesAction = {
  type: typeof FETCH_TEMPLATES,
  payload: TemplatesQuery
}

export type SetTemplateCategories = {
  type: typeof SET_TEMPLATES_CATEGORIES,
  payload: TemplatesCategoriesTrayState
}

export type FetchTemplateCategoriesAction = {
  type: typeof FETCH_TEMPLATES_CATEGORIES,
  payload: TemplatesCategoriesQuery
}