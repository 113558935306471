import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import moment from "moment";
// import GreyBackground from "../../../assets/img/grey-background.png";
import { useSelector, useDispatch } from "react-redux";
import { ApprovalState, AuthState } from "../../../store/types";
import {
  setPendingApprovals,
  fetchProjects,
  markApprovalStatus,
  getUsersData,
  fetchHyperProjects,
  fetchApprovalsList
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { openToast } from "../../../Toasts";
import InfiniteScroll from 'react-infinite-scroll-component';
import {EmptyPlaceHolder,Modal,AlertPopUp} from '../../shared';
import EmptyApproval from '../../../assets/img/emptyapproval.svg';
import Img from "../../ImageLoader/ImageLoader";
import { getPreview } from "../../../utilities/common-function";
import { Button } from "pyxis-ui-kit";

interface ApprovalsInterface {
  status: string;
}
interface ApprovalCardInterface {
  data: any;
  index: number;
  removePending: Function;
}

const ApprovalCard: React.FunctionComponent<ApprovalCardInterface> = ({
  data,
  index,
  removePending,
}) => {
  const user = useSelector(
    (state: { authentication: AuthState }) => state.authentication.userData
  );
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [alertPopUpProps, setAlertPopUpProps] = useState<any>(null);

  return (
    <div className="approval-card">
      <div className="asset">
        {/* <input type="checkbox" /> */}
        {data.type === "video"? <video src={data.rendered_file||""}></video>:
        <Img
          src={(data.type !== 'image' && data.type !== 'image_set' && data.type !== 'variant_set' && data.type !== 'gif')? getPreview(data?.file_type):data.rendered_file || ""}
          style={{objectFit:'scale-down',objectPosition:'center center'}} 
        />}
      </div>
      <div className="detail">
        <div className="header">
          <div className="title">
            <p>{data.title}</p>
            <span>Details</span>
          </div>
          <div
            className="action"
            onClick={() => {
              if(data.entity_type === "project"){
                history.push(`/cocreate/banner-detail/?banner=${data.id}`);
              }
              
              if(data.entity_type === "hpproject"){
                history.push(`/cocreate/creative-automation/detail/?banner=${data.id}`);
              }
            }}
          >
            <button className="tertiary">View Project</button>
          </div>
        </div>
        <div className="about">
          <div className="key">Creation Date</div>
          <div>
            {moment(data.created_at).format("dddd") +
              ", " +
              moment(data.created_at).format("Do MMMM, YYYY | h:mm a")}
          </div>

          <div className="key">Created By</div>
          <div>{data.created_by}</div>

          <div className="key">Project Type</div>
          <div>{`${data.entity_type === 'hpproject'?"Creative Automation Project":""} ${data.entity_type === 'hpproject'?"(":""}${data.type === "image" ? "Banner" : "Banner Set"}${data.entity_type === 'hpproject'?")":""}`}</div>
          
          <div className="key">Round Name</div>
          <div>{data.round_name}</div>

          <div className="key">Note</div>
          <div>{data.note?data.note:"No Note"}</div>

        </div>
        {data.status !== 'approved' && <div className="actions">
          {isLoading && (
            <span style={{ marginRight: "2rem" }}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>
          )}
          {data.status !== 'rejected' && <button
            className="secondary"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              if (user) {
                setAlertPopUpProps({
                  title: "Are You sure?",
                  text: `Are you sure you want to reject this ${data.entity_type}?`,
                  submittext: "Yes",
                  canceltext: "No",
                  onSubmit: (setLoader:Function)=>{
                    setLoader(true);
                    dispatch(
                      markApprovalStatus(
                        {
                          userId: user.userId,
                          entity_id: data.entity_id,
                          status: "rejected",
                          entity_type: data.entity_type
                        },
                        (res: any, err: boolean) => {
                          setLoader(false);
                          setAlertPopUpProps(null);
                          if (!err) {
                            removePending(index,setLoading);
                          }else{
                            openToast('error','Not able to Update Status!')
                          }
                        }
                      )
                    );
                  },
                  onCancel: () => {
                    setAlertPopUpProps(null);
                  }
              });
            }}}>
            Reject
          </button>}
          <Button
            type="primary"
            className="primary"
            disabled={isLoading}
            onClick={(e:any) => {
              e.stopPropagation();
              if (user) {
                setAlertPopUpProps({
                  title: "Are You sure?",
                  text: `Are you sure you want to approve this ${data.entity_type}?`,
                  submittext: "Yes",
                  canceltext: "No",
                  isPrimary: true,
                  onSubmit: (setLoader:Function)=>{
                    setLoader(true);
                    dispatch(
                      markApprovalStatus(
                        {
                          userId: user.userId,
                          entity_id: data.entity_id,
                          status: "approved",
                          entity_type: data.entity_type
                        },
                        (res: any, err: boolean) => {
                          setLoader(false);
                          setAlertPopUpProps(null);
                          if (!err) {
                            removePending(index,setLoading);
                          }else{
                            openToast('error','Not able to Update Status!')
                          }
                        }
                      )
                    ); 
                  },
                  onCancel: () => {
                    setAlertPopUpProps(null);
                  }
                });
              }}}>
            Approve
          </Button>
        </div>}
      </div>
      {alertPopUpProps && (
        <Modal>
            <AlertPopUp {...alertPopUpProps} />
        </Modal>
      )}
    </div>
  );
};

const Approvals: React.FunctionComponent<ApprovalsInterface> = ({ status }) => {
  const state = useSelector(
    (state: { approval: ApprovalState; authentication: AuthState }) => ({
      ...state.approval,
      ...state.authentication,
    })
  );
  const { pendingApprovals, pendingEntities, userData,pendingApprovalCount,activeApprovalStatus,activeApprovalEntityType } = state;
  const [loading,setLoader] = useState(false);
  const [pageData,setPageData] = useState({current_page: 0,pages: 0});
  const projectsHashRef = useRef<any>({
    "project": new Map(),
    "hpproject": new Map()
  });
  const usersHashRef = useRef<any>(new Map());
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchProjectForApprovals = (params:any,hpparams:any,callback:Function) => {
    const getProjects = new Promise((complete:any,rej:any)=>{ 
      dispatch(fetchProjects(params,(res:any,err:boolean)=>{
        if(!err){
          res.data.map((project:any)=>{
            projectsHashRef.current.project.set(project.id,project);
          })
          complete(res.data);
        }else{
          rej()
        }
      }));
    });
    const getHpproejcts = new Promise((complete:any,rej:any)=>{
      dispatch(fetchHyperProjects(hpparams,(res:any,err:boolean)=>{
        if(!err){
          res.map((project:any)=>{
            projectsHashRef.current.hpproject.set(project.id,project);
          })
          complete(res);
        }else{
          rej()
        }
      }));
    })

    if((hpparams.hpproject_ids.length === 0) && (params.project_id.length === 0)){
      callback([],false);
    }else{
      Promise.all([getProjects,getHpproejcts]).then(response=>{
        callback(response,false);
      }).catch((err:any)=>{
        callback(err,true);
      })
    }
  }

  const fetchApprovals = (page:number,setLoader:Function) => {
    if(userData){
      setLoader(true)
      dispatch(fetchApprovalsList({page,status: activeApprovalStatus,entity_type: activeApprovalEntityType? activeApprovalEntityType: undefined},(approvalsRes:any,approvalsErr:boolean)=>{
        if(!approvalsErr){
          setPageData({
            pages: approvalsRes.data.pages,
            current_page: approvalsRes.data.current_page
          })
          let entityIds:any = approvalsRes.data.results.reduce(
            (acc: any, val: any) => {
              if(!projectsHashRef.current[val.entity_type].has(val.entity_id)){
                acc[val.entity_type] = [...acc[val.entity_type],val.entity_id]
              }
              return acc;
            },{"project":[],"hpproject":[]}
          );
          fetchProjectForApprovals({page:1,project_id: entityIds["project"]},{page:1,hpproject_ids: entityIds["hpproject"]},
            (projectsRes:any,proejctsErr:boolean)=>{
              if(!proejctsErr){
                let projects = projectsRes.flat();
                let uids = projects.reduce((acc: number[], val: any) => {
                  if(!usersHashRef.current.has(val.created_by)){
                    return [...acc, val.created_by]
                  }else{
                    return acc;
                  }
                },[]);

                if(uids.length > 0){
                  dispatch(
                    getUsersData({ userIds: uids }, (userres: any, usererr: boolean) => {
                      setLoader(false)
                      if (!usererr) {
                        userres.data.users.map((u:any)=>usersHashRef.current.set(u._id,u));

                        approvalsRes.data.results.map((approval:any, index: number) => {
                          let project:any = projectsHashRef.current[approval.entity_type].get(approval.entity_id-0);
                          if(project){
                            let user:any =  usersHashRef.current.get(project.created_by);
                            approval['title'] = project.title;
                            approval['type'] =  project.type;
                            approval['rendered_file'] = project.rendered_file;
                            approval['created_at'] =  project.created_at;
                            approval['created_by'] = user? (user.firstName +" " + user.lastName): "";
                            approval['id'] = project.id;
                          }
                        });
                        if(page > 1){
                          dispatch(setPendingApprovals([...pendingApprovals,...approvalsRes.data.results]))
                        }else{
                          dispatch(setPendingApprovals(approvalsRes.data.results));
                        }
                      }else{
                        openToast('error','Issue while fetch users!')
                      }
                    })
                  )
                }else{
                  approvalsRes.data.results.map((approval:any, index: number) => {
                    let project:any = projectsHashRef.current[approval.entity_type].get(approval.entity_id-0);
                    if(project){
                      let user:any =  usersHashRef.current.get(project.created_by);
                      approval['title'] = project.title;
                      approval['type'] =  project.type;
                      approval['rendered_file'] = project.rendered_file;
                      approval['created_at'] =  project.created_at;
                      approval['created_by'] = user? (user.firstName +" " + user.lastName): "";
                      approval['id'] = project.id;
                    }
                  });
                  if(page > 1){
                    dispatch(setPendingApprovals([...pendingApprovals,...approvalsRes.data.results]))
                  }else{
                    dispatch(setPendingApprovals(approvalsRes.data.results));
                  }
                  setLoader(false)
                }

              }
            })
        }else{
          setLoader(false)
        }
      }))
    }
  }

  useEffect(() => {
    fetchApprovals(1,setLoader);
    return ()=>{
      dispatch(setPendingApprovals([]))
    }
  }, [activeApprovalStatus,activeApprovalEntityType]);

  const removePending = (index: number,setLoader:Function) => {
    fetchApprovals(1,setLoader)
    openToast('success','Project Status Changed!');
  };

  return (
    <div className="approvals-list" id="approval-listing">
      <InfiniteScroll
        dataLength={pendingApprovals.length}
        next={()=>fetchApprovals(pageData.current_page+1,setLoader)}
        hasMore={pageData.current_page < pageData.pages}
        loader={null}
        scrollableTarget="approval-listing"
      >
        {pendingApprovals.map((data: any, index: number) => (
          <ApprovalCard
            key={data.entity_id}
            data={data}
            index={index}
            removePending={removePending}
          />
        ))}
      </InfiniteScroll>
      {loading && <div className="loader">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>}
      {!loading && pendingApprovals.length === 0 && <EmptyPlaceHolder 
        icon={<img src={EmptyApproval} />}
        text={`No ${activeApprovalStatus} approvals found. Go to designs to add projects here`}
        submitText="Open Design Page"
        onSubmit={()=>history.push('/cocreate/homepage')}
        isSubmitBtn={activeApprovalStatus === "pending"}
        style={{'width': '75vw',"background": "#fff"}}
      />}
    </div>
  );
};

export default Approvals;
