import React, { useState, useEffect } from "react";
import "./index.scss";
import { FolderState, BannerData } from "../../../store/types";
import { useSelector, useDispatch } from "react-redux";
import { IMAGE_BASE_URL, S3_BASE_URL } from "../../../utilities/paths";
import Img from "../../ImageLoader/ImageLoader";
import { Button, Input, InputNumber, Spin } from "pyxis-ui-kit";
import { EmptyFile } from "../../shared";
import {
  getIsTypeEditable,
  downloadFile,
  getPreview,
  detectQueryString,
  isItImage,
} from "../../../utilities/common-function";
import { debounce } from "lodash";
import EmptyPlaceHolder from "../../shared/EmptyPlaceHolder";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { IMAGE_SUPPORTED } from "../../../utilities/contants";
import { loading, setBannerView } from "../../../store/actions";
import content from "./index.scss";
import ImgMap from "../../Annotation/ImageMap";
import loader5 from "../../../assets/img/loader5.gif";
import Lottie from "react-lottie";
import projectLoader from "../../../assets/animation/projectLoader.json";

interface ContainerInterface {
  className: string;
  style: any;
}

interface ProjectInterface {
  data: any;
  handleIndexChange: Function;
  handleBackClick: Function;
  index: number;
  total: number;
  type: string;
  showEmbeded: boolean;
  fileType: string;
  renderedCount: number;
  projectType: string;
  componentCaller: string;
  selectedBanner: any;
  setSelectedBanner?: Function;
  commentMode: boolean;
  updateLoader: boolean;
  showAddComment: boolean;
  setShowAddComment: Function;
  commentIndex: number;
  setCommentIndex: Function;
  setReplyCommentIndex: Function;
  setRetainedComments: Function;
  imageDimensions: any;
  setImageDimensions: Function;
  client: WebSocket;
  setInFocus: Function;
}

interface ProjectViewInterface {
  index: number;
  handleIndexChange: Function;
  handleBackClick: Function;
  type: string;
  renderedCount: number;
  style?: any;
  className?: any;
  componentCaller: string;
  setSelectedBanner?: Function;
  commentMode: boolean;
  updateLoader: boolean;
  showAddComment: boolean;
  setShowAddComment: Function;
  commentIndex: number;
  setCommentIndex: Function;
  setReplyCommentIndex: Function;
  setRetainedComments: Function;
  imageDimensions: any;
  setImageDimensions: Function;
  client: WebSocket;
  setInFocus: Function;
}

interface ActionBarInteface {
  handleBackClick: Function;
  handleIndexChange: Function;
  index: number;
  total: number;
  zoom: number;
  setZoom: Function;
  renderedCount: number;
  projectType: string;
  componentCaller: string;
  setSelectedBanner: Function;
}

const supported_type = [
  "mp3",
  "ogg",
  "wav",
  "m4v",
  "mov",
  "java",
  "xlsx",
  "docx",
  "doc",
  "odt",
  "pdf",
  "txt",
  "odp",
  "ppt",
  "pptx",
  "css",
  "html",
  "htm",
  "js",
  "bmp",
  "gif",
  "ico",
  "zxp",
  "py",
  "tiff",
  "pl",
  "h",
  "json",
  "cs",
  "cpp",
  "csv",
  "ods",
  "xls",
  "jpe",
  "webp",
  "jfif",
  "mp4",
  "svg",
  "webm",
  "ai",
];
const not_supported = [
  "wma",
  "7z",
  "zip",
  "key",
  "rtf",
  "wpd",
  "tex",
  "rar",
  "aif",
  "mid",
  "midi",
  "mpa",
  "3gp",
  "3g2",
  "avi",
  "flv",
  "mpg",
  "mpeg",
  "wmv",
  "swf",
  "vob",
  "mkv",
  "arj",
  "deb",
  "pkg",
  "rpm",
  "gz",
  "z",
  "pps",
  "ps",
];
const img_show_iframe = ["ps"];
const microsoft_extensions = [
  "ppt",
  "pptx",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "odt",
  "odp",
  "ods",
  "csv",
];
// const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;

const Container: React.FunctionComponent<ContainerInterface> = ({
  children,
  className,
  style,
}) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

const Project: React.FunctionComponent<ProjectInterface> = ({
  data,
  total,
  index,
  handleIndexChange,
  handleBackClick,
  type,
  showEmbeded,
  fileType,
  renderedCount,
  projectType,
  componentCaller,
  selectedBanner,
  setSelectedBanner,
  commentMode,
  updateLoader,
  showAddComment,
  setShowAddComment,
  commentIndex,
  setCommentIndex,
  setReplyCommentIndex,
  setRetainedComments,
  imageDimensions,
  setImageDimensions,
  client,
  setInFocus,
}) => {
  const [zoom, setZoom] = useState(1);
  const [reset, setReset] = useState(0);
  const [loaded, setLoaded] = useState(false);
  let extention =
    data?.rendered_file &&
    data?.rendered_file
      .split(".")
      .pop()
      .toLowerCase();
  const projectRendering = data?.render_status === "INPROGRESS";

  const handleLoad = (e: any) => {
    setLoaded(true);
  };

  useEffect(() => {
    let timer: any;
    if (timer && loaded) {
      clearTimeout(timer);
    }
    if (
      fileType !== "video" &&
      fileType !== "audio" &&
      fileType !== "gif" &&
      !loaded
    ) {
      timer = setTimeout(() => {
        if (!loaded) {
          setReset((r: number) => r + 1);
        }
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [fileType, reset, loaded]);

  useEffect(() => {
    extention =
      data?.rendered_file &&
      data?.rendered_file
        .split(".")
        .pop()
        .toLowerCase();
  }, [data?.rendered_file]);

  const supportedType =
    IMAGE_SUPPORTED.includes(fileType) && !img_show_iframe.includes(fileType);
  if (extention && showEmbeded && !supported_type.includes(extention)) {
    return (
      <EmptyFile
        title='Oops... Preview of this file is not available!'
        subtitle=''
        img={getPreview(data.file_type) || fileType}
        className='file-preview'
        isPrimaryBtn={false}
        isSecondaryBtn={false}
      />
    );
  }
  return (
    <div className='area'>
      {!showEmbeded && (
        <ActionBar
          handleIndexChange={handleIndexChange}
          handleBackClick={handleBackClick}
          index={index}
          total={total}
          zoom={zoom}
          setZoom={setZoom}
          renderedCount={renderedCount}
          projectType={type}
          componentCaller={componentCaller}
          setSelectedBanner={setSelectedBanner}
        />
      )}
      {showEmbeded ? (
        supportedType ? (
          <div
            id='img-container'
            className={
              renderedCount === 1
                ? "singleImg img-container noselect"
                : "img-container noselect"
            }
          >
            {updateLoader || projectRendering ? (
              <div className='loader'>
                <img src={loader5} />
              </div>
            ) : (
              // <Img src={data?.rendered_file+(fileType !== 'gif' ? (detectQueryString(data?.rendered_image)? '&q=40': '?q=40')+'&auto=format':'')} />}
              <ImgMap
                src={data.rendered_file}
                style={{
                  objectFit: "scale-down",
                  objectPosition: "center center",
                }}
                commentMode={commentMode}
                zoom={zoom}
                showAddComment={showAddComment}
                setShowAddComment={setShowAddComment}
                type={type}
                selectedBanner={selectedBanner}
                setInFocus={setInFocus}
                commentIndex={commentIndex}
                setCommentIndex={setCommentIndex}
                setReplyCommentIndex={setReplyCommentIndex}
                setRetainedComments={setRetainedComments}
                imageDimensions={imageDimensions}
                setImageDimensions={setImageDimensions}
                client={client}
              />
            )}
            {/* <Img 
                            src={data?.rendered_file+(fileType !== 'gif' ? (detectQueryString(data.rendered_image)? '&q=40': '?q=40')+'&auto=format':'')} 
                            style={{objectFit:'scale-down',objectPosition:'center center'}}
                        /> */}
            {/* <Img src={data?.rendered_file} /> */}
          </div>
        ) : projectType === "video" ? (
          updateLoader ? (
            <div className='loader'>
              <img src={loader5} />
            </div>
          ) : (
            <video
              className='video-container'
              src={data.rendered_file || ""}
              controls
            ></video>
          )
        ) : projectType === "audio" ? (
          updateLoader ? (
            <div className='loader'>
              <img src={loader5} />
            </div>
          ) : (
            <audio className='audio-container' controls>
              <source src={data.rendered_file || ""} />
              Your browser does not support the audio tag.
            </audio>
          )
        ) : (
          <>
            {!loaded && !updateLoader && (
              <div className='loader'>
                <span className='loading'>Loading...</span>
              </div>
            )}
            {updateLoader ? (
              <div className='loader'>
                <img src={loader5} />
              </div>
            ) : (
              <iframe
                className='iframe-container'
                key={reset}
                onLoad={handleLoad}
                style={{ border: "none", height: "5px" }}
                src={
                  microsoft_extensions.includes(data?.file_type)
                    ? `https://view.officeapps.live.com/op/embed.aspx?src=${data?.rendered_file ||
                        ""}`
                    : `https://docs.google.com/gview?url=${data?.rendered_file ||
                        ""}&embedded=true`
                } //google docs view
              ></iframe>
            )}
          </>
        )
      ) : (
        <div className='slider-container'>
          {renderedCount > 1 && (
            <div
              className={`slider-btn prev-slider-btn ${
                index === 1 ? "disabled-btn" : ""
              }`}
              onClick={() => handleIndexChange(-1)}
            >
              <span
                className={`icon-next prev ${
                  index === 1 ? "disabled-icon" : ""
                }`}
              >
                <LeftOutlined />
              </span>
            </div>
          )}
          <div
            id='img-container'
            className={`img-container noselect ${
              renderedCount > 1 ? "multiple-img-container" : "single-img"
            }`}
          >
            {updateLoader || projectRendering ? (
              <div className='loader'>
                <img src={loader5} />
              </div>
            ) : (
              <ImgMap
                src={
                  S3_BASE_URL +
                    data?.rendered_image +
                    (detectQueryString(data?.rendered_image)
                      ? "&q=40"
                      : "?q=40") +
                    "&auto=format" || ""
                }
                style={{
                  transformOrigin: "top left",
                  transform: `scale(${zoom})`,
                }}
                commentMode={commentMode}
                zoom={zoom}
                showAddComment={showAddComment}
                setShowAddComment={setShowAddComment}
                type={type}
                selectedBanner={selectedBanner}
                setInFocus={setInFocus}
                commentIndex={commentIndex}
                setCommentIndex={setCommentIndex}
                setReplyCommentIndex={setReplyCommentIndex}
                setRetainedComments={setRetainedComments}
                imageDimensions={imageDimensions}
                setImageDimensions={setImageDimensions}
                client={client}
              />
            )}
            {/* <Img 
                            src={( IMAGE_BASE_URL + data.rendered_image + (detectQueryString(data.rendered_image)? '&q=40': '?q=40')+'&auto=format')||""} 
                            style={{transformOrigin: 'top left', transform: `scale(${zoom})`}}
                        /> */}
            {/* <img src={IMAGE_BASE_URL+data.rendered_image||data?.rendered_file}/> */}
          </div>
          {renderedCount > 1 && (
            <div
              className={`slider-btn ${
                index === renderedCount ? "disabled-btn" : ""
              }`}
              onClick={() => handleIndexChange(1)}
            >
              <span
                className={`icon-next ${
                  index === renderedCount ? "disabledicon" : ""
                }`}
              >
                <RightOutlined />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ActionBar: React.FunctionComponent<ActionBarInteface> = ({
  index,
  total,
  zoom,
  setZoom,
  handleIndexChange,
  handleBackClick,
  renderedCount,
  projectType,
  componentCaller,
  setSelectedBanner,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`action-bar ${
        projectType === "hpproject" ? "hp-action-bar" : ""
      }`}
    >
      {/* <div className="flex"> */}
      {/* <div className="flex">
                <span onClick={()=>handleIndexChange(-1)}>
                    <svg width="8" height="12" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5466 14.1067L4.43996 8L10.5466 1.88L8.66663 0L0.666631 8L8.66663 16L10.5466 14.1067Z" fill="#515151"/>
                    </svg>
                </span>
                <span onClick={()=>handleIndexChange(1)}>
                    <svg width="8" height="12" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.453369 14.1067L6.56004 8L0.453369 1.88L2.33337 0L10.3334 8L2.33337 16L0.453369 14.1067Z" fill="#515151"/>
                    </svg>
                </span>
            </div> */}
      {/* <div className="flex ml-5 noselect">
                <span>{index}</span>
                <span className="ml-5">/</span>
                <span className="ml-5">{total === 0? 1:total}</span>
            </div> */}
      {/* </div> */}
      {componentCaller === "publicCreative" && (
        <div className='flex back-container'>
          <Button
            typ='default'
            btnSize='sm'
            onClick={() => {
              dispatch(setBannerView(false));
              setSelectedBanner && setSelectedBanner(-1);
            }}
          >
            <LeftOutlined className='back-icon' />
            Back
          </Button>
        </div>
      )}
      {renderedCount > 1 && (
        <div className='flex container'>
          <span className='render-count'>{index}</span>
          <span className='render-count'>/</span>
          <span className='render-count'>{total === 0 ? 1 : total}</span>
        </div>
      )}
      <div className='flex container'>
        <div className='flex'>
          <span
            className='btn'
            onClick={(e) => {
              if (zoom > 0) setZoom((val: number) => val - 0.05);
            }}
          >
            <svg
              width='12'
              height='2'
              viewBox='0 0 12 2'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect width='12' height='2' fill='#152370' />
            </svg>
          </span>
          <span className='noselect'>
            <InputNumber
              className='zoom'
              value={Math.round(zoom * 100)}
              min={5}
              step={5}
              onChange={(value: any) => {
                setZoom(value / 100);
              }}
              formatter={(value) => `${value}%`}
            />
          </span>
          <span
            className='btn'
            onClick={(e) => {
              setZoom((val: number) => val + 0.05);
            }}
          >
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect y='5' width='12' height='2' fill='#152370' />
              <rect
                x='7'
                width='12'
                height='2'
                transform='rotate(90 7 0)'
                fill='#152370'
              />
            </svg>
          </span>
        </div>
        {/* <div className="flex ml-5" style={{width: '3.4rem'}} onClick={()=>handleBackClick()}>
                <span className="focus-out">
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6667 12.6667H19.3334V15.3333H15.3334V19.3333H12.6667V12.6667ZM0.666748 12.6667H7.33342V19.3333H4.66675V15.3333H0.666748V12.6667ZM4.66675 0.666667H7.33342V7.33333H0.666748V4.66667H4.66675V0.666667ZM19.3334 4.66667V7.33333H12.6667V0.666667H15.3334V4.66667H19.3334Z" fill="black"/>
                    </svg>
                </span>
            </div> */}
      </div>
    </div>
  );
};

const ProjectView: React.FunctionComponent<ProjectViewInterface> = ({
  index,
  handleIndexChange,
  handleBackClick,
  type,
  style,
  renderedCount,
  className,
  componentCaller,
  setSelectedBanner,
  commentMode,
  updateLoader,
  showAddComment,
  setShowAddComment,
  commentIndex,
  setCommentIndex,
  setReplyCommentIndex,
  setRetainedComments,
  imageDimensions,
  setImageDimensions,
  client,
  setInFocus,
}) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const { bannerData } = state;
  const banners = bannerData?.banners || bannerData?.renderedimages || [];

  return (
    <Container
      className={`aiquare-projectview ${className ? className : ""}`}
      style={style}
    >
      {/* Add a project activity bar component here */}
      <Project
        data={
          isItImage(bannerData?.type, bannerData?.file_type)
            ? banners[index]
            : bannerData
        }
        handleIndexChange={handleIndexChange}
        fileType={bannerData?.file_type}
        projectType={bannerData?.type}
        componentCaller={componentCaller}
        setInFocus={setInFocus}
        commentMode={commentMode}
        updateLoader={updateLoader}
        selectedBanner={index}
        setSelectedBanner={setSelectedBanner}
        client={client}
        setRetainedComments={setRetainedComments}
        showAddComment={showAddComment}
        setShowAddComment={setShowAddComment}
        handleBackClick={handleBackClick}
        index={index + 1}
        total={banners.length}
        type={type}
        showEmbeded={!isItImage(bannerData?.type, bannerData?.file_type)}
        renderedCount={renderedCount}
        commentIndex={commentIndex}
        setCommentIndex={setCommentIndex}
        setReplyCommentIndex={setReplyCommentIndex}
        imageDimensions={imageDimensions}
        setImageDimensions={setImageDimensions}
      />
    </Container>
  );
};

export default ProjectView;
