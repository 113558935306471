import { IGroup } from "./types";

export const getFlatStructure = (groups: IGroup, initialCanvasChildren: Array<any>): IGroup => {
  const allParentsIds: Array<any> = Object.keys(groups);
  const result: IGroup = {};
  const childrenIds: Array<string> = [];

  for (const parentId of allParentsIds) {
    if (childrenIds.indexOf(parentId) === -1) {
      const parentObj = initialCanvasChildren.find(c => c.id === parentId) || groups?.[parentId]?.parent;
      result[parentId] = { parent: parentObj, children: getAllNestedChildren(groups, parentId) };
      childrenIds.push(...result?.[parentId]?.children.map((o: any) => o.id));
    }
  }

  for (const parentId of Object.keys(result)) {
    if (childrenIds.indexOf(parentId) !== -1) {
      delete result[parentId];
    }
  }

  return result;
};

const getAllNestedChildren = (groups: any, objectId: any): any => {
  const children = groups?.[objectId]?.children;
  if (!children?.length) {
    return [];
  }
  const result = [];
  for (const child of children) {
    const isParent = groups?.[child?.id]?.children?.length;

    if (isParent) {
      result.push(child, ...getAllNestedChildren(groups, child.id));
    } else {
      result.push(child);
    }
  }

  return result;
};
