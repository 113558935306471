import { GET_PSD_JSON_SUCCESS, UPDATE_PROJECT_DATA, GET_FONTS_LIST, SET_ERR_MSG_PROJECT, RENDER_IMAGES, SAVE_RENDER_IMAGES, CLEAR_DATA_EDITOR, GET_URL_STOCK_IMAGE, CANCEL_PSD_UPLOAD } from './../types/createAiquireTypes';
import { LOADING, GET_SIZES, GET_POPULAR_SIZES, UPLOAD_FILE, GET_TEMPLATES, GET_CATEGORIES, SAVE_MULTIPLE_TEMPLATES, UPLOAD_MULTIPLE_FILES, GET_PROJECT, CREATE_PROJECT, UPDATE_PROJECT, GET_TEMPLATE_DATA, GET_CATEGORIES_ELEMENTS, GET_ELEMENTS_CATEGORY, GET_UPLOADED_FILES, DELETE_MULTIPLE_FILES, UPDATE_SIZE_CANVAS, GET_PSD_JSON, UPDATE_STATUS_DATA, SAVE_AI_DESIGN_DATA } from '../types';

export const loading = () => ({
  type: LOADING,
});

export const getTemplateSizes = (params: any, callback: any) => ({
  type: GET_SIZES,
  callback,
  params,
});

export const getTemplateData = (params: any, callback: any) => ({
  type: GET_TEMPLATE_DATA,
  callback,
  params,
});

export const getUrlStockImage = (params: any, callback: any) => ({
  type: GET_URL_STOCK_IMAGE,
  callback,
  params,
});

export const getPopularTemplateSizes = (params: any, callback: any) => ({
  type: GET_POPULAR_SIZES,
  callback,
  params,
});

export const getTemplates = (params: any, callback: any) => ({
  type: GET_TEMPLATES,
  callback,
  params,
});

export const getCategories = (params: any, callback: any) => ({
  type: GET_CATEGORIES,
  callback,
  params,
});

export const uploadFile = (params: any, callback: any, config: any) => ({
  type: UPLOAD_FILE,
  callback,
  params,
  config,
});

export const saveMultipleTemplates = (params: any, callback: any) => ({
  type: SAVE_MULTIPLE_TEMPLATES,
  callback,
  params,
});

export const uploadMultipleFiles = (params: any, callback: any) => ({
  type: UPLOAD_MULTIPLE_FILES,
  callback,
  params,
});

export const getProject = (params: any, callback: any) => ({
  type: GET_PROJECT,
  callback,
  params,
});

export const createProject = (params: any, callback: any) => ({
  type: CREATE_PROJECT,
  callback,
  params,
});

export const renderImages = (params: any, callback: any) => ({
  type: RENDER_IMAGES,
  callback,
  params,
});

export const saveRenderImages = (params: any, callback: any) => ({
  type: SAVE_RENDER_IMAGES,
  callback,
  params,
});

export const updateProject = (params: any, callback: any) => ({
  type: UPDATE_PROJECT,
  callback,
  params,
});

export const getCategoriesOfElements = (params: any, callback: any) => ({
  type: GET_CATEGORIES_ELEMENTS,
  callback,
  params,
});

export const getElementsOfCategory = (params: any, callback: any) => ({
  type: GET_ELEMENTS_CATEGORY,
  callback,
  params,
});

export const getUploadedFiles = (params: any, callback: any) => ({
  type: GET_UPLOADED_FILES,
  callback,
  params,
});

export const getFontsList = (params: any, callback: any) => ({
  type: GET_FONTS_LIST,
  callback,
  params,
});

export const deleteMultipleFiles = (params: any, callback: any) => ({
  type: DELETE_MULTIPLE_FILES,
  callback,
  params,
});

export const updateSize = (payload: any) => ({
  type: UPDATE_SIZE_CANVAS,
  payload,
});

export const clearDataEditor = () => ({
  type: CLEAR_DATA_EDITOR
});

export const updateStatusData = (payload: any) => ({
  type: UPDATE_STATUS_DATA,
  payload,
});

export const updateProjectData = (payload: any) => ({
  type: UPDATE_PROJECT_DATA,
  payload,
});

export const updatePsdData = (payload: any) => ({
  type: GET_PSD_JSON_SUCCESS,
  payload,
});

export const getPSDJSON = (params: any, callback: any, config?: any) => ({
  type: GET_PSD_JSON,
  callback,
  params,
  config,
});

export const setErrMsg = (payload: any) => ({
  type: SET_ERR_MSG_PROJECT,
  payload,
});

export const cancelPSDUpload = () => ({
  type: CANCEL_PSD_UPLOAD
})

export const saveAiDesignData = (payload: any) => ({
  type: SAVE_AI_DESIGN_DATA,
  payload,
});
