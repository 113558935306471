import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, SelectOption } from 'pyxis-ui-kit';
import { debounce } from 'lodash';
import { Dropdown, Menu, MenuItem, Input, Spin, AutoComplete } from 'pyxis-ui-kit';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchTags } from '../../../../store/actions';


const TagSuggestionInput:React.FC<{
    handleAddOrRemoveTag: Function;
    customTags: any;
    entityType: string;
}> = ({handleAddOrRemoveTag, customTags,entityType}) => {
    const [visible,setVisible] = useState(false);
    const [tags,setTags] = useState<{value: string}[]>([]);
    const [loading,setLoader] = useState(false);
    const [isOpen,setIsOpen] = useState(false);
    const dispatch = useDispatch();

    // const [customTags, setCustomTags] = useState(custom_tags);

    const fetchTagsList = useCallback((text:string)=>{
        dispatch(searchTags({search: text},(res:any,err:boolean)=>{
            setLoader(false)
            if(!err){
                let tempTags = res.data.keywords.reduce((acc:any,tag:string)=>(
                    [...acc,{value: tag}]
                ),[])
                if(tempTags.length === 0) { setIsOpen(false); }
                setTags(tempTags)
            }else{
                setIsOpen(false);
                setTags([])
            }
        }))
    },[])

    const handleDelayedSearch = debounce((text:string) =>fetchTagsList(text),1000);
    const handleSearch = (text:string) => {
        if(text.trim()) { 
            setLoader(true) 
            handleDelayedSearch(text)
        } else {
            setLoader(false)
            setTags([]);
        }
    };

    return (
        <Select 
            mode="tags" 
            placeholder="Tag Name" 
            className="tags-input"
            defaultValue={customTags}
            onSearch={handleSearch}
            onDropdownVisibleChange={(open)=>setIsOpen(open)}
            open={tags.length > 0 && isOpen}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            // onKeyDown={(e:any)=>{
            //     if(e.key === 'Escape'){
            //         // setShowInput(false)
            //     }
            //     if(e.key === 'Enter' && e.target?.value){
            //         handleAddOrRemoveTag(e.target?.value,'add')
            //         // setShowInput(false)
            //     }
            // }}
            onSelect={(text:string)=>{
                handleAddOrRemoveTag(text,'add');
                // setShowInput(false)
            }}
            onDeselect={(value:any)=>{
                if(value?.trim() !== '') {
                    handleAddOrRemoveTag(value, 'delete', customTags.indexOf(value));
                }
            }}
            onBlur={(e:any)=>{
                if(e.target?.value.trim()) {
                    handleAddOrRemoveTag(e.target?.value, 'add')
                }
            }}
            listHeight={150}
            >
                {tags.map((name, index)=>(
                    <SelectOption
                        className="options" 
                        key={name.value} 
                        value={name.value}>
                            {name.value}
                    </SelectOption>
                ))}
        </Select> 
    );
}

export default TagSuggestionInput;