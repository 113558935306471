import { getObjectSize, getObjectHeight } from "./getObjectSize";

export const getObjListSortedByZIndex = (originalObjList: Array<any>, objList: Array<any>) => {
  const result: Array<any> = [];

  for (const origObj of originalObjList) {
    const obj = objList.find(obj => obj?.id === origObj?.id)
    if (typeof obj !== 'undefined') {
      result.push(objList.find(obj => obj.id === origObj.id))
    }
  }

  return result;
};


export const sortForHorizontalResize = (a: any, b: any) => {
  return a.left - b.left
};

export const sortForVerticalResize = (a: any, b: any) => {
  if (a.top < b.top) {
    return -1;
  } else if (a.top === b.top) {
    return a.left - b.left;
  }

  return 1;
};

export const sortBySquareDesc = (a: any, b: any) => {
  const [aWidth, aHeight] = getObjectSize(a);
  const [bWidth, bHeight] = getObjectSize(b);
  const aSquare = aWidth * aHeight;
  const bSquare = bWidth * bHeight

  if (aSquare > bSquare) {
    return 1;
  } else if (bSquare > aSquare) {
    return -1;
  }

  return 0;
};

const getTheMostLeftObjIndex = (objList: Array<any>) => {
  return objList.reduce((acc, cur, curIndex) => {
    if (objList[curIndex].left < objList[acc].left) {
      return curIndex;
    }

    return acc;
  }, 0);
};

const getOverlapGroup = (obj: any, objList: any) => {
  return objList.reduce((acc: Array<any>, cur: any, curIndex: number) => {
    const height = getObjectHeight(cur)
    const curObjCenterY = cur.top + height / 2;

    if (curObjCenterY + height / 6 > obj.top) {
      acc.push(curIndex);
    }

    return acc;
  }, []);
};

export const sortObjectListInReadOrder = (prevObjList: Array<any>) => {
  const tmpObjList = [...prevObjList];
  const sortedIds = sortObjectListInReadOrderInner(tmpObjList);
  const sortById = (a: any, b: any) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
  return [...prevObjList.sort(sortById)];
};

export const sortObjectListInReadOrderInner = (objList: Array<any>) => {
  let tmpObjList = [...objList];
  const result: Array<any> = [];

  const leftObjIndex = getTheMostLeftObjIndex(tmpObjList);
  const leftObj = tmpObjList[leftObjIndex];
  tmpObjList.splice(leftObjIndex, 1);
  const overlapGroupIndexList = getOverlapGroup(leftObj, tmpObjList);

  result.unshift(leftObj?.id);

  if (overlapGroupIndexList?.length !== 0) {
    const overlapGroup = overlapGroupIndexList.map((index: number) => tmpObjList[index]);
    const overlapGroupIds = overlapGroup.map((o: any) => o.id);
    tmpObjList = tmpObjList.filter(item => !overlapGroupIds.includes(item.id));
    result.push(...sortObjectListInReadOrderInner(overlapGroup));
  }

  if (tmpObjList?.length !== 0) {
    result.unshift(...sortObjectListInReadOrderInner(tmpObjList));
  }

  return result;
};
