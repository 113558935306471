import { RESIZE_TYPE } from "../constants";
import { resizeBackgroundPartsHorizontal } from "./resizeBackgroundPartHorizontal";
import { resizeBackgroundPartsVertical } from "./resizeBackgroundPartVertical";
import { resizeBackgroundPartsSimple } from "./resizeBackgroundPartsSimple";
import { cloneDeep } from "lodash";

type Size = Array<number>;

interface IResult {
  backgrounds: Array<any>;
}

export const resizeBackgroundParts = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  resizeType: RESIZE_TYPE
) => {
  const ratioX = canvasWidth / prevWidth;
  const ratioY = canvasHeight / prevHeight;
  const isHorizontalResize = ratioX > ratioY;

  let resizeFunc = resizeBackgroundPartsHorizontal;

  if (resizeType === RESIZE_TYPE.TO_EXTREME && isHorizontalResize) {
    resizeFunc = resizeBackgroundPartsHorizontal;
  } else if (resizeType === RESIZE_TYPE.TO_EXTREME && !isHorizontalResize) {
    resizeFunc = resizeBackgroundPartsVertical;
  } else {
    //@ts-ignore
    resizeFunc = resizeBackgroundPartsSimple;
  }

  return resizeFunc(cloneDeep(children), prevWidth, prevHeight, canvasWidth, canvasHeight)
};
