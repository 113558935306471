import React from 'react';
import './index.scss';

interface TopBarInterface {
    title: any;
    subtitle: any;
    leftBtn: any;
    rightBtn: any;
    className?:string;
    style?: any;
}

const TopBar:React.FC<TopBarInterface> = ({title, subtitle, leftBtn, rightBtn, className, style}) => {
    return <div className={`hyper-personlize-topbar-select ${className ? className : ''}`} style={style || {}}>
        <div className="action">
            {leftBtn}
        </div>
        <div className="detail">
            {title && <h3 className="title">{title}</h3>}
            {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
        <div className="action">
            {rightBtn}
        </div>
    </div>
}

export default TopBar;