export const AUDIO_SUPPORTED = ".aif,.aiff,.cda,.mid,.midi,.mp3,.mp2,.mpa,.ogg,.wav,.wma,.wpl";
export const VIDEO_SUPPORTED = ".3g2,.3gp,.avi,.flv,.h264,.m4v,.mkv,.mov,.mp4,.mpg,.rm,.mpeg,.swf,.vob,.wmv, .avi";
export const WORD_SUPPORTED = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.csv";
export const ZIP_SUPPORTED = ".7z,.7-Zip,.arj,.deb,.pkg,.rar,.rpm,.tar.gz,.z,.zip";
export const PRESENTATION_SUPPORTED = ".key,.odp,.pps,.ppt,.pptx";
export const IMAGE_SUPPORTED = ".ai,.bmp,.gif,.ico,.jpeg,.jpg,.png,.ps,.psd,.svg,.webp,.jfif,.jpe";
export const OTHER_SUPPORTED = ".css,.htm,.html,.js";

//enable edit and create for users with this email
// export const enableEdit = ['@pyxispm.com','@hexaware.com', '@pixis.ai'];
// temp fix to set isEditEnable to True
export const enableEdit = ['@'];