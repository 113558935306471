import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import { LeftSideBar } from '../../components/RolesSection/MembersAndProducts';
import Roles from '../../components/RolesSection/RolesAndPermissions';

import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const AiquireRoles: FC = () => {
  const SuspenseComponent = () => {
    return (
      <>
        <div className="dashboard-wrap">
          <LeftSideBar />
          <div className="flex-column">
            <Roles />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default AiquireRoles;

