import React, {FC, useState,useEffect, Dispatch, ReactElement } from 'react';
import {Card,Button,Title,DragAndDrop,Input} from 'pyxis-ui-kit'
import { Banner, Color, FolderState, LibraryState, RolesState, Typography } from '../../../store/types';
import { useDispatch,useSelector } from 'react-redux';
import './index.scss';
import { openToast } from '../../../Toasts';
import { InboxOutlined,RightOutlined,CloseOutlined,LockFilled, Loading3QuartersOutlined, DeleteOutlined, DeleteFilled } from '@ant-design/icons';
import {uploadLibraryFile,deleteFiles,uploadTypography,deleteTypography,uploadColor,fetchColors, fetchFiles, deleteColor, fetchTypographies, setTypography, setavailableTypography, setProjects, addColors, removeColor, setColors } from '../../../store/actions';
import { ChromePicker } from 'react-color';
import FontFaceObserver from 'fontfaceobserver';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import { detectQueryString } from '../../../utilities/common-function';
import Img from '../../ImageLoader/ImageLoader';
import iconDelete from '../../../assets/svg/iconDelete.svg';
import remove from '../../../assets/svg/remove.svg';


interface Ifile {
  description: string;
  file_url: string;
  id: number;
  project: string | null;
  resource_type: string;
  title: string | null;
  type: string;
}

interface Ifont{
  auth_user_id: string
  file: string
  font_family: string
  font_style: string
  id: number
  is_web_font: boolean
}

interface UploadInterface{
  selectedType:string,
  dispatch: Dispatch<any>,
  banners:Banner[],
  typographies:Typography[],
  acceptParams:string
}

interface ColorInterface{
  colors:Color[],
  dispatch:Dispatch<any>
}

interface DisplayContainerInterface{
  selectedType:string,
  dispatch: Dispatch<any>,
  banners:Banner[],
  typographies:Typography[]
}


const BrandUpload: React.FunctionComponent<UploadInterface> = ({selectedType,dispatch,banners,typographies,acceptParams}) => {
  const [fileUploaded,setFileUploaded]=useState(0)
  const [loading,setLoader] = useState(false);
  let image_info:any=[]
  const appendProject = (banner:any)=>{
    dispatch(setProjects([...banner,...banners]));
  }
  const onChange=(info:any)=>{
    setLoader(true)
    image_info.push(info.file)
    if(selectedType==="custom files" || selectedType==="brand logo"){
      if(info.fileList.length===(image_info.length+fileUploaded) ){
      dispatch(uploadLibraryFile({file:image_info,type: "direct"},(res:any,err:boolean)=>{
          if(!err){
            let banners:any[] = (Array.isArray(res.data)?res.data:[res.data]).reduce((acc:any,data:any)=>{
                return [...acc,{
                    id: data.id,
                    parent: 0,
                    title: data.title,
                    type: data.resource_type,
                    can_manage: true,
                    rendered_file: IMAGE_BASE_URL + data.file_url
                }]
            },[])
            appendProject(banners)
            setLoader(false)  
            setFileUploaded(image_info.length+fileUploaded)
          }
        }))
      }
    }else if(selectedType==="typography"){
      if(info.fileList.length===(image_info.length+fileUploaded)){
        dispatch(uploadTypography(image_info,1,(res:any,err:boolean)=>{    
          setLoader(false)
          if(!err){
            setFileUploaded(image_info.length+fileUploaded)
            addFont(res.data.font_family+'-'+res.data.font_style,res.data.file);
            openToast('success', "Typography Uploaded!")
          }
        }))
      }
    }

  }
  const addFont = (font: string | null,file:string) => {
    if (font === null) return;
    const fontData = {
      weight: 400,
      src: `url('${IMAGE_BASE_URL}${file}')`,
    };
    const obs = new FontFaceObserver(font.toLowerCase(), fontData);
    // @ts-ignore
    const ff = new FontFace(font.toLowerCase(), fontData.src);
    ff.load()
      // @ts-ignore
      .then(f => {
        // @ts-ignore
        document.fonts.add(f);
      })
      .catch(() => {
        openToast('error', `Missing Font: ${font}`);
    });
    // obs
    //   .load()
    //   .then(() => {
    //     setFontFamily(font);
    // })
    //   .catch(() => {
    //     setFontFamily('Helvetica-Black');
    // });
  };
  return <DragAndDrop 
  className={banners.length === 0 && typographies.length === 0 ? `` : `small-box`}
  listType="picture-card"
  multiple={true}
  accept={acceptParams}
  onChange={onChange}
  showUploadList={false}
  beforeUpload={()=>false} 
>
  <p className="ant-upload-drag-icon"><InboxOutlined /></p>
  <p>Drop file here</p>
  <p>or</p>
  <div className="upload-btn"><span>Choose from desktop</span>
  {loading && <span className="loader"><Loading3QuartersOutlined spin /></span>}
  </div>
  <p className="max-size">{selectedType==="typography" ? "OTF, TTF, WOFF" :"JPG, PNG, GIF" } are supported</p>
  </DragAndDrop>
}

const BrandColor: React.FunctionComponent<ColorInterface> = ({colors,dispatch}) => {
    const [color,setColor]=useState('#152370')
    const [title,setTitle]=useState('#152370')
    const  handleChangeComplete = (color:any) => {
      setColor(color.hex)
      title.startsWith('#')&&setTitle(color.hex)
    };
    const handleDeleteClick=(id:number)=>{
        dispatch(deleteColor([id],(res:any, err:boolean) =>{
          dispatch(removeColor([id]))
          openToast('success', "Color Deleted!")
        }))
    }
    const addColor=()=>{
      let flag=colors.some((colorData:Color)=>colorData['color_code']===color)
      if(flag){
        openToast('warn','Color is already added.')
      }else{
        const colorName=title?title:color    
        dispatch(uploadColor({color_code:color,name:colorName},(res:any,err:boolean)=>{    
            if(!err){
            dispatch(addColors([res.data]))
            openToast('success',"Color Uploaded!")
            }
        }))
      }
    }
    const handleKeyDown = (e:any) => {
      if (e.key === 'Enter') {addColor()}
    }
    return   <>
    <div className='color-picker-container'>
        <ChromePicker
          disableAlpha={true}
          color={color}
          onChangeComplete={handleChangeComplete}
        ></ChromePicker>
    </div>
    <div className="color-form">
      <label className="color-label noselect">Color Name</label>
      <div className="add-color">
        <Input
          placeholder="Enter your color name. For example, Red-Default."
          onChange={(e:any)=>setTitle(e.target.value)}
          value={title}
          className="color-input noselect"
          onKeyDown={(e:any)=>handleKeyDown(e)}  
        />
        <Button btnsize="md" type="primary" className="save" onClick={addColor}><span className="add">Add color</span></Button>
      </div>
    </div>
    <div className="color-card">
      {colors.map((color:any,key:number) =>
        (
          <div className="color-container" key={key}>
            <div className="selected-color" style={{backgroundColor:color.color_code}}/>
            <div className="color-action">
              <div className="color-info">
                <div className="color-name">{color.name}</div>
                <div className="color-code">{color.color_code}</div>
              </div>
              <img className="delete-color" src={iconDelete} onClick={()=>handleDeleteClick(color.id)}/>
            </div>
          </div>
        ))
      }
    </div>
  </>
}

const DisplayContainer: React.FunctionComponent<DisplayContainerInterface> = ({selectedType,dispatch,banners,typographies}) => {
    const handleDeleteClick=(id:number)=>{
      dispatch(deleteFiles([id], (res:any, err:boolean) =>{
        if(!err){
          dispatch(setProjects(banners.filter((obj:any)=>(obj.id !== id))))
        }
      })); 
    }
    const deleteFont=(index:number,id:number)=>{
        dispatch(deleteTypography(index, id))     
    }
    return  <div className="image-card">
    {(selectedType==="custom files" || selectedType==="brand logo") &&
    banners.map((file:any,key:number) =>
    (
      <div className="image-container" key={key}>
      <Img src={!file.rendered_file.includes("gif")?file.rendered_file+(detectQueryString(file.rendered_file)? '&w=210': '?w=210'):file.rendered_file} className="file"/>
      <div className="image-delete" onClick={()=>handleDeleteClick(file.id)}><img src={remove} className="close-btn"/></div>
      </div>
    ))
    }
    {selectedType==="typography" &&
    typographies.map((file:any,key:number) =>
    (
        <div className="font-container" key={key}>
          <div className="font-display noselect" style={{fontFamily:file.font_family+'-'+file.font_style}}>The quick brown fox jumps over the lazy dog</div>
          <div className="font-title">{file.font_family}</div>
          <div className="font-delete" onClick={()=>deleteFont(key,file.id)}><img src={remove} className="close-btn"/></div>
        </div>
    ))
    }
  </div>
}


const UploadFiles:FC<{routePage:any,selectedType:any}> = ({routePage,selectedType}) => {
  const [acceptParams,setAccesptParams]=useState("image/jpg,image/jpeg,image/png,image/gif")
  const dispatch = useDispatch();
  const [isDisabled,setIsDisabled]=useState(false)
  const state = useSelector((state: {library: LibraryState})=>(state.library));
  const stateFile = useSelector((state: {folders: FolderState})=>(state.folders));
  const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
  const { colors,typographies } = state;
  const { banners } = stateFile;
    useEffect(() => {
    if(selectedType==="typography"){
      setAccesptParams(".ttf,.otf,.woff")
      dispatch(fetchTypographies({page: 1,webfont:false},(response:any,error:boolean)=>{
        if(!error){  
            dispatch(setTypography(response.data.results))
        }
      }))
      setIsDisabled(userPermission.indexOf('manage-typography') === -1)
    }else if(selectedType==="brand color"){
      dispatch(fetchColors({page: 1},(res:any,err:boolean)=>{
        if(!err){
            dispatch(setColors(res.data.results))
        }
      }))
      setIsDisabled(userPermission.indexOf('manage-brand-colors') === -1)
    }if(selectedType==="custom files" || selectedType==="brand logo"){
      setIsDisabled(userPermission.indexOf('create-new-banners') === -1)
      dispatch(fetchFiles({page: 1},(res:any,err:boolean)=>{
          dispatch(setProjects(res.results))
      }))
    }
  },[]);
  
  return <div className="upload-files">
    { isDisabled ? <div className="role-container">
        <LockFilled className="lock"/>
        <div className="role-error">You don't have access to this feature as per your role</div>
      </div> :
      <div className="container">
        <Title className="title">Upload Your {selectedType==='custom files'?'files':selectedType}</Title>
        {selectedType==="brand color" ? <Title className="subtitle">Select brand color from color picker</Title> : <Title className="subtitle">Drag and drop files here. Or simply choose a file to upload</Title>}
        
        <Card className="upload-card">
        {(selectedType==="custom files" || selectedType==="brand logo" || selectedType==="typography") && 
        <BrandUpload
          selectedType={selectedType}
          banners={banners}
          typographies={typographies}
          dispatch={dispatch}
          acceptParams={acceptParams}
        />}
      {selectedType==="brand color" && <BrandColor colors={colors} dispatch={dispatch}/>}
      <DisplayContainer 
        selectedType={selectedType}
        banners={banners}
        typographies={typographies}
        dispatch={dispatch}
      />
      <div className="btn-container">
        <Button 
          btnsize="md" 
          type="primary" 
          className="continue" 
          disabled={!colors.length && !banners.length && !typographies.length}
          onClick={()=>{routePage(selectedType)}}>
          Continue<RightOutlined className="right-arrow"/>
          </Button>
      </div>
    </Card>
  </div> 
  }
  </div>
}


export default UploadFiles;
