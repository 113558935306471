import { put, takeLatest, call, all, takeEvery, select } from "redux-saga/effects";
import { DESIGN_URI } from "../../utilities/paths";
import { hyperProjectsAPI } from "./../../MappedAPI/MappedAPI";
import HttpService from "../../HttpService/HttpService";
import {
  CreateHyperProject,
  CREATE_HYPER_PROJECT,
  MoveHyperProjects,
  DeleteHyperProjects,
  DELETE_HYPER_PROJECTS,
  MOVE_HYPER_PROJECTS,
  AddSizesToHpproject,
  ADD_SIZES_TO_HPPROJECT,
  DELETE_SIZES_FROM_HPPROJECT,
  DeleteSizesFromHpproject,
  FetchLayersWithAssets,
  FETCH_LAYERS_WITH_ASSETS,
  ADD_LAYER_ASSETS,
  AddLayerAssets,
  DELETE_LAYER_ASSET,
  DeleteLayerAsset,
  FetchHyperRenderedImages,
  FETCH_HYPER_RENDERED_IMAGES,
  DeleteHyperRenderedImages,
  DELETE_HYPER_RENDERED_IMAGES,
  CANCEL_HYPER_PROJECT
} from "../types";
import axios from "axios";

// Token to abort API on close of HyperProjectsPopUp
let HyperProjectToken = axios.CancelToken.source();

const getToken = (state: any) => state.authentication.token;
const getTokenOrPublic = (state: any) => (!window.location.hash.startsWith('#/cocreate/public') ? state.authentication.token : state.publicLink.token);
const getProductId = (state: any) => state.roles.selectedProductId;

function* createHyperProject(action: CreateHyperProject) {
  let { payload, callback } = action;
  const TOKEN: any = yield select(getToken);
  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${hyperProjectsAPI}`,
      TOKEN,
      payload,
      {},
      HyperProjectToken.token
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

// Abort create hyper project API 
function* cancelHyperProject(){ 
  HyperProjectToken.cancel();
  HyperProjectToken = axios.CancelToken.source();
}

function* moveHyperProjects(action: MoveHyperProjects) {
  let { payload, callback } = action;
  const TOKEN: any = yield select(getToken);
  const product_id: any = yield select(getProductId);
  try {
    let params = "/move";
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${hyperProjectsAPI}${params}`,
      TOKEN,
      { hpproject_ids:payload.hpproject_ids,destination_folder_id: payload.destination_folder_id === 0?undefined:payload.destination_folder_id, product_id }
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* deleteHyperProjects(action: DeleteHyperProjects) {
  let { payload, callback } = action;
  const TOKEN: any = yield select(getToken);
  const product_id: any = yield select(getProductId);
  try {
    let params, response;
    if (Array.isArray(payload)) {
      params = `/delete`;
      response = yield call(
        HttpService.post,
        DESIGN_URI,
        `${hyperProjectsAPI}${params}`,
        TOKEN,
        { ids: payload }
      );
    } else {
      params = `/${payload}`;
      response = yield call(
        HttpService.delete,
        DESIGN_URI,
        `${hyperProjectsAPI}${params}`,
        TOKEN
      );
    }

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* addSizesToHyperProjects(action: AddSizesToHpproject) {
  let {
    payload: { project_id, size_ids },
    callback,
  } = action;
  const TOKEN: any = yield select(getToken);
  try {
    let params = `/${project_id}/sizes`;
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${hyperProjectsAPI}${params}`,
      TOKEN,
      { size_ids }
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* removeSizesToHyperProjects(action: DeleteSizesFromHpproject) {
  let {
    payload: { project_id, size_ids },
    callback,
  } = action;
  const TOKEN: any = yield select(getToken);
  try {
    let params = `/${project_id}/sizes`;
    let response = yield call(
      HttpService.delete,
      DESIGN_URI,
      `${hyperProjectsAPI}${params}`,
      TOKEN,
      { size_ids }
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchLayersWithAssets(action: FetchLayersWithAssets) {
  let {
    payload: { project_id, page, page_size },
    callback,
  } = action;
  const TOKEN: any = yield select(getTokenOrPublic);
  try {
    let params = `/${project_id}/layers?page=${page}&page_size=${page_size}`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${hyperProjectsAPI}${params}`,
      TOKEN
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* addLayerAssets(action: AddLayerAssets) {
  let {
    payload: { project_id, layer_id,assets },
    callback,
  } = action;
  const TOKEN: any = yield select(getToken);
  try {
    let params = `/${project_id}/layer/${layer_id}/assets`;
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${hyperProjectsAPI}${params}`,
      TOKEN,
      { assets }
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* deleteLayerAsset(action: DeleteLayerAsset) {
    let {
      payload: { project_id, layer_id,asset_id },
      callback,
    } = action;
    const TOKEN: any = yield select(getToken);
    try {
      let params = `/${project_id}/layer/${layer_id}/asset/${asset_id}`;
      let response = yield call(
        HttpService.delete,
        DESIGN_URI,
        `${hyperProjectsAPI}${params}`,
        TOKEN
      );
  
      if (!response.data.error) {
        callback && callback(response.data, false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
      callback && callback(error.response, true);
    }
}

function* fetchHyperRenderedImages(action: FetchHyperRenderedImages) {
    let {
      payload: { page_size,page,project_id,size_id,text_layer_asset_id,image_layer_asset_id,layer_asset_id },
      callback,
    } = action;
    const TOKEN: any = yield select(getTokenOrPublic);
    try {
      // let params = `/${project_id}/rendered-images?page=${page}&page_size=${page_size}${size_id?'&size_id='+size_id:''}${text_layer_asset_id!==null?'&text_layer_asset_id='+text_layer_asset_id:''}${text_layer_asset_id!==null?'&image_layer_asset_id='+image_layer_asset_id:''}${layer_asset_id?'&layer_asset_id='+layer_asset_id:''}`;
      let params = `/${project_id}/rendered-images?page=${page}&page_size=${page_size}${size_id?'&size_id='+size_id:''}${text_layer_asset_id!==null?'&text_layer_asset_id='+JSON.stringify(text_layer_asset_id):''}${image_layer_asset_id!==null?'&image_layer_asset_id='+JSON.stringify(image_layer_asset_id):''}`;
      let response = yield call(
        HttpService.get,
        DESIGN_URI,
        `${hyperProjectsAPI}${params}`,
        TOKEN
      );
  
      if (!response.data.error) {
        callback && callback(response.data, false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
      callback && callback(error.response, true);
    }
}

function* deleteHyperRenderedImages(action: DeleteHyperRenderedImages) {
    let {
      payload: { project_id,ids },
      callback,
    } = action;
    const TOKEN: any = yield select(getToken);
    try {
      let params = `/${project_id}/rendered-images`;
      let response = yield call(
        HttpService.delete,
        DESIGN_URI,
        `${hyperProjectsAPI}${params}`,
        TOKEN,
        {ids}
      );
  
      if (!response.data.error) {
        callback && callback(response.data, false);
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
      callback && callback(error.response, true);
    }
}

export function* hyperProjectFolderWatcher() {
  yield takeEvery(CREATE_HYPER_PROJECT, createHyperProject);
  yield takeEvery(CANCEL_HYPER_PROJECT, cancelHyperProject);
  yield takeEvery(DELETE_HYPER_PROJECTS, deleteHyperProjects);
  yield takeEvery(MOVE_HYPER_PROJECTS, moveHyperProjects);
  yield takeEvery(ADD_SIZES_TO_HPPROJECT, addSizesToHyperProjects);
  yield takeEvery(DELETE_SIZES_FROM_HPPROJECT, removeSizesToHyperProjects);
  yield takeEvery(FETCH_LAYERS_WITH_ASSETS, fetchLayersWithAssets);
  yield takeEvery(ADD_LAYER_ASSETS, addLayerAssets);
  yield takeEvery(DELETE_LAYER_ASSET, deleteLayerAsset);
  yield takeEvery(FETCH_HYPER_RENDERED_IMAGES, fetchHyperRenderedImages);
  yield takeEvery(DELETE_HYPER_RENDERED_IMAGES, deleteHyperRenderedImages);
}
