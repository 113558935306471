import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import PublicNavBar from '../../components/Public/PublicNavBar';

import './index.scss';
import PublicCreative from '../../components/Public/PublicCreative';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const PublicProject: FC<any> = () => {

  const SuspenseComponent = () => {
    return (
      <div className="public-creative-wrap">
        <PublicNavBar />
        <div className="flex h-100">
          <PublicCreative />
        </div>
      </div>
    );
  }

  return (<>
    <div className="cocreate-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  </>);
};

export default PublicProject;

