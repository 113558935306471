import { createStore, applyMiddleware, combineReducers,compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import {
    imagesTrayReducer, elementsTrayReducer, projectFolderReducer, libraryReducer, workflowReducer, approvalReducer
    , createAiquireReducer, cropStateReducer, drangAndDropImageReducer, rolesReducer, authReducer, hyperPersonalizeReducer
    , hyperProjectFolderReducer, templatesTrayReducer, publicLinkReducer
} from "./reducers";
import { rootSaga } from "./sagas";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { exportReducer } from './reducers/export';
import { agencyFileDropReducer } from './reducers/agencyFile';
// import LogRocket from 'logrocket';

// // enable redux dev tool
declare global {
    interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'root1',
    storage,
    whitelist: ["authentication"]
}

const rolesConfig = {
    key: 'roles',
    storage,
    whitelist: ["selectedProductId","brand_id","org_id"]
};

const publicLinkConfig = {
    key: 'publicLink',
    storage: storageSession,
}

const rootReducer = combineReducers({
    images: imagesTrayReducer,
    elements: elementsTrayReducer,
    create: createAiquireReducer,
    crop: cropStateReducer,
    dragAndDrop: drangAndDropImageReducer,
    folders: projectFolderReducer, 
    library: libraryReducer, 
    workflows: workflowReducer,
    approval: approvalReducer,
    authentication: authReducer,
    roles: persistReducer(rolesConfig,rolesReducer),
    hyper: hyperPersonalizeReducer,
    hyperProject: hyperProjectFolderReducer,
    templates: templatesTrayReducer,
    export: exportReducer,
    publicLink: persistReducer(publicLinkConfig, publicLinkReducer),
    agencyFileDrop: agencyFileDropReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
)

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga);


export { store, persistor }