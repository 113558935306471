import {
  ENABLE_CROP,
  EnableCropAction,
  CANCEL_CROP,
  CancelCropAction,
  CROP_DONE,
  CropDoneAction,
  ResetCropStateAction,
  RESET_CROP,
  EnableSvgCropAction,
  ENABLE_SVG_CROP,
} from '../types/cropTypes';

export const enableCropAction = (): EnableCropAction => ({
  type: ENABLE_CROP,
  payload: {
    cropEnabled: true,
  },
});

export const enableSvgCropAction = (): EnableSvgCropAction => ({
  type: ENABLE_SVG_CROP,
  payload: {
    svgCropEnabled: true,
  },
});

export const cancelCropAction = (): CancelCropAction => ({
  type: CANCEL_CROP,
  payload: {
    cropEnabled: false,
    svgCropEnabled: false,
  },
});

export const cropDoneAction = (): CropDoneAction => ({
  type: CROP_DONE,
  payload: {
    cropDone: true,
  },
});

export const resetCropAction = (): ResetCropStateAction => ({
  type: RESET_CROP,
  payload: {
    cropDone: false,
    cropEnabled: false,
    svgCropDone: false,
    svgCropEnabled: false,
  },
});
