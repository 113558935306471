import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { WorkflowState, FolderState, Round, WorkFLow,ApprovalState, PageData, HyperProjectFoldersState } from '../../../store/types';
import { fetchWorkFlows, fetchWorkFlowData,sendForApproval, getUsersData } from '../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import GreyBackground from '../../../assets/img/grey-background.png';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import { openToast } from '../../../Toasts';
import Avatar from '../../shared/Avatar';
import Img from '../../ImageLoader/ImageLoader';
import { getPreview } from '../../../utilities/common-function';

interface ContainerInterface {
    className: string
}

interface TitleInterface {
    title: string
}

interface SectionsInterface {
    project: any;
    projectType: string;
    handleClose: Function;
    onSubmit: Function;
}
interface ApprovalWorkFlowsInterface {
    workflows: WorkFLow[],
    setActiveWorkFlow: Function,
    activeWorkFlow: number,
    pageData: PageData|null,
    fetchWorkflows: Function
}
interface WorkFlowInterface {
    data: WorkFLow,
    setActiveWorkFlow: Function,
    isActive: boolean
}
interface ApprovalRoundsInterface {
    rounds: Round[]
}
interface RoundInterface {
    data1: Round,
    index: number
}
interface CardInterface {
    data: any
}
interface FooterInterface {
    activeWorkFlow: number,
    isDisabled: boolean,
    projectId: number,
    callback?: Function,
    note: string;
    projectType: string;
    handleClose: Function;
    isSubmitDisabled: boolean;
}

const colors = [
    "#D32F2F",
    "#B85600",
    "#F55F44",
    "#F2994A",
    "#20007F",
    "#9E54FD",
    "#025D8C",
    "#006666"
]

const Container: React.FunctionComponent<ContainerInterface> = ({children,className}) =>{
    return <div className={className}>
        {children}
    </div>;
}

const Title: React.FunctionComponent<TitleInterface> = ({title}) =>{
    return <Container className="title">
        <p>{title}</p>
    </Container>
}

const ApprovalWorkFlows: React.FunctionComponent<ApprovalWorkFlowsInterface> = ({workflows,activeWorkFlow,setActiveWorkFlow,pageData,fetchWorkflows}) =>{
    const dispatch = useDispatch();
    return (
        <div className="workflows" id="workflow-selection-popup">
            <div className="headertext">WorkFlow</div>
            <InfiniteScroll
                dataLength={workflows.length}
                next={()=>{
                    fetchWorkflows(pageData? pageData.current_page + 1 : 1)
                }}
                hasMore={(pageData?.current_page || 0) < (pageData?.pages || 1)}
                loader={<FontAwesomeIcon icon={faSpinner} spin /> }
                scrollableTarget="workflow-selection-popup"
                style={{display:"flex",flexDirection: "column"}}
                height="calc(85vh - 22.2rem)"
            >
                {workflows.map((workflow,index)=>(<WorkFlow data={workflow} key={workflow.id} isActive={activeWorkFlow === workflow.id} setActiveWorkFlow={setActiveWorkFlow} />))}
            </InfiniteScroll>
        </div>
    )
} 

const WorkFlow: React.FunctionComponent<WorkFlowInterface> = ({data:{id,name,round_count},isActive,setActiveWorkFlow}) =>{
    return <div className={isActive?"workflow active": "workflow"} onClick={(e)=>{
        e.stopPropagation()
        setActiveWorkFlow(id)
    }}>
        <p>{name}</p>
        <span>{round_count?round_count+" ":'No '} Rounds</span>
    </div>
}

const ApprovalRounds: React.FunctionComponent<ApprovalRoundsInterface> = ({rounds}) =>{
    return (
        <Container className="rounds">
            <div className="headertext">Approval cycle</div>
            {rounds.map((round:any,index:number)=>(<RoundCard data1={{...(round)}} key={round.id} index={index} />))}
        </Container>
    )
} 

const RoundCard: React.FunctionComponent<RoundInterface> = ({data1,index}) =>{
    const getColor = useCallback(()=>{
        return colors[Math.floor(Math.random() * colors.length)];
    },[])
    const {name,settings,approvers} = data1;

    const randomColors = useMemo(()=>approvers.reduce((acc:any,val:any)=>([...acc,getColor()]),[]),[approvers.length]);

    return <div className="round">
        <div className="circle">
            <span>{"R"+(index+1)}</span>
            <p className="name ml-10">{name}</p>
        </div>
        <div className="members">
            {approvers.map((approver:any,i:number)=>(
                <div className="member">
                    <Avatar 
                        key={approver._id}
                        size="small"
                        initials={approver.firstName[0]||""} 
                        showInitials={!!(approver && !approver.profilePicture)}
                        backgroundColor={approver.profileColour}
                        src={approver?approver.profilePicture:""}
                    />
                </div>
            ))}
        </div>
        <div className="detail">
            <p>{settings.everyonemustapprove? "Everyone must Approve":"Anyone can Approve"}</p>
            {/* <p>Auto-schedules after approved</p> */}
        </div>
    </div>
}

const Card: React.FunctionComponent<CardInterface> = ({data}) => {
    const { rendered_file,title } = data;
    
    return <div className="card">
        <div className="card-body">
        {data.type === "video"? <video src={data.rendered_file||""}></video>:
            <Img src={(data.type !== 'image' && data.type !== 'image_set' && data.type !== 'variant_set' && data.type !== 'gif')? getPreview(data?.file_type):rendered_file||"" } />}
        </div>
        <div className="card-footer">
            <p className="text">{title}</p>
        </div>
    </div>
}

const Sections: React.FunctionComponent<SectionsInterface> = ({project,projectType,handleClose,onSubmit}) =>{
    const [activeWorkFlow,setActiveWorkFlow] = useState(-1);
    const [note,setNote] = useState("");
    const [loading,setLoader] = useState(false);
    const [workflows,setWorkflows] = useState<any[]>([]);
    const [pageData,setPageData] = useState<any>(null)
    const [workflowData,setWorkflowData] = useState<any>(null)
    const workflowMap = useRef(new Map());
    const [isSubmitDisabled,setIsSubmitDisabled] = useState(false); 
    const dispatch = useDispatch();
    
    const fetchWorkflows = (page:number) => {
        dispatch(fetchWorkFlows({page},(data:any,err:boolean)=>{
            if(!err){
                setPageData({
                    count: data.count,
                    page_size: data.page_size,
                    current_page: data.current_page,
                    pages: data.pages
                })
                data.results.forEach((d:any)=>{
                    if(!workflowMap.current.has(d.id)){
                        workflowMap.current.set(d.id,d);
                    }
                })
                if(page === 1){
                    setActiveWorkFlow(data.results[0].id);
                }
                setWorkflows([...workflows,...data.results]);
            }else{
                if(page === 1){
                    setWorkflows([])
                }
                setPageData(null)
                openToast('error','Unable to fetch workflows')
            }
        }));
    }

    useEffect(()=>{
        fetchWorkflows(1);
        return ()=>{
            setActiveWorkFlow(-1)
        }
    },[]);

    useEffect(()=>{
        if(workflowMap.current.has(activeWorkFlow)){
            let isDisabled = !!!workflowMap.current.get(activeWorkFlow).round_count;
            setIsSubmitDisabled(isDisabled);
        }
        if(activeWorkFlow !== -1){
            setLoader(true)
            dispatch(fetchWorkFlowData(activeWorkFlow,(res:any,err:boolean)=>{
                if(!err){
                    let ids:string[] = res.data.rounds.reduce((acc:any,val:any)=>{
                        return [...acc,...val.approvers]
                    },[]);
                    if(ids.length > 0){
                        dispatch(getUsersData({userIds: ids},(userres:any,usererr:boolean)=>{
                            if(!usererr){
                                let { users } = userres.data;
                                res.data.rounds.map((round:any,index:number)=>{
                                    round.approvers.map((a:any,r:number)=>{
                                        round.approvers[r] = users.find((u:any)=>u._id === a)||"";
                                    });
                                })
                                setLoader(false)
                                setWorkflowData(res.data)
                            }
                        }))
                    }else{
                        setLoader(false)
                        setWorkflowData(res.data)
                    }
                }else{
                    setLoader(false)
                }
            }))
        }
        return ()=>{
            setWorkflowData(null)
        }
    },[activeWorkFlow])

    return <Container className="content">
        <Container className="section grey">
            <div className="headertext">Banner</div>
            <Card data={project} />
            <textarea placeholder="Add a note for approver" value={note} onChange={(e)=>setNote(e.target.value)}></textarea>
        </Container>
        <Container className="container">
            <div className="main flex">
                <ApprovalWorkFlows workflows={workflows} activeWorkFlow={activeWorkFlow} setActiveWorkFlow={setActiveWorkFlow} pageData={pageData} fetchWorkflows={fetchWorkflows} />
                {loading? <Container className="rounds">
                    <div className="loader">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </Container>:
                    <ApprovalRounds rounds={[...(workflowData?.rounds||[])]} />
                }
            </div>
            <Footer activeWorkFlow={activeWorkFlow} isDisabled={workflows.length === 0} isSubmitDisabled={isSubmitDisabled} projectId={project.id} projectType={projectType} note={note} callback={onSubmit} handleClose={handleClose} />
        </Container>
    </Container>
}

const Footer: React.FunctionComponent<FooterInterface> = ({activeWorkFlow,isDisabled,projectId,note,projectType,callback,handleClose,isSubmitDisabled}) =>{
    const [loading,setLoader] = useState(false);
    const dispatch = useDispatch();

    return <Container className="footer">
        <Container className="right">
            <button className="secondary" disabled={loading} onClick={()=>handleClose()}>Cancel</button>
            <button className="primary mr-10" disabled={isDisabled||loading} onClick={()=>{
                if(isSubmitDisabled){
                    openToast('warn','Please select a workflow with atleast one round')
                    return;
                }
                setLoader(true);
                dispatch(sendForApproval({entity_id: projectId,workflow_id: activeWorkFlow,note,entity_type: projectType},(response:any,error:boolean)=>{
                    setLoader(false)
                    callback && callback(response,error)
                    handleClose()
                }))
            }}>
                {loading && <span className="loader"> <FontAwesomeIcon icon={faSpinner} spin /> </span>}
                Submit
            </button>
        </Container>
    </Container>
}

const ApprovalPopUp: React.FunctionComponent<{projectType: string;project: any;handleClose: Function; onSubmit:Function}> = ({projectType,project,handleClose,onSubmit}) => {
    return (<div className="popup-overlay">
        <Container className="aiquare-approval-popup">
            <Title title={"Send to Approval"} />
            <Sections project={project} projectType={projectType} handleClose={handleClose} onSubmit={onSubmit} />
        </Container>
    </div>
    )
}

export default ApprovalPopUp;