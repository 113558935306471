import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import TopBar from "../../shared/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  detectQueryString,
  downloadFile,
  getIsTypeEditable,
  isItImage,
  isItImageFileType,
  permissionDeniedClick,
} from "../../../utilities/common-function";
import { FolderState, AuthState, ApprovalState } from "../../../store/types";
import {
  setHyperProjectData,
  generateCombinations,
  renderImages,
  setBannerView,
  updateHyperProjectDetails,
  setProject,
  updateProject,
  updateFileToProject,
  fetchProject,
  getUsersData,
  updateVariant,
  createVariant,
  fileToProject,
  fetchApprovalComments,
} from "../../../store/actions";
import { openToast } from "../../../Toasts";
import "./index.scss";
import BubbleContainer from "../../shared/BubbleContainer";
import { ReactComponent as iconEdit } from "../../../assets/svg/iconEdit.svg";
import { ReactComponent as iconDownload } from "../../../assets/svg/iconDownload.svg";
import { ReactComponent as iconFilter } from "../../../assets/svg/iconFilter.svg";
import {
  Button,
  Dropdown,
  DropdownButton,
  Input,
  Menu,
  MenuItem,
  Tooltip,
} from "pyxis-ui-kit";
import Icon, { DownOutlined } from "@ant-design/icons";
import { DropDownOption } from "../../shared";
import FilterDrawer from "../../HyperPersonalizeSection/FilterDrawer";
import { enableEdit } from "../../../utilities/contants";
import { IMAGE_BASE_URL, S3_BASE_URL } from "../../../utilities/paths";
import { omit } from "lodash";
import AWS from "aws-sdk";

interface DetailTopBarInterface {
  selectedBanner: number;
  setSelectedBanner: Function;
  renderedCount: number;
  type: string;
  renderData: any;
  hyperProjectData: any;
  setProjectRename: Function;
  projectRename: string;
  isFiltered: boolean;
  isBannerDetailView: boolean;
  sizeTabsArr: any;
  bannerData: any;
  commentMode: boolean;
  setCommentMode: Function;
  setUpdateLoader: Function;
  updateType: string;
  setUpdateType: Function;
  setActiveTab: Function;
  setRetainedComments: Function;
  setPrevDimensions: Function;
  inFocus: boolean;
}

const DetailTopBar: FC<DetailTopBarInterface> = ({
  selectedBanner,
  setSelectedBanner,
  renderedCount,
  type,
  renderData,
  hyperProjectData,
  setProjectRename,
  projectRename,
  isFiltered,
  isBannerDetailView,
  sizeTabsArr,
  bannerData,
  commentMode,
  setCommentMode,
  setUpdateLoader,
  updateType,
  setUpdateType,
  setActiveTab,
  setRetainedComments,
  setPrevDimensions,
  inFocus,
}) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isDownload, setIsDownload] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [rename, setRename] = useState("");
  const [dropDownStep, setDropDownStep] = useState(0);
  const [isFilterDrawer, setIsFilterDrawer] = useState(false);

  const isSet = !!(
    bannerData &&
    (bannerData.type === "image_set" || bannerData.type === "variant_set")
  );
  const { userData } = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const {
    location: { search },
  } = history;
  const isPublicLink = history.location.pathname.startsWith("/cocreate/public");
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get("banner");
  const [rendering, setRendering] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const inputRef = useRef<any>(null);
  const productId = useSelector(({ roles }: any) => roles.selectedProductId);

  const updateFileUpload = useRef<any>(null);
  const variantFileUpload = useRef<any>(null);
  const isEditEnabled: boolean = enableEdit.some((val: string) =>
    userData?.emailId.includes(val)
  );
  const approvalState = useSelector(
    (state: { approval: ApprovalState }) => state.approval
  );
  const banners = useSelector(
    (state: { folders: FolderState }) => state?.folders?.bannerData?.banners
  );
  const commentResolution = useSelector(
    (state: { approval: ApprovalState }) => state.approval.comments_resolution
  );
  const { comments } = approvalState;
  const bannerWithRenderedImages: string[] = [
    "jpg",
    "png",
    "jpeg",
    "psd",
    "svg",
  ];

  useEffect(() => {
    if (bannerData) {
      setProjectRename(bannerData.title);
    }
  }, [bannerData]);

  useEffect(() => {
    if (hyperProjectData?.title) {
      !projectRename && setProjectRename(hyperProjectData.title);
    }
  }, [hyperProjectData]);

  useEffect(() => {
    if (isRename && !isPublicLink) {
      inputRef?.current!?.focus({
        cursor: "end",
      });
    }
  }, [isRename]);

  useEffect(() => {
    const handleEsc = (e: any) => {
      if (e.key === "Escape") {
        if (commentResolution !== 1 && !inFocus) {
          setCommentMode(false);
        }
      }
    };

    document.addEventListener("keydown", handleEsc, false);
    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, [inFocus, commentResolution]);

  // const handleDownloadAIDesigner = (projectId) => {

  //     const params = {
  //         Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
  //         Key: `aidesigner/${productId}/${projectId}/test.png`,

  //     };

  //     AWS.config.update({
  //         accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID || '',
  //         secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY || '',
  //         region: 'ap-south-1',
  //     });

  //     const s3 = new AWS.S3();

  //     function downloadBlob(blob, name = `output.png`) {
  //         const blobUrl = URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = blobUrl;
  //         console.log(blobUrl, "blobUrl")
  //         link.download = name;
  //         document.body.appendChild(link);
  //         link.dispatchEvent(
  //             new MouseEvent('click', {
  //                 bubbles: true,
  //                 cancelable: true,
  //                 view: window,
  //             })
  //         );
  //         document.body.removeChild(link);
  //     }

  //     s3.getObject(params, (err, data: any) => {
  //         if (err) {
  //             console.log(err, err.stack);
  //         } else {
  //             console.log(data.body, "data")

  //             let csvBlob = new Blob([data.Body], {
  //                 type: 'image/png',
  //             });
  //             downloadBlob(csvBlob);
  //         }
  //     });

  // }

  const handleDownload = () => {
    if (bannerData?.psdUrl?.includes("png")) {
      // handleDownloadAIDesigner(bannerData.id)
      const imageUrl = bannerData?.psdUrl;
      // const link = document.createElement("a");
      // link.href = imageUrl;
      // link.target = "_blank";
      // link.download = "downloaded_image.png"; // Specify the filename for the downloaded image
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      fetch(imageUrl)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = "downloaded_image.png";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          console.log("Something went wrong please try again!");
          window.open(imageUrl);
        });
    } else if (!bannerData.rendered_file.includes("null")) {
      const { rendered_file, type, title } = bannerData;
      const b = rendered_file.split("/");
      b[b.length - 1] = encodeURIComponent(b[b.length - 1]);
      const fileUrl = bannerData?.psdUrl?.length
        ? bannerData?.psdUrl
        : ["application"].includes(type)
        ? rendered_file.split("?")[0].replace(IMAGE_BASE_URL, S3_BASE_URL)
        : b.join("/");

      downloadFile(fileUrl, title);
    } else {
      openToast("error", "Unable to export project!");
    }
  };

  const handleRename = () => {
    if (banner) {
      let id: number = parseInt(banner);
      if (rename.length === 0) {
        openToast("warn", "Please enter a valid name");
      } else if (rename.length > 256) {
        openToast("warn", `Project name can have maximum 256 characters`);
      } else if (rename === projectRename) {
        openToast("warn", "Please make some changes before updating");
      } else {
        if (type === "project") {
          dispatch(
            updateProject(
              { projectId: id, data: { title: rename } },
              (res: any, err: boolean) => {
                if (!err) {
                  openToast("success", "Project name updated!");
                  setProjectRename(rename);
                  setIsRename(false);
                } else {
                  openToast("error", "Something went wrong!");
                }
              }
            )
          );
        } else {
          dispatch(
            updateHyperProjectDetails(
              { projectId: id, data: { title: rename } },
              (res: any, err: boolean) => {
                if (!err) {
                  openToast("success", "Project name updated!");
                  setProjectRename(rename);
                  setIsRename(false);
                } else {
                  openToast("error", "Something went wrong!");
                }
              }
            )
          );
        }
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleRename();
    }
  };

  const updateFile = (
    res: any,
    action: string,
    file: any,
    selectedBanner?: number
  ) => {
    let project: any = omit(res.data, "project_json");
    project["can_manage"] = true;
    project["rendered_file"] = IMAGE_BASE_URL + project.rendered_file;
    project["created_by"] = userData;
    project["banners"] = res.data.renderedimages.sort(
      (a: any, b: any) => a.id - b.id
    );
    project["renderedimages"] = res.data.renderedimages.sort(
      (a: any, b: any) => a.id - b.id
    );
    project["render_status"] = "INPROGRESS";
    if (action === "update") {
      if (project.renderedimages.length > 0) {
        project.renderedimages[project.renderedimages.length - 1][
          "render_status"
        ] = "INPROGRESS";
        project.renderedimages[project.renderedimages.length - 1].size_name =
          file.name;
      }
    } else if (action === "update-add") {
      project.renderedimages[selectedBanner]["render_status"] = "INPROGRESS";
      project.banners[selectedBanner]["render_status"] = "INPROGRESS";
      project.renderedimages[project.renderedimages.length - 1].size_name =
        file.name;
    } else if (action === "add") {
      let count = Math.min(project.renderedimages.length, selectedBanner);
      for (let j = 0; j <= count; j++) {
        if (
          project.renderedimages[project.renderedimages.length - j - 1][
            "render_status"
          ] !== "COMPLETED"
        ) {
          project.renderedimages[project.renderedimages.length - j - 1][
            "render_status"
          ] = "INPROGRESS";
          project.banners[project.banners.length - j - 1]["render_status"] =
            "INPROGRESS";
        }
      }
    }
    dispatch(setProject(project));
  };

  const updateRetainedComments = (
    res: any,
    extension: string,
    prevChildId: number
  ) => {
    let commentsTempArr: any[];
    const id: number = parseInt(banner);
    dispatch(
      fetchApprovalComments(
        {
          entity_id: id,
          page: 1,
          entity_type: type,
          order_by: "created_at",
          child_id: prevChildId,
          resolution_status: -1,
        },
        (response: any, error: any) => {
          if (!error) {
            const {
              data: { results },
            } = response;
            results.reverse();
            if (bannerWithRenderedImages.includes(extension)) {
              let newChildId: number =
                res.data?.renderedimages[selectedBanner]?.id;
              commentsTempArr = results.reduce((prev: any, comment: any) => {
                if (
                  comment.child_id === prevChildId.toString() ||
                  prevChildId === -1
                ) {
                  prev.push({ ...comment, child_id: newChildId.toString() });
                }
                return prev;
              }, []);
            } else {
              commentsTempArr = results.map((comment: any) => ({
                ...comment,
                child_id: null,
              }));
            }
            setRetainedComments(commentsTempArr);
          }
        }
      )
    );
  };

  const handleUpdate = (e: any) => {
    // To disable update/add variant button
    // let project = bannerData;
    // project['render_status'] = 'NOT_STARTED';
    // dispatch(setProject(project));
    let file: any[] = e?.target?.files;
    let extension: string = file[0].name.split(".").pop();
    let prevChildId: number =
      bannerData?.renderedimages[selectedBanner]?.id || -1;
    if (isItImageFileType(bannerData?.type, bannerData?.file_type)) {
      setPrevDimensions({
        width:
          bannerData?.banners[selectedBanner]?.width ||
          bannerData?.renderedimages[selectedBanner]?.width,
        height:
          bannerData?.banners[selectedBanner]?.height ||
          bannerData?.renderedimages[selectedBanner]?.height,
      });
    }
    if (
      bannerData?.type === "image_set" ||
      bannerData?.type === "variant_set"
    ) {
      if (isItImageFileType(bannerData?.type, extension)) {
        setUpdateLoader(true);
        setUpdateType("update-add");
        dispatch(
          updateVariant(
            {
              project_id: bannerData?.id,
              file: file[0],
              project_size: bannerData?.renderedimages[selectedBanner]?.id,
            },
            (res: any, err: boolean) => {
              if (!err) {
                setSelectedBanner(selectedBanner);
                updateFile(res, "update-add", file[0], selectedBanner);
                updateRetainedComments(res, extension, prevChildId);
              }
            }
          )
        );
      } else {
        openToast("warn", `${extension} is not supported`);
      }
    } else {
      setUpdateLoader(true);
      setUpdateType("update");
      dispatch(
        updateFileToProject(
          { project_id: bannerData?.id, file: file[0] },
          (res: any, err: boolean) => {
            if (!err) {
              updateFile(res, "update", file[0]);
              updateRetainedComments(res, extension, prevChildId);
            }
          }
        )
      );
    }
  };

  // const handleAdd = (e:any) => {
  //     let files:any[] = e?.target?.files;
  //     for(let index = 0; index < files.length; index++) {
  //         let extension:string = files[index].name.split('.').pop();
  //         let prevChildId:number = bannerData?.renderedimages[selectedBanner]?.id || -1;
  //         if(isItImageFileType(bannerData?.type, bannerData?.file_type)) {
  //             setPrevDimensions({
  //                 width: (bannerData?.banners[selectedBanner]?.width || bannerData?.renderedimages[selectedBanner]?.width),
  //                 height: (bannerData?.banners[selectedBanner]?.height || bannerData?.renderedimages[selectedBanner]?.height)
  //             });
  //         }
  //         if(isItImageFileType(bannerData?.type, extension)) {
  //             setUpdateLoader(true);
  //             setUpdateType('add-' + `${files.length}`);
  //             dispatch(createVariant({ project_id: bannerData?.id, file: files[index] }, (res: any, err: boolean) => {
  //                 if(!err) {
  //                     dispatch(setBannerView(false));
  //                     updateFile(res, "add", null, files.length);
  //                     updateRetainedComments(res, extension, prevChildId);
  //                     setSelectedBanner(-1);
  //                 }
  //             }));
  //         } else {
  //             openToast('warn', `${extension} is not supported`);
  //         }
  //     }
  // }

  return (
    <>
      <TopBar
        className='project-detail-bar'
        title={<></>}
        subtitle={<></>}
        leftBtn={
          <div className='left-btn-container'>
            <div className='title-container'>
              {isRename ? (
                <Input
                  className='rename-project'
                  value={rename}
                  // Used another state so changes reflect in about tab after enter
                  onChange={(e: any) => setRename(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                  ref={inputRef}
                />
              ) : (
                <Tooltip
                  placement='bottom'
                  getPopupContainer={(trigger) =>
                    trigger.parentNode as HTMLElement
                  }
                  title={projectRename}
                  content={
                    <span
                      className='project-title noselect'
                      onClick={() => {
                        if (!isPublicLink) {
                          if (
                            (type === "project" && bannerData.can_manage) ||
                            type === "hpproject"
                          ) {
                            setIsRename(true);
                            setRename(projectRename);
                          } else {
                            permissionDeniedClick();
                          }
                        }
                      }}
                    >
                      {projectRename}
                    </span>
                  }
                ></Tooltip>
              )}
            </div>
            {(type === "project"
              ? bannerData?.render_status === "FAILED"
              : renderData?.render_status === "FAILED") && (
              <span
                className='ml-10'
                onMouseOver={() => setShowBubble(true)}
                onMouseLeave={() => setShowBubble(false)}
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z'
                    fill='#ce3802'
                  />
                </svg>
                {showBubble && (
                  <BubbleContainer>
                    <p>
                      Seems like there was some issue while rendering the
                      project
                    </p>
                    <Button
                      type='primary'
                      className='primary'
                      onClick={() => {
                        setRendering(true);
                        if (type === "project") {
                          dispatch(
                            renderImages(
                              { projectId: banner },
                              (res: any, err: boolean) => {
                                if (err) openToast("error", "Render failed");
                                if (!err) {
                                  dispatch(
                                    setProject({
                                      ...bannerData,
                                      render_status:
                                        res.data.status === "NOT_STARTED"
                                          ? "INPROGRESS"
                                          : res.data.status,
                                    })
                                  );
                                  openToast("success", "Render Successfull");
                                }
                                setRendering(false);
                                setShowBubble(false);
                              }
                            )
                          );
                        } else {
                          dispatch(
                            generateCombinations(
                              { projectId: banner },
                              (res: any, err: boolean) => {
                                if (err) openToast("error", "Render failed");
                                if (!err) {
                                  bannerData?.banners.map((p: any) => {
                                    p.render_status = "INPROGRESS";
                                  });
                                  bannerData.rendered_count = 0;
                                  dispatch(setProject(bannerData));
                                  dispatch(
                                    setHyperProjectData({
                                      ...hyperProjectData,
                                      ...res.data,
                                      render_status: "INPROGRESS",
                                    })
                                  );
                                  openToast("success", "Render Successfull");
                                }
                                setRendering(false);
                                setShowBubble(false);
                              }
                            )
                          );
                        }
                      }}
                      disabled={rendering}
                    >
                      Retry
                    </Button>
                  </BubbleContainer>
                )}
              </span>
            )}
            {bannerData ? (
              <>
                {type === "project" &&
                  !isPublicLink &&
                  getIsTypeEditable(
                    bannerData?.file_type,
                    bannerData?.type
                  ) && (
                    <Tooltip
                      placement='bottom'
                      title={
                        !isEditEnabled
                          ? "Get in touch with our customer success manager to know more about our editing functionality."
                          : ""
                      }
                      content={
                        <span>
                          <Button
                            type='default'
                            className={`left-btn editor-btn ${
                              isEditEnabled ? "" : "btn-disabled"
                            }`}
                            btnsize='md'
                            disabled={!isEditEnabled}
                            data-tip={
                              isEditEnabled
                                ? ""
                                : "Get in touch with our customer success manager to know more about our editing functionality."
                            }
                            onClick={() => {
                              if (bannerData) {
                                if (bannerData.can_manage) {
                                  if (isSet) {
                                    history.push(
                                      `/cocreate/set/${bannerData?.id}`
                                    );
                                  } else {
                                    history.push(
                                      `/cocreate/editor/${bannerData?.id}`
                                    );
                                  }
                                } else {
                                  permissionDeniedClick();
                                }
                              }
                            }}
                          >
                            <span
                              className={
                                bannerData.can_manage
                                  ? "btn-info"
                                  : "btn-info permission-denied-lock"
                              }
                            >
                              <span>
                                <Icon
                                  component={iconEdit}
                                  className='left-btn-icon'
                                />
                                Open project in editor
                              </span>
                            </span>
                          </Button>
                        </span>
                      }
                    ></Tooltip>
                  )}
                {!isPublicLink &&
                bannerData?.render_status !== "NOT_STARTED" &&
                (type === "hpproject" ||
                  (type === "project" &&
                    isItImage(bannerData?.type, bannerData?.file_type))) ? (
                  <DropDownOption
                    project={banner ? parseInt(banner) : 0}
                    componentCaller='projectDetail'
                    projectType={type}
                    isTopFilter='singleDownload'
                    isOpenMoreVisible={isDownload}
                    setIsOpenMoreVisible={setIsDownload}
                    setDropDownStep={setDropDownStep}
                    dropDownStep={2}
                    renderData={renderData}
                  />
                ) : (
                  <Button
                    btnsize='md'
                    type='link'
                    className='left-btn'
                    onClick={handleDownload}
                  >
                    <span>
                      <Icon
                        component={iconDownload}
                        className='left-btn-icon'
                      />
                      Download project
                    </span>
                  </Button>
                )}
              </>
            ) : null}
            {!isPublicLink &&
              isItImageFileType(bannerData?.type, bannerData?.file_type) &&
              (((bannerData?.type === "image_set" ||
                bannerData?.type === "variant_set") &&
                isBannerDetailView) ||
                (bannerData?.type !== "image_set" &&
                  bannerData?.type !== "variant_set")) && (
                <Tooltip
                  placement='bottom'
                  title={
                    commentResolution === 1
                      ? "Select 'Show un-resolved' from the filter to add new comment"
                      : ""
                  }
                  content={
                    <Button
                      type='link'
                      id='annotate-btn'
                      className={`annotate-btn ${
                        commentResolution === 1 ? "read-only" : ""
                      } ${commentMode ? "mode-on" : "mode-off"}`}
                      onClick={() => {
                        if (commentResolution !== 1) {
                          setCommentMode(!commentMode);
                          setActiveTab("2");
                        }
                      }}
                    >
                      <svg
                        width='22'
                        height='22'
                        viewBox='0 0 42 42'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M35.4167 12.5H33.3333V6.25C33.3333 4.5924 32.6749 3.00269 31.5028 1.83058C30.3307 0.658481 28.7409 0 27.0833 0H6.25C4.5924 0 3.00269 0.658481 1.83058 1.83058C0.658481 3.00269 0 4.5924 0 6.25V31.25C0.00205309 31.6613 0.125808 32.0628 0.355669 32.4039C0.58553 32.745 0.91121 33.0104 1.29167 33.1667C1.53892 33.2833 1.81005 33.3404 2.08333 33.3333C2.35751 33.3349 2.62931 33.2824 2.88314 33.1787C3.13697 33.075 3.36783 32.9223 3.5625 32.7292L9.41667 26.8542H12.5V29.8542C12.5 31.5118 13.1585 33.1015 14.3306 34.2736C15.5027 35.4457 17.0924 36.1042 18.75 36.1042L33.9375 34.5625L38.1042 41.0625C38.2988 41.2556 38.5297 41.4084 38.7835 41.512C39.0374 41.6157 39.3092 41.6683 39.5833 41.6667C39.8566 41.6737 40.1278 41.6166 40.375 41.5C40.7555 41.3437 41.0811 41.0783 41.311 40.7372C41.5409 40.3961 41.6646 39.9946 41.6667 39.5833V18.75C41.6667 17.0924 41.0082 15.5027 39.8361 14.3306C38.664 13.1585 37.0743 12.5 35.4167 12.5ZM12.5 18.75V22.6875H8.5625C8.28832 22.6859 8.01652 22.7385 7.76269 22.8421C7.50887 22.9458 7.278 23.0986 7.08333 23.2917L4.16667 26.2292V6.25C4.16667 5.69747 4.38616 5.16756 4.77686 4.77686C5.16756 4.38616 5.69747 4.16667 6.25 4.16667H27.0833C27.6359 4.16667 28.1658 4.38616 28.5565 4.77686C28.9472 5.16756 29.1667 5.69747 29.1667 6.25V12.5H18.75C17.0924 12.5 15.5027 13.1585 14.3306 14.3306C13.1585 15.5027 12.5 17.0924 12.5 18.75ZM37.5 34.5625L35.4167 32.4792C33.1667 36.1042 34.7083 30.3125 33.9375 34.5625C36.1875 30.9375 34.2131 31.8553 33.9375 31.8542H18.75C18.1975 31.8542 17.6676 31.6347 17.2769 31.244C16.8862 30.8533 16.6667 30.3234 16.6667 29.7708V18.75C16.6667 18.1975 16.8862 17.6676 17.2769 17.2769C17.6676 16.8862 18.1975 16.6667 18.75 16.6667H35.4167C35.9692 16.6667 36.4991 16.8862 36.8898 17.2769C37.2805 17.6676 37.5 18.1975 37.5 18.75V34.5625Z'
                          fill='#6B38C4'
                        />
                      </svg>
                    </Button>
                  }
                ></Tooltip>
              )}
          </div>
        }
        rightBtn={
          <>
            {(type === "project"
              ? bannerData?.rendered_count > 1 ||
                bannerData?.banners?.length > 1
              : type === "hpproject" &&
                hyperProjectData?.rendered_count > 1) && (
              <>
                {type === "hpproject" && !isPublicLink && (
                  <div
                    className={`filter-container ${
                      isFiltered ? "filtered-container" : ""
                    }`}
                    onClick={() => {
                      if (
                        type === "hpproject" &&
                        (renderData?.render_status === "COMPLETED" ||
                          bannerData?.rendered_count === bannerData?.count)
                      ) {
                        setIsFilterDrawer(true);
                      } else {
                        openToast(
                          "info",
                          "Please wait for combinations to render!"
                        );
                      }
                      // setIsFilterDrawer(true)
                    }}
                  >
                    <Icon
                      component={iconFilter}
                      className={`icon-filter ${
                        isFiltered ? " filtered-icon" : ""
                      }`}
                    />
                    <span className={`filter ${isFiltered ? " filtered" : ""}`}>
                      Filter{isFiltered ? " applied" : ""}
                    </span>
                  </div>
                )}
                <span
                  className={`switcher-icon ${
                    !isBannerDetailView ? "detail-view" : ""
                  }`}
                  onClick={() => {
                    dispatch(setBannerView(false));
                    setCommentMode(false);
                    setSelectedBanner(-1);
                  }}
                >
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.0003 6.04771V1.84128C15.0003 1.3769 14.6243 1 14.159 1H9.95261C9.48738 1 9.11133 1.3769 9.11133 1.84128V6.04771C9.11133 6.51209 9.48738 6.88899 9.95261 6.88899H14.159C14.6243 6.88899 15.0003 6.51209 15.0003 6.04771Z'
                      fill='#CFD5F6'
                    />
                    <path
                      d='M9.11133 9.95261V14.159C9.11133 14.6234 9.48738 15.0003 9.95261 15.0003H14.159C14.6243 15.0003 15.0003 14.6234 15.0003 14.159V9.95261C15.0003 9.48822 14.6243 9.11133 14.159 9.11133H9.95261C9.48738 9.11133 9.11133 9.48822 9.11133 9.95261Z'
                      fill='#CFD5F6'
                    />
                    <path
                      d='M6.04771 1H1.84128C1.3769 1 1 1.3769 1 1.84128V6.04771C1 6.51209 1.3769 6.88899 1.84128 6.88899H6.04771C6.51209 6.88899 6.88899 6.51209 6.88899 6.04771V1.84128C6.88899 1.3769 6.51209 1 6.04771 1Z'
                      fill='#CFD5F6'
                    />
                    <path
                      d='M1 9.95261V14.159C1 14.6234 1.3769 15.0003 1.84128 15.0003H6.04771C6.51209 15.0003 6.88899 14.6234 6.88899 14.159V9.95261C6.88899 9.48822 6.51209 9.11133 6.04771 9.11133H1.84128C1.3769 9.11133 1 9.48822 1 9.95261Z'
                      fill='#CFD5F6'
                    />
                  </svg>
                </span>
                <span
                  className={`switcher-icon switch-icon ${
                    isBannerDetailView ? "view-mode" : ""
                  }`}
                  onClick={() => {
                    dispatch(setBannerView(true));
                    setSelectedBanner(0);
                  }}
                >
                  <svg
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect width='14' height='14' rx='1' fill='#CFD5F6' />
                  </svg>
                </span>
              </>
            )}
            {/* {(isPublicLink || !bannerData?.can_manage) ? null :
                <DropdownButton
                    // disabled={bannerData?.render_status !== "COMPLETED"}
                    trigger={['click']}
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    placement="bottomRight"
                    icon={(bannerData?.type === "image" && isItImageFileType(bannerData?.type, bannerData?.file_type)) && <DownOutlined className="caret-icon" />}
                    overlay={(bannerData?.type === "image" && isItImageFileType(bannerData?.type, bannerData?.file_type)) ?
                        <Menu>
                            <MenuItem className="add-variant" onClick={()=>{
                                let node:any = variantFileUpload?.current;
                                node?.click();
                            }}>
                                <span className="upload-btn" role="button">
                                    <input 
                                        ref={variantFileUpload}
                                        type="file" 
                                        style={{display: 'none'}} 
                                        onClick={(e:any)=>e.target.value = null}
                                        onChange={handleAdd}
                                        accept=".png, .jpg, .jpeg, .psd"
                                        multiple />
                                    <span className="icon">
                                        <svg width="18" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 3V7" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18 5H22" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14 5H5C3.895 5 3 5.895 3 7V19C3 20.105 3.895 21 5 21H18C19.105 21 20 20.105 20 19V11" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.9674 14.467L8.4714 12.081C8.8204 11.528 9.5974 11.453 10.0454 11.929L11.1204 13.071L12.2474 10.676C12.5944 9.93797 13.6324 9.90398 14.0274 10.618L16.1814 14.516C16.5494 15.183 16.0664 16 15.3054 16H7.8134C7.0254 16 6.5474 15.133 6.9674 14.467V14.467Z" stroke="#152370" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    <span style={{color: '#152370'}}>Add Variant</span>
                                </span>
                            </MenuItem>
                        </Menu> : <></>
                    }>
                        {(((bannerData?.type === "image_set" || bannerData?.type === "variant_set") && isBannerDetailView) || (bannerData?.type !== "image_set" && bannerData?.type !== "variant_set")) ?
                            <div className="update-file" onClick={()=>{
                                let node:any = updateFileUpload?.current;
                                node?.click();
                            }}>
                                <span className="upload-btn" role="button">
                                    <input 
                                        ref={updateFileUpload}
                                        type="file" 
                                        style={{display: 'none'}} 
                                        onClick={(e:any)=>e.target.value = null}
                                        onChange={handleUpdate}
                                        accept={(bannerData?.type === "image_set" || bannerData?.type === "variant_set") ? ".png, .jpg, .jpeg, .psd" : "/*"} 
                                    />
                                        <span className="update-icon icon">
                                            <svg fill="#7335cb" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="18" height="16">
                                                <path d="M 16 4 C 10.886719 4 6.617188 7.160156 4.875 11.625 L 6.71875 12.375 C 8.175781 8.640625 11.710938 6 16 6 C 19.242188 6 22.132813 7.589844 23.9375 10 L 20 10 L 20 12 L 27 12 L 27 5 L 25 5 L 25 8.09375 C 22.808594 5.582031 19.570313 4 16 4 Z M 25.28125 19.625 C 23.824219 23.359375 20.289063 26 16 26 C 12.722656 26 9.84375 24.386719 8.03125 22 L 12 22 L 12 20 L 5 20 L 5 27 L 7 27 L 7 23.90625 C 9.1875 26.386719 12.394531 28 16 28 C 21.113281 28 25.382813 24.839844 27.125 20.375 Z"/>
                                            </svg>
                                        </span>
                                    <span>Update File</span>
                                </span>
                            </div> : ((bannerData?.type === "image_set" || bannerData?.type === "variant_set") && !isBannerDetailView) ? 
                            <div className="add-variant" onClick={()=>{
                                let node:any = variantFileUpload?.current;
                                node?.click();
                            }}>
                                <span className="upload-btn" role="button">
                                    <input 
                                        ref={variantFileUpload}
                                        type="file" 
                                        style={{display: 'none'}} 
                                        onClick={(e:any)=>e.target.value = null}
                                        onChange={handleAdd}
                                        accept=".png, .jpg, .jpeg, .psd"
                                        multiple />
                                    <span className="variant-icon icon">
                                        <svg width="18" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 3V7" stroke="#7335cb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18 5H22" stroke="#7335cb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14 5H5C3.895 5 3 5.895 3 7V19C3 20.105 3.895 21 5 21H18C19.105 21 20 20.105 20 19V11" stroke="#7335cb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.9674 14.467L8.4714 12.081C8.8204 11.528 9.5974 11.453 10.0454 11.929L11.1204 13.071L12.2474 10.676C12.5944 9.93797 13.6324 9.90398 14.0274 10.618L16.1814 14.516C16.5494 15.183 16.0664 16 15.3054 16H7.8134C7.0254 16 6.5474 15.133 6.9674 14.467V14.467Z" stroke="#7335cb" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    <span>Add Variant</span>
                                </span>
                            </div> : <></>
                        }
                </DropdownButton>
            } */}
          </>
        }
        style={isPublicLink ? { width: "100%" } : {}}
      />
      <FilterDrawer
        isDrawerVisible={isFilterDrawer}
        setIsDrawerVisible={setIsFilterDrawer}
        sizeTabsArr={sizeTabsArr}
      />
    </>
  );
};

export default DetailTopBar;
