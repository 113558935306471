import React, { useState, useEffect } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RolesState } from '../../../../store/types';
import { setTopBarActiveTab, createProduct, setItemList, setNavBarProductList, linkUserWithEntity, setSelectedItem, setMembersList,setSelectedProductId } from '../../../../store/actions';
import AddMemberDrawer from '../../AddMemberDrawer';
import CommonDrawerComponent from '../../../shared/CommonDrawer';
import CommonSuccesWarnDrawer from "../../../shared/CommonSuccessWarnDrawer";
import { permissionDeniedClick } from '../../../../utilities/common-function';
import AddIcon from "../../../../assets/svg/iconAdd.svg";
import { Button, Input } from 'pyxis-ui-kit';

const tabs = [{
  name: "Users",
  type: "brand",
  style: {
    left: '0rem',
    width: '7.3rem'
  }
},{
  name: "Workspaces",
  type: "product",
  style: {
    left: '7.0rem',
    width: '10.8rem'
  }
}]

const TopBar:React.FunctionComponent = () => {
  const state = useSelector((state: {roles:RolesState})=>(state.roles));
  const [addMemberPopUpProps,setAddMemberPopUpProps] = useState<any>(null);
  const [newWorkspaceName,setNewWorkspaceName] = useState<any>("");
  const [isCreateWorkspaceDrawerOpen,setCreateWorkspaceDrawer] = useState<boolean>(false);
  const [isWorkspaceCreated,setIsWorkspaceCreated] = useState<any>(null);
  const { activeTopBarTab,itemList,navProductList,selectedItem,showBrandAdmin,showProductAdmin,userPermissions,brandPermissions,membersList,selectedProductId } = state;
  const history = useHistory();
  const dispatch = useDispatch();
  const canAddDeleteProduct:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('add-delete-product') > -1;
  const canManageUsers:boolean = userPermissions.indexOf('manage-product-users') > -1;
  const [loading, setLoader] = useState(false);

  const changeTab = (tabIndex:number) => {
    if(activeTopBarTab !== tabIndex){
      dispatch(setTopBarActiveTab(tabIndex))
      let newurl = window.location.origin + (tabIndex === 1? '#/cocreate/members':'#/cocreate/products');
      window.history.pushState({path: newurl},'',newurl);
    }
  }

  const checkPermission = (type:string) => {
    if(type === "brand") return showBrandAdmin;
    if(type === "product") {
      if(showBrandAdmin) return true;
      return showProductAdmin;
    }
    return true;
  }

  useEffect(()=>{
    let {location: {pathname}} = history;
    if(pathname === '/cocreate/products') {
      dispatch(setTopBarActiveTab(2));
    }else {
      dispatch(setTopBarActiveTab(1));
    }
  },[])
  
  return <div className="roles-topbar">
    <div className="tabs">
      {tabs.map((tab,index)=>(
        (checkPermission(tab.type))&&
        <div key={tab.name} className={activeTopBarTab === (index+1)?"tab active": "tab"}
          onClick={()=>{
            changeTab(index+1)
          }}
        >{tab.name}</div>
      ))}
      {activeTopBarTab > -1 && <hr className="highlight" style={checkPermission("brand")? tabs[activeTopBarTab-1].style:{...tabs[activeTopBarTab-1].style,left: '0'}} />}
    </div>
    
    {activeTopBarTab === 1 ? 
      <Button
        className='inviteBtn' 
        type='primary'
        onClick={() => {
          setAddMemberPopUpProps({
            type: "member",
            initailStep: 1,
            onCancel: () => setAddMemberPopUpProps(null),
            onSubmit: (data:any, callback:Function) => {
              dispatch(setItemList([...itemList, data]))
              if(itemList.length === 0) {
                dispatch(setSelectedItem(0));
              } else {
                dispatch(setSelectedItem(itemList.length));
              }
            }
          })
        }}>
          <img className="icon" src={AddIcon} alt="add" />
          <span>Add user</span>
      </Button> : 
      <Button 
        className={!canAddDeleteProduct ? "workspaceBtn ml-15 permission-denied-lock" : "workspaceBtn ml-15"}
        type='primary' 
        onClick={() => {
          if(!canAddDeleteProduct) {
            permissionDeniedClick();
          } else {
            setCreateWorkspaceDrawer(true);
          }
        }}>
          <img className="icon" src={AddIcon} alt="add" />
          <span>Create workspace</span>
      </Button>
    }
    {addMemberPopUpProps && <AddMemberDrawer {...addMemberPopUpProps}/> }
    {isCreateWorkspaceDrawerOpen && (
      <CommonDrawerComponent 
        title="Create New Workspace"
        subText="Add unique workspaces enabling teams to create and collaborate on different ideas, products, or projects."
        cancelButtonText="Cancel and return"
        submitButtonText="Create"
        cancelButtonFunction={()=>{setNewWorkspaceName("");setCreateWorkspaceDrawer(false)}}
        submitButtonFunction={()=>{
          setLoader(true);
          dispatch(createProduct({name:newWorkspaceName},(res:any,err:boolean)=>{
            setLoader(false);
            if(!err){
              dispatch(setNavBarProductList([...navProductList,res.data.product]))
              if(selectedProductId === "none"||!selectedProductId){
                dispatch(setSelectedProductId(res.data.product._id))
              } 
              dispatch(setItemList([...itemList,res.data.product]))
              if(itemList.length === 0) {
                dispatch(setSelectedItem(0))
              }else{
                dispatch(setSelectedItem(itemList.length))
              }
              setCreateWorkspaceDrawer(false);
              setIsWorkspaceCreated({
                ...isWorkspaceCreated,
                isOpen: true, 
                type: 0, 
                title: `Great Job! Workspace created successfully`,
                subText:'',
                cancelButtonText: "Close",
                cancelButtonFunction: () => {setIsWorkspaceCreated(null)}
              })
              setNewWorkspaceName("");
            }
          }))
        }}
        isFormValid={newWorkspaceName.length > 0}
        loading={loading}
      >
        <React.Fragment>
      <div className="createWorkspaceContainer">
          <div>
            <label>Workspace name</label>
          </div>
          <div className="inputFieldDiv">
            <Input
              type={'text'}
              value={newWorkspaceName || ''}
              placeholder={'Enter workspace name'}
              onChange={(e:any) => {
                setNewWorkspaceName(e.target.value);
              }}
              onPressEnter={()=>{
                setLoader(true);
                dispatch(createProduct({name:newWorkspaceName},(res:any,err:boolean)=>{
                  setLoader(false);
                  if(!err){
                    dispatch(setNavBarProductList([...navProductList,res.data.product]))
                    if(selectedProductId === "none"||!selectedProductId){
                      dispatch(setSelectedProductId(res.data.product._id))
                    } 
                    dispatch(setItemList([...itemList,res.data.product]))
                    if(itemList.length === 0) {
                      dispatch(setSelectedItem(0))
                    }else{
                      dispatch(setSelectedItem(itemList.length))
                    }
                    setCreateWorkspaceDrawer(false);
                    setIsWorkspaceCreated({
                      ...isWorkspaceCreated,
                      isOpen: true, 
                      type: 0, 
                      title: `Great Job! Workspace created successfully`,
                      subText:'',
                      cancelButtonText: "Close",
                      cancelButtonFunction: () => {setIsWorkspaceCreated(null)}
                    })
                    setNewWorkspaceName("");
                  }
                }))
              }}
            />
          </div>
        </div>
      </React.Fragment>
      </CommonDrawerComponent> 
    )}
    {isWorkspaceCreated && <CommonSuccesWarnDrawer {...isWorkspaceCreated} handleClose={()=> setIsWorkspaceCreated(null)}/>}
  </div>
}

export default TopBar;