import {
  ADD_NEW_AGENCY,
  AddNewAgency,
  Agencies,
  AgencyList,
  CREATE_NEW_AGENCY,
  CreateNewAgency,
  DELETE_AGENCY,
  FETCH_AGENCY_LIST,
  DeleteAgency,
  FetchAgencyList,
  GET_AGENCY_LIST,
  GetAgencyList,
  FETCH_AGENCY_FOLDERS,
  FetchAgencyFolders,
  SelectedAgency,
  SetAgencyFolders,
  SET_AGENCY_FOLDERS,
  CREATE_NEW_FOLDER,
  CreateNewFolder,
  AgencyFolder,
  AddNewAgencyFolder,
  ADD_NEW_AGENCY_FOLDER,
  FetchAgencyData,
  FETCH_AGENCY_DATA,
  DELETE_AGENCY_FOLDER,
  DeleteAgencyFolder,
  RenameAgencyFolder,
  RENAME_AGENCY_FOLDER,
  SET_AGENCY_DATA,
  SetAgencyData,
  GET_AGENCY_SHAREABLE_KEY,
  GetAgencyShareableKey,
  SetAgencyShareableKey,
  SET_AGENCY_SHAREABLE_KEY,
  AgencyToBeShared,
} from "../types";

export const fetchAgencyListAction = (payload: {
  workspace: string;
  page?: number;
}): FetchAgencyList => ({
  type: FETCH_AGENCY_LIST,
  payload,
});

export const getAgencyList = (payload: {
  data: AgencyList;
  page: number;
  shouldLoading?: boolean;
}): GetAgencyList => ({
  type: GET_AGENCY_LIST,
  payload,
});

export const createNewAgency = (payload: {
  agency_name: string;
  workspace: string;
}): CreateNewAgency => ({
  type: CREATE_NEW_AGENCY,
  payload,
});

export const addNewAgency = (payload: Agencies): AddNewAgency => ({
  type: ADD_NEW_AGENCY,
  payload,
});

export const fetchAgencyData = (agency_id: string): FetchAgencyData => ({
  type: FETCH_AGENCY_DATA,
  payload: agency_id,
});

export const deleteAgency = (payload: {
  id: string;
  workspace: string;
}): DeleteAgency => ({
  type: DELETE_AGENCY,
  payload,
});

export const fetchAgencyFolders = (payload: {
  agency_id: string;
  page?: number;
}): FetchAgencyFolders => ({
  type: FETCH_AGENCY_FOLDERS,
  payload,
});

export const setAgencyFolders = (
  payload: {data:SelectedAgency, page:number | undefined}
): SetAgencyFolders => ({
  type: SET_AGENCY_FOLDERS,
  payload,
});

export const createNewFolder = (payload: {
  name: string;
  agency_id: string;
}): CreateNewFolder => ({
  type: CREATE_NEW_FOLDER,
  payload,
});

export const addNewAgencyFolder = (
  payload: AgencyFolder
): AddNewAgencyFolder => ({
  type: ADD_NEW_AGENCY_FOLDER,
  payload,
});

export const deleteAgencyFolder = (payload: {
  id: string;
  agencyId: string;
}): DeleteAgencyFolder => ({
  type: DELETE_AGENCY_FOLDER,
  payload,
});

export const renameAgencyFolder = (payload: {
  id: string;
  name: string;
}): RenameAgencyFolder => ({
  type: RENAME_AGENCY_FOLDER,
  payload,
});

export const setAgencyData = (payload: Agencies): SetAgencyData => ({
  type: SET_AGENCY_DATA,
  payload,
});

export const getAgencyShareableKey = (payload: {
  password_flag: boolean;
  agency: string;
}): GetAgencyShareableKey => ({
  type: GET_AGENCY_SHAREABLE_KEY,
  payload,
});

export const setAgencyShareableKey = (
  payload: AgencyToBeShared
): SetAgencyShareableKey => ({
  type: SET_AGENCY_SHAREABLE_KEY,
  payload,
});
