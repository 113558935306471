import { FETCH_TEMPLATES, FetchTemplatesAction, SetTemplates, SET_TEMPLATES, SET_TEMPLATES_CATEGORIES, FETCH_TEMPLATES_CATEGORIES, FetchTemplateCategoriesAction, SetTemplateCategories } from "../types";

export const fetchTemplatesAction = (search: string, page: number = 1, totalPages: number = 1, type: string, category?: string): FetchTemplatesAction => ({
  type: FETCH_TEMPLATES,
  payload: {
    search,
    page,
    totalPages,
    type,
    category
  }
})

export const setTemplatesAction = (templates: any[], page: number, totalPages: number, search: string, type: string): SetTemplates => ({
  type: SET_TEMPLATES,
  payload: {
    templates,
    page,
    totalPages,
    loading: false,
    search,
    type
  }
})

export const fetchTemplateCategoriesAction = (page: number = 1, type: string): FetchTemplateCategoriesAction => ({
  type: FETCH_TEMPLATES_CATEGORIES,
  payload: {
    page,
    type
  }
})

export const setTemplateCategoriesAction = (categories: any[], count: number, current_page: number, page_size: number, pages: number): SetTemplateCategories => ({
  type: SET_TEMPLATES_CATEGORIES,
  payload: {
    categories,
    count,
    current_page,
    page_size,
    pages
  }
})