import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import CommonDrawerComponent from "../../shared/CommonDrawer";
import IconArrow from "../../../assets/svg/iconArrow.svg";
import { DragAndDrop } from "pyxis-ui-kit";
import { useDispatch,useSelector } from 'react-redux';
import Icon from "@ant-design/icons";
import { ReactComponent as uploadIcon } from "../../../assets/svg/upload.svg";
import IconUpload from "../../../assets/svg/iconUploadAccent.svg";
import { openToast } from "../../../Toasts";
import { setUserData, updateProfile } from "../../../store/actions";
import { AuthState } from "../../../store/types";

const TEN_MB = Math.pow(10, 7);

interface UploadImagePopUpProps {
  onCancel?: Function;
  onSubmit?: Function;
  setShowUploadImagePopUp:Function
}

const UploadImagePopUp: React.FC<UploadImagePopUpProps> = ({
  onCancel,
  onSubmit,
  setShowUploadImagePopUp
}) => {
  const [loading, setImgLoader] = useState(false);
  const [image, setImage] = useState<any>(null);
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState<any>(null);
  const [files, setFile] = useState([]);
  const { userData } = useSelector((state:{authentication:AuthState})=>({...state.authentication}));
  const upload = useRef(null);

  const onChangeImage = (e: any) => {
    let file = e.fileList.reduce((acc: any[], val: any) => {
      return [...acc, val.originFileObj];
    }, []);
    setFile(e.fileList);
    if(e?.fileList?.length > 1) {
      openToast('error', `You can't upload more than 1 image`);
    } else {
      if(e.fileList[0]) {
        if(e.fileList[0].size <= TEN_MB) {
          updateImage(file[0]);
        } else {
          openToast('error', `You can't upload image more than 10MB in size`);
        }
      }
    }
  };

  const updateImage = (file: File) => {
    setImage(file);
    const reader: any = new FileReader();
    reader.addEventListener("load", () => {
      setImageData(reader.result);
    });
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    return (() => {
      setFile([]);
      setImage(null);
    });
  }, []);

  return (
    <CommonDrawerComponent
      title="Choose photo for profile image"
      subText=""
      cancelButtonText="Cancel"
      submitButtonText="Continue"
      loading={loading}
      cancelButtonIcon={<></>}
      submitButtonIcon={<img src={IconArrow} alt="arrow" />}
      cancelButtonFunction={() => onCancel && onCancel()}
      submitButtonFunction={() => {
          setImgLoader(true)
          dispatch(updateProfile({ profilePicture: image }, (res:any, err:boolean) => {
            if(!err) {
              openToast('success', 'Profile Picture updated successfully');
              dispatch(setUserData({...userData,...res.data}))
              setShowUploadImagePopUp(false);
            }
            setImgLoader(false);
        }))
      }}
      isFormValid={files.length === 1 && files[0].size <= TEN_MB}
    >
      <div className="uploadContainer">
        <DragAndDrop
          className={files.length === 0 ? `DNDContainer` : `small-box`}
          listType="picture-card"
          multiple={false}
          accept="image/jpg,image/jpeg,image/png"
          onChange={onChangeImage}
          showUploadList={{
            showPreviewIcon: false,
          }}
          beforeUpload={() => false}
          maxCount={1}
        >
          <p className="ant-upload-drag-icon">
            <Icon component={uploadIcon} />
          </p>
          <p>Drag photo into this area</p>
          <p>or</p>
          <div className="upload-btn">
            <span><img src={IconUpload} alt="upload" />Select file</span>
          </div>
          <p className="max-size">
            Max. size 10MB. JPG, PNG are supported
          </p>
        </DragAndDrop>
      </div>
    </CommonDrawerComponent>
  );
};

export default UploadImagePopUp;
