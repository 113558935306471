import React, { useEffect } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { ApprovalState } from '../../../store/types';

const TopBar:React.FunctionComponent = () => {
  const state = useSelector((state:{approval:ApprovalState})=>(state.approval));
  const { pendingApprovalCount } = state;

  return <div className="approval-topbar" style={{justifyContent: 'center'}}>
    {/* <div className="select">
      <input type="checkbox" />
      <span>Select All</span>
    </div> */}
    <div className="title">
      <h3>Approvals History</h3>
      <span>{pendingApprovalCount === 0? "No Pending Approvals":pendingApprovalCount+" Pending Approvals"}</span>
    </div>
    {/* <div className="actions">
      <button className="secondary">
        Reject
      </button>
      <button className="primary">
        Approve All
      </button>
    </div> */}
  </div>
}

export default TopBar;