import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { WorkflowState, RolesState } from '../../../store/types';
import {
  fetchWorkFlowData,
  updateWorkFlowData,
  createRound,
  setWorkFlowData,
  getUsersData,
  addRound
} from '../../../store/actions';
import Round from '../RoundCard';
import { permissionDeniedClick } from '../../../utilities/common-function';
import { openToast } from '../../../Toasts';
import { Button, Input } from 'pyxis-ui-kit';

interface ContainerInterface {
  className: string;
}
interface HeaderInterface {
  title: string;
  workFlowId: number | undefined;
  setEditMode: Function;
  isEditMode: boolean;
  isDisabled: boolean;
  handleWorkflowUpdate: Function
}
interface RoundsListInterface {
  rounds: any[];
  workFlowId: number | undefined;
  isEditMode: boolean;
}
interface BackBtnInterface {}
interface AddRoundInterface {
  handleAddRound: Function;
}

const Container: React.FunctionComponent<ContainerInterface> = (
  { children, className },
  props
) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

const Header: React.FunctionComponent<HeaderInterface> = ({
  workFlowId,
  title,
  isEditMode,
  setEditMode,
  isDisabled,
  handleWorkflowUpdate
}) => {
  const [name, setName] = useState(title);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setName(title);
  }, [title]);

  return (
    <Container className='header'>
      <div className='title'>
        {isEditMode ? (
          <div className='flex'>
            <Input value={name} onChange={(e) => setName(e.target.value)} 
              onBlur={()=>{
                if(name.length === 0) setName(title);
                if(workFlowId && name.length > 0){
                  handleWorkflowUpdate(workFlowId,name,setLoading)
                }
              }}
              onKeyPress={(e:any)=>{
                if(e.key.toLowerCase() === 'enter'){
                  if(name.length === 0) setName(title);
                  if(workFlowId && name.length > 0){
                    handleWorkflowUpdate(workFlowId,name,setLoading);
                    setEditMode((val: boolean) => !val);
                  }
                }
              }} />
            {loading && (
              <div
                className={'check loading'}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                />
              </div>
            )}
          </div>
        ) : (
          title
        )}
      </div>
      <div className='actions'>
        <div
          className={isDisabled? "action permission-denied-lock" : 'action'}
          onClick={() => {
            if(isDisabled){
              permissionDeniedClick()
            }else{
              setEditMode((val: boolean) => !val)
            }
          }}
        >
          {isEditMode ? 
          <svg width="18" height="18" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00033 3C7.46989 3 6.96118 3.21071 6.58611 3.58579C6.21104 3.96086 6.00033 4.46957 6.00033 5C6.00033 5.53043 6.21104 6.03914 6.58611 6.41421C6.96118 6.78929 7.46989 7 8.00033 7C8.53076 7 9.03947 6.78929 9.41454 6.41421C9.78961 6.03914 10.0003 5.53043 10.0003 5C10.0003 4.46957 9.78961 3.96086 9.41454 3.58579C9.03947 3.21071 8.53076 3 8.00033 3ZM8.00033 8.33333C7.11627 8.33333 6.26842 7.98214 5.6433 7.35702C5.01818 6.7319 4.66699 5.88406 4.66699 5C4.66699 4.11595 5.01818 3.2681 5.6433 2.64298C6.26842 2.01786 7.11627 1.66667 8.00033 1.66667C8.88438 1.66667 9.73223 2.01786 10.3573 2.64298C10.9825 3.2681 11.3337 4.11595 11.3337 5C11.3337 5.88406 10.9825 6.7319 10.3573 7.35702C9.73223 7.98214 8.88438 8.33333 8.00033 8.33333ZM8.00033 0C4.66699 0 1.82033 2.07333 0.666992 5C1.82033 7.92667 4.66699 10 8.00033 10C11.3337 10 14.1803 7.92667 15.3337 5C14.1803 2.07333 11.3337 0 8.00033 0Z" fill="#515151"/>
          </svg> : 
          <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.71 5.04C18.1 4.65 18.1 4 17.71 3.63L15.37 1.29C15 0.899998 14.35 0.899998 13.96 1.29L12.12 3.12L15.87 6.87L17.71 5.04ZM0 15.25V19H3.75L14.81 7.93L11.06 4.18L0 15.25Z" fill="#515151"/>
          </svg>}
          <span>{isEditMode ? 'View' : 'Edit'}</span>
        </div>
        {/* <div className="action">
                <FontAwesomeIcon icon={faEllipsisV} />
            </div> */}
      </div>
    </Container>
  );
};

const RoundsList: React.FunctionComponent<RoundsListInterface> = ({
  rounds,
  workFlowId,
  isEditMode,
}) => {
  return (
    <Container className='rounds-list'>
      {rounds.map((round: any, index: number) => (
        <Round
          data={round}
          workFlowId={workFlowId}
          isEditable={isEditMode}
          isViewCard={true}
          key={round.id}
          index={index}
        />
      ))}
    </Container>
  );
};

const BackBtn: React.FunctionComponent<BackBtnInterface> = () => {
  const history = useHistory();
  return (
    <Button className='back-btn' onClick={() => history.go(-1)}>
      <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 6.99999V8.99999H4.00003L9.50003 14.5L8.08003 15.92L0.160034 7.99999L8.08003 0.0799866L9.50003 1.49999L4.00003 6.99999H16Z" fill="#7335cb"/>
      </svg>
      <span>Back</span>
    </Button>
  );
};

const AddRound: React.FunctionComponent<AddRoundInterface> = ({
  handleAddRound,
}) => {
  return (
    <Button type="link" className='add-round' onClick={() => handleAddRound()}>
      <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#7335cb"/>
      </svg>
      Add Another Round
    </Button>
  );
};

const WorkFlowView: React.FunctionComponent = () => {
  const state = useSelector(
    (state: { workflows: WorkflowState }) => state.workflows
  );
  const { workflowData } = state;
  const rounds = workflowData?.rounds || [];
  const [isEditMode, setEditMode] = useState(true);
  const [loading,setLoader] = useState(false);
  const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    let params: any = match.params;
    let workflowid = parseInt(params.workflowid);
    setLoader(true)
    dispatch(fetchWorkFlowData(workflowid,(res:any,err:boolean)=>{
      if(!err){
        let ids:string[] = res.data.rounds.reduce((acc:any,val:any)=>{
          return [...acc,...val.approvers]
        },[]);
        if(ids.length > 0){
          dispatch(getUsersData({userIds: ids},(userres:any,usererr:boolean)=>{
              if(!usererr){
                  let { users } = userres.data;
                  res.data.rounds.map((round:any,index:number)=>{
                      round.approvers.map((a:any,r:number)=>{
                          round.approvers[r] = users.find((u:any)=>u._id === a)||"";
                      });
                  })
                  setLoader(false)
                  dispatch(setWorkFlowData(res.data))
              }
          }))
        }else{
          setLoader(false)
          dispatch(setWorkFlowData(res.data))
        }
      }else{
        setLoader(false)
      }
    }))
    return () => {
      dispatch(setWorkFlowData(null));
    };
  }, []);

  const handleAddRound = useCallback(() => {
    if(workflowData && workflowData?.rounds[workflowData?.rounds.length -1]?.id !== -1){
      workflowData.rounds = [...workflowData.rounds,{
          id:-1,
          name: `Round ${rounds.length+1}`,
          settings: {
              everyonemustapprove: false
          },
          order: -1,
          approvers: []
      }];
      dispatch(setWorkFlowData(workflowData))
    }
    else{
        openToast('warn','You Already have a empty round present!')
    }
  }, [workflowData, rounds.length]);

  const handleWorkflowUpdate = useCallback((workflowId:number,name:string,setLoader:Function) => {
    setLoader(true)
    dispatch(updateWorkFlowData(workflowId, name,(res:any,err:boolean)=>{
      setLoader(false)
      if(!err){
        dispatch(setWorkFlowData({...res.data,rounds}))
      }
    }));
  },[rounds.length]);

  return (
    <React.Fragment>
      <Container className='workflow-view'>
        {loading? <div className="loader">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>:<>
          <BackBtn />
          <Container className='container'>
            <Header
              title={workflowData?.name || ''}
              workFlowId={workflowData?.id}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              handleWorkflowUpdate={handleWorkflowUpdate}
              isDisabled={userPermission.indexOf('manage-approval-workflow') === -1}
            />
            <RoundsList
              rounds={rounds}
              workFlowId={workflowData?.id}
              isEditMode={isEditMode}
            />
            {(isEditMode || rounds.length === 0) && (
              <AddRound handleAddRound={handleAddRound} />
            )}
          </Container>
        </>}
      </Container>
    </React.Fragment>
  );
};

export default WorkFlowView;
