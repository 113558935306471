import React, { useState, useEffect, useCallback, useMemo,useRef } from 'react';

interface InitialActions {
  onError?: Function|null,
  onConnect?: Function|null,
  onDisconnect?: Function|null,
  onRecieve?: Function|null
}
const initialActions:InitialActions = {
  onError: null,
  onConnect: null,
  onDisconnect: null,
  onRecieve: null
}

const useWebSocket = (url:string,canCreateConnection:boolean = true): [WebSocket,any] => {
  const count = useRef(0)
  const client = useMemo(()=>{
    if(canCreateConnection){
      return new WebSocket(url)
    }else{
      return null;
    }
  },[url,count.current,canCreateConnection]);
  const [clientActions,setClientActions] = useState<InitialActions>(initialActions);
  const { onError,onConnect,onDisconnect,onRecieve } = clientActions;

  useEffect(()=>{
    return () => {
      if(client) {
        client.close();
      }
    }
  },[client])

  useEffect(()=>{
    if(!canCreateConnection && client){
      client.close()
    }
  },[canCreateConnection])

  if(client){
    client.onerror = (err)=>{
      onError && onError(err);
    }
  
    client.onopen = (e)=>{
      onConnect && onConnect(e);
    }
    
    client.onclose = (e) => {
      setTimeout(()=>{
        count.current += 1; 
      }, 2000)
      // onDisconnect && onDisconnect(e);
    }
  
    client.onmessage = (e) => {
      onRecieve && onRecieve(JSON.parse(e.data));
    }
  }
  
  return [client as WebSocket,setClientActions];
}

export default useWebSocket;