import React from "react"
import PixisLogoLoadingCard from "../../../assets/svg/PixisLogoLoadingCard.svg";
const LoadingCard = () => {
    const [loadingPercentage, setLoadingPercentage] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setLoadingPercentage((prev) => {
                if (prev >= 100) {
                    // clearInterval(interval);
                    // setLoadingPercentage(0);
                    return 0;
                }
                return prev + 1;
            });
        }, 300);
        return () => clearInterval(interval);
    }, []);


    return <div className="loading-card" >
        <div className="pixis-logo-container"><img src={PixisLogoLoadingCard} /></div>
        <div className="text-container">
            <div className="title">We’re designing your Ad</div>
            <div className="desc">We Appreciate your patience </div>
        </div>
        <div className="loader">
            <div className="progress" style={{ width: `${loadingPercentage}%` }}></div>
        </div>
    </div>
}

export default LoadingCard