import { FETCH_IMAGES, FetchImagesAction, SetImagesAction, SET_IMAGES, SetPhotosSearchAction, SET_PHOTOS_SEARCH, SET_DEFAULT_IMAGES, SetDefaultImagesAction, FetchDefaultImagesAction, FETCH_DEFAULT_IMAGES } from "../types";

export const fetchImagesAction = (search: string, page: number = 1, totalPages: number = 1): FetchImagesAction => ({
  type: FETCH_IMAGES,
  payload: {
    search,
    page,
    totalPages
  }
})

export const setImagesAction = (images: any[], page: number, totalPages: number, totalRecords: number, search: string): SetImagesAction => ({
  type: SET_IMAGES,
  payload: {
    images,
    page,
    totalPages,
    loading: false,
    search,
    totalRecords
  }
})

export const setPhotosSearchAction = (search: string): SetPhotosSearchAction => ({
  type: SET_PHOTOS_SEARCH,
  payload: {
    search
  }
})

export const fetchDefaultImagesAction = (search: string, type: string, is_default: boolean, page: number, page_size: number): FetchDefaultImagesAction => ({
  type: FETCH_DEFAULT_IMAGES,
  payload: {
    search,
    type,
    is_default,
    page,
    page_size,
  }
})

export const setDefaultImagesAction = (categories: any[], count: number, current_page: number, page_size: number, pages: number, params: any): SetDefaultImagesAction => ({
  type: SET_DEFAULT_IMAGES,
  payload: {
    categories,
    count,
    current_page,
    loading: false,
    page_size,
    pages,
    params
  }
})