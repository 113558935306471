import { cloneDeep } from "lodash";
import { isObjectScalable } from "../helpers/isScalable";
import { sortForVerticalResize, sortForHorizontalResize } from "../helpers/objectListSort";
import { getObjectGridPositionY, getObjectGridPositionX } from "../helpers/positioning";
import { CANVAS_GRID_POSITION_Y, CANVAS_GRID_POSITION_X } from "../constants";
import { resizeObjectListSimple } from "./simpleResize";
import { resizeObj } from "../helpers/resizeObj";
import { getObjectSize, getObjectWidth, getObjectHeight } from "../helpers/getObjectSize";

export const resizeObjectListFromHorizontalExtreme = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  const result = [];
  const maxSide = prevWidth;

  // LENGTH === 1
  if (children?.length === 1) {
    const obj = children[0];
    let newObj = cloneDeep(obj);
    let heightRatio = prevWidth / prevHeight;
    const isScalable = isObjectScalable(obj);

    if (isScalable) {
      newObj = resizeObj(newObj, heightRatio, 1);
      const [newWidth, newHeight] = getObjectSize(newObj);
      if (newHeight * heightRatio < maxSide) {
        newObj = resizeObj(newObj, 1, heightRatio);
      }
    } else {
      const isEnoughSpace = getObjectWidth(newObj) * heightRatio < maxSide;
      const newRatio = isEnoughSpace ? heightRatio : Math.sqrt(heightRatio);
      newObj = resizeObj(newObj, newRatio, newRatio);
    }

    const [newWidth, newHeight] = getObjectSize(newObj);
    const newLeft = (maxSide - newWidth) / 2;
    const newTop = (maxSide - newHeight) / 2;
    newObj.left = newLeft > 0 ? newLeft : 0;
    newObj.top = newTop > 0 ? newTop : 0;
    result.push(newObj);

    // LENGTH >= 2 && LENGTH <=3
  } else if (children?.length >= 2 && children?.length <= 3) {
    const sortedChildren = cloneDeep(children).sort(sortForHorizontalResize);
    const sumHeight = prevHeight * sortedChildren.length;
    const heightRatio = maxSide / sumHeight;
    let newSumHeight = 0;

    for (const obj of sortedChildren) {
      let newObj = cloneDeep(obj);
      if (getObjectWidth(newObj) * heightRatio <= prevWidth) {
        newObj = resizeObj(newObj, heightRatio, heightRatio);
      } else {
        const ratio = prevWidth / newObj.width;
        newObj = resizeObj(newObj, ratio, ratio);
      }

      newSumHeight += getObjectHeight(newObj);
      result.push(newObj);
    }

    let spaceBetween = (maxSide - newSumHeight) / (sortedChildren.length + 1);
    let startPoint = spaceBetween;
    for (const obj of result) {
      const [width, height] = getObjectSize(obj);
      obj.top = startPoint;
      startPoint += height + spaceBetween;
      obj.left = (maxSide - width) / 2;
    }

    // LENGTH > 3
  } else {
    const row1: Array<any> = [];
    const row2: Array<any> = [];
    const row3: Array<any> = [];

    for (const obj of children) {
      let newObj = cloneDeep(obj);
      const width = getObjectWidth(newObj);
      const objCenterX = obj.left + width / 2;
      const position = getObjectGridPositionX(objCenterX, width, prevWidth);

      if (position === CANVAS_GRID_POSITION_X.LEFT) {
        row1.push(newObj);
      } else if (position === CANVAS_GRID_POSITION_X.CENTER) {
        row2.push(newObj);
      } else {
        row3.push(newObj);
      }
    }

    row1.sort(sortForHorizontalResize);
    row2.sort(sortForHorizontalResize);
    row3.sort(sortForHorizontalResize);

    if (row1.length) {
      let initialWidth = maxSide / 3;
      let lastChild = row1[row1.length - 1];
      for (const object of row1) {
        if (object?.left + getObjectWidth(object) > lastChild?.left + getObjectWidth(lastChild)) {
          lastChild = object;
        }
      }
      const rightMargin = lastChild.left + getObjectWidth(lastChild) - maxSide / 3;
      if (rightMargin > 0) {
        initialWidth += rightMargin;
      }
      const resizedObj = resizeObjectListSimple(row1, initialWidth, prevHeight, maxSide, maxSide / 3);
      const newObjects = centerObjectsOnCanvas(resizedObj, maxSide);
      result.push(...newObjects);
    }

    if (row2.length) {
      let initialWidth = maxSide / 3;
      const lastChild = row2?.[row2?.length - 1];
      const rightMargin = lastChild.left + getObjectWidth(lastChild) - (maxSide / 3) * 2;

      if (rightMargin > 0) {
        initialWidth += rightMargin;
      }

      const firstChild = row2?.[0];
      const leftMargin = maxSide / 3 - firstChild?.left;

      if (leftMargin > 0) {
        initialWidth += leftMargin;
        firstChild.left += leftMargin;
      }

      for (const obj of row2) {
        obj.left -= maxSide / 3;
      }

      const resizedObj = resizeObjectListSimple(row2, initialWidth, prevHeight, maxSide, maxSide / 3);

      for (const obj of resizedObj) {
        obj.top += maxSide / 3;
      }

      const newObjects = centerObjectsOnCanvas(resizedObj, maxSide);
      result.push(...newObjects);
    }

    if (row3.length) {
      let initialWidth = maxSide / 3;
      let firstChild = row3?.[0];
      for (const object of row3) {
        if (object?.left < firstChild?.left) {
          firstChild = object;
        }
      }
      const leftMargin3 = (maxSide / 3) * 2 - firstChild?.left;
      if (leftMargin3 > 0) {
        initialWidth += leftMargin3;
        firstChild.left += leftMargin3;
      }

      for (const obj of row3) {
        obj.left -= (maxSide / 3) * 2;
      }

      const resizedObj = resizeObjectListSimple(row3, initialWidth, prevHeight, maxSide, maxSide / 3);
      for (const obj of resizedObj) {
        obj.top += (maxSide / 3) * 2;
      }
      const newObjects = centerObjectsOnCanvas(resizedObj, maxSide);
      result.push(...newObjects);
    }
  }

  return result;
};

const centerObjectsOnCanvas = (resizedObjectList: Array<any>, maxSide: number) => {
      const copyArray = [...resizedObjectList];
      //temp vars
      let firstObject = copyArray?.[0];
      let lastObject = copyArray[0];
      //get left and right objects
      for (const object of copyArray) {
        if (object?.left + getObjectWidth(object) > lastObject?.left + getObjectWidth(lastObject)) {
          lastObject = object;
        }
        if (object?.left < firstObject?.left) {
          firstObject = object;
        }
      }
      //get left 
      const left = firstObject.left;
      //get right margin
      const right = lastObject.left + getObjectWidth(lastObject);

      //get left and right paddings
      const leftPadding = left >= 0 ? left : 0;
      const rightPadding = right >= 0 ? right : 0;

      //get the correction
      const newHyperPadding = (maxSide - leftPadding - rightPadding) / 2;
      //update left
      for (const object of copyArray) {
        object.left += newHyperPadding;
      }
      return copyArray;
}

export const resizeObjectListFromVerticalExtreme = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  const maxSide = prevHeight;
  const result = [];

  if (children?.length === 1) {
    const obj = children[0];
    let newObj = cloneDeep(obj);
    let heightRatio = prevHeight / prevWidth;
    let widthRatio = prevHeight / prevWidth;
    const minRatio = Math.min(widthRatio, heightRatio);
    const isScalable = isObjectScalable(newObj);

    if (isScalable) {
      newObj = resizeObj(newObj, 1, heightRatio);
      if (getObjectWidth(newObj) * widthRatio < maxSide) {
        newObj = resizeObj(newObj, widthRatio, 1);
      }
    } else {
      const isEnoughSpace = getObjectWidth(newObj) * minRatio < maxSide;
      const ratio = isEnoughSpace ? minRatio : Math.sqrt(minRatio);
      newObj = resizeObj(newObj, ratio, ratio);
    }

    const newObjWidth = getObjectWidth(newObj);

    const newLeft = (maxSide - newObjWidth) / 2;
    const newTop = (maxSide - newObjWidth) / 2;
    newObj.left = newLeft > 0 ? newLeft : 0;
    newObj.top = newTop > 0 ? newTop : 0;
    result.push(newObj);
  } else if (children?.length >= 2 && children?.length <= 3) {
    const sortedChildren = cloneDeep(children).sort(sortForVerticalResize);
    const sumWidth = prevWidth * sortedChildren.length;
    const widthRatio = maxSide / sumWidth;
    let newSumWidth = 0;

    for (const obj of sortedChildren) {
      let newObj = cloneDeep(obj);
      const newObjHeight = getObjectHeight(newObj);
      if (newObjHeight * widthRatio <= prevHeight) {
        newObj = resizeObj(newObj, widthRatio, widthRatio);
      } else {
        const ratio = prevHeight / obj.height;
        newObj = resizeObj(newObj, ratio, ratio);
      }

      newSumWidth += getObjectWidth(newObj);
      result.push(newObj);
    }

    let spaceBetween = (maxSide - newSumWidth) / (sortedChildren.length + 1);
    let startPoint = spaceBetween;
    for (const obj of result) {
      obj.left = startPoint;
      startPoint += getObjectWidth(obj) + spaceBetween;
      obj.top = (maxSide - getObjectHeight(obj)) / 2;
    }
  } else {
    const column1: Array<any> = [];
    const column2: Array<any> = [];
    const column3: Array<any> = [];

    const columns = [column1, column2, column3];

    for (const obj of children) {
      let newObj = cloneDeep(obj);
      const height = getObjectHeight(newObj)
      const objCenterY = obj.top + height / 2;
      const position = getObjectGridPositionY(objCenterY,height, prevHeight);
      const widthRatio = maxSide / (prevWidth * 3);
      //divide on columns
      if (position === CANVAS_GRID_POSITION_Y.TOP) {
        column1.push(newObj);
      } else if (position === CANVAS_GRID_POSITION_Y.CENTER) {
        column2.push(newObj);
      } else {
        column3.push(newObj);
      }
    }

    for (let i = 0; i < columns.length; ++i) {
      const column = columns[i];
      column.sort(sortForVerticalResize);
      for (const obj of column) {
        [obj.left, obj.top] = [obj.top, obj.left];
      }
    }

    //margins
    if (column1.length) {
      let row1InitialWidth = maxSide / 3;
      const row1LastChild = column1[column1.length - 1];
      const rightMargin = row1LastChild.left + getObjectWidth(row1LastChild) - maxSide / 3;
      if (rightMargin > 0) {
        row1InitialWidth += rightMargin;
      }
      const row1ResizedObj = resizeObjectListSimple(column1, row1InitialWidth, prevWidth, maxSide, maxSide / 3);
      result.push(...row1ResizedObj);
    }

    if (column2.length) {
      let row2InitialWidth = maxSide / 3;
      const lastChild = column2?.[column2?.length - 1];
      const rightMargin2 = lastChild.left + getObjectWidth(lastChild) - (maxSide / 3) * 2;

      if (rightMargin2 > 0) {
        row2InitialWidth += rightMargin2;
      }

      const firstChild = column2?.[0];
      const leftMargin = maxSide / 3 - firstChild?.left;

      if (leftMargin > 0) {
        row2InitialWidth += leftMargin;
        firstChild.left += leftMargin;
      }

      for (const obj of column2) {
        obj.left -= maxSide / 3;
      }

      const row2ResizedObj = resizeObjectListSimple(column2, row2InitialWidth, prevWidth, maxSide, maxSide / 3);
      for (const obj of row2ResizedObj) {
        obj.top += maxSide / 3;
      }
      result.push(...row2ResizedObj);
    }

    if (column3.length) {
      let row3InitialWidth = maxSide / 3;

      const firstChild3 = column3?.[0];
      const leftMargin3 = (maxSide / 3) * 2 - firstChild3?.left;

      if (leftMargin3 > 0) {
        row3InitialWidth += leftMargin3;
        firstChild3.left += leftMargin3;
      }

      for (const obj of column3) {
        obj.left -= (maxSide / 3) * 2;
      }

      const row3ResizedObj = resizeObjectListSimple(column3, row3InitialWidth, prevWidth, maxSide, maxSide / 3);
      for (const obj of row3ResizedObj) {
        obj.top += (maxSide / 3) * 2;
      }
      result.push(...row3ResizedObj);
    }
  }

  return result;
};

export const resizeObjectListFromExtreme = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  const ratio = prevWidth / prevHeight;
  const isFromHorizontalExtremeResize = ratio >= 3;
  const maxSide = Math.max(prevWidth, prevHeight);

  if (!Array.isArray(children) || children?.length === 0) {
    return [];
  }

  const resizeFunc = isFromHorizontalExtremeResize ? resizeObjectListFromHorizontalExtreme : resizeObjectListFromVerticalExtreme;

  const result = resizeFunc(children, prevWidth, prevHeight, canvasWidth, canvasHeight);

  return resizeObjectListSimple(result, maxSide, maxSide, canvasWidth, canvasHeight);
};
