import { getObjectSize } from "../helpers/getObjectSize";
import { getVisibleSide } from "../helpers/getVisibleSide";
import { CLASSIFICATION_TYPES } from "../classification/constants";
import { cropImage } from "../helpers/cropImage";
import { resizeObj } from "../helpers/resizeObj";
import { getBackgroundPartLeftAndTop } from "../helpers/getBackgroundPartLeftAndTop";
import { cloneDeep } from "lodash";

export const resizeBackgroundPartsSimple = (parts: Array<any>, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  const ratioX = canvasWidth / prevWidth;
  const ratioY = canvasHeight / prevHeight;
  const isHorizontallyDivided = parts.find(
    p => [CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT, CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT].indexOf(p.classificationType) !== -1
  );
  const result = [];
  for (const part of parts) {
    const { visibleWidth, visibleHeight } = part;

    const newWidth = visibleWidth * ratioX;
    const newHeight = visibleHeight * ratioY;
    const naturalWidth = !part.naturalWidth ? (part.width as number) : part.naturalWidth;

    const naturalHeight = !part.naturalHeight ? (part.height as number) : part.naturalHeight;
    [part.scaleX, part.scaleY] = [part.initialScaleX, part.initialScaleY];
    const [oldWidth, oldHeight] = [part.width * part.scaleX, part.height * part.scaleY]
    const leftMargin = part.leftMargin <= 0 ? part.leftMargin : 0;
    const rightMargin = part.rightMargin <= 0 ? part.rightMargin : 0;
    const topMargin = part.topMargin <= 0 ? part.topMargin : 0;
    const bottomMargin = part.bottomMargin <= 0 ? part.bottomMargin : 0;

    const cropRight = naturalWidth - part?.cropX - part?.width;
    const generalRight = cropRight + Math.abs(rightMargin) / part?.scaleX;
    const generalLeft = part.cropX + Math.abs(leftMargin) / part?.scaleX;
    const generalTop = part.cropY + Math.abs(topMargin) / part?.scaleY;
    const cropBottom = naturalHeight - part?.cropY - part?.height;
    const generalBottom = cropBottom + Math.abs(bottomMargin) / part?.scaleY;
    const area = visibleHeight * visibleWidth;

    const newRatio = newHeight / newWidth;

    const coeffArea = Math.sqrt(area);
    const coeffRatio = Math.sqrt(newRatio);

    const intermediateWidth = coeffArea / coeffRatio;
    const intermediateWidthNatural = intermediateWidth / part.scaleX;

    let maxCropWidth = 0;
    let maxCropHeight = 0;
    switch (part.classificationType) {
      case CLASSIFICATION_TYPES.BACKGROUND_PART_TOP: {
        maxCropWidth = naturalWidth - generalRight;
        maxCropHeight = naturalHeight - generalBottom;
      };
      case CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM: {
        maxCropWidth = naturalWidth - generalLeft;
        maxCropHeight = naturalHeight - generalTop;
      };
      case CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT: {
        maxCropWidth = naturalWidth - generalRight;
        maxCropHeight = naturalHeight - generalBottom;
      };
      case CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT: {
        maxCropWidth = naturalWidth - generalLeft;
        maxCropHeight = naturalHeight - generalTop;
      };
    };

    let finalWidth = intermediateWidthNatural > maxCropWidth ? maxCropWidth : intermediateWidthNatural;
    let finalRatio = newWidth / newHeight;
    let finalHeight = finalWidth / finalRatio;
    finalHeight = finalHeight > maxCropHeight ? maxCropHeight : finalHeight;
    finalWidth = finalHeight * finalRatio;

    const scaleRatio = newWidth / finalWidth;

    part.scaleX = scaleRatio;
    part.scaleY = scaleRatio;
    const scaleX = part?.scaleX;
    const scaleY = part?.scaleY;


    const isVerticalPart =
      part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT || part.classificationType === CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT;

    const getCropFunc = isVerticalPart ? getCropForVerticalPart : getCropForHorizontalPart;

    let croppedPart = part;
    if (part.classificationType !== CLASSIFICATION_TYPES.BACKGROUND_PART_CENTER) {
      const crop = getCropFunc(part, naturalWidth, naturalHeight, generalLeft, generalRight, generalTop, generalBottom, finalWidth, newHeight, scaleX, scaleY);
      croppedPart = cropImage(part, crop);
    }
    const isFirst = [CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT, CLASSIFICATION_TYPES.BACKGROUND_PART_TOP].indexOf(part.classificationType) !== -1;


    result.push({
      ...croppedPart,
      ...getBackgroundPartLeftAndTop(part, newWidth, newHeight, prevWidth, prevHeight, canvasWidth, canvasHeight),
      initialSize: [visibleWidth, visibleHeight],
      resultSize: [newWidth, newHeight]
    });
  }

  return result
};

const getCropForVerticalPart = (
  part: any,
  naturalWidth: number,
  naturalHeight: number,
  generalLeft: number,
  generalRight: number,
  generalTop: number,
  generalBottom: number,
  finalWidth: number,
  newHeight: number,
  scaleX: number,
  scaleY: number,
) => {
  switch (part.classificationType) {
    case CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT: {
      return {
        x: (naturalWidth - generalRight - finalWidth) * scaleX,
        y: naturalHeight * scaleY - newHeight - generalBottom * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      };
    };
    case CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT: {
      return {
        x: generalLeft * scaleX,
        y: generalTop * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      }
    };
    default: console.log("FAILURE VERT")
  }
};

const getCropForHorizontalPart = (
  part: any,
  naturalWidth: number,
  naturalHeight: number,
  generalLeft: number,
  generalRight: number,
  generalTop: number,
  generalBottom: number,
  finalWidth: number,
  newHeight: number,
  scaleX: number,
  scaleY: number,
) => {
  switch (part.classificationType) {
    case CLASSIFICATION_TYPES.BACKGROUND_PART_TOP: {
      return {
        x: (naturalWidth - generalRight - finalWidth) * scaleX,
        y: naturalHeight * scaleY - newHeight - generalBottom * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      };
    };
    case CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM: {
      return {
        x: generalLeft * scaleX,
        y: generalTop * scaleY,
        width: finalWidth * scaleX,
        height: newHeight
      }
    };
    default: console.log("FAILURE HOR")
  }
};