import { TemplatesTrayState, SetTemplates, SET_TEMPLATES, FETCH_TEMPLATES, FetchTemplatesAction, FETCH_TEMPLATES_CATEGORIES, FetchTemplateCategoriesAction, SetTemplateCategories, SET_TEMPLATES_CATEGORIES } from "../types";

const initialState: TemplatesTrayState = {
  templates: [],
  page: 1,
  totalPages: null,
  search: '',
  loading: false,
  textTemplates: null,
  type: '',
  loadingCategories: false,
  categoriesData: {
    categories: [],
    count: 1,
    current_page: 1,
    page_size: 1,
    pages: 1
  }
}

export const templatesTrayReducer = (state = initialState, action: SetTemplates|FetchTemplatesAction|FetchTemplateCategoriesAction|SetTemplateCategories): TemplatesTrayState => {
  switch(action.type) {
    case FETCH_TEMPLATES:
      return {
        ...state,
        loading: action.payload.page < 2 ? true : false
      }
    case SET_TEMPLATES:
      if(action.payload.type === "image"){
        return {
          ...state,
          ...action.payload,
          templates: action.payload.page > 1 ? [...state.templates, ...action.payload.templates] : action.payload.templates
        }
      }
      if(action.payload.type === "text"){
        let textTemplates = {};
        if(state.textTemplates){
          textTemplates = {
            ...action.payload,
            templates:  action.payload.page > 1 && state.textTemplates ? [...state.textTemplates.templates, ...action.payload.templates] : action.payload.templates
          }
        }else {
          textTemplates = {
            ...action.payload
          }
        }
        return {
          ...state,
          loading: false,
          textTemplates
        }
      }
      
      return {
        ...state,
        loading: false
      }
      case FETCH_TEMPLATES_CATEGORIES:
        return {
          ...state,
          loadingCategories: true
        }
      case SET_TEMPLATES_CATEGORIES:
          return {
            ...state,
            categoriesData: {
              ...action.payload,
              categories: action.payload.current_page > 1 && state.categoriesData ? [...state.categoriesData.categories, ...action.payload.categories] : action.payload.categories
            },
            loadingCategories: false
          }
    default:
      return state
  }
}