import React, { FC, useCallback, useEffect, useRef } from "react";
import { Button } from "pyxis-ui-kit";
import { Modal, Input } from "antd";

interface CreateResourceModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalTitle: string;
  modalDesc: string;
  text: string;
  setText: (title: string) => void;
  handleCreateButton: Function;
}

const CreateResourceModal: FC<CreateResourceModalProps> = ({
  isModalOpen,
  setModalOpen,
  modalTitle = "",
  modalDesc = "",
  text,
  setText,
  handleCreateButton,
}) => {
  const handleInputChange = useCallback((e) => setText(e.target.value), [
    setText,
  ]);

  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
    setText("");
  }, [setModalOpen, setText]);

  return (
    <Modal
      centered
      visible={isModalOpen}
      footer={null}
      className="add-new-agency-modal"
      onCancel={handleCancelButton}
    >
      <h2 className="modal-title">{modalTitle}</h2>
      <div className="modal-form">
        {modalDesc && <p>{modalDesc}</p>}
        <Input
          className="input-field"
          type="text"
          size="large"
          placeholder="Untitled"
          value={text}
          onChange={handleInputChange}
          onPressEnter={() => handleCreateButton(text)}
          autoFocus
        />
      </div>
      <div className="modal-buttons">
        <Button
          className="button create-button"
          type="primary"
          disabled={!text.trim().length}
          onClick={() => handleCreateButton(text)}
        >
          Create
        </Button>
        <Button className="button cancel-button" onClick={handleCancelButton}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CreateResourceModal;
