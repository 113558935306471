import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Menu, MenuItem, SubMenu, Checkbox, Input, Row, Col, Space } from 'pyxis-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RolesState } from '../../../store/types';
import { fetchMembersList } from '../../../store/actions';
import Avatar from '../../shared/Avatar';

const UserFilter:React.FC<{
    label:string;
    value: string[];
    permissions: string[];
    changeFilter: Function;
    selectedUsers: string[];
}> = ({label,permissions,changeFilter,value,selectedUsers}) => {
    const userData = useSelector((state:{authentication:AuthState})=>state.authentication.userData);
    const selectedProductId = useSelector((state:{roles: RolesState})=>(state.roles.selectedProductId))
    const [showMembers,setShowMembers] = useState(false);
    const [visible,setVisible] = useState(false);
    const [search,setSearch] = useState('');
    const [users,setUsers] =  useState([]);
    const dispatch = useDispatch();
    const filteredUsers = useMemo(()=>{
        if(!search.trim()){
            return users;
        }
        return users.filter((u:any)=>((u.firstName+" "+u.lastName).toLowerCase().includes(search.trim().toLowerCase())))
    },[users,search])
    const createdByMe:boolean = useMemo(()=>{
        return userData ? selectedUsers.includes(userData.userId):false;
    },[userData,selectedUsers])

    const fetchUsers = useCallback((params:any) => {
        dispatch(
            fetchMembersList(params,(res: any, err: boolean) => {
                if(!err){
                    setUsers(res.data.users);
                }else{
                    setUsers([])
                }
            })
        );
    },[])

    useEffect(()=>{
        fetchUsers({
            entityType: 'product',
            entityId: selectedProductId ? selectedProductId : undefined,
            permissions
        })
    },[])

    useEffect(()=>{
        if(!showMembers && selectedUsers.length > 0){
            if(createdByMe){
                changeFilter([userData?.userId],true)
            }else{
                changeFilter(null,true)
            }
        }
    },[showMembers])

    return <div className="filter">
        <div className="label">{label}</div>
        <Dropdown 
        visible={visible}
        onVisibleChange={(flag)=>setVisible(flag)}
        trigger={['click']}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        overlay={
            <Menu>
                <MenuItem>
                    <Checkbox checked={createdByMe}
                        onChange={(e)=>{
                            changeFilter({userId: userData?.userId,value: `${userData?.firstName} ${userData?.lastName}`,checked: e.target.checked});
                        }}
                    >{label} me</Checkbox>
                </MenuItem>
                <MenuItem>
                    <Checkbox value={showMembers} onChange={()=>setShowMembers((val)=>!val)}>{label} member</Checkbox>
                </MenuItem>
                {showMembers && <>
                    <MenuItem style={{position: "sticky",top:'-0.5rem',background: '#fff',zIndex: 1}}>
                        <Input placeholder="Search.." allowClear={true} onChange={(e:any)=>setSearch(e.target.value)} />
                    </MenuItem>
                    {filteredUsers.map((user:any)=>(
                        (userData? (user._id !== userData?.userId):true)&&
                        <MenuItem key={user._id}>
                            <Checkbox checked={selectedUsers.includes(user._id)}
                            onChange={(e)=>{
                                changeFilter({userId: user._id,value: `${user.firstName} ${user.lastName}`,checked: e.target.checked});
                            }}>
                                <Space direction="vertical" align="baseline" style={{width: '20rem'}}>
                                    <span className="user-item text-ellipsis">{`${user.firstName} ${user.lastName}`}</span>
                                    <span className="user-subitem text-ellipsis">{`${user.email}`}</span>
                                </Space>
                            </Checkbox>
                        </MenuItem>
                    ))}
                </>}
            </Menu>
        }>
            <div className="action">
                <div className="name">
                    <span className={value.length === 1? "ml-10 text-ellipsis":"text-ellipsis"}>{value.length === 0? 'All Users':value.join(',')}</span>
                    {value.length > 2 &&<span>{`+${value.length - 2}`}</span>}
                </div>
                <span className="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6L8 10L13 6" stroke="#8090E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
        </Dropdown>
    </div>
}

export default UserFilter;