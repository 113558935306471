import { getObjectSize } from "../helpers/getObjectSize";
import { getVisibleSide } from "../helpers/getVisibleSide";
import { isObjectPartOutsideCanvas } from "../helpers/positioning";
import { CLASSIFICATION_TYPES } from "./constants";

export const addClassToObjects = (obj: any, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  const [width, height] = getObjectSize(obj);

  const yObjCenter = obj.top + height / 2;
  const topMargin = obj.top;
  const bottomMargin = prevHeight - (yObjCenter + height / 2);

  const xObjCenter = obj.left + width / 2;
  const leftMargin = obj.left;
  const rightMargin = prevWidth - (xObjCenter + width / 2);

  const visibleWidth = getVisibleSide(width, leftMargin, rightMargin);
  const visibleHeight = getVisibleSide(height, topMargin, bottomMargin);
  const visibleSquare = visibleWidth * visibleHeight;

  const canvasSquare = prevWidth * prevHeight;

  if (obj.isBackground || (visibleSquare / canvasSquare) * 100 >= 90) {
    obj.classificationType = CLASSIFICATION_TYPES.BACKGROUND;
    return obj;
  }

  const isHorizontalPart = visibleHeight / prevHeight >= 0.9;
  const isVerticalPart = visibleWidth / prevWidth >= 0.9;

  const objSquare = width * height;
  const imageSquare = obj.width * obj.scaleX * obj.height * obj.scaleY;

  const isBackgroundPart = imageSquare >= 0.3 * canvasSquare;
  const isImage = obj.layerType === "Image";
  const backgroundImage = isBackgroundPart && isImage;

  if (backgroundImage && isHorizontalPart && rightMargin / prevWidth <= 0.05) {
    obj.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_RIGHT;
    return obj;
  } else if (backgroundImage && isVerticalPart && bottomMargin / prevHeight <= 0.05) {
    obj.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_BOTTOM;
    return obj;
  } else if (backgroundImage && isHorizontalPart && leftMargin / prevWidth <= 0.05) {
    obj.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_LEFT;
    return obj;
  } else if (backgroundImage && isVerticalPart && topMargin / prevHeight <= 0.05) {
    obj.classificationType = CLASSIFICATION_TYPES.BACKGROUND_PART_TOP;
    return obj;
  } else if (isObjectPartOutsideCanvas(obj, prevWidth, prevHeight)) {
    obj.classificationType = CLASSIFICATION_TYPES.OUTSIDE_CANVAS;
    return obj;
  } else obj.classificationType = CLASSIFICATION_TYPES.DEFAULT;
  return obj;
};

export const addClassToObjectList = (objList: Array<any>, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  return objList.map(obj => addClassToObjects(obj, prevWidth, prevHeight, canvasWidth, canvasHeight));
};
