import React, { useEffect, useState,Dispatch,useRef,FunctionComponent} from 'react';
import './index.scss';
import { LibraryState,Color, RolesState } from '../../../store/types';
import { fetchColors, uploadColor, deleteColor, setSelectedColors, updateColor, setColors, addColors, removeColor } from '../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { openToast } from '../../../Toasts';
import { TopFilter ,EmptyFile,SuccessScreen,DropDownOption} from '../../shared/index';
import { Button, Checkbox, Drawer,Input } from 'pyxis-ui-kit';
import { CopyOutlined, PlusCircleOutlined} from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import iconAddCircle from '../../../assets/svg/iconAddCircle.svg';
import iconEdit from '../../../assets/svg/iconEdit.svg';
import iconDelete from '../../../assets/svg/iconDelete.svg';
import { isEmpty } from 'lodash';
import {permissionDeniedClick} from '../../../utilities/common-function';

interface ColorInterface{
    color: Color,
    isSelected: boolean,
    isDisabled: boolean,
    handleColorSelect: Function,
    setEditColor:Function,
    index:number,
    setDrawerVisible:Function,
    selectedColors:number[]
}

interface ColorAlertInterface{
    isDrawerVisible:boolean,
    dispatch: Dispatch<any>,
    setDrawerVisible:Function,
    drawerStep:number,
    setDrawerStep:Function,
    type?:string,
    isFolderDrawer?:boolean,
    setIsFolderDrawer?:Function,
    projectType:string,
    editColor:any,
    setEditColor:Function
}

const ColorAlert:FunctionComponent<ColorAlertInterface> = ({dispatch,isDrawerVisible,setDrawerVisible,drawerStep,setDrawerStep,isFolderDrawer,editColor,setEditColor}) =>{
    const [loader,setLoader]=useState(false)
    const [color,setColor]=useState('#152370')
    const [title,setTitle]=useState('#152370')
    const [isAddAnother,setIsAddAnother]=useState(true)
    const [isEdit,setIsEdit]=useState(false)
    const [addedColors,setAddedColors]=useState<any>([])
    const colorNameRef =useRef<any>(null);
    const saveRef =useRef<any>(null);
    const closeRef =useRef<any>(null);
    const state = useSelector((state: {library: LibraryState})=>(state.library));
    const { colors } = state;

    useEffect(()=>{
        if(!isEmpty(editColor)){
            setColor(editColor.color_code)
            setTitle(editColor.name)
        }
    },[])

    useEffect(()=>{
        setTimeout(()=>{
            colorNameRef.current!.focus({
                cursor: 'end',
            });
        },[50])
    },[colorNameRef])

    useEffect(()=>{
        if(isAddAnother){
            colorNameRef.current!.focus({
                cursor: 'end',
            })
        }else if(addedColors.length){
            saveRef.current!.focus()
        }
    },[isAddAnother])

    const handleAddColor=()=>{
        if(isEmpty(editColor)){
            setLoader(true);
            let uploadedColor=0;
            addedColors.forEach((color:any)=>{
                const colorName=isEmpty(editColor)?color.name:title?title:color.name    
                dispatch(uploadColor({color_code:color.color_code,name:colorName},(res:any,err:boolean)=>{    
                    if(!err){
                        res.data['can_manage'] = true;
                        dispatch(addColors([res.data]))
                        uploadedColor=uploadedColor+1
                        if(addedColors.length===1){
                            openToast('success', 'Color added!')
                        }else if(uploadedColor === addedColors.length){
                            openToast('success', 'Colors added!')
                        }
                    }
                }))
            })
            
            setLoader(false);
        }else{
            setLoader(true);
            dispatch(updateColor({...editColor,name:title,color_code:color},(res:any,err:boolean)=>{    
            }));
            setLoader(false);    
        }
        setDrawerStep(1)
        setTimeout(()=>{
            closeRef.current!.focus()
        },100)
    }
    const closeDrawer=()=>{
        setDrawerStep(0)
        setDrawerVisible('')
        setEditColor({})
    }
    const  handleChangeComplete = (color:any) => {
        setColor(color.hex)
        title.startsWith('#')&&setTitle(color.hex)
    };

    const handleColorDelete=(colorCode:any)=>{  
        setAddedColors((colorData:Color[])=>(colorData.filter((color:Color)=>color.color_code!==colorCode)))
    }

    const handleColorEdit=(name:string,colorCode:any)=>{
        setTitle(name)
        setColor(colorCode)
        handleColorDelete(colorCode)
        setIsAddAnother(true)
        setIsEdit(true)
    }

    const handleColor=()=>{
        let flag=colors.some((colorData:Color)=>colorData['color_code']===color)
        !flag&&(flag=addedColors.some((colorData:Color)=>colorData['color_code']===color))
        if(flag){
            openToast('warn','Color is already added!')
        }else{
            setAddedColors((colors:Color[])=>([...colors,{name:title,color_code:color}]))
            setIsAddAnother(false)
            isEdit&&setIsEdit(false)
        }
    }
    const handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {if(!isEmpty(editColor)){handleAddColor()}}
    }
    // <div className="mount-drawer">
    return  <Drawer 
    className="color-drawer" 
    closable={false}
    visible={isDrawerVisible||isFolderDrawer} 
    onClose={closeDrawer} 
    // getContainer=".mount-drawer"
    footer={
        <div className="add-drawer-footer">
        {drawerStep ===0 ? 
        <>
            <Button className="add-drawer-cancel" onClick={closeDrawer} type="link">
                Cancel
            </Button>
            <Button className="color-btn" onClick={handleAddColor} disabled={!addedColors.length&&(isEmpty(editColor))} type="primary" loading={loader} ref={saveRef}>{isEmpty(editColor)?"Save":"Edit"}</Button> 
        </>
        : <div className="file-success-footer">
            <Button onClick={closeDrawer} type="primary" ref={closeRef} className="color-btn">
                Close
            </Button>
        </div>
        }
        </div> 
    } 
>
    {drawerStep ===0 ? 
    <div className="color-alert">
        <h3 className="add-drawer-title">{isEmpty(editColor)?"Add new color":"Edit Color"}</h3>
        {/* <span className="add-drawer-subtitle">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</span> */}
        {addedColors.map((color:any)=>{
            const {color_code,name}=color
            return <div className="color-container" key={color_code}>
                <div className="color-info">
                    <div style={{background:color_code}} className="color-display"/>
                    <div className="color-detail">
                        <div className="color-name">{name}</div>
                        <div className="color-code">{color_code}</div>
                    </div>
                </div>
                <div className="color-action">
                    <img src={iconEdit} className="edit-icon" onClick={()=>{handleColorEdit(name,color_code)}} alt=""/>
                    <img src={iconDelete} onClick={()=>{handleColorDelete(color_code)}} className="delete-icon" alt=""/>
                </div>
            </div>
        })}
        {(addedColors.length===0?true:isAddAnother)?
        <>
            <span className="add-drawer-label">Color name</span>
            <div className="color-form">
                <Input
                    ref={colorNameRef}
                    placeholder="Enter your color name. For example, Red-Default."
                    className="add-drawer-input"
                    value={title}
                    onChange={(e:any)=>setTitle(e.target.value)}
                    onKeyDown={(e:any)=>handleKeyDown(e)}  
                />
                {isEmpty(editColor)&&<div className="btn-container">
                    <Button type="default" className="save" onClick={handleColor}>{isEdit?"Edit ":"Add "}color</Button>
                </div>}
            </div>
            <div className='color-picker-container'>
                <ChromePicker
                    disableAlpha={true}
                    color={color}
                    onChangeComplete={handleChangeComplete}
                ></ChromePicker>
            </div>
            
        </>
        :
        <div className="add-another-container">
            <Button type="default" className="add-another" 
            onClick={()=>{setIsAddAnother(true)
                setTitle(color)
            }}>
                <PlusCircleOutlined className="add-icon"/>Add another color</Button>
        </div>
        }
    </div>  
    : <SuccessScreen
        title={"Great! Color " + (isEmpty(editColor) ? "added" : "edited") + " successfully"}
        subTitle="Discovering your files just got easier!"
    />
    } 
</Drawer>
// </div>
}


const Container:any = ({children,className,...props}:any) =>{
    return <div className={className} {...props}>
        {children}
    </div>
}

const ColorCard:FunctionComponent<ColorInterface> = ({isDisabled,color,isSelected,handleColorSelect,setDrawerVisible,index,setEditColor,selectedColors}) => {
    const [isOpenMoreVisible,setIsOpenMoreVisible] = useState(false);
    const [dropDownStep, setDropDownStep] = useState(0)
    const [loading,setLoader]=useState(false)
    const dispatch = useDispatch();
    const [isDeletOverlay,setIsDeleteOverlay]=useState(false)

    const handleDelete=(colorId:number)=>{
        setLoader(true);
        dispatch(deleteColor([colorId], (res:any, err:boolean) => {
            if(!err) {
                dispatch(removeColor([colorId]));
                dispatch(setSelectedColors([]));
                openToast('success', "Color deleted!");
            }
            setLoader(false);
        }))
    }


    const fallbackCopyTextToClipboard=(colorCode:any) =>{
        var textArea = document.createElement("textarea");
        textArea.value = colorCode;
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.opacity="0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          var successful = document.execCommand('copy');
          if(successful){
              openToast('success','Color copied to clipboard!')
          }else{
             openToast('error','Could not copy to clipboard!')
          }
        } catch (err) {
          openToast('error','Could not copy to clipboard!')
        }
      
        document.body.removeChild(textArea);
    }

    const handleCopyCode=(colorCode:any,e:any)=>{
        e.stopPropagation();
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(colorCode);
            return;
          }
          navigator.clipboard.writeText(colorCode).then(function() {
            openToast('success','Color copied to clipboard!')
          }, function(err) {
            openToast('error','Could not copy to clipboard!')
          });
    }

    return <>
        <Container onClick={()=>{handleColorSelect(color.id)}} className={isSelected?"selected card":"card"} >
            <div className= "color" style={{background: color.color_code}}>
            </div>
            <div className="color-footer">
                <div className="color-name">{color.name}</div>
                <div className="color-code">{color.color_code}</div>
            </div>
            <div className={isOpenMoreVisible||isDeletOverlay?"show-overlay overlay":"overlay"}>
            {!isDeletOverlay?
                <>
                {!isDisabled && <div className="action">
                    {selectedColors.length < 1 && <DropDownOption componentCaller="color" project={color} isOpenMoreVisible={isOpenMoreVisible}
                    setIsOpenMoreVisible={setIsOpenMoreVisible} setIsDeleteOverlay={setIsDeleteOverlay}
                    projectIndex={index} setDrawerVisible={setDrawerVisible} setCurrentProject={setEditColor}
                    setDropDownStep={setDropDownStep} dropDownStep={dropDownStep} projectType="color"
                    />}
                    {selectedColors.length > 0 && <Checkbox checked={isSelected} onChange={()=>{handleColorSelect(color.id)}}/>}
                </div>}
                <div className="copy-container">
                    <Button type="default" className="copy" onClick={(e:any)=>handleCopyCode(color.color_code,e)}><CopyOutlined className="icon-copy"/>Copy Code</Button>
                </div>
                </>
                :<div className="delete-overlay">
                    <div className="delete">
                        Delete color?
                    </div>
                    <div className="btn-container">
                        <Button className="no" 
                        onClick={(e:any)=>{
                                e.stopPropagation();
                                setIsDeleteOverlay(false)
                            }}>No</Button>
                        <Button className="yes" onClick={(e:any)=>{
                            e.stopPropagation();
                            handleDelete(color.id)
                        }} loading={loading}>Yes</Button>
                    </div>
                </div>
                }
            </div>
        </Container>
    </>
}


const Colors:FunctionComponent = () => {
    const [loading,setLoader] = useState(false);
    // const [isAddColor,setIsAddColor]=useState(false);
    const [editColor,setEditColor]=useState<Color>(Object)
    const [drawerStep,setDrawerStep]=useState(0);
    const userPermission:any = useSelector((state: {roles:RolesState})=>(state.roles.userPermissions.length !== 0 ? state.roles.userPermissions:[]))
    const state = useSelector((state: {library: LibraryState})=>(state.library));
    const dispatch = useDispatch();
    const { colors,selectedColors } = state;
    const [drawerVisible,setDrawerVisible]=useState('')

    const handleColorSelect = (id:number) => {
        if(selectedColors.indexOf(id)>-1){
            let colors:number[] = selectedColors.filter((i)=>i!==id);
            dispatch(setSelectedColors(colors));
        }else{
            dispatch(setSelectedColors([...selectedColors,id]));
        }
    }

    useEffect(()=>{
        setLoader(true);
        dispatch(fetchColors({page: 1},(res:any,err:boolean)=>{
            if(!err){
                dispatch(setColors(res.data.results))
            }
            setLoader(false)
          }))
    },[])

    const handleEmptyColorClick = () =>{
        if(userPermission.indexOf('manage-brand-colors') > -1){
            setDrawerVisible('addColor')
        }else{
            permissionDeniedClick()
        }
    }
    
    return (<>
        {loading ? new Array(3).fill(null).map((f: any, i: number) => (
                <div className="empty-color pulsate" key={i} ></div>
            ))
        : colors.length > 0 ? <Container className="library-colors">
            <TopFilter projectType="color" componentCaller="project" drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} canManage={userPermission.indexOf('manage-brand-colors') > -1}/>
            <div className="flexwrap">
                {userPermission.indexOf('manage-brand-colors') > -1 && <div className="add-card" onClick={()=>{
                    setDrawerVisible('addColor')
                }}>
                    {/* <img src={iconInfo} className="info-icon"/> */}
                    <span className="add-icon">
                        <img src={iconAddCircle} alt=""/>
                    </span> 
                    <p className="add-color">Add color</p>
                </div>}
                {colors.map((color,index)=>(  
                    <ColorCard color={color} key={color.id} isSelected={selectedColors.indexOf(color.id) > -1} 
                        handleColorSelect={handleColorSelect} isDisabled={userPermission.indexOf('manage-brand-colors') === -1}
                        setEditColor={setEditColor} index={index} setDrawerVisible={setDrawerVisible} selectedColors={selectedColors}
                    />
                ))}
            </div>
        </Container>
        : colors.length === 0 && <EmptyFile
            title="Add brand colors"
            subtitle=""
            btnTxt={<span className={userPermission.indexOf('manage-brand-colors') > -1?"txt-container":"txt-container permission-denied-lock"}>
                <PlusCircleOutlined className="icon"/>Add color
                </span>}
            handleClick={handleEmptyColorClick}
        />}
        {(drawerVisible==='addColor'||drawerVisible==='editColor')&&<ColorAlert
            dispatch={dispatch}
            isDrawerVisible={drawerVisible==="addColor"||drawerVisible==="editColor"}   
            setDrawerVisible={setDrawerVisible}
            drawerStep={drawerStep}
            setDrawerStep={setDrawerStep}
            projectType="color"
            editColor={editColor}
            setEditColor={setEditColor}
            
        />}
    </>
    )
}

export default Colors;