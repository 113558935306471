import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConversionContext } from '../../ConversionContext/ConversionContext';
import { CreateContextAiquire } from '../../CreateContextAiquire/CreateContextAiquire';
import {
  getTemplateSizes,
  loading,
  getPopularTemplateSizes,
} from '../../store/actions';
import { FullScreenSpinner } from '../shared/Spinner';
import { IMAGE_BASE_URL } from '../../utilities/paths';
import { BoxIcon, CheckIcon, PartialIcon } from '../LayerMenu/Icons/Icons';
import TopMenu from './TopMenu';

import './AiquireCreateFlow.scss';
import { openToast } from '../../Toasts';
import Img from '../ImageLoader/ImageLoader';
import { compareStrings } from '../../utilities/common-function';
import { getTourStateValue, SET_DESIGN_FIRST_TOUR_ID, SINGLE_DESIGN_TOUR_ID, startTour, updateTourState } from '../../utilities/services/intercom';

export const getSizesArray = (arr: any) => {
  const updatedArr: any[] = [];
  arr.forEach((categoryObj: any) => {
    categoryObj.presetsizes.forEach((sizeObj: any) => {
      updatedArr.push({
        key: `${categoryObj.id}-${sizeObj.id}`,
        value: sizeObj.name,
        width: sizeObj.width,
        height: sizeObj.height,
        category: categoryObj.name,
        iconUrl: sizeObj.icon ? `${IMAGE_BASE_URL}${sizeObj.icon}` : null,
      });
    });
  });
  return updatedArr.sort((a, b) => compareStrings(a.category, b.category));
};

export const getCategoryArr = (arr: any) => {
  const res: any = ['Custom'];
  arr.forEach((categoryObj: any) => {
    const { category } = categoryObj;
    !res.includes(category) && res.push(category);
  });
  return res.sort(compareStrings);
};

const SelectSizes = () => {
  const {
  } = useContext(ConversionContext);
  const {
    sizesArray,
    setSizesArray,
    currentStep,
    setCurrentStep,
    createMode,
    setCreateMode,
    setLoading,
    selectedSizes,
    setSelectedSizes,
  } = useContext(CreateContextAiquire);

  const [popularArray, setPopularArray] = useState<any[]>([]);
  const [customArray, setCustomArray] = useState<any[]>([]);
  const [customWidth, setCustomWidth] = useState<any>('');
  const [customHeight, setCustomHeight] = useState<any>('');
  const [customCount, setCustomCount] = useState<number>(1);

  const history = useHistory();
  const dispatch = useDispatch();
  const props = useSelector((state: any) => ({
    templateSizes: state.create.templateSizes,
    isLoading: state.create.loading,
    popularSizes: state.create.popularTemplateSizes,
  }));

  useEffect(() => {
    // make api call to get sizes and popular sizes
    dispatch(loading());
    dispatch(
      getTemplateSizes({}, (res: any, isError: any) => {
        // toast message integration here
        if (isError) openToast('error', 'sizes fetch fail');
        if (!isError) {
          setSizesArray(
            getSizesArray(res.data.categories)
              .sort((a, b) => compareStrings(a.value, b.value))
          );
          if (createMode === 'single') {
            dispatch(loading());
            dispatch(
              getPopularTemplateSizes({}, (res1: any, isError1: any) => {
                // toast message integration here
                if (isError1) openToast('error', 'popular sizes fetch fail');
                if (!isError1) {
                  setPopularArray(
                    getSizesArray([
                      {
                        id: 'popular',
                        name: 'Popular',
                        presetsizes: (res1.data.presetsizes).sort((a: any, b: any) => compareStrings(a.name, b.name)),
                      },
                    ])
                  );
                }
              })
            );
          }
        }
      })
    );

    // check and start the tour if first time landed 
    if (createMode === 'single' && !getTourStateValue('SINGLE_DESIGN_TOUR')) {
      startTour(SINGLE_DESIGN_TOUR_ID);
      updateTourState('SINGLE_DESIGN_TOUR', true)
    } else if (!getTourStateValue('SET_DESIGN_FIRST_TOUR')) {
      startTour(SET_DESIGN_FIRST_TOUR_ID);
      updateTourState('SET_DESIGN_FIRST_TOUR', true)
    }
  }, []);

  const addCustomSize = () => {
    if (!customHeight || !customWidth) {
      openToast('warn', 'Please enter numbers.');
    } else if (isNaN(customWidth) || isNaN(customHeight)) {
      openToast('warn', 'Not valid numbers.');
    } else if (parseInt(customWidth) < 1 || parseInt(customHeight) < 1) {
      openToast('warn', 'Not valid numbers.');
    } else {
      setCustomArray(
        [{
          key: `custom${customCount}-(${parseInt(customWidth, 10)},${parseInt(
            customHeight,
            10
          )})`,
          value: `Custom-${customCount} (${parseInt(
            customWidth,
            10
          )}X${parseInt(customHeight, 10)})`,
          width: parseInt(customWidth, 10),
          height: parseInt(customHeight, 10),
          category: 'Custom',
        }].concat(customArray)
      );
      setCustomCount(customCount + 1);
      setCustomHeight('');
      setCustomWidth('');
    }
    updateSelection(`custom${customCount}-(${parseInt(customWidth, 10)},${parseInt(customHeight, 10)})`);
  };

  const updateSelection = (key: any) => {
    if (createMode === 'single') {
      setSelectedSizes([key]);
    } else {
      if (selectedSizes.includes(key)) {
        setSelectedSizes(selectedSizes.filter((k: any) => k !== key));
      } else {
        setSelectedSizes(selectedSizes.concat(key));
      }
    }
  };

  const createBoxes = (arr: any) =>
    arr.map((obj: any) => {
      const { height, width, value, key } = obj;
      let intW = 70;
      let intH = 70;
      if (height > width) {
        intW = (70 * width) / height;
      }
      if (height < width) {
        intH = (70 * height) / width;
      }
      const imageLoaderStyle = {
        // height: '9.5rem',
        maxWidth: '40px',
        borderRadius: '0.8rem',
        overflow: 'hidden',
      };
      return (
        <div
          key={key}
          className='select-sizes-checkbox-wrap__box'
          onClick={() => updateSelection(key)}
        >
          <div
            style={
              selectedSizes.includes(key) ? { backgroundColor: '#D7D3F5', border: '0.2rem solid #20007F' } : {}
            }
            className='select-sizes-checkbox-wrap__box__single'
          >
            {obj.iconUrl ? (
              <Img src={obj.iconUrl} style={imageLoaderStyle} />
            ) : (
              <span
                className='select-sizes-checkbox-wrap__box__single__internal'
                style={{ width: `${intW}px`, height: `${intH}px` }}
              />
            )}
          </div>
          <p>{value.split('(')[0]}</p>
          <p>{`${width} x ${height} px`}</p>
        </div>
      );
    });

  const createCustomBox = () => (
    <div className='select-sizes-checkbox-wrap__custom'>
      <div className='select-sizes-checkbox-wrap__custom__single'>
        <input
          type='number'
          value={customWidth}
          onChange={(e) => setCustomWidth(e.target.value)}
          placeholder='Width'
        />
        <input
          type='number'
          value={customHeight}
          onChange={(e) => setCustomHeight(e.target.value)}
          placeholder='Height'
        />
        <select value='pixel' disabled>
          <option value='pixels'>Unit: Pixels</option>
        </select>
      </div>
      <button onClick={() => addCustomSize()} type='button' disabled={!customWidth || !customHeight}>
        Add Size
      </button>
      <p>custom sizes</p>
    </div>
  );

  const selectAllHandler = (categoryKeysArr: any[]) => {
    // const allKeysArr = popularArray.concat(sizesArray).map((obj) => obj.key);
    const allSelected = categoryKeysArr.every((key) =>
      selectedSizes.includes(key)
    );
    const noneSelected = categoryKeysArr.every(
      (key) => !selectedSizes.includes(key)
    );
    if (allSelected) {
      const updatedSelectedSizes = selectedSizes.filter(
        (key: any) => !categoryKeysArr.includes(key)
      );
      setSelectedSizes(updatedSelectedSizes);
    } else if (noneSelected) {
      const updatedSelectedSizes = selectedSizes.concat(categoryKeysArr);
      setSelectedSizes(updatedSelectedSizes);
    } else {
      const updatedSelectedSizes = selectedSizes
        .filter((key: any) => !categoryKeysArr.includes(key))
        .concat(categoryKeysArr);
      setSelectedSizes(updatedSelectedSizes);
    }
  };

  const createTabHead = (tabName: string) => {
    const categoryKeysArr = popularArray
      .concat(sizesArray)
      .filter((obj) => {
        if (tabName === 'Custom') {
          return obj.key.includes('popular') || obj.key.includes('custom');
        }
        return obj.category.includes(tabName);
      })
      .map((obj) => obj.key);
    const allSelected = categoryKeysArr.every((key) =>
      selectedSizes.includes(key)
    );
    const noneSelected = categoryKeysArr.every(
      (key) => !selectedSizes.includes(key)
    );
    return (
      <div className='select-sizes-tabName'>
        {createMode === 'set' ? (
          <div
            className='select-sizes-tabName__checkbox'
            onClick={() => selectAllHandler(categoryKeysArr)}
          >
            {noneSelected ? (
              <BoxIcon />
            ) : allSelected ? (
              <CheckIcon />
            ) : (
              <PartialIcon />
            )}
          </div>
        ) : null}
        <h6>{tabName}</h6>
      </div>
    );
  };
  const tabsArr = getCategoryArr(sizesArray);

  return (
    <div className='select-sizes-wrapper'>
      <div className='select-sizes-header flex-direction-column'>
        {/* header ccomponent/ function  */}
        <TopMenu />
      </div>

      <div className='select-sizes-body'>
        {tabsArr.map((tab: any) => {
          if (tab === 'Custom') {
            return (
              (
                <Fragment key={tab}>
                  {createTabHead(tab)}
                  <div className='select-sizes-category-wrap'>
                    {createCustomBox()}
                    {createBoxes(customArray)}
                  </div>
                  {createMode === 'single' && <>
                    {createTabHead("Most Popular")}
                    <div className='select-sizes-category-wrap'>
                      {createBoxes(popularArray)}
                    </div>
                  </>}
                </Fragment>
              )
            );
          } else {
            return (
              <Fragment key={tab}>
                {createTabHead(tab)}
                <div className='select-sizes-category-wrap'>
                  {createBoxes(
                    sizesArray.filter((obj: any) => obj.category === tab)
                  )}
                </div>
              </Fragment>
            );
          }
        })}
      </div>
      {props.isLoading ? <FullScreenSpinner /> : null}
    </div>
  );
};

export default SelectSizes;
