import { put, takeLatest, call, all, takeEvery, select } from "redux-saga/effects";
import {
  GET_POPULAR_SIZES_SUCCESS,
  GET_SIZES_SUCCESS,
  LOADING_OFF,
  GET_SIZES,
  UPLOAD_FILE,
  GET_POPULAR_SIZES,
  GET_TEMPLATES,
  GET_CATEGORIES,
  GET_TEMPLATES_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  UPLOAD_MULTIPLE_FILES,
  SAVE_MULTIPLE_TEMPLATES,
  UPLOAD_MULTIPLE_FILES_SUCCESS,
  SAVE_MULTIPLE_TEMPLATES_SUCCESS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  CREATE_PROJECT_SUCCESS,
  GET_TEMPLATE_DATA,
  GET_TEMPLATE_DATA_SUCCESS,
  GET_CATEGORIES_ELEMENTS,
  GET_ELEMENTS_CATEGORY,
  GET_UPLOADED_FILES,
  DELETE_MULTIPLE_FILES,
  GET_CATEGORIES_ELEMENTS_SUCCESS,
  GET_ELEMENTS_CATEGORY_SUCCESS,
  GET_UPLOADED_FILES_SUCCESS,
  GET_PSD_JSON_SUCCESS,
  GET_PSD_JSON,
  GET_FONTS_LIST_SUCCESS_WEB,
  GET_FONTS_LIST_SUCCESS_UPLOAD,
  GET_FONTS_LIST,
  RENDER_IMAGES,
  SAVE_RENDER_IMAGES,
  GET_URL_STOCK_IMAGE,
  CANCEL_PSD_UPLOAD,
  SAVE_AI_DESIGN_DATA
} from "../types";
import {
  getTemplateDataAPI,
  getTemplateSizesAPI,
  uploadFileAPI,
  getPopularTemplateSizesAPI,
  getTemplatesAPI,
  getCategoriesAPI,
  saveMultipleTemplatesAPI,
  getProjectAPI,
  updateProjectAPI,
  createProjectAPI,
  getCategoriesOfElementsAPI,
  getElementsOfCategoryAPI,
  getUploadedFilesAPI,
  getPSDJSONAPI,
  getFontsListAPI,
  renderImagesAPI,
  getCategoriesAllAPI,
  getUrlStockImageAPI,
} from "../../MappedAPI/MappedAPI";
import { DESIGN_URI, PSD_IMPORT_URI } from "../../utilities/paths";
import HttpService from "../../HttpService/HttpService";
import { store } from "../store";
import axios from "axios";
//@ts-ignore
import AiTemplates1 from '../../components/Main/aiTemplates.ts'
import AiHsbcTemplates from "../../components/Main/aiHsbcTemplates";
import { getTemplatesByBrandOwner } from "../../components/Main/utils";
// this will come from cookies/login
// const TOKEN:string = localStorage.getItem('TOKEN') || '';
// const TOKEN = TMP_TOKEN;

function* getTemplateSizesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      getTemplateSizesAPI,
      TOKEN
    );
    if (!response.data.error) {
      yield put({
        type: GET_SIZES_SUCCESS,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getPopularTemplateSizesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      getPopularTemplateSizesAPI,
      TOKEN
    );
    if (!response.data.error) {
      yield put({
        type: GET_POPULAR_SIZES_SUCCESS,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getTemplatesSaga(action: any) {
  // create from action.params
  const {
    page,
    type,
    presetSizes,
    customSizes,
    pageSize,
    category,
    search,
  } = action.params;
  const paramsUrl = `?category=${category}&search=${search}&page=${page}&type=${type}&preset_sizes=${presetSizes}&custom_sizes=${customSizes}&page_size=${pageSize}`;
  const TOKEN: any = store.getState().authentication.token;
  try {
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getTemplatesAPI}${paramsUrl}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put({
        type: GET_TEMPLATES_SUCCESS,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getFontsListSaga(action: any) {
  // create from action.params
  const { webfont, productId, pageSize } = action.params;
  const TOKEN: any = store.getState().authentication.token;
  const paramsUrl = `?product_id=${productId}&webfont=${webfont}&page_size=${pageSize}`;
  try {
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getFontsListAPI}${paramsUrl}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put({
        type: webfont
          ? GET_FONTS_LIST_SUCCESS_WEB
          : GET_FONTS_LIST_SUCCESS_UPLOAD,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getCategoriesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      action.params.all ? getCategoriesAllAPI : getCategoriesAPI,
      TOKEN
    );
    if (!response.data.error) {
      yield put({
        type: GET_CATEGORIES_SUCCESS,
        payload: response.data.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getUrlStockImageSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      getUrlStockImageAPI,
      TOKEN,
      action.params
    );
    if (!response.data.error) {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

// upload file binary to file and fd conversion - reference
// fetch(str).then(res=>res.blob()).then(blob=>{
//   const file = new File([blob], 'test.jpeg');
//   const fd = new FormData();
//   fd.append('file', file);
//   fd.append('title', 'test');
//   fd.append('description', 'test desc');
//   fd.append('folder', '3');
//   fd.append('product_id', '1');
//   fd.append('type', 'direct');
//   dispatch(loading());
//   dispatch(uploadFile(fd, (res:any, isError:any) => {
//     console.log(res, isError, 'callback file');
//   }));
// })

function* uploadFileSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      uploadFileAPI,
      TOKEN,
      action.params,
      action.config || {}
    );
    if (!response.data.error) {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* uploadMultipleFilesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]
    const response = yield all(
      action.params.map((formDataImage: any) => {
        return call(
          HttpService.post,
          DESIGN_URI,
          uploadFileAPI,
          TOKEN,
          formDataImage
        );
      })
    );
    if (!response.map((res: any) => res.data.error).includes(true)) {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* saveMultiPleTemplatesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]
    const { projectId, data } = action.params;
    const urlParams = `${projectId}/save-as-template`;
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${saveMultipleTemplatesAPI}${urlParams}`,
      TOKEN,
      data
    );
    if (!response.data.error) {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getProjectSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getProjectAPI}${action.params.projectId}?editor_mode=true`,
      TOKEN
    );
    if (!response.data.error) {
      yield put({ type: GET_PROJECT_SUCCESS, payload: response.data });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* renderImagesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]
    const { projectId, psdUrl, cancelPSDToken } = action.params;
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${renderImagesAPI}${projectId}/psd-converter`,
      TOKEN,
      { psdUrl },
      {},
      cancelPSDToken && cancelPSDToken.token
    );
    if (!response.data.error) {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* saveRenderImagesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]
    const { projectId, cancelPSDToken } = action.params;
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${renderImagesAPI}${projectId}/generate-output`,
      TOKEN,
      {},
      {},
      cancelPSDToken && cancelPSDToken.token
    );
    if (!response.data.error) {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

// const TOKEN:any = store.getState().authentication.token;
//   const { showProgress = false,...params } = action.params;
//   try {
//     let config = {
//       onUploadProgress: function(progressEvent:any) {
//         let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
//         action.callback && action.callback({progress: percentCompleted})
//       }
//     };
//     const response = yield call(HttpService.post, PSD_IMPORT_URI, `${getPSDJSONAPI}`, TOKEN, params,showProgress?config:undefined);
//     if (response.data.statusCode === 200) {
//       yield put({ type: GET_PSD_JSON_SUCCESS, payload: response.data });
//       action.callback && action.callback(response.data);
//     } else {
//       yield put({ type: LOADING_OFF });
//       action.callback && action.callback(response, true);
//     }
//   } catch (error) {
//     yield put({ type: LOADING_OFF });
//     action.callback && action.callback(error, true);
//   }

function* getPSDJSONSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  const { data, cancelPSDToken } = action.params;

  try {
    const response = yield call(
      HttpService.post,
      PSD_IMPORT_URI,
      `${getPSDJSONAPI}`,
      TOKEN,
      data,
      action.config,
      cancelPSDToken && cancelPSDToken.token
    );
    if (response.data.statusCode === 200) {
      yield put({ type: GET_PSD_JSON_SUCCESS, payload: response.data });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* updateProjectSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // use all effect and run call in loop - get response[]

    const { projectId, data, noUpdate, cancelPSDToken } = action.params;

    const response = yield call(
      HttpService.put,
      DESIGN_URI,
      `${updateProjectAPI}${projectId}`,
      TOKEN,
      data,
      cancelPSDToken && cancelPSDToken.token
    );

    if (!response.data.error) {

      yield put({
        type: noUpdate ? LOADING_OFF : UPDATE_PROJECT_SUCCESS,
        payload: response.data,
      });
      action.callback && action.callback(response.data);

    } else {

      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* createProjectSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;

  try {
    // use all effect and run call in loop - get response[]
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      createProjectAPI,
      TOKEN,
      action.params,
      {}
    );

    if (!response.data.error) {
      yield put({ type: CREATE_PROJECT_SUCCESS, payload: response.data });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getTemplateDataSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  const organistationId: any = yield select((state: any) => state.roles.org_id);
  try {
    // use all effect and run call in loop - get response[]
    // const response = yield call(
    //   HttpService.get,
    //   DESIGN_URI,
    //   `${getTemplateDataAPI}${action.params.templateId}`,
    //   TOKEN
    // );
    const aiTemplates = getTemplatesByBrandOwner(organistationId);
    const response = { data: aiTemplates[action.params.templateId] }

    if (!response.data.error) {
      if (response.data) {
        yield put({ type: GET_TEMPLATE_DATA_SUCCESS, payload: response.data });
      }
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getCategoriesOfElementsSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // create url from params
    const urlParams = ``;
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getCategoriesOfElementsAPI}${urlParams}`,
      TOKEN
    );

    if (!response.data.error) {
      yield put({
        type: GET_CATEGORIES_ELEMENTS_SUCCESS,
        payload: response.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getElementsOfCategorySaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // create url from params
    const urlParams = ``;
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getElementsOfCategoryAPI}${urlParams}`,
      TOKEN
    );

    if (!response.data.error) {
      yield put({
        type: GET_ELEMENTS_CATEGORY_SUCCESS,
        payload: response.data,
      });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* getUploadedFilesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    // create url from params
    const urlParams = ``;
    const response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getUploadedFilesAPI}${urlParams}`,
      TOKEN
    );

    if (!response.data.error) {
      yield put({ type: GET_UPLOADED_FILES_SUCCESS, payload: response.data });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}

function* deleteMultipleFilesSaga(action: any) {
  const TOKEN: any = store.getState().authentication.token;
  try {
    const response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${getUploadedFilesAPI}`,
      TOKEN,
      action.params
    );

    if (!response.data.error) {
      yield put({ type: LOADING_OFF, payload: response.data });
      action.callback && action.callback(response.data);
    } else {
      yield put({ type: LOADING_OFF });
      action.callback && action.callback(response, true);
    }
  } catch (error) {
    yield put({ type: LOADING_OFF });
    action.callback && action.callback(error, true);
  }
}


export function* createAiquireWatcher() {
  yield takeLatest(GET_SIZES, getTemplateSizesSaga);
  yield takeLatest(GET_POPULAR_SIZES, getPopularTemplateSizesSaga);
  yield takeLatest(GET_TEMPLATES, getTemplatesSaga);
  yield takeLatest(GET_CATEGORIES, getCategoriesSaga);
  yield takeEvery(UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(UPLOAD_MULTIPLE_FILES, uploadMultipleFilesSaga);
  yield takeLatest(SAVE_MULTIPLE_TEMPLATES, saveMultiPleTemplatesSaga);
  yield takeEvery(CREATE_PROJECT, createProjectSaga);
  yield takeLatest(GET_PROJECT, getProjectSaga);
  yield takeEvery(UPDATE_PROJECT, updateProjectSaga);
  yield takeLatest(GET_TEMPLATE_DATA, getTemplateDataSaga);
  yield takeLatest(GET_CATEGORIES_ELEMENTS, getCategoriesOfElementsSaga);
  yield takeLatest(GET_ELEMENTS_CATEGORY, getElementsOfCategorySaga);
  yield takeLatest(GET_UPLOADED_FILES, getUploadedFilesSaga);
  yield takeLatest(DELETE_MULTIPLE_FILES, deleteMultipleFilesSaga);
  yield takeEvery(GET_PSD_JSON, getPSDJSONSaga);
  yield takeEvery(GET_FONTS_LIST, getFontsListSaga);
  yield takeEvery(RENDER_IMAGES, renderImagesSaga);
  yield takeEvery(SAVE_RENDER_IMAGES, saveRenderImagesSaga);
  yield takeEvery(GET_URL_STOCK_IMAGE, getUrlStockImageSaga);
}
