import React, { useEffect, useRef, useState, createRef, useCallback } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { RolesState } from '../../../../../store/types';
import { setMembersList, setProductsList, fetchMembersList, fetchProductList, fetchOthersProductList, fetchRolesList, setRolesList, unlinkUserWithEntity, linkUserWithEntity, setSelectedItem } from '../../../../../store/actions';
import { openToast } from '../../../../../Toasts';
import { permissionDeniedClick } from '../../../../../utilities/common-function';
import EmptyPlaceHolder from '../../../../shared/EmptyPlaceHolder';
import Avatar from '../../../../shared/Avatar';
import { DeleteIcon } from "../../../../LayerMenu/Icons/Icons";
import NoMedia from "../../../../../assets/svg/iconNoMedia.svg";
import AddMemberDrawer from "../../../AddMemberDrawer";
import CommonSuccessWarnDrawer from "../../../../shared/CommonSuccessWarnDrawer";
import { Dropdown, Menu, MenuItem } from 'pyxis-ui-kit';
import { HDFC_MFILTERIT_USER } from '../../../../../utilities/paths';

const TableContent:React.FunctionComponent = () => {
  const state = useSelector((state: {roles:RolesState})=>(state.roles));
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(true);
  const [addMemberPopUpProps, setAddMemberPopUpProps] = useState<any>(null);
  const [updatedLoading,setUpdateLoading] = useState(-1);
  const [removeLoading,setRemoveLoading] = useState(-1);
  const [open,setOpen] = useState(-1);
  const [activeRole,setActiveRole] = useState(-1);
  const [openRemoveAlert,setOpenRemoveAlert] = useState(-1);
  const [openChangeRoleAlert,setOpenChangeRoleAlert] = useState(-1);
  const rolesHashRef = useRef(new Map());
  // const [page,setPage] = useState(1);
  // const [total,setTotal] = useState(0)
  const [elRefs, setElRefs] = React.useState([]);
  const { activeTopBarTab,productsList,membersList,selectedItem,itemList,rolesList,userPermissions,brandPermissions,showBrandAdmin,brand_id } = state;
  const arrLength = (activeTopBarTab === 1? productsList:membersList).length; 
  const selectedEntityItemId = (itemList.length > 0 && selectedItem > -1)? itemList[selectedItem]._id:null;
  const canAddDeleteProducts:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('add-delete-product') > -1;
  const canManageUsers:boolean = (showBrandAdmin?brandPermissions: userPermissions).indexOf('manage-product-users') > -1;
  const [completionPopUpState, setCompletionPopUpState] = useState<any>(null);

  useEffect(()=>{
    dispatch(fetchRolesList({entityType: "product"},(res:any,err:boolean)=>{
      if(!err){
        // hiding third party custom role mfilterit
        delete res.data['third-party-approver'];
        let roles:any[] = Object.keys(res.data).reduce((acc:any,val:string)=>{
          rolesHashRef.current.set(val,res.data[val]?.name);
          return [...acc,{ roleId: val,name: res.data[val]?.name }];
        },[]);
        dispatch(setRolesList(roles));
      }
    }))
    return () => {
      dispatch(setMembersList([]));
      dispatch(setProductsList([]));
    }
  },[])

  useEffect(()=>{
    if(activeTopBarTab === 2 && selectedEntityItemId){
      dispatch(fetchMembersList({entityType: "product",entityId: selectedEntityItemId},(response:any,error:boolean)=>{
        if(!error){
          let users = response.data.users.filter((u:any)=>u.entityType === "product")
          dispatch(setMembersList(users));
          // setTotal(response.data.total);
        }
        setLoading(false);
      }))
    }
    if(activeTopBarTab === 1 && selectedEntityItemId && brand_id){
      dispatch(fetchOthersProductList({userId:selectedEntityItemId,level:"brand",entityId: brand_id },(response:any,error:boolean)=>{
        if(!error){
          dispatch(setProductsList(response.data.products));
        }
        setLoading(false);
      }))
    }
    if(!selectedEntityItemId) setLoading(true);
  },[selectedEntityItemId]);

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs:any[]):any => (
      Array(arrLength).fill(null).map((_, i) => elRefs[i] || createRef())
    ));
  }, [arrLength]);

  const getRoleName = useCallback((role:string)=>{
    return rolesHashRef.current.get(role)||role.split('-').join(' ');
  },[rolesHashRef.current])
  
  return <div className="table-content" id="table-content" style={{ overflow: (activeTopBarTab === 1 ? productsList : membersList).length === 0 ? "hidden" : "auto"}}>
    {selectedItem !== -1 && !loading && <div className="header-grid">
      <div>{activeTopBarTab === 1? 'Workspaces' : 'Users'}</div>
      <div>Roles</div>
      <div>Delete</div>
    </div>}
    {loading ? <div className="loader">
      <FontAwesomeIcon icon={faSpinner} spin={true} />  
    </div> :
    (activeTopBarTab === 1 ? productsList : membersList).length === 0 && <EmptyPlaceHolder 
      icon={<img src={NoMedia} alt="No Media"/>}
      text={activeTopBarTab === 1
        ? "There are no assigned workspaces currently, click on the button below to assign a workspace"
        : "There are no users in this workspace currently, click on the button below to add a user"}
      submitText={activeTopBarTab === 1? "Add workspace": "Add user"}
      isDisabled={!(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers)}
      onSubmit={()=>{
        if(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers){
          setAddMemberPopUpProps({
            type: activeTopBarTab === 1 ? "product" : "member",
            onCancel: () => {
              setAddMemberPopUpProps(null);
            },
            onSubmit: (data:any,callback:Function) => { 
              let {selectedId,role,text} = data;
              // callback(true)
              // dispatch(unlinkUserWithEntity({
              //   userId: activeTopBarTab === 1?  itemList[selectedItem]._id: selectedId,
              //   entityType: 'product',
              //   entityId: activeTopBarTab === 1? selectedId: itemList[selectedItem]._id 
              // },(res:any,err:boolean)=>{
              //   callback(false)
              //   if(!err){
                  // callback(true)
                  dispatch(linkUserWithEntity({
                    userId: activeTopBarTab === 1?  itemList[selectedItem]._id: selectedId,
                    entityType: 'product',
                    entityId: activeTopBarTab === 1? selectedId: itemList[selectedItem]._id,
                    role
                  },(res:any,err:boolean)=>{
                    // callback(false)
                    setAddMemberPopUpProps(null);
                    if(!err){
                      setCompletionPopUpState({	
                        ...completionPopUpState,	
                        isOpen: true,	
                        type: 0,	
                        title: `Great Job! ${activeTopBarTab === 1 ? itemList[selectedItem].email : text} was invited to workspace!`,	
                        subText:'',	
                        cancelButtonText: "Close"	
                      })
                      openToast('success',activeTopBarTab === 1 ?'Workspace added!':'User added!')
                      dispatch(setSelectedItem(-1));
                      dispatch(setSelectedItem(selectedItem));
                    }
                  }))
                }
          });
        }else{
          permissionDeniedClick()
        }
      }}
    />}
    {!loading && (activeTopBarTab === 1? productsList:membersList ).length > 0 &&<div className="body-grid">
    {/* <InfiniteScroll
      dataLength={(membersList).length}
      next={()=>{
        if(activeTopBarTab === 2)
        dispatch(fetchMembersList({entityType: "product",entityId: selectedEntityItemId,page: page+1},(response:any,error:boolean)=>{
          if(!error){
            dispatch(setMembersList([...membersList,...response.data.users]));
            setTotal(response.data.total);
            setPage(val=>val+1)
          }
        }))
      }}
      hasMore={activeTopBarTab === 2? (membersList).length < total : false}
      loader={<div className="loader-container"><div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div></div>}
      className="body-grid"
      scrollableTarget="table-content"
    > */}
      {loading? [] : (activeTopBarTab === 1? productsList:membersList ).map((data:any,index:number)=>(
        <React.Fragment key={data._id}>
          <div className="item detail">
            <Avatar 
              size="x-small"
              initials={activeTopBarTab === 2? (data.firstName ? data.firstName[0] : "" )
                :(data.name ? data.name[0]: "")} 
              showInitials={activeTopBarTab === 2? !!(data && !data.profilePicture):true}
              backgroundColor={activeTopBarTab === 2? data?.profileColour:""}
              src={activeTopBarTab === 2? (data.profilePicture?data.profilePicture:""):""}
            />
            <div className="name">
              {activeTopBarTab === 1 && data.name}
              {activeTopBarTab === 2 && data.firstName + " " + data.lastName}
            </div>
          </div>
          {/* <div className="input-field">
            <div className="item input-select" ref={elRefs[index]} onClick={()=>setOpen(index)}>
              <span>{getRoleName(data.role)}</span> 
              <span>
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.94 0.720215L4 3.78021L7.06 0.720215L8 1.66688L4 5.66688L0 1.66688L0.94 0.720215Z" fill="black"/>
                </svg>
              </span>
            </div>
            {updatedLoading === index && <span className="loader">
              <FontAwesomeIcon icon={faSpinner} spin />
            </span>}
          </div> */}
            <Dropdown trigger={['click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={
              <Menu className="dropdown-container">
              {rolesList.map((role:any,rolesindex:number)=>((rolesindex !== activeRole)&&
                  <MenuItem className={(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers)? "dropdown-item":"dropdown-item permission-denied-lock"} onClick={()=>{
                    setOpen(-1)
                    if (activeTopBarTab === 1 && HDFC_MFILTERIT_USER === itemList[selectedItem]._id) {
                      return openToast("error", "Restricted: Can not change role of Third party user");
                    }
                    if (activeTopBarTab === 2 && HDFC_MFILTERIT_USER === data._id) {
                      return openToast("error", "Restricted: Can not change role of Third party user");
                    }
                  if(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers){
                    setUpdateLoading(index)
                    dispatch(unlinkUserWithEntity({
                      userId: activeTopBarTab === 1?  itemList[selectedItem]._id: data._id,
                      entityType: 'product',
                      entityId: activeTopBarTab === 1? data._id: itemList[selectedItem]._id 
                    },(res:any,err:boolean)=>{
                      if(!err){
                        dispatch(linkUserWithEntity({
                          userId: activeTopBarTab === 1?  itemList[selectedItem]._id: data._id,
                          entityType: 'product',
                          entityId: activeTopBarTab === 1? data._id: itemList[selectedItem]._id,
                          role: role.roleId
                        },(res:any,err:boolean)=>{
                          setUpdateLoading(-1)
                          if(!err){
                            let arr = activeTopBarTab === 1?productsList:membersList;
                            arr[index]["role"] = role.roleId;
                            if(activeTopBarTab === 1){
                              dispatch(setProductsList(arr))
                              openToast('success','Product role changed!')
                            }else{
                              dispatch(setMembersList(arr))
                              openToast('success','Member role changed!')
                            }
                          }else{
                            openToast('error',res.data.message||"Something went wrong!")
                          }
                        }))
                      }else{
                        setUpdateLoading(-1)
                        openToast('error',res.data.message||"Something went wrong!")
                      }
                    }))
                  }else{
                    permissionDeniedClick()
                  }}}
                    key={role.roleId}
                  >
                    <span>
                      {role.name}
                    </span>
                  </MenuItem>
                ))}
              </Menu>
            }>
              <div className="item input-select" ref={elRefs[index]} onClick={()=>setOpen(index)}>
                <span>{getRoleName(data.role)}</span> 
                <span>
                  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.94 0.720215L4 3.78021L7.06 0.720215L8 1.66688L4 5.66688L0 1.66688L0.94 0.720215Z" fill="black"/>
                  </svg>
                </span>
              </div>
            </Dropdown>
          <div className="action-field">
          <div className={(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers)? "item action": "item action permission-denied-lock" }>
             <div className="deleteIconBtn" onClick={()=>{
                if (activeTopBarTab === 1 && HDFC_MFILTERIT_USER === itemList[selectedItem]._id) {
                  return openToast("error", "Restricted: Can not remove Third party user from a workspace");
                }
                if (activeTopBarTab === 2 && HDFC_MFILTERIT_USER === data._id) {
                  return openToast("error", "Restricted: Can not remove Third party user from a workspace");
                }
                if(activeTopBarTab === 1? canAddDeleteProducts: canManageUsers){
                  setOpenRemoveAlert(index);
                  setCompletionPopUpState({
                    ...completionPopUpState,
                    isOpen: true, 
                    type: 1,
                    title: `Remove ${(activeTopBarTab === 1 ? 'workspace':'the user')}?`,
                    subText: '',
                    cancelButtonText: "Cancel",
                    cancelButtonFunction:() => {
                      setOpenRemoveAlert(-1);
                    },
                    submitButtonText: "Delete",
                    submitButtonFunction: (setLoader:Function) => {
                      setLoader(true);
                      if (removeLoading === -1) {
                        let data = (activeTopBarTab === 1 ? productsList : membersList)[
                          index
                        ];
                        setRemoveLoading(index);
                        dispatch(
                          unlinkUserWithEntity(
                            {
                              userId:
                                activeTopBarTab === 1
                                  ? itemList[selectedItem]._id
                                  : data._id,
                              entityType: "product",
                              entityId:
                                activeTopBarTab === 1
                                  ? data._id
                                  : itemList[selectedItem]._id,
                            },
                            (res: any, err: boolean) => {
                              setLoader(false);
                              setRemoveLoading(-1);
                              if (!err) {
                                let arr =
                                  activeTopBarTab === 1 ? productsList : membersList;
                                arr.splice(index, 1);
                                if (activeTopBarTab === 1) {
                                  dispatch(setProductsList(arr));
                                  openToast("success", "Workspace removed!");
                                  setCompletionPopUpState({
                                    ...completionPopUpState,
                                    isOpen: true, 
                                    type: 0, 
                                    title: `Great Job! Workspace removed successfully`,
                                    subText:'',
                                    cancelButtonText: "Close",
                                    cancelButtonFunction: () => {setOpenRemoveAlert(-1);setCompletionPopUpState(null)}
                                  });
                                } else {
                                  dispatch(setMembersList(arr));
                                  openToast("success", "Member removed!");
                                  setCompletionPopUpState({
                                    ...completionPopUpState,
                                    isOpen: true, 
                                    type: 0, 
                                    title: `Great Job! User removed successfully`,
                                    subText:'',
                                    cancelButtonText: "Close",
                                    cancelButtonFunction: () => {setOpenRemoveAlert(-1);setCompletionPopUpState(null)}
                                  });
                                }
                              } else {
                                setOpenRemoveAlert(-1);
                                openToast(
                                  "error",
                                  res.data.message || "Something went wrong!"
                                );
                              }
                            }
                          )
                        );
                      }
                    }
                  })
                }else{
                  permissionDeniedClick()
                }
              }}>
              <DeleteIcon /> 
            </div>
          </div>
          {removeLoading === index && <span className="loader">
            <FontAwesomeIcon icon={faSpinner} spin />
          </span>}
          </div>
        </React.Fragment>
      ))}
    {/* </InfiniteScroll> */}
    </div>}
    {openRemoveAlert !== -1 && <CommonSuccessWarnDrawer {...completionPopUpState} handleClose={()=> setCompletionPopUpState(null)}/>}
    {completionPopUpState && completionPopUpState.isOpen && <CommonSuccessWarnDrawer {...completionPopUpState} handleClose={()=> setCompletionPopUpState(null)}/>}
    {addMemberPopUpProps && (<AddMemberDrawer {...addMemberPopUpProps} />)}
  </div>
}

export default TableContent;