import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import { Selection } from '../../components/HyperPersonalizeSection';

import './index.scss';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const HyperPersonlizeSelect: FC<any> = () => {

  const SuspenseComponent = () => {
    return (
      <>
        <div className="hyper-personalize-select-wrap">
          <div className="flex h-100">
            <Selection />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default HyperPersonlizeSelect;

