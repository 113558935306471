import React, { useEffect, useState } from "react";
import { Popover, Input } from "antd";
import "react-calendar/dist/Calendar.css";
// import CalendarIcon from '../../../../assets/icons/Calendar.svg';
import RightOutlined from "@ant-design/icons/RightOutlined";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import { ReactComponent as CalenderIcon } from "../../assets/svg/calender-icon.svg";
import { ReactComponent as CalenderIconSuffix } from "../../assets/svg/calender-icon-suffix.svg";
import { getFormattedDate } from "./utils";
import Calendar from "react-calendar";
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ReactComponent as iconRemove } from "../../assets/svg/vle-delete.svg";
import { DownOutlined } from "@ant-design/icons";
const timeFormat = {
  hour: "2-digit",
  minute: "2-digit",
  day: "numeric",
  month: "short",
  year: "numeric",
} as Intl.DateTimeFormatOptions;
const Window = ({
  onChange,
  setPanel,
  setLocalValue,
  localValue,
  panel,
  setActiveStartDate,
  mode,
  setCurrentView,
}: {
  mode?: Mode;
  localValue: any;
  value?: any;
  panel: number;
  onChange?: any;
  setPanel?: any;
  setLocalValue: any;
  setActiveStartDate?:any;
  setCurrentView?:any;
}) => {
  const [highlightedDate,setHighLightedDate] = useState(0)
  const customDates = [
    { title: "Past 7 Days", value: [dayjs().subtract(7, 'd'), dayjs().subtract(1, 'd')] },
    { title: "Past 14 Days", value: [dayjs().subtract(14, 'd'), dayjs().subtract(1, 'd')] },
    { title: "Past 30 Days", value: [dayjs().subtract(30, 'd'), dayjs().subtract(1, 'd')] },
    { title: "Past 45 Days", value: [dayjs().subtract(45, 'd'), dayjs().subtract(1, 'd')] },
    { title: "Past 3 Months", value: [dayjs().subtract(3, 'M'), dayjs().subtract(1, 'd')] },
    { title: "Past 6 Months", value: [dayjs().subtract(6, 'M'), dayjs().subtract(1, 'd')] },
  ];
  return (
    <div className='date-form'>
      <div
        className={`calnder ${
          !highlightedDate ?  "calnder-first" : "calnder-nth"
        }`}
        onClick={() =>{
          setHighLightedDate(0);
          onChange([undefined, undefined]);
          setActiveStartDate(true);
          setCurrentView("month");
          }  }
      >
        Custom
      </div>
      {customDates.map((date,index) => (
        <div className={`calnder ${
          highlightedDate===index+1?   "calnder-first" : "calnder-nth"
        }`} onClick={() =>{
        setHighLightedDate(index+1);
         onChange(date.value)
         setActiveStartDate(true);
         setCurrentView("month")
         }}>
          {date.title}
        </div>
      ))}
    </div>
  );
};
type Mode = "single" | "double" | "both";
const defaultPopoverContainer = (trigger) => trigger;
export const DateFilter = ({
  value,
  onChange,
  mode = "single",
  className,
  placeholder = "Custom",
  questionnaire,
  
  getPopupContainer = defaultPopoverContainer,
}: {
  value?: any;
  onChange?: any;
  mode?: Mode;
  className?: string;
  placeholder?: string;
  questionnaire?: any;
  
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}) => {
  const [panel, setPanel] = useState(0);
  const [localValue, setLocalValue] = useState(
    value ||
      (mode === "double"
        ? [undefined, undefined, undefined, undefined]
        : [undefined, undefined])
  );
  const [activeStartDate1, setActiveStartDate]= useState(false)
  const [currentDate, setCurrentDate]= useState(new Date())
  const [currentView, setCurrentView]= useState<any>("month")
  const [finalValue, setFinalValue] = useState(value || "");
  const [visible, setVisible] = useState(false);
  console.log("activeStartDate1",activeStartDate1)
  useEffect(() => {
    setLocalValue(
      value
        ? value.map((date) => new Date(date))
        : mode === "double"
        ? [undefined, undefined, undefined, undefined]
        : [undefined, undefined]
    );
  }, [mode, value]);
  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      visible={visible}
      className='popover'
      onVisibleChange={setVisible}
      getPopupContainer={getPopupContainer}
      content={
        <div className='styled-date'>
          <Window
            setPanel={setPanel}
            panel={panel}
            onChange={(v) => {
              // setVisible(false);
              setLocalValue(v[0]?v.map((date) => new Date(date)):[undefined, undefined]);
              setFinalValue(v[0]?v.map((date) => new Date(date)):"");
              setActiveStartDate(true)
              setCurrentView("month")
              // onChange(v);
            }}
            localValue={localValue}
            setLocalValue={setLocalValue}
            mode={mode}
            setActiveStartDate={setActiveStartDate}
            setCurrentView={setCurrentView}
          />
          <div className='calender-column'>
        {activeStartDate1? <Calendar
              className='calender-css'
              showNeighboringMonth={true}
              calendarType="gregory"
              next2Label={null}
              view={currentView}
              prev2Label={null}
              prevLabel={<LeftOutlined />}
              nextLabel={<RightOutlined />}
              minDate={!!questionnaire && new Date(questionnaire.startTime)}
              maxDate={!!questionnaire && new Date(questionnaire.endTime)}
              showDoubleView={true}
              selectRange={true}
              formatShortWeekday={(_, l) => {
                return l
                  .toLocaleString("en-us", { weekday: "long" })
                  .substring(0, 1);
              }}
              formatMonth={(_, l) => {
                return l
                  .toLocaleString("en-us", { month: 'short', year: 'numeric' })
                  .substring(0, 3);
              }}
              onViewChange={({ action, activeStartDate, value, view } )=>{
              // setCurrentView(value);
              // setCurrentDate(new Date(activeStartDate));
              setActiveStartDate(false);
              return value;
                   
              }}
              onActiveStartDateChange={({ action, activeStartDate, value, view } )=>{
              // setCurrentView(value);
              // setCurrentDate(new Date(activeStartDate));
              setActiveStartDate(false);
              return value;
                   
              }}
              activeStartDate={currentDate}
              onChange={(obj) => {
                const temp: any = [...localValue];
                temp[2 * panel] = obj[0];
                temp[2 * panel + 1] = obj[1];
                setLocalValue(temp);
              }}
              

              value={[localValue?.[2 * panel], localValue?.[2 * panel + 1]]}
             
            />:    <Calendar
              className='calender-css'
              showNeighboringMonth={true}
              calendarType="gregory"
              next2Label={null}
              prev2Label={null}
              prevLabel={<LeftOutlined />}
              nextLabel={<RightOutlined />}
              formatMonth={(_, l) => {
                return l
                  .toLocaleString("en-us", { month: 'short', year: 'numeric' })
                  .substring(0, 3);
              }}
              minDate={!!questionnaire && new Date(questionnaire.startTime)}
              maxDate={!!questionnaire && new Date(questionnaire.endTime)}
              showDoubleView={true}
              selectRange={true}
              formatShortWeekday={(_, l) => {
                return l
                  .toLocaleString("en-us", { weekday: "long" })
                  .substring(0, 1);
              }}
              onChange={(obj) => {
                const temp: any = [...localValue];
                temp[2 * panel] = obj[0];
                temp[2 * panel + 1] = obj[1];
                setLocalValue(temp);
              }}
              

              value={[localValue?.[2 * panel], localValue?.[2 * panel + 1]]}
             
            />}

            <div className="footer-calender">
              <div className="footer-left-content">
                <Input className="input-content" value={getFormattedDate(localValue[0])}/>
                <span>-</span>
                <Input className="input-content"value={getFormattedDate(localValue[1])}/>
              </div>
              <div className="footer-right-content">
                <button onClick={() =>{
                  setVisible(false);}} className='cancel-button'>
                    Cancel
                </button>
              <button
                className='apply-button'
                disabled={
                  localValue[0] === undefined ||
                  (localValue.length === 4 && localValue[2] === undefined)
                }
                onClick={() =>{
                  setVisible(false);
                  setFinalValue(localValue[0]?localValue
                    .filter((date) => date)
                    .map((date, index) =>
                      new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        index % 2 === 0 ? 0 : 23,
                        index % 2 === 0 ? 0 : 59,
                        index % 2 === 0 ? 0 : 59,
                        index % 2 === 0 ? 0 : 999
                      ).toISOString()
                    ):"");
                  onChange(
                    localValue
                      .filter((date) => date)
                      .map((date, index) =>
                        new Date(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                          index % 2 === 0 ? 0 : 23,
                          index % 2 === 0 ? 0 : 59,
                          index % 2 === 0 ? 0 : 59,
                          index % 2 === 0 ? 0 : 999
                        ).toISOString()
                      )
                  );
                }
                }
              >
                Apply
              </button>
              </div>
             
            </div>
          </div>
        </div>
      }
    >
      <div className="date-picker-container">
      <div className="date-picker-child1">
      <CalenderIcon />
        <span>{
           finalValue
           ? `${getFormattedDate(finalValue[0])}-${getFormattedDate(
               finalValue[1]
             )}`
           : "Custom"
          }</span>

      </div>
      <div className="date-picker-child2">
        <CalenderIconSuffix/>
      </div>
      </div>
      {/* <Input
        className='popver-input'
        readOnly={true}
        style={{ width: "27.7rem" }}
        placeholder={placeholder}
        prefix={<CalenderIcon onClick={()=>setVisible(true)}/>}
        suffix={<CalenderIconSuffix onClick={()=>setVisible(true)} />}
        allowClear={true}
        onChange={(e) => {
          if (e.target.value) return;
          onChange("");
          setFinalValue("");
          setLocalValue(
            mode === "single"
              ? [undefined, undefined]
              : [undefined, undefined, undefined, undefined]
          );
        }}
        value={
          finalValue
            ? `${getFormattedDate(finalValue[0])}-${getFormattedDate(
                finalValue[1]
              )}${
                finalValue[3]
                  ? ` TO ${getFormattedDate(finalValue[2])}-${getFormattedDate(
                      finalValue[3]
                    )}`
                  : ""
              }`
            : ""
        }
      /> */}
    </Popover>
  );
};
