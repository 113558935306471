import React, { useState, useEffect, useCallback, useRef } from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faEye,
  faEyeSlash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { RolesState } from "../../../store/types";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMembersList,
  fetchProductList,
  createUser,
  fetchOthersProductList,
} from "../../../store/actions";
import { ClickAwayListener } from "@material-ui/core";
import { openToast } from "../../../Toasts";
import Avatar from "../../shared/Avatar";
import {
  csvJSON,
  isValidEmail,
  isValidName,
  isValidPhone,
  isValidPhoneNo,
} from "../../../utilities/common-function";
import CommonDrawerComponent from "../../shared/CommonDrawer";
import SuccessDrawer from "../../shared/CommonSuccessWarnDrawer";
import { Input, Button, Dropdown, Menu, MenuItem } from 'pyxis-ui-kit';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { HDFC_MFILTERIT_USER } from "../../../utilities/paths";

interface AddMemberDrawerInterface {
  type: string;
  initailStep?: number;
  onCancel: Function;
  onSubmit: Function;
}

const ChooseEmailAndRole: React.FunctionComponent<{
  step: number;
  setStep: Function;
  type: string;
  initailStep: number;
  onCancel: Function;
  onSubmit: Function;
  setUserData: Function;
  initialEmail: string;
  initialSelectedId: string;
}> = ({
  step,
  setStep,
  type,
  initailStep,
  onCancel,
  onSubmit,
  setUserData,
  initialEmail,
  initialSelectedId,
}) => {
  const state = useSelector((state: { roles: RolesState }) => state.roles);
  const [text, setText] = useState(initialEmail);
  const [isEmailValid, setEmailStatus] = useState(true)
  const [isRoleDropdown, setRoleDropdown] = useState(false);
  const [role, setRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedId, setSelectedId] = useState(initialSelectedId);
  const [isMemberDropdown, setMemberDropdown] = useState(false);
  const [dropdownItemList, setDropdownItemList] = useState([]);
  const [userWorkspace,setUserWorkspace]=useState([])
  const [isFocussed, setFocus] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const rolesHashRef = useRef(new Map());
  const { selectedItem,itemList,rolesList,brand_id } = state;
  const selectedEntityItemId = (itemList.length > 0 && selectedItem > -1)? itemList[selectedItem]._id:null;
  const list = dropdownItemList.filter((data: any) => type === "member"
      ? data.email.toLowerCase().indexOf(text.trim().toLowerCase()) > -1 ||
        (data.firstName + " " + data.lastName).toLowerCase().indexOf(text.trim().toLowerCase()) > -1
      // : data.name.toLowerCase().indexOf(text.trim().toLowerCase()) > -1
      : data.name
  );
  const [loading, setLoader] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    rolesList.map((r:any) => {
      rolesHashRef.current.set(r.roleId, r.name);
    });
  }, [rolesList]);

  const getRoleName = useCallback((role:string) => {
      return rolesHashRef.current.get(role) || role.split("-").join(" ");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rolesHashRef.current]
  );

  useEffect(() => {
    if(type === "member" && step === 0 && brand_id) {
      dispatch(fetchMembersList({ entityType: "brand", entityId: brand_id },
        (response:any, error:boolean) => {
          if(!error) {
            let users = response.data.users.filter(
              (u:any) => u.role === "brand-user"
            );
            // hiding third party user mfilterit
            let updatedUsers = users.filter(({ _id }) => ![HDFC_MFILTERIT_USER].includes(_id));
            setDropdownItemList(updatedUsers);
          }
        }
      ))
    }
    if(type === "product" && step === 0) {
      dispatch(fetchProductList({ entityType: "product" },
        (response:any, error:boolean) => {
          if(!error) {
            setDropdownItemList(response.data.products);
          }
        }
      ))
      dispatch(fetchOthersProductList({userId:selectedEntityItemId,level:"brand",entityId: brand_id },(response:any,error:boolean)=>{
        if(!error){
          setUserWorkspace(response.data.products)
        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function validate() {
    if(step === 0) {
      if(!text.trim() || !role) {
        openToast("error", "One or more fields are empty");
        return false;
      }
      if(!isValidEmail(text)) {
        openToast("error", "Email Id is invalid");
        return false;
      }
    } else if(step === 1) {
      if(!text.trim() || !firstName.trim() || !lastName.trim()) {
        openToast("error", "One or more fields are empty");
        return false;
      }
      if(!isValidEmail(text)) {
        openToast("error", "Email Id is invalid");
        return false;
      }
      if(!isValidName(firstName)) {
        openToast("error", "First name is invalid");
        return false;
      }
      if(!isValidName(lastName)) {
        openToast("error", "Last name is invalid");
        return false;
      }
      if(phoneNumber && !isValidPhoneNo(phoneNumber)) {
        openToast("error", "Phone number is invalid");
        return false;
      }
    }
    return true;
  }

  return (
    <CommonDrawerComponent
      title={step === 0 ? "Add user to workspace" : "Invite Users"}
      subText={step === 0 ? "User will be able to view, edit and manage workspaces based on the roles assigned to them." : "Invite your peers to join you on Pixis CoCreate"}
      cancelButtonText="Cancel and return"
      submitButtonText="Add"
      cancelButtonFunction={() => onCancel && onCancel()}
      submitButtonFunction={() => {
        if(onSubmit) {
          if(step === 0) {
            onSubmit({ selectedId, role, text });
          }
          if(step === 1 && brand_id && validate()) {
            setLoader(true);
            dispatch(createUser({
              firstName,
              lastName,
              username: firstName + "-" + lastName,
              phoneNumber,
              email: text,
              role: "brand-user",
              entityType: "brand",
              entityId: brand_id,
            }, (res:any, err:boolean) => {
              setLoader(false);
              if(!err) {
                let users:any = res && res.userList && res.userList.length > 0
                  ? res.userList
                  : csvJSON(res);
                if(users.length > 0) {
                  setUserData(users[0]);
                  if(initailStep === 1) onSubmit && onSubmit(users[0]);
                  setStep(2);
                } else {
                  openToast("error", "Unable to Invite");
                }
              } else {
                openToast("error", res.message);
              }
            }
          ))}
        }
      }}
      isFormValid={step === 0
        ? (type === "member"
          ? text &&
            role &&
            isValidEmail(text) &&
            selectedId &&
            !!dropdownItemList.find((a: any) => a._id === selectedId)
          : text &&
            role &&
            selectedId &&
            !!dropdownItemList.find((a: any) => a._id === selectedId))
          ? true
          : false
        : text && firstName && lastName && isValidEmail(text)
        ? true
        : false
      }
      loading={loading}
      isAddMember={step === 0 ? true : false}>
      <div className="add-member-popup">
        <div className="body">
          <div className="input-field">
            <div>
              <label className="add-member-label">
                {type === "member" ? "Email ID" : "Workspace Name"}
              </label>
            </div>
            <div className="inputFieldDiv">
              {type === "member" && <Input
                key="somethingUnique"
                className={ isEmailValid ? "" : "errorInput" }
                autoFocus
                type="email"
                value={text}
                placeholder="Enter user's email"
                onFocus={(e:any) => {
                  setFocus(true);
                  setMemberDropdown(true);
                }}
                onBlur={() => {
                  setFocus(false);
                  if(type === "member" && text.length > 0) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    setEmailStatus(re.test(String(text).toLowerCase()));
                  }
                }}
                onChange={(e:any) => {
                  if(!isMemberDropdown && step === 0) {
                    setMemberDropdown(true);
                  }
                  if(selectedId) {
                    setSelectedId("");
                  }
                  setText(e.target.value);
                }}
                onPressEnter={() => {
                  if(onSubmit) {
                    if(step === 0 && validate()) {
                      onSubmit({ selectedId, role, text });
                    }
                    if(step === 1 && brand_id && validate()) {
                      setLoader(true);
                      dispatch(createUser({
                        firstName,
                        lastName,
                        username: firstName + "-" + lastName,
                        phoneNumber,
                        email: text,
                        role: "brand-user",
                        entityType: "brand",
                        entityId: brand_id,
                      }, (res:any, err:boolean) => {
                        setLoader(false);
                        if(!err) {
                          let users:any = res && res.userList && res.userList.length > 0
                            ? res.userList
                            : csvJSON(res);
                          if(users.length > 0) {
                            setUserData(users[0]);
                            if(initailStep === 1) onSubmit && onSubmit(users[0]);
                            setStep(2);
                          } else {
                            openToast("error", "Unable to Invite!");
                          }
                        } else {
                          openToast("error", res.message);
                        }
                      }
                    ))}
                  }
                }} />
              }
              {type === "member" ? isEmailValid === false ? <div className="errorMessage">Enter a valid email</div> : "" : ""}
              {type === "member" && step === 0 && (
                <span>
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              )}
            </div>
            {type === "member" ?
              <ClickAwayListener
                onClickAway={() => {
                  !isFocussed  && setMemberDropdown(false);
                }}>
                <span>
                  {isMemberDropdown && step === 0 && (
                    <>
                      <div className="dropdown-container">
                        {list.length === 0 && text.length > 0 && (
                          <div className="invite" onClick={() => setStep(1)}>
                            <div className="icon">
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                            <span className="text">{`Invite "${text}"`}</span>
                          </div>
                        )}
                        {list.map((data: any, index: number) => (
                          <div
                            className="dropdown-item"
                            key={data._id}
                            onClick={() => {
                              setSelectedId(data._id);
                              setText(type === "member" ? data.email : data.name);
                              setMemberDropdown(false);
                            }}>
                            <Avatar
                              size="x-small"
                              initials={data.firstName ? data.firstName[0] : ""}
                              showInitials={!!(data && !data.profilePicture)}
                              backgroundColor={data?.profileColour}
                              src={data.profilePicture ? data.profilePicture : ""} />
                            <div className="detail">
                              <div className="name">{data.firstName + " " + data.lastName}</div>
                              <div className="email">{data.email}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </span>
              </ClickAwayListener> :
              <Dropdown trigger={['click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={
                <Menu className="dropdown-container">
                  {list.filter((workspace:any)=>!userWorkspace.find((userWorkspace:any)=>userWorkspace._id===workspace._id)).map((data: any, index: number) => (
                    <MenuItem
                      className="dropdown-item"
                      key={data._id}
                      onClick={() => {
                        setSelectedId(data._id);
                        setText(data.name);
                        setMemberDropdown(false);
                      }}>
                      <Avatar
                        size="x-small"
                        initials={data.name ? data.name[0] : ""}
                        showInitials={true} />
                      <div className="detail">
                        <div className="name">{data.name}</div>
                      </div>
                    </MenuItem>
                  ))}
                </Menu>
              }>
                <div className="select">
                  <span className={text ? "" : "placeholder"}>{text ? text : "Select Workspace"}</span>
                  <span>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </div>
              </Dropdown>}
          </div>
          {step === 0 && (
            <div className="input-field">
              <label className="add-member-label">User Role</label>
              {/* {isRoleDropdown && (
                <ClickAwayListener
                  onClickAway={() => {
                    setRoleDropdown(false);
                  }}
                > */}
                <Dropdown trigger={['click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={
                  <Menu className="dropdown-container">
                    {rolesList.map((role: any, index: number) => (
                      <MenuItem
                        key={role.roleId}
                        className="dropdown-item"
                        onClick={() => {
                          setRole(role.roleId);
                          setRoleDropdown(false);
                        }}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Menu>
                }>
                  <div className="select" onClick={() => setRoleDropdown(true)}>
                    <span className={role ? "" : "placeholder"}>{role ? getRoleName(role) : "Select member's role"}</span>
                    <span>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </div>
                </Dropdown>
                {/* </ClickAwayListener> */}
              {/* )} */}
            </div>
          )}
          {step === 1 && (
            <>
              <div className="input-field">
                <label className="add-member-label">First name</label>
                <Input
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e:any) => {
                    setFirstName(e.target.value);
                  }}
                  onPressEnter={() => {
                    if(onSubmit) {
                      if(step === 1 && brand_id && validate()) {
                        setLoader(true);
                        dispatch(createUser({
                          firstName,
                          lastName,
                          username: firstName + "-" + lastName,
                          phoneNumber,
                          email: text,
                          role: "brand-user",
                          entityType: "brand",
                          entityId: brand_id,
                        }, (res:any, err:boolean) => {
                          setLoader(false);
                          if(!err) {
                            let users:any = res && res.userList && res.userList.length > 0
                              ? res.userList
                              : csvJSON(res);
                            if(users.length > 0) {
                              setUserData(users[0]);
                              if(initailStep === 1) onSubmit && onSubmit(users[0]);
                              setStep(2);
                            } else {
                              openToast("error", "Unable to Invite!");
                            }
                          } else {
                            openToast("error", res.message);
                          }
                        }
                      ))}
                    }
                  }}
                />
              </div>
              <div className="input-field">
                <label className="add-member-label">Last name</label>
                <Input
                  type="text"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e:any) => {
                    setLastName(e.target.value);
                  }}
                  onPressEnter={() => {
                    if(onSubmit) {
                      if(step === 1 && brand_id && validate()) {
                        setLoader(true);
                        dispatch(createUser({
                          firstName,
                          lastName,
                          username: firstName + "-" + lastName,
                          phoneNumber,
                          email: text,
                          role: "brand-user",
                          entityType: "brand",
                          entityId: brand_id,
                        }, (res:any, err:boolean) => {
                          setLoader(false);
                          if(!err) {
                            let users:any = res && res.userList && res.userList.length > 0
                              ? res.userList
                              : csvJSON(res);
                            if(users.length > 0) {
                              setUserData(users[0]);
                              if(initailStep === 1) onSubmit && onSubmit(users[0]);
                              setStep(2);
                            } else {
                              openToast("error", "Unable to Invite!");
                            }
                          } else {
                            openToast("error", res.message);
                          }
                        }
                      ))}
                    }
                  }}
                />
              </div>
              <div className="input-field">
                <label className="add-member-label">Phone Number</label>
                <PhoneInput
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
                {/* <Input
                  type="text"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e:any) => {
                    setPhoneNumber(e.target.value);
                  }}
                  onPressEnter={() => {
                    if(onSubmit) {
                      if(step === 1 && brand_id && validate()) {
                        setLoader(true);
                        dispatch(createUser({
                          firstName,
                          lastName,
                          username: firstName + "-" + lastName,
                          phoneNumber,
                          email: text,
                          role: "brand-user",
                          entityType: "brand",
                          entityId: brand_id,
                        }, (res:any, err:boolean) => {
                          setLoader(false);
                          if(!err) {
                            let users:any = res && res.userList && res.userList.length > 0
                              ? res.userList
                              : csvJSON(res);
                            if(users.length > 0) {
                              setUserData(users[0]);
                              if(initailStep === 1) onSubmit && onSubmit(users[0]);
                              setStep(2);
                            } else {
                              openToast("error", "Unable to Invite!");
                            }
                          } else {
                            openToast("error", res.message);
                          }
                        }
                      ))}
                    }
                  }}
                /> */}
              </div>
            </>
          )}
        </div>
      </div>
    </CommonDrawerComponent> 
  );
};

const UserAddedPropy: React.FunctionComponent<{
  onCancel: Function;
  userData: any;
  initailStep: number;
  setStep: Function;
}> = ({ onCancel, initailStep, userData, setStep }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopy] = useState(false);
  const { password } = userData;
  
  const copy = useCallback(() => {
    navigator.clipboard.writeText(password).then(() => {
        setIsCopy(true);
      }, (err) => {
        console.log("Some issue while copying text!");
      }
    );
  }, [password]);

  return (
    <SuccessDrawer
      type={0}
      title='Great Job! New user added successfully'
      subText=""
      cancelButtonText="Close"
      cancelButtonFunction={() => {
        if(initailStep === 1) {
          onCancel && onCancel();
        } else {
          setStep(0);
        }
      }}>
      <div className="password">
        <div id="passwordDiv">Password</div>
        <div>
          <div className="copy">
            <Input
              type={isVisible ? "text" : "password"}
              value={password}
              readOnly />
            <span onClick={() => setIsVisible((val) => !val)}>
              <FontAwesomeIcon icon={!isVisible ? faEye : faEyeSlash} />
            </span>
          </div>
          <Button className="secondary" type="link" onClick={() => copy()}>
            {isCopied ? "Copied credentials" : "Copy credentials"}
          </Button>
        </div>
      </div>
    </SuccessDrawer>
  );
};

const AddMemberDrawer: React.FC<AddMemberDrawerInterface> = ({
  type,
  initailStep = 0,
  onCancel,
  onSubmit,
}) => {
  const [step, setStep] = useState(initailStep);
  const [userData, setUserData] = useState<any>(null);

  switch (step) {
    case 0:
    case 1:
      return (
        <ChooseEmailAndRole
          step={step}
          setStep={setStep}
          onCancel={onCancel}
          onSubmit={onSubmit}
          initailStep={initailStep}
          setUserData={setUserData}
          initialEmail={!!userData ? userData.email : ""}
          initialSelectedId={!!userData ? userData._id : ""}
          type={type} />
      );
    case 2:
      return (
        <UserAddedPropy
          onCancel={onCancel}
          userData={userData}
          setStep={setStep}
          initailStep={initailStep} />
      );
  }
  return null;
};

export default AddMemberDrawer;