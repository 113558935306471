import { DELETE_DROP_SRC, SET_DROP_SRC, DeleteDropSrcAction, SetDropSrcAction } from '../types';

export const deleteSrcAction = (): DeleteDropSrcAction => ({
  type: DELETE_DROP_SRC,
  payload: {
    dropImageSrc: false,
  },
});

export const setDropSrcAction = (dropImageSrc: string): SetDropSrcAction => ({
  type: SET_DROP_SRC,
  payload: {
    dropImageSrc,
  },
});
