export const LOGIN = "LOGIN"; 
export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";
export const SET_TOKEN = "SET_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT = "LOGOUT";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const FETCH_ACCESS_TOKEN = "FETCH_ACCESS_TOKEN";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const AUTHORIZE = "AUTHORIZE";
export const SEND_RESET_LINK = "SEND_RESET_LINK";

export type AuthState = {
  isLoggedIn: boolean,
  token: string|null,
  accessToken: string|null,
  userData: UserData|null
}

export type UserData = {
  active: boolean;
  emailId: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  profileColour: string;
  profilePicture: string;
  token: string;
  userId: string;
  username: string;
}

export type SetIsLoggedIn = {
  type: typeof SET_IS_LOGGED_IN,
  payload: boolean
}

export type SetToken = {
  type: typeof SET_TOKEN,
  payload: string|null
}

export type Login = {
  type: typeof LOGIN,
  payload: {
    email: string,
    password: string
  },
  callback?:Function
}

export type SetUserData = {
  type: typeof SET_USER_DATA,
  payload: UserData|null
}

export type Logout = {
  type: typeof LOGOUT
}

export type SetAccessToken = {
  type: typeof SET_ACCESS_TOKEN,
  payload: string|null;
}

export type FetchAccessToken = {
  type: typeof FETCH_ACCESS_TOKEN,
  callback?:Function
}

export type UpdateProfile = {
  type: typeof UPDATE_PROFILE,
  payload: {
    firstName?: string;
    lastName?: string;
    newPassword?: string;
    phoneNumber?: string;
    profileColour?: string;
    confirmPassword?: string;
    currentPassword?: string;
    profilePicture?: File;
    username?: string;
  },
  callback?: Function
}

export type Authorize = {
  type: typeof AUTHORIZE,
  payload: {
    token: string;
  },
  callback?: Function;
}

export type CallbackFunctionAPI = (response: any, error: Boolean) => void;

export type SendResetLinkPayload = {
  email: string;
}
export type SendResetLink = {
  type: typeof SEND_RESET_LINK,
  payload: SendResetLinkPayload,
  callback?: CallbackFunctionAPI;
}