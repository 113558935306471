import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios'
import { setTemplatesAction, setTemplateCategoriesAction } from "../actions";
import { FETCH_TEMPLATES, FetchTemplatesAction, FETCH_TEMPLATES_CATEGORIES, FetchTemplateCategoriesAction, LOADER_OFF } from "../types";
import { API_URL, SUB_KEY, TOKEN } from '../../utilities/paths';
import { isNil, omit } from 'lodash';

const formParams = (paramMap: [any, any][]) => paramMap.reduce((acc, [key, val]) => isNil(val) ? acc : `${acc}&${key}=${val}`, '')

function* fetchTemplates(action: FetchTemplatesAction) {
    if (action.payload.page >( action.payload.totalPages || 1)) {
        return
    }
    const endpoint = `/template?type=${action.payload.type}`

    try {
        const response: any = yield axios.get(`${API_URL}${endpoint}${formParams(Object.entries(omit(action.payload, 'totalPages')))}`, {
            headers: {
                'Ocp-Apim-Subscription-Key': SUB_KEY,
                token: TOKEN,
            },
        });
        const hasError = response.data || response.data.error;

        if (!hasError) {
            setTemplatesAction(
                hasError ? [] : response.data.data.results,
                hasError ? 1 : response.data.data.current_page,
                hasError ? 1 : response.data.data.pages,
                hasError ? '' : action.payload.search,
                action.payload.type
            )
        } else {
          yield put({type: LOADER_OFF});
        }
      } catch (error) {
        alert('Something went wrong while fetching templates')
        yield put({type: LOADER_OFF});
    }
}

function* fetchTemplateCategories(action: FetchTemplateCategoriesAction) {
    const endpoint = `/template/category?page=${action.payload.page}`;

    try {
        const response: any = yield axios.get(`${API_URL}${endpoint}`, {
            headers: {
                'Ocp-Apim-Subscription-Key': SUB_KEY,
                token: TOKEN,
            },
        });
    
        const hasError = response.data || response.data.error;

        if (!hasError) {

            setTemplateCategoriesAction(
                hasError ? [] : response.data.data.categories,
                hasError ? 1 : response.data.data.count,
                hasError ? 1 : response.data.data.current_page,
                hasError ? 1 : response.data.data.page_size,
                hasError ? 1 : response.data.data.pages,
            )
        } else {
          yield put({type: LOADER_OFF});
        }
      } catch (error) {
        alert('Something went wrong while fetching templates categories');
        yield put({type: LOADER_OFF});
    }
}


export function* getTemplatesWatcher() {
    yield takeLatest(FETCH_TEMPLATES, fetchTemplates);
    yield takeLatest(FETCH_TEMPLATES_CATEGORIES, fetchTemplateCategories);
}
