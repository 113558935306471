const prompts = {
    "EDUCATION_LOAN": {
        "Female": {
            "20s": [
                {
                    "label": "Ad Concept _ Edu Loan - 1 _ Happy Indian Student _ International University _ YA Girl",
                    "value": "A confident 21-year-old, female Indian student in casual clothes stands in front of a prestigious foreign university, holding a notebook in her hand. She gazes at the camera with ambition and joy, with foreign students in soft focus behind her. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 2 _ Happy Indian Student _ University _ YA Girl",
                    "value": "A female Indian student, 20 years old, dressed in indigo blue standing in front of an educational university, holding a book in her hand, looking at the camera, feeling ambitious, happy and confident, background blurred, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 3 _ Happy Indian Student _ Library _ YA Girl",
                    "value": "A female Indian student, aged 18 years, she is casually dressed, holding a book, and sitting inside a college library, she is ready to embrace learning with determination and her dress toned blue and brown in color, feeling happy, looking at the camera, white lighting, background blurred, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 4 _ Happy Indian Student _ Yard _ YA Girl",
                    "value": "A confident 19-year-old Indian female student casually dressed in a white top and blue jeans, sitting comfortably on a green meadow under a tree with her two peers on either side, in front of a blurred university background, she holds a book in her hand and is looking directly at the camera. The focus is solely on the Indian student, her peers and the background are not visible. The scene is lit with white lighting. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 5 _ Graduation _ YA Girl",
                    "value": "A 25-year-old female Indian student, in her graduation attire, standing in front of a college building, with a beaming smile, proudly showing us her hard-earned degree in front of the camera. The overall image is captured daylight and lit.  Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }

            ],
            "30s": [
                {
                    "label": "Ad Concept _ Edu Loan - 12 _ Happy Indian Student _ International University _ Adult - Female",
                    "value": "A confident 31-year-old female Indian student, with straight black hair, in casual clothes stands in front of a prestigious foreign university, holding a notebook in her hand. She gazes at the camera with ambition and joy, with foreign students behind her, background blurred, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 13 _ Happy Indian Student _ University _ Adult - Female",
                    "value": "A female Indian student, 30 years old, standing in front of an educational university, holding a book in her hand, feeling ambitious and confident, background slightly blurred, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 14 _ Happy Indian Student _ Library _ Adult - Female",
                    "value": "A happy female Indian student, aged 30 years, she is casually dressed, holding a book, and sitting inside a college library. She is ready to embrace learning with enthusiasm and determination and her dress toned blue in color. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/4.5, ISO 200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 15 _ Happy Indian Student _ Yard _ Adult - Female",
                    "value": "A female Indian student, 30 years old, casually dressed, feeling ambitious, happy and confident, sitting in front of a university front with a book in her hand, along with few students, background blurred, and her uniform is in light blue tone. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 16 _ Graduation _ Adult - Female",
                    "value": "A 35-year-old female Indian student, in her graduation attire, standing in front of a college building, with a beaming smile, proudly showing us her hard-earned degree in front of the camera. The overall image is captured daylight and lit.  Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        },
        "Male": {
            "20s": [
                {
                    "label": "Ad Concept _ Edu Loan - 6 _ Graduation _ YA Boy",
                    "value": "A 25-year-old male Indian student, in graduation attire toned blue, standing in front of a college building, with a beaming smile, proudly showing us his hard-earned degree in front of the camera. The overall image is captured in daylight and lit. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 7 _ Happy Indian Student _ International University _ YA Boy",
                    "value": "A confident 24-year-old, male Indian student in casual clothes colored dark blue stands in front of a prestigious foreign university, holding a notebook in his hand and we see no people in the background. He gazes at the camera with ambition and joy dressed in a blue shade attire, with foreign students in soft focus behind him. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 8 _ Happy Indian Student _ University _ YA Boy",
                    "value": "A male Indian student, 25 years old, casually dressed in a blue tone standing in front of an educational university, holding a book in his hand, feeling ambitious and confident, background slightly blurred, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 9 _ Happy Indian Student _ Library _ YA Boy",
                    "value": "A happy male Indian student, aged 23 years, he is casually dressed in blue tone, holding a book, and sitting inside a college library. He is ready to embrace learning with enthusiasm and determination and his dress toned blue in color. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/4.5, ISO 200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 10 _ Happy Indian Student _ Yard _ YA Boy",
                    "value": "A male Indian student, 24 years old, casually dressed, feeling ambitious, happy and confident, sitting in front of a university front with a book in his hand, along with other students, background slightly blurred without students, and his uniform is in blue tone. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 11 _ Graduation _ Adult - Male",
                    "value": "A 28-year-old male Indian student, in his graduation attire, standing in front of a college building, with a beaming smile, proudly showing us his hard-earned degree in front of the camera. The overall image is captured daylight and lit.  Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Edu Loan - 17 _ Graduation _ Adult - Male 2",
                    "value": "A 35-year-old male Indian student, in graduation attire toned blue, standing in front of a college building, with a beaming smile, proudly showing us his hard-earned degree in front of the camera. The overall image is captured in daylight and lit.  Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 18 _ Happy Indian Student _ International University _ Adult - Male",
                    "value": "A confident 34-year-old, male Indian student in casual clothes stands in front of a prestigious foreign university, holding a notebook in his hand and we see no people in the background. He gazes at the camera with ambition and joy dressed in a brown shade attire, with foreign students in soft focus behind him. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 19 _ Happy Indian Student _ International University _ Adult - Male 2",
                    "value": "A male indian student, 35 years old, casually dressed in a blue tone standing in front of an educational university, holding a book in his hand, feeling ambitious and confident, background slightly blurred, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 20 _ Happy Indian Student _ Library _ Adult - Male",
                    "value": "A happy male Indian student, aged 33 years, he is casually dressed in blue tone, holding a book, and sitting inside a college library. He is ready to embrace learning with enthusiasm and determination and his dress toned blue in color. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/4.5, ISO 200 "
                },
                {
                    "label": "Ad Concept _ Edu Loan - 21 _ Happy Indian Student _ Yard _ Adult - Male",
                    "value": "A male Indian student, 34 years old, in bright sunlight, casually dressed, feeling ambitious, happy and confident, sitting in front of a university front with a book in his hand, along with other students, background slightly blurred without students, and his uniform is in blue tone. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        }
    },
    "HOME_LOAN": {
        "couple": {
            "20s": [
                {
                    label: "Ad Concept _ Home Loan - 1 _ Happy Indian Couple _ Home _ YA Couple",
                    value: "A young Indian couple, both 21 years old, standing in the garden area of their new house, looking at the camera and posed, feeling happy and accomplished, background showcases the beautiful architecture of their entire house with minimal interiors, front view of their modern house, mid shot. Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8, ISO 4000, capturing a sense of joy and celebration "
                },
                {
                    label: "Ad Concept _ Home Loan - 2 _ Happy Indian Couple _ Courtyard _ YA Couple",
                    value: "An Indian couple, husband and a wife, 20 years old, sitting on a chair in the spacious courtyard of their modern one-storey house, feeling happy and accomplished, the background setting reflects beautiful interiors, casually attire blue kurta and white shirt, looking at the camera with a smile, front angle, long shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                //write the rest of the prompts here
                {
                    label: "Ad Concept _ Home Loan - 3 _ Happy Indian Couple _ Miniature Home _ YA Couple",
                    value: "A beautiful Indian couple, husband and a wife, 23 years old, holding a small white colour house miniature and standing in front of a new one storey house, reflects intricate, simple yet traditional architecture, background blurred, feeling happy, long shot,  Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ],
            "30s": [
                {
                    label: "Ad Concept _ Home Loan - 7 _ Happy Indian Couple _ Home _ Adult Couple",
                    value: "A young Indian couple, both 30 years old, standing in the garden area of their new house, looking at the camera and posed, feeling happy and accomplished, background showcases the beautiful architecture of their entire house with minimal interiors, front view of their modern house, mid shot. Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8, ISO 4000, capturing a sense of joy and celebration "

                },
                {
                    label: "Ad Concept _ Home Loan - 8 _ Happy Indian Couple _ Living Room _ Adult Couple",
                    value: "An Indian couple, husband and a wife, 30 years old, sitting on a sofa in the spacious living room of a new modern house, feeling happy and accomplished, the background setting reflects beautiful interiors, casually attire blue kurta and white shirt, candid shot with a smile, front angle, long shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "

                },
                {
                    label: "Ad Concept _ Home Loan - 9 _ Happy Indian Couple _ Miniature Home _ Adult Couple",
                    value: "A beautiful modern Indian couple, husband and a wife, 33 years old, holding a small white colour house miniature and standing in front of a new one-storey house, reflects the intricate, simple yet traditional architecture, background blurred, feeling happy, long shot,  Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        },
        //write for family, write the full prompts
        "family": {
            "20s": [
                {
                    "label": "Ad Concept _ Home Loan - 4 _ Happy Indian Family _ Home _ YA Couple with child",
                    "value": "Happy Indian family, husband and a wife 21 years old, a 2-year-old son, in casual mild blue attire, standing in front of their modern house, with the background showcasing the house's architecture and an overall atmosphere of happiness and achievement. Medium shot. Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 "
                },
                {
                    "value": "Indian family, a husband and a wife 26 years old, a 4-year-old daughter, casual mild blue attire, sitting comfortably in the garden area, the background showcases the beautiful architecture of a new house, Medium shot. Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 ",
                    "label": "Ad Concept _ Home Loan - 5 _ Happy Indian Family _ Garden _ Adult Couple with child",
                },
                {
                    "label": "Ad Concept _ Home Loan - 6 _ Happy Indian Family _ Balcony _ Adult Couple with two children",
                    "value": "Family of 4, indian home. Both 28years old husband and wife. Standing in their huge balcony of their new modern home with their two kids standing next to them. They are all happy and joyful in their own space while they look into the camera. We see a long shot of them to capture the entire house in a frame. Canon EOS 5D Mark IV, Sigma 100mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 ",
                }

            ],
            "30s": [
                {
                    "value": "Happy Indian family, husband and a wife 35 years old, a 2-year-old son, in casual mild blue attire, standing in front of their modern house, with the background showcasing the house's architecture and an overall atmosphere of happiness and achievement. Medium shot. Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 ",
                    "label": "Ad Concept _ Home Loan - 10 _ Happy Indian Family _ Home _ Adult Couple with child",
                },
                {
                    "label": "Ad Concept _ Home Loan - 11 _ Happy Indian Family _ Garden _ Adult Couple with child - 2",
                    "value": "A Indian family, a husband and a wife 34 years old, a 4-year-old daughter, casual mild blue attire, sitting together in front of a new house, background showcases the house architecture, front view, focusing on the family, joyful moment, candid shot, Medium shot. Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/2.8 for depth of field, ISO 1200 "
                },
                {
                    "value": "Family of 4, indian home. Both 32 years old husband and wife. Standing in their huge balcony of their new modern home with their two kids standing next to them. They are all happy and joyful in their own space while they look into the camera. We see a long shot of them to capture the entire house in a frame. Canon EOS 5D Mark IV, Sigma 100mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 ",
                    "label": "Ad Concept _ Home Loan - 12 _ Happy Indian Family _ Balcony _ Adult Couple with two children - 2"
                }
            ]
        },
        "Male": {
            "20s": [
                {
                    "label": "Ad Concept _ Home Loan - 13 _ Happy Indian Man _ Home _ YA Male",
                    "value": "Proud and happy moment, Indian man, 26 years old, standing in front of a new modern house and posed with hands crossed, blue toned dress, Canon EOS 5D Mark IV, Sigma 100mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Home Loan - 15 _ Happy Indian Man _ Garden _ Adult Male",
                    "value": "A young indian Man in his mid 30's getting a new home. He stand full of experienced and energised of taking his new home possession. In a casual dress in blue tone, we see a good shot him facing the camera. Behind him we can see his newly build ultra modern home and garden at distance. Canon EOS 5D Mark IV, Sigma 100mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 ",
                }]
        },
        "Female": {
            "20s": [
                {
                    "label": "Ad Concept _ Home Loan - 14 _ Happy Indian Woman _ Home _ YA Female",
                    "value": "Modern Indian woman, 27 years old, standing in front of a new modern house and posed with hands crossed, blue-toned modern dress, feeling happy and proud, Canon EOS 5D Mark IV, Sigma 100mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 "
                },
            ],
            "30s": [
                {
                    "value": "A young indian woman in her mid 30's taking her new home. She stands full of excitement and joy of taking her new home possession. In a casual attire in shades of blue, we see a good shot of her looking into the camera. Behind her we can see his newly build ultra modern home and garden at distance. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 ",
                    "label": "Ad Concept _ Home Loan - 16 _ Happy Indian Woman _ Front Lawn _ Adult Female"
                }
            ]
        }
    },
    "BUSINESS_LOAN": {
        "Male": {
            "20s": [
                {
                    "label": "Ad Concept _ Business Loan - 1 _ Happy Indian Man _ Cafe _ YA Male",
                    "value": "Indian man, 21 years old, standing in front of a newly opened cafe, background blurred with no people, wearing an apron, hands crossed, feeling happy and accomplished, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 2 _ Happy Indian Man _ Grocery Store _ YA Male",
                    "value": "Proud and happy moment, Indian man, 21 years old, standing in the cashier counter of a modern grocery shop, looking at the camera with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 3 _ Happy Indian Man _ Super Market _ YA Male",
                    "value": "Indian man, 21 years old, standing in front of a warehouse, hands crossed, feeling happy and pride, looking at the camera and posed, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 4 _ Happy Indian Man _ Corporate Office _ YA Male",
                    "value": "Young Indian guy, 21 years old, formal attire, blue-toned suit, standing outside an office building, his hands in his pockets, feeling happy and proud, beaming with joy, white daylighting, mid shot, he is looking at the camera and posed, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 5 _ Happy Indian Man _ Office Cabin _ YA Male",
                    "value": "Young Indian guy, 21 years old, white and mild blue formal attire, sitting on a manager's chair and keeping his hand on the table holding an iPad and iPad pen in his hand, inside a modern office cabin room with files and a plant, background blurred, looking at the camera and posed with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Business Loan - 11 _ Happy Indian Man _ Cafe _ Adult Male",
                    "value": "Indian man, 31 years old, standing in front of a newly opened cafe, background blurred with no people, wearing an apron, hands crossed, feeling happy and accomplished, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 12 _ Happy Indian Man _ Grocery Store _ Adult Male",
                    "value": "Proud and happy moment, Indian man, 32 years old, standing in the cash counter of a modern grocery shop, looking at the camera with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 13 _ Happy Indian Man _ Warehouse _ Adult Male",
                    "value": "Indian man, 31 years old, standing in front of a warehouse, hands crossed, feeling happy and pride, looking at the camera and posed, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 14 _ Happy Indian Man _ Corporate Office _ Adult Male",
                    "value": "Young Indian guy, 31 years old, formal attire, blue-toned suit, standing outside an office building, his hands in his pockets, feeling happy and proud, beaming with joy, white daylighting, mid shot, he is looking at the camera and posed, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 15 _ Happy Indian Man _ Office Cabin _ Adult Male",
                    "value": "Young Indian guy, 31 years old, white and mild blue formal attire, sitting on a manager's chair and keeping his hand on the table holding an iPad and iPad pen in his hand, inside a modern office cabin room with files and a plant, background blurred, looking at the camera and posed with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ]
        },
        "Female": {
            "20s": [
                {
                    "label": "Ad Concept _ Business Loan - 6 _ Happy Indian Woman _ Boutique _ YA Female",
                    "value": "Captured an image of a young Indian woman who is a fashion designer, 21 years old, in mild blue and green attire, standing in front of a newly opened boutique, background showcases her new modern clothing store with a mannequin dressed in a white midi dress, showing thumbs up, feeling happy and accomplished, white daylighting, background slightly blurred, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 7 _ Happy Indian Woman _ Bakery _ YA Female",
                    "value": "Proud and happy moment, Indian woman, 21 years old, wearing an apron, standing at the cashier counter of a modern cake shop, looking at the camera with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 8 _ Happy Indian Woman _ Warehouse _ YA Female",
                    "value": "Indian woman, 21 years old, standing in front of a warehouse, hands crossed, feeling happy and pride, looking at the camera and posed, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 9 _ Happy Indian Woman _ Corporate Office _ YA Female",
                    "value": "Young Indian woman, 21 years old, in formal blue-toned attire, standing outside an office building, her hands in her pockets, feeling happy and proud, beaming with joy, white daylighting, mid shot, she is looking at the camera and posed, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 10 _ Happy Indian Woman _ Office Cabin _ YA Female",
                    "value": "Young Indian woman, 21 years old, white and mild blue formal attire, sitting on a manager's chair and keeping her hand on the table holding an iPad and iPad pen in her hand, inside a modern office cabin room with files and a plant, background blurred, looking at the camera and posed with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Business Loan - 16 _ Happy Indian Woman _ Sewing _ Adult Female",
                    "value": "A good looking and confident Indian woman wearing a kurta, 34 years old, while working on a sewing machine, looking directly at the camera and posed with a smile, background a cosy room setting, background blurred, focus on the woman, close mid shot, day white lighting, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 17 _ Happy Indian Woman _ Bakery _ Adult Female",
                    "value": "Proud and happy moment, Indian woman, 35 years old, with straight hair, wearing an apron, standing at the cashier counter of a modern cake shop, looking at the camera with a smile, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 18 _ Happy Indian Woman _ Warehouse _ Adult Female",
                    "value": "Indian woman, 33 years old, with straight black hair, standing in front of a warehouse, hands crossed, feeling happy and confident, looking at the camera and posed, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 19 _ Happy Indian Woman _ Corporate Office _ Adult Female",
                    "value": "Young Indian woman, 33 years old, in formal blue-toned attire, standing outside an office building, her hands in her pockets, feeling happy and achieved, beaming with joy, white daylighting, mid shot, she is looking at the camera and posed, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Business Loan - 20 _ Happy Indian Woman _ Office Cabin _ Adult Female",
                    "value": "Young Indian woman, 31 years old, white and mild blue formal attire, sitting on a manager's chair and keeping her hand on the table holding an iPad and iPad pen in her hand, inside a modern office cabin room with files and a plant, background blurred, looking at the camera and posed with a smile, feeling happy and achieved, white daylighting, mid shot, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ]
        }
    },
    "USED_CAR_LOAN": {
        "Male": {
            "20s": [
                {
                    "label": "Ad Concept _ Used Car Loan - 1 _ Happy Indian Man _ Car - Showroom _ YA Male",
                    "value": "Radiant Indian man in his 20s, standing confidently in front of a car showroom in casual attire. His face shows happiness and energy as he anticipates getting his first car. The wide-angle shot captures both the guy and the surrounding car garage in a brightly lit interior. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 2 _ Happy Indian Man _ Garage - Evening _ YA Male",
                    "value": "A 25-year-old Indian man standing outside a car showroom, appearing happy and confident about getting his first car from a second-hand dealer. He is in a very casual blue-toned attire, standing next to his new car. The outdoor bright lights reflect on his face, and it's a long shot capturing both him and his new car. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 3 _ Happy Indian Man _ Car - Outdoors _ Adult Male",
                    "value": "An Indian man, 28 years old, is seen happy and confident. He got his second-hand car and is parked it beside a hill, enjoying the scenic beauty. He is wearing a casual blue attire. Day white lighting. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 4 _ Happy Indian Man _ Car - Street _ YA Male",
                    "value": "An Indian guy, 25 years old, stands tall with folded hands and poses for the camera in a blue-toned t-shirt. He stands next to his new second-hand car, radiating happiness and confidence. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 5 _ Happy Indian Man _ Car - Celebration _ YA Male",
                    "value": "A happy and confident Indian guy, 23 years old, standing next to a new car, and we see only the car bonnet is tied up and decorated with a small white floral garland, he is in casual blue attire and we have a long shot to capture both himself and the car, full shot, the car is slightly blurred, empty road background blurred, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Used Car Loan - 11 _ Happy Indian Man _ Car - Showroom _ Adult Male",
                    "value": "Indian man in his 30s, standing with happiness and joy in front of a car garage in casual attire. His face shows happiness and energy as he anticipates getting his first car. The wide-angle shot captures both the guy and the surrounding car garage in bright light interiors. Daylighting, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 12 _ Happy Indian Man _ Garage - Evening _ Adult Male",
                    "value": "A 35-year-old Indian man standing outside a car showroom, appearing really happy and confident about getting his first car from a second-hand car showroom. He is in very casual attire, standing right next to his new car. The outdoor bright lights are reflecting on his face, and it's a long shot capturing both him and his new car. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 13 _ Happy Indian Man _ Car - Outdoors _ Adult Male",
                    "value": "Indian man aged 38, is seen happy and in great joy. He got his second-hand car and has parked it beside a hill, enjoying the scenic beauty. He is wearing casual blue attire. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 14 _ Happy Indian Man _ Car - Street _ Adult Male",
                    "value": "An Indian guy, 32 years old, stands tall with folded hands and poses for the camera in a blue-toned t-shirt. He is standing happy and with full joy and confidence next to his new second-hand car. Background blurred. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 15 _ Happy Indian Man _ Car - Celebration _ Adult Male",
                    "value": "A happy and confident Indian guy, 33 years old standing next to a new blue car, and we see only the car bonnet is tied up and decorated with a small white floral garland, he is in casual attire and we have a long shot to capture both himself and the car, full shot, the car is slightly blurred, empty road background blurred, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ]
        },
        "Female": {
            "20s": [
                {
                    "label": "Ad Concept _ Used Car Loan - 6 _ Happy Indian Woman _ Car - Showroom _ YA Female",
                    "value": "Indian woman in her early 20s, standing confidently in front of a car showroom in casual attire. Her face shows happiness and energy as she anticipates getting her first car. The wide-angle shot captures both the girl and the surrounding car garage in a bright lit environment. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 7 _ Happy Indian Woman _ Garage - Evening _ YA Female",
                    "value": "A 25-year-old Indian woman standing outside a car showroom, appearing really happy and confident in getting her first own car from a second-hand dealer. She is in a very casual blue tone attire and we can see her right next to a new car. The outdoor bright lights reflecting on her face, and we see a long shot capturing both her and the new car. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 8 _ Happy Indian Woman _ Car - Outdoors _ Adult Female",
                    "value": "Indian woman aged 28, we can see her happy and confident. She got her second-hand car and we see her parked the car side of a hill, looking into the scenic beauty. She is wearing a blue attire very casually. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 9 _ Happy Indian Woman _ Car - Street _ Adult Female",
                    "value": "An Indian woman, 25 years old, stands tall with folded hands and poses for the camera in a blue-toned t-shirt. She is standing happy and with full joy and confidence next to her new second-hand car. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 10 _ Happy Indian Woman _ Car - Celebration _ YA Female",
                    "value": "A happy Indian girl, 24 years old we see standing next to her first new car, she is holding a plate plate with a few orange and yellow marigold floral petals, She is in casual blue attire for her celebration and we have a long shot to capture both herself and the car, full angle shot, white day-lighting, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Used Car Loan - 16 _ Happy Indian Woman _ Car - Showroom _ Adult Female",
                    "value": "An Indian woman in her early 30s, standing confidently in front of a car showroom in casual attire. Her face shows happiness and energy as she anticipates getting her first car. The wide-angle shot captures both the girl and the surrounding car garage in a bright lit environment. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 17 _ Happy Indian Woman _ Garage - Evening _ Adult Female",
                    "value": "A 35-year-old Indian woman standing outside a car showroom, appearing really happy and confident in getting her first own car from a second-hand dealer. She is in a very casual blue tone attire and we can see her right next to a new car. The outdoor bright lights reflecting on her face, and we see a long shot capturing both her and the new car. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 18 _ Happy Indian Woman _ Car - Outdoors _ Adult Female - 2",
                    "value": "Indian woman aged 38, we can see her happy and confident. She got her second-hand car and we see her parked the car side of a hill, looking into the scenic beauty. She is wearing a blue attire very casually. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 19 _ Happy Indian Woman _ Car - Street _ Adult Female",
                    "value": "An Indian woman, 32 years old, stands tall with folded hands and poses for the camera in a blue-toned t-shirt. She is standing happy and with full joy and confidence next to her new second-hand car with a blurred background. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Used Car Loan - 20 _ Happy Indian Woman _ Car - Celebration _ Adult Female",
                    "value": "A happy Indian girl, 37 years old we see standing next to her first blue car, she is holding a plate plate with a few orange and yellow marigold floral petals, She is in casual attire for her celebration and we have a long shot to capture both herself and the car, full angle shot, white day-lighting, Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200 "
                }
            ]
        },
        "Family": {
            "Mixed_Age": [
                {
                    "label": "Ad Concept _ Used Car Loan - 21 _ Happy Indian Family _ Car - Home _ Family",
                    "value": "Modern Indian family, husband and a wife, both aged 24 years, 3 year old son, casual attire, standing in front of a new car and posed, mild blue toned car, feeling happy and accomplished, feeling happy, background modern apartment blurred, white daylighting, candid shot, mid shot, Nikon D850, high quality photo, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        }
    },
    "CAR_LOAN": {
        "female": {
            "20s": [
                {
                    "label": "Ad Concept _ Car Loan - 1 _ Happy Indian Woman _ Car _ YA Female",
                    "value": "Mid full shot photo of an Indian woman, 21 years old, blue toned attire, beaming with joy, She elegantly poses next to a sleek white modern SUV car, her posture subtly showcasing its design. Her eyes, locked onto the camera, and her hands crossed, empty highway road background setting with no people softly blurred. Focus on the car and the girl, Front angle, Day white lighting, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 2 _ Happy Indian Woman _ Car Key _ YA Female",
                    "value": "An Indian woman, 21 years old, mild blue attire, radiating accomplishment, beaming with joy, delicately holding a car key in her hand and posing with a pristine white sleek design car, with a blurred background of a modern house, focus on the car and her, showcases the car front view, happy moment, mid shot, post colour correction, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 3 _ Happy Indian Woman _ Inside the Car _ YA Female",
                    "value": "A 22-year-old beautiful Indian woman dressed in white and blue-toned attire, feeling happy and accomplished, sitting in the driver's seat of a new car, giving a thumbs up with her right hand. The photo is taken from a right side angle of a car, background blurred, daylighting, mid shot using a Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 4 _ Happy Indian Woman _ Car _ YA Female - 2",
                    "value": "A high-quality image of an Indian girl, 21 years old, kurta attire mild blue toned, with straightened hair, feeling happy and accomplished, beaming with joy, standing next to a new car, sleek design of the car is visible, looking at the camera posed with a smile, sunlight glides on her face, background a new one-storey modern house, white day lighting, long mid shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Car Loan - 9 _ Happy Indian Woman _ Car _ Adult Female",
                    "value": "Mid full shot photo of an Indian woman, 30 years old, blue toned casual attire, beaming with joy, She elegantly poses next to a new sleek white car, her posture subtly showcasing its design. Her eyes, locked onto the camera, and her hands crossed, empty highway road background setting with no people softly blurred. Focus on the car and the girl, Front angle, Day white lighting, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 10 _ Happy Indian Woman _ Car Key _ Adult Female",
                    "value": "An Indian woman, 31 years old, mild blue attire, radiating accomplishment, beaming with joy, delicately holding a car key in her hand, posing near a pristine white sleek design car, with a blurred background of a modern house, focus on the car and her, showcases the car front view, happy moment, mid shot, post colour correction, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 11 _ Happy Indian Woman _ Inside the Car _ Adult Female",
                    "value": "A 32-year-old beautiful Indian woman dressed in white and blue-toned attire, feeling happy and accomplished, sitting in the driver's seat of a new car, giving a thumbs up with her right hand. The photo is taken from a right side angle of a car, background blurred, daylighting, mid shot using a Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/2.8 for depth of field, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 12 _ Happy Indian Woman _ Car _ Adult Female - 2",
                    "value": "A high-quality image of an Indian girl, 31 years old, kurta attire mild blue toned, feeling happy and accomplished, beaming with joy, standing next to a new grey car, sleek design of the car is visible, looking at the camera posed with a smile, sunlight glides on her face, background a new one-storey modern house, white day lighting, long mid shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        },
        "male": {
            "20s": [
                {
                    "label": "Ad Concept _ Car Loan - 5 _ Happy Indian Man _ Car _ YA Male",
                    "value": "Mid full shot photo of an Indian man, 21 years old, blue toned attire, beaming with joy, he elegantly poses next to a new sleek white car, his posture subtly showcasing its design. His eyes, locked onto the camera, and his hands crossed, empty highway road background setting with no people softly blurred. Focus on the car and the man, Front angle, Day white lighting, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 6 _ Happy Indian Man _ Car Key _ YA Male",
                    "value": "A young Indian guy, 20 years old, mild blue casual t-shirt attire, radiating accomplishment, beaming with joy, delicately holding a car key in his right hand and posing near to a new white car, with a blurred background of a modern house, focus on the car and him, showcases the car front view, happy moment, mid shot, white day lighting, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 7 _ Happy Indian Man _ Inside the Car _ YA Male",
                    "value": "A 21-year-old young indian guy dressed in black attire, feeling happy and accomplished, sitting in the driver's seat of a new blue car, giving a thumbs up with his right hand. The photo is taken from a right side angle of a car, background blurred, daylighting, mid shot using a Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 8 _ Happy Indian Man _ Car _ YA Male - 2",
                    "value": "A high-quality image of an Indian guy, 21 years old, t-shirt attire mild blue toned, feeling happy and accomplished, beaming with joy, standing next to a new grey car, sleek design of the car is visible, looking at the camera posed with a smile, sunlight glides on his face, background a new one-storey modern house, white daylighting, long mid shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Car Loan - 13 _ Happy Indian Man _ Car _ Adult Male",
                    "value": "Mid full shot photo of an Indian man, 31 years old, blue toned attire, with beard, beaming with joy, he elegantly poses next to a new sleek white car, his posture subtly showcasing its design. His eyes, locked onto the camera, and his hands crossed, empty highway road background setting with no people softly blurred. Focus on the car and the man, Front angle, Day white lighting, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 14 _ Happy Indian Man _ Car Key _ Adult Male",
                    "value": "An indian man, 30 years old, mild blue casual t-shirt attire, clean shaved, radiating accomplishment, beaming with joy, delicately holding a car key in his right hand and posing near to a new white car, with a blurred background of a modern house, focus on the car and him, showcases the car front view, happy moment, mid shot, white day lighting, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 15 _ Happy Indian Man _ Inside the Car _ Adult Male",
                    "value": "A photo taken by a professional photographer, a 32-year-old indian man, wearing white toned shirt, feeling happy and accomplished, sitting in the driver's seat of a new white SUV car, giving a thumbs up with his right hand. The photo is taken from a right side angle of a car, background blurred, daylighting, mid shot using a Canon EOS 5D Mark IV, Sigma 35mm f/1.4 Art lens, f/1.8 for depth of field, ISO 1200 "
                },
                {
                    "label": "Ad Concept _ Car Loan - 12 _ Happy Indian Man _ Car _ Adult Male - 2",
                    "value": "A high-quality image of an Indian man, 31 years old, mild blue toned attire, feeling happy and accomplished, beaming with joy, standing next to a brand new car, sleek design of the car is visible, looking at the camera posed with a smile, sunlight glides on his face, background a new one-storey modern house, white daylighting, long mid shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        },
        "couple": {
            "multiple": [
                {
                    "label": "Ad Concept _ Car Loan - 13 _ Happy Indian Couple _ Car _ YA Couple",
                    "value": "Indian family, husband and a woman, both aged 24 years, white and blue toned attire, standing next to a brand-new car, brand logo not visible, feeling happy and accomplished, background showcases a new house, long mid shot, Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        },
        "family": {
            "multiple": [
                {
                    "label": "Ad Concept _ Car Loan - 14 _ Happy Indian Family _ Car _ YA Couple with child",
                    "value": "Modern Indian family, husband and a wife, both aged 24 years, 3 year old son, white and blue toned attire, standing in front of a brand-new car and posed, feeling happy and accomplished, looking at the camera with a smile, background empty road blurred, white daylighting, mid shot, Nikon D850, high quality photo, Nikkor 24-70mm, f/2.8, ISO 800 "
                }
            ]
        }
    },
    "PERSONAL_LOAN": {
        "male": {
            "20s": [
                {
                    "label": "Ad Concept _ Personal Loan - 1 _ Happy Indian Man _ Vacation _ YA Male",
                    "value": "We see a 20-year-old Indian guy on his vacation in a nice hill station, dressed very casually and enjoying the sun. The scene is captured in a long shot with him looking into the camera. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200, daylighting."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 2 _ Happy Indian Man _ Business _ YA Male",
                    "value": "An Indian guy at the age of 25, composed and proud of becoming a co-founder of a tech company he has opened. He is wearing office attire in blue shades and looking into the camera, with a blurred huge office space in the background. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200, white light."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 3 _ Happy Indian Man _ Shopping _ YA Male",
                    "value": "A 29-year-old Indian male is seen very happy and joyful on a shopping day, treating himself with lots of clothes to maintain his stylish lifestyle. He is wearing good-looking clothes and looking into the mirror of a big clothing store while also looking into the camera. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200, white light."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 4 _ Happy Indian Man _ Migration _ YA Male",
                    "value": "An Indian male aged 27, proud and energetic as he prepares to fly to another country and settle. He is seen with baggage and suitcase, with airport architecture in the background. He is wearing a very casual dress and a cap, and this image is shot in daylight. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 5 _ Happy Indian Man _ Wedding _ YA Male",
                    "value": "We see a 26-year-old Indian male, excited and full of energy, standing in groom-like attire, waiting with a smile on his wedding day. He is getting ready in a western style and standing tall in the lawn area. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Personal Loan - 11 _ Happy Indian Man _ Vacation _ Adult Male",
                    "value": "We see a 30-year-old Indian guy on his vacation in a nice hill station, dressed very casually and enjoying the sun. The scene is captured in a long shot with him looking into the camera. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200, daylighting."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 12 _ Happy Indian Man _ Business _ Adult Male",
                    "value": "An Indian guy at the age of 35, composed and proud of becoming a co-founder of a tech company he has opened. He is wearing office attire in blue shades and looking into the camera, with a blurred huge office space in the background. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200, white light."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 13 _ Happy Indian Man _ Shopping _ Adult Male",
                    "value": "A 39-year-old Indian male is seen very happy and joyful on a shopping day, treating himself with lots of clothes to maintain his stylish lifestyle. He is wearing good-looking casual clothes and looking into the camera in a white light shot. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 14 _ Happy Indian Man _ Migration _ Adult Male",
                    "value": "An Indian male aged 37, proud and energetic as he prepares to fly to another country and settle. He is seen with baggage and suitcase, with airport architecture in the background. He is wearing a very casual dress and a cap, and this image is shot in daylight. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 15 _ Happy Indian Man _ Wedding _ Adult Male",
                    "value": "We see a 36-year-old Indian male, excited and full of energy, standing in groom-like western wear and waiting with a smile on his wedding day. He is getting ready and standing tall in the lawn area with his hands folded while giving a pose. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                }
            ]
        },
        "female": {
            "20s": [
                {
                    "label": "Ad Concept _ Personal Loan - 6 _ Happy Indian Woman _ Vacation _ YA Female",
                    "value": "We see a 20-year-old Indian girl on her vacation in a nice hill station, dressed very casually and enjoying the sun. The scene is captured in a long shot with her looking into the camera in daylight. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 7 _ Happy Indian Woman _ Business _ YA Female",
                    "value": "An Indian girl at the age of 25, confident and proud of becoming a co-founder of a tech company she has started. She is wearing office attire in blue shade and looking into the camera, with a blurred huge office space in the background and white light. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 8 _ Happy Indian Woman _ Shopping _ YA Female",
                    "value": "A 29-year-old Indian female is seen very happy and joyful on a shopping day, treating herself with lots of clothes to maintain her stylish lifestyle. She is wearing good-looking clothes and looking into the mirror of a big clothing store while looking into the camera. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 9 _ Happy Indian Woman _ Migration _ YA Female",
                    "value": "An Indian woman aged 27, proud and energetic as she prepares to fly to another country and settle. She is seen with baggage and suitcase, with airport architecture in the background. She is wearing a very casual dress in blue tone and a cap, and this image is shot in daylight. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 10 _ Happy Indian Woman _ Wedding _ YA Female",
                    "value": "We see a 26-year-old Indian female, joyful and full of energy, standing in a bride-like attire, waiting with a smile on her wedding day. She is getting ready in a western style and standing tall in the lawn area. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                }
            ],
            "30s": [
                {
                    "label": "Ad Concept _ Personal Loan - 16 _ Happy Indian Woman _ Vacation _ Adult Female",
                    "value": "We see a 30-year-old Indian girl on her vacation in a nice hill station, dressed very casually and enjoying the sun. The scene is captured in a long shot with her looking into the camera in daylight. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 17 _ Happy Indian Woman _ Business _ Adult Female",
                    "value": "An Indian girl at the age of 35, confident and proud of becoming a co-founder of a tech company she has started. She is wearing office attire in blue shade and looking into the camera, with a blurred huge office space in the background and white light. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 18 _ Happy Indian Woman _ Shopping _ Adult Female",
                    "value": "A 39-year-old Indian female is seen very happy and joyful on a shopping day, treating herself with lots of clothes to maintain her stylish lifestyle. She is wearing good-looking clothes and looking into the mirror of a big clothing store while looking into the camera. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 19 _ Happy Indian Woman _ Migration _ Adult Female",
                    "value": "An Indian woman aged 37, proud and energetic as she prepares to fly to another country and settle. She is seen with baggage and suitcase, with airport architecture in the background. She is wearing a very casual dress in blue tone and a cap, and this image is shot in daylight. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                },
                {
                    "label": "Ad Concept _ Personal Loan - 20 _ Happy Indian Woman _ Wedding _ Adult Female",
                    "value": "We see a 36-year-old Indian female, joyful and full of energy, standing in a bride-like attire, waiting with a smile on her wedding day. She is getting ready in a western style and standing tall in the lawn area. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                }
            ]
        },
        "family": {
            "multiple": [
                {
                    "label": "Ad Concept _ Personal Loan - 21 _ Happy Indian Family _ Vacation _ Adult Couple with 2 children",
                    "value": "An Indian family with a couple and their 2 kids spending a good time on their first vacation outside the country. We see them enjoying a beautiful daylight scene at the beach side. They are all looking into the camera and wearing very casual attire for the vacation. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 1200."
                }
            ]
        },
        "couple": {
            "multiple": [
                {
                    "label": "Ad Concept _ Personal Loan - 22 _ Happy Indian Couple _ International Trip _ YA Couple",
                    "value": "Indian couple in their 20s, both happy and enjoying their first trip to another country together. They are standing in the airport with casual attire, holding suitcases and bags for their trip. Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM, f/6.1, ISO 1200."
                }
            ]
        }
    }





}


export default prompts;