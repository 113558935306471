import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyShareableKey } from "../../../../store/actions/agencyFile";
import { createShareableAgencyURL } from "../../utils";

import { Checkbox, Input, Modal } from "antd";

import { Agencies, AgencyFileDropState } from "../../../../store/types";

import { openToast } from "../../../../Toasts";

import cancelButton from "../../../../assets/svg/cancelModalIcon.svg";
import copyIcon from "../../../../assets/svg/copy_agency_link_icon.svg";

import "./index.scss";

interface ShareAgencyModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  agency: Agencies;
}

const ShareAgencyModal: FC<ShareAgencyModalProps> = ({
  isModalOpen,
  setModalOpen,
  agency,
}) => {
  const dispatch = useDispatch();
  const { agencyToBeShared } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => state.agencyFileDrop
  );

  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(
    !!agencyToBeShared.password ? true : false
  );

  const agencyPublicURL = createShareableAgencyURL(
    `${window.location.origin}#/cocreate/shared-agency`,
    agencyToBeShared
  );
  useEffect(() => {
    if (!!isModalOpen) {
      dispatch(
        getAgencyShareableKey({
          password_flag: isPasswordProtected,
          agency: agency.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, isPasswordProtected]);

  const handleCopy = (id: string) => {
    const inputField = document.getElementById(id) as HTMLInputElement;
    if (inputField) {
      navigator.clipboard
        .writeText(inputField.value)
        .then(() => {
          openToast("success", "Text copied to clipboard.");
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    }
  };
  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <Modal
      centered
      visible={isModalOpen}
      footer={null}
      className="share-agency-modal"
      onCancel={handleCancelButton}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          className="modal-title"
          style={{ display: "flex", alignItems: "center" }}
        >
          Share "{agency.agency_name}"
        </div>
        <div style={{ height: "3rem" }}>
          <img
            src={cancelButton}
            alt="cancel button"
            style={{ cursor: "pointer" }}
            onClick={handleCancelButton}
          />
        </div>
      </div>
      <div className="modal-desc ">
        Let people upload files your folder structure
      </div>

      <div className="input-title">Direct link</div>
      <div className="input-wrapper">
        <Input
          id="shareable-link"
          className="input-field input-data__link"
          value={agencyPublicURL}
        />
        <img
          src={copyIcon}
          alt="copy Icon"
          style={{ height: "3.6rem", cursor: "pointer" }}
          onClick={() => handleCopy("shareable-link")}
        />
      </div>
      {!!isPasswordProtected ? (
        <>
          <div className="input-title">Password</div>
          <div className="input-wrapper">
            <Input
              id="shareable-pass"
              className="input-field input-data__password"
              value={agencyToBeShared.password}
            />
            <img
              src={copyIcon}
              alt="copy Icon"
              style={{ height: "3.6rem", cursor: "pointer" }}
              onClick={() => handleCopy("shareable-pass")}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="settings-wrapper">Settings</div>
      <Checkbox
        className="password-checkbox"
        checked={isPasswordProtected}
        onChange={() => {
          setIsPasswordProtected((val) => !val);
        }}
      >
        Protect the link with password
      </Checkbox>
    </Modal>
  );
};

export default ShareAgencyModal;
