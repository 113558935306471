import { Drawer, Button, Input, Table, Search, Tooltip } from "pyxis-ui-kit";
import React, { FC, useState, useEffect, useMemo } from "react";
import "./index.scss";
import {
  CloseCircleOutlined,
  LeftOutlined,
  Loading3QuartersOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Banner,
  RolesState,
  Round,
  WorkFLow,
  WorkflowState,
} from "../../../store/types";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchHyperProjects,
  fetchProjects,
  fetchWorkFlows,
  folderApproval,
  getUsersData,
  sendForApproval,
  setProjects,
  setWorkflowPageData,
  setWorkFlows,
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import { EmptyFile } from "../EmptyFile";
import { SuccessScreen } from "../../shared/SuccessScreen";
import Avatar from "../Avatar";
import { openToast } from "../../../Toasts";
import {
  HDFC_DIGITAL_MFILTERIT_WORKFLOW,
  HDFC_PRINT_MFILTERIT_WORKFLOW,
  HDFC_BANK_MFILTERIT_WORKFLOW,
} from "../../../utilities/paths";
import { PlatFormTypePage } from "./PlatformType";

interface ApprovalAlertInterface {
  projectId: number;
  isApproval: boolean;
  projectType?: string;
  banners?: Banner[];
  setDrawerVisible: Function;
  callback?: Function;
  componentCaller?: string;
  contentCount?: number;
}

interface worflowInterface {
  key: React.Key;
  id: number;
  name: string;
  member_count: number;
  round_count: number;
}

interface ApprovalWorkflowInterface {
  description: string;
  setDescription: Function;
  setSelectedRowKeys: Function;
  setDataSource: Function;
  setActiveWorkFlow: Function;
  setSearchText: Function;
  dataSource: any;
  workflows: WorkFLow[];
  loading: boolean;
  searchText: string;
  rowSelection: any;
  selectedRowKeys: any;
}

interface workflowInterface {
  activeWorkFlow: any;
}

const putThirdPartyWorkflowAtTop = (workflows) => {
  const result = [];
  workflows.forEach((workflow) => {
    if (
      workflow.id == HDFC_DIGITAL_MFILTERIT_WORKFLOW ||
      workflow.id == HDFC_PRINT_MFILTERIT_WORKFLOW ||
      workflow.id == HDFC_BANK_MFILTERIT_WORKFLOW
    ) {
      result.unshift(workflow);
    } else {
      result.push(workflow);
    }
  });
  return result;
};

export const ApprovalAlert: FC<ApprovalAlertInterface> = ({
  projectId,
  isApproval,
  projectType,
  banners,
  setDrawerVisible,
  callback,
  componentCaller,
  contentCount,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([0]);
  const [workFlowStep, setWorkFlowStep] = useState("not-selected");
  const [drawerLoader, setDrawerLoader] = useState(false);
  const [loading, setLoader] = useState(false);
  const [description, setDescription] = useState("");
  const [activeWorkFlow, setActiveWorkFlow] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [platformValue, setPlatFormValue] = useState(null);
  const state = useSelector(
    (state: { workflows: WorkflowState }) => state.workflows
  );
  const userPermission: any = useSelector((state: { roles: RolesState }) =>
    state.roles.userPermissions.length !== 0 ? state.roles.userPermissions : []
  );
  const { workflows } = state;
  const dispatch = useDispatch();
  let history = useHistory();
  console.log("workflows", workflows);
  const isPlatformTypeEnabled = useMemo(() => {
    const result = workflows.filter(
      (workflow) =>
        workflow.name === "Compliance Flow" &&
        workflow.rounds.find((round) => round.name === "MfilterIt")
    );
    return result.length;
  }, [workflows]);
  console.log("isPlatformTypeEnabled", isPlatformTypeEnabled);
  useEffect(() => {
    // if(!onceLoaded&&isApproval){
    if (isApproval) {
      setLoader(true);
      dispatch(
        fetchWorkFlows(
          { page: 1, page_size: 100 },
          (data: any, err: boolean) => {
            if (!err) {
              let workflows: any = putThirdPartyWorkflowAtTop(data.results);
              dispatch(
                setWorkflowPageData({
                  count: data.count,
                  page_size: data.page_size,
                  current_page: data.current_page,
                  pages: data.pages,
                })
              );

              if (workflows.length > 0) {
                let ids: string[] = workflows.reduce((acc: any, val: any) => {
                  return [
                    ...acc,
                    val.created_by,
                    ...val.rounds.reduce((acc1: any, val1: any) => {
                      return [...acc1, ...val1.approvers];
                    }, []),
                  ];
                }, []);
                if (ids.length > 0) {
                  dispatch(
                    getUsersData(
                      { userIds: ids },
                      (userres: any, usererr: boolean) => {
                        if (!usererr) {
                          let { users } = userres.data;
                          let index = 0;
                          workflows.forEach((workflow: any, windex: number) => {
                            let member_count = 0;
                            workflow.round_count =
                              workflow.round_count +
                              " round" +
                              (workflow.round_count > 1 ? "s" : "");
                            workflow.rounds.forEach(
                              (round: any, index: number) => {
                                member_count =
                                  member_count + round.approvers.length;
                                round.approvers.forEach((a: any, r: number) => {
                                  round.approvers[r] =
                                    users.find((u: any) => u._id === a) || "";
                                });
                                workflow.member_count =
                                  member_count +
                                  " member" +
                                  (member_count > 1 ? "s" : "");
                              }
                            );
                            // using index variable so that key is not set on workflow which have 0 round and 0 memeber as key will affect default selected key in approval alert
                            if (
                              workflow.round_count !== "0 round" &&
                              workflow.member_count !== "0 member"
                            ) {
                              workflow.key = index;
                              index = index + 1;
                            }
                          });
                          const filteredWorkFlow = workflows.filter(
                            (workflow: any) =>
                              workflow.round_count !== "0 round" &&
                              workflow.member_count !== "0 member"
                          );
                          setActiveWorkFlow(filteredWorkFlow[0]);
                          dispatch(setWorkFlows(filteredWorkFlow));
                          setDataSource(filteredWorkFlow);
                          setLoader(false);
                        }
                      }
                    )
                  );
                } else {
                  setLoader(false);
                  dispatch(setWorkFlows(workflows));
                }
              } else {
                setLoader(false);
                dispatch(setWorkFlows([]));
              }
            }
          }
        )
      );
    }
  }, [isApproval]);

  const close = () => {
    setDrawerVisible("");
    setWorkFlowStep("not-selected");
    dispatch(setWorkFlows([]));
  };

  const folderApprovalAPI = (ids: number[]) => {
    dispatch(
      folderApproval(
        {
          entity_ids: ids,
          workflow_id: activeWorkFlow.id,
          note: description,
          entity_type: projectType,
          platform: platformValue,
        },
        (response: any, error: boolean) => {
          if (!error) {
            if (response.data.requested_to.length === 0) {
              openToast(
                "warn",
                response?.data?.data?.message ||
                  "All the projects in this folder are already sent for approval."
              );
            } else {
              openToast(
                "success",
                response?.data?.data?.message ||
                  "Successfully sent for approval"
              );
            }
            setWorkFlowStep("confirmed");
          }
          setDrawerLoader(false);
        }
      )
    );
  };

  const submitApproval = () => {
    setDrawerLoader(true);
    if (componentCaller === "folder") {
      let ids: number[];
      if (projectType === "project") {
        dispatch(
          fetchProjects(
            {
              page: 1,
              page_size: contentCount,
              folder_id: projectId,
              order_by: "-created_at",
            },
            (res: any, error: boolean) => {
              if (!error) {
                res = res.data;
                ids = res.reduce(
                  (acc: number[], val: any) => [...acc, val.id],
                  []
                );
                folderApprovalAPI(ids);
              }
            }
          )
        );
      } else if (projectType === "hpproject") {
        dispatch(
          fetchHyperProjects(
            { page: 1, page_size: contentCount, folder: projectId },
            (res: any, err: boolean) => {
              if (!err) {
                let ids = res.reduce(
                  (acc: number[], val: any) => [...acc, val.id],
                  []
                );
                folderApprovalAPI(ids);
              }
            }
          )
        );
      }
    } else if (componentCaller === "project") {
      if (projectType) {
        dispatch(
          sendForApproval(
            {
              entity_id: projectId,
              workflow_id: activeWorkFlow.id,
              note: description,
              entity_type: projectType,
              platform: platformValue,
            },
            (response: any, error: boolean) => {
              if (!error) {
                const projectIndex = banners.findIndex(
                  (project) => project.id === projectId
                );
                if (projectIndex > -1) {
                  banners[projectIndex].status = "pending";
                  banners[projectIndex].entity_id = projectId.toString();
                  dispatch(setProjects(banners));
                } else {
                  callback && callback(response, error);
                }
                setWorkFlowStep("confirmed");
              }
              setDrawerLoader(false);
            }
          )
        );
      }
    }
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: worflowInterface[]
    ) => {
      setSelectedRowKeys([selectedRows[0].key]);
      setActiveWorkFlow(selectedRows[0]);
    },
  };

  return (
    <Drawer
      className='approval-drawer'
      visible={isApproval}
      closable={false}
      onClose={close}
      footer={
        !loading &&
        dataSource.length !== 0 &&
        (workFlowStep === "not-selected" ? (
          <div className='footer'>
            <Button type='link' className='cancel' onClick={close}>
              Cancel
            </Button>
            <Button
              type='primary'
              className='submit'
              onClick={() => {
                setWorkFlowStep("selected");
                const workflow = activeWorkFlow;
                workflow.rounds = workflow.rounds.sort(function(
                  a: Round,
                  b: Round
                ) {
                  return a.id - b.id;
                });
                setActiveWorkFlow(workflow);
              }}
              loading={drawerLoader}
              disabled={activeWorkFlow.length === 0 && true}
            >
              <span>Send for approval</span>
              <RightOutlined />
            </Button>
          </div>
        ) : workFlowStep === "selected" ? (
          <div className='footer'>
            <Button
              type='link'
              className='cancel'
              onClick={() => {
                setWorkFlowStep("not-selected");
              }}
            >
              <LeftOutlined />
              Back to workflow list
            </Button>
            <Button
              type='primary'
              className='submit'
              onClick={() =>
                isPlatformTypeEnabled
                  ? setWorkFlowStep("platformType")
                  : submitApproval()
              }
              loading={drawerLoader}
              disabled={activeWorkFlow.length === 0 && true}
            >
              {isPlatformTypeEnabled ? (
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Next &nbsp;&nbsp;
                  &nbsp; &nbsp;&nbsp; &nbsp;
                </span>
              ) : (
                <span>Send to this workflow</span>
              )}
              <RightOutlined />
            </Button>
          </div>
        ) : workFlowStep === "platformType" ? (
          <div className='footer'>
            <Button
              type='link'
              className='cancel'
              onClick={() => {
                setWorkFlowStep("selected");
              }}
            >
              <LeftOutlined />
              Back to workflow list
            </Button>
            <Button
              type='primary'
              className='submit'
              onClick={submitApproval}
              loading={drawerLoader}
              disabled={activeWorkFlow.length === 0 && true}
            >
              <span>Send to this workflow</span>
              <RightOutlined />
            </Button>
          </div>
        ) : (
          <div className='footer success-footer'>
            <Button type='primary' className='ok' autoFocus onClick={close}>
              <span>Close</span>
            </Button>
          </div>
        ))
      }
    >
      {loading ? (
        <div className='loader-container'>
          <Loading3QuartersOutlined spin className='loader' />
        </div>
      ) : dataSource.length === 0 ? (
        <EmptyFile
          title='No approval workflows added yet.'
          subtitle='Add a new workflow to get started'
          btnTxt='New Workflow'
          handleClick={() => {
            history.push("/cocreate/workflow/new");
          }}
          isDisabled={userPermission.indexOf("manage-approval-workflow") === -1}
          className='approval-empty'
          isSecondaryBtn={true}
          secondaryBtnTxt='Close'
          handleSecondaryBtnClick={close}
        />
      ) : workFlowStep === "not-selected" ? (
        <ApprovalWorklows
          description={description}
          setDescription={setDescription}
          setSelectedRowKeys={setSelectedRowKeys}
          setDataSource={setDataSource}
          setActiveWorkFlow={setActiveWorkFlow}
          setSearchText={setSearchText}
          dataSource={dataSource}
          workflows={workflows}
          loading={loading}
          searchText={searchText}
          rowSelection={rowSelection}
          selectedRowKeys={selectedRowKeys}
        />
      ) : workFlowStep === "selected" ? (
        <Workflow activeWorkFlow={activeWorkFlow} />
      ) : workFlowStep === "platformType" ? (
        <PlatFormTypePage
          platformValue={platformValue}
          onChange={setPlatFormValue}
        />
      ) : (
        <>
          <SuccessScreen
            title='Great! Artwork has been sent to approval!'
            subTitle='Discovering your files just got easier!'
          />
        </>
      )}
    </Drawer>
  );
};

export const Workflow: FC<workflowInterface> = ({ activeWorkFlow }) => {
  return (
    <div className='workflow'>
      <div className='workflow-title'>{activeWorkFlow.name}</div>
      <div className='approval-cycle'>Approval Cycle</div>
      {activeWorkFlow.rounds.map((round: Round, index: number) => {
        const isEveryOneMustApprove = round.settings.everyonemustapprove;
        return (
          <div className='round' key={index}>
            <div className='round-info'>
              <div className='round-index'>R{index + 1}</div>
              <div className='round-detail'>
                <div className='round-name'>{round.name}</div>
                <div className='member-count'>
                  {round.approvers.length} members
                </div>
              </div>
            </div>
            <div className='round-info approver-info'>
              {round.approvers.map((approver: any, index: number) => {
                return (
                  <Tooltip
                    placement='top'
                    title={approver?.firstName + " " + approver?.lastName}
                    overlayStyle={{ zIndex: 2147483004 }}
                    key={index}
                    content={
                      <div>
                        <Avatar
                          className='approver'
                          size='small'
                          initials={approver?.username[0]}
                          showInitials={approver?.profilePicture}
                          backgroundColor={approver?.profileColour}
                          src={approver?.profilePicture}
                        />
                      </div>
                    }
                  ></Tooltip>
                );
              })}
            </div>
            <div
              className={`round-preference ${
                isEveryOneMustApprove ? "every-one" : ""
              }`}
            >
              {isEveryOneMustApprove
                ? "Everyone must approve"
                : "Anyone Can Approve"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ApprovalWorklows: FC<ApprovalWorkflowInterface> = ({
  dataSource,
  description,
  loading,
  rowSelection,
  searchText,
  selectedRowKeys,
  setActiveWorkFlow,
  setDataSource,
  setDescription,
  setSearchText,
  setSelectedRowKeys,
  workflows,
}) => {
  return (
    <div className='approval'>
      <div className='title'>Select workflow to send for approval</div>
      {/* <div className="sub-title">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div> */}
      <div className='description'>Description of artwork</div>
      <Input
        className='note'
        placeholder='Add a note to approvers'
        value={description}
        onChange={(e: any) => setDescription(e.target.value)}
      />
      <div className='description'>Search for workflows</div>
      <Search
        onSearch={(nameSearch: string) => {
          setSelectedRowKeys([]);
          setActiveWorkFlow([]);
          nameSearch
            ? setDataSource((data: any) =>
                data.filter((info: any) =>
                  info.name.toLowerCase().includes(nameSearch.toLowerCase())
                )
              )
            : setDataSource(workflows);
        }}
        value={searchText}
        onChange={(e: any) => {
          // When user backspace entire search text
          if (!e.target.value) {
            setDataSource(workflows);
          }
          // when user has typed wrong spelling and press backspace
          if (dataSource.length === 0) {
            let newSearch = workflows.filter((info: any) =>
              info.name.toLowerCase().includes(e.target?.value.toLowerCase())
            );
            e.target?.value
              ? setDataSource(newSearch)
              : setDataSource(workflows);
          }
          setSearchText(e.target.value);
        }}
        className='search'
        placeholder='Search for workflows by name...'
        suffix={
          <CloseCircleOutlined
            onClick={() => {
              setDataSource(workflows);
              setSearchText("");
            }}
          />
        }
      />
      <div className='found'>
        {dataSource.length} Workflow{dataSource.length > 1 && "s"} found
      </div>
      <Table
        loading={loading}
        className='workflow-list'
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          ...rowSelection,
        }}
        columns={[
          {
            title: "workflow name",
            dataIndex: "name",
          },
          {
            title: "workflow members",
            dataIndex: "member_count",
          },
          {
            title: "rounds amount",
            dataIndex: "round_count",
          },
        ]}
        dataSource={dataSource}
        onRow={(record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              setSelectedRowKeys([record.key]);
              setActiveWorkFlow(record);
            },
          };
        }}
      />
    </div>
  );
};
