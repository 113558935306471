import { getObjectSize } from "./getObjectSize";
import { cloneDeep } from "lodash";

export const getBoundaryLeftAndTop = (obj: any) => {
  const [widthBB, heightBB] = getObjectSize(obj);
  const width = obj.width * obj.scaleX;
  const height = obj.height * obj.scaleY;
  const angle = (obj.angle / 180) * Math.PI;
  const angleSin = Math.sin(angle);
  const angleCos = Math.cos(angle);

  const objectCenterInside = { x: width / 2, y: height / 2 };

  // #rotate by angle
  const objectCenterInsideNewX = objectCenterInside.x * angleCos - objectCenterInside.y * angleSin;
  const objectCenterInsideNewY = objectCenterInside.x * angleSin + objectCenterInside.y * angleCos;

  // #get real coordinate of object center
  const realCenterX = objectCenterInsideNewX + obj.left;
  const realCenterY = objectCenterInsideNewY + obj.top;

  // #get top left BB
  const leftBB = realCenterX - widthBB / 2;
  const topBB = realCenterY - heightBB / 2;

  const points = [
    { x: 0, y: 0 },
    { x: width, y: 0 },
    { x: 0, y: height },
    { x: width, y: height }
  ];

  const coordinates = [];

  for (const p of points) {
    const rotatedPoint = getRotatedPoint(p.x, p.y, angle);
    rotatedPoint.x += obj.left;
    rotatedPoint.y += obj.top;
    coordinates.push(rotatedPoint);
  }

  return { left: leftBB, top: topBB, coordinates };
};

export const getObjectListBoundaries = (objList: Array<any>) => {
  return cloneDeep(objList).map(obj => ({ ...obj, ...getBoundaryLeftAndTop(obj) }));
};

const getRotatedPoint = (x: number, y: number, angle: number) => {
  const angleSin = Math.sin(angle);
  const angleCos = Math.cos(angle);

  return { x: x * angleCos - y * angleSin, y: x * angleSin + y * angleCos };
};
