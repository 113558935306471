export const FETCH_ELEMENTS = 'FETCH_ELEMENTS';
export const SET_ELEMENTS = 'SET_ELEMENTS';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const LOADER_OFF = 'LOADER_OFF';

export type ElementsQuery = {
  search: string;
  page: number;
  catName: string;
  seeAll: boolean;
  selectedElementId: number;
  page_size: number;
  type: string;
};

export type CategoryQuery = {
  categoryPage: number;
  categoryPages: number;
  type: string;
};

export type FetchCategoriesAction = {
  type: typeof FETCH_CATEGORIES;
  payload: CategoryQuery;
};

export type SetCategoriesAction = {
  type: typeof SET_CATEGORIES;
  payload: {
    categories: any[];
    categoryPage: number;
    categoryPages: number;
  };
};

export type FetchElementsAction = {
  type: typeof FETCH_ELEMENTS;
  payload: ElementsQuery;
};

export type ElementsTrayState = {
  elements: any[];
  categories: any[];
  page: number;
  categoryPage: number;
  categoryPages: number;
  catName: string;
  search: string;
  loading: boolean;
  seeAll: boolean;
  selectedElementId: number;
  page_size: number;
  totalPages: number;
  type: string
};

export type SetElementsAction = {
  type: typeof SET_ELEMENTS;
  payload: Omit<Omit<Omit<ElementsTrayState, 'categories'>, 'categoryPage'>, 'categoryPages'>;
};

export type LoadingOffAction = {
  type: typeof LOADER_OFF;
  loading: boolean;
};