import React from 'react';
// import { TextField, InputAdornment } from '@material-ui/core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styled';
import { DebounceInput } from 'react-debounce-input';

function SearchInput({
  iconRight,
  handleChange,
  customTextFieldProps,
  customInputProps,
  placeholder,
  value,
  onKeyPress,
  errorText,
  onClickSearch,
  onBlur,
}: any) {
  return (
    <div className='search-input-wrapper'>
      <div className='search-input'>
        <SearchIcon />
        <DebounceInput
          className='custom-form-control'
          value={value}
          minLength={2}
          debounceTimeout={300}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          placeholder='Search'
        />
      </div>
    </div>
  );
}

export default SearchInput;
