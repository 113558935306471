import { type } from 'os';
export const GET_VLE_DATA = 'VLE_Data';
export const UPLOAD_VLE_PROOFS = 'UploadVLEImageProofs';
export const UPDATE_VLE_DATA = 'updateVleExpirationDate'
export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_SUCCESS';
export const UPLOAD_IMAGES_FAILURE = 'UPLOAD_IMAGES_FAILURE';

export type UploadVLEImageProofs = {
    type: typeof UPLOAD_VLE_PROOFS,
    payload: {
        vle_Id: string,
        images: File[],
    },
    callback?:Function
}


export type VLE_Data = {
    type: typeof GET_VLE_DATA,
    payload:
        {
            vle_Id: string,
        },
    callback?: Function;
}
