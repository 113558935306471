import React, { useState, useCallback, useRef, useEffect } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface AddTextPopUpProps {
    onCancel?: Function;
    onSubmit?: Function;
}

const AddTextPopUp:React.FC<AddTextPopUpProps> = ({onCancel, onSubmit}) => {
    const [loading, setLoader] = useState(false);
    const [list, setList] = useState<any>([]);
    const [text, setText] = useState('');
    const textInputRef = useRef(null);

    useEffect(()=>{
        let node:any = textInputRef?.current;
        if(node){
            node.scrollIntoView({ behavior: "smooth" })
        }
    },[list])

    const handleAdd = useCallback((text:string)=>{
        setList((list:any)=>([...list, {type: "Text", text}]));
        setText('');
    },[setList,list]);

    const handleDelete = useCallback((index:number)=>{
        setList((list:any)=>(list.filter((l:any,i:number)=>i !== index)));
    },[setList])
    
    return (
        <>
        <div 
            className="popup-overlay" 
            onClick={()=>onCancel && onCancel()}>
        </div>
        <div className="popup">
            <div className="title">
                <h3>Upload text alternatives</h3>
            </div>
            <div className="dynamic-input">
                <input
                    type="text"
                    value={text}
                    placeholder="Add Text Here"
                    onChange={(e:any)=>setText(e.target.value)}
                    onKeyPress={(e:any) => {
                        if(e.target.value && e.target.value.trim() && e.key.toLowerCase() === 'enter') {
                            handleAdd(e.target.value);
                        }
                    }}
                />
                <button
                    value={text}
                    disabled={text.trim() === ''}
                    onClick={(e:any) => {
                        if(e.target.value && e.target.value.trim()) {
                            handleAdd(e.target.value);
                        }
                }}>Add</button>
            </div>
            <div className="text-list">
                <ul className="list">
                    {list.length > 0 && list.map((t:any, index:number) => {
                        return (
                            <li key={t.key} ref={textInputRef}>
                                <input
                                    type="text"
                                    id={t.key}
                                    value={t.text}
                                    onChange={(e:any)=> {
                                        let value = e.target.value;
                                        if(value.trim()) {
                                            setList((list:any)=>{
                                                const newList = list.slice(0);
                                                newList[index].text = value;
                                                return newList;
                                            });
                                        }
                                    }}
                                />
                                <span className="delete-item" onClick={()=>handleDelete(index)}>
                                    <svg width="12" height="16" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1ZM1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z" fill="#D32F2F"/>
                                    </svg>
                                </span>
                            </li>
                        );
                    })}
                    <div style={{ clear: "both" }} ref={textInputRef}></div>
                </ul>
            </div>
            <div className="text-footer">        
                <button 
                    className="secondary" 
                    onClick={()=>onCancel && onCancel()}>
                        Cancel
                </button>
                <button 
                    className="primary" 
                    onClick={()=>onSubmit && onSubmit(list, setLoader)}
                    disabled={list.length === 0 || loading}>
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        Add Text
                </button>
            </div>
        </div>
        </>
    );
};

export default AddTextPopUp;