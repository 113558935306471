export const getVisibleSide = (side: number, firstMargin: number, secondMargin: number) => {
  let result = side

  if (firstMargin < 0) {
    result -= Math.abs(firstMargin);
  }

  if (secondMargin < 0) {
    result -= Math.abs(secondMargin)
  }

  return result
}