import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import axios from "axios";
import "./index.scss";
import { Button } from "pyxis-ui-kit";
import Avatar from "./../../components/shared/Avatar";
import ChatGPT from "../../assets/svg/ChatGPTLogo.svg";
import {
  EditOutlined,
  PlusOutlined,
  CloseCircleFilled,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AuthState } from "../../store/types";

const AskAnything = ({ setAskAnythingOpen }) => {
  const inputRef = useRef(null);
  const { userData } = useSelector((state: { authentication: AuthState }) => ({
    ...state.authentication,
  }));
  const [input, setInput] = useState("");
  const [gptResponse, setGptResponse] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessions, setSessions] = useState<any>(
    JSON.parse(localStorage.getItem("askanything-sessions")) || {}
  );
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (userData.userId != localStorage.getItem("askanythingId"))
      localStorage.setItem("askanything-sessions", JSON.stringify({}));
    localStorage.setItem("askanythingId", userData.userId);
    const data = JSON.parse(localStorage.getItem("askanything-sessions")) || {};

    const sessionsArray = Object.keys(data).map((sessionId) => ({
      ...data[sessionId],
      id: sessionId,
      title:
        data[sessionId].title.charAt(0).toUpperCase() +
        data[sessionId].title.slice(1),
      isEditing: false,
      isDeleting: false,
    }));

    const sessions = sessionsArray.reduce((acc, session) => {
      acc[session.id] = session;
      return acc;
    }, {});

    setSessions(sessions);
  }, [userData.userId]);

  useEffect(() => {
    if (inputRef) inputRef.current.focus();
  }, [selectedSession]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input) {
      setIsLoading(true);
      setQuestion(input);
      const question = input.charAt(0).toUpperCase() + input.slice(1);
      setTitle(question);
      const sessions_copy: any = {};
      Object.assign(sessions_copy, sessions);
      if (selectedSession) {
        sessions_copy[selectedSession].chat.push({
          content: question,
          user: "user",
        });
        setSessions(sessions_copy);
      }
      const response: any = await axios
        .post(
          `${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/ask-anything`,
          {
            userQuestion: input,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((err) => console.log(err));
      setInput("");
      const answer = response.data.response;
      setGptResponse(response.data.response);

      if (selectedSession) {
        sessions_copy[selectedSession].chat.push({
          content: answer,
          user: "chatGPT",
        });
        localStorage.setItem(
          "askanything-sessions",
          JSON.stringify(sessions_copy)
        );
        setSessions(sessions_copy);
      } else {
        const newSessionId = generateUniqueId();
        const data: any = {
          chat: [
            { content: question, user: "user" },
            { content: answer, user: "chatGPT" },
          ],
          title: question,
        };
        data.createdAt = new Date().getTime();
        sessions_copy[newSessionId] = data;
        sessions_copy[newSessionId].id = newSessionId;
        sessions_copy[newSessionId].title = question;
        localStorage.setItem(
          "askanything-sessions",
          JSON.stringify(sessions_copy)
        );
        setSelectedSession(newSessionId);
        setSessions(sessions_copy);
      }
      if (inputRef) inputRef.current.focus();
      setIsLoading(false);
    }
  };

  const addNewChat = () => {
    // localStorage.setItem('pixis-askAnything',sessions)
    setSelectedSession(null);
  };

  const handleSessionChange = (id) => {
    // setQuestion(sessions[id].question)
    // setGptResponse(sessions[id].answer)

    setSelectedSession(id);
    setTitle(sessions[id].title);
  };

  const handleDelete = (id) => {
    // Create a copy of the sessions object using Object.assign()
    const copy = Object.assign({}, sessions);

    // Delete the selected session from the copy
    delete copy[id];

    // Update local storage with the new session data
    localStorage.setItem("askanything-sessions", JSON.stringify(copy));

    const relevantObjects = Object.entries(sessions) // converts each entry to [key, value]
      .filter(([k, v]) => k !== id) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
        acc[k] = v;
        return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
      }, {});
    setSessions(relevantObjects);
    setSelectedSession(
      Object.keys(relevantObjects).length > 0
        ? Object.keys(relevantObjects)[0]
        : null
    );
  };

  const handleEdit = (id) => {
    let copy = {};
    Object.assign(copy, sessions);
    copy[id].isEditing = true;
    setSessions(copy);
  };

  const handleDeletePress = (id) => {
    let copy = {};
    Object.assign(copy, sessions);
    copy[id].isDeleting = true;
    setSessions(copy);
  };

  const handleNameChange = async (id) => {
    const copy = Object.assign({}, sessions);
    copy[id].title = title;
    copy[id].isEditing = false;
    localStorage.setItem("askanything-sessions", JSON.stringify(copy));
    setSessions(copy);
  };

  const handleClose = (id) => {
    let copy = {};
    Object.assign(copy, sessions);
    copy[id].isEditing = false;
    console.log(copy);
    setSessions(copy);
  };

  const Avatarpic = () => {
    return (
      <div
        className='avatar'
        style={{ margin: "1rem 0.5rem 1rem 0rem", alignSelf: "flex-start" }}
      >
        <Avatar
          size='large'
          initials={userData ? userData.firstName[0] : ""}
          showInitials={!!(userData && !userData.profilePicture)}
          backgroundColor={userData?.profileColour}
          src={userData ? userData.profilePicture : ""}
        />
        {userData && userData.profilePicture && userData.firstName[0]}
      </div>
    );
  };

  const handleCloseDelete = (id) => {
    let copy = {};
    Object.assign(copy, sessions);
    copy[id].isDeleting = false;
    setSessions(copy);
  };

  const renderTile = (item) => {
    if (item.isEditing) {
      return (
        <div
          className={`chat-tile ${item.id == selectedSession && "active"}`}
          onClick={() => handleSessionChange(item.id)}
        >
          <input
            className='title-input'
            onChange={(e) => setTitle(e.target.value)}
            style={{
              maxWidth: "60%",
              minWidth: "60%",
              border: "none",
              borderRadius: "4px",
            }}
            value={title}
          />
          {item.id == selectedSession && (
            <div className='icon-container'>
              <CheckOutlined
                onClick={(e) => {
                  handleNameChange(item.id);
                  e.stopPropagation();
                }}
              />
              <CloseOutlined
                style={{ marginLeft: "8px" }}
                onClick={(e) => {
                  handleClose(item.id);
                  e.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
      );
    } else if (item.isDeleting) {
      return (
        <div
          className={`chat-tile ${item.id == selectedSession && "active"}`}
          onClick={() => handleSessionChange(item.id)}
        >
          <div>
            {item.title.length > 15
              ? item.title.slice(0, 15) + "..."
              : item.title}
          </div>
          {item.id == selectedSession && (
            <div className='icon-container'>
              <CheckOutlined
                onClick={(e) => {
                  handleDelete(item.id);
                  e.stopPropagation();
                }}
              />
              <CloseOutlined
                style={{ marginLeft: "8px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseDelete(item.id);
                }}
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        className={`chat-tile ${item.id == selectedSession && "active"}`}
        onClick={() => handleSessionChange(item.id)}
      >
        <div>
          {item.title.length > 15
            ? item.title.slice(0, 15) + "..."
            : item.title}
        </div>
        {/* </div> : <div className={`chat-tile ${item.id == selectedSession && 'active'}`} onClick={() => handleSessionChange(item.id)}>{item.chat && item?.chat[0].content.length > 27 ? item?.chat[0].content.slice(0, 27) + "..." : item?.chat[0].content} */}
        {item.id == selectedSession && (
          <div className='icon-container'>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(item.id);
              }}
            />
            <DeleteOutlined
              style={{ marginLeft: "8px" }}
              onClick={(e) => {
                handleDeletePress(item.id);
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <CloseCircleFilled
        onClick={() => setAskAnythingOpen(false)}
        style={{
          fontSize: "18px",
          marginTop: "2rem",
          marginRight: "2rem",
          textAlign: "right",
          position: "absolute",
          right: "0.5rem",
          top: "0.4rem",
        }}
      />
      <div className='askanything-container'>
        <div className='side-bar'>
          <div className='new-chat' onClick={addNewChat}>
            <div className='icon'>
              <PlusOutlined style={{ fontSize: "16px" }} />
            </div>
            <div style={{ marginLeft: "1rem" }}>New Chat</div>
          </div>
          {Object.values(sessions)
            .reverse()
            .map((item: any, index) => renderTile(item))}
        </div>
        <div className='chat-container'>
          {selectedSession == null ? (
            <>
              <div></div>
              <div className='new-chat'>
                <h1 className='title'>Ask Anything</h1>
                <h3 className='sub-title'>
                  Get real-time answers to your queries with the "Ask Anything"
                  feature's reliable and accurate response system.
                </h3>
                <div className='feature-container'>
                  <div className='feature'></div>
                  <div className='feature'></div>
                  <div className='feature'></div>
                </div>
              </div>{" "}
            </>
          ) : (
            <div className='prev-chat'>
              {sessions[selectedSession]?.chat.map((item, index) => (
                <div
                  className='question-container'
                  key={index}
                  style={{
                    backgroundColor: item.user == "chatGPT" ? "" : "#FAF5FF",
                    minHeight: item.user == "chatGPT" ? "" : "83px",
                    padding: "1rem 0rem",
                  }}
                >
                  <div
                    className='question'
                    style={{
                      justifyContent:
                        item.user != "chatGPT" ? "space-between" : "none",
                      alignItems: "center",
                    }}
                  >
                    {item.user == "user" ? (
                      <Avatarpic />
                    ) : (
                      <img
                        src={ChatGPT}
                        style={{
                          margin: "1rem 0rem",
                          marginRight: "1rem",
                          width: "4.2rem",
                          alignSelf: "flex-start",
                        }}
                      />
                    )}
                    <p
                      className=''
                      style={{
                        width: item.user == "chatGPT" ? "70%" : "100%",
                        margin: "1rem 0rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.content.replace(/\n/g, "<br>"),
                      }}
                    ></p>
                    {/* {item.user != 'chatGPT' && <EditOutlined style={{ fontSize: "16px" }} className="edit-icon" />} */}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div>
            {isLoading && <div className='loader'>{LOADER}</div>}
            {/* <div className='chat-input-container'> */}
            <form className='chat-input-container' onSubmit={handleSubmit}>
              <input
                ref={inputRef}
                onChange={(e) => {
                  // if(e.keyCode)
                  setInput(e.target.value);
                }}
                value={input}
                className='chat-input'
                type='text'
                height={"43px"}
              />
              {/* <input type='submit'  style={{display:"absolute", visibility:"hidden"}} /> */}
              <Button
                btnsize='md'
                type='primary'
                disabled={input.length > 0 ? false : true}
                className='primary submit'
                onClick={handleSubmit}
                style={{ marginRight: "0px" }}
              >
                Submit
              </Button>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const LOADER = (
  <div className='loader'>
    <img width='50' src='/images/spinnerimg.gif' alt='Loading...' />
  </div>
);

function generateUniqueId() {
  const timestamp = Date.now();
  const randomNumber = Math.random();
  const uniqueId = `${timestamp}-${randomNumber}`;
  return uniqueId;
}

export default AskAnything;
