import React, { lazy, Suspense, FC, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';

import {LeftSideBar,TopBar,Content} from '../../components/RolesSection/MembersAndProducts';

import './index.scss';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';
import { store } from '../../store/store';
import { useSelector } from 'react-redux';
import { RolesState } from '../../store/types';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const MembersAndProducts: FC<any> = ({match,brandPermissions}) => {
  const { path } = match;
  const showBrandAdmin:boolean = useSelector((state:{roles:RolesState})=>(state.roles.showBrandAdmin))
  const showProductAdmin:boolean = useSelector((state:{roles:RolesState})=>(state.roles.showProductAdmin))
  
  const SuspenseComponent = () => {
    return (
      <>
        <div className="dashboard-wrap"> 
          <LeftSideBar />
          <div className="flex-column">
            {(path === '/cocreate/members'? (brandPermissions.indexOf('manage-users') > -1 && showBrandAdmin): (showBrandAdmin || showProductAdmin)) ?
              <>
                <TopBar />
                <div className="flex h-100">
                  <Content />
                </div>
              </>
              :<PermissionNotAllowed title="You do not have access to this feature as per your role" />
            } 
            </div>
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-homepage-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default MembersAndProducts;

