import React from 'react';
import TableLeftBar from './TableLeftBar';
import TableTopBar from './TableTopBar';
import TableContent from './TableContent';
import './index.scss';


const Content:React.FunctionComponent = () => {
  return <div className="content-table">
    <TableLeftBar />
    <div className="container">
      <TableTopBar />
      <TableContent />
    </div>
  </div>
}

export default Content;