import React, {FC, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import './index.scss';
import { ReactComponent as iconComplete} from '../../../assets/svg/iconComplete.svg';
import { ReactComponent as iconRemove} from '../../../assets/svg/iconRemove.svg';
import { Button } from 'pyxis-ui-kit';
import Icon, { ClockCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { permissionDeniedClick } from '../../../utilities/common-function';

interface ApprovalStatusInterface {
    approvalLoader:boolean,
    isSentForApproval:boolean,
    isApprover:boolean,
    approvalStatus:any,
    setDrawerVisible:Function,
    currentRound:string,
    statusLoader:boolean,
    handleStatusChange:Function,
    setIsRejectDrawer:Function,
    canManage:boolean
}


const ApprovalStatus:FC<ApprovalStatusInterface> = ({approvalLoader,isSentForApproval,isApprover,approvalStatus,setDrawerVisible,currentRound,statusLoader,handleStatusChange,setIsRejectDrawer,canManage}) => {
    return <div className="approval-status">
    {!approvalLoader&&(!isSentForApproval ?
        <Button className="footer-btn" btnsize="md" type="primary" onClick={()=>{
                if(canManage){
                    setDrawerVisible('approval')
                }else{
                    permissionDeniedClick()
                }
                
            }}>
            <span className={canManage?"btn-info":"btn-info permission-denied-lock"}>
                <span className="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6663 1.33301L7.33301 8.66634" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.6663 1.33301L9.99967 14.6663L7.33301 8.66634L1.33301 5.99967L14.6663 1.33301Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                Send for approval
            </span>
        </Button>
        :isApprover?<div className="approve-info noselect">
            <div className="current-round noselect">{currentRound}</div>
            <Icon component={iconRemove} className="approval-icon" onClick={()=>{setIsRejectDrawer(true)}}/>
            {statusLoader?<Loading3QuartersOutlined spin className="approval-icon loading-icon"/>
            :<Icon component={iconComplete} className="approval-icon" onClick={()=>{handleStatusChange('approved')}}/>}
        </div>
        :!statusLoader&&<div className="status-info">
            {approvalStatus?.status==="approved"?
                <div className="flex noselect">
                    <Icon component={iconComplete} className="status-icon"/>
                    <div className="approve-status">Approved</div>
                </div>
                :approvalStatus?.status==="rejected"?
                <div className="flex noselect">
                    <Icon component={iconRemove} className="status-icon"/>
                    <div className="approve-status rejected">Rejected</div>
                </div>
                :<div className="flex noselect">
                    <>
                        <ClockCircleOutlined className="status-icon pending-icon"/>
                        <div className="approve-status pending">Ongoing</div>
                    </>
                </div>
            }
        </div>)
        }
    </div>
}

export default ApprovalStatus;