import React, { useRef, useEffect, FC, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { ClickAwayListener } from '@material-ui/core';

const modalRoot = document.getElementById('modal-root');

const Modal: FC = ({children}) => {
  const el = useRef(document.createElement('div'));

  useEffect(()=>{
    modalRoot?.appendChild(el.current);
    return ()=>{
      modalRoot?.removeChild(el.current);
    }
  },[])

  return ReactDOM.createPortal(
    children,
    el.current
  );
}

export default Modal;