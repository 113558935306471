import {
  CreateHyperProject,
  CREATE_HYPER_PROJECT,
  DeleteHyperProjects,
  DELETE_HYPER_PROJECTS,
  DeleteAllHyperProjects,
  DELETE_ALL_HYPER_PROJECTS,
  MoveHyperProjects,
  MOVE_HYPER_PROJECTS, 
  SetHyperProjectData, 
  SetSelectedHyperSizes,
  SET_SELECTED_HYPER_SIZES,
  SET_HYPERPROJECT_DATA,
  AddSizesToHpproject,
  ADD_SIZES_TO_HPPROJECT, 
  DeleteSizesFromHpproject,
  DELETE_SIZES_FROM_HPPROJECT,
  SetLayersWithAssets,
  SET_LAYERS_WITH_ASSETS,
  FetchLayersWithAssets,
  FETCH_LAYERS_WITH_ASSETS,
  AddLayerAssets,
  ADD_LAYER_ASSETS,
  DELETE_LAYER_ASSET,
  DeleteLayerAsset,
  FetchHyperRenderedImages,
  FETCH_HYPER_RENDERED_IMAGES,
  SET_SELECTED_HPPROJECTS,
  SetSelectedHpProjects,
  SetSelectedImageId,
  SetSelectedTextId,
  SetSelectedImageLayerId,
  SetSelectedTextLayerId,
  SetSelectedSizeId,
  SetSelectedAssetId,
  SetSelectedLayerId,
  SET_SELECTED_IMAGE_ID,
  SET_SELECTED_TEXT_ID,
  SET_SELECTED_IMAGE_LAYER_ID,
  SET_SELECTED_TEXT_LAYER_ID,
  SET_SELECTED_SIZE_ID,
  SET_SELECTED_ASSET_ID,
  SET_SELECTED_LAYER_ID,
  DeleteHyperRenderedImages,
  DELETE_HYPER_RENDERED_IMAGES,
  SetProjectsForReadyCheck,
  SET_PROJECTS_FOR_READY_CHECK,
  CancelHyperProject,
  CANCEL_HYPER_PROJECT
} from "../types";

export const createHyperProject = (
  payload: {
    project_id: number;
    title: string;
    description: string;
  },
  callback?: Function
): CreateHyperProject => ({
  type: CREATE_HYPER_PROJECT,
  payload,
  callback,
});

export const cancelHyperProject= () : CancelHyperProject=>({
    type: CANCEL_HYPER_PROJECT
})


export const deleteHyperProjects = (
  payload: number | number[],
  callback?: Function
): DeleteHyperProjects => ({
  type: DELETE_HYPER_PROJECTS,
  payload,
  callback,
});

export const deleteAllHyperProjects = (callback?:Function): DeleteAllHyperProjects => ({
  type: DELETE_ALL_HYPER_PROJECTS,
  callback,
});

export const moveHyperProjects = (payload: {
  hpproject_ids: number[];
  destination_folder_id: number;
},callback?:Function): MoveHyperProjects => ({
  type: MOVE_HYPER_PROJECTS,
  payload,
  callback
});

export const setHyperProjectData = (payload:any):SetHyperProjectData => ({
    type: SET_HYPERPROJECT_DATA,
    payload
})

export const setSelectedHyperSizes = (payload: number[]):SetSelectedHyperSizes => ({
    type: SET_SELECTED_HYPER_SIZES,
    payload
})

export const addSizesToHpproject = (payload:{
    project_id: number;
    size_ids: number[];
},callback?:Function):AddSizesToHpproject => ({
    type: ADD_SIZES_TO_HPPROJECT,
    payload,
    callback
})

export const deleteSizesFromHpproject = (payload: {
    project_id: number;
    size_ids: number[];
},callback?:Function):DeleteSizesFromHpproject=>({
    type: DELETE_SIZES_FROM_HPPROJECT,
    payload,
    callback
})

export const setLayersWithAssests = (payload: any):SetLayersWithAssets => ({
    type: SET_LAYERS_WITH_ASSETS,
    payload
})

export const fetchLayersWithAssets = (payload: {
    project_id: number;
    page: number;
    page_size?: number;
},callback?:Function):FetchLayersWithAssets => ({
    type: FETCH_LAYERS_WITH_ASSETS,
    payload: {...payload,page_size: payload.page_size || 20},
    callback
})

export const addLayerAssets = (payload: {
    project_id: number;
    layer_id: number;
    assets: any[];
},callback?:Function):AddLayerAssets => ({
    type: ADD_LAYER_ASSETS,
    payload,
    callback
})

export const deleteLayerAsset = (payload: {
    project_id: number;
    layer_id: number;
    asset_id: number;
},callback?:Function):DeleteLayerAsset => ({
    type: DELETE_LAYER_ASSET,
    payload,
    callback
})

export const fetchHyperRenderedImages = (payload:{
    project_id: number;
    page: number;
    page_size?: number;
    size_id?: number[];
    text_layer_asset_id?: any;
    image_layer_asset_id?: any;
    layer_asset_id?: number;
},callback?:Function):FetchHyperRenderedImages => ({
    type: FETCH_HYPER_RENDERED_IMAGES,
    payload:{...payload,page_size: payload.page_size||60},
    callback
})

export const setSelectedHpProjects = (payload: number[]):SetSelectedHpProjects => ({
    type: SET_SELECTED_HPPROJECTS,
    payload
})

export const setSelectedLayerId = (payload: number|null):SetSelectedLayerId => ({
    type: SET_SELECTED_LAYER_ID,
    payload
})
export const setSelectedAssetId = (payload: number|null):SetSelectedAssetId => ({
    type: SET_SELECTED_ASSET_ID,
    payload
})

export const setSelectedSizeId = (payload: number[]|null):SetSelectedSizeId => ({
    type: SET_SELECTED_SIZE_ID,
    payload
})
export const setSelectedImageLayerId = (payload: number|null):SetSelectedImageLayerId => ({
    type: SET_SELECTED_IMAGE_LAYER_ID,
    payload
})
export const setSelectedTextLayerId = (payload: number|null):SetSelectedTextLayerId => ({
    type: SET_SELECTED_TEXT_LAYER_ID,
    payload
})
export const setSelectedImageId = (payload: any):SetSelectedImageId => ({
    type: SET_SELECTED_IMAGE_ID,
    payload
})
export const setSelectedTextId = (payload: any):SetSelectedTextId => ({
    type: SET_SELECTED_TEXT_ID,
    payload
})

export const deleteHyperRenderedImages = (payload:{
    project_id: number;
    ids: number[];
},callback?:Function):DeleteHyperRenderedImages => ({
    type: DELETE_HYPER_RENDERED_IMAGES,
    payload,
    callback
})

export const setProjectsForReadyCheck = (payload: any):SetProjectsForReadyCheck => ({
    type: SET_PROJECTS_FOR_READY_CHECK,
    payload
})