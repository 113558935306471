import { GROUPING_TYPES } from "./constants";
import { getObjectSize } from "../helpers/getObjectSize";
import { cloneDeep } from "lodash";

export interface IResult {
  parent: any;
  children: Array<any>;
}

export const createGroup = (childObj: any, parentObj: any, prevChildren: Array<any>, groupType: GROUPING_TYPES) => {
  const prevChildrenResult = prevChildren || [];
  if (groupType === GROUPING_TYPES.PARENT_CONTAINER) {
    return {
      parent: parentObj,
      children: [...prevChildrenResult, childObj]
    };
  } else if (groupType === GROUPING_TYPES.TEXT_UNDER_IMAGE) {
    const wrapper = createFakeParent(parentObj, [childObj, prevChildrenResult]);
    return {
      parent: wrapper,
      children: [...prevChildrenResult, parentObj, childObj]
    };
  }

  return {
    parent: parentObj,
    children: prevChildrenResult
  };
};

const createFakeParent = (parentObj: any, hisChildren: Array<any>) => {
  const parentWrapper = cloneDeep(parentObj);
  parentWrapper.id = `${parentObj.id}-wrapper`;
  for (const child of hisChildren) {
    const [parentObjWidth, parentObjHeight] = getObjectSize(parentWrapper);
    const [childObjWidth, childObjHeight] = getObjectSize(child);
    if (child.left < parentWrapper.left) {
      parentWrapper.left = child.left;
      const newWidth = parentObjWidth + parentWrapper.left - child.left;
      parentWrapper.scaleX *= newWidth / parentObjWidth;
    }
    if (child.left + childObjWidth > parentObjWidth + parentWrapper.left) {
      const newWidth = parentObjWidth + child.left + childObjWidth - (parentObjWidth + parentWrapper.left);
      parentWrapper.scaleX *= newWidth / parentObjWidth;
    }
    if (child.top < parentWrapper.top) {
      parentWrapper.top = child.top;
      const newHeight = parentObjHeight + parentWrapper.top - child.top;
      parentWrapper.scaleY *= newHeight / parentObjHeight;
    }
    if (child.top + childObjHeight > parentWrapper.top + parentObjHeight) {
      const newHeight = parentObjHeight + child.top + childObjHeight - (parentWrapper.top + parentObjHeight);
      parentWrapper.scaleY *= newHeight / parentObjHeight;
    }
  }

  return parentWrapper;
};
