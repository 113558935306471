import React from 'react';
import { WorkflowState } from '../../../store/types';
import WorkFlows from '../WorkFlows';
import Dashboardoverview from '../DashboardOverview/DashboardOverview';
import { useSelector } from 'react-redux';

const Content:React.FC = () => {
  const workflowTopBarTab = useSelector((state:{workflows:WorkflowState})=>(state.workflows.workflowTopBarTab))
  switch(workflowTopBarTab){
    case 1:return <Dashboardoverview />;
    case 2:return <WorkFlows />;
  }
  return null;
}

export default Content;