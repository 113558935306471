import { getObjectSize } from "../helpers/getObjectSize";
import { isTextGroupedWithImage, isObjectInside } from "../helpers/positioning";
import { GROUPING_TYPES } from "./constants";

export const getGroupType = (parentObj: any, childObj: any) => {
  const parentSize = getObjectSize(parentObj);
  const childSize = getObjectSize(childObj);

  if (isTextGroupedWithImage(parentObj, childObj, parentSize, childSize)) {
    return GROUPING_TYPES.TEXT_UNDER_IMAGE;
  } else if (isObjectInside(childObj, parentObj)) {
    return GROUPING_TYPES.PARENT_CONTAINER;
  }

  return GROUPING_TYPES.NOT_GROUP;
};

export const isGroup = (groupType: GROUPING_TYPES) => {
  return [GROUPING_TYPES.TEXT_UNDER_IMAGE, GROUPING_TYPES.PARENT_CONTAINER].indexOf(groupType) !== -1;
};
