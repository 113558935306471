import { FetchTypography, FETCH_TYPOGRAPHY, SetLoader, SET_LOADER, Typography, AddTypography, 
        ADD_TYPOGRAPHY, UploadTypography, UPLOAD_TYPOGRAPHY, SetTypography, SET_TYPOGRAPHY, Color,DeleteColors,
        DELETE_TYPOGRAPHY, DeleteTypography, FETCH_COLORS, FetchColors, UploadColor, UPLOAD_COLOR, SetColors, SET_COLORS,
        AddColor, ADD_COLORS, RemoveColor, REMOVE_COLORS, DELETE_COLORS, ChangeColor, CHANGE_COLOR, UpdateColor,
        UPDATE_COLOR, SetSelectedColors, SET_SELECTED_COLORS, FetchMyTemplates, FETCH_MY_TEMPLATES, MyTemplate, 
        SetMyTemplates, SET_MY_TEMPLATES, DeleteMyTemplates, DELETE_MY_TEMPLATES, RemoveMyTemplate, REMOVE_MY_TEMPLATE, SetSelectedMyTemplates,
        SET_SELECTED_MY_TEMPLATES, FetchTemplateOptions, FETCH_TEMPLATE_OPTIONS, TemplateOption, SetTemplateOptions, SET_TEMPLATE_OPTIONS,
        UpdateTemplateOptions, UPDATE_TEMPLATE_OPTIONS, ChangeTemplateOption, CHANGE_TEMPLATE_OPTIONS, SET_AVAILABLE_TYPOGRAPHIES, 
        SetAvailableTypography, SetTemplatePageData, SET_TEMPLATE_PAGE_DATA, CREATE_PROJECT_FROM_TEMPLATE } from "../types"


export const fetchTypographies = (payload:{page:number,webfont:boolean,page_size?:number,product_id?: number},callback?:Function): FetchTypography => ({
    type: FETCH_TYPOGRAPHY,
    payload: {...payload,page_size: 500,product_id: 1},
    callback
})

export const setLoader = (payload: boolean): SetLoader => ({
    type: SET_LOADER,
    payload: payload
})

export const uploadTypography = (files: object[],product_id: number = 1, callback?: Function ): UploadTypography => ({
    type: UPLOAD_TYPOGRAPHY,
    payload: {
        files,
        product_id
    }, 
    callback
})

export const addTypography = (payload: Typography[]): AddTypography => ({
    type: ADD_TYPOGRAPHY,
    payload: payload
})

export const setTypography = (payload: Typography[]): SetTypography => ({
    type: SET_TYPOGRAPHY,
    payload: payload
})

export const setavailableTypography = (payload: Typography[]): SetAvailableTypography => ({
    type: SET_AVAILABLE_TYPOGRAPHIES,
    payload: payload
})

export const deleteTypography = (index: number, id:number, callback?:Function): DeleteTypography => ({
    type: DELETE_TYPOGRAPHY, 
    payload: {
        index,
        id
    },
    callback
})

export const fetchColors = (payload:{page:number,page_size?:number,product_id?:number},callback?:Function): FetchColors => ({
    type: FETCH_COLORS,
    payload: {
        ...payload,
        page_size:20,
        product_id:1
    },
    callback
})

export const uploadColor = (payload:{color_code: string, name:string,product_id?: number},callback?:Function): UploadColor => ({
    type: UPLOAD_COLOR,
    payload: {
       ...payload,
       product_id:1
    },
    callback
})

export const setColors = (payload: Color[]): SetColors => ({
    type: SET_COLORS,
    payload: payload
})

export const addColors = (payload: Color[]): AddColor => ({
    type: ADD_COLORS,
    payload: payload
})

export const removeColor = (payload: number[]): RemoveColor => ({
    type: REMOVE_COLORS,
    payload: payload
})

export const deleteColor = (
    payload: number[],
    callback?: Function
): DeleteColors => ({
    type: DELETE_COLORS,
    payload: payload,
    callback
})

export const updateColor = (payload: Color,callback?:Function): UpdateColor => ({
    type: UPDATE_COLOR,
    payload,
    callback
})

export const changeColor = (payload: Color): ChangeColor => ({
    type: CHANGE_COLOR,
    payload: payload
})

export const setSelectedColors = (payload: number[]): SetSelectedColors => ({
    type: SET_SELECTED_COLORS,
    payload: payload
})

export const fetchMyTemplates = (payload: {page: number,page_size?: number,product_id?: number},callback?:Function): FetchMyTemplates => ({
    type: FETCH_MY_TEMPLATES,
    payload: {...payload,page_size: payload.page_size||20,product_id: 1},
    callback
})

export const setMyTemplates = (payload: MyTemplate[]): SetMyTemplates => ({
    type: SET_MY_TEMPLATES,
    payload: payload
})

export const deleteMyTemplates = (payload: number[], callback?: Function): DeleteMyTemplates => ({
    type: DELETE_MY_TEMPLATES,
    payload:payload,
    callback
})

export const removeMyTemplates = (payload: number): RemoveMyTemplate => ({
    type: REMOVE_MY_TEMPLATE,
    payload: payload
})

export const setSelectedMyTemplates = (payload: number[]): SetSelectedMyTemplates => ({
    type: SET_SELECTED_MY_TEMPLATES,
    payload: payload
})

export const fetchTemplateOptions = (product_id: number):FetchTemplateOptions => ({
    type: FETCH_TEMPLATE_OPTIONS,
    payload: {
        product_id
    }
})

export const setTemplateOptions = (payload: TemplateOption[]): SetTemplateOptions => ({
    type: SET_TEMPLATE_OPTIONS,
    payload: payload
})

export const updateTemplateOption = (option: TemplateOption,product_id:number = 1): UpdateTemplateOptions => ({
    type: UPDATE_TEMPLATE_OPTIONS,
    payload: {
        option,
        product_id
    }
})

export const changeTemplateOption = (payload: TemplateOption): ChangeTemplateOption => ({
    type: CHANGE_TEMPLATE_OPTIONS,
    payload: payload
})

export const setTemplatePageData = (payload:any): SetTemplatePageData => ({
    type: SET_TEMPLATE_PAGE_DATA,
    payload:payload
})

export const createProjectFromTemplate = (payload:{
    templateId: number,
    title: string,
    folder?: number
},callback?:Function) => ({
    type: CREATE_PROJECT_FROM_TEMPLATE,
    payload,
    callback
})