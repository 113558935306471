import { fabric } from 'fabric';
import { DEFAULT_SHAPE_PROPS } from './defaults';
import { getTextbox } from '../components/Main/FabricLoaders/objectGetters';
import { ExtendedCanvas, ExtendedObject } from './FabricCanvasContext';
import { v4 } from 'uuid';
import FontFaceObserver from 'fontfaceobserver';
import { IMAGE_BASE_URL } from '../utilities/paths';
import { openToast } from '../Toasts';
import { customizedKeysArr, setTextBoxEvents } from '../ConversionContext/ConversionContext';
import { capitalizeWords } from '../components/RightMenu/ItemTray/Elements/TextTray/utils';
import { store as ReduxStore } from '../store/store';
import { FabricGroupExtended } from '../components/shared/types';
import { isSvg } from '../utilities/common-function';
import { enableSvgCropAction, enableCropAction, cropDoneAction, cancelCropAction } from '../store/actions';
import { LAYER_TYPE } from '../components/Main/FabricLoaders/node-type';

const KEYS: any = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  UNDO_REDO: 'KeyZ',
  SELECT_ALL: 'KeyA',
  ZOOM_IN: 'Equal',
  ZOOM_OUT: 'Minus',
  DELETE_BCS: 'Backspace',
  DELETE_DLT: 'Delete',
  SAVE: 'KeyS',
  FORWARD: 'BracketRight',
  BACKWARD: 'BracketLeft',
  FILTER: 'KeyF',
  LOCK: 'Semicolon',
  CLONE: 'KeyJ',
  TEXT_CASE: 'KeyK',
  ALIGNL: 'KeyL',
  ALIGNC: 'KeyC',
  ALIGNR: 'KeyR',
  FLIPH: 'KeyH',
  FLIPV: 'KeyV',
  CAPITALISE: 'F3',
  CROP: 'KeyP',
};

const isUserInputOrTextareaFocused = () => {
  return document.activeElement?.nodeName === 'INPUT' || document.activeElement?.nodeName === 'TEXTAREA';
};

export const getId = () => {
  return Math.random()
    .toString(36)
    .substring(2, 8);
};

export const getDefaultProps = (centerX: number, centerY: number, objectProps: any) => {
  objectProps = { ...DEFAULT_SHAPE_PROPS, ...objectProps };
  const x = centerX - objectProps.width / 2;
  const y = centerY - objectProps.height / 2;
  return { x, y, ...DEFAULT_SHAPE_PROPS, ...objectProps };
};

const updatePositionOnKeyPress = (isShift: boolean, key: string, activeObject: fabric.Object) => {
  const delta = isShift ? 10 : 1;

  if (key === KEYS.ARROW_RIGHT) {
    activeObject.set('left', (activeObject.left as number) + delta);
  }
  if (key === KEYS.ARROW_LEFT) {
    activeObject.set('left', (activeObject.left as number) - delta);
  }
  if (key === KEYS.ARROW_DOWN) {
    activeObject.set('top', (activeObject.top as number) + delta);
  }
  if (key === KEYS.ARROW_UP) {
    activeObject.set('top', (activeObject.top as number) - delta);
  }
  activeObject.setCoords();
  activeObject.canvas?.requestRenderAll();
};

export const listenKeyboardEvents = ({
  e,
  activeObject,
  setActiveObject,
  cropEnabled,
  fabricCanvases,
  fabricCanvasesPreview,
  fabricCanvasesHyper,
  fabricCanvas,
  objectToPaste,
  setObjectToPaste,
  setZoom,
  activeCanvasId,
  setActiveCanvasId,
  setSaveCountSync,
  setKeyboardSaveCount,
  setCurrentElementRight,
  zoomLevel,
  setSelectedZoomOption,
  params,
  conversionStep,
  viewModePreview,
  isCanvasBusy,
  setIsCanvasBusy,
  layers,
  setLayers,
}: any) => {
  const isMetaKeyPressed = e.ctrlKey || e.metaKey;
  const isAltKeyPressed = e.altKey;
  const isShiftKeyPressed = e.shiftKey;
  const prevId = activeCanvasId;
  const isIndividualSetEditor = conversionStep === 'preview' && viewModePreview === 'Individual';
  const isGridSetEditor = conversionStep === 'preview' && viewModePreview === 'Grid';
  const isHyperSingleEditor = params.sizeId || params.combination;
  const isLocked = activeObject?.lockRotation;

  if (fabricCanvasesHyper.length) return;
  if (!fabricCanvas) return;
  if (isCanvasBusy) return;
  if (e.type === 'wheel' && !isMetaKeyPressed) return;
  if (cropEnabled && e.code === KEYS.ENTER) ReduxStore.dispatch(cropDoneAction());
  if (cropEnabled && e.code === KEYS.ESCAPE) ReduxStore.dispatch(cancelCropAction());
  if (cropEnabled || isUserInputOrTextareaFocused()) return;

  // console.log('cmd', isMetaKeyPressed, 'alt', isAltKeyPressed, 'shift', isShiftKeyPressed, 'ind-set', isIndividualSetEditor, 'grid-set', isGridSetEditor, 'hp-single', isHyperSingleEditor, 'code', e.code, e);
  if (e.type === 'wheel' && isMetaKeyPressed && !isShiftKeyPressed) {
    e.preventDefault();
    const delta = e.deltaY;
    if (delta && delta > 0) {
      setZoom((z: any) => z > 0.05 ? z - 0.02 : 0.05);
    }
    if (delta && delta < 0) {
      setZoom((z: any) => z < 5 ? z + 0.02 : 5);
    }
    return;
  }
  if (e.type === 'wheel' && isMetaKeyPressed && isShiftKeyPressed && activeObject) {
    e.preventDefault();
    const delta = e.deltaY;
    if (isLocked) return;
    if (delta && delta > 0) {
      if (activeObject.scaleX <= 0.05 || activeObject.scaleY <= 0.05) return;
      activeObject.scaleX *= 0.95;
      activeObject.scaleY *= 0.95;
      activeObject.top += (activeObject.height * activeObject.scaleY * 0.025);
      activeObject.left += (activeObject.width * activeObject.scaleX * 0.025);
      activeObject.canvas.requestRenderAll();
    }
    if (delta && delta < 0) {
      activeObject.scaleX *= 1.05;
      activeObject.scaleY *= 1.05;
      activeObject.top -= (activeObject.height * activeObject.scaleY * 0.025);
      activeObject.left -= (activeObject.width * activeObject.scaleX * 0.025);
      activeObject.canvas.requestRenderAll();
    }
    return;
  }
  if (e.code.includes('Digit')) {
    if (!isAltKeyPressed || !activeObject) return;
    if (isLocked) return;
    const int: number = parseInt(e.code.replace('Digit', ''), 10);
    if (isShiftKeyPressed) {
      if (!['Text', 'Shape'].includes((activeObject as any).layerType)) return;
      activeObject.set('strokeWidth', int);
    } else {
      activeObject.set('opacity', int * 0.1);
    }
    fabricCanvas.requestRenderAll();
    return;
  }
  switch (e.code) {
    case KEYS.CROP:
      if (!activeObject || isShiftKeyPressed || isMetaKeyPressed || isAltKeyPressed) return;
      if (isLocked) return;
      if (!['Elements', 'Image'].includes((activeObject as any).layerType)) return;
      if (isSvg(activeObject as FabricGroupExtended)) {
        return ReduxStore.dispatch(enableSvgCropAction());
      }
      ReduxStore.dispatch(enableCropAction());
      break;
    case KEYS.CAPITALISE:
      if (!activeObject || !isShiftKeyPressed) return;
      if ((activeObject as any).layerType !== 'Text') return;
      if (isLocked) return;
      const t = activeObject.text;
      activeObject.set('text', capitalizeWords(t));
      fabricCanvas.requestRenderAll();
      e.preventDefault();
      break;
    case KEYS.FLIPV:
      if (!isAltKeyPressed || !activeObject) return;
      if ((activeObject as any).layerType === 'Text') return;
      if (isLocked) return;
      activeObject.set('flipY', !activeObject.flipY);
      fabricCanvas.requestRenderAll();
      break;
    case KEYS.FLIPH:
      if (!isAltKeyPressed || !activeObject) return;
      if ((activeObject as any).layerType === 'Text') return;
      if (isLocked) return;
      activeObject.set('flipX', !activeObject.flipX);
      fabricCanvas.requestRenderAll();
      break;
    case KEYS.TEXT_CASE:
      if (!activeObject) return;
      if ((activeObject as any).layerType !== 'Text') return;
      if (isLocked) return;
      if (isAltKeyPressed) {
        activeObject.set('linethrough', !activeObject.linethrough);
      } else if (isShiftKeyPressed && isMetaKeyPressed) {
        const text = activeObject.text;
        const isUpper = text === text.toUpperCase();
        activeObject.set('text', isUpper ? text.toLowerCase() : text.toUpperCase());
      }
      fabricCanvas.requestRenderAll();
      e.preventDefault();
      break;
    case KEYS.ALIGNL:
    case KEYS.ALIGNR:
    case KEYS.ALIGNC:
      e.preventDefault();
      if (!activeObject || !isShiftKeyPressed) return;
      if (isLocked) return;
      if ((activeObject as any).layerType !== 'Text') return;
      const alignMap: any = { KeyL: 'left', KeyC: 'center', KeyR: 'right' };
      activeObject.set('textAlign', alignMap[e.code]);
      // console.log(alignMap[e.code], activeObject.textAlign);
      fabricCanvas.requestRenderAll();
      break;
    case KEYS.CLONE:
      if (params.sizeId || params.combination) return;
      if (!isMetaKeyPressed || !activeObject) return;
      if (fabricCanvasesPreview.length) return;
      setIsCanvasBusy(true);
      setTimeout(() => {
        activeObject.clone((objClone: any) => {
          fabricCanvas.add(objClone);
          (objClone as ExtendedObject).set('isBackground', false);
          (objClone as ExtendedObject).set('id', v4());
          (objClone as ExtendedObject).set('top', objClone.top * 1.2);
          (objClone as ExtendedObject).set('left', objClone.left * 1.2);
          (objClone as ExtendedObject).set('layerType', activeObject.layerType);
          (objClone as any).set('isNameEdited', false);
          if (objClone.layerType === LAYER_TYPE.TEXT) setTextBoxEvents(objClone);
          fabricCanvas.requestRenderAll();
          setIsCanvasBusy(false);
        }, customizedKeysArr);
      }, 0);
      break;
    case KEYS.LOCK:
      if (!isMetaKeyPressed || !activeObject || !isAltKeyPressed) return;
      const lockState = !activeObject.lockRotation;
      activeObject.lockMovementX = activeObject.lockMovementY = activeObject.lockScalingX = activeObject.lockScalingY = activeObject.lockUniScaling = activeObject.lockRotation = lockState;
      activeObject.setControlsVisibility({
        bl: !lockState,
        br: !lockState,
        mb: activeObject.type === 'textbox' ? false : !lockState,
        ml: !lockState,
        mr: !lockState,
        mt: activeObject.type === 'textbox' ? false : !lockState,
        tl: !lockState,
        tr: !lockState,
        mtr: !lockState,
      });
      if (activeObject.type === 'textbox') (activeObject as fabric.Textbox).set('editable', !lockState);
      fabricCanvas?.requestRenderAll();
      setCurrentElementRight('');
      break;
    case KEYS.FILTER:
      if (!isMetaKeyPressed || !activeObject || !isShiftKeyPressed) return;
      if (isLocked) return;
      const { layerType } = activeObject as any;
      if (!['Elements', 'Image'].includes(layerType)) return;
      e.preventDefault();
      setCurrentElementRight('ADJUSTMENTS');
      break;
    case KEYS.FORWARD:
      if (params.sizeId || params.combination) return;
      if (!isMetaKeyPressed || !activeObject) return;
      if (isLocked) return;
      e.preventDefault();
      if ((activeObject as any).isBackground) return;
      if (fabricCanvasesPreview.length) return;
      if (isAltKeyPressed) {
        activeObject.bringToFront();
      } else {
        activeObject.bringForward();
      }
      activeObject.canvas.requestRenderAll();
      activeObject.canvas?.fire('object:modified', {});
      break;
    case KEYS.BACKWARD:
      if (params.sizeId || params.combination) return;
      if (!isMetaKeyPressed || !activeObject) return;
      if (isLocked) return;
      e.preventDefault();
      if ((activeObject as any).isBackground) return;
      if (fabricCanvasesPreview.length) return;
      if (!isAltKeyPressed) {
        const objs2: any = fabricCanvas.getObjects();
        const index = objs2.findIndex((o: any) => o.id === (activeObject as any).id)
        if (objs2[0].isBackground && index === 1) return;
        activeObject.sendBackwards();
      } else {
        const objs1: any = fabricCanvas.getObjects();
        if (objs1[0].isBackground) {
          activeObject.sendToBack();
          activeObject.bringForward();
        } else {
          activeObject.sendToBack();
        }
      }
      activeObject.canvas.requestRenderAll();
      activeObject.canvas?.fire('object:modified', {});
      break;
    case KEYS.SAVE:
      if (!isMetaKeyPressed && fabricCanvas) return;
      e.preventDefault();
      setKeyboardSaveCount((c: any) => c + 1);
      break;
    case KEYS.ARROW_DOWN:
    case KEYS.ARROW_UP:
    case KEYS.ARROW_RIGHT:
    case KEYS.ARROW_LEFT:
      if (activeObject === null) return;
      if (isLocked) return;
      e.preventDefault();
      if (isShiftKeyPressed) {
        if (activeObject.lockMovementY) return;
        updatePositionOnKeyPress(isShiftKeyPressed, e.key, activeObject);
      } else if (isAltKeyPressed) {
        if ((activeObject as any).layerType !== 'Text') return;
        const { lineHeight, charSpacing, fontSize } = activeObject;
        const h = charSpacing * fontSize;
        e.key === KEYS.ARROW_DOWN && activeObject.set('lineHeight', lineHeight > 0.1 ? lineHeight - 0.1 : lineHeight);
        e.key === KEYS.ARROW_UP && activeObject.set('lineHeight', lineHeight + 0.1);
        e.key === KEYS.ARROW_RIGHT && activeObject.set('charSpacing', (h + 1000) / fontSize);
        e.key === KEYS.ARROW_LEFT && activeObject.set('charSpacing', h > 100 ? (h - 1000) / fontSize : charSpacing);
        fabricCanvas.requestRenderAll();
        e.preventDefault();
      } else {
        if (activeObject.lockMovementY) return;
        updatePositionOnKeyPress(isShiftKeyPressed, e.key, activeObject);
      }
      break;
    case KEYS.ESCAPE:
      setActiveObject(null);
      fabricCanvas.discardActiveObject();
      fabricCanvas.requestRenderAll();
      break;
    case KEYS.UNDO_REDO:
      if (!isMetaKeyPressed) return;
      if (isCanvasBusy) return;
      setActiveObject(null);
      if (layers.findIndex((l: any) => l.isOpen === true) !== -1) {
        setLayers(layers.map((l: any) => ({ ...l, isOpen: false })));
      }
      if (e.shiftKey) {
        if (!(fabricCanvas as any).historyRedo.length) return openToast('info', 'No more history.');
        setIsCanvasBusy(true);
        if (fabricCanvas && fabricCanvasesPreview.length) {
          Promise.all(fabricCanvasesPreview.map((canvas: any) => new Promise((res) => {
            canvas.redo();
            res();
          }))).finally(() => {
            setIsCanvasBusy(false);
            setActiveCanvasId('');
            setTimeout(() => setActiveCanvasId(prevId), 100);
          });
        } else {
          setTimeout(() => {
            fabricCanvas && fabricCanvas.redo();
            const z = fabricCanvas?.getZoom() || zoomLevel;
            setSelectedZoomOption(`${Math.round(z * 100)}%`);
            setIsCanvasBusy(false);
            setActiveCanvasId('');
            setTimeout(() => setActiveCanvasId(prevId), 100);
          }, 0);
        }
      } else {
        if (!(fabricCanvas as any).historyUndo.length) return openToast('info', 'No more history.');
        setIsCanvasBusy(true);
        if (fabricCanvas && fabricCanvasesPreview.length) {
          Promise.all(fabricCanvasesPreview.map((canvas: any) => new Promise((res) => {
            canvas.undo();
            res();
          }))).finally(() => {
            setIsCanvasBusy(false);
            setActiveCanvasId('');
            setTimeout(() => setActiveCanvasId(prevId), 100);
          });
        } else {
          setTimeout(() => {
            fabricCanvas && fabricCanvas.undo();
            const z = fabricCanvas?.getZoom() || zoomLevel;
            setSelectedZoomOption(`${Math.round(z * 100)}%`);
            setIsCanvasBusy(false);
            setActiveCanvasId('');
            setTimeout(() => setActiveCanvasId(prevId), 100);
          }, 0);
        }
      }
      break;
    case KEYS.SELECT_ALL:
      if (!isMetaKeyPressed) return;
      if (fabricCanvasesPreview.length) return;
      fabricCanvas.discardActiveObject();
      const sel = new fabric.ActiveSelection(fabricCanvas.getObjects(), {
        canvas: fabricCanvas,
      });
      fabricCanvas.setActiveObject(sel);
      setActiveObject(sel);
      fabricCanvas.requestRenderAll();
      e.preventDefault();
      e.stopPropagation();
      break;
    case KEYS.ZOOM_IN:
      if (!isMetaKeyPressed || isAltKeyPressed) return;
      if (isLocked) return;
      e.preventDefault();
      if (activeObject && isShiftKeyPressed) {
        activeObject.scaleX *= 1.05;
        activeObject.scaleY *= 1.05;
        activeObject.top -= (activeObject.height * activeObject.scaleY * 0.025);
        activeObject.left -= (activeObject.width * activeObject.scaleX * 0.025);
        activeObject.canvas.requestRenderAll();
      } else if (!isShiftKeyPressed) {
        setZoom((zoom: number) => zoom + 0.05);
      }
      break;
    case KEYS.ZOOM_OUT:
      if (!isMetaKeyPressed || isAltKeyPressed) return;
      if (isLocked) return;
      e.preventDefault();
      if (activeObject && isShiftKeyPressed) {
        if (activeObject.scaleX <= 0.05 || activeObject.scaleY <= 0.05) return;
        activeObject.scaleX *= 0.95;
        activeObject.scaleY *= 0.95;
        activeObject.top += (activeObject.height * activeObject.scaleY * 0.025);
        activeObject.left += (activeObject.width * activeObject.scaleX * 0.025);
        activeObject.canvas.requestRenderAll();
      } else if (!isShiftKeyPressed) {
        setZoom((zoom: number) => zoom - 0.05);
      }
      break;
    case KEYS.DELETE_BCS:
    case KEYS.DELETE_DLT:
      if (params.sizeId || params.combination) return;
      if (isLocked) return;
      if (!activeObject) return;
      if (fabricCanvasesPreview.length) return;
      fabricCanvas.remove(...fabricCanvas.getActiveObjects());
      fabricCanvas.discardActiveObject();
      fabricCanvas.requestRenderAll();
      setActiveObject(null);
      setActiveCanvasId('');
      setTimeout(() => setActiveCanvasId(prevId), 100)
      break;
    default:
      return;
  }
};

export const loadFonts = (productId: string, fontsArr: any[], callback: any) => {
  // console.log(fontsArr, "fontsArr")
  Promise.all(fontsArr.map((fObj: any) => {
    let { isCustomFont, fontFamily } = fObj;
    if (isCustomFont === undefined) isCustomFont = true;
    // console.log(fObj)
    return new Promise((res: any, rej: any) => {
      const fontData = {
        weight: 400,
        src: `url('${IMAGE_BASE_URL}font_files/${isCustomFont ? productId : 'webfonts'}/${fontFamily.replace(/ /g, '')}.ttf')`,
      };
      const obs = new FontFaceObserver(fontFamily, fontData);
      // @ts-ignore
      const ff = new FontFace(fontFamily, fontData.src);
      ff.load()
        // @ts-ignore
        .then(f => {
          // @ts-ignore
          document.fonts.add(f);
          console.log("font Loaded")
        })
        .catch((err: any) => {
          console.log(err, "this is a font error");
          openToast('warn', `Missing Font: ${fontFamily}`)
        });
      obs
        .load()
        .then((f) => {
          res();
        })
        .catch((err: any) => {
          console.log(err);
          res();
        });
    })
  })).finally(() => {
    callback && callback();
  })
}

export const getFontsArr = (canvasArr: any) => {
  const fontsArr: any = [];
  canvasArr.forEach((canvasObj: any) => {
    canvasObj.data?.children?.forEach((child: any) => {
      if (child.type === 'textbox') {
        const i = fontsArr.findIndex((f: any) => f.fontFamily === child.fontFamily);
        if (i === -1) {
          fontsArr.push({ fontFamily: child.fontFamily, isCustomFont: child.isCustomFont });
        }
      }
    })
  })
  return fontsArr;
}