import React, { Suspense, FC } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import './index.scss';
import ResetPassword from '../../components/Authentication/ResetPassword';

const AiquireResetPassword: FC = () => (
  <div className="aiquire-reset-wrapper">
    <ErrorBoundary>
      <Suspense fallback={<FullScreenSpinner />}>
        <ResetPassword />
      </Suspense>
    </ErrorBoundary>
  </div>
);

export default AiquireResetPassword;

