import { FETCH_ELEMENTS, FetchElementsAction, SET_ELEMENTS, SetElementsAction, FetchCategoriesAction, FETCH_CATEGORIES, SET_CATEGORIES, SetCategoriesAction } from "../types";

export const fetchElementsAction = (search: string, page: number = 1, catName: string = '', seeAll: boolean,
  selectedElementId: number,
  page_size: number, type: string): FetchElementsAction => ({
    type: FETCH_ELEMENTS,
    payload: {
      search,
      page,
      catName,
      seeAll,
      selectedElementId,
      page_size,
      type
    }
  })

export const setElementsAction = (elements: any[], page: number, catName: string = '', page_size: number, search: string, seeAll: boolean, selectedElementId: number, totalPages: number, type: string): SetElementsAction => ({
  type: SET_ELEMENTS,
  payload: {
    elements,
    page,
    catName,
    loading: false,
    search,
    seeAll,
    selectedElementId,
    page_size,
    totalPages,
    type
  }
})

export const fetchCategoriesAction = (categoryPage: number, categoryPages: number, type: string): FetchCategoriesAction => ({
    type: FETCH_CATEGORIES,
    payload: {
      categoryPage,
      categoryPages,
      type
    }
  })

export const setCategoriesAction = (categories: any[], categoryPage: number, categoryPages: number): SetCategoriesAction => ({
  type: SET_CATEGORIES,
  payload: {
    categoryPages,
    categories,
    categoryPage
  }
})