import React, { useEffect, useState, useRef, useCallback } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AuthState, RolesState } from "./store/types";
import {
  getFontsList,
  fetchUserPermissions,
  setUserPermissions,
  setBrandPermissions,
  fetchPendingApprovalCount,
  setPendingApprovalCount,
  setActiveFolderCreate,
  fetchAccessToken,
  setAccessToken,
} from "./store/actions";
import { FullScreenSpinner } from "./components/shared/Spinner";
import { openToast } from "./Toasts";
import { IMAGE_BASE_URL } from "./utilities/paths";
import {
  loadIntercom,
  updateIntercom,
  startTour,
  WELCOME_TOUR_ID,
  getTourStateValue,
  updateTourState,
  turnOffAutoTour,
} from "./utilities/services/intercom";
import NavBar from "./components/NavBar";
import backNavigationIcon from "./assets/svg/BackArrowAgencyFiles.svg";
// import moment from 'moment';
import { Layout, Header } from "pyxis-ui-kit";
import { identifyMixpanel } from "./utilities/services/mixpanel";

const PrivateRoute: React.FunctionComponent<any> = ({
  component: Component,
  productIndependent,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn, userData, accessToken, token } = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const selectedProductId = useSelector(
    (state: { roles: RolesState }) => state.roles.selectedProductId
  );
  const brand_id = useSelector(
    (state: { roles: RolesState }) => state.roles.brand_id
  );
  const userPermissions = useRef([]);
  const brandPermissions = useRef([]);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [brandPermissionLoaded, setBrandPermissionLoaded] = useState(false);
  const isAgencyFile =
    location.pathname === "/cocreate/agency-files" ||
    location.pathname === "/cocreate/shared-agency";

  useEffect(() => {
    let unlisten: any;
    if (isLoggedIn) {
      unlisten = history.listen((location, action) => {
        if (location.pathname !== "/login") {
          updateIntercom();
        }
      });
    }
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setActiveFolderCreate({ folder: 0 }));
  }, [selectedProductId]);

  useEffect(() => {
    if (isLoggedIn && userData) {
      const { firstName, lastName, emailId } = userData;
      loadIntercom({ name: `${firstName} ${lastName}`, email: emailId });
      //fetch access token
      // dispatch(fetchAccessToken((res:any,err:boolean)=>{
      //   if(!err){
      //     dispatch(setAccessToken(res.data.key))
      //   }else{
      //     // dispatch(setAccessToken(null))
      //   }
      // }))
      identifyMixpanel(userData.emailId, `${firstName} ${lastName}`);
    }
  }, [isLoggedIn, userData]);

  useEffect(() => {
    if (isLoggedIn && brand_id) {
      if (brandPermissionLoaded) setBrandPermissionLoaded(false);
      dispatch(
        fetchUserPermissions(
          { entityType: "brand", entityId: brand_id },
          (res: any, err: boolean) => {
            if (!err) {
              brandPermissions.current = res.permissions;
              dispatch(setBrandPermissions(res.permissions));
            }
            setBrandPermissionLoaded(true);
          }
        )
      );
    }
  }, [isLoggedIn, brand_id]);

  useEffect(() => {
    if (selectedProductId && selectedProductId !== "none" && isLoggedIn) {
      //fetching permissions
      dispatch(
        fetchUserPermissions(
          { entityType: "product", entityId: selectedProductId },
          (res: any, err: boolean) => {
            if (!err) {
              userPermissions.current = res.permissions;

              //auto start welcome tour if user has permission to create
              if (
                res.permissions.includes("create-new-banners") &&
                !getTourStateValue("WELCOME_TOUR")
              ) {
                startTour(WELCOME_TOUR_ID);
                updateTourState("WELCOME_TOUR", true);
                updateTourState("CHOOSE_SET_DESIGN_SECOND_TOUR", true);
              } else {
                turnOffAutoTour();
              }
              //stop auto tour if doesnot have permission
              if (
                !res.permissions.includes("view-approval-workflow") ||
                !res.permissions.includes("manage-approval-workflow")
              ) {
                updateTourState("APPROVAL_WORKFLOW_TOUR", true);
                updateTourState("NO_WORKFLOWS_TOUR", true);
                updateTourState("SOME_WORKFLOWS_TOUR", true);
              }

              //fetch pending apporval count
              if (res.permissions.indexOf("grand-approval") > -1) {
                if (userData)
                  dispatch(
                    fetchPendingApprovalCount(
                      {
                        user_id: userData.userId,
                        product_id: selectedProductId,
                      },
                      (res: any, err: boolean) => {
                        if (!err) {
                          dispatch(
                            setPendingApprovalCount(
                              res.data.pending_approval_count
                            )
                          );
                        }
                      }
                    )
                  );
              } else {
                dispatch(setPendingApprovalCount(0));
              }

              dispatch(setUserPermissions(res.permissions));

              if (
                res.permissions.indexOf("view-typography") > -1 ||
                res.permissions.indexOf("create-new-banners") > -1
              ) {
                // loading fonts to document
                const head =
                  document.head || document.getElementsByTagName("head")[0];
                const style: any = document.createElement("style");
                style.type = "text/css";
                let fonts = "";
                const params = {
                  productId: selectedProductId,
                  pageSize: 1000,
                  webfont: true,
                };
                dispatch(
                  getFontsList(params, (res: any, isError: any) => {
                    let web: any = [];
                    if (!isError) web = res.data.results || [];
                    if (isError) openToast("error", "Unable to fetch fonts");
                    dispatch(
                      getFontsList(
                        { ...params, webfont: false },
                        (res1: any, isError1: any) => {
                          let uploaded: any = [];
                          if (!isError1) uploaded = res1.data.results || [];
                          if (isError1)
                            openToast("error", "Unable to fetch your fonts");
                          web.concat(uploaded).forEach((obj: any) => {
                            const { file, font_family, font_style } = obj;
                            fonts = fonts.concat(
                              `@font-face{font-family: "${font_family}-${font_style}";src: url('${IMAGE_BASE_URL}${file}') format('truetype'), local('${font_family}-${font_style}');}`
                            );
                          });
                          if (style.styleSheet) {
                            // This is required for IE8 and below.
                            style.styleSheet.cssText = fonts;
                          } else {
                            style.appendChild(document.createTextNode(fonts));
                          }
                          head.appendChild(style);
                          style.onload = () => {
                            setFontsLoaded(true);
                          };
                        }
                      )
                    );
                  })
                );
              } else {
                setFontsLoaded(true);
              }
            } else {
              setFontsLoaded(true);
            }
          }
        )
      );
    }
  }, [selectedProductId, isLoggedIn]);

  return (
    <>
      {isLoggedIn && (
        <Layout>
          {!!isAgencyFile ? (
            <></>
          ) : (
            <Header className="site-header">
              <NavBar />
            </Header>
          )}
        </Layout>
      )}
      <Route
        {...rest}
        stat
        render={(props) =>
          isLoggedIn ? (
            selectedProductId === "none" &&
            !productIndependent ? null : (productIndependent ? (
                selectedProductId === "none" ? (
                  brand_id ? (
                    brandPermissionLoaded
                  ) : (
                    true
                  )
                ) : (
                  selectedProductId &&
                  fontsLoaded &&
                  (brand_id ? brandPermissionLoaded : true)
                )
              ) : (
                selectedProductId && fontsLoaded
              )) ? (
              <Component
                {...{
                  ...props,
                  userPermissions: userPermissions.current,
                  brandPermissions: brandPermissions.current,
                }}
              />
            ) : (
              <FullScreenSpinner />
            )
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { referer: props.location } }}
            />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
