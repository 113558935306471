import React, { createContext, useState } from 'react';

export type ZoomContext = {
  zoomLevel: number;
  setZoom: (zoomLevel: number) => void;
  displayZoomOption: string;
  setSelectedZoomOption: Function;
};

export const ZoomContext = createContext({} as ZoomContext);
type ZoomContextProvider = {
  children: React.ReactNode;
};

const ZoomContextProvider = ({ children }: ZoomContextProvider) => {
  const [zoomLevel, setZoom] = useState(1);
  const [displayZoomOption, setSelectedZoomOption] = useState<string>('60%');

  return (
    <ZoomContext.Provider
      value={{
        zoomLevel,
        setZoom,
        displayZoomOption,
        setSelectedZoomOption,
      }}
    >
      {children}
    </ZoomContext.Provider>
  );
};

export default ZoomContextProvider;
