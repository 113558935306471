import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    makeStyles,
    Dialog,
    DialogActions
} from '@material-ui/core';
import { DeleteOutline as DeleteIcon, OpenInNew as ExportIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowBack, FileCopy } from '@material-ui/icons'
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'pyxis-ui-kit';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import httpservice from '../../HttpService/HttpService'


const useStyles = makeStyles({
    dialogContainer: {
        borderRadius: '20px', // Change the border radius value as per your requirement
    },
    backBtn: {
        background: '#EEE4FF',
        borderRadius: '6px',
        padding: '0.8rem 1.2rem',
        color: 'black',
        fontSize: '12px',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#EEEEFF',
        },
        height: '48px'
    },
    headerRow: {
        backgroundColor: '#FCFCFF',
        '& .MuiTableCell-root': {
            padding: '16px', // Adjust the padding as per your requirements
        },
    },
    deleteButton: {
        backgroundColor: '#FFF9F9',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        color: '#F16060',
        fontSize: '1.4rem',
        padding: '6px 12px',
        lineHeight: '1.43',
        height: '36px',
    },
    cancelDeleteButton: {
        background: '#FFF9F9',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        padding: '6px 12px',
        fontSize: '12px',
        // width: '64px',
        height: '28px',
        lineHeight: '1.43',

        color: '#F16060',
    },
    confirmDeleteButton: {
        padding: '6px 12px',
        fontSize: '12px',
        height: '36px',
        background: '#F16060',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        color: 'white',
        marginLeft: '2rem',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    deleteIcon: {
        color: '#F16060',
        fontSize: '14px',
    },
    deletingRow: {
        background: '#FFEBEB',
    },
    copy: {
        backgroundColor: '#6B38C4',
        borderRadius: '6px'

    },
    dialogContent: {
        padding: '3rem 2rem',
        fontSize: '16px',
        lineHeight: '135.02%',
        height: '320px',
        overflow: 'scroll',
        margin: '3rem 3rem 2rem 3rem',
        background: '#FCFCFF',
        borderRadius: '12px',
    }
});

const CreationsDetailsModal = ({ open, handleClose, item, handleCancelDelete, isDeleting, handleDeleteRow, handleConfirmDelete }) => {
    const classes: any = useStyles();
    const [deletingRowIndex, setDeletingRowIndex] = useState(-1);
    const getTokenOrPublic = (state: any) => state.authentication.token || state.publicLink.token;
    const state = useSelector((state) => state)
    const [reprompts, setReprompts] = useState<any[]>([])
    const [activeRepromptIndex, setActiveRepromptIndex] = useState<number>(0)
    const [reprompt, setReprompt] = useState<any>('')

    useEffect(() => {
        if (item?.id)
            getHistory();
    }, [open])

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(item?.output);
        toast.success('Text Copied to Clip Board')
    }

    const getHistory = async () => {

        // console.l

        await httpservice.get(process.env.REACT_APP_DESIGN_URI, `/api/creation/history/${item?.id}`, getTokenOrPublic(state)).
            then((response) => {
                setReprompts(response.data.data.sort((a: any, b: any) => a.id - b.id))
                setActiveRepromptIndex(response.data.data.length - 1);
                setReprompt(response.data.data[response.data.data.length - 1].user_input == 'Regenerate text' ? '' : response.data.data[response.data.data.length - 1].user_input)
            })
    }


    const goRight = () => {
        if (activeRepromptIndex < reprompts.length - 1) {
            setActiveRepromptIndex(activeRepromptIndex + 1);
            setReprompt(reprompts[activeRepromptIndex + 1].user_input == 'Regenerate text' ? '' : reprompts[activeRepromptIndex + 1].user_input)

            return;
        }

        return;
    }

    const goLeft = () => {
        if (activeRepromptIndex > 0) {
            setActiveRepromptIndex(activeRepromptIndex - 1);
            setReprompt(reprompts[activeRepromptIndex - 1].user_input == 'Regenerate text' ? '' : reprompts[activeRepromptIndex - 1].user_input)
            return;
        }

        return;
    }


    return (
        <Dialog
            classes={{ paper: classes.dialogContainer }}
            fullWidth maxWidth={"md"} open={open} onClose={() => handleClose(false)}>
            <div className='dialog-header' style={{ display: "flex", justifyContent: "space-between", margin: "2rem 2rem" }}>
                <IconButton
                    aria-label='close'
                    onClick={() => handleClose(false)}
                    className={classes.backBtn}
                >
                    <ArrowBack />
                    <Typography style={{ marginLeft: '1rem', fontSize: "12px" }}>Back</Typography>
                </IconButton>

                <div className='dialog-title'>
                    <div className='my-creations' style={{ fontSize: "18px", color: "#18236B" }}>
                        My creations
                    </div>
                    <div className='sub-title'>
                        Select any text to add new suggestions
                    </div>
                </div>
                <IconButton
                    aria-label='close'
                    onClick={() => handleClose(false)}

                >
                    <CloseIcon style={{ color: "black" }} />
                </IconButton>
            </div>
            <div className='dialog-content'
                style={{
                    // padding: '3rem 2rem',
                    fontSize: '16px',
                    lineHeight: '135.02%',
                    height: '320px',
                    overflow: 'scroll',
                    margin: '3rem 3rem 2rem 3rem',
                    background: '#FCFCFF',
                    borderRadius: '12px',
                }}
            >
                {reprompts.length > 1 &&
                    <div
                        className='pagination-btn-container'
                        style={{
                            padding: "2rem 1rem", background: "white", borderRadius: "8px",
                            margin: "2rem 1rem", fontFamily: 'Inter'
                        }}>
                        <span
                            style={{ color: activeRepromptIndex === 0 ? '#E0E0E0' : '#838383' }}
                            className='left arrow-btn' onClick={() => goLeft()}>{"<  "}</span>
                        <span style={{ width: '5px' }}>{" "}</span>
                        <span>{activeRepromptIndex + 1}/{reprompts.length}</span>
                        <span style={{ width: '5px' }}>{" "}</span>
                        <span className='right arrow-btn'
                            style={{ color: activeRepromptIndex === reprompts.length - 1 ? '#E0E0E0' : '#838383' }}
                            onClick={() => goRight()}>{"  >"}</span>
                        &nbsp;
                        {activeRepromptIndex != 0 && <span>{reprompt}</span>}</div>
                }
                {item && <div
                    style={{ padding: "0rem 2rem" }}
                    dangerouslySetInnerHTML={{ __html: reprompts.length > 1 ? parseTextToHTML(reprompts[activeRepromptIndex]?.output) : parseTextToHTML(item?.output) }}>
                </div>}
            </div>
            <DialogActions>
                <div className='' style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
                    {isDeleting ? (
                        <>
                            <Button
                                className={classes.cancelDeleteButton}
                                onClick={handleCancelDelete}
                                variant="outlined"
                                style={{ textTransform: 'none' }}
                            >
                                Cancel
                            </Button>
                            <button
                                className={classes.confirmDeleteButton}
                                style={{ textTransform: 'none' }}
                                onClick={() => { handleConfirmDelete(item.id) }}
                            >
                                Confirm Delete
                            </button>
                        </>
                    ) : (
                        <>
                            <IconButton
                                className={classes.deleteButton}
                                aria-label="Delete"
                                onClick={() => handleDeleteRow()}
                            >
                                <DeleteIcon className={classes.deleteIcon} />
                                <Typography style={{ fontSize: '1.4rem', marginLeft: '3px' }}>Delete</Typography>
                            </IconButton>
                        </>
                    )}
                    <Button btnsize="md"
                        type="primary"
                        className="primary submit"
                        // onClick={handleSubmit}
                        icon={<CopyOutlined style={{ fontSize: '14px' }} />}
                        style={{ position: "relative", margin: "2rem 2rem", padding: "0 8rem", display: "flex", flexDirection: 'row', gap: '5px' }}
                        onClick={copyToClipBoard}

                    >
                        Copy to Clipboard
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default CreationsDetailsModal;

function parseTextToHTML(text) {

    const encodedText = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");

    const finalHTML = `<div style="white-space: pre-line;">${encodedText}</div>`;

    return finalHTML;
}