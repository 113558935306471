import React, { useEffect } from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { WorkflowState } from '../../../store/types';
import { setWorkflowTopbarTab } from '../../../store/actions';
import { useHistory } from 'react-router-dom';


const tabs = [{
  name: "DashBoard",
  type: "dashboard",
  style: {
    left: '0rem',
    width: '12.2rem'
  }
},{
  name: "Create Workflows",
  type: "create-workflows",
  style: {
    left: '12.6rem',
    width: '17.4rem'
  }
}]

const TopBar:React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state:{workflows:WorkflowState})=>(state.workflows))
  const { workflowTopBarTab,workflows } = state;
  
  useEffect(()=>{
    let {location: {pathname}} = history;
    if(pathname === '/cocreate/workflows') dispatch(setWorkflowTopbarTab(2));
    else dispatch(setWorkflowTopbarTab(1));
  },[])

  const changeTab = (tabIndex:number) => {
    if(workflowTopBarTab !== tabIndex){
      dispatch(setWorkflowTopbarTab(tabIndex))
      let newurl = window.location.origin + (tabIndex === 1? '#/cocreate/workflow/dashboard':'#/cocreate/workflows');
      window.history.pushState({path:newurl},'',newurl);
    }
  }
  
  return <div className="workflow-topbar">
    {workflows.length > 0 && <div className="tabs">
      {tabs.map((tab,index)=>(
        <div key={tab.name} className={workflowTopBarTab === (index+1)?"tab active": "tab"}
          onClick={()=>{
            changeTab(index+1)
          }}
        >{tab.name}</div>
      ))}
      {workflowTopBarTab > -1 && <hr className="highlight" style={tabs[workflowTopBarTab-1].style} />}
    </div>}
  </div>
}

export default TopBar;