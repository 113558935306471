import { AgencyToBeShared } from "../../store/types";

export const formatDate = (dateString: Date): string => {
  const date = new Date(dateString);

  const day = date
    .getDate()
    .toString()
    .padStart(2, "0"); // Ensures day is 2 digits
  const month = date.toLocaleString("en-US", { month: "short" }); // Short month name
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const getAgencyFromParams = (url: string) => {
  const params = new URLSearchParams(url);
  const agency = params.get("agency");
  return agency;
};

export const createShareableAgencyURL = (
  baseURL: string,
  data: AgencyToBeShared
) => {
  const { shareable_link_key } = data;

  // Construct the query parameters
  const params = new URLSearchParams({
    shareable_link_key,
  });

  // Build and return the full URL
  return `${baseURL}?${params.toString()}`;
};
