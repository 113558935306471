import { getObjectSize } from "./getObjectSize";

export const getObjectLeftAndTop = (obj: any) => {
  const angle = (obj.angle / 180) * Math.PI;
  const angleSin = Math.sin(angle);
  const angleCos = Math.cos(angle);
  const [widthBB, heightBB] = getObjectSize(obj)
  const width = obj.width * obj.scaleX;
  const height = obj.height * obj.scaleY
  
  const centerX = obj.left + widthBB / 2;
  const centerY = obj.top + heightBB / 2;

  const left = centerX + (-width / 2) * angleCos + (height / 2) * angleSin
  const top = centerY + (-width / 2) * angleSin - (height / 2) * angleCos 

  return {left, top}
}

export const getObjectListFromBoundaries = (objectList: Array<any>) => {
  return objectList.map(obj => ({...obj, ...getObjectLeftAndTop(obj)}))
};
