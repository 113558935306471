export const capitalizeWords = (str: string) => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const FONT_STYLE = {
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE',
  OVERLINE: 'OVERLINE',
  LINETHROUGH: 'LINETHROUGH',
};
