import { SetExportedProjects, ExportState, SET_EXPORTED_PROJECTS } from "../types/export";


const initialState:ExportState = {
    exportedProjects: []
}

export const exportReducer = (state:ExportState = initialState,action: SetExportedProjects):ExportState => {
    switch(action.type){
        case SET_EXPORTED_PROJECTS:{
            return {
                ...state,
                exportedProjects: action.payload
            }
        }
        default: {
            return state;
        }
    }
}