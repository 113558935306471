import React, { lazy, Suspense, FC } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { FullScreenSpinner } from '../../components/shared/Spinner';
import { WorkFlowView } from '../../components/WorkFlow';

import './index.scss';
import { withRouter, Redirect } from 'react-router-dom';
import PermissionNotAllowed from '../../components/shared/PermissionNotAllowed';

const getSuspense = (ComponentRef: FC):JSX.Element => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <ComponentRef />
    </Suspense>
  );
}

const WorkFlowDetail: FC<any> = ({userPermissions}) => {

  const SuspenseComponent = () => {
    return (
      <>
        <div className="workflow-detail-wrap">
        {userPermissions.indexOf('view-approval-workflow') > -1?
          <WorkFlowView />
          :<PermissionNotAllowed title="You do not have access to this feature as per your role" />
        }
        </div>
      </>
    );
  }

  return (
    <div className="aiquire-workflow-detail-wrapper">
      <ErrorBoundary>
        {getSuspense(SuspenseComponent)}
      </ErrorBoundary>
    </div>
  );
};

export default WorkFlowDetail;

