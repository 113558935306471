import { CANVAS_GRID_POSITION_X, CANVAS_GRID_POSITION_Y } from "../constants";
import { resizeObj } from "../helpers/resizeObj";
import { getObjectGridPositionX, getObjectGridPositionY } from "../helpers/positioning";
import { getObjectWidth, getObjectHeight } from "../helpers/getObjectSize";

const getObjectLeft = (prevCanvasWidth: number, canvasWidth: number, obj: any, resizedObj: any, ratioX: number) => {
  const width = getObjectWidth(obj)
  const newObjectWidth = getObjectWidth(resizedObj)
  const {left} =obj
  const xObjCenter = left + width / 2;
  const objectGridPositionX = getObjectGridPositionX(xObjCenter, width, prevCanvasWidth);

  switch (objectGridPositionX) {
    case CANVAS_GRID_POSITION_X.LEFT: {
      if (left >= 0) {
        return left * ratioX;
      } else {
        return left * (newObjectWidth / width);
      }
    }

    case CANVAS_GRID_POSITION_X.CENTER: {
      return xObjCenter * ratioX - newObjectWidth / 2;
    }

    case CANVAS_GRID_POSITION_X.RIGHT: {
      const rightMargin = prevCanvasWidth - left - width;

      if (rightMargin >= 0) {
        return canvasWidth - rightMargin * ratioX - newObjectWidth;
      } else {
        return canvasWidth - (newObjectWidth - Math.abs(rightMargin * (newObjectWidth / width)));
      }
    }
  }
};

const getObjectTop = (prevCanvasHeight: number, canvasHeight: number, obj: any, resizedObj: any, ratioY: number) => {
  const height = getObjectHeight(obj);
  const newObjectHeight = getObjectHeight(resizedObj)
  const {top} = obj
  const yObjCenter = top + height / 2;
  const objectGridPositionY = getObjectGridPositionY(yObjCenter, height, prevCanvasHeight);

  switch (objectGridPositionY) {
    case CANVAS_GRID_POSITION_Y.TOP: {
      if (top >= 0) {
        return top * ratioY;
      } else {
        return top * (newObjectHeight / height);
      }
    }

    case CANVAS_GRID_POSITION_Y.CENTER:
      return yObjCenter * ratioY - newObjectHeight / 2;

    case CANVAS_GRID_POSITION_Y.BOTTOM: {
      const bottomMargin = prevCanvasHeight - top - height;

      if (bottomMargin >= 0) {
        return canvasHeight - bottomMargin * ratioY - newObjectHeight;
      } else {
        return canvasHeight - (newObjectHeight - Math.abs(bottomMargin * (newObjectHeight / height)));
      }
    }
  }
};

export const resizeObjectSimple = (obj: any, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) => {
  let ratioX = canvasWidth / prevWidth;
  let ratioY = canvasHeight / prevHeight;

  const resizedObj = resizeObj(obj, ratioX, ratioY);
  const left = getObjectLeft(prevWidth, canvasWidth, obj, resizedObj, ratioX);
  const top = getObjectTop(prevHeight, canvasHeight, obj, resizedObj, ratioY);

  return {
    ...resizedObj,
    top,
    left
  };
};

export const resizeObjectListSimple = (objList: Array<any>, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number) =>
  objList.map(obj => resizeObjectSimple(obj, prevWidth, prevHeight, canvasWidth, canvasHeight));
