import React, { useEffect,useState,useCallback, useContext } from 'react';
import TopBar from '../../shared/TopBar';
import Masonry from '../../shared/Masonry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight,faSpinner } from '@fortawesome/free-solid-svg-icons';
import GreyBackground from '../../../assets/img/grey-background.png';
import './index.scss';
import { FolderState, HyperProjectFoldersState } from '../../../store/types';
import { getHyperProject,setHyperProjectData,setProject,setSelectedHyperSizes,
    addSizesToHpproject,deleteSizesFromHpproject, getTemplateSizes, getPopularTemplateSizes } from '../../../store/actions';
import { useHistory,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import {openToast} from '../../../Toasts';
import Img from '../../ImageLoader/ImageLoader';
import { getTourStateValue, HYPER_PERSONALIZE_SET_TOUR_ID, startTour, updateTourState } from '../../../utilities/services/intercom';
import { Button, Checkbox, Menu, MenuItem, Title } from 'pyxis-ui-kit';
import { compareStrings } from '../../../utilities/common-function';
import { chownSync } from 'fs';
import { CreateContextAiquire } from '../../../CreateContextAiquire/CreateContextAiquire';

interface CardInterface {
    creative: any;
    isSelected: boolean;
    handleSelectClick: Function;
}

const getRemovedAndAddedIds = (selectedIds: number[], sizesArr:any[]) => {
    let initialSelected: number[] = sizesArr.reduce((acc:any,val:any)=>{
        if(val.selected_for_combination){
            return [...acc,val.id];
        }else{
            return acc;
        }
    },[]);
    let removedIds = initialSelected.filter((val:number)=>selectedIds.indexOf(val) === -1);
    let addedIds = selectedIds.filter((val:number)=>initialSelected.indexOf(val) === -1);
    return {
        added: addedIds,
        removed: removedIds
    }
}

const ActionBar:React.FC<any> = ({isSelected, handleSelectAllClick}) => {

    return <div className="action-bar">
        <Checkbox
            checked={isSelected} 
            id="hyper-select-all" 
            onChange={()=>handleSelectAllClick()}
        />
        <label className="ml-10" onClick={()=>handleSelectAllClick()}>Select all</label>
    </div>
}

const Creatives:React.FC<any> = ({data,handleHyperProjectSelect,selectedHyperSizes}) => {
    return <div className="creatives">
        <Masonry 
            breakpointCols={{
                default: 5,
                1500: 4,
                1400: 3,
                1000: 2,
                800: 1
            }}
            className="masonry"
            columnClassName="column">
            {data.map((creative:any,index:number)=>(
                <Card 
                    key={creative.id} 
                    creative={creative} 
                    isSelected={selectedHyperSizes.indexOf(creative.id)>-1} 
                    handleSelectClick={handleHyperProjectSelect} 
                />
            ))}
        </Masonry>
    </div>
}

const Card: React.FC<CardInterface> = (props) =>{
    const { creative, isSelected, handleSelectClick } = props;

    return <div className={isSelected ? "card selected" : "card"}>
        <div className={isSelected? "card-body selected":"card-body"}>
            <Img src={(IMAGE_BASE_URL + creative?.defatult_rendered_image)||""} />
            <div className="overlay">
                <div className="options">
                    <label className="checkbox-container">
                        <Checkbox checked={isSelected} onChange={()=>handleSelectClick(creative.id)} />
                    </label>
                </div>
            </div>
        </div>
        <div className="footer">
           {creative && <p className="text">{creative.name}</p>}
           <div className="dimension">
            {creative && <span>{creative.width}</span>}x
            {creative && <span>{creative.height}</span>}
           </div>
        </div>
    </div>
}


const Selection:React.FC = () => {
    const [loading,setLoading] = useState(false);
    const state = useSelector((state:{hyperProject:HyperProjectFoldersState})=>state.hyperProject);
    const history = useHistory();
    const dispatch = useDispatch();
    const params:any = useParams();
    const [dataLoading,setDataLoading] = useState(false);
    const { hyperProjectData,selectedHyperSizes } = state;

    const [hpSizes, setHpSizes] = useState([]);
    const [tabsArr, setTabsArr] = useState([]);
    const [currentTab, setCurrentTab] = useState<string>('All Sizes');

    useEffect(()=>{
        if(params.projectId){
            setDataLoading(true)
            dispatch(getHyperProject({projectId: params.projectId,with_default_json: false},(res:any,err:boolean)=>{
                setDataLoading(false)
                if(!err){
                    dispatch(setHyperProjectData(res.data));
                    setHpSizes(res.data.sizes);
                    let selectedSizesId:number[] = res.data.sizes.reduce((acc:any,val:any)=>{
                        if(val.selected_for_combination){
                            return [...acc,val.id];
                        }else{
                            return acc;
                        }
                    },[])
                    dispatch(setSelectedHyperSizes(selectedSizesId));
                }
            }))
        }

        if(!getTourStateValue('HYPER_PERSONALIZE_SET_TOUR')){
            startTour(HYPER_PERSONALIZE_SET_TOUR_ID);
            updateTourState('HYPER_PERSONALIZE_SET_TOUR',true);
        }
        
        return ()=>{
            dispatch(setHyperProjectData(null));
            dispatch(setSelectedHyperSizes([]));
        }
    },[]);

    const getSizesArray = (arr: any) => {
        const updatedArr: any[] = [];
        let tabsObjArr = [];
        let tabsObj = {category: '', sizes: {}};
        hyperProjectData?.sizes.forEach((obj:any) => {
            arr.forEach((categoryObj: any) => {
                categoryObj.presetsizes.forEach((sizeObj: any) => {
                    if(sizeObj.name === obj.name) {
                        const category = categoryObj.name;
                        if(tabsObj.category !== category) {
                            tabsObj.category = category;
                            tabsObj.sizes = {
                                name: obj.name,
                                id: obj.id,
                                defatult_rendered_image: obj.defatult_rendered_image,
                                width: sizeObj.width,
                                height: sizeObj.height
                            };
                            tabsObjArr.push(tabsObj);
                            // console.log("sizeObj.name: " + tabsObj?.sizes[name] + " sizeObj.id: " + 
                            // tabsObj?.sizes?.id + " sizeObj.defatult_rendered_image: " + tabsObj?.sizes?.defatult_rendered_image +
                            // " sizeObj.width: " + tabsObj?.sizes?.width + " sizeObj.height: " + tabsObj?.sizes?.height);
                        }
                        updatedArr.push({
                            key: `${categoryObj.id}-${sizeObj.id}`,
                            value: sizeObj.name,
                            width: sizeObj.width,
                            height: sizeObj.height,
                            category: categoryObj.name,
                            iconUrl: sizeObj.icon ? `${IMAGE_BASE_URL}${sizeObj.icon}` : null,
                        });
                    }
                });
            });
        });
        return updatedArr.sort((a,b)=>compareStrings(a.category,b.category));
    };
      
    const getCategoryArr = (arr: any) => {
        const res: any = ['All Sizes'];
        arr.forEach((categoryObj: any) => {
            const { category } = categoryObj;
            !res.includes(category) && res.push(category);
        });
        return res.sort(compareStrings);
    };

    const getObjectsArr = (arr: any) => {
        let tabsObjArr = [];
        let tabsObj = {category: '', sizes: {}};
        hyperProjectData?.sizes.forEach((obj:any) => {
            arr.forEach((categoryObj: any) => {
                categoryObj.presetsizes.forEach((sizeObj: any) => {
                    if(sizeObj.name === obj.name) {
                        const category = categoryObj.name;
                        if(tabsObj.category !== category) {
                            tabsObj.category = category;
                            tabsObj.sizes = {
                                name: obj.name,
                                id: obj.id,
                                defatult_rendered_image: obj.defatult_rendered_image,
                                width: sizeObj.width,
                                height: sizeObj.height
                            };
                            // console.log("sizeObj.name: " + tabsObj?.sizes[name] + " sizeObj.id: " + 
                            // tabsObj?.sizes?.id + " sizeObj.defatult_rendered_image: " + tabsObj?.sizes?.defatult_rendered_image +
                            // " sizeObj.width: " + tabsObj?.sizes?.width + " sizeObj.height: " + tabsObj?.sizes?.height);
                        }
                    }
                });
            });
        });
        return tabsObj;
    };

    // useEffect(() => {
    //     let tabsObj:any;
    //     dispatch(
    //         getTemplateSizes({}, (res: any, isError: any) => {
    //           if (isError) openToast('error', 'sizes fetch fail');
    //           if (!isError) {
    //             tabsObj = getObjectsArr(res.data.categories);
    //             console.log("tabsObj: ", tabsObj, " ", tabsObj[0].category, " ", tabsObj[1].category, " ", tabsObj[2].category);
    //           }
    //         })
    //     );
    // }, [currentTab]);

    useEffect(() => {
        let allSizes:any, allCategories:any;
        dispatch(
          getTemplateSizes({}, (res: any, isError: any) => {
            if (isError) openToast('error', 'sizes fetch fail');
            if (!isError) {
                allSizes = getSizesArray(res.data.categories);
                allCategories = getCategoryArr(allSizes);
                setTabsArr(allCategories);
            }
          })
        );
    }, [hyperProjectData]);

    const handleHyperProjectSelect = useCallback((id:number) => {
        if(selectedHyperSizes.indexOf(id)>-1){
            let hyperSizes:number[] = selectedHyperSizes.filter((i)=>i!==id);
            dispatch(setSelectedHyperSizes(hyperSizes));
        }else{
            dispatch(setSelectedHyperSizes([...selectedHyperSizes,id]));
        }
    },[selectedHyperSizes.length]) 

    const handleSelectAllClick = useCallback(()=>{
        if(selectedHyperSizes.length === (hyperProjectData?.sizes||[]).length && selectedHyperSizes.length !== 0){
            dispatch(setSelectedHyperSizes([]));
        }else{
            let hyperSizes:number[] = (hyperProjectData?.sizes||[]).reduce((acc:any,val:any)=>(
                [...acc,val.id]
            ),[]);
            dispatch(setSelectedHyperSizes(hyperSizes));
        }
    },[hyperProjectData,selectedHyperSizes.length])

    const handleClick = (e:any) => {
        setCurrentTab(e.key);
    };

    const clickHandler = (continueAllBtn:boolean) => {
        let { added,removed } = getRemovedAndAddedIds(continueAllBtn ? (hyperProjectData?.sizes||[]).reduce((acc:any, val:any)=>(
            [...acc,val.id]
        ), []) : selectedHyperSizes,hyperProjectData?.sizes||[]);
        if(added.length === 0 && removed.length === 0){
            // history.push(`/cocreate/creative-automation/layers/${params.projectId}`)
            return history.push(`/cocreate/creative-automation/editor/${params.projectId}`);
        }else{
            setLoading(true);
            if(added.length > 0){
                dispatch(addSizesToHpproject({project_id: params.projectId,size_ids: added},(res:any,err:boolean)=>{
                    if(removed.length === 0) setLoading(false)
                    if(!err){
                        if(removed.length === 0){
                            openToast('success','Sizes Added Sucessfully!');
                            // history.push(`/cocreate/creative-automation/layers/${params.projectId}`)
                            return history.push(`/cocreate/creative-automation/editor/${params.projectId}`);
                        }
                    }else{
                        openToast('error','Unable to Add Sizes!');
                    }
                }))
            }
            if(removed.length > 0){
                dispatch(deleteSizesFromHpproject({project_id: params.projectId,size_ids: removed},(res:any,err:boolean)=>{
                    setLoading(false)
                    if(!err){
                        openToast('success','Sizes Updated Sucessfully!');
                        // history.push(`/cocreate/creative-automation/layers/${params.projectId}`)
                        return history.push(`/cocreate/creative-automation/editor/${params.projectId}`);
                    }else{
                        openToast('error','Unable to Update Sizes!');
                    }
                }))
            }
        }
    }

    return <div className="hyper-personlize-selection-listing">
        {/* <TopBar title="Please Select Hyper Personalize Creatives" subtitle={dataLoading? <FontAwesomeIcon icon={faSpinner} spin />: `${(hyperProjectData?.sizes||[]).length === 0? "No":(hyperProjectData?.sizes||[]).length} sizes available`} 
            leftBtn={ <button className="back-btn-secondary" onClick={()=>history.go(-1)}>
                 <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0002 7.00008V9.00008H4.00016L9.50016 14.5001L8.08016 15.9201L0.160156 8.00008L8.08016 0.0800781L9.50016 1.50008L4.00016 7.00008H16.0002Z" fill="#20007F"/>
                </svg>
                <span className="ml-10">Back</span>
            </button> }
            rightBtn={
                <button className="primary" disabled={selectedHyperSizes.length === 0 || loading} onClick={()=>{
                    let { added,removed } = getRemovedAndAddedIds(selectedHyperSizes,hyperProjectData?.sizes||[]);
                    if(added.length === 0 && removed.length === 0){
                        history.push(`/cocreate/creative-automation/layers/${params.projectId}`)
                    }else{
                        setLoading(true);
                        if(added.length > 0){
                            dispatch(addSizesToHpproject({project_id: params.projectId,size_ids: added},(res:any,err:boolean)=>{
                                if(removed.length === 0) setLoading(false)
                                if(!err){
                                    if(removed.length === 0){
                                        openToast('success','Sizes Added Sucessfully!');
                                        history.push(`/cocreate/creative-automation/layers/${params.projectId}`)
                                    }
                                }else{
                                    openToast('error','Unable to Add Sizes!');
                                }
                            }))
                        }
                        if(removed.length > 0){
                            dispatch(deleteSizesFromHpproject({project_id: params.projectId,size_ids: removed},(res:any,err:boolean)=>{
                                setLoading(false)
                                if(!err){
                                    openToast('success','Sizes Updated Sucessfully!');
                                    history.push(`/cocreate/creative-automation/layers/${params.projectId}`)
                                }else{
                                    openToast('error','Unable to Update Sizes!');
                                }
                            }))
                        }
                    }
                }}>
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                    <span className="mr-10">Next</span>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.16 9.00004L0.160001 7.00004L12.16 7.00004L6.66 1.50004L8.08 0.0800431L16 8.00004L8.08 15.92L6.66 14.5L12.16 9.00004L0.16 9.00004Z" fill="white"/>
                        </svg>
                </button>
            }
        /> */}
        <div className='select-sizes-header'>
            <div className='sec-headingWrapper'>
                <Title className='sec-heading'>Choose sizes</Title>
                <Title className='sec-subheading' level={5}>Choose sizes you need to create variants from your design template.</Title>
            </div>
            <Menu className='sizes-menu' mode='horizontal' onClick={handleClick} selectedKeys={[currentTab]}>
                {tabsArr.map((tab: any) => {
                    return (
                    <MenuItem
                        key={tab}
                        style={currentTab === tab ? { borderColor: '#12C8B2' } : {}}
                        onClick={() => setCurrentTab(tab)}>
                            {tab}
                    </MenuItem>
                    );
                })}
            </Menu>
        </div>
        <div className="selection-creatives-container">
            {hyperProjectData?.sizes.length > 0 && <ActionBar handleSelectAllClick={handleSelectAllClick} 
                isSelected={selectedHyperSizes.length === (hyperProjectData?.sizes||[]).length && selectedHyperSizes.length !== 0} />}
            <Creatives data={hyperProjectData?.sizes||[]} selectedHyperSizes={selectedHyperSizes} 
                handleHyperProjectSelect={handleHyperProjectSelect}
            />
        </div>
        <div className='select-sizes-footer'>
            <div className='backnext-btn-sec'>
                <div className='back-next-btnWrapper'>
                    <Button className="back-btn" type="link" btnsize="lg" onClick={()=>{return history.go(-1)}}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 3L6 8L10 13" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <span className='ml-2'>Go back to choosing files</span>
                    </Button>

                    <div className="continue-btns">
                        <Button className="continue-all-btn" btnsize="lg" onClick={()=>clickHandler(true)}>
                            <span className='ml-2'>{`Continue with all ${hyperProjectData?.sizes.length} sizes`}</span>
                        </Button>

                        <Button className="continue-btn" type="primary" btnsize="lg" disabled={selectedHyperSizes.length === 0 || loading} onClick={()=>clickHandler(false)}>
                            <span className='mr-2'>{selectedHyperSizes.length === 0 ? `Continue` : (`Continue with ${selectedHyperSizes.length} size` + (selectedHyperSizes.length > 1 ? `s` : ``))}</span>
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 13L10 8L6 3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Selection;