import { getAgencyFromParams } from "../../Pages/AgencyFileDrop/utils";
import { getUrlProperties } from "../../utilities/common-function";
import {
  ADD_NEW_AGENCY,
  AddNewAgency,
  AgencyFileDropState,
  CREATE_NEW_AGENCY,
  CreateNewAgency,
  DELETE_AGENCY,
  FETCH_AGENCY_LIST,
  DeleteAgency,
  FetchAgencyList,
  GET_AGENCY_LIST,
  GetAgencyList,
  FetchAgencyFolders,
  FETCH_AGENCY_FOLDERS,
  SET_AGENCY_FOLDERS,
  SetAgencyFolders,
  CreateNewFolder,
  CREATE_NEW_FOLDER,
  ADD_NEW_AGENCY_FOLDER,
  AddNewAgencyFolder,
  DELETE_AGENCY_FOLDER,
  DeleteAgencyFolder,
  RenameAgencyFolder,
  RENAME_AGENCY_FOLDER,
  FetchAgencyData,
  FETCH_AGENCY_DATA,
  SetAgencyData,
  SET_AGENCY_DATA,
  GetAgencyShareableKey,
  GET_AGENCY_SHAREABLE_KEY,
  SetAgencyShareableKey,
  SET_AGENCY_SHAREABLE_KEY,
} from "../types";

const initialState: AgencyFileDropState = {
  loading: true,
  agenciesList: {
    pages: 1,
    page_size: 10,
    current_page: 1,
    count: 0,
    results: [],
  },
  currentAgencyFolders: {
    count: 0,
    current_page: 1,
    pages: 1,
    page_size: 10,
    parent: {},
    results: [],
  },
  agencySelected: null,
  agencyToBeShared: {
    shareable_link_key: "",
    password: null,
    expiration_date: null,
  },
};

export const agencyFileDropReducer = (
  state: AgencyFileDropState = initialState,
  action:
    | FetchAgencyList
    | GetAgencyList
    | CreateNewAgency
    | AddNewAgency
    | DeleteAgency
    | FetchAgencyFolders
    | SetAgencyFolders
    | CreateNewFolder
    | AddNewAgencyFolder
    | DeleteAgencyFolder
    | RenameAgencyFolder
    | FetchAgencyData
    | SetAgencyData
    | GetAgencyShareableKey
    | SetAgencyShareableKey
) => {
  switch (action.type) {
    case FETCH_AGENCY_LIST:
    case CREATE_NEW_AGENCY:
    case FETCH_AGENCY_FOLDERS:
    case CREATE_NEW_FOLDER:
    case FETCH_AGENCY_DATA:
    case GET_AGENCY_SHAREABLE_KEY: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AGENCY_LIST: {
      const { data, shouldLoading, page } = action.payload;
      const { search } = getUrlProperties();
      const selectedAgencyId = getAgencyFromParams(search);
      const requiredAgency = [
        ...state.agenciesList.results,
        ...data.results,
      ].filter((agency) => String(agency.id) === selectedAgencyId);
      return {
        ...state,
        agencySelected: !!requiredAgency.length ? requiredAgency[0] : null,
        agenciesList: {
          ...data,
          results: !!page
            ? [...state.agenciesList.results, ...data.results]
            : [...data.results],
        },
        loading: !!shouldLoading ? true : false,
      };
    }
    case ADD_NEW_AGENCY: {
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: [action.payload, ...state.agenciesList.results],
        },
        loading: false,
      };
    }

    case SET_AGENCY_DATA: {
      const { search } = getUrlProperties();
      const selectedAgencyId = getAgencyFromParams(search);
      const requiredAgency = [
        action.payload,
        ...state.agenciesList.results,
      ].filter((agency) => String(agency.id) === selectedAgencyId);
      return {
        ...state,
        agencySelected: !!requiredAgency.length ? requiredAgency[0] : null,
        agenciesList: {
          ...state.agenciesList,
          results: [action.payload, ...state.agenciesList.results],
        },
        loading: true,
      };
    }

    case DELETE_AGENCY: {
      const { id } = action.payload;
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: state.agenciesList.results.filter(
            (agency) => agency.id !== id
          ),
        },
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: [],
        },
        loading: true,
      };
    }

    case SET_AGENCY_SHAREABLE_KEY: {
      return {
        ...state,
        agencyToBeShared: {
          ...action.payload,
        },
        loading: false,
      };
    }
    case SET_AGENCY_FOLDERS: {
      const { data, page } = action.payload;
      return {
        ...state,
        currentAgencyFolders: {
          ...data,
          results: !!page
            ? [...state.currentAgencyFolders.results, ...data.results]
            : [...data.results],
        },
        loading: false,
      };
    }

    case ADD_NEW_AGENCY_FOLDER: {
      const { agency_id } = action.payload;

      // Increment the folders_count if the agency is found
      state.agenciesList.results = state.agenciesList.results.map((item) =>
        String(item.id) === agency_id
          ? { ...item, folders_count: (item.folders_count ?? 0) + 1 }
          : { ...item }
      );
      // increment in the folders_count is done to increase the count value in the agencies list or else
      // we have to make a call every time to get the data of agency
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: state.agenciesList.results,
        },
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: [action.payload, ...state.currentAgencyFolders.results],
        },
        loading: false,
      };
    }

    case DELETE_AGENCY_FOLDER: {
      const { id, agencyId } = action.payload;
      // Decrement the folders_count if the agency is found
      state.agenciesList.results = state.agenciesList.results.map((item) =>
        String(item.id) === agencyId
          ? { ...item, folders_count: item.folders_count - 1 }
          : { ...item }
      );
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: state.agenciesList.results,
        },
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: state.currentAgencyFolders.results.filter(
            (folder) => String(folder.id) !== id
          ),
        },
        loading: false,
      };
    }
    case RENAME_AGENCY_FOLDER: {
      const { id, name } = action.payload;
      return {
        ...state,
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: state.currentAgencyFolders.results.map((item) =>
            String(item.id) === id ? { ...item, name } : { ...item }
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};
