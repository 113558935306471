import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./index.scss";
import { Input } from "pyxis-ui-kit";
import { FolderState } from "../../../store/types";

interface SearchBarInterface {
  type: string;
}

export const SearchBar: React.FunctionComponent<SearchBarInterface> = ({
  type,
}) => {
  const folderstate = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const { filter } = folderstate;
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSearch = () => {
    // if(!search||(search && filter && filter['search'] && filter['search'] === search)) return;
    // dispatch(setFilter({search}));
    if (!search) return;
    history.push(
      `/cocreate/advance-search?project_type=${type}${
        search ? "&q=" + encodeURIComponent(search) : ""
      }`
    );
  };

  // useEffect(()=>{
  //     if(!search && filter && filter['search']){
  //         dispatch(setFilter(null))
  //     }
  // },[search])

  // useEffect(()=>{
  //     if(search && !filter){
  //         setSearch('')
  //     }
  // },[filter])

  return (
    <div className='search-bar'>
      <Input
        className='search-input'
        value={search}
        allowClear
        prefix={
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.3024 11.2023C8.05366 13.0034 4.76202 12.8616 2.67748 10.7771C0.440839 8.54042 0.440839 4.91411 2.67748 2.67748C4.91412 0.440841 8.54044 0.440841 10.7771 2.67748C12.8616 4.76202 13.0034 8.05367 11.2023 10.3024L14.8136 13.9137C15.0621 14.1622 15.0621 14.5651 14.8136 14.8136C14.5651 15.0621 14.1622 15.0621 13.9137 14.8136L10.3024 11.2023ZM3.57743 3.57743C1.83782 5.31704 1.83782 8.1375 3.57743 9.87711C5.31704 11.6167 8.13751 11.6167 9.87712 9.87711C11.6167 8.1375 11.6167 5.31704 9.87712 3.57743C8.13751 1.83783 5.31704 1.83783 3.57743 3.57743Z'
              fill='#1B0A7A'
              fill-opacity='0.5'
            />
          </svg>
        }
        type='text'
        size='small'
        placeholder={
          "Search in " +
          (type === "project" ? "designs" : "creative automation")
        }
        onChange={(e: any) => setSearch(e.target.value)}
        onPressEnter={handleSearch}
        suffix={
          <Link
            className='advanced-search-tab'
            to={`/cocreate/advance-search?project_type=${type}${
              search ? "&q=" + encodeURIComponent(search) : ""
            }`}
          >
            {" "}
            Advanced Search{" "}
          </Link>
        }
      />
    </div>
  );
};
