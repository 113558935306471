export const FETCH_IMAGES = 'FETCH_IMAGES';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_PHOTOS_SEARCH = 'SET_PHOTOS_SEARCH'

export const FETCH_DEFAULT_IMAGES = 'FETCH_DEFAULT_IMAGES';
export const SET_DEFAULT_IMAGES = 'SET_DEFAULT_IMAGES';

export type ImageQuery = {
  search: string,
  page: number,
  totalPages: number|null
}

export type FetchImagesAction = {
  type: typeof FETCH_IMAGES,
  payload: ImageQuery
}

export type SetPhotosSearchAction = {
  type: typeof SET_PHOTOS_SEARCH,
  payload: {
    search: string
   }
}

export type ImageTrayState = {
  images: any[],
  page: number,
  totalPages: number|null,
  search: string,
  loading: boolean,
  noResultsError?: boolean,
  totalRecords: number,
  defaultImages?: any,
  shapes?: any,
  background?: any,
  humanCutouts?: any,
}

export type SetImagesAction = {
  type: typeof SET_IMAGES,
  payload: ImageTrayState
}

// default images

export type DefaultImageQuery = {
  type: string,
  search: string,
  is_default: boolean,
  page: number,
  page_size: number,
}

export type DefaultImageTrayState = {
  categories: any[],
  count: number,
  current_page: number,
  page_size: number,
  pages: number,
  params: any,
  loading: boolean,
}

export type SetDefaultImagesAction = {
  type: typeof SET_DEFAULT_IMAGES,
  payload: DefaultImageTrayState
}

export type FetchDefaultImagesAction = {
  type: typeof FETCH_DEFAULT_IMAGES,
  payload: DefaultImageQuery
}
