import React, { useRef, useEffect, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Warning from '../../../assets/img/warning.png';
import './index.scss';
import Img from '../../ImageLoader/ImageLoader';

const PermissionNotAllowed:React.FC<{title:string;description?:string;isEditor?:boolean}> = ({title,description,isEditor}) => {
  return <div className="not-allowed-container">
    <div className="not-allowed-block">
      <div className="icon">
        {isEditor ? <img src={Warning} alt="warning" /> : <FontAwesomeIcon icon={faLock} />}
      </div>
      <div className="title">
        {title}
      </div>
      {description && <div className="description">
        {description}
      </div>}
    </div>
  </div>
}

export default PermissionNotAllowed;