import React from 'react';
// import './index.scss';
import singinLeft from '../../../assets/svg/singinLeft.svg'

const AuthWrapper:React.FunctionComponent = ({children}) => {
  require("./index.scss");
  return <div className="auth-wrapper">
    <div className="banner">
      <div className="img-container">
        <img src={singinLeft} alt="signIn" className="sign-in-img"/>
      </div>
      <div className="title">Welcome to Pixis CoCreate!</div>
      <div className="sub-title">Get the power to attract, engage & retain customers with personalized content & actionable insights.</div>
    </div>
    <div className="section">
      {children}
    </div>
  </div>
}

export default AuthWrapper;