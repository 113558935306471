export const FETCH_AGENCY_LIST = "FETCH_AGENCY_LIST";
export const GET_AGENCY_LIST = "GET_AGENCY_LIST";
export const CREATE_NEW_AGENCY = "CREATE_NEW_AGENCY";
export const ADD_NEW_AGENCY = "ADD_NEW_AGENCY";
export const FETCH_AGENCY_DATA = "FETCH_AGENCY_DATA";
export const SET_AGENCY_DATA = "SET_AGENCY_DATA";
export const DELETE_AGENCY = "DELETE_AGENCY";
export const FETCH_AGENCY_FOLDERS = "FETCH_AGENCY_FOLDERS";
export const SET_AGENCY_FOLDERS = "SET_AGENCY_FOLDERS";
export const CREATE_NEW_FOLDER = "CREATE_NEW_FOLDER";
export const ADD_NEW_AGENCY_FOLDER = "ADD_NEW_AGENCY_FOLDER";
export const DELETE_AGENCY_FOLDER = "DELETE_AGENCY_FOLDER";
export const RENAME_AGENCY_FOLDER = "RENAME_AGENCY_FOLDER";
export const GET_AGENCY_SHAREABLE_KEY = "GET_AGENCY_SHAREABLE_KEY";
export const SET_AGENCY_SHAREABLE_KEY = "SET_AGENCY_SHAREABLE_KEY";

export type AgencyFileDropState = {
  loading: boolean;
  agenciesList: AgencyList;
  currentAgencyFolders: SelectedAgency;
  agencySelected: Agencies | null;
  agencyToBeShared: AgencyToBeShared;
};

export type AgencyList = {
  pages: number;
  page_size: number;
  current_page: number;
  count: number;
  results: Agencies[];
};

export type SelectedAgency = {
  count: number;
  current_page: number;
  pages: number;
  page_size: number;
  parent: any;
  results: AgencyFolder[];
};

export type Agencies = {
  agency_name: string;
  created_at: Date;
  id: string;
  is_trashed: boolean;
  modified_at: Date;
  trashed_at: null;
  workspace: string;
  folders_count?: null | number;
};

export type AgencyToBeShared = {
  shareable_link_key: string;
  password: string | null;
  expiration_date: string | null;
};

export type AgencyFolder = {
  agency_id: string;
  content_count: number;
  id: number;
  name: string;
  parent: null;
  subfolder_count: number;
  type: string;
};

export type FetchAgencyList = {
  type: typeof FETCH_AGENCY_LIST;
  payload: {
    workspace: string;
    page?: number;
  };
};

export type GetAgencyList = {
  type: typeof GET_AGENCY_LIST;
  payload: { data: AgencyList; shouldLoading?: boolean; page: number };
};

export type CreateNewAgency = {
  type: typeof CREATE_NEW_AGENCY;
  payload: {
    agency_name: string;
    workspace: string;
  };
};

export type AddNewAgency = {
  type: typeof ADD_NEW_AGENCY;
  payload: Agencies;
};

export type FetchAgencyData = {
  type: typeof FETCH_AGENCY_DATA;
  payload: string;
};

export type DeleteAgency = {
  type: typeof DELETE_AGENCY;
  payload: {
    id: string;
    workspace: string;
  };
};

export type FetchAgencyFolders = {
  type: typeof FETCH_AGENCY_FOLDERS;
  payload: {
    agency_id: string;
    page?: number;
  };
};

export type SetAgencyFolders = {
  type: typeof SET_AGENCY_FOLDERS;
  payload: { data: SelectedAgency; page: number | undefined };
};

export type CreateNewFolder = {
  type: typeof CREATE_NEW_FOLDER;
  payload: {
    name: string;
    agency_id: string;
  };
};

export type AddNewAgencyFolder = {
  type: typeof ADD_NEW_AGENCY_FOLDER;
  payload: AgencyFolder;
};

export type DeleteAgencyFolder = {
  type: typeof DELETE_AGENCY_FOLDER;
  payload: {
    id: string;
    agencyId: string;
  };
};

export type RenameAgencyFolder = {
  type: typeof RENAME_AGENCY_FOLDER;
  payload: {
    id: string;
    name: string;
  };
};

export type SetAgencyData = {
  type: typeof SET_AGENCY_DATA;
  payload: Agencies;
};

export type GetAgencyShareableKey = {
  type: typeof GET_AGENCY_SHAREABLE_KEY;
  payload: {
    password_flag: boolean;
    agency: string;
  };
};

export type SetAgencyShareableKey = {
  type: typeof SET_AGENCY_SHAREABLE_KEY;
  payload: AgencyToBeShared;
};
