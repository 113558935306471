export const NODE_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  CUSTOM_SHAPE: 'CUSTOM_SHAPE',
  TEXT_IMAGE: 'TEXT_IMAGE',
  FOLDER: 'FOLDER',
};

export const SHAPE_TYPE = {
  RECT: 'Rect',
  CIRCLE: 'Circle',
  ELLIPSE: 'Ellipse',
};

export const LAYER_TYPE = {
  IMAGE: 'Image',
  TEXT: 'Text',
  ELEMENTS: 'Elements',
  SHAPE: 'Shape',
  LOGO: 'Logo',
  BUTTON: 'Button',
  MODEL: 'Model',
  LAYOUT: 'Layout',
};
