export const AUTH_URI: string = process.env.REACT_APP_AUTH_URI as string;
export const ENTERPRISE: boolean = (process.env.REACT_APP_ENTERPRISE as string) === 'true';
export const DESIGN_URI: string = process.env.REACT_APP_DESIGN_URI as string;
export const PSD_IMPORT_URI: string = process.env.REACT_APP_PSD_IMPORT_URI as string;
export const APPROVAL_URI: string = process.env.REACT_APP_APPROVAL_URI as string;
export const IMAGE_BASE_URL: string = process.env.REACT_APP_IMAGE_BASE_URL as string;
export const S3_BASE_URL: string = process.env.REACT_APP_S3_BASE_URL as string;
export const LOCUS_URI:string = process.env.REACT_APP_LOCUS_URI as string;
export const APP_ID:string = process.env.REACT_APP_APP_ID as string;
export const WEBSOCKET_ENDPOINT:string = process.env.REACT_APP_WEBSOCKET_ENDPOINT as string;
export const REPORT_URI: string = process.env.REACT_APP_REPORT_URI as string;
export const REPORT_REDIRECT_ORIGIN: string = process.env.REACT_APP_REPORT_REDIRECT_ORIGIN as string;
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN as string;
export const INTERCOM_APPID: string = process.env.REACT_APP_INTERCOM_APPID as string;
export const SUB_KEY: string = process.env.REACT_APP_ROMAN_API_SUB_KEY as string;
export const TOKEN: string = process.env.REACT_APP_ROMAN_API_TOKEN as string;
export const API_URL: string = process.env.REACT_APP_ROMAN_API_URL as string;
export const LOGROCKET_DSN: string = process.env.REACT_APP_LOGROCKET_DSN as string;
export const ENV_TYPE: string = process.env.REACT_APP_ENV_TYPE as string;
export const AUDIT_URI: string = process.env.REACT_APP_AUDIT_URI as string;
export const MIXPANEL_TOKEN: string = process.env.REACT_APP_MIXPANEL_TOKEN as string;
export const HDFC_DIGITAL_MFILTERIT_WORKFLOW: string = process.env.REACT_APP_HDFC_DIGITAL_MFILTERIT_WORKFLOW as string;
export const HDFC_PRINT_MFILTERIT_WORKFLOW: string = process.env.REACT_APP_HDFC_PRINT_MFILTERIT_WORKFLOW as string;
export const HDFC_BANK_MFILTERIT_WORKFLOW: string = process.env.REACT_APP_HDFC_BANK_MFILTERIT_WORKFLOW as string;
export const HDFC_MFILTERIT_USER: string = process.env.REACT_APP_HDFC_MFILTERIT_USER as string;