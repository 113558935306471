export const SET_DROP_SRC = 'SET_DROP_SRC';
export const DELETE_DROP_SRC = 'DELETE_DROP_SRC';

export type DropSrcState = {
  dropImageSrc: string | false;
};

export type SetDropSrcAction = {
  type: typeof SET_DROP_SRC;
  payload: {
    dropImageSrc: string;
  };
};

export type DeleteDropSrcAction = {
  type: typeof DELETE_DROP_SRC;
  payload: {
    dropImageSrc: false;
  };
};
