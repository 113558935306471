import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Menu, MenuItem, SubMenu, DatePicker, Row, Space, RangePicker} from 'pyxis-ui-kit';
import moment, { Moment } from 'moment';

const getDate = (key:string,dateFormat:string) => {
    switch(key){
        case 'today': return {
            start: moment().format(dateFormat),
            end: moment().format(dateFormat),
            value: 'Today'
        }
        case 'week': return {
            start: moment().subtract(7, 'days').format(dateFormat),
            end: moment().format(dateFormat),
            value: 'Past 7 Days'
        }
        case 'month': return {
            start: moment().subtract(30, 'days').format(dateFormat),
            end: moment().format(dateFormat),
            value: 'Past 30 Days'
        }
    }
}


const DateFilter:React.FC<{
    label:string;
    changeFilter:Function;
    dateFormat: string;
    value:string;
}> = ({label,dateFormat,changeFilter,value}) => {
    const [visible,setVisible] = useState(false);
    const [startDate,setStartDate] = useState<string|null>(null);
    const [endDate,setEndDate] = useState<string|null>(null);
    const [endDateOpen,setEndDateOpen] = useState(false);
    
    const todayRange = useMemo(()=>getDate('today',dateFormat),[])
    const weekRange = useMemo(()=>getDate('week',dateFormat),[])
    const monthRange = useMemo(()=>getDate('month',dateFormat),[])
    
    const handleEndDateDisabled = (current:any) => {
        const isToday = moment().format() === current.format();
        return !(isToday ||
            (moment(startDate).diff(current, 'days',true) < 0 && moment().diff(current, 'days',true) > 0)
        );
    }
    const handleStartDateDisabled = (current:any) => {
        return moment().diff(current, 'days', true) < 0;
    }

    useEffect(()=>{
        if(startDate && endDate){
            changeFilter({ start:startDate,end:endDate,value: `${moment(startDate).format('Do MMM YYYY')}-${moment(endDate).format('Do MMM YYYY')}` })
        }else if(!startDate||!endDate){
            changeFilter(null);
        }
    },[startDate,endDate])

    return <div className="filter">
        <div className="label">{label}</div>
        <Dropdown 
        visible={visible}
        onVisibleChange={(flag)=>setVisible(flag)}
        trigger={['click']}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        overlay={
            <Menu>
                <MenuItem onClick={()=>{
                    changeFilter(null)
                    setVisible(false)
                }}>All Time</MenuItem>
                <MenuItem onClick={()=>{
                    changeFilter(todayRange)
                    setVisible(false)
                }}>Today</MenuItem>
                <MenuItem onClick={()=>{
                    changeFilter(weekRange)
                    setVisible(false)
                }}>Past 7 Days</MenuItem>
                <MenuItem onClick={()=>{
                    changeFilter(monthRange)
                    setVisible(false)
                }}>Past 30 Days</MenuItem>
                <MenuItem 
                    style={{width: 'max-content', background: 'transparent'}}
                    onClick={({domEvent})=>{ 
                        setVisible(true)
                        domEvent.stopPropagation()
                    }}
                >
                    {/* <Space> */}
                        <DatePicker placeholder="Start Date" 
                            value={startDate?moment(startDate):null}
                            format={'Do MMM YYYY'} 
                            disabledDate={handleStartDateDisabled}
                            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                            onChange={(value)=>{ 
                                setVisible(true)
                                if(!value && startDate){ setStartDate(null); setEndDate(null); }
                                else{ setStartDate(moment(value).format(dateFormat)); }
                            }} 
                        />
                        <DatePicker placeholder="End Date" 
                            value={endDate?moment(endDate):null}
                            format={'Do MMM YYYY'} 
                            disabled={!startDate} 
                            disabledDate={handleEndDateDisabled}
                            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                            onChange={(value)=>{ 
                                if(!value && endDate){ setEndDate(null) }
                                else{ 
                                    setEndDate(moment(value).format(dateFormat)) 
                                    setVisible(false);
                                }
                            }} 
                        />
                    {/* </Space> */}
                </MenuItem>
            </Menu>
        }>
            <div className="action">
                <div className="name">
                    <span className="icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.403 13.7521H2.59739C2.25135 13.7521 1.96935 13.4701 1.96935 13.1241V7.82358H14.031V13.1241C14.031 13.4701 13.749 13.7521 13.403 13.7521ZM2.59739 3.85224H4.63124V4.87124C4.63124 5.21562 4.91074 5.49512 5.25512 5.49512C5.5995 5.49512 5.879 5.21562 5.879 4.87124V3.85224H10.1214V4.87124C10.1214 5.21562 10.4009 5.49512 10.7453 5.49512C11.0897 5.49512 11.3691 5.21562 11.3691 4.87124V3.85224H13.403C13.749 3.85224 14.031 4.1334 14.031 4.48028V6.57568H1.96935V4.48028C1.96935 4.1334 2.25135 3.85224 2.59739 3.85224ZM13.4031 2.60475H11.3692V1.58824C11.3692 1.24385 11.0897 0.964355 10.7454 0.964355C10.401 0.964355 10.1215 1.24385 10.1215 1.58824V2.60475H5.87909V1.58824C5.87909 1.24385 5.59959 0.964355 5.25521 0.964355C4.91083 0.964355 4.63133 1.24385 4.63133 1.58824V2.60475H2.59748C1.5635 2.60475 0.72168 3.44574 0.72168 4.48055V13.1242C0.72168 14.1582 1.5635 15 2.59748 15H13.4031C14.4371 15 15.2789 14.1582 15.2789 13.1242V4.48055C15.2789 3.44574 14.4371 2.60475 13.4031 2.60475Z" fill="#8090E7"/>
                        </svg>
                    </span>
                    <span className="ml-10 text-ellipsis">{value}</span>
                </div>
                <span className="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6L8 10L13 6" stroke="#8090E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
        </Dropdown>
    </div>
}

export default DateFilter;