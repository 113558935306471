import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { WorkflowState } from '../../../store/types';
import { createWorkFlow, setWorkFlowData,createRound, updateWorkFlowData,getUsersData } from '../../../store/actions';
import Round from '../RoundCard';
import { openToast } from '../../../Toasts';
import { Button,Input } from 'pyxis-ui-kit';

interface ContainerInterface {
    className: string
}
interface HeaderInterface {
    title: string,
    workFlowId: number|undefined,
    loading: boolean,
    workFlowCount: number,
    workflowName: string|undefined,
    isFullScreen: boolean,
    handleWorkflowUpdate: Function
}
interface RoundsListInterface {
    rounds: any[],
    workFlowId: number|undefined
}
interface InputInterface {
    workFlowName: string,
    loading: boolean,
    setWorkFlowName: Function
}
interface AddRoundInterface {
    handleAddRound: Function
}

const dummyrounds:any = [];
const Container: React.FunctionComponent<ContainerInterface> = ({children,className},props) =>{
    return <div className={className} {...props}>
        {children}
    </div>;
}

const Header: React.FunctionComponent<HeaderInterface> = ({title,workFlowId,workflowName,loading,workFlowCount,isFullScreen,handleWorkflowUpdate}) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [workFlowName,setWorkFlowName] = useState(workflowName? workflowName: "");
    const [nameLoading,setNameLoading] = useState(false);

    useEffect(()=>{
        if(workflowName)
        setWorkFlowName(workflowName);
    },[workflowName])
    
    return <Container className={isFullScreen? "header fullscreen":"header"}>
        <div className="title">{title}</div>
        <div className="flex">
            <div className="workflow-name">
                <Input type="text" value={workFlowName} onChange={(e)=>setWorkFlowName(e.target.value)}
                    placeholder="WorkFlow Name" onBlur={()=>{
                        if(workFlowName.length === 0) setWorkFlowName(workflowName||"");
                        if(workFlowId && workFlowName.trim()!==workflowName.trim() && workFlowName.length > 0){
                            handleWorkflowUpdate(workFlowId,workFlowName,setNameLoading)
                        }
                    }}
                    onPressEnter={()=>{
                        if(isFullScreen){
                            (workFlowName.length && !loading) && dispatch(createWorkFlow(workFlowName,workFlowName+ " Description"))
                        }else{
                            if(workFlowName.length === 0) setWorkFlowName(workflowName||"");
                            if(workFlowId && workFlowName.trim()!==workflowName.trim() && workFlowName.length > 0){
                                handleWorkflowUpdate(workFlowId,workFlowName,setNameLoading)
                            }
                        }
                        
                    }} 
                />
            </div>
            {nameLoading && !isFullScreen && <div className={nameLoading? "check loading": "check"}>
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>}
        </div>
        {isFullScreen && <div className="actions">
            <Button onClick={()=>history.go(-1)}>
                Cancel
            </Button>
            <Button type="primary" className="primary ml-20" loading={loading} onClick={()=>{
                dispatch(createWorkFlow(workFlowName,workFlowName+ " Description"))
            }} disabled={!!!(workFlowName.length) || loading}>
                Create WorkFlow    
            </Button>
        </div>}
    </Container>
}

const RoundsList:React.FunctionComponent<RoundsListInterface> = ({workFlowId,rounds}) => {
    return <Container className="rounds-list">
        {rounds.map((round:any,index:number)=>(<Round data={round} key={round.id} workFlowId={workFlowId} isEditable={true} isViewCard={false} index={index} />))}
    </Container>
}
    

const AddRound:React.FunctionComponent<AddRoundInterface> = ({handleAddRound}) => {
    return <Button type="link" className="add-round" onClick={()=>handleAddRound()}>
        <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#7335cb"/>
        </svg>
        Add Another Round
    </Button>
}

const CreateWorkFlow: React.FunctionComponent = () => {
    const state = useSelector((state: {workflows: WorkflowState}) =>(state.workflows));
    const { workflows,workflowData,loading } = state;
    const rounds = workflowData?.rounds || [];
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(()=>{
        return ()=>{
            dispatch(setWorkFlowData(null))
        }
    },[])

    const handleAddRound = useCallback(()=>{
        if(workflowData && workflowData?.rounds[workflowData?.rounds.length -1]?.id !== -1){
            workflowData.rounds = [...workflowData.rounds,{
                id:-1,
                name: `Round ${rounds.length+1}`,
                settings: {
                    everyonemustapprove: false
                },
                order: -1,
                approvers: []
            }];
            dispatch(setWorkFlowData(workflowData))
        }
        else{
            openToast('warn','You Already have a empty round present!')
        }
    },[workflowData,rounds.length])

    const handleWorkflowUpdate = useCallback((workFlowId:number,workFlowName:string,setLoader:Function) => {
        setLoader(true)
        dispatch(updateWorkFlowData(workFlowId,workFlowName,(res:any,err:boolean)=>{
            if(!err){
                setLoader(false); 
                dispatch(setWorkFlowData({...res.data,rounds: rounds}))
            }else{
                setLoader(false);
            }
        }));
    },[rounds.length]);

    return (<>
        <Container className="workflow-create">
            {!!workflowData && <Container className="back-btn">
                <Button onClick={()=>history.go(-1)}>
                    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 6.99999V8.99999H4.00003L9.50003 14.5L8.08003 15.92L0.160034 7.99999L8.08003 0.0799866L9.50003 1.49999L4.00003 6.99999H16Z" fill="#7335cb"/>
                    </svg>
                    Back
                </Button>
            </Container>}
            <Container className="container">
                <Header title="New Approval Workflow" workFlowId={workflowData?.id} workflowName={workflowData?.name} 
                    workFlowCount={workflows.length} loading={loading} isFullScreen={!!!(workflowData)}
                    handleWorkflowUpdate={handleWorkflowUpdate} />
                <RoundsList rounds={rounds} workFlowId={workflowData?.id} />
                {!!workflowData && <AddRound handleAddRound={handleAddRound} />}
            </Container>
        </Container>
    </>
    )
}

export default CreateWorkFlow;