import { put, takeLatest } from 'redux-saga/effects';
import { setImagesAction, setDefaultImagesAction } from "../actions";
import { FETCH_IMAGES, FetchImagesAction, FetchDefaultImagesAction, FETCH_DEFAULT_IMAGES } from "../types";
import { searchPixabayImages, searchPexelImages, searchUnsplashImages, fetchDefaultImagesAPI, fetchDefaultSearchElementsAPI } from '../../utilities/services/api';
import { openToast } from '../../Toasts';


function* fetchImages(action: FetchImagesAction) {
  if (!action.payload.search) {
    yield put(
      setImagesAction(
        [],
        1,
        1,
        0,
        action.payload.search
      )
    )
    return
  }

  const query = action.payload.search ? { query: action.payload.search, page: action.payload.page } : { page: action.payload.page }
  const pexelByImage: any = yield searchPixabayImages(query);
  const pexelByPages: any = pexelByImage.hits.length
    ? Math.ceil(pexelByImage.totalHits / pexelByImage.hits.length)
    : 1;
  const PexelImage: any = yield searchPexelImages(query);
  const PexelPages: any = PexelImage.total_results ? Math.ceil(PexelImage.total_results / PexelImage.per_page) : 1;
  const pexel = PexelImage.photos.map((val: any) => ({
    largeImageURL: val.src.large,
    previewURL: val.src.medium,
  }));

  const unSplashImage: any = yield searchUnsplashImages(query);

  const maxPages: any = Math.max(pexelByPages, PexelPages, unSplashImage.total_pages);
  const unsplash = unSplashImage.results.map((val: any) => ({
    largeImageURL: val.urls.regular,
    previewURL: val.urls.small,
  }));

  yield put(
    setImagesAction(
      [...pexelByImage.hits, ...pexel, ...unsplash],
      action.payload.page,
      maxPages,
      pexelByImage.totalHits + PexelImage.total_results + unSplashImage.total,
      action.payload.search
    )
  )
}

function* fetchDefaultImages(action: FetchDefaultImagesAction) {
  let defaultImages: any;
  let result;
  if(action.payload.search !== "" || action.payload.type === "human_cutout" || action.payload.type === "photo"){
    defaultImages = yield fetchDefaultSearchElementsAPI(action.payload);
    if (!defaultImages.data?.data?.results) return openToast('error', 'Failed to fetch stock images.')
    defaultImages.data.data.categories = [{ elements: defaultImages.data.data.results } ];
  }
  else {
    defaultImages = yield fetchDefaultImagesAPI(action.payload);
  }
  result = defaultImages.data.data;
  
  yield put(
    setDefaultImagesAction(
      result.categories,
      result.count,
      result.current_page,
      result.page_size,
      result.pages,
      action.payload
    )
  );
  return;
}

export function* getImagesWatcher() {
  yield takeLatest(FETCH_IMAGES, fetchImages)
  yield takeLatest(FETCH_DEFAULT_IMAGES, fetchDefaultImages)
}
