import { fabric } from 'fabric';

const GUIDELINE_OFFSET = 5;

function getLineGuideStops(skipShape: fabric.Object, canvas: fabric.Canvas) {
  // we can snap to canvas borders and the center of the canvas
  const vertical = [0, canvas.getWidth() / 2 / canvas.getZoom(), canvas.getWidth() / canvas.getZoom()];
  const horizontal = [0, canvas.getHeight() / 2 / canvas.getZoom(), canvas.getHeight() / canvas.getZoom()];
  // and we snap over edges and center of each object on the canvas
  canvas.getObjects().forEach((object: fabric.Object) => {
    if (JSON.stringify(object) === JSON.stringify(skipShape)) {
      return;
    }
    const { left, top, width, height } = object.getBoundingRect(true);
    // and we can snap to all edges of shapes
    vertical.push(left);
    vertical.push(left + width);
    vertical.push(left + width / 2);
    horizontal.push(top);
    horizontal.push(top + height);
    horizontal.push(top + height / 2);
  });
  return {
    vertical,
    horizontal,
  };
}

function getObjectSnappingEdges(object: fabric.Object) {
  const { left, width, top, height } = object.getBoundingRect(true);
  return {
    vertical: [
      {
        guide: Math.round(left),
        offset: Math.round((object.left || 0) - left),
        snap: 'start',
      },
      {
        guide: Math.round(left + width / 2),
        offset: Math.round((object.left || 0) - left - width / 2),
        snap: 'center',
      },
      {
        guide: Math.round(left + width),
        offset: Math.round((object.left || 0) - left - width),
        snap: 'end',
      },
    ],
    horizontal: [
      {
        guide: Math.round(top),
        offset: Math.round((object.top || 0) - top),
        snap: 'start',
      },
      {
        guide: Math.round(top + height / 2),
        offset: Math.round((object.top || 0) - top - height / 2),
        snap: 'center',
      },
      {
        guide: Math.round(top + height),
        offset: Math.round((object.top || 0) - top - height),
        snap: 'end',
      },
    ],
  };
}

function getGuides(
  lineGuideStops: {
    vertical: number[];
    horizontal: number[];
  },
  itemBounds: {
    vertical: {
      guide: number;
      offset: number;
      snap: string;
    }[];
    horizontal: {
      guide: number;
      offset: number;
      snap: string;
    }[];
  },
  zoomLevel: number
) {
  const resultV: Array<{
    lineGuide: number;
    diff: number;
    snap: string;
    offset: number;
  }> = [];
  const resultH: Array<{
    lineGuide: number;
    diff: number;
    snap: string;
    offset: number;
  }> = [];

  lineGuideStops.vertical.forEach(lineGuide => {
    itemBounds.vertical.forEach(itemBound => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      // if the distance between guild line and object snap point is close we can consider this for snapping
      if (diff < GUIDELINE_OFFSET * zoomLevel) {
        resultV.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach(lineGuide => {
    itemBounds.horizontal.forEach(itemBound => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET * zoomLevel) {
        resultH.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  const guides = [];

  // find closest snap
  const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: 'V',
      snap: minV.snap,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: 'H',
      snap: minH.snap,
    });
  }
  return guides;
}

const drawGuides = (
  guides: {
    lineGuide: number;
    offset: number;
    orientation: string;
    snap: string;
  }[],
  canvas: fabric.Canvas
) => {
  guides.forEach(lg => {
    if (lg.orientation === 'H') {
      const line = new fabric.Line([-6000, lg.lineGuide, 6000, lg.lineGuide], {
        stroke: '#17005c',
        strokeWidth: 1,
      });
      canvas.add(line);
    } else if (lg.orientation === 'V') {
      const line = new fabric.Line([lg.lineGuide, -6000, lg.lineGuide, 6000], {
        stroke: '#17005c',
        strokeWidth: 1,
      });
      canvas.add(line);
    }
    canvas.requestRenderAll();
  });
};

export const onObjectSnapingEnd = (canvas: fabric.Canvas) => {
  canvas.getObjects('line').forEach(object => canvas.remove(object));
};

export const onObjectSnapping = (target: fabric.Object | undefined, canvas: fabric.Canvas) => {
  if (!target) return;
  target.setCoords();
  onObjectSnapingEnd(canvas);
  canvas.requestRenderAll();
  // find possible snapping lines
  const lineGuideStops = getLineGuideStops(target, canvas);
  // find snapping points of current object
  const itemBounds = getObjectSnappingEdges(target);
  // now find where can we snap current object
  const guides = getGuides(lineGuideStops, itemBounds, canvas.getZoom() || 1);
  // do nothing of no snapping
  if (!guides.length) {
    return;
  }

  drawGuides(guides, canvas);

  // now force object position
  guides.forEach(lg => {
    switch (lg.snap) {
      case 'start': {
        switch (lg.orientation) {
          case 'V': {
            target.set('left', lg.lineGuide + lg.offset);
            break;
          }
          case 'H': {
            target.set('top', lg.lineGuide + lg.offset);
            break;
          }
        }
        break;
      }
      case 'center': {
        switch (lg.orientation) {
          case 'V': {
            target.set('left', lg.lineGuide + lg.offset);
            break;
          }
          case 'H': {
            target.set('top', lg.lineGuide + lg.offset);
            break;
          }
        }
        break;
      }
      case 'end': {
        switch (lg.orientation) {
          case 'V': {
            target.set('left', lg.lineGuide + lg.offset);
            break;
          }
          case 'H': {
            target.set('top', lg.lineGuide + lg.offset);
            break;
          }
        }
        break;
      }
    }
  });
};

const drawGuidesOnKeyDown = (target: fabric.Object, canvas: fabric.Canvas) => {
  onObjectSnapingEnd(canvas);
  canvas.requestRenderAll();
  // find possible snapping lines
  const lineGuideStops = getLineGuideStops(target, canvas);
  // find snapping points of current object
  const itemBounds = getObjectSnappingEdges(target);
  // now find where can we snap current object
  const guides = getGuides(lineGuideStops, itemBounds, canvas.getZoom() || 1);
  // do nothing of no snapping
  if (!guides.length) {
    return;
  }

  drawGuides(guides, canvas);
};

export const showGuides = (keyCode: number, activeObject: fabric.Object | null, fabricCanvas: fabric.Canvas | null) => {
  if (!activeObject || !fabricCanvas) return;

  if (keyCode === 186) {
    drawGuidesOnKeyDown(activeObject, fabricCanvas);
  }
};
