import axios from 'axios';
import { put, takeLatest, all, call, takeEvery } from 'redux-saga/effects';
import { LOCUS_URI,APP_ID } from '../../utilities/paths';
import HttpService from '../../HttpService/HttpService';
import { appAPI } from '../../MappedAPI/MappedAPI';
import { FetchProductList, FETCH_PRODUCT_LIST, FetchMembersList, FETCH_MEMBERS_LIST, FetchOthersProductList, FETCH_OTHERS_PRODUCT_LIST, GetUsersData, GET_USER_DATA, FetchRolesList, FETCH_ROLES_LIST, LinkUserWithEntity, UnLinkUserWithEntity, LINK_USER_WITH_ENTITY, UNLINK_USER_WITH_ENTITY, CreateProduct, UpdateProduct, CREATE_PRODUCT, UPDATE_PRODUCT, DeleteProduct, DELETE_PRODUCT, FetchPermissions, FETCH_PERMISSIONS, CreateUser, CREATE_USER, CreateCustomRole, DeleteCustomRole, UpdateCustomRole, UPDATE_CUSTOM_ROLE, CREATE_CUSTOM_ROLE, DELETE_CUSTOM_ROLE, FetchUserPermissions, FETCH_USER_PERMISSIONS } from '../types';
import { store } from '../store';
import { openToast } from '../../Toasts';

function* fetchProductList(action: FetchProductList){
  let { payload: {entityType,entityId,userId},callback } = action;
  const TOKEN:any = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/roles?secret_token=${TOKEN}${entityType? ('&entityType='+entityType):''}${entityId? ('&entityId='+entityId):''}${userId? ('&userId='+userId):''}`
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchMembersList(action: FetchMembersList){
  let { payload: {entityType,entityId,permissions,roles,page},callback } = action;
  const TOKEN = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/user?secret_token=${TOKEN}&entityType=${entityType}${entityId? ('&entityId='+entityId):''}${permissions? ('&permissions='+permissions):''}${roles? ('&roles='+roles):''}${page? ('&page='+page):''}`
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchOthersProductList(action: FetchOthersProductList){
  let { payload: {userId,level,entityId},callback } = action;
  const TOKEN = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/user/${userId}/roles?secret_token=${TOKEN}&level=${level}${entityId? ('&entityId='+entityId):''}`
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* getUsersData(action: GetUsersData){
  let { payload: {userIds},callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/user-details?secret_token=${TOKEN}`
    let response = yield call(HttpService.post,LOCUS_URI,`${appAPI}${params}`,undefined,{userIds})

    if (response.data.success) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchRolesList(action: FetchRolesList){
  let { payload: {entityType},callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  try{
    let params = `/${APP_ID}/all-roles?organisationId=${ORG_ID}&secret_token=${TOKEN}&entityType=${entityType}`
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* linkUserWithEntity(action: LinkUserWithEntity){
  let { payload: {userId,entityId,entityType,role},callback } = action;
  const TOKEN = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/user/${userId}/link-entity?secret_token=${TOKEN}`
    let response = yield call(HttpService.post,LOCUS_URI,`${appAPI}${params}`,undefined,{entityId,entityType,role})
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* unlinkUserWithEntity(action: UnLinkUserWithEntity){
  let { payload: {userId,entityId,entityType},callback } = action;
  const TOKEN = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/user/${userId}/unlink-entity?secret_token=${TOKEN}`
    let response = yield call(HttpService.delete,LOCUS_URI,`${appAPI}${params}`,undefined,{entityId,entityType})
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* createProduct(action: CreateProduct){
  let { payload: {name},callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  const BRAND_ID:any = store.getState().roles.brand_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/brand/${BRAND_ID}/product?secret_token=${TOKEN}`
    let response = yield call(HttpService.post,LOCUS_URI,`${appAPI}${params}`,undefined,{name})
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message || "Workspace added!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error',error?.response?.data?.message || "Something went wrong!")

    callback && callback(error.response, true);
  }
}

function* updateProduct(action: UpdateProduct){
  let { payload: {name,product_id},callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  const BRAND_ID:any = store.getState().roles.brand_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/brand/${BRAND_ID}/product/${product_id}?secret_token=${TOKEN}`
    let response = yield call(HttpService.put,LOCUS_URI,`${appAPI}${params}`,undefined,{name})
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message || "Workspace name updated!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    if(error?.response?.data?.message === "Couldn't update product details") {
      openToast('warn', "Please make some changes before updating");
    } else {
      openToast('error', error?.response?.data?.message || "Something went wrong!");
    }

    callback && callback(error.response, true);
  }
}

function* deleteProduct(action: DeleteProduct){
  let { payload: {productId},callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  const BRAND_ID:any = store.getState().roles.brand_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/brand/${BRAND_ID}/product/${productId}?secret_token=${TOKEN}`
    let response = yield call(HttpService.delete,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message || "Workspace deleted!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error',error?.response?.data?.message || "Something went wrong!")

    callback && callback(error.response, true);
  }
}

function* fetchPermissions(action: FetchPermissions){
  let { callback } = action;
  const TOKEN = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/all-permissions?secret_token=${TOKEN}`
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* createUser(action: CreateUser){
  let { payload,callback } = action;
  const TOKEN = store.getState().authentication.token;
  let body:any = {
    usersList: [payload]
  } 
  try{
    let params = `/${APP_ID}/user/invite?secret_token=${TOKEN}`
    let response = yield call(HttpService.post,LOCUS_URI,`${appAPI}${params}`,undefined,body)
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message ||"User added!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error',error?.response?.data?.message || "Something went wrong!")

    callback && callback(error.response, true);
  }
}

function* createCustomRole(action: CreateCustomRole){
  let { payload,callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/custom-role?secret_token=${TOKEN}`
    let response = yield call(HttpService.post,LOCUS_URI,`${appAPI}${params}`,undefined,payload)
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message || "Custom role added!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error',error?.response?.data?.message || "Something went wrong!")

    callback && callback(error.response, true);
  }
}

function* updateCustomRole(action: UpdateCustomRole){
  let { payload,callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/custom-role?secret_token=${TOKEN}`
    let response = yield call(HttpService.patch,LOCUS_URI,`${appAPI}${params}`,undefined,payload)
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message || "Custom role updated!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error',error?.response?.data?.message || "Something went wrong!")

    callback && callback(error.response, true);
  }
}

function* deleteCustomRole(action: DeleteCustomRole){
  let { payload,callback } = action;
  const TOKEN = store.getState().authentication.token;
  const ORG_ID:any = store.getState().roles.org_id;
  try{
    let params = `/${APP_ID}/organisation/${ORG_ID}/custom-role/${payload}?secret_token=${TOKEN}`
    let response = yield call(HttpService.delete,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      openToast('success',response?.data?.data?.message || "Custome role deleted!")
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error',error?.response?.data?.message || "Something went wrong!")
    callback && callback(error.response, true);
  }
}

function* fetchUserPermissions(action: FetchUserPermissions){
  let { payload:{entityType,entityId},callback } = action;
  const TOKEN = store.getState().authentication.token;
  try{
    let params = `/${APP_ID}/permission?secret_token=${TOKEN}&entityType=${entityType}&entityId=${entityId}`
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

export function* rolesWatcher() {
  yield takeEvery(FETCH_PRODUCT_LIST, fetchProductList)
  yield takeEvery(FETCH_MEMBERS_LIST, fetchMembersList)
  yield takeLatest(FETCH_OTHERS_PRODUCT_LIST, fetchOthersProductList)
  yield takeEvery(GET_USER_DATA, getUsersData)
  yield takeLatest(FETCH_ROLES_LIST, fetchRolesList)
  yield takeLatest(LINK_USER_WITH_ENTITY, linkUserWithEntity)
  yield takeLatest(UNLINK_USER_WITH_ENTITY, unlinkUserWithEntity)
  yield takeLatest(CREATE_PRODUCT, createProduct)
  yield takeLatest(UPDATE_PRODUCT, updateProduct)
  yield takeLatest(DELETE_PRODUCT, deleteProduct)
  yield takeLatest(FETCH_PERMISSIONS, fetchPermissions)
  yield takeLatest(CREATE_USER, createUser)
  yield takeLatest(CREATE_CUSTOM_ROLE, createCustomRole)
  yield takeLatest(UPDATE_CUSTOM_ROLE, updateCustomRole)
  yield takeLatest(DELETE_CUSTOM_ROLE, deleteCustomRole)
  yield takeEvery(FETCH_USER_PERMISSIONS, fetchUserPermissions)
}